import logo from "./images/logo3.png";
import avatar from "./images/demo-user.png";
import notFound from "./images/404.jpg";
import unauthorize from "./images/401.png";
import error from "./images/failed.svg";
import success from "./images/success.svg";
import echalan from "./images/logo-bangla.png";
import pay from "./images/paynow.png";
import gov from "./images/govt_logo.png";
import logoo from "./images/logoo.png";

import sign from "./images/signature.png";

export const assets = {
  logo: logo,
  avatar: avatar,
  notFound: notFound,
  unauthorize: unauthorize,
  error: error,
  success: success,
  echalan: echalan,
  pay: pay,
  gov: gov,
  logoBlack: logoo,
  sign: sign,
};
