import { useFormik } from "formik";
import React, { useEffect } from "react";
import toast from "react-hot-toast";
import { ImPlus } from "react-icons/im";
import { IoCloseSharp } from "react-icons/io5";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import Select from "react-select";
import { BeatLoader } from "react-spinners";
import Swal from "sweetalert2";
import Loader from "../../../../components/custom/Loader";
import ClientFormikFormFieldMultiple from "../../../../components/custom/form/ClientFormikFormFieldMultiple";
import { useDeleteAgreementDetailsMutation } from "../../../../redux/api/agreementsApiSlice";
import { useGetCategoriesQuery } from "../../../../redux/api/categoryApiSlice";
import { useLazyGetSubCategoryCatalogQuery } from "../../../../redux/api/categoryCatalogApiSlice";
import {
  useShowAgreementAdminEditSecondPartQuery,
  useUpdateAgreementAdminEditSecondPartMutation,
} from "../../../../redux/api/editFormApiSlice";
import { globalDateFormat } from "../../../../utils/dateFormat";
import BanglaCalender from "../../../frontend/userPanel/form_two/BanglaCalender";
import styles from "./ContractCopyTwo.module.css";
import validationSchema, { initialValues } from "./validation/ContractTwo";

export default function ContractCopyTwoSecondPartEdit() {
  const dispatch = useDispatch();
  const { data: categoryData } = useGetCategoriesQuery();
  const [fetchSubCategory, { data: subCategoryData }] =
    useLazyGetSubCategoryCatalogQuery();

  const { id, trackReg } = useParams();

  const {
    data: AgreementDetailsData,
    isLoading: isLoadingAgreementDetails,
    isSuccess: isSuccessAgreement,
    isError,
  } = useShowAgreementAdminEditSecondPartQuery({ id, trackReg });
  const agreementDetails = AgreementDetailsData?.data;
  const applicant = agreementDetails?.profile?.user?.id;

  const navigate = useNavigate();

  const [deleteAgreementData] = useDeleteAgreementDetailsMutation();
  const [
    updateAgreement,
    {
      isLoading: isLoadingUpdate,
      error: errorUpdate,
      isError: isErrorUpdate,
      data: dataUpdate,
      isSuccess: isSuccessUpdate,
    },
  ] = useUpdateAgreementAdminEditSecondPartMutation();

  /// update a new agreement
  const {
    values,
    touched,
    errors,
    handleBlur,
    handleChange,
    handleSubmit,
    setValues,
    setErrors,
    resetForm,
  } = useFormik({
    initialValues,
    validationSchema: validationSchema,
    onSubmit: async (values, { setSubmitting }) => {
      try {
        updateAgreement({ id: agreementDetails?.id, formData: values });
      } catch (error) {
        setSubmitting(false);
      }
    },
  });

  useEffect(() => {
    if (isSuccessAgreement) {
      fetchSubCategory({ parent_id: agreementDetails.category.id });
      setValues((prev) => ({
        ...prev,
        trackReg,
        agreement_id: Number(agreementDetails?.profile?.id),
        date: agreementDetails.contractdate,
        nationality: agreementDetails.nationality,
        source: Number(agreementDetails.source),
        onwer: agreementDetails.onwer,
        address: agreementDetails?.profile?.communicationAddress,
        exchangerate: agreementDetails.exchangerate,
        documentexpire: agreementDetails.documentexpire,
        quantity: agreementDetails.quantity,
        category_id: agreementDetails.category.id,
        subCategory: agreementDetails.subCategory.id,
        worktitle: agreementDetails.worktitle,
        geoperimeter: agreementDetails.geoperimeter,
        extensions: agreementDetails.extensions,
        profiletypesa: agreementDetails.profiletypea,
        profiletypesb: agreementDetails.profiletypeb,
        profiletypesc:
          agreementDetails.profiletypec &&
          agreementDetails.profiletypec?.length > 0
            ? agreementDetails.profiletypec
            : [
                {
                  name: "",
                  fathername: "",
                  mothername: "",
                  address: "",
                  nidno: "",
                  mobile: "",
                  email: "",
                },
              ],
        profiletypesd:
          agreementDetails.profiletyped &&
          agreementDetails.profiletyped?.length > 0
            ? agreementDetails.profiletyped
            : [
                {
                  name: "",
                  fathername: "",
                  mothername: "",
                  address: "",
                  nidno: "",
                  mobile: "",
                  email: "",
                },
              ],
        form_status: agreementDetails.form_status,
      }));
    }
  }, [isSuccessAgreement]);

  useEffect(() => {
    if (isErrorUpdate) {
      setErrors(errorUpdate?.data?.data || {});
      toast.error(errorUpdate?.data?.message);
    }
    if (isSuccessUpdate) {
      navigate(-1);
      toast.success(dataUpdate?.message);
    }
    // eslint-disable-next-line
  }, [isErrorUpdate, isSuccessUpdate]);

  // Multiple item update and remove functions

  const newFieldSet = {
    name: "",
    fathername: "",
    mothername: "",
    address: "",
    nidno: "",
    mobile: "",
    email: "",
  };
  // step 4
  const handleAddFieldSetFirstSide = () => {
    setValues((prevValues) => ({
      ...prevValues,
      profiletypesa: [...prevValues.profiletypesa, newFieldSet],
    }));
  };

  const handleRemoveFieldSetFirstSide = async (idToRemove, itemId) => {
    // Update the field sets
    const updatedFieldSets = values.profiletypesa.filter(
      (_, i) => i !== idToRemove
    );

    // Function to update the state
    const updateFieldSets = () => {
      setValues((prevValues) => ({
        ...prevValues,
        profiletypesa: updatedFieldSets,
      }));
    };

    if (itemId) {
      const result = await Swal.fire({
        text: "আপনি কি মুছে ফেলতে চান?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "না",
        confirmButtonText: "হ্যাঁ",
      });

      if (result.isConfirmed) {
        try {
          await deleteAgreementData({ itemId, applicant }).unwrap();
          toast.success("মুছে ফেলা হয়েছে");
          updateFieldSets(); // Update the field sets after deletion
        } catch (err) {
          toast.error(err?.data?.message || "মুছে ফেলতে ব্যর্থ হয়েছে");
        }
      }
    } else {
      updateFieldSets(); // Update field sets if no itemId is provided
    }
  };

  const handleAddFieldSetSecondSide = () => {
    setValues((prevValues) => ({
      ...prevValues,
      profiletypesb: [...prevValues.profiletypesb, newFieldSet],
    }));
  };

  const handleRemoveFieldSetSecondSide = async (idToRemove, itemId) => {
    const updatedFieldSets = values.profiletypesb.filter(
      (_, i) => i !== idToRemove
    );

    // Function to update the state
    const updateFieldSets = () => {
      setValues((prevValues) => ({
        ...prevValues,
        profiletypesb: updatedFieldSets,
      }));
    };

    if (itemId) {
      const result = await Swal.fire({
        text: "আপনি কি মুছে ফেলতে চান?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "না",
        confirmButtonText: "হ্যাঁ",
      });

      if (result.isConfirmed) {
        try {
          await deleteAgreementData({ itemId, applicant }).unwrap();
          toast.success("মুছে ফেলা হয়েছে");
          updateFieldSets(); // Update the field sets after deletion
        } catch (err) {
          toast.error(err?.data?.message || "মুছে ফেলতে ব্যর্থ হয়েছে");
        }
      }
    } else {
      updateFieldSets(); // Update field sets if no itemId is provided
    }
  };

  const handleAddFieldSetFirstWitness = () => {
    setValues((prevValues) => ({
      ...prevValues,
      profiletypesc: [...prevValues.profiletypesc, newFieldSet],
    }));
  };

  const handleRemoveFieldSetFirstWitness = async (idToRemove, itemId) => {
    const updatedFieldSets = values.profiletypesc.filter(
      (_, i) => i !== idToRemove
    );

    // Function to update the state
    const updateFieldSets = () => {
      setValues((prevValues) => ({
        ...prevValues,
        profiletypesc: updatedFieldSets,
      }));
    };

    if (itemId) {
      const result = await Swal.fire({
        text: "আপনি কি মুছে ফেলতে চান?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "না",
        confirmButtonText: "হ্যাঁ",
      });

      if (result.isConfirmed) {
        try {
          await deleteAgreementData({ itemId, applicant }).unwrap();
          toast.success("মুছে ফেলা হয়েছে");
          updateFieldSets(); // Update the field sets after deletion
        } catch (err) {
          toast.error(err?.data?.message || "মুছে ফেলতে ব্যর্থ হয়েছে");
        }
      }
    } else {
      updateFieldSets(); // Update field sets if no itemId is provided
    }
  };

  const handleAddFieldSetSecondWitness = () => {
    setValues((prevValues) => ({
      ...prevValues,
      profiletypesd: [...prevValues.profiletypesd, newFieldSet],
    }));
  };

  const handleRemoveFieldSetSecondWitness = async (idToRemove, itemId) => {
    const updatedFieldSets = values.profiletypesd.filter(
      (_, i) => i !== idToRemove
    );

    // Function to update the state
    const updateFieldSets = () => {
      setValues((prevValues) => ({
        ...prevValues,
        profiletypesd: updatedFieldSets,
      }));
    };

    if (itemId) {
      const result = await Swal.fire({
        text: "আপনি কি মুছে ফেলতে চান?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "না",
        confirmButtonText: "হ্যাঁ",
      });

      if (result.isConfirmed) {
        try {
          await deleteAgreementData({ itemId, applicant }).unwrap();
          toast.success("মুছে ফেলা হয়েছে");
          updateFieldSets(); // Update the field sets after deletion
        } catch (err) {
          toast.error(err?.data?.message || "মুছে ফেলতে ব্যর্থ হয়েছে");
        }
      }
    } else {
      updateFieldSets(); // Update field sets if no itemId is provided
    }
  };

  // Selected defoult items
  // Mapping the category data to create options for the select component
  const WorkTypeLists = categoryData?.data?.map((data) => ({
    value: data.id,
    label: data.name,
  }));

  // Finding the default category based on the value
  const catDefault = WorkTypeLists?.find(
    (data) => data.value === values.category_id
  );

  // Mapping the sub-category data to create options for the select component
  const WorkTypeSubList = subCategoryData?.data?.map((data) => ({
    value: data.id,
    label: data.name,
  }));

  // Finding the default sub-category based on the value
  const subCatDefaultData = subCategoryData?.data?.find(
    (data) => data.id === values.subCategory
  );

  const subCatDefault = subCatDefaultData
    ? {
        value: subCatDefaultData.id,
        label: subCatDefaultData.name,
      }
    : null;

  const handleCatChange = (selectedOption) => {
    setValues((prevValues) => ({
      ...prevValues,
      category_id: selectedOption.value,
      subCategory: "",
    }));

    fetchSubCategory({ parent_id: values.category_id });
  };
  const handleSourceChange = (val) => {
    setValues((prevValues) => ({
      ...prevValues,
      source: val,
    }));
  };

  if (isError) {
    navigate(`/page-not-found`, {
      replace: true,
    });
  }

  if (isLoadingAgreementDetails) {
    return <Loader />;
  }

  return (
    <div>
      <section className="section py-5 copyrightformone crossiconshowicon service-single">
        <div className="container">
          <div className="d-flex justify-content-center align-items-center">
            <div className="card shadow border-0 w-100">
              <div
                className="tab-content rounded border py-4 paddddingnone px-5 border-1"
                id="nav-tabContent"
              >
                <div className="text-end me-4">
                  <div className="">
                    {" "}
                    <img
                      src={`/assets/images/p2.png`}
                      alt="Logo"
                      style={{
                        height: "70px",
                        width: "60px",
                        marginTop: "-47px",
                      }}
                      className="img-fluid pataresponsive"
                    />{" "}
                  </div>
                </div>

                <div
                  className="tab-pane fade active show"
                  id="nav-application"
                  role="tabpanel"
                  aria-labelledby="nav-application-tab"
                >
                  <div className={styles.applicationForm}>
                    <div
                      style={{ marginTop: "-20px" }}
                      className="applicationForm-header text-center"
                    >
                      <h5 className="mb-0 form2fontsizeee">বিষয়াবলীর বিবরণী</h5>
                      <h5 className="mt-1 form2fontsizeee">
                        (দুটি প্রতিলিপি পাঠাতে হবে)
                      </h5>
                    </div>

                    <div className="mb-3 mt-4 form2fontsizeee3 pt-2">
                      <div className={styles.add2sectiontitle}>
                        <div className="add2-section-title">
                          <div className="d-flex widtghh ">
                            <h5 className="mt-2">
                              <b>১. নিবন্ধন সংখ্যাঃ </b>
                            </h5>

                            <h5 className="px-3 widtghhh ms-3  py-2 text-center rounded border text-success">
                              <b> কপিরাইট অফিস কর্তৃক পূরণীয় </b>
                            </h5>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="add2-section-2">
                      <div className="row">
                        <div className="col-md-12">
                          <div className={styles.add2sectiontitle}>
                            <h5>
                              <b>২. আবেদনকারীর তথ্য</b>
                            </h5>
                          </div>
                          <div className={styles.add2panelbackground}>
                            <div className="row">
                              <div className="col-md-3">
                                <div className="form-group mb-2">
                                  <label>আবেদনকারীর নাম</label>
                                  <input
                                    placeholder="আবেদনকারীর নাম"
                                    type="email"
                                    className="form-control"
                                    value={
                                      AgreementDetailsData?.data?.profile
                                        ?.puname
                                    }
                                    disabled
                                  />
                                </div>
                              </div>
                              <div className="col-md-3">
                                <div className="form-group mb-2">
                                  <label>ই-মেইল</label>
                                  <input
                                    type="email"
                                    className="form-control"
                                    placeholder="ই-মেইল"
                                    value={
                                      AgreementDetailsData?.data?.profile?.user
                                        ?.email
                                    }
                                    disabled
                                  />
                                </div>
                              </div>
                              <div className="col-md-3">
                                <div className="form-group mb-2">
                                  <label>মোবাইল নম্বর</label>
                                  <input
                                    disabled
                                    type="number"
                                    className="form-control"
                                    value={
                                      AgreementDetailsData?.data?.profile
                                        ?.mobileNo
                                    }
                                  />
                                </div>
                              </div>
                              <div className="col-md-3">
                                <div className="form-group mb-2">
                                  <label>ঠিকানা</label>
                                  <ClientFormikFormFieldMultiple
                                    placeholder={"ঠিকানা"}
                                    value={values.address}
                                    name={`address`}
                                    errors={errors?.address && errors?.address}
                                    touched={
                                      touched?.address && touched?.address
                                    }
                                    handleChange={handleChange}
                                    handleBlur={handleBlur}
                                  />
                                </div>
                              </div>
                              <div className="col-md-3">
                                <div className="form-group mb-2">
                                  <label>জাতীয়তা</label>
                                  <ClientFormikFormFieldMultiple
                                    placeholder={"জাতীয়তা"}
                                    value={values.nationality}
                                    name={`nationality`}
                                    errors={
                                      errors?.nationality && errors?.nationality
                                    }
                                    touched={
                                      touched?.nationality &&
                                      touched?.nationality
                                    }
                                    handleChange={handleChange}
                                    handleBlur={handleBlur}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="add2-section-3 mt-3">
                      <div className="row">
                        <div className="col-md-12">
                          <div className={styles.add2sectiontitle}>
                            <h5>
                              <b>৩. পক্ষদ্বয়ের নাম ও ঠিকানা</b>
                            </h5>
                          </div>

                          <div className={styles.add2panelbackground}>
                            <b className="mb-2 d-block">
                              ক. ১ম পক্ষ সত্মনিয়োগকর্তা / লাইসেন্স প্রদানকারী
                            </b>

                            {values.profiletypesa.map((item, index) => (
                              <div key={index} className="row mb-2">
                                <div className="col-md-3">
                                  <div className="form-group mb-2">
                                    <ClientFormikFormFieldMultiple
                                      placeholder="নাম"
                                      value={item.name}
                                      name={`profiletypesa[${index}].name`}
                                      errors={
                                        errors?.profiletypesa &&
                                        errors.profiletypesa?.length > index &&
                                        errors?.profiletypesa[index]?.name
                                      }
                                      touched={
                                        touched?.profiletypesa &&
                                        touched.profiletypesa?.length > index &&
                                        touched?.profiletypesa[index]?.name
                                      }
                                      handleChange={handleChange}
                                      handleBlur={handleBlur}
                                    />
                                  </div>
                                </div>
                                <div className="col-md-3">
                                  <div className="form-group mb-2">
                                    <ClientFormikFormFieldMultiple
                                      placeholder="পিতার নাম"
                                      value={item.fathername}
                                      name={`profiletypesa[${index}].fathername`}
                                      errors={
                                        errors?.profiletypesa &&
                                        errors.profiletypesa?.length > index &&
                                        errors?.profiletypesa[index]?.fathername
                                      }
                                      touched={
                                        touched?.profiletypesa &&
                                        touched.profiletypesa?.length > index &&
                                        touched?.profiletypesa[index]
                                          ?.fathername
                                      }
                                      handleChange={handleChange}
                                      handleBlur={handleBlur}
                                    />
                                  </div>
                                </div>
                                <div className="col-md-3">
                                  <div className="form-group mb-2">
                                    <ClientFormikFormFieldMultiple
                                      placeholder="মাতার নাম"
                                      value={item.mothername}
                                      name={`profiletypesa[${index}].mothername`}
                                      errors={
                                        errors?.profiletypesa &&
                                        errors.profiletypesa?.length > index &&
                                        errors?.profiletypesa[index]?.mothername
                                      }
                                      touched={
                                        touched?.profiletypesa &&
                                        touched.profiletypesa?.length > index &&
                                        touched?.profiletypesa[index]
                                          ?.mothername
                                      }
                                      handleChange={handleChange}
                                      handleBlur={handleBlur}
                                    />
                                  </div>
                                </div>
                                <div className="col-md-3">
                                  <div className="form-group mb-2">
                                    <ClientFormikFormFieldMultiple
                                      placeholder="ঠিকানা"
                                      value={item.address}
                                      name={`profiletypesa[${index}].address`}
                                      errors={
                                        errors?.profiletypesa &&
                                        errors.profiletypesa?.length > index &&
                                        errors?.profiletypesa[index]?.address
                                      }
                                      touched={
                                        touched?.profiletypesa &&
                                        touched.profiletypesa?.length > index &&
                                        touched?.profiletypesa[index]?.address
                                      }
                                      handleChange={handleChange}
                                      handleBlur={handleBlur}
                                    />
                                  </div>
                                </div>
                                <div className="col-md-3">
                                  <div className="form-group mb-2">
                                    <ClientFormikFormFieldMultiple
                                      placeholder="ভোটার আইডি নং"
                                      value={item.nidno}
                                      name={`profiletypesa[${index}].nidno`}
                                      errors={
                                        errors?.profiletypesa &&
                                        errors.profiletypesa?.length > index &&
                                        errors?.profiletypesa[index]?.nidno
                                      }
                                      touched={
                                        touched?.profiletypesa &&
                                        touched.profiletypesa?.length > index &&
                                        touched?.profiletypesa[index]?.nidno
                                      }
                                      handleChange={handleChange}
                                      handleBlur={handleBlur}
                                    />
                                  </div>
                                </div>
                                <div className="col-md-3">
                                  <div className="form-group mb-2">
                                    <ClientFormikFormFieldMultiple
                                      placeholder="মোবাইল নাম্বার"
                                      value={item.mobile}
                                      name={`profiletypesa[${index}].mobile`}
                                      errors={
                                        errors?.profiletypesa &&
                                        errors.profiletypesa?.length > index &&
                                        errors?.profiletypesa[index]?.mobile
                                      }
                                      touched={
                                        touched?.profiletypesa &&
                                        touched.profiletypesa?.length > index &&
                                        touched?.profiletypesa[index]?.mobile
                                      }
                                      handleChange={handleChange}
                                      handleBlur={handleBlur}
                                    />
                                  </div>
                                </div>
                                <div className="col-md-3">
                                  <div className="form-group mb-2">
                                    <ClientFormikFormFieldMultiple
                                      placeholder="ইমেইল"
                                      value={item.email}
                                      name={`profiletypesa[${index}].email`}
                                      errors={
                                        errors?.profiletypesa &&
                                        errors.profiletypesa?.length > index &&
                                        errors?.profiletypesa[index]?.email
                                      }
                                      touched={
                                        touched?.profiletypesa &&
                                        touched.profiletypesa?.length > index &&
                                        touched?.profiletypesa[index]?.email
                                      }
                                      handleChange={handleChange}
                                      handleBlur={handleBlur}
                                    />
                                  </div>
                                </div>
                                <div className="col-md-2 mb-3">
                                  <div className="form-group">
                                    <div className="d-flex">
                                      {(values.profiletypesa.length ===
                                        index + 1 ||
                                        values.profiletypesa.length === 1) && (
                                        <ImPlus
                                          className="customAddButton p-2"
                                          onClick={handleAddFieldSetFirstSide}
                                        />
                                      )}
                                      {values.profiletypesa.length > 1 &&
                                        index !== 0 && (
                                          <IoCloseSharp
                                            className="customRemoveButton"
                                            onClick={() =>
                                              handleRemoveFieldSetFirstSide(
                                                index,
                                                item?.id
                                              )
                                            }
                                          />
                                        )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="add2-section-4 mt-3">
                      <div className="row">
                        <div className="col-md-12">
                          <div className={styles.add2panelbackground}>
                            <b className="mb-2 d-block">
                              খ. ২য় পক্ষ সত্মনিয়োগী / লাইসেন্স গ্রহীতা
                            </b>

                            {values.profiletypesb.map((item, index) => (
                              <div key={index} className="row mb-2">
                                <div className="col-md-3">
                                  <div className="form-group mb-2">
                                    <ClientFormikFormFieldMultiple
                                      placeholder="নাম"
                                      value={item.name}
                                      name={`profiletypesb[${index}].name`}
                                      errors={
                                        errors?.profiletypesb &&
                                        errors.profiletypesb?.length > index &&
                                        errors?.profiletypesb[index]?.name
                                      }
                                      touched={
                                        touched?.profiletypesb &&
                                        touched.profiletypesb?.length > index &&
                                        touched?.profiletypesb[index]?.name
                                      }
                                      handleChange={handleChange}
                                      handleBlur={handleBlur}
                                    />
                                  </div>
                                </div>
                                <div className="col-md-3">
                                  <div className="form-group mb-2">
                                    <ClientFormikFormFieldMultiple
                                      placeholder="পিতার নাম"
                                      value={item.fathername}
                                      name={`profiletypesb[${index}].fathername`}
                                      errors={
                                        errors?.profiletypesb &&
                                        errors.profiletypesb?.length > index &&
                                        errors?.profiletypesb[index]?.fathername
                                      }
                                      touched={
                                        touched?.profiletypesb &&
                                        touched.profiletypesb?.length > index &&
                                        touched?.profiletypesb[index]
                                          ?.fathername
                                      }
                                      handleChange={handleChange}
                                      handleBlur={handleBlur}
                                    />
                                  </div>
                                </div>
                                <div className="col-md-3">
                                  <div className="form-group mb-2">
                                    <ClientFormikFormFieldMultiple
                                      placeholder="মাতার নাম"
                                      value={item.mothername}
                                      name={`profiletypesb[${index}].mothername`}
                                      errors={
                                        errors?.profiletypesb &&
                                        errors.profiletypesb?.length > index &&
                                        errors?.profiletypesb[index]?.mothername
                                      }
                                      touched={
                                        touched?.profiletypesb &&
                                        touched.profiletypesb?.length > index &&
                                        touched?.profiletypesb[index]
                                          ?.mothername
                                      }
                                      handleChange={handleChange}
                                      handleBlur={handleBlur}
                                    />
                                  </div>
                                </div>
                                <div className="col-md-3">
                                  <div className="form-group mb-2">
                                    <ClientFormikFormFieldMultiple
                                      placeholder="ঠিকানা"
                                      value={item.address}
                                      name={`profiletypesb[${index}].address`}
                                      errors={
                                        errors?.profiletypesb &&
                                        errors.profiletypesb?.length > index &&
                                        errors?.profiletypesb[index]?.address
                                      }
                                      touched={
                                        touched?.profiletypesb &&
                                        touched.profiletypesb?.length > index &&
                                        touched?.profiletypesb[index]?.address
                                      }
                                      handleChange={handleChange}
                                      handleBlur={handleBlur}
                                    />
                                  </div>
                                </div>
                                <div className="col-md-3">
                                  <div className="form-group mb-2">
                                    <ClientFormikFormFieldMultiple
                                      placeholder="ভোটার আইডি নং"
                                      value={item.nidno}
                                      name={`profiletypesb[${index}].nidno`}
                                      errors={
                                        errors?.profiletypesb &&
                                        errors.profiletypesb?.length > index &&
                                        errors?.profiletypesb[index]?.nidno
                                      }
                                      touched={
                                        touched?.profiletypesb &&
                                        touched.profiletypesb?.length > index &&
                                        touched?.profiletypesb[index]?.nidno
                                      }
                                      handleChange={handleChange}
                                      handleBlur={handleBlur}
                                    />
                                  </div>
                                </div>
                                <div className="col-md-3">
                                  <div className="form-group mb-2">
                                    <ClientFormikFormFieldMultiple
                                      placeholder="মোবাইল নাম্বার"
                                      value={item.mobile}
                                      name={`profiletypesb[${index}].mobile`}
                                      errors={
                                        errors?.profiletypesb &&
                                        errors.profiletypesb?.length > index &&
                                        errors?.profiletypesb[index]?.mobile
                                      }
                                      touched={
                                        touched?.profiletypesb &&
                                        touched.profiletypesb?.length > index &&
                                        touched?.profiletypesb[index]?.mobile
                                      }
                                      handleChange={handleChange}
                                      handleBlur={handleBlur}
                                    />
                                  </div>
                                </div>
                                <div className="col-md-3">
                                  <div className="form-group mb-2">
                                    <ClientFormikFormFieldMultiple
                                      placeholder="ইমেইল"
                                      value={item.email}
                                      name={`profiletypesb[${index}].email`}
                                      errors={
                                        errors?.profiletypesb &&
                                        errors.profiletypesb?.length > index &&
                                        errors?.profiletypesb[index]?.email
                                      }
                                      touched={
                                        touched?.profiletypesb &&
                                        touched.profiletypesb?.length > index &&
                                        touched?.profiletypesb[index]?.email
                                      }
                                      handleChange={handleChange}
                                      handleBlur={handleBlur}
                                    />
                                  </div>
                                </div>
                                <div className="col-md-2 mb-3">
                                  <div className="form-group">
                                    <div className="d-flex">
                                      {(values.profiletypesb.length ===
                                        index + 1 ||
                                        values.profiletypesb.length === 1) && (
                                        <ImPlus
                                          className="customAddButton p-2"
                                          onClick={handleAddFieldSetSecondSide}
                                        />
                                      )}
                                      {values.profiletypesb.length > 1 &&
                                        index !== 0 && (
                                          <IoCloseSharp
                                            className="customRemoveButton"
                                            onClick={() =>
                                              handleRemoveFieldSetSecondSide(
                                                index,
                                                item?.id
                                              )
                                            }
                                          />
                                        )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="add2-section-5 mt-3">
                      <div className="row">
                        <div className="col-md-12">
                          <div className={styles.add2sectiontitle}>
                            <h5>
                              <b>৪. সাক্ষীর নাম ও ঠিকানা</b>
                            </h5>
                          </div>

                          <div className={styles.add2panelbackground}>
                            <b className="mb-2 d-block">ক. ১ম সাক্ষী</b>

                            {values.profiletypesc.map((item, index) => (
                              <div key={index} className="row mb-2">
                                <div className="col-md-3">
                                  <div className="form-group mb-2">
                                    <ClientFormikFormFieldMultiple
                                      placeholder="নাম"
                                      value={item.name}
                                      name={`profiletypesc[${index}].name`}
                                      errors={
                                        errors?.profiletypesc &&
                                        errors.profiletypesc?.length > index &&
                                        errors?.profiletypesc[index]?.name
                                      }
                                      touched={
                                        touched?.profiletypesc &&
                                        touched.profiletypesc?.length > index &&
                                        touched?.profiletypesc[index]?.name
                                      }
                                      handleChange={handleChange}
                                      handleBlur={handleBlur}
                                    />
                                  </div>
                                </div>
                                <div className="col-md-3">
                                  <div className="form-group mb-2">
                                    <ClientFormikFormFieldMultiple
                                      placeholder="পিতার নাম"
                                      value={item.fathername}
                                      name={`profiletypesc[${index}].fathername`}
                                      errors={
                                        errors?.profiletypesc &&
                                        errors.profiletypesc?.length > index &&
                                        errors?.profiletypesc[index]?.fathername
                                      }
                                      touched={
                                        touched?.profiletypesc &&
                                        touched.profiletypesc?.length > index &&
                                        touched?.profiletypesc[index]
                                          ?.fathername
                                      }
                                      handleChange={handleChange}
                                      handleBlur={handleBlur}
                                    />
                                  </div>
                                </div>
                                <div className="col-md-3">
                                  <div className="form-group mb-2">
                                    <ClientFormikFormFieldMultiple
                                      placeholder="মাতার নাম"
                                      value={item.mothername}
                                      name={`profiletypesc[${index}].mothername`}
                                      errors={
                                        errors?.profiletypesc &&
                                        errors.profiletypesc?.length > index &&
                                        errors?.profiletypesc[index]?.mothername
                                      }
                                      touched={
                                        touched?.profiletypesc &&
                                        touched.profiletypesc?.length > index &&
                                        touched?.profiletypesc[index]
                                          ?.mothername
                                      }
                                      handleChange={handleChange}
                                      handleBlur={handleBlur}
                                    />
                                  </div>
                                </div>
                                <div className="col-md-3">
                                  <div className="form-group mb-2">
                                    <ClientFormikFormFieldMultiple
                                      placeholder="ঠিকানা"
                                      value={item.address}
                                      name={`profiletypesc[${index}].address`}
                                      errors={
                                        errors?.profiletypesc &&
                                        errors.profiletypesc?.length > index &&
                                        errors?.profiletypesc[index]?.address
                                      }
                                      touched={
                                        touched?.profiletypesc &&
                                        touched.profiletypesc?.length > index &&
                                        touched?.profiletypesc[index]?.address
                                      }
                                      handleChange={handleChange}
                                      handleBlur={handleBlur}
                                    />
                                  </div>
                                </div>
                                <div className="col-md-3">
                                  <div className="form-group mb-2">
                                    <ClientFormikFormFieldMultiple
                                      placeholder="ভোটার আইডি নং"
                                      value={item.nidno}
                                      name={`profiletypesc[${index}].nidno`}
                                      errors={
                                        errors?.profiletypesc &&
                                        errors.profiletypesc?.length > index &&
                                        errors?.profiletypesc[index]?.nidno
                                      }
                                      touched={
                                        touched?.profiletypesc &&
                                        touched.profiletypesc?.length > index &&
                                        touched?.profiletypesc[index]?.nidno
                                      }
                                      handleChange={handleChange}
                                      handleBlur={handleBlur}
                                    />
                                  </div>
                                </div>
                                <div className="col-md-3">
                                  <div className="form-group mb-2">
                                    <ClientFormikFormFieldMultiple
                                      placeholder="মোবাইল নাম্বার"
                                      value={item.mobile}
                                      name={`profiletypesc[${index}].mobile`}
                                      errors={
                                        errors?.profiletypesc &&
                                        errors.profiletypesc?.length > index &&
                                        errors?.profiletypesc[index]?.mobile
                                      }
                                      touched={
                                        touched?.profiletypesc &&
                                        touched.profiletypesc?.length > index &&
                                        touched?.profiletypesc[index]?.mobile
                                      }
                                      handleChange={handleChange}
                                      handleBlur={handleBlur}
                                    />
                                  </div>
                                </div>
                                <div className="col-md-3">
                                  <div className="form-group mb-2">
                                    <ClientFormikFormFieldMultiple
                                      placeholder="ইমেইল"
                                      value={item.email}
                                      name={`profiletypesc[${index}].email`}
                                      errors={
                                        errors?.profiletypesc &&
                                        errors.profiletypesc?.length > index &&
                                        errors?.profiletypesc[index]?.email
                                      }
                                      touched={
                                        touched?.profiletypesc &&
                                        touched.profiletypesc?.length > index &&
                                        touched?.profiletypesc[index]?.email
                                      }
                                      handleChange={handleChange}
                                      handleBlur={handleBlur}
                                    />
                                  </div>
                                </div>
                                <div className="col-md-2 mb-3">
                                  <div className="form-group">
                                    <div className="d-flex">
                                      {(values.profiletypesc.length ===
                                        index + 1 ||
                                        values.profiletypesc.length === 1) && (
                                        <ImPlus
                                          className="customAddButton p-2"
                                          onClick={
                                            handleAddFieldSetFirstWitness
                                          }
                                        />
                                      )}
                                      {values.profiletypesc.length > 1 &&
                                        index !== 0 && (
                                          <IoCloseSharp
                                            className="customRemoveButton"
                                            onClick={() =>
                                              handleRemoveFieldSetFirstWitness(
                                                index,
                                                item?.id
                                              )
                                            }
                                          />
                                        )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="add2-section-6 mt-3">
                      <div className="row">
                        <div className="col-md-12">
                          <div className={styles.add2panelbackground}>
                            <b className="mb-2 d-block">খ. ২য় সাক্ষী</b>

                            {values.profiletypesd.map((item, index) => (
                              <div key={index} className="row mb-2">
                                <div className="col-md-3">
                                  <div className="form-group mb-2">
                                    <ClientFormikFormFieldMultiple
                                      placeholder="নাম"
                                      value={item.name}
                                      name={`profiletypesd[${index}].name`}
                                      errors={
                                        errors?.profiletypesd &&
                                        errors.profiletypesd?.length > index &&
                                        errors?.profiletypesd[index]?.name
                                      }
                                      touched={
                                        touched?.profiletypesd &&
                                        touched.profiletypesd?.length > index &&
                                        touched?.profiletypesd[index]?.name
                                      }
                                      handleChange={handleChange}
                                      handleBlur={handleBlur}
                                    />
                                  </div>
                                </div>
                                <div className="col-md-3">
                                  <div className="form-group mb-2">
                                    <ClientFormikFormFieldMultiple
                                      placeholder="পিতার নাম"
                                      value={item.fathername}
                                      name={`profiletypesd[${index}].fathername`}
                                      errors={
                                        errors?.profiletypesd &&
                                        errors.profiletypesd?.length > index &&
                                        errors?.profiletypesd[index]?.fathername
                                      }
                                      touched={
                                        touched?.profiletypesd &&
                                        touched.profiletypesd?.length > index &&
                                        touched?.profiletypesd[index]
                                          ?.fathername
                                      }
                                      handleChange={handleChange}
                                      handleBlur={handleBlur}
                                    />
                                  </div>
                                </div>
                                <div className="col-md-3">
                                  <div className="form-group mb-2">
                                    <ClientFormikFormFieldMultiple
                                      placeholder="মাতার নাম"
                                      value={item.mothername}
                                      name={`profiletypesd[${index}].mothername`}
                                      errors={
                                        errors?.profiletypesd &&
                                        errors.profiletypesd?.length > index &&
                                        errors?.profiletypesd[index]?.mothername
                                      }
                                      touched={
                                        touched?.profiletypesd &&
                                        touched.profiletypesd?.length > index &&
                                        touched?.profiletypesd[index]
                                          ?.mothername
                                      }
                                      handleChange={handleChange}
                                      handleBlur={handleBlur}
                                    />
                                  </div>
                                </div>
                                <div className="col-md-3">
                                  <div className="form-group mb-2">
                                    <ClientFormikFormFieldMultiple
                                      placeholder="ঠিকানা"
                                      value={item.address}
                                      name={`profiletypesd[${index}].address`}
                                      errors={
                                        errors?.profiletypesd &&
                                        errors.profiletypesd?.length > index &&
                                        errors?.profiletypesd[index]?.address
                                      }
                                      touched={
                                        touched?.profiletypesd &&
                                        touched.profiletypesd?.length > index &&
                                        touched?.profiletypesd[index]?.address
                                      }
                                      handleChange={handleChange}
                                      handleBlur={handleBlur}
                                    />
                                  </div>
                                </div>
                                <div className="col-md-3">
                                  <div className="form-group mb-2">
                                    <ClientFormikFormFieldMultiple
                                      placeholder="ভোটার আইডি নং"
                                      value={item.nidno}
                                      name={`profiletypesd[${index}].nidno`}
                                      errors={
                                        errors?.profiletypesd &&
                                        errors.profiletypesd?.length > index &&
                                        errors?.profiletypesd[index]?.nidno
                                      }
                                      touched={
                                        touched?.profiletypesd &&
                                        touched.profiletypesd?.length > index &&
                                        touched?.profiletypesd[index]?.nidno
                                      }
                                      handleChange={handleChange}
                                      handleBlur={handleBlur}
                                    />
                                  </div>
                                </div>
                                <div className="col-md-3">
                                  <div className="form-group mb-2">
                                    <ClientFormikFormFieldMultiple
                                      placeholder="মোবাইল নাম্বার"
                                      value={item.mobile}
                                      name={`profiletypesd[${index}].mobile`}
                                      errors={
                                        errors?.profiletypesd &&
                                        errors.profiletypesd?.length > index &&
                                        errors?.profiletypesd[index]?.mobile
                                      }
                                      touched={
                                        touched?.profiletypesd &&
                                        touched.profiletypesd?.length > index &&
                                        touched?.profiletypesd[index]?.mobile
                                      }
                                      handleChange={handleChange}
                                      handleBlur={handleBlur}
                                    />
                                  </div>
                                </div>
                                <div className="col-md-3">
                                  <div className="form-group mb-2">
                                    <ClientFormikFormFieldMultiple
                                      placeholder="ইমেইল"
                                      value={item.email}
                                      name={`profiletypesd[${index}].email`}
                                      errors={
                                        errors?.profiletypesd &&
                                        errors.profiletypesd?.length > index &&
                                        errors?.profiletypesd[index]?.email
                                      }
                                      touched={
                                        touched?.profiletypesd &&
                                        touched.profiletypesd?.length > index &&
                                        touched?.profiletypesd[index]?.email
                                      }
                                      handleChange={handleChange}
                                      handleBlur={handleBlur}
                                    />
                                  </div>
                                </div>
                                <div className="col-md-2 mb-3">
                                  <div className="form-group">
                                    <div className="d-flex">
                                      {(values.profiletypesd.length ===
                                        index + 1 ||
                                        values.profiletypesd.length === 1) && (
                                        <ImPlus
                                          className="customAddButton p-2"
                                          onClick={
                                            handleAddFieldSetSecondWitness
                                          }
                                        />
                                      )}
                                      {values.profiletypesd.length > 1 &&
                                        index !== 0 && (
                                          <IoCloseSharp
                                            className="customRemoveButton"
                                            onClick={() =>
                                              handleRemoveFieldSetSecondWitness(
                                                index,
                                                item?.id
                                              )
                                            }
                                          />
                                        )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="add2-section-7 mt-3">
                      <div className="row">
                        <div className="col-md-12">
                          <div className={styles.add2sectiontitle}>
                            <h5>
                              <b>
                                ৫. কর্মটির শ্রেণি/উপশ্রেণি এবং নাম (যদি থাকে)
                              </b>
                            </h5>
                          </div>
                          <div className={styles.add2panelbackground}>
                            <div className="row">
                              <div className="col-md-4 col-12">
                                <div className="form-group">
                                  <div>
                                    <Select
                                      options={WorkTypeLists}
                                      value={
                                        catDefault || {
                                          value: "",
                                          label: "-- শ্রেণি --",
                                          isDisabled: true,
                                        }
                                      }
                                      classNamePrefix="react-select"
                                      onChange={handleCatChange}
                                    />
                                  </div>
                                  <div>
                                    {errors.category_id &&
                                      touched.category_id && (
                                        <small className="my-2 text-danger">
                                          {errors.category_id}
                                        </small>
                                      )}
                                  </div>
                                </div>
                              </div>

                              <div className="col-md-4 col-12">
                                <div className="form-group">
                                  <div>
                                    <Select
                                      options={WorkTypeSubList}
                                      value={
                                        subCatDefault || {
                                          value: "",
                                          label: "-- উপশ্রেণি --",
                                          isDisabled: true,
                                        }
                                      }
                                      classNamePrefix="react-select"
                                      onChange={(selectedOption) => {
                                        setValues((prevValues) => ({
                                          ...prevValues,
                                          subCategory: selectedOption.value,
                                        }));
                                      }}
                                      onBlur={handleBlur}
                                    />
                                  </div>
                                  <div>
                                    {errors.subCategory &&
                                      touched.subCategory && (
                                        <small className="my-2 text-danger">
                                          {errors.subCategory}
                                        </small>
                                      )}
                                  </div>
                                </div>
                              </div>

                              <div className="col-md-4">
                                <div className="form-group mb-2">
                                  <ClientFormikFormFieldMultiple
                                    placeholder="কর্মের শিরোনাম"
                                    value={values.worktitle}
                                    name={`worktitle`}
                                    errors={
                                      errors?.worktitle && errors?.worktitle
                                    }
                                    touched={
                                      touched?.worktitle && touched?.worktitle
                                    }
                                    handleChange={handleChange}
                                    handleBlur={handleBlur}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="add2-section-8 mt-3">
                      <div className="row">
                        <div className="col-md-12">
                          <div className={styles.add2sectiontitle}>
                            <h5>
                              <b>৬. সত্ত্বাধিকারী স্বত্ব প্রাপ্তির উৎস</b>
                            </h5>
                          </div>
                          <div className={styles.add2panelbackground}>
                            <div className="row">
                              <div className="col-md-2 col-12">
                                <div className="form-check mx-3">
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    id="pc1"
                                    checked={values.source === 1}
                                    onClick={() => handleSourceChange(1)}
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="pc1"
                                  >
                                    প্রণেতা
                                  </label>
                                </div>
                              </div>
                              <div className="col-md-3 col-12">
                                <div className="form-check mx-3">
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    name="praptirUtso"
                                    id="pc2"
                                    checked={values.source === 2}
                                    onClick={() => handleSourceChange(2)}
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="pc2"
                                  >
                                    বিনিয়োগকারী প্রযোজক
                                  </label>
                                </div>
                              </div>
                              <div className="col-md-2 col-12">
                                <div className="form-check mx-3">
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    name="praptirUtso"
                                    id="praptirUtso3"
                                    checked={values.source === 3}
                                    onClick={() => handleSourceChange(3)}
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="praptirUtso3"
                                  >
                                    উত্তরাধিকার
                                  </label>
                                </div>
                              </div>
                              <div className="col-md-2 col-12">
                                <div className="form-check mx-3">
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    name="praptirUtso"
                                    id="praptirUtso4"
                                    checked={values.source === 4}
                                    onClick={() => handleSourceChange(4)}
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="praptirUtso4"
                                  >
                                    ক্রয়সূত্রে
                                  </label>
                                </div>
                              </div>
                              <div className="col-md-2 col-12">
                                <div className="form-check mx-3">
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    name="praptirUtso"
                                    id="praptirUtso5"
                                    checked={values.source === 5}
                                    onClick={() => handleSourceChange(5)}
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="praptirUtso5"
                                  >
                                    সম্পাদনকারী
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="add2-section-9 mt-3">
                      <div className="row">
                        <div className="col-md-12">
                          <div className={styles.add2sectiontitle}>
                            <h5>
                              <b>
                                ৭. যে নির্দিষ্ট স্বত্বের অধিকার প্রদান করা হয়েছে
                                (প্রযোজ্য ক্ষেত্রে)
                              </b>
                            </h5>
                          </div>
                          <div className={styles.add2panelbackground}>
                            <div className="row">
                              <div className="col-md-12">
                                <div className="form-group mb-2">
                                  <ClientFormikFormFieldMultiple
                                    value={values.onwer}
                                    name={`onwer`}
                                    errors={errors?.onwer && errors?.onwer}
                                    touched={touched?.onwer && touched?.onwer}
                                    handleChange={handleChange}
                                    handleBlur={handleBlur}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-6">
                        <div className="add2-section-10 mt-3">
                          <div className="row">
                            <div className="col-md-12">
                              <div className={styles.add2sectiontitle}>
                                <h5>
                                  <b>৮. বিনিময় মূল্য</b>
                                </h5>
                              </div>
                              <div className={styles.add2panelbackground}>
                                <div className="row">
                                  <div className="col-md-12">
                                    <div className="form-group mb-2">
                                      <ClientFormikFormFieldMultiple
                                        value={values.exchangerate}
                                        name={`exchangerate`}
                                        errors={
                                          errors?.exchangerate &&
                                          errors?.exchangerate
                                        }
                                        touched={
                                          touched?.exchangerate &&
                                          touched?.exchangerate
                                        }
                                        handleChange={handleChange}
                                        handleBlur={handleBlur}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="add2-section-11 mt-3">
                          <div className="row">
                            <div className="col-md-12">
                              <div className={styles.add2sectiontitle}>
                                <h5>
                                  <b>৯. দলিল / লাইসেন্সের মেয়াদ</b>
                                </h5>
                              </div>
                              <div className={styles.add2panelbackground}>
                                <div className="row">
                                  <div className="col-md-12">
                                    <div className="form-group mb-2">
                                      <ClientFormikFormFieldMultiple
                                        placeholder="-- বছর"
                                        value={values.documentexpire}
                                        name={`documentexpire`}
                                        errors={
                                          errors?.documentexpire &&
                                          errors?.documentexpire
                                        }
                                        touched={
                                          touched?.documentexpire &&
                                          touched?.documentexpire
                                        }
                                        handleChange={handleChange}
                                        handleBlur={handleBlur}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-6">
                        <div className="add2-section-12 mt-3">
                          <div className="row">
                            <div className="col-md-13">
                              <div className={styles.add2sectiontitle}>
                                <h5>
                                  <b>১০. দলিল সম্পাদনের তারিখ</b>
                                </h5>
                              </div>
                              <div className={styles.add2panelbackground}>
                                <div className="row">
                                  <div className="col-md-12">
                                    <div className="form-group mb-2">
                                      <BanglaCalender
                                        placeholder={"তারিখ"}
                                        value={values.date}
                                        onChange={(date) => {
                                          setValues((prevValues) => ({
                                            ...prevValues,
                                            date: globalDateFormat(date),
                                          }));
                                        }}
                                      />
                                      <div>
                                        {" "}
                                        {errors?.date && touched?.date && (
                                          <small className="my-2 text-danger">
                                            {errors?.date}
                                          </small>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="add2-section-14 mt-3">
                          <div className="row">
                            <div className="col-md-12">
                              <div className={styles.add2sectiontitle}>
                                <h5>
                                  <b>১১ . রয়্যালিটির পরিমাণ</b>
                                </h5>
                              </div>
                              <div className={styles.add2panelbackground}>
                                <div className="row">
                                  <div className="col-md-12">
                                    <div className="form-group mb-2">
                                      <ClientFormikFormFieldMultiple
                                        value={values.quantity}
                                        name={`quantity`}
                                        errors={
                                          errors?.quantity && errors?.quantity
                                        }
                                        touched={
                                          touched?.quantity && touched?.quantity
                                        }
                                        handleChange={handleChange}
                                        handleBlur={handleBlur}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-6">
                        <div className="add2-section-15 mt-3">
                          <div className="row">
                            <div className="col-md-12">
                              <div className={styles.add2sectiontitle}>
                                <h5>
                                  <b>১২. স্বত্ব নিয়োগের ভৌগোলিক পরিধি</b>
                                </h5>
                              </div>
                              <div className={styles.add2panelbackground}>
                                <div className="row">
                                  <div className="col-md-12">
                                    <div className="form-group mb-2">
                                      <ClientFormikFormFieldMultiple
                                        value={values.geoperimeter}
                                        name={`geoperimeter`}
                                        errors={
                                          errors?.geoperimeter &&
                                          errors?.geoperimeter
                                        }
                                        touched={
                                          touched?.geoperimeter &&
                                          touched?.geoperimeter
                                        }
                                        handleChange={handleChange}
                                        handleBlur={handleBlur}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="add2-section-16 mt-3">
                          <div className="row">
                            <div className="col-md-12">
                              <div className={styles.add2sectiontitle}>
                                <h5>
                                  <b>
                                    ১৩. পুনঃপরীক্ষণ, বর্ধিতকরণ ও বন্ধকরণ
                                    (প্রযোজ্য নয়)
                                  </b>
                                </h5>
                              </div>
                              <div className={styles.add2panelbackground}>
                                <div className="row">
                                  <div className="col-md-12">
                                    <div className="form-group mb-2">
                                      <ClientFormikFormFieldMultiple
                                        value={values.extensions}
                                        name={`extensions`}
                                        errors={
                                          errors?.extensions &&
                                          errors?.extensions
                                        }
                                        touched={
                                          touched?.extensions &&
                                          touched?.extensions
                                        }
                                        handleChange={handleChange}
                                        handleBlur={handleBlur}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="submit-area mt-2">
                      <div className="d-flex gap-3 justify-content-center">
                        <buttton
                          className="custombtn5"
                          type="submit"
                          onClick={handleSubmit}
                          disabled={isLoadingUpdate}
                        >
                          {isLoadingUpdate ? (
                            <BeatLoader color="#fff" />
                          ) : (
                            "দাখিল করুন"
                          )}
                        </buttton>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
