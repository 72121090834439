import React, { memo, useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import { Typography, Box } from "@mui/material";
import { useUpdateContactsMutation } from "../../../redux/api/contactsApiSlice";

const ViewData = ({ onHide, showData, ...props }) => {
  const [updateSeen] = useUpdateContactsMutation();

  useEffect(() => {
    if (showData?.read_unread !== 1) {
      updateSeen({
        formData: { _method: "PUT" },
        id: showData?.id,
      });
    }
  }, [showData?.read_unread]);
  return (
    <Modal
      {...props}
      onHide={onHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header style={{ display: "flex", justifyContent: "center" }}>
        <Modal.Title id="contained-modal-title-vcenter">
          <Typography variant="h5" component="h2" className="fw-bold">
            যোগাযোগের বিস্তারিত
          </Typography>
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Box p={3}>
          <Typography className="fw-bold">
            নাম: <span className="fw-normal"> {showData?.name}</span>
          </Typography>
          <Typography className="fw-bold">
            ফোন নম্বর: <span className="fw-normal"> {showData?.number}</span>
          </Typography>
          <Typography className="fw-bold">
            তারিখ: <span className="fw-normal"> {showData?.date}</span>
          </Typography>
          <Typography className="fw-bold">
            ইমেইল: <span className="fw-normal"> {showData?.email}</span>
          </Typography>
          <Typography className="fw-bold">
            বিষয়: <span className="fw-normal"> {showData?.subject}</span>
          </Typography>
          <Typography className="fw-bold">
            বার্তা: <span className="fw-normal"> {showData?.message}</span>
          </Typography>
        </Box>
      </Modal.Body>

      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          বন্ধ করুন
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default memo(ViewData);
