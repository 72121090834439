import React from "react";
import SubmittedCopyright from "../sumitted_copyright/SubmittedCopyright";

const HearingCopyright = () => {
  const fileStatus = 10;
  const applicationPath = {
    copyright: "/admin/hearing-copyright",
    agreement: "/admin/hearing-agreement",
  };
  const detailsRoute = "/admin/hearing-copyright-details";
  const pageTitle  = "শুনানীর চিঠি";
  return (
    <SubmittedCopyright
      fileStatus={fileStatus}
      applicationPath={applicationPath}
      detailsRoute={detailsRoute}
      pageTitle={pageTitle}
    />
  );
};

export default HearingCopyright;
