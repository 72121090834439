import React, { useEffect, useRef, useState } from "react";
import { useTextToggle } from "../hooks/useTextToggle";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import styles from "./CommentCell.module.css"; // Import CSS module

const CommentCell = ({ text, maxLength }) => {
  const { displayText, isExpanded, toggleExpansion, shouldTruncate } =
    useTextToggle(text, maxLength);

  const [contentHeight, setContentHeight] = useState(0);
  const contentRef = useRef(null);

  useEffect(() => {
    if (contentRef.current) {
      setContentHeight(contentRef.current.scrollHeight);
    }
  }, [text, maxLength, isExpanded]);
  return (
    <div>
      <div
        ref={contentRef}
        className={`${styles.textContainer} ${
          isExpanded ? styles.slideIn : styles.slideUp
        }`}
        style={{
          maxHeight: `${contentHeight}px`,
        }}
      >
        <span dangerouslySetInnerHTML={{ __html: displayText }}></span>
      </div>
      <div className={`${isExpanded ? styles.slideIn : styles.slideUp}`}>
        {shouldTruncate && (
          <small className={styles.toggleIcon} onClick={toggleExpansion}>
            {isExpanded ? <KeyboardArrowUpIcon /> : <ExpandMoreIcon />}
          </small>
        )}
      </div>
    </div>
  );
};

export default CommentCell;

// import React from "react";
// import { useTextToggle } from "../hooks/useTextToggle";
// import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
// import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

// const CommentCell = ({ text, maxLength }) => {
//   const { displayText, isExpanded, toggleExpansion, shouldTruncate } =
//     useTextToggle(text, maxLength);

//   const toggleText = isExpanded ? (
//     <small
//       className="border rounded px-2"
//       style={{ cursor: "pointer", color: "blue", background: "#e3e3e3" }}
//     >
//       <KeyboardArrowUpIcon style={{ cursor: "pointer", color: "blue" }} />
//     </small>
//   ) : shouldTruncate ? (
//     <small
//       className="border rounded px-2"
//       style={{ cursor: "pointer", color: "blue", background: "#e3e3e3" }}
//     >
//       <ExpandMoreIcon style={{ cursor: "pointer", color: "blue" }} />
//     </small>
//   ) : (
//     ""
//   );

//   return (
//     <div onClick={toggleExpansion}>
//       <span>{displayText}</span>

//       {toggleText}
//     </div>
//   );
// };

// export default CommentCell;
