import React from "react";
import AgreementFormPreview from "../preview_applications/full-form-preview-copyright/AgreementFormPreview";
import Loader from "../custom/Loader";
import { useShowAgreementFullFormQuery } from "../../redux/api/submittedCopyrightAgreementApiSlice";

const AdminFullFormAgreement = ({ info, printRef }) => {
  const { id, trackReg } = info;
  const { data, isLoading } = useShowAgreementFullFormQuery({
    id,
    trackReg,
  });

  if (isLoading) {
    return <Loader />;
  }

  return (
    <div className="slideUp">
      <AgreementFormPreview printRef={printRef} data={data} />{" "}
    </div>
  );
};

export default AdminFullFormAgreement;
