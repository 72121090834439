import React from "react";
import { Link } from "react-router-dom";
import { useGetCopyrightOfficeQuery } from "../../redux/api/copyrightOfficeApiSlice";
import Loader from "../../components/custom/Loader";

export default function About() {
  const { data, isLoading } = useGetCopyrightOfficeQuery();
  const copyrightOffice = data?.data;
  if (isLoading) {
    return <Loader />;
  }

  return (
    <div>
      <div className="breadcrumbs" data-stellar-background-ratio="0.5">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-6 col-12">
              <div className="breadcrumbs-content left">
                <h1 className="page-title">কপিরাইট অফিসের সংক্ষিপ্ত পরিচিতি</h1>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-12">
              <div className="breadcrumbs-content right">
                <ul className="breadcrumb-nav">
                  <li>
                    <Link
                      to="/"
                      style={{
                        fontSize: 20,
                      }}
                    >
                      হোম পেইজ
                    </Link>
                  </li>
                  <li
                    style={{
                      fontSize: 20,
                    }}
                  >
                    কপিরাইট অফিস
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <section className="section pt-5 mb-5 pb-0 service-single">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-12">
              <div className="service-details">
                <div className="service-single-img">
                  <img src="assets/images/Capture.png" alt="#" />
                </div>

                <div className="row">
                  <div className="col-lg-6 px-3 col-md-6 col-12">
                    <div className="content-body">
                      <p
                        style={{
                          fontSize: 18,
                          fontWeight: "bold",
                        }}
                      >
                        {copyrightOffice?.title1}
                      </p>
                      <p
                        style={{
                          fontSize: 17,
                        }}
                      >
                        {copyrightOffice?.description1}
                      </p>{" "}
                      <br />
                    </div>
                  </div>

                  <div className="col-lg-6 col-md-6 col-12">
                    <div className="content-body">
                      <p
                        style={{
                          fontSize: 18,
                          fontWeight: "bold",
                        }}
                      >
                        {copyrightOffice?.title2}
                      </p>
                      <p
                        style={{
                          fontSize: 17,
                        }}
                      >
                        {copyrightOffice?.description2}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
