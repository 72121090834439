import React, { useEffect, useState } from "react";
import {
  useGetCopyrightQuery,
  useGetRelatedRightQuery,
  useUpdateSortingCatalogMutation,
} from "../redux/api/categoryCatalogApiSlice";
import Loader from "./custom/Loader";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import toast from "react-hot-toast";
import { Button } from "react-bootstrap";
import { BeatLoader } from "react-spinners";
import ShorthenComponent from "./ShorthenComponent";

const ShartherDhoron = () => {
  const [type, setType] = useState("copyright");
  const { data: copyright, isLoading: isLoadingCopyright } =
    useGetCopyrightQuery();
  const { data: relatedRight, isLoading: isLoadingRelatedRight } =
    useGetRelatedRightQuery();
  const initialData =
    type === "copyright" ? copyright?.data : relatedRight?.data;

  const handleCheckboxClick = (val) => () => {
    setType(val);
  };
  if (isLoadingRelatedRight || isLoadingCopyright) {
    return <Loader />;
  }

  return (
    <div className="row">
      <div className="col-md-12">
        <p className="card p-2 text-center fw-semibold">
          <div className="d-flex align-items-center gap-4">
            <div className="d-flex align-items-center gap-2">
              <input
                className="form-check-input  m-0"
                type="radio"
                name="add2-checkbox1"
                id="copyright"
                checked={type === "copyright"}
                onChange={handleCheckboxClick("copyright")}
              />
              <label className="pt-1" htmlFor="copyright">
                কপিরাইট
              </label>
            </div>
            <div className="d-flex align-items-center gap-2">
              <input
                className="form-check-input m-0"
                type="radio"
                name="add2-checkbox1"
                id="related"
                checked={type === "related"}
                onChange={handleCheckboxClick("related")}
              />
              <label className="pt-1" htmlFor="related">
                সম্পর্কিত অধিকার
              </label>
            </div>
          </div>
        </p>
      </div>
      <ShorthenComponent initialData={initialData} />
    </div>
  );
};

export default ShartherDhoron;
