import { Typography } from "@mui/material";
import React, { useEffect } from "react";
import { Button } from "react-bootstrap";
import { BeatLoader } from "react-spinners";
import FormikFormField from "../../../components/custom/form/FormikFormField";
import FormikFormSelect from "../../../components/custom/form/FormikFormSelect";
import * as Yup from "yup";
import { Formik, Form } from "formik";
import {
  useCreateAdminAttentionMessageMutation,
  useUpdateAdminAttentionMessageMutation,
} from "../../../redux/api/attentionsMessage";
import toast from "react-hot-toast";
import { transformErrorsToObjectStructure } from "../../../utils/transformErrorsToObjectStructure";

// Validation schema using Yup
const validationSchema = Yup.object({
  title: Yup.string().required("বার্তা প্রযোজ্য"),
  sorting_index: Yup.number().required("ক্রমিক নং প্রযোজ্য"),
  status: Yup.string().required("স্ট্যাটাস প্রযোজ্য"),
});

const EditUpdate = ({ data, setData }) => {
  // For create
  const [create, { isLoading: isLoadingCreate }] =
    useCreateAdminAttentionMessageMutation();

  // For update
  const [update, { isLoading: isLoadingUpdate }] =
    useUpdateAdminAttentionMessageMutation();

  const initialValues = {
    title: data?.title || "",
    sorting_index: data?.sorting_index || "",
    status: data?.status || "",
  };

  return (
    <div className="card p-3">
      <Typography fontWeight="bold">
        বার্তা {data?.id ? "সম্পাদন" : "যোগ"} করুন
      </Typography>
      <hr />
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values, { setErrors, resetForm }) => {
          const submission = data?.id
            ? update({ id: data.id, formData: values })
            : create(values);

          submission
            .unwrap()
            .then(() => {
              // Reset form and show success message
              resetForm();
              setData(null); // Reset the data state
              toast.success(
                data?.id ? "Updated successfully" : "Created successfully"
              );
            })
            .catch((error) => {
              // Handle errors
              if (error.data?.errors) {
                setErrors(transformErrorsToObjectStructure(error.data.errors));
              } else {
                toast.error(error.message || "An error occurred");
              }
            });
        }}
        enableReinitialize={true} // Ensure Formik resets values when initialValues change
      >
        {({ values, handleBlur, handleChange, errors, touched }) => (
          <Form>
            <div className="row">
              <div className="col-md-12">
                <FormikFormField
                  value={values.title}
                  name="title"
                  label="বার্তা"
                  placeholder="বার্তা লিখুন..."
                  errors={errors}
                  touched={touched}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  as="textarea"
                  rows={3}
                  additional={{
                    as: "textarea",
                    rows: 3,
                  }}
                />
              </div>
              <div className="col-md-12">
                <FormikFormField
                  value={values.sorting_index}
                  name="sorting_index"
                  label="ক্রমিক নং"
                  placeholder="ক্রমিক নং"
                  errors={errors}
                  touched={touched}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  type="number"
                />
              </div>
              <div className="col-md-12">
                <FormikFormSelect
                  value={values.status}
                  name="status"
                  label="স্ট্যাটাস"
                  placeholder="স্ট্যাটাস নির্বাচন করুন"
                  errors={errors}
                  touched={touched}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  options={[
                    { label: "Active", value: 1 },
                    { label: "Inactive", value: 0 },
                  ]}
                />
              </div>
              <div className="col-md-12">
                <hr />
                <div className="d-flex justify-content-between align-items-center pt-2">
                  <Button
                    variant="primary"
                    type="submit"
                    style={{ width: "150px" }}
                    disabled={isLoadingCreate || isLoadingUpdate}
                    className="w-100"
                  >
                    {isLoadingCreate || isLoadingUpdate ? (
                      <BeatLoader className="text-white" />
                    ) : data?.id ? (
                      "সম্পাদন করুন"
                    ) : (
                      "দাখিল করুন"
                    )}
                  </Button>
                </div>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default EditUpdate;
