import { useFormik } from "formik";
import React, { useEffect, useRef } from "react";
import { Button, Form } from "react-bootstrap";
import toast from "react-hot-toast";
import { useNavigate, useParams } from "react-router-dom";
import { BeatLoader } from "react-spinners";
import Swal from "sweetalert2";
import * as Yup from "yup";
import { usePrepareEndorseMutation } from "../../redux/api/submittedCopyrightAgreementApiSlice";
import { transformErrorsToObjectStructure } from "../../utils/transformErrorsToObjectStructure";

const FinalApprovedCertificateEndorse = ({ data }) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const printLetter = useRef();
  const [
    submitData,
    {
      isLoading: isLoadingSubmit,
      isSuccess: isSuccessSubmit,
      error: errorSubmit,
      isError: isErrorSubmit,
      data: dataSubmit,
    },
  ] = usePrepareEndorseMutation();

  const initialValues = {
    registration_no: "",
    comments: "",
  };

  const validationSchema = Yup.object().shape({
    registration_no: Yup.string().required("রেজিস্ট্রেশন নম্বর প্রযোজ্য"),
    comments: Yup.string().required("মন্তব্য প্রযোজ্য"),
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      const formData = {
        ...values,
        registration_no: values.registration_no.replace(/\s+/g, ""),
      };
      Swal.fire({
        text: "আপনি কি নিশ্চিত?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "না",
        confirmButtonText: "হ্যাঁ",
      }).then((result) => {
        if (result.isConfirmed) {
          submitData({ id, formData });
        }
      });
    },
  });
  useEffect(() => {
    if (isSuccessSubmit) {
      toast.success(dataSubmit?.message);
      formik.resetForm();
      navigate(-1);
    }
    if (isErrorSubmit) {
      toast.error(errorSubmit?.data?.message);
      formik.setErrors(transformErrorsToObjectStructure(errorSubmit.data?.data || {}));
    }
    // eslint-disable-next-line
  }, [isErrorSubmit, isSuccessSubmit]);

  return (
    <>
      <div className="d-flex justify-content-between align-items-center bg-slate p-2">
        <div className="fw-semibold">চুক্তিপত্রের এন্ডোর্স প্রস্তুত</div>
      </div>

      <form onSubmit={formik.handleSubmit}>
        <div className="py-2" ref={printLetter}>
          <div className="card p-3">
            <div className="row">
              <div className="col-md-12 mb-2">
                <label htmlFor="">চুক্তিপত্রের নিবন্ধন সংখ্যা</label>
                <Form.Control
                  value={formik.values.registration_no}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  errors={formik.errors}
                  touched={formik.touched}
                  name="registration_no"
                />
                {formik.errors.registration_no &&
                  formik.touched.registration_no && (
                    <span className="text-danger">
                      {formik.errors.registration_no}
                    </span>
                  )}
              </div>

              <div className="col-md-12  mb-2">
                <label htmlFor="">চূড়ান্ত মন্তব্য</label>
                <Form.Control
                  placeholder="মন্তব্য"
                  value={formik.values.comments}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  errors={formik.errors}
                  touched={formik.touched}
                  name="comments"
                  as={"textarea"}
                />
                {formik.errors.comments && formik.touched.comments && (
                  <span className="text-danger">{formik.errors.comments}</span>
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="my-4 text-center d-flex justify-content-center gap-3">
          <div className="mr-3">
            <Button variant="success" type="submit" disabled={isLoadingSubmit}>
              {isLoadingSubmit ? <BeatLoader /> : "আবেদনকারীকে বার্তা পাঠান"}
            </Button>
          </div>
        </div>
      </form>
    </>
  );
};

export default FinalApprovedCertificateEndorse;
