import React from "react";
import SubmittedCopyright from "../sumitted_copyright/SubmittedCopyright";

const LetterCorrectionCopyright = () => {
  const fileStatus = 7;
  const applicationPath = {
    copyright: "/admin/letter-of-correction-copyright",
    agreement: "/admin/letter-of-correction-agreement",
  };
  const detailsRoute = "/admin/letter-of-correction-copyright-details";
  const pageTitle  = "সংশোধনের চিঠি";
  return (
    <SubmittedCopyright
      fileStatus={fileStatus}
      applicationPath={applicationPath}
      detailsRoute={detailsRoute}
      pageTitle={pageTitle}
    />
  );
};

export default LetterCorrectionCopyright;
