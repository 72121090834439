import { Download, Print } from "@mui/icons-material";
import React, { useRef, useState } from "react";
import ReactToPrint from "react-to-print";
import CopyrightPreviewFormOne from "../copyright/CopyrightPreviewFormOne";
import CopyrightPreviewFormTwo from "../copyright/CopyrightPreviewFormTwo";
import ShowFilePreviewModal from "../../../pages/frontend/userPanel/form_two/ShowPreviewFiles";
import PaymentPreview from "../payment/PaymentPreview";

import jsPDF from "jspdf";
import html2canvas from "html2canvas";

const CopyrightFormPreview = ({ data, printRef }) => {
  const [loading, setLoading] = useState(false);
  const [previewModal, setPreviewModal] = useState(false);
  const [previewFile, setPreviewFile] = useState(null);
  const printSectionRef = useRef();
  const applicationFormOne = data?.data;
  const applicationFormTwo = data?.data?.profileDetail;

  // const generatePdf = () => {
  //   const input = document.getElementById("pdf-content");

  //   html2canvas(input, { scale: 2, useCORS:true }) // Increase the scale to get a higher quality image
  //     .then((canvas) => {
  //       const imgData = canvas.toDataURL("image/png");
  //       const pdf = new jsPDF("p", "mm", "a4"); // Adjust page size as needed
  //       const imgWidth = 210; // A4 width in mm
  //       const pageHeight = 295; // A4 height in mm
  //       const imgHeight = (canvas.height * imgWidth) / canvas.width;
  //       let heightLeft = imgHeight;
  //       let position = 0;

  //       pdf.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
  //       heightLeft -= pageHeight;

  //       while (heightLeft >= 0) {
  //         position = heightLeft - imgHeight;
  //         pdf.addPage();
  //         pdf.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
  //         heightLeft -= pageHeight;
  //       }
  //       pdf.save("download.pdf");
  //     })
  //     .catch((error) => {
  //       console.error("Error generating PDF: ", error);
  //     });
  // };

  const generatePdf = () => {
    setLoading(true);
    const input = document.getElementById("pdf-content");

    html2canvas(input, { scale: 2 })
      .then((canvas) => {
        const imgData = canvas.toDataURL("image/png");
        const pdf = new jsPDF("p", "mm", "a4");
        const pageWidth = 210;
        const pageHeight = 297;
        const margin = 8;
        const imgWidth = pageWidth - 2 * margin;
        const imgHeight = (canvas.height * imgWidth) / canvas.width;
        let heightLeft = imgHeight;
        let position = 0;

        // Add the first page with content and margins
        pdf.addImage(
          imgData,
          "PNG",
          margin,
          margin + position,
          imgWidth,
          imgHeight
        );
        heightLeft -= pageHeight - 2 * margin;

        // Add remaining pages if content overflows
        while (heightLeft > 0) {
          position = heightLeft - imgHeight;
          pdf.addPage();
          pdf.addImage(
            imgData,
            "PNG",
            margin,
            margin + position,
            imgWidth,
            imgHeight
          );
          heightLeft -= pageHeight - 2 * margin;
        }

        // Save the PDF
        pdf.save("download.pdf");
      })
      .catch((error) => {
        setLoading(false);
        console.error("Error generating PDF: ", error);
      });
  };

  return (
    <div>
      <section className="section chuktipotro2 pt-4 py-5 service-single">
        {!printRef && (
          <div className="container">
            <div className="row">
              <div className="col-lg-10 mb-2 attentionmessage1">
                <p className="mb-0 border attentionmessage text-justify">
                  আমি / আমরা ঘোষণা করছি যে, কপিরাইট রেজিস্ট্রেশনের আবেদনে আমার
                  পূরণকৃত সকল তথ্য ও সংযুক্ত দলিলসমূহ সত্য ও নির্ভুল এবং এ
                  কর্মটিকে কেন্দ্র করে কোন মামলা কোন বিজ্ঞ আদালত বা
                  ট্রাইব্যুনালে বিচারাধীন নেই । উল্লিখিত তথ্য বা দলিলে কোন ভুল ও
                  অসত্য তথ্য উদ্‌ঘাটিত হলে আমি / আমরা এর জন্য ব্যক্তিগতভাবে দায়ী
                  থাকব এবং দেশের প্রচলিত আইন অনুযায়ী যেকোন দন্ড গ্রহণে বাধ্য হবো
                  ।
                </p>
              </div>

              <div className="col-lg-2">
                <div className="row">
                  <div className="col-md-12">
                    <ReactToPrint
                      trigger={() => (
                        <button className="custombtn555">
                          <Print /> প্রিন্ট করুন
                        </button>
                      )}
                      content={() => printSectionRef.current}
                    />
                  </div>
                  <div className="col-md-12">
                    <button className="custombtn5555" onClick={generatePdf}>
                      <Download /> ডাউনলোড করুন
                    </button>
                  </div>

                  {loading && <div className="loading">Generating PDF...</div>}
                </div>
              </div>
            </div>
          </div>
        )}

        <div
          id="pdf-content"
          className={`container mt-4 fullformprint printsection`}
          ref={printRef || printSectionRef}
        >
          <div className={`${printRef ? "show-print" : ""}`}>
            <div className="border printborder">
              <div className="d-flex my-2 justify-content-center printborder borderbottomm align-items-center">
                <div className="card w-100">
                  <CopyrightPreviewFormOne data={applicationFormOne} />
                </div>
              </div>

              <div className="d-flex mt-2 pagebreak borderbottomm paddingbocfttom justify-content-center align-items-center">
                <div className="card w-100">
                  <CopyrightPreviewFormTwo data={applicationFormTwo} />
                </div>
              </div>

              <div className="d-flex my-4 justify-content-center pagebreak printborder">
                <div className="card w-100 mx-4">
                  <PaymentPreview data={applicationFormOne} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <ShowFilePreviewModal
        setPreviewModal={setPreviewModal}
        previewModal={previewModal}
        previewUrl={previewFile}
        setPreviewUrl={setPreviewFile}
      />
    </div>
  );
};

export default CopyrightFormPreview;
