import React, { useEffect, useRef } from "react";
import {
  useDeleteCertificateLanguageMutation,
  useGetAttentionMessagesQuery,
  useHearingScheduleSentMutation,
  usePrepareCertificateMutation,
} from "../../redux/api/submittedCopyrightAgreementApiSlice";
import Swal from "sweetalert2";
import { useNavigate, useParams } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Button, Form } from "react-bootstrap";
import toast from "react-hot-toast";
import { globalDateFormat } from "../../utils/dateFormat";
import { useAuth } from "../../utils/authorization";
import ClientFormikYearSelector from "../custom/form/year/ClientFormikYearSelector";
import BanglaCalender from "../../pages/frontend/userPanel/form_two/BanglaCalender";
import { ImPlus } from "react-icons/im";
import { IoCloseSharp } from "react-icons/io5";
import { BeatLoader } from "react-spinners";
import { transformErrorsToObjectStructure } from "../../utils/transformErrorsToObjectStructure";

const FinalApproveCertificateForm = ({ data }) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const printLetter = useRef();
  const [
    submitData,
    {
      isLoading: isLoadingSubmit,
      isSuccess: isSuccessSubmit,
      error: errorSubmit,
      isError: isErrorSubmit,
      data: dataSubmit,
    },
  ] = usePrepareCertificateMutation();

  // delete the certificate language
  const [deleteCertificate] = useDeleteCertificateLanguageMutation();

  const { data: attentionMessage } = useGetAttentionMessagesQuery();

  const pd = data?.profileDetail;

  const initialValues = {
    certificate_type: Number(pd?.selfType) !== 2 ? 1 : 3 || "",
    certificate: Number(pd?.certificate) || "",
    work_title: pd?.workTitle || "",
    certificatelanguages: (pd?.certificateLangs.length > 0 &&
      pd?.certificateLangs) || [
      {
        author_creator: "",
        owner_right: "",
        address: "",
      },
    ],
    pub_unpub: Number(pd?.pub_unpub) || "",
    pub_unpub_year: pd?.pub_unpub_year || "",
    registration_no: "",
    expiry_date: "",
    expiry_year: "",
    cw_proportion: "",
    tc_licence: "",
    creative_work: "",
    ownergain: "",
    comments: "",
    attention: [],
  };

  const validationSchema = Yup.object().shape({
    certificate_type: Yup.number()
      .required("সার্টিফিকেটের ধরণ নির্বাচন করুন")
      .oneOf([1, 2, 3, 4]),
    certificate: Yup.number()
      .required("সার্টিফিকেট নির্বাচন করুন")
      .oneOf([1, 2]),
    work_title: Yup.string().when("certificate", {
      is: (val) => val === 2,
      then: () =>
        Yup.string()
          .required("ওয়ার্ক টাইটেল প্রযোজ্য")
          .min(2, "অন্তত ২ অক্ষর হতে হবে"),
    }),

    certificatelanguages: Yup.array().of(
      Yup.object().shape({
        author_creator: Yup.string().when(["$certificate"], {
          is: (val) => val === 2,
          then: () =>
            Yup.string()
              .required("লেখকের নাম প্রযোজ্য")
              .min(2, "অন্তত ২ অক্ষর হতে হবে"),
          otherwise: () => Yup.string().nullable(),
        }),

        owner_right: Yup.string().when(["$certificate"], {
          is: (val) => val === 2, // ইংরেজি
          then: () =>
            Yup.string()
              .required("অধিকারের নাম প্রযোজ্য")
              .min(2, "অন্তত ২ অক্ষর হতে হবে"),
          otherwise: () => Yup.string().nullable(),
        }),
        address: Yup.string().when(["$certificate"], {
          is: (val) => val === 2, // ইংরেজি
          then: () =>
            Yup.string()
              .required("ঠিকানা প্রযোজ্য")
              .min(2, "অন্তত ২ অক্ষর হতে হবে"),
          otherwise: () => Yup.string().nullable(),
        }),
      })
    ),
    pub_unpub: Yup.number().when("certificate", {
      is: (val) => val === 2, // ইংরেজি
      then: () =>
        Yup.number().required("প্রকাশনার অবস্থা নির্বাচন করুন").oneOf([1, 2]),
    }),
    pub_unpub_year: Yup.string().when("certificate", {
      is: (val) => val === 2, // ইংরেজি
      then: () =>
        Yup.string()
          .required("প্রকাশনার বছর প্রযোজ্য")
          .matches(/^[0-9]{4}$/, "সঠিক বছর প্রযোজ্য"),
    }),
    registration_no: Yup.string().required("রেজিস্ট্রেশন নম্বর প্রযোজ্য"),

    expiry_date: Yup.string()
      .matches(/^\d{4}-\d{2}-\d{2}$/, "সঠিক তারিখ প্রযোজ্য")
      .test(
        "expiry-date-or-year",
        "মেয়াদ শেষের তারিখ প্রযোজ্য",
        function (value) {
          const { expiry_year } = this.parent;
          return value || expiry_year;
        }
      ),
    expiry_year: Yup.string().test(
      "expiry-year-or-date",
      "বছর প্রযোজ্য",
      function (value) {
        const { expiry_date } = this.parent;
        return value || expiry_date;
      }
    ),

    cw_proportion: Yup.string().when("certificate", {
      is: (val) => val === 1, // বাংলা
      then: () =>
        Yup.string()
          .required("সৃজনশীল কাজের অনুপাত প্রযোজ্য")
          .min(1, "অন্তত ১ অক্ষর হতে হবে"),
    }),
    ownergain: Yup.string().required("ওনার গেইন প্রযোজ্য"),
    creative_work: Yup.string().when("certificate", {
      is: (val) => val === 2, // ইংরেজি
      then: () =>
        Yup.string()
          .required("সৃজনশীল কাজের বিবরণ প্রযোজ্য")
          .min(2, "অন্তত ২ অক্ষর হতে হবে"),
    }),
    tc_licence: Yup.string().when("certificate_type", {
      is: (val) => val === 2, // লাইসেন্স
      then: () =>
        Yup.string()
          .required("টিসি লাইসেন্স প্রযোজ্য")
          .min(2, "অন্তত ২ অক্ষর হতে হবে"),
    }),
    comments: Yup.string().required("মন্তব্য প্রযোজ্য"),
    attention: Yup.array().nullable(),
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      const formData = {
        ...values,
        registration_no: values.registration_no.replace(/\s+/g, ""),
      };
      Swal.fire({
        text: "আপনি কি নিশ্চিত?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "না",
        confirmButtonText: "হ্যাঁ",
      }).then((result) => {
        if (result.isConfirmed) {
          submitData({ id, formData });
        }
      });
    },
  });
  useEffect(() => {
    if (isSuccessSubmit) {
      toast.success(dataSubmit?.message);
      formik.resetForm();
      navigate(-1);
    }
    if (isErrorSubmit) {
      toast.error(errorSubmit?.data?.message);
      formik.setErrors(
        transformErrorsToObjectStructure(errorSubmit.data?.data || {})
      );
    }
    // eslint-disable-next-line
  }, [isErrorSubmit, isSuccessSubmit]);

  const certificateType = [
    { label: "পার্মানেন্ট", value: 1 },
    { label: "লাইসেন্স", value: 2 },
    { label: "রিলেটেড রাইট", value: 3 },
    { label: "ব্রডকাস্টিং", value: 4 },
  ];

  const lang = [
    { label: "বাংলা", value: 1 },
    { label: "ইংরেজি", value: 2 },
  ];

  const pub = [
    { label: "Published", value: 1 },
    { label: "Unpublished", value: 2 },
  ];

  // handle add
  const handleAdd = () => {
    formik.setValues((prev) => ({
      ...prev,
      certificatelanguages: [
        ...prev.certificatelanguages,
        {
          author_creator: "",
          owner_right: "",
          address: "",
        },
      ],
    }));
  };

  // handle remove
  const handleRemove = (index, idCir) => {
    console.log(index, idCir);
    if (idCir) {
      Swal.fire({
        text: "আপনি কি নিশ্চিত?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "না",
        confirmButtonText: "হ্যাঁ",
      }).then((result) => {
        if (result.isConfirmed) {
          deleteCertificate({ id1: pd?.id, id2: idCir }).then((result) => {
            formik.setValues((prev) => ({
              ...prev,
              certificatelanguages: prev.certificatelanguages.filter(
                (_, i) => i !== index
              ),
            }));
          });
        }
      });
    } else {
      formik.setValues((prev) => ({
        ...prev,
        certificatelanguages: prev.certificatelanguages.filter(
          (_, i) => i !== index
        ),
      }));
    }
  };

  return (
    <>
      <div className="d-flex justify-content-between align-items-center bg-slate p-2">
        <div className="fw-semibold">কপিরাইট নিবন্ধন সনদ প্রস্তুত</div>
      </div>

      <form onSubmit={formik.handleSubmit}>
        <div className="py-2" ref={printLetter}>
          <div className="card p-3">
            <div className="d-flex align-items-center flex-wrap gap-3 mb-2">
              <span className="fw-semibold">সার্টিফিকেটের ধরণঃ</span>{" "}
              {certificateType.map((type, i) => (
                <Form.Check
                  className="mr-3"
                  key={i}
                  type="radio"
                  label={type.label}
                  value={type.value}
                  id={`default-${type.label}`}
                  onChange={() =>
                    formik.setValues((prev) => ({
                      ...prev,
                      certificate_type: type.value,
                    }))
                  }
                  checked={formik.values.certificate_type === type.value}
                />
              ))}
            </div>
            <div className="d-flex align-items-center gap-3 mb-3">
              <span className="fw-semibold"> সার্টিফিকেটের ভাষাঃ</span>
              {lang.map((type, i) => (
                <Form.Check
                  type={"radio"}
                  label={`${type.label}`}
                  value={type.value}
                  id={`default-${type.label}`}
                  onChange={() =>
                    formik.setValues((prev) => ({
                      ...prev,
                      certificate: type.value,
                    }))
                  }
                  checked={formik.values.certificate === type.value}
                />
              ))}
            </div>
            {formik.values.certificate === 2 && (
              <>
                <div className="py-3">
                  <div className="mb-3">
                    <span className="fw-semibold">Title of the work</span>
                    <Form.Control
                      value={formik.values.work_title}
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      errors={formik.errors}
                      touched={formik.touched}
                      name="work_title"
                    />
                    {formik.errors.work_title && formik.touched.work_title && (
                      <span className="text-danger">
                        {formik.errors.work_title}
                      </span>
                    )}
                  </div>
                  {formik.values.certificatelanguages.map((item, i) => (
                    <div className="d-flex align-items-center">
                      <div className="row" key={i}>
                        <div className="col-md-4">
                          <label className="fw-semibold">
                            Author / Creator
                          </label>
                          <Form.Control
                            value={
                              formik.values.certificatelanguages[i]
                                .author_creator || ""
                            }
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            name={`certificatelanguages[${i}].author_creator`}
                            isInvalid={
                              formik.touched.certificatelanguages?.[i]
                                ?.author_creator &&
                              formik.errors.certificatelanguages?.[i]
                                ?.author_creator
                            }
                          />
                          {formik.touched.certificatelanguages?.[i]
                            ?.author_creator &&
                            formik.errors.certificatelanguages?.[i]
                              ?.author_creator && (
                              <span className="text-danger">
                                {
                                  formik.errors.certificatelanguages?.[i]
                                    ?.author_creator
                                }
                              </span>
                            )}
                        </div>
                        <div className="col-md-4">
                          <label className="fw-semibold">
                            Owner of the rights
                          </label>
                          <Form.Control
                            value={
                              formik.values.certificatelanguages[i]
                                .owner_right || ""
                            }
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            name={`certificatelanguages[${i}].owner_right`}
                            isInvalid={
                              formik.touched.certificatelanguages?.[i]
                                ?.owner_right &&
                              formik.errors.certificatelanguages?.[i]
                                ?.owner_right
                            }
                          />
                          {formik.touched.certificatelanguages?.[i]
                            ?.owner_right &&
                            formik.errors.certificatelanguages?.[i]
                              ?.owner_right && (
                              <span className="text-danger">
                                {
                                  formik.errors.certificatelanguages?.[i]
                                    ?.owner_right
                                }
                              </span>
                            )}
                        </div>
                        <div className="col-md-4">
                          <label className="fw-semibold">Address</label>
                          <Form.Control
                            value={
                              formik.values.certificatelanguages[i].address ||
                              ""
                            }
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            name={`certificatelanguages[${i}].address`}
                            isInvalid={
                              formik.touched.certificatelanguages?.[i]
                                ?.address &&
                              formik.errors.certificatelanguages?.[i]?.address
                            }
                          />
                          {formik.touched.certificatelanguages?.[i]?.address &&
                            formik.errors.certificatelanguages?.[i]
                              ?.address && (
                              <span className="text-danger">
                                {
                                  formik.errors.certificatelanguages?.[i]
                                    ?.address
                                }
                              </span>
                            )}
                        </div>
                      </div>
                      <div className="p-2">
                        <div
                          style={{
                            marginTop: "5px",
                            width: "20px",
                            textAlign: "center",
                          }}
                        >
                          {formik.values.certificatelanguages.length > 1 &&
                            i !== 0 && (
                              <IoCloseSharp
                                className="text-danger fw-bold cursor-pointer"
                                onClick={() => handleRemove(i, item?.id)}
                                size="24"
                              />
                            )}
                          {(formik.values.certificatelanguages.length ===
                            i + 1 ||
                            formik.values.certificatelanguages.length ===
                              1) && (
                            <ImPlus
                              className="cursor-pointer"
                              onClick={handleAdd}
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>{" "}
              </>
            )}

            {formik.values.certificate === 2 && (
              <>
                <div
                  className="rounded mb-4"
                  style={{
                    background: "#f4f4f4",
                    padding: "10px",
                  }}
                >
                  <div className="row">
                    <div className="col-md-12">
                      <label htmlFor="">Date of publication</label>
                    </div>
                    <div className="col-md-6 mt-2">
                      <div className="d-flex align-items-center flex-wrap gap-3">
                        {pub.map((type, i) => (
                          <Form.Check
                            type={"radio"}
                            label={`${type.label}`}
                            value={type.value}
                            id={`default-${type.label}`}
                            onChange={() =>
                              formik.setValues((prev) => ({
                                ...prev,
                                pub_unpub: type.value,
                              }))
                            }
                            checked={formik.values.pub_unpub === type.value}
                          />
                        ))}
                      </div>
                    </div>
                    <div className="col-md-6 mb-2">
                      <ClientFormikYearSelector
                        value={formik.values?.pub_unpub_year}
                        name={`pub_unpub_year`}
                        errors={formik.errors?.pub_unpub_year}
                        touched={formik.touched?.pub_unpub_year}
                        handleChange={formik.handleChange}
                        handleBlur={formik.handleBlur}
                      />
                    </div>
                  </div>
                </div>
              </>
            )}

            <div className="row">
              <div className="col-md-6 mb-2">
                <label htmlFor="">রেজিস্ট্রেশন নম্বর</label>
                <Form.Control
                  value={formik.values.registration_no}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  errors={formik.errors}
                  touched={formik.touched}
                  name="registration_no"
                />
                {formik.errors.registration_no &&
                  formik.touched.registration_no && (
                    <span className="text-danger">
                      {formik.errors.registration_no}
                    </span>
                  )}
              </div>
              <div className="col-md-6 mb-2">
                <label htmlFor="">মেয়াদ উত্তীর্ণের তারিখ</label>
                <BanglaCalender
                  value={formik.values?.expiry_date}
                  onChange={(date) => {
                    formik.setValues((prevValues) => ({
                      ...prevValues,
                      expiry_date: globalDateFormat(date),
                    }));
                  }}
                />
                {formik.errors.expiry_date && (
                  <span className="text-danger">
                    {formik.errors.expiry_date}
                  </span>
                )}
              </div>
              <div className="col-md-12 mb-2">
                <label htmlFor="">মেয়াদ উত্তীর্ণের বছর</label>
                <Form.Select
                  className="form-control"
                  value={formik.values?.expiry_year}
                  name={`expiry_year`}
                  errors={formik.errors?.expiry_year}
                  touched={formik.touched?.expiry_year}
                  onChange={formik.handleChange}
                  placeholder="YY"
                  handleBlur={formik.handleBlur}
                  isInvalid={
                    formik.touched.expiry_year && formik.errors.expiry_year
                  }
                >
                  <option value={""}>বছর</option>
                  {Array.from({ length: 60 }).map((_, index) => (
                    <option key={index + 1} value={index + 1}>
                      {index + 1}
                    </option>
                  ))}
                </Form.Select>
                {formik.errors.expiry_year && formik.touched.expiry_year && (
                  <span className="text-danger">
                    {formik.errors.expiry_year}
                  </span>
                )}
              </div>
              {formik.values.certificate === 1 && (
                <div className="col-md-12 mb-2">
                  <label htmlFor="">
                    সৃজনশীল কর্মের স্বত্বাধিকারী (স্বত্বের অনুপাত ) / Creator of
                    the Work
                  </label>
                  <Form.Control
                    value={formik.values.cw_proportion}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    errors={formik.errors}
                    touched={formik.touched}
                    name="cw_proportion"
                  />
                  {formik.errors.cw_proportion &&
                    formik.touched.cw_proportion && (
                      <span className="text-danger">
                        {formik.errors.cw_proportion}
                      </span>
                    )}
                </div>
              )}
              <div className="col-md-12 mb-2">
                <label htmlFor="">
                  স্বত্ব প্রাপ্তির মাধ্যম / Name of Licensor & Authority
                </label>
                <Form.Control
                  value={formik.values.ownergain}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  errors={formik.errors}
                  touched={formik.touched}
                  name="ownergain"
                />
                {formik.errors.ownergain && formik.touched.ownergain && (
                  <span className="text-danger">{formik.errors.ownergain}</span>
                )}
              </div>

              {formik.values.certificate === 2 && (
                <div className="col-md-12 mb-2">
                  <label htmlFor="">
                    Field of the Creative Work / Field of copyright
                  </label>
                  <Form.Control
                    value={formik.values.creative_work}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    errors={formik.errors}
                    touched={formik.touched}
                    name="creative_work"
                  />
                  {formik.errors.creative_work &&
                    formik.touched.creative_work && (
                      <span className="text-danger">
                        {formik.errors.creative_work}
                      </span>
                    )}
                </div>
              )}
              {formik.values.certificate_type === 2 && (
                <div className="col-md-12 mb-2">
                  <label htmlFor="">Type & Conditions of Licence</label>
                  <Form.Control
                    value={formik.values.tc_licence}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    errors={formik.errors}
                    touched={formik.touched}
                    name="tc_licence"
                  />
                  {formik.errors.tc_licence && formik.touched.tc_licence && (
                    <span className="text-danger">
                      {formik.errors.tc_licence}
                    </span>
                  )}
                </div>
              )}
              <div className="col-md-12  mb-2">
                <label htmlFor="">চূড়ান্ত মন্তব্য</label>
                <Form.Control
                  placeholder="মন্তব্য"
                  value={formik.values.comments}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  errors={formik.errors}
                  touched={formik.touched}
                  name="comments"
                  as={"textarea"}
                />
                {formik.errors.comments && formik.touched.comments && (
                  <span className="text-danger">{formik.errors.comments}</span>
                )}
              </div>
            </div>
          </div>
        </div>

        <div
          className="rounded mb-4"
          style={{
            background: "#f4f4f4",
            padding: "10px",
          }}
        >
          <label className="pb-1">এটেনশন বার্তা নির্বাচন করুনঃ</label>
          {attentionMessage?.data.map((item, i) => (
            <Form.Check
              key={i}
              type={"checkbox"}
              label={`${item.title}`}
              value={item.title}
              id={`default-${item.title}`}
              onChange={(e) => {
                const { checked, value } = e.target;
                if (checked) {
                  formik.setValues((prev) => ({
                    ...prev,
                    attention: [...prev.attention, value],
                  }));
                } else {
                  formik.setValues((prev) => ({
                    ...prev,
                    attention: prev.attention.filter((att) => att !== value),
                  }));
                }
              }}
              checked={formik.values.attention.includes(item.title)}
            />
          ))}
        </div>

        <div className="my-4 text-center d-flex justify-content-center gap-3">
          <div className="mr-3">
            <Button variant="success" type="submit" disabled={isLoadingSubmit}>
              {isLoadingSubmit ? <BeatLoader /> : "আবেদনকারীকে বার্তা পাঠান"}
            </Button>
          </div>
        </div>
      </form>
    </>
  );
};

export default FinalApproveCertificateForm;
