import React from "react";
import SubmittedCopyright from "../sumitted_copyright/SubmittedCopyright";

const InvestigationCopyright = () => {
  const pageTitle = "তদন্তের নথি";

  const fileStatus = 14;
  const applicationPath = {
    copyright: "/admin/investigation-copyright",
    agreement: "/admin/investigation-agreement",
  };
  const detailsRoute = "/admin/explanation-copyright-details";
  return (
    <SubmittedCopyright
      fileStatus={fileStatus}
      applicationPath={applicationPath}
      detailsRoute={detailsRoute}
      pageTitle={pageTitle}
    />
  );
};

export default InvestigationCopyright;
