import React from "react";
import SubmittedAgreement from "../../sumitted_agreement/SubmittedAgreement";

const ReplyExplanationAgreement = () => {
  const applicationPath = {
    copyright: "/admin/reply-to-additional-explanation-copyright",
    agreement: "/admin/reply-to-additional-explanation-agreement",
  };
  const pageTitle = "ব্যাখ্যার জবাব";
  
  return (
    <SubmittedAgreement fileStatus={18} applicationPath={applicationPath} pageTitle={pageTitle} />
  );
};

export default ReplyExplanationAgreement;
