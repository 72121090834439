import React, { useState, useEffect } from "react";
import Form from "react-bootstrap/Form";
import { format } from "date-fns";
import { bn } from "date-fns/locale";

const ClientFormikDateSelector = ({
  lang = false,
  yearValue,
  monthValue,
  dayValue,
  yearName,
  monthName,
  dayName,
  errors,
  touched,
  handleChange,
  handleBlur,
  additional,
  required,
}) => {
  const [daysInMonth, setDaysInMonth] = useState([]);

  useEffect(() => {
    if (yearValue && monthValue) {
      const days = new Date(yearValue, monthValue, 0).getDate();
      setDaysInMonth([...Array(days).keys()].map((i) => i + 1));
    } else {
      setDaysInMonth([]);
    }
  }, [yearValue, monthValue]);

  const years = Array.from(
    { length: 100 },
    (_, i) => new Date().getFullYear() - i
  );
  const months = Array.from({ length: 12 }, (_, i) => i + 1);

  const formatInBangla = (number) => {
    return number
      .toString()
      .split("")
      .map((digit) => "০১২৩৪৫৬৭৮৯"[digit])
      .join("");
  };

  return (
    <div className="row">
      <Form.Group controlId={yearName} className="col-md-4">
        <Form.Control
          as="select"
          value={yearValue || ""}
          onChange={handleChange}
          onBlur={handleBlur}
          name={yearName}
          isInvalid={!!errors[yearName] && touched[yearName]}
          isValid={touched[yearName] && !errors[yearName]}
          {...additional}
        >
          <option value="">{lang ? "Year" : "বছর"}</option>
          {years.map((year) => (
            <option key={year} value={year}>
              {lang ? year : formatInBangla(year)}
            </option>
          ))}
        </Form.Control>
        {errors[yearName] && touched[yearName] && (
          <Form.Control.Feedback type="invalid">
            <small>{errors[yearName]}</small>
          </Form.Control.Feedback>
        )}
      </Form.Group>

      <Form.Group controlId={monthName} className="col-md-4">
        <Form.Control
          as="select"
          value={parseInt(monthValue, 10).toString() || ""}
          onChange={handleChange}
          onBlur={handleBlur}
          name={monthName}
          isInvalid={!!errors[monthName] && touched[monthName]}
          isValid={touched[monthName] && !errors[monthName]}
          {...additional}
        >
          <option value="">{lang ? "Month" : "মাস"}</option>
          {months.map((month) => (
            <option key={month} value={month}>
              {lang
                ? month
                : format(new Date(0, month - 1), "MMMM", { locale: bn })}
            </option>
          ))}
        </Form.Control>
        {errors[monthName] && touched[monthName] && (
          <Form.Control.Feedback type="invalid">
            <small>{errors[monthName]}</small>
          </Form.Control.Feedback>
        )}
      </Form.Group>

      <Form.Group controlId={dayName} className="col-md-4">
        <Form.Control
          as="select"
          value={parseInt(dayValue, 10).toString() || ""}
          onChange={handleChange}
          onBlur={handleBlur}
          name={dayName}
          isInvalid={!!errors[dayName] && touched[dayName]}
          isValid={touched[dayName] && !errors[dayName]}
          {...additional}
        >
          <option value="">{lang ? "Day" : "দিন"}</option>
          {daysInMonth.map((day) => (
            <option key={day} value={day}>
              {lang ? day : formatInBangla(day)}
            </option>
          ))}
        </Form.Control>
        {errors[dayName] && touched[dayName] && (
          <Form.Control.Feedback type="invalid">
            <small>{errors[dayName]}</small>
          </Form.Control.Feedback>
        )}
      </Form.Group>
    </div>
  );
};

export default ClientFormikDateSelector;
