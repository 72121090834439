import { CheckCircle } from "@mui/icons-material";
import { useFormik } from "formik";
import React, { memo } from "react";
import { Button, Modal } from "react-bootstrap";
import toast from "react-hot-toast";
import { BeatLoader } from "react-spinners";
import { assets } from "../../../assets";
import {
  useGetPermissionUserMenuQuery,
  useUpdateUserPermissionsMutation,
} from "../../../redux/api/authApiSlice";
import { useFindUserQuery } from "../../../redux/api/userApiSlice";

const ShowUser = ({ onHide, user, ...props }) => {
  const { data: permissions, isLoading: isLoadingPermissions } =
    useGetPermissionUserMenuQuery();
  const [updatePermission, { isLoading }] = useUpdateUserPermissionsMutation();

  const { data: findUser, isFetching } = useFindUserQuery(user?.id);

  const permission = findUser?.data?.permissions?.map((item) => item?.id);

  const initialValues = {
    user_id: user?.id || "",
    permissions: permission || [],
  };

  const { values, setValues, handleSubmit } = useFormik({
    initialValues,
    onSubmit: async (values) => {
      try {
        const res = await updatePermission(values).unwrap();
        console.log(res);
        toast.success(res?.message);
        onHide();
      } catch (err) {
        console.error(err);
      }
    },
    enableReinitialize: true,
  });

  const handleClickPermissions = (permissionId) => {
    const updatedPermissions = values.permissions.includes(permissionId)
      ? values.permissions.filter((id) => id !== permissionId) // Remove if already selected
      : [...values.permissions, permissionId]; // Add if not selected
    setValues({ ...values, permissions: updatedPermissions });
  };
  // console.log(error);
  // console.log(values);
  return (
    <Modal
      {...props}
      onHide={onHide}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
    >
      {/* <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">
          <span className="fw-bold fs-5">ইউজার পারমিশন</span>
        </Modal.Title>
      </Modal.Header> */}

      <Modal.Body>
        <div className="row">
          <div className="col-md-12">
            <div className="card p-3 rounded">
              <div className="d-flex gap-2">  
                <div>
                  <img
                    src={user?.image || assets.avatar}
                    alt={user?.name}
                    style={{ height: "60px" }}
                    className="rounded"
                  />
                </div>
                <div>
                  <div>
                    <strong>নামঃ </strong> {user?.name}
                  </div>
                  <div className="pt-2">
                    <strong>পদবীঃ </strong> {user?.role}
                  </div>
                </div>
              </div>
            </div>
            {isLoadingPermissions || isFetching ? (
              <div className="text-center py-4">
                <BeatLoader />
              </div>
            ) : (
              <>
                <span className="fw-bold fs-6 py-3">পারমিশন দিন</span>
                <div className="row">
                  {permissions?.length > 0 &&
                    permissions.map((item) => (
                      <div
                        className="col-md-6 mb-3"
                        key={item?.id}
                        style={{
                          position: "relative",
                        }}
                      >
                        <div
                          className={`card p-2 rounded cursor-pointer ${
                            values.permissions.includes(item?.id)
                              ? "permission-active"
                              : "border-3"
                          }`}
                          onClick={() => handleClickPermissions(item?.id)}
                        >
                          <span>{item?.name}</span>
                          <span
                            style={{
                              position: "absolute",
                              top: "6px",
                              right: "5px",
                              padding: "2px",
                              color: values.permissions.includes(item?.id)
                                ? "green"
                                : "#575757",
                            }}
                          >
                            <CheckCircle
                              sx={{
                                fontSize: "20px",
                              }}
                            />
                          </span>
                        </div>
                      </div>
                    ))}
                </div>
              </>
            )}
          </div>
          <div className="col-md-12">
            <hr />
            <div className="d-flex justify-content-between align-items-center pt-2">
              <Button
                className="mx-2"
                variant="secondary"
                onClick={onHide}
                style={{ width: "100px" }}
              >
                বন্ধ
              </Button>
              <Button
                variant="primary"
                onClick={handleSubmit}
                style={{ width: "100px" }}
                disabled={isLoading || isLoadingPermissions}
              >
                {isLoading || isLoadingPermissions ? (
                  <BeatLoader size={8} color="#fff" />
                ) : (
                  "সম্পাদন"
                )}
              </Button>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default memo(ShowUser);
