import React, { useEffect, useState } from "react";
import { assets } from "../assets";
import { Table } from "react-bootstrap";
import { convertToBanglaBenjonBorno } from "../utils/convertToBanglaBenjonBorno";
import { localDateFormat, localTimeFormat } from "../utils/dateFormat";
import { convertToBanglaNumerals } from "../utils/convertToBanglaNumerals";

const NoteSheet = ({ commentsNoteSheet, info, infoTable, printRef }) => {
  const { noteSheetList, noteSheetValues } = commentsNoteSheet;
  const [initCheckBox, setInitCheckBox] = useState({});

  const arrayToObject = (arr) => {
    return arr.reduce((acc, str) => {
      const [key, value] = str.split(":");
      acc[key] = Number(value); // convert value to number if needed
      return acc;
    }, {});
  };
  useEffect(() => {
    if (noteSheetValues) {
      setInitCheckBox(arrayToObject(noteSheetValues));
    }
  }, [noteSheetValues]);

  return (
    <div className="py-2 Nothi" ref={printRef}>
      <div className="text-center pt-2">
        <img src={assets.gov} style={{ height: "100px" }} alt="" />
        <h6 className="fw-fw-bold fs-5 mt-3">কপিরাইট অফিস</h6>
        <div className="d-flex align-items-center justify-content-center gap-3">
          <p>
            নথি নং -{" "}
            <span
              style={{
                border: "none",
                borderBottom: "1px dotted #d0d0d0",
              }}
              className="fw-semibold"
            >
              {info.noteNumber}
            </span>{" "}
          </p>
          <p>
            রেজিঃ নং -{" "}
            {Array.from({ length: 6 }).map((_, i) => (
              <span
                key={i}
                style={{
                  border: "1px dotted #a0a0a0",
                }}
                className="fw-semibold px-2"
              >
                0
              </span>
            ))}
          </p>
        </div>
      </div>
      {/* <hr
        style={{
          border: "none",
          borderTop: "1px dotted #d0d0d0",
          margin: "10px 0",
        }}
      /> */}
      <div className="py-3">
        <p
          style={{
            textAlign: "justify",
          }}
        >
          ১। আবেদনকারী{" "}
          <span
            style={{
              border: "none",
              borderBottom: "1px dotted #d0d0d0",
            }}
            className="fw-semibold"
          >
            {info.applicantName}
          </span>{" "}
          কর্তৃক{" "}
          <span
            style={{
              border: "none",
              borderBottom: "1px dotted #d0d0d0",
            }}
            className="fw-semibold"
          >
            {info.workTitle}
          </span>{" "}
          নামক{" "}
          <span
            style={{
              border: "none",
              borderBottom: "1px dotted #d0d0d0",
            }}
            className="fw-semibold"
          >
            {info.category}
          </span>{" "}
          এর কপিরাইট রেজিস্ট্রেশনের জন্য অনলাইনে{" "}
          <span
            style={{
              border: "none",
              borderBottom: "1px dotted #d0d0d0",
            }}
            className="fw-semibold"
          >
            {info.amount}
          </span>{" "}
          টাকার{" "}
          <span
            style={{
              border: "none",
              borderBottom: "1px dotted #d0d0d0",
            }}
            className="fw-semibold"
          >
            {info.challan}
          </span>{" "}
          (নং{" "}
          <span
            style={{
              border: "none",
              borderBottom: "1px dotted #d0d0d0",
            }}
            className="fw-semibold"
          >
            {info.challanNumber}
          </span>{" "}
          ও তাং{" "}
          <span
            style={{
              border: "none",
              borderBottom: "1px dotted #d0d0d0",
            }}
            className="fw-semibold"
          >
            {localDateFormat(info.treasuryDate)}
          </span>{" "}
          খ্রিঃ) এবং নিম্নবর্ণিত কাগজপত্র সহ অনলাইনে আবেদন দাখিল করা হয়েছে ।
        </p>
      </div>
      <div>
        <Table striped bordered hover>
          <tbody>
            {Object.entries(infoTable).map(([key, value], i) => (
              <tr key={i}>
                <td className={`${initCheckBox[key] === 0 && "text-danger"}`}>
                  {convertToBanglaBenjonBorno(i + 1)}
                </td>
                <td className={`${initCheckBox[key] === 0 && "text-danger"}`}>
                  {value}
                </td>
                <td className={`${initCheckBox[key] === 0 && "text-danger"}`}>
                  ০১ কপি
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
      <div className="py-3">
        {noteSheetList?.map((comment, i) => (
          <div className="pb-4" key={i}>
            <div className="d-flex gap-2">
              <div>{convertToBanglaNumerals(i + 2)}।</div>
              <div>{comment.comments}</div>
            </div>
            {comment.ext_comments && <div>{comment.ext_comments}</div>}
            <div className="d-flex justify-content-end pt-1">
              <div className="text-center">
                {comment?.user?.sign && (
                  <img
                    style={{ height: "40px" }}
                    src={comment?.user?.sign}
                    alt=""
                  />
                )}
                <div
                  style={{
                    color: "#0000ff",
                    fontWeight: "bold",
                  }}
                >
                  {comment?.user.name}
                </div>
                <div
                  style={{
                    color: "#0000ff",
                    fontWeight: "bold",
                  }}
                >
                  {comment?.role?.bnName}
                </div>
                <div>তারিখ : {localDateFormat(comment?.date)}</div>
                <div>সময় : {localTimeFormat(comment?.date)}</div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default NoteSheet;
