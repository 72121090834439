import { Handshake, Home } from "@mui/icons-material";
import debounce from "lodash/debounce";
import React, { useCallback, useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import toast from "react-hot-toast";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import {
  useCertificateIssueEndorseMutation,
  useGetCertificateListQuery,
  useGetEndorseListQuery,
} from "../../redux/api/submittedCopyrightAgreementApiSlice";
import { accessRoles } from "../../utils/accessRoles";
import { useAuth } from "../../utils/authorization";
import { localDateFormat } from "../../utils/dateFormat";
import CountCategoryAgreement from "../count_category/CountCategoryAgreement";
import ReusableTable from "../custom/data_table/ReusableTable";
import IconBreadcrumbs from "../custom/IconBreadcrumbs";
import SubmittedCommon from "../SubmittedCommon";

const EndorseFile = ({
  fileStatus,
  applicationPath,
  detailsRoute,
  pageTitle,
}) => {
  // State to track issue status for each certificate
  const [issueStatus, setIssueStatus] = useState({});
  const [date, setDate] = useState("");
  const [category_id, setCategoryId] = useState("");
  const [catId, setCatId] = useState("");
  const [subCategory, setSubCategory] = useState("");
  const [page, setPage] = useState(1);
  const [searchText, setSearchText] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const { authUser } = useAuth();
  // certificate endorse issue
  const [issue, { isSuccess: isSuccessIssue, data: dataIssue }] =
    useCertificateIssueEndorseMutation();

  const { data, isLoading, refetch } = useGetEndorseListQuery({
    date,
    trackReg: searchValue,
    category_id: category_id || catId,
    subCategory,
    page,
    fileStatus,
  });

  /// copyright application length
  const { data: copyright } = useGetCertificateListQuery({
    date: "",
    trackReg: "",
    category_id: "",
    subCategory: "",
    page: "",
    fileStatus,
  });
  /// agreement application length
  const { data: agreement } = useGetEndorseListQuery({
    date: "",
    trackReg: "",
    category_id: "",
    subCategory: "",
    page: "",
    fileStatus,
  });

  const total = {
    copyright: copyright?.meta?.total ?? 0,
    agreement: agreement?.meta?.total ?? 0,
  };

  const breadcrumbsData = [
    { icon: Home, title: "হোম", url: "/" },
    {
      icon: Handshake,
      title: pageTitle ? "চুক্তিপত্র - " + pageTitle : "চুক্তিপত্র আবেদন পত্র",
      active: true,
    },
  ];

  // data table start
  const totalData = data?.meta?.total;
  const lastPage = data?.meta?.last_page;

  const debouncedSearch = useCallback(
    debounce((value) => {
      setPage(1);
      setSearchValue(value);
      refetch();
    }, 400),
    []
  );

  useEffect(() => {
    // Cleanup function to cancel the debounce when the component unmounts or debouncedSearch changes
    return () => {
      debouncedSearch?.cancel();
    };
  }, [debouncedSearch]);

  const handleSearchChange = (value) => {
    setSearchText(value);
    debouncedSearch(value);
  };

  const handlePageChange = (page) => {
    setPage(page);
    refetch();
  };

  // handleIssue
  const handleIssue = (rowData) => {
    const { id, sendUserId } = rowData;

    Swal.fire({
      text: "আপনি কি নিশ্চিত?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      cancelButtonText: "না",
      confirmButtonText: "হ্যাঁ",
    }).then((result) => {
      if (result.isConfirmed) {
        issue({ id, formData: { user_id: sendUserId } }).then(() => {
          setIssueStatus((prev) => ({ ...prev, [id]: !prev[id] })); // Toggle the issue status
          refetch();
        });
      }
    });
  };

  useEffect(() => {
    if (isSuccessIssue) {
      toast.success(
        dataIssue?.message || "Certificate has been successfully issued."
      );
    }
  }, [dataIssue]);

  const columns = [
    { label: "তারিখ", name: "sendDate" },
    {
      label: "আবেদন নং",
      name: "trackReg",
      options: {
        customBodyRender: (value, tableMeta) => {
          const id = tableMeta.rowData.id;
          return (
            <Link
              className="text-primary"
              to={`${detailsRoute}/${id}/${tableMeta.rowData?.sendUserId}`}
            >
              {value}
            </Link>
          );
        },
      },
    },
    { label: "আবেদনকারির নাম", name: "applicant" },
    {
      label: "শিরোনাম",
      name: "workTitle",
      options: {
        customBodyRender: (value, tableMeta) => {
          const id = tableMeta.rowData.id;
          return (
            <Link
              className="text-primary"
              to={`${detailsRoute}/${id}/${tableMeta.rowData?.sendUserId}`}
            >
              {value}
            </Link>
          );
        },
      },
    },

    { label: "বিভাগ", name: "category" },
    { label: "উপবিভাগ", name: "subCategory" },
  ];

  // Conditionally add the "রেজিস্টার কর্তৃক সনদ প্রদান" column
  if (authUser?.role_id === accessRoles.Register) {
    columns.push({
      label: "রেজিস্টার কর্তৃক সনদ প্রদান",
      name: "id",
      options: {
        customBodyRender: (value, tableMeta) => {
          const rowId = tableMeta.rowData.id; // Use rowId to access the correct item
          return (
            <div className="">
              <Form.Check
                type="switch"
                id={`custom-switch-${rowId}`}
                checked={issueStatus[rowId] || false} // Set the checked state based on issueStatus
                label={"Certificate issue"}
                onChange={() => handleIssue(tableMeta.rowData)}
              />
            </div>
          );
        },
      },
    });
  }

  const dataTable = data?.data.map((item, i) => ({
    sendDate: localDateFormat(item?.sendDate),
    trackReg: item?.trackReg,
    id: item?.id,
    applicant: item?.puname,
    sendUserId: item?.sendUserId,
    workTitle: item?.document?.workTitle,
    category: item?.document?.category?.name,
    subCategory: item?.document?.subCategory?.name,
  }));

  // if (isLoading) {
  //   return <Loader />;
  // }

  return (
    <div>
      <div className="py-3">
        <IconBreadcrumbs breadcrumbs={breadcrumbsData} />
      </div>
      <div className="slideUp dashboard-layout p-3">
        <div>
          <SubmittedCommon
            total={total}
            applicationPath={applicationPath}
            date={date}
            setDate={setDate}
            category_id={category_id}
            setCategoryId={setCategoryId}
            subCategory={subCategory}
            setSubCategory={setSubCategory}
          />
        </div>
        <CountCategoryAgreement
          category_id={catId}
          setCategoryId={setCatId}
          fileStatus={fileStatus}
        />
        <div>
          <ReusableTable
            title={"চুক্তিপত্র আবেদন"}
            columns={columns}
            data={dataTable}
            isLoading={isLoading}
            currentPage={page}
            lastPage={lastPage}
            totalData={totalData}
            onSearchChange={handleSearchChange}
            searchText={searchText}
            onPageChange={handlePageChange}
          />
        </div>
      </div>
    </div>
  );
};

export default EndorseFile;
