import { Divider } from "@mui/material";
import React from "react";
import { MenuItem } from "react-pro-sidebar";
import { Link, useLocation } from "react-router-dom";

const CustomMenuItem = ({ title, path, icon }) => {
  const location = useLocation();
  return (
    <div>
      <Link to={path} className="w-100">
        <MenuItem
          icon={icon}
          className={`${
            location.pathname === path
              ? "fw-semibold text-success bg-success bg-opacity-10"
              : "text-secondary"
          }`}
          active={location.pathname === path}
        >
          {title}
        </MenuItem>
      </Link>
      <Divider />
    </div>
  );
};

export default CustomMenuItem;
