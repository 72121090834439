import React from "react";
import SubmittedAgreement from "../sumitted_agreement/SubmittedAgreement";

const InvestigationReportAgreement = () => {
  const pageTitle = "তদন্তের রিপোর্ট";

  const fileStatus = 15;
  const applicationPath = {
    copyright: "/admin/investigation-report-copyright",
    agreement: "/admin/investigation-report-agreement",
  };
  return (
    <SubmittedAgreement
      fileStatus={fileStatus}
      applicationPath={applicationPath}
      pageTitle={pageTitle}
    />
  );
};

export default InvestigationReportAgreement;
