import React, { useCallback, useEffect, useState } from "react";
import { Copyright, Home, Print } from "@mui/icons-material";
import Loader from "../../../../../components/custom/Loader";
import SubmittedCommon from "../../../../../components/SubmittedCommon";
import IconBreadcrumbs from "../../../../../components/custom/IconBreadcrumbs";
import debounce from "lodash/debounce";
import ReusableTable from "../../../../../components/custom/data_table/ReusableTable";
import {
  useGetCorrectionFormAgreementQuery,
  useGetCorrectionFormCopyrightQuery,
} from "../../../../../redux/api/submittedCopyrightAgreementApiSlice";
import { localDateFormat } from "../../../../../utils/dateFormat";
import { Link, useLocation } from "react-router-dom";
import { useAuth } from "../../../../../utils/authorization";
import { detailsUrlCopyright } from "../../../../../utils/detailsLink";
import SubmittedCopyright from "../../sumitted_copyright/SubmittedCopyright";

const CorrectionFormCopyright = () => {
  const applicationPath = {
    copyright: "/admin/correction-form-copyright",
    agreement: "/admin/correction-form-agreement",
  };
  const pageTitle = "সংরক্ষিত নথি";
  return (
    <SubmittedCopyright
      fileStatus={2}
      applicationPath={applicationPath}
      pageTitle={pageTitle}
    />
  );
};

export default CorrectionFormCopyright;

// const CorrectionFormCopyright = () => {
//   const { authUser } = useAuth();
//   const [date, setDate] = useState("");
//   const [category_id, setCategoryId] = useState("");
//   const [subCategory, setSubCategory] = useState("");
//   const [page, setPage] = useState(1);
//   const [searchText, setSearchText] = useState("");
//   const [searchValue, setSearchValue] = useState("");
//   const { data, isLoading, refetch } = useGetCorrectionFormCopyrightQuery({
//     date,
//     trackReg: searchValue,
//     category_id,
//     subCategory,
//     page,
//   });

//   /// copyright application length
//   const { data: copyright } = useGetCorrectionFormCopyrightQuery({
//     date: "",
//     trackReg: "",
//     category_id: "",
//     subCategory: "",
//     page: "",
//   });
//   /// agreement application length
//   const { data: agreement } = useGetCorrectionFormAgreementQuery({
//     date: "",
//     trackReg: "",
//     category_id: "",
//     subCategory: "",
//     page: "",
//   });

//   const total = {
//     copyright: copyright?.data?.length ?? 0,
//     agreement: agreement?.data?.length ?? 0,
//   };

//   const breadcrumbsData = [
//     { icon: Home, title: "হোম", url: "/" },
//     { icon: Copyright, title: "কপিরাইটঃ সংরক্ষিত আবেদন", active: true },
//   ];

//   // data table start
//   const totalData = data?.meta?.total;
//   const lastPage = data?.meta?.last_page;

//   const debouncedSearch = useCallback(
//     debounce((value) => {
//       setPage(1);
//       setSearchValue(value);
//       refetch();
//     }, 400),
//     []
//   );

//   useEffect(() => {
//     // Cleanup function to cancel the debounce when the component unmounts or debouncedSearch changes
//     return () => {
//       debouncedSearch?.cancel();
//     };
//   }, [debouncedSearch]);

//   const handleSearchChange = (value) => {
//     setSearchText(value);
//     debouncedSearch(value);
//   };

//   const handlePageChange = (page) => {
//     setPage(page);
//     refetch();
//   };

//   const columns = [
//     { label: "তারিখ", name: "sendDate" },
//     {
//       label: "আবেদন নং",
//       name: "trackReg",
//       options: {
//         customBodyRender: (value, tableMeta) => {
//           const id = tableMeta.rowData.id;
//           return (
//             <Link
//               className="text-primary"
//               to={`${detailsUrlCopyright(authUser.role_id, id)}`}
//             >
//               {value}
//             </Link>
//           );
//         },
//       },
//     },
//     { label: "আবেদনকারির নাম", name: "applicant" },
//     {
//       label: "শিরোনাম",
//       name: "workTitle",
//       options: {
//         customBodyRender: (value, tableMeta) => {
//           const id = tableMeta.rowData.id;
//           return (
//             <Link
//               className="text-primary"
//               to={`${detailsUrlCopyright(authUser.role_id, id)}`}
//             >
//               {value}
//             </Link>
//           );
//         },
//       },
//     },

//     { label: "বিভাগ", name: "category" },
//     { label: "উপবিভাগ", name: "subCategory" },
//     {
//       label: "প্রিন্ট",
//       name: "phone",
//       options: {
//         customBodyRender: (value) => {
//           return <Print className="text-primary fs-3" />;
//         },
//       },
//     },
//   ];

//   const dataTable = data?.data.map((item, i) => ({
//     sendDate: localDateFormat(item?.sendDate),
//     id: item?.id,
//     trackReg: item?.trackReg,
//     applicant: item?.applicantName,
//     workTitle: item?.profileDetail?.workTitle,
//     category: item?.profileDetail?.category?.name,
//     subCategory: item?.profileDetail?.subCategory?.name,
//   }));

//   if (isLoading) {
//     return <Loader />;
//   }

//   const applicationPath = {
//     copyright: "/admin/correction-form-copyright",
//     agreement: "/admin/correction-form-agreement",
//   };
//   return (
//     <div>
//       <div className="py-3">
//         <IconBreadcrumbs breadcrumbs={breadcrumbsData} />
//       </div>
//       <div className="slideUp dashboard-layout p-3">
//         <div className="pb-3">
//           <SubmittedCommon
//             total={total}
//             applicationPath={applicationPath}
//             date={date}
//             setDate={setDate}
//             category_id={category_id}
//             setCategoryId={setCategoryId}
//             subCategory={subCategory}
//             setSubCategory={setSubCategory}
//           />
//         </div>
//         <div>
//           <ReusableTable
//             title={"কপিরাইট আবেদন"}
//             columns={columns}
//             data={dataTable}
//             isLoading={isLoading}
//             currentPage={page}
//             lastPage={lastPage}
//             totalData={totalData}
//             onSearchChange={handleSearchChange}
//             searchText={searchText}
//             onPageChange={handlePageChange}
//           />
//         </div>
//       </div>
//     </div>
//   );
// };
