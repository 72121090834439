import React from "react";
import SubmittedCopyright from "../../sumitted_copyright/SubmittedCopyright";

const RecordedApprovedCopyright = () => {
  const fileStatus = 28;
  const applicationPath = {
    copyright: "/admin/recorded-approved-copyright",
    agreement: "/admin/recorded-approved-agreement",
  };
  const detailsRoute = "/admin/recorded-copyright-details";
  const pageTitle = "নথিজাত অনুমোদিত";
  return (
    <SubmittedCopyright
      fileStatus={fileStatus}
      applicationPath={applicationPath}
      detailsRoute={detailsRoute}
      pageTitle={pageTitle}
    />
  );
};

export default RecordedApprovedCopyright;
