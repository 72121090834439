import React, { useState } from "react";
import { Copyright, Home } from "@mui/icons-material";
import RegisterBookContent from "../../../../../components/reg_book/RegisterBookContent";
import { useGetRegBookCopyrightQuery } from "../../../../../redux/api/submittedCopyrightAgreementApiSlice";
import Loader from "../../../../../components/custom/Loader";
import CopyrightTable from "../../../../../components/reg_book/CopyrightTable";

const UnauthorizedBookCopyright = () => {
  const bookType = 2;
  const [trackReg, setTrackReg] = useState("");
  const { data, isLoading } = useGetRegBookCopyrightQuery({
    bookType,
    trackReg,
  });
  const breadcrumbsData = [
    { icon: Home, title: "হোম", url: "/" },
    {
      icon: Copyright,
      title: "কপিরাইট - অননুমোদিত রেজিস্টার বুক",
      active: true,
    },
  ];
  const applicationPath = {
    copyright: "/admin/unauthorized-register-book-copyright",
    agreement: "/admin/unauthorized-register-book-agreement",
  };

  if (isLoading) {
    return <Loader />;
  }
  return (
    <RegisterBookContent
      data={data}
      trackReg={trackReg}
      setTrackReg={setTrackReg}
      content={<CopyrightTable data={data} />}
      breadcrumbsData={breadcrumbsData}
      applicationPath={applicationPath}
    />
  );
};

export default UnauthorizedBookCopyright;
