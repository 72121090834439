import React from "react";
import { Container, Box, Typography } from "@mui/material";
import { ErrorOutline } from "@mui/icons-material";
import { Button, Card } from "react-bootstrap";
import { Link } from "react-router-dom";
import { theme } from "../../../theme";
import { assets } from "../../../assets";

function ErrorPage() {
  setTimeout(() => {
    window.location.href = "/applicant";
  }, [3000]);
  return (
    <Card
      sx={{
        boxShadow: theme.palette.boxShadow,
        borderRadius: "10px",
        padding: "5px 10px",
        position: "relative",
        width: "100%",
        height: "100%",
        bgcolor: "#ffffff",
        p: 3,
      }}
    >
      <Container
        className="d-flex align-items-center justify-content-center"
        style={{ minHeight: "25px" }}
      >
        <Box textAlign="center" py={3}>
          <img
            src={assets?.error}
            style={{ height: "200px" }}
            className="mb-3"
            alt=""
          />
          <Typography
            variant="h5"
            component="h1"
            fontWeight={"bold"}
            gutterBottom
          >
            পেমেন্ট বাতিল করা হয়েছে
          </Typography>
          <Typography>
            আপনার পেমেন্ট সফল হয়নি. পরে আবার চেষ্টা করুন।
          </Typography>
        </Box>
      </Container>
    </Card>
  );
}

export default ErrorPage;
