import React from "react";
import SubmittedAgreement from "../sumitted_agreement/SubmittedAgreement";

const InvestigationAgreement = () => {
  const pageTitle = "তদন্তের নথি";

  const fileStatus = 14;
  const applicationPath = {
    copyright: "/admin/investigation-copyright",
    agreement: "/admin/investigation-agreement",
  };
  const detailsRoute = "/admin/explanation-agreement-details";
  return (
    <SubmittedAgreement
      fileStatus={fileStatus}
      applicationPath={applicationPath}
      detailsRoute={detailsRoute}
      pageTitle={pageTitle}
    />
  );
};

export default InvestigationAgreement;
