import React, { useEffect, useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { useFormik } from "formik";
import toast from "react-hot-toast";
import FormikFormField from "../../../components/custom/form/FormikFormField";
import FormikFormSelect from "../../../components/custom/form/FormikFormSelect";
import { BeatLoader } from "react-spinners";
import { createInitializeValues, createValidationSchema } from "./form_config";
import {
  useCreateCategoryCatalogMutation,
  useUpdateCategoryCatalogMutation,
} from "../../../redux/api/categoryCatalogApiSlice";

const UpdateCategory = ({ onHide, ...props }) => {
  const [updateCategory, { isError, error, isSuccess, data, isLoading }] =
    useUpdateCategoryCatalogMutation();

  const [imagePreview, setImagePreview] = useState(null);

  const formik = useFormik({
    initialValues: createInitializeValues,
    validationSchema: createValidationSchema,
    onSubmit: async (values, { setSubmitting }) => {
      try {
        setSubmitting(true);

        const formData = new FormData();
        if (values.image instanceof File) {
          formData.append("image", values.image);
        }

        formData.append("name", values.name);
        formData.append("code", values.code);
        formData.append("status", values.status);
        formData.append("_method", "PUT");
        await updateCategory({ id: props?.data?.id, formData });
        setSubmitting(false);
      } catch (error) {
        setSubmitting(false);
      }
    },
  });

  const {
    values,
    touched,
    errors,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
    setErrors,
    isSubmitting,
    resetForm,
    setValues,
  } = formik;

  useEffect(() => {
    if (props.data) {
      setValues({
        ...createInitializeValues,
        ...props.data,
      });
    }
  }, [props.data, setValues]);
  useEffect(() => {
    if (isError) {
      toast.error(error?.data?.message);
      setErrors(handleServerErrors(error?.data?.data || {}));
    }
    if (isSuccess) {
      resetForm();
      onHide();
      toast.success(data?.message);
    }
  }, [isError, isSuccess, error, data]);

  const handleServerErrors = (serverErrors) => {
    const errors = {};
    Object.keys(serverErrors).forEach((field) => {
      errors[field] = serverErrors[field].join(", ");
    });
    return errors;
  };

  const handleImageChange = (event) => {
    const file = event.currentTarget.files[0];
    setFieldValue("image", file);
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result);
      };
      reader.readAsDataURL(file);
    } else {
      setImagePreview(null);
    }
  };

  return (
    <Modal
      {...props}
      onHide={() => {
        setImagePreview(null);
        resetForm();
        onHide();
      }}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header style={{ display: "flex", justifyContent: "center" }}>
        <Modal.Title id="contained-modal-title-vcenter">
          <span className="fw-bold px-3">ক্যাটাগরি সম্পাদনা করুনঃ</span>
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <form onSubmit={handleSubmit}>
          <div className="row">
            <div className="col-md-6">
              <FormikFormField
                value={values.name}
                name="name"
                label={
                  values.parent_id
                    ? "উপ-শ্রেণী  নাম ( সাব-ক্যাটাগরি )"
                    : "শ্রেণী নাম ( ক্যাটাগরি )"
                }
                placeholder="নাম লিখুন"
                errors={errors}
                touched={touched}
                handleChange={handleChange}
                handleBlur={handleBlur}
              />
            </div>
            <div className="col-md-6">
              <FormikFormField
                value={values.code}
                name="code"
                label="কোড"
                placeholder="কোড লিখুন"
                errors={errors}
                touched={touched}
                handleChange={handleChange}
                handleBlur={handleBlur}
              />
            </div>
            <div className="col-md-6">
              <FormikFormSelect
                value={values.status}
                name="status"
                label="স্ট্যাটাস"
                placeholder="স্ট্যাটাস নির্বাচন করুন"
                errors={errors}
                touched={touched}
                handleChange={handleChange}
                handleBlur={handleBlur}
                options={[
                  { label: "Active", value: 1 },
                  { label: "In-Active", value: 2 },
                ]}
              />
            </div>
            {(!values?.parent_id || props?.data?.image) && (
              <div className="col-md-6">
                <FormikFormField
                  name="image"
                  label="ছবি"
                  placeholder="ছবি নির্বাচন করুন"
                  errors={errors}
                  touched={touched}
                  handleChange={handleImageChange}
                  handleBlur={handleBlur}
                  type="file"
                />
              </div>
            )}
            {imagePreview && (
              <div className="col-md-6">
                <div className="pb-1">
                  <span>নতুন ছবি</span>
                </div>

                <img
                  src={imagePreview}
                  alt="Preview"
                  className="rounded border p-2"
                  style={{ maxWidth: "100%", maxHeight: "150px" }}
                />
              </div>
            )}
            {props?.data?.image && (
              <div className="col-md-6">
                <div className="pb-1">
                  <span>পুরানো ছবি</span>
                </div>
                <img
                  src={props?.data?.image}
                  alt="Preview"
                  className="rounded border p-2"
                  style={{ maxWidth: "100%", maxHeight: "150px" }}
                />
              </div>
            )}
            <div className="col-md-12">
              <hr />
              <div className="d-flex justify-content-between align-items-center pt-2">
                <Button
                  variant="secondary"
                  onClick={() => {
                    onHide();
                    resetForm();
                    setImagePreview(null);
                  }}
                  style={{ width: "100px" }}
                >
                  বন্ধ
                </Button>
                <Button
                  variant="primary"
                  type="submit"
                  style={{ width: "150px" }}
                  disabled={isLoading}
                >
                  {isLoading ? (
                    <BeatLoader className="text-white" />
                  ) : (
                    "দাখিল করুন"
                  )}
                </Button>
              </div>
            </div>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
};

export default UpdateCategory;
