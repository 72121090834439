import React, { useEffect } from "react";
import { Button, Form } from "react-bootstrap";
import { ImPlus } from "react-icons/im";
import { IoCloseSharp } from "react-icons/io5";
import { BeatLoader } from "react-spinners";
import Swal from "sweetalert2";
import ClientFormikYearSelector from "../custom/form/year/ClientFormikYearSelector";
import { transformErrorsToObjectStructure } from "../../utils/transformErrorsToObjectStructure";
import toast from "react-hot-toast";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  useCertificateLangUpdateMutation,
  useDeleteCertificateLanguageMutation,
} from "../../redux/api/submittedCopyrightAgreementApiSlice";
import { certificateTitles } from "../../utils/certificate_title";

const CertificateLang = ({ data, refetch, onHide }) => {
  const [
    submitData,
    {
      isLoading: isLoadingSubmit,
      isSuccess: isSuccessSubmit,
      error: errorSubmit,
      isError: isErrorSubmit,
      data: dataSubmit,
    },
  ] = useCertificateLangUpdateMutation();

  // delete the certificate language
  const [deleteCertificate] = useDeleteCertificateLanguageMutation();

  const id = data?.id;
  const ac = data?.archive;
  const pd = data?.profileDetail;

  const getWritersName = () => {
    // Check if ac?.writers_name is available
    if (ac?.writers_name) {
      return ac.writers_name;
    }

    // Check if pd?.writerDetails is available and map to writer names
    if (pd?.writerDetails) {
      const writerNames = pd.writerDetails
        .map((item) => item?.writerName)
        .filter((name) => name);
      return writerNames.join(",");
    }

    // Default to an empty string if neither is available
    return "";
  };

  const writersName = getWritersName();

  const initialValues = {
    archive_id: ac?.id || "",
    certificate: Number(pd?.certificate) || "",
    workTitle: pd?.workTitle || "",
    writers_name: writersName,
    cw_proportion: ac?.cw_proportion || "",
    workPublishtype: Number(pd?.workPublishtype) || 1,
    publish_year: ac?.publish_year || pd?.publish?.year || "",
    work_title: pd?.work_title || "", // For ইংরেজি
    certificatelanguages: (pd?.certificateLangs.length > 0 &&
      pd?.certificateLangs) || [
      {
        author_creator: "",
        owner_right: "",
      },
    ], // For ইংরেজি
    pub_unpub: Number(pd?.pub_unpub) || 1, // For ইংরেজি 1=Published, 2=Unpublished
    pub_unpub_year: pd?.pub_unpub_year || "", // For ইংরেজি
    creative_work: ac?.creative_work || "", // For ইংরেজি
  };

  const validationSchema = Yup.object().shape({
    certificate: Yup.number()
      .required("সার্টিফিকেট নির্বাচন করুন")
      .oneOf([1, 2]),
    // for bangla
    workTitle: Yup.string().when("certificate", {
      is: (val) => val === 1,
      then: () =>
        Yup.string()
          .required("ওয়ার্ক টাইটেল প্রযোজ্য")
          .min(2, "অন্তত ২ অক্ষর হতে হবে"),
    }),
    writers_name: Yup.string().when("certificate", {
      is: (val) => val === 1,
      then: () =>
        Yup.string()
          .required("রাইটার নাম প্রযোজ্য")
          .min(2, "অন্তত ২ অক্ষর হতে হবে"),
    }),

    cw_proportion: Yup.string().when("certificate", {
      is: (val) => val === 1, // বাংলা
      then: () => Yup.string().required("সৃজনশীল কাজের অনুপাত প্রযোজ্য"),
    }),
    // workPublishtype: Yup.string().when("certificate", {
    //   is: (val) => val === 1, // বাংলা
    //   then: () => Yup.string().required("ওয়ার্ক পাবলিশ টাইপ প্রযোজ্য"),
    // }),
    publish_year: Yup.string().when("certificate", {
      is: (val) => val === 1, // বাংলা
      then: () => Yup.string().required("প্রকাশনার বছর প্রযোজ্য"),
    }),

    // for english

    work_title: Yup.string().when("certificate", {
      is: (val) => val === 2,
      then: () =>
        Yup.string()
          .required("ওয়ার্ক টাইটেল প্রযোজ্য")
          .min(2, "অন্তত ২ অক্ষর হতে হবে"),
    }),
    certificatelanguages: Yup.array().of(
      Yup.object().shape({
        author_creator: Yup.string().when(["$certificate"], {
          is: (val) => val === 2,
          then: () =>
            Yup.string()
              .required("লেখকের নাম প্রযোজ্য")
              .min(2, "অন্তত ২ অক্ষর হতে হবে"),
          otherwise: () => Yup.string().nullable(),
        }),

        owner_right: Yup.string().when(["$certificate"], {
          is: (val) => val === 2, // ইংরেজি
          then: () =>
            Yup.string()
              .required("অধিকারের নাম প্রযোজ্য")
              .min(2, "অন্তত ২ অক্ষর হতে হবে"),
          otherwise: () => Yup.string().nullable(),
        }),
      })
    ),

    // pub_unpub: Yup.number().when("certificate", {
    //   is: (val) => val === 2, // ইংরেজি
    //   then: () =>
    //     Yup.number().required("প্রকাশনার অবস্থা নির্বাচন করুন").oneOf([1, 2]),
    // }),
    pub_unpub_year: Yup.string().when("certificate", {
      is: (val) => val === 2, // ইংরেজি
      then: () =>
        Yup.string()
          .required("প্রকাশনার বছর প্রযোজ্য")
          .matches(/^[0-9]{4}$/, "সঠিক বছর প্রযোজ্য"),
    }),
    creative_work: Yup.string().when("certificate", {
      is: (val) => val === 2, // ইংরেজি
      then: () =>
        Yup.string()
          .required("সৃজনশীল কাজের বিবরণ প্রযোজ্য")
          .min(2, "অন্তত ২ অক্ষর হতে হবে"),
    }),
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      Swal.fire({
        text: "আপনি কি নিশ্চিত?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "না",
        confirmButtonText: "হ্যাঁ",
      }).then((result) => {
        if (result.isConfirmed) {
          submitData({ id, formData: values });
        }
      });
    },
  });

  useEffect(() => {
    if (isSuccessSubmit) {
      toast.success(dataSubmit?.message);
      formik.resetForm();
      refetch();
      onHide();
    }
    if (isErrorSubmit) {
      toast.error(errorSubmit?.data?.message);
      formik.setErrors(
        transformErrorsToObjectStructure(errorSubmit.data?.data || {})
      );
    }
    // eslint-disable-next-line
  }, [isErrorSubmit, isSuccessSubmit]);

  const lang = [
    { label: "বাংলা", value: 1 },
    { label: "ইংরেজি", value: 2 },
  ];

  const pub = [
    { label: "Published", value: 1 },
    { label: "Unpublished", value: 2 },
  ];

  // handle add
  const handleAdd = () => {
    formik.setValues((prev) => ({
      ...prev,
      certificatelanguages: [
        ...prev.certificatelanguages,
        {
          author_creator: "",
          owner_right: "",
        },
      ],
    }));
  };

  // handle remove
  const handleRemove = (index, idCir) => {
    if (idCir) {
      Swal.fire({
        text: "আপনি কি নিশ্চিত?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "না",
        confirmButtonText: "হ্যাঁ",
      }).then((result) => {
        if (result.isConfirmed) {
          deleteCertificate({ id1: pd?.id, id2: idCir }).then((result) => {
            formik.setValues((prev) => ({
              ...prev,
              certificatelanguages: prev.certificatelanguages.filter(
                (_, i) => i !== index
              ),
            }));
          });
        }
      });
    } else {
      formik.setValues((prev) => ({
        ...prev,
        certificatelanguages: prev.certificatelanguages.filter(
          (_, i) => i !== index
        ),
      }));
    }
  };

  const certificateTitlesObj = certificateTitles(Number(ac?.certificate_type));

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <div className="py-3">
          <div className="d-flex align-items-center gap-3 mb-3">
            <span className="fw-semibold"> সার্টিফিকেটের ভাষাঃ</span>
            {lang.map((type, i) => (
              <Form.Check
                type={"radio"}
                label={`${type.label}`}
                value={type.value}
                id={`default-${type.label}`}
                onChange={() =>
                  formik.setValues((prev) => ({
                    ...prev,
                    certificate: type.value,
                  }))
                }
                checked={formik.values.certificate === type.value}
              />
            ))}
          </div>
          {/* bangla  */}

          {formik.values.certificate === 1 && (
            <div className="row">
              <div className="col-md-6 mb-2">
                <label htmlFor="">{certificateTitlesObj?.a}</label>
                <Form.Control
                  value={formik.values.workTitle}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  errors={formik.errors}
                  touched={formik.touched}
                  name="workTitle"
                />
                {formik.errors.workTitle && formik.touched.workTitle && (
                  <span className="text-danger">{formik.errors.workTitle}</span>
                )}
              </div>
              <div className="col-md-6 mb-2">
                <label htmlFor="">{certificateTitlesObj?.b}</label>
                <Form.Control
                  value={formik.values.writers_name}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  errors={formik.errors}
                  touched={formik.touched}
                  name="writers_name"
                />
                {formik.errors.writers_name && formik.touched.writers_name && (
                  <span className="text-danger">
                    {formik.errors.writers_name}
                  </span>
                )}
              </div>
              <div className="col-md-6 mb-2">
                <label htmlFor="">{certificateTitlesObj?.c}</label>
                <Form.Control
                  value={formik.values.cw_proportion}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  errors={formik.errors}
                  touched={formik.touched}
                  name="cw_proportion"
                />
                {formik.errors.cw_proportion &&
                  formik.touched.cw_proportion && (
                    <span className="text-danger">
                      {formik.errors.cw_proportion}
                    </span>
                  )}
              </div>

              <div className="col-md-12 mb-2">
                <div
                  className="rounded mb-4"
                  style={{
                    background: "#f4f4f4",
                    padding: "10px",
                  }}
                >
                  <div className="row">
                    <div className="col-md-6 mt-2">
                      <div className="d-flex align-items-center flex-wrap gap-3">
                        {pub.map((type, i) => (
                          <Form.Check
                            type={"radio"}
                            label={`${type.label}`}
                            value={type.value}
                            id={`default-${type.label}`}
                            onChange={() =>
                              formik.setValues((prev) => ({
                                ...prev,
                                workPublishtype: type.value,
                              }))
                            }
                            checked={
                              formik.values.workPublishtype === type.value
                            }
                          />
                        ))}
                      </div>
                    </div>
                    <div className="col-md-6 mb-2">
                      <ClientFormikYearSelector
                        defaultOptions={
                          formik.values.workPublishtype === 1
                            ? "কর্ম প্রকাশনার বছর"
                            : "কর্ম সম্পন্নের বছর"
                        }
                        value={formik.values?.publish_year}
                        name={`publish_year`}
                        errors={formik.errors?.publish_year}
                        touched={formik.touched?.publish_year}
                        handleChange={formik.handleChange}
                        handleBlur={formik.handleBlur}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          {/* english  */}
          {formik.values.certificate === 2 && (
            <>
              <div className="py-3">
                <div className="mb-3">
                  <span className="fw-semibold">{certificateTitlesObj?.a}</span>
                  <Form.Control
                    value={formik.values.work_title}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    errors={formik.errors}
                    touched={formik.touched}
                    name="work_title"
                  />
                  {formik.errors.work_title && formik.touched.work_title && (
                    <span className="text-danger">
                      {formik.errors.work_title}
                    </span>
                  )}
                </div>
                {formik.values.certificatelanguages.map((item, i) => (
                  <div className="d-flex align-items-center">
                    <div className="row w-100" key={i}>
                      <div className="col-md-6">
                        <label className="fw-semibold">Author / Creator</label>
                        <Form.Control
                          value={
                            formik.values.certificatelanguages[i]
                              .author_creator || ""
                          }
                          onBlur={formik.handleBlur}
                          onChange={formik.handleChange}
                          name={`certificatelanguages[${i}].author_creator`}
                          isInvalid={
                            formik.touched.certificatelanguages?.[i]
                              ?.author_creator &&
                            formik.errors.certificatelanguages?.[i]
                              ?.author_creator
                          }
                        />
                        {formik.touched.certificatelanguages?.[i]
                          ?.author_creator &&
                          formik.errors.certificatelanguages?.[i]
                            ?.author_creator && (
                            <span className="text-danger">
                              {
                                formik.errors.certificatelanguages?.[i]
                                  ?.author_creator
                              }
                            </span>
                          )}
                      </div>
                      <div className="col-md-6">
                        <label className="fw-semibold">
                          Owner of the rights
                        </label>
                        <Form.Control
                          value={
                            formik.values.certificatelanguages[i].owner_right ||
                            ""
                          }
                          onBlur={formik.handleBlur}
                          onChange={formik.handleChange}
                          name={`certificatelanguages[${i}].owner_right`}
                          isInvalid={
                            formik.touched.certificatelanguages?.[i]
                              ?.owner_right &&
                            formik.errors.certificatelanguages?.[i]?.owner_right
                          }
                        />
                        {formik.touched.certificatelanguages?.[i]
                          ?.owner_right &&
                          formik.errors.certificatelanguages?.[i]
                            ?.owner_right && (
                            <span className="text-danger">
                              {
                                formik.errors.certificatelanguages?.[i]
                                  ?.owner_right
                              }
                            </span>
                          )}
                      </div>
                    </div>
                    <div className="p-2">
                      <div
                        style={{
                          marginTop: "5px",
                          width: "20px",
                          textAlign: "center",
                        }}
                      >
                        {formik.values.certificatelanguages.length > 1 &&
                          i !== 0 && (
                            <IoCloseSharp
                              className="text-danger fw-bold cursor-pointer"
                              onClick={() => handleRemove(i, item?.id)}
                              size="24"
                            />
                          )}
                        {(formik.values.certificatelanguages.length === i + 1 ||
                          formik.values.certificatelanguages.length === 1) && (
                          <ImPlus
                            className="cursor-pointer"
                            onClick={handleAdd}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                ))}
              </div>

              <div className="col-md-12 mb-4">
                <label htmlFor="">{certificateTitlesObj?.d}</label>
                <Form.Control
                  value={formik.values.creative_work}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  errors={formik.errors}
                  touched={formik.touched}
                  name="creative_work"
                />
                {formik.errors.creative_work &&
                  formik.touched.creative_work && (
                    <span className="text-danger">
                      {formik.errors.creative_work}
                    </span>
                  )}
              </div>
              <div
                className="rounded mb-4"
                style={{
                  background: "#f4f4f4",
                  padding: "10px",
                }}
              >
                <div className="row">
                  <div className="col-md-6 mt-2">
                    <div className="d-flex align-items-center flex-wrap gap-3">
                      {pub.map((type, i) => (
                        <Form.Check
                          type={"radio"}
                          label={`${type.label}`}
                          value={type.value}
                          id={`default-${type.label}`}
                          onChange={() =>
                            formik.setValues((prev) => ({
                              ...prev,
                              pub_unpub: type.value,
                            }))
                          }
                          checked={formik.values.pub_unpub === type.value}
                        />
                      ))}
                    </div>
                  </div>
                  <div className="col-md-6 mb-2">
                    <ClientFormikYearSelector
                      defaultOptions={
                        formik.values.pub_unpub === 1
                          ? "Year of Publication"
                          : "Year of Completion"
                      }
                      value={formik.values?.pub_unpub_year}
                      name={`pub_unpub_year`}
                      errors={formik.errors?.pub_unpub_year}
                      touched={formik.touched?.pub_unpub_year}
                      handleChange={formik.handleChange}
                      handleBlur={formik.handleBlur}
                    />
                  </div>
                </div>
              </div>
            </>
          )}
        </div>

        <div className="my-3 text-center d-flex justify-content-center gap-3">
          <div className="mr-3">
            <Button type="submit" disabled={isLoadingSubmit}>
              {isLoadingSubmit ? <BeatLoader /> : "সম্পাদন করুন"}
            </Button>
          </div>
        </div>
      </form>
    </>
  );
};

export default CertificateLang;
