import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";

const FilePreviewModal = ({
  previewFile,
  setPreviewFile,
  previewModal,
  setPreviewModal,
}) => {
  return (
    <Modal
      onHide={() => {
        setPreviewFile(null);
        setPreviewModal(false);
      }}
      show={previewModal}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body>
        {previewFile && previewFile.type.startsWith("image/") && (
          <img
            className="w-100 rounded"
            src={URL.createObjectURL(previewFile)}
            alt="Preview"
          />
        )}
        {previewFile && previewFile.type === "application/pdf" && (
          <embed
            src={URL.createObjectURL(previewFile)}
            type="application/pdf"
            width="100%"
            height="600px"
          />
        )}
      </Modal.Body>
      <Modal.Footer>
        <div className="text-center w-100">
          <Button
            type="button"
            className="btn btn-secondary"
            onClick={() => {
              setPreviewModal(false);
            }}
          >
            বন্ধ
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default FilePreviewModal;
