import React from "react";
import SubmittedCopyright from "../sumitted_copyright/SubmittedCopyright";

const InvestigationReportCopyright = () => {
  const pageTitle = "তদন্তের রিপোর্ট";

  const fileStatus = 15;
  const applicationPath = {
    copyright: "/admin/investigation-report-copyright",
    agreement: "/admin/investigation-report-agreement",
  };
  return (
    <SubmittedCopyright
      fileStatus={fileStatus}
      applicationPath={applicationPath}
      pageTitle={pageTitle}
    />
  );
};

export default InvestigationReportCopyright;
