import React from "react";
import SubmittedCopyright from "../sumitted_copyright/SubmittedCopyright";

const LetterCorrectionApprovedCopyright = () => {
  const fileStatus = 22;
  const applicationPath = {
    copyright: "/admin/letter-of-correction-approved-copyright",
    agreement: "/admin/letter-of-correction-approved-agreement",
  };
  const detailsRoute = "/admin/letter-of-correction-copyright-details";
  const pageTitle = "সংশোধনের চিঠি (অনুমোদিত)";
  return (
    <SubmittedCopyright
      fileStatus={fileStatus}
      applicationPath={applicationPath}
      detailsRoute={detailsRoute}
      pageTitle={pageTitle}
    />
  );
};

export default LetterCorrectionApprovedCopyright;
