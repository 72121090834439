import React from "react";
import SubmittedCopyright from "../../sumitted_copyright/SubmittedCopyright";

const RecordedCopyrightApplication = () => {
  const fileStatus = 19;
  const applicationPath = {
    copyright: "/admin/recorded-application-copyright",
    agreement: "/admin/recorded-application-agreement",
  };
  const detailsRoute = "/admin/recorded-copyright-details";
  const pageTitle = "চূড়ান্তভাবে নথিজাত";
  return (
    <SubmittedCopyright
      fileStatus={fileStatus}
      applicationPath={applicationPath}
      detailsRoute={detailsRoute}
      pageTitle={pageTitle}
    />
  );
};

export default RecordedCopyrightApplication;
