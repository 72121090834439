import React, { useEffect } from "react";
import {
  useShowEmailWebsiteSettingQuery,
  useUpdateEmailWebsiteSettingMutation,
} from "../redux/api/websiteSettingsApiSlice";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Button, Form } from "react-bootstrap";
import toast from "react-hot-toast";
import { BeatLoader } from "react-spinners";
import Loader from "./custom/Loader";
import FormikFormField from "./custom/form/FormikFormField";
import { transformErrorsToObjectStructure } from "../utils/transformErrorsToObjectStructure";

const initialValues = {
  host: "",
  port: "",
  password: "",
  username: "",
};

const validationSchema = Yup.object().shape({
  host: Yup.string().required("হোস্ট প্রয়োজন"),
  port: Yup.string().required("পোর্ট প্রয়োজন"),
  password: Yup.string().required("পাসওয়ার্ড প্রয়োজন"),
  username: Yup.string().required("ইউজারনেম প্রয়োজন"),
});

const EmailConfigurationSettings = () => {
  const { data, isLoading } = useShowEmailWebsiteSettingQuery();

  const [
    UpdateEmailWebsiteSetting,
    {
      isLoading: isLoadingUpdate,
      isError: isErrorUpdate,
      error: errorUpdate,
      isSuccess: isSuccessUpdate,
      data: dataUpdate,
    },
  ] = useUpdateEmailWebsiteSettingMutation();

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values, { setSubmitting }) => {
      const formData = { ...values, _method: "PUT" };
      await UpdateEmailWebsiteSetting(formData);
      setSubmitting(false);
    },
  });

  const {
    values,
    touched,
    errors,
    handleBlur,
    handleChange,
    handleSubmit,
    setValues,
    setErrors,
    resetForm,
  } = formik;

  useEffect(() => {
    if (data?.data) {
      setValues(data.data);
    }
  }, [data?.data, setValues]);

  useEffect(() => {
    if (isErrorUpdate) {
      setErrors(
        transformErrorsToObjectStructure(errorUpdate?.data?.data || {})
      );
      toast.error(errorUpdate?.data?.message);
    }
    if (isSuccessUpdate) {
      toast.success(dataUpdate?.message);
    }
  }, [isErrorUpdate, isSuccessUpdate]);

  if (isLoading) {
    return <Loader />;
  }

  return (
    <Form onSubmit={handleSubmit}>
      <div className="row p-2">
        <div className="col-md-6 col-12">
          <div className="form-group">
            <label>হোস্ট</label>
            <FormikFormField
              placeholder="হোস্ট"
              value={values.host}
              name="host"
              errors={errors}
              touched={touched}
              handleChange={handleChange}
              handleBlur={handleBlur}
            />
          </div>
        </div>
        <div className="col-md-6 col-12">
          <div className="form-group">
            <label>পোর্ট</label>
            <FormikFormField
              placeholder="পোর্ট"
              value={values.port}
              name="port"
              errors={errors}
              touched={touched}
              handleChange={handleChange}
              handleBlur={handleBlur}
            />
          </div>
        </div>
        <div className="col-md-6 col-12">
          <div className="form-group">
            <label>পাসওয়ার্ড</label>
            <FormikFormField
              placeholder="পাসওয়ার্ড"
              value={values.password}
              name="password"
              errors={errors}
              touched={touched}
              handleChange={handleChange}
              handleBlur={handleBlur}
            />
          </div>
        </div>
        <div className="col-md-6 col-12">
          <div className="form-group">
            <label>ইউজারনেম</label>
            <FormikFormField
              placeholder="ইউজারনেম"
              value={values.username}
              name="username"
              errors={errors}
              touched={touched}
              handleChange={handleChange}
              handleBlur={handleBlur}
            />
          </div>
        </div>
      </div>

      <div className="text-center mb-2">
        <Button type="submit" disabled={isLoadingUpdate}>
          {isLoadingUpdate ? <BeatLoader color="#fff" /> : "আপডেট করুন "}
        </Button>
      </div>
    </Form>
  );
};

export default EmailConfigurationSettings;
