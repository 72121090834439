import React from "react";
import styles from "./CertificateOne.module.css";

const HeadingPart = ({ data }) => {
  const publicUrl = process.env.PUBLIC_URL;

  const formatDate = (dateString) => {
    // Remove any non-digit characters
    let digits;
    digits = dateString.replace(/\D/g, "");

    if (digits.length !== 8) {
      // then take last 4 digits
      digits = digits.substr(-4);
    }
    // Ensure the string is 8 characters long, prefixing with zeros if necessary
    return digits.padStart(8, "0");
  };

  const isValidDate = (dateString) => {
    // Regular expression to check if the string contains only digits
    const onlyNumbers = /^\d+$/;

    // Extract the date components (assuming the format is DD-MM-YYYY)
    const [day, month, year] = dateString.split("-");

    // Check if the date string contains exactly 8 digits and is valid numerically
    if (!dateString.replace(/\D/g, "").match(onlyNumbers)) {
      return false; // Contains non-numeric characters
    }

    // Check if the extracted values form a valid date
    const date = new Date(`${year}-${month}-${day}`);

    // Check if the date is valid
    return (
      date instanceof Date &&
      !isNaN(date) &&
      date.getDate() == day &&
      date.getMonth() + 1 == month &&
      date.getFullYear() == year
    );
  };

  return (
    <div className={`${styles.section2}`}>
      <div className={`${styles.publication}`}>
        <table className="table table-bordered text-center datetable">
          <tbody>
            <tr className={`${styles.customTr}`}>
              <td colspan="8">Date of Publication</td>
            </tr>
            <tr className={`${styles.customTr}`}>
              {data.publication_date !== "Unpublished" ? (
                formatDate(data.publication_date)
                  .split("")
                  .map((char, index) => <td key={index}>{char}</td>)
              ) : (
                <td colspan="8">Unpublished</td>
              )}
            </tr>
            <tr className={`${styles.customTr}`}>
              <td colspan="8">Related Right Registration No.</td>
            </tr>
            <tr className="">
              <td colspan="8">{data.registration_no}</td>
            </tr>
          </tbody>
        </table>
      </div>

      <div className={`${styles.copyrightLogo}`}>
        <img
          src={`${publicUrl}/assets/copyright_logo.jpg`}
          alt="copyright_logo"
          id=""
        />
      </div>

      <div className={`${styles.registration}`}>
        <table className="table table-bordered text-center datetable">
          <tbody>
            <tr className={`${styles.customTr}`}>
              <td colspan="8">Date of Registration</td>
            </tr>
            <tr className={`${styles.customTr}`}>
              {formatDate(data.date_of_reg)
                .split("")
                .map((char, index) => (
                  <td key={index}>{char}</td>
                ))}
            </tr>
            <tr className={`${styles.customTr}`}>
              <td colspan="8">Validity of Registration</td>
            </tr>
            <tr className="">
              {isValidDate(data.validity) ? (
                formatDate(data.validity)
                  .split("")
                  .map((char, index) => <td key={index}>{char}</td>)
              ) : (
                <td colspan="8">{data.validity}</td>
              )}
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default HeadingPart;
