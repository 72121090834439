import React from "react";
import SubmittedAgreement from "../sumitted_agreement/SubmittedAgreement";

const HearingAgreementApplication = () => {
  const fileStatus = 11;
  const applicationPath = {
    copyright: "/admin/hearing-application-copyright",
    agreement: "/admin/hearing-application-agreement",
  };
  const detailsRoute = "/admin/hearing-agreement-details";
  const pageTitle = "শুনানীর নথি";
  return (
    <SubmittedAgreement
      fileStatus={fileStatus}
      applicationPath={applicationPath}
      detailsRoute={detailsRoute}
      pageTitle={pageTitle}
    />
  );
};

export default HearingAgreementApplication;
