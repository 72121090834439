import { useFormik } from "formik";
import React, { useCallback, useEffect, useState } from "react";
import { Alert } from "react-bootstrap";
import toast from "react-hot-toast";
import { useNavigate, useParams } from "react-router-dom";
import Select from "react-select";
import AsyncSelect from "react-select/async";
import { BeatLoader } from "react-spinners";
import { assets } from "../../../../assets";
import FilePreviewModal from "../../../../components/ApllicantionFilePreview";
import ClientFormikFormFieldMultiple from "../../../../components/custom/form/ClientFormikFormFieldMultiple";
import Loader from "../../../../components/custom/Loader";
import {
  useCreateApplicationPaymentProcessMutation,
  useGetBankQuery,
  useGetInitialFeeQuery,
  useLazyGetBankBranchesQuery,
} from "../../../../redux/api/applicationPaymentProcessApiSlice";
import {
  globalDateFormat,
  localDateFormat,
} from "../../../../utils/dateFormat";
import { createFormData } from "../../../../utils/formDataTransform";
import { transformErrorsToObjectStructure } from "../../../../utils/transformErrorsToObjectStructure";
import BanglaCalender from "../form_two/BanglaCalender";
import ShowPreviewFiles from "../form_two/ShowPreviewFiles";
import styles from "./ApplicationForCopyright.module.css";
import { initialValues, validationSchema } from "./validation";

export default function CopyrightPayment() {
  const [previewModal, setPreviewModal] = useState(false);
  const [previewFile, setPreviewFile] = useState("");
  const [previewModalURL, setPreviewModalURL] = useState("");
  const { profileId } = useParams();
  const [searchText, setSearchText] = useState("");
  const [bank_id, setBank_id] = useState("");
  const [bankBranches, setBankBranches] = useState([]);
  const [page, setPage] = useState(1);
  const navigate = useNavigate();

  const [
    createApplication,
    {
      isLoading: isLoadingCreate,
      error: errorCreate,
      isError: isErrorCreate,
      data: dataCreate,
      isSuccess: isSuccessCreate,
    },
  ] = useCreateApplicationPaymentProcessMutation();

  const { data: bankName, isLoading: isLoadingBank } = useGetBankQuery();
  const [
    fetchBankBranches,
    { data: bankNameBranch, isLoading: isLoadingBankBranch },
  ] = useLazyGetBankBranchesQuery();

  const { data: initialFee, isLoading: isLoadingInitialFee } =
    useGetInitialFeeQuery(profileId, {
      refetchOnMountOrArgChange: true,
    });

  const treasuryBankName = bankName?.data.map((item) => {
    return { value: item.id, label: item.name };
  });

  const amountForList = initialFee?.data?.amountForList || {};
  const amountBD = Object.entries(amountForList).map(([value, label]) => ({
    value: Number(value),
    label: label,
  }));

  const defaultValue = {
    value: "",
    label: "নির্বাচন করুন",
  };

  const loadOptions = useCallback(
    async (inputValue, callback) => {
      setSearchText(inputValue);
      if (page !== 1) {
        setPage(1); // Reset page when search text changes
      }
      try {
        const data = await fetchBankBranches({
          bank_id,
          name: inputValue,
          page: 1,
        });
        const branches = data?.data?.data.map((item) => ({
          value: item.id,
          label: item.name,
        }));
        setBankBranches(branches);
        callback(branches);
      } catch (error) {
        console.error("Error loading options:", error);
      }
    },
    [bank_id, page]
  );

  const handleMenuScrollToBottom = async () => {
    const newPage = page + 1;
    setPage(newPage);
    try {
      const data = await fetchBankBranches({
        bank_id,
        name: searchText,
        page: newPage,
      });
      const newBranches = data?.data?.data.map((item) => ({
        value: item.id,
        label: item.name,
      }));
      setBankBranches((prevBranches) => [...prevBranches, ...newBranches]);
    } catch (error) {
      console.error("Error scrolling to bottom:", error);
    }
  };

  const {
    values,
    touched,
    errors,
    handleBlur,
    handleChange,
    handleSubmit,
    setValues,
    setErrors,
    resetForm,
    setFieldValue,
  } = useFormik({
    initialValues,
    validationSchema: validationSchema,
    onSubmit: async (values, { setSubmitting }) => {
      try {
        const formData = createFormData(values);
        await createApplication(formData);
      } catch (error) {
        console.error("Error submitting form:", error);
        setSubmitting(false);
      }
    },
  });

  useEffect(() => {
    if (initialFee?.data) {
      setValues((prev) => ({
        ...prev,
        name: initialFee?.data?.profileDetails?.name,
        email: initialFee?.data?.profileDetails?.email,
        mobile: initialFee?.data?.profileDetails?.mobile,
        amountFor: amountBD?.[0]?.value || "",
      }));
    }
  }, [initialFee?.data]);

  const selectedOption = amountBD.find(
    (option) => option.value === values.amountFor
  );

  useEffect(() => {
    if (isErrorCreate) {
      setErrors(
        transformErrorsToObjectStructure(errorCreate?.data?.data || {})
      );
      toast.error(errorCreate?.data?.message);
    }
    if (isSuccessCreate) {
      toast.success(dataCreate?.message);
      if (Number(dataCreate?.data?.st) === 3) {
        window.location.href = dataCreate?.data?.redirect_url;
      }
      if (Number(dataCreate?.data?.st) === 2) {
        if (Number(dataCreate?.data?.apptype) === 1) {
          window.location.href = `/applicant/application-full-preview/${profileId}`;
        }
        if (Number(dataCreate?.data?.apptype) === 2) {
          window.location.href = `/contract-full-preview/${profileId}`;
        }
      }
    }
    // eslint-disable-next-line
  }, [isErrorCreate, isSuccessCreate]);

  const toggleHowToPay = (side) => {
    setValues((prev) => ({
      ...prev,
      st: side,
      treasuryNo: "",
      treasuryDate: "",
      treasuryBank: "",
      bankbranch_id: "",
      treasury: "",
    }));
  };

  useEffect(() => {
    if (profileId) {
      setValues((prev) => ({ ...prev, application_id: Number(profileId) }));
    }
  }, [profileId]);

  const handleFileChange = (field, e) => {
    const file = e.currentTarget.files[0];
    setFieldValue(field, file);
  };

  const handleSelectChange = async (name, e) => {
    if (name === "treasuryBank") {
      setBank_id(e.value);
      setBankBranches([]); // Clear bank branches when bank_id changes
      setFieldValue("bankbranch_id", defaultValue.value); // Reset selected bank branch
      try {
        const data = await fetchBankBranches({
          bank_id: e.value,
          name: "",
          page: page,
        });
        const branches = data?.data?.data.map((item) => ({
          value: item.id,
          label: item.name,
        }));
        setBankBranches(branches);
      } catch (error) {
        console.error("Error fetching bank branches:", error);
      }
    }
    setValues((prev) => ({ ...prev, [name]: e.value }));
  };

  if (isLoadingBank || isLoadingInitialFee) {
    return <Loader />;
  }

  return (
    <div className="pb-5">
      <section className="section py-5 service-single">
        <div className="container pb-5">
          <div className="d-flex justify-content-center align-items-center bg-light">
            <div className="card p-3 shadow w-100">
              <nav>
                <div className="nav nav-tabs" id="nav-tab" role="tablist">
                  <button
                    className="nav-link active fw-semibold"
                    id="nav-application-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#nav-application"
                    type="button"
                    role="tab"
                    aria-controls="nav-application"
                    aria-selected="true"
                  >
                    {Number(initialFee?.data?.apptype) === 1
                      ? "কপিরাইট"
                      : "চুক্তিপত্র"}{" "}
                    : রেজিস্ট্রেশন ফি এবং পেমেন্ট অপশন
                  </button>
                </div>
              </nav>
              <div
                className="tab-content p-3 border border-top-0 bg-light"
                id="nav-tabContent"
              >
                <div
                  className="tab-pane fade active show"
                  id="nav-application"
                  role="tabpanel"
                  aria-labelledby="nav-application-tab"
                >
                  {initialFee?.data?.trackReg ? (
                    <div className="applicationForm">
                      <div className="applicationForm-body">
                        <div className="applicationForm-body-top">
                          <div className="d-flex mt-4">
                            <span className="d-flex">
                              <div className="form-check mr-3">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="howToPayRadio"
                                  id="howToPayRadio1"
                                  checked={Number(initialFee?.data?.st) === 3}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="howToPayRadio1"
                                >
                                  ই-চালান
                                </label>
                              </div>
                              <div className="form-check mx-3">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="howToPayRadio"
                                  id="howToPayRadio2"
                                  checked={Number(initialFee?.data?.st) === 2}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="howToPayRadio2"
                                >
                                  ট্রেজারি
                                </label>
                              </div>
                            </span>
                          </div>

                          <div>
                            <div className={`${styles.howToPay}  my-4`}>
                              <div className="row">
                                {Number(initialFee?.data?.st) === 2 && (
                                  <>
                                    <div className="col-md-4 pt-3">
                                      <div className="form-group">
                                        <label>ট্রেজারি চালান নং </label>
                                        <div>
                                          {initialFee?.data?.treasuryNo}
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-md-4 pt-3">
                                      <div className="form-group">
                                        <label>ব্যাংকের নাম </label>
                                        <div>
                                          {
                                            initialFee?.data?.bankbranch?.bank
                                              ?.name
                                          }
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-md-4 pt-3">
                                      <div className="form-group">
                                        <label>শাখা </label>
                                        <div>
                                          {initialFee?.data?.bankbranch?.name}
                                        </div>
                                        <div>
                                          {errors.bankbranch_id &&
                                            touched.bankbranch_id && (
                                              <small className="my-2 text-danger">
                                                {errors.bankbranch_id}
                                              </small>
                                            )}
                                        </div>
                                      </div>
                                    </div>
                                  </>
                                )}

                                {Number(initialFee?.data?.st) === 3 && (
                                  <>
                                    <div className="col-md-4 pt-3">
                                      <div className="form-group ">
                                        <img src={assets?.echalan} alt="Logo" />
                                      </div>
                                    </div>
                                    <div className="col-md-4 pt-3">
                                      <div className="form-group">
                                        <label>ট্রানজ্যাকশন নম্বর</label>
                                        <div>
                                          {" "}
                                          {initialFee?.data?.transactionNo}
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-md-4 pt-3">
                                      <div className="form-group">
                                        <label>ই-চালান নম্বর</label>
                                        <div>
                                          {" "}
                                          {initialFee?.data?.echallanId}
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-md-4 pt-3"></div>
                                  </>
                                )}
                                <div className="col-md-4 pt-3">
                                  <div className="form-group">
                                    <label>টাকার পরিমাণ </label>
                                    <div>
                                      <div>{initialFee?.data?.amount}</div>
                                    </div>
                                  </div>
                                </div>

                                <div className="col-md-4 pt-3">
                                  <div className="form-group">
                                    <label>
                                      {Number(initialFee?.data?.st) === 3
                                        ? "ট্রানজ্যাকশন"
                                        : "ট্রেজারি"}{" "}
                                      তারিখ
                                    </label>
                                    <div>
                                      {" "}
                                      {localDateFormat(
                                        initialFee?.data?.treasuryDate
                                      )}
                                    </div>
                                  </div>
                                </div>
                                {Number(initialFee?.data?.st) === 2 && (
                                  <div className="col-md-4 pt-3">
                                    <div
                                      className={`${styles.add2FormGroupImagePreviewBox} form-group`}
                                    >
                                      <label>
                                        ট্রেজারি চালান ( স্ক্যান কপি )
                                      </label>
                                      <br />
                                      <div
                                        className="py-1 px-3 rounded rounded-1"
                                        style={{
                                          border: "1px dotted #848484",
                                          cursor: "pointer",
                                        }}
                                        onClick={() => {
                                          setPreviewModalURL(
                                            initialFee?.data?.treasury
                                              ? true
                                              : false
                                          );
                                          setPreviewFile(
                                            initialFee?.data?.treasury
                                          );
                                        }}
                                      >
                                        <span>Preview</span>
                                      </div>
                                    </div>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="applicationForm">
                      <div className="applicationForm-body">
                        <div className="applicationForm-body-top">
                          <div className="d-flex mt-4">
                            <span className="d-flex">
                              <div className="form-check mr-3">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="howToPayRadio"
                                  id="howToPayRadio1"
                                  defaultChecked={values.st === 3}
                                  onClick={() => toggleHowToPay(3)}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="howToPayRadio1"
                                >
                                  ই-চালান
                                </label>
                              </div>
                              <div className="form-check mx-3">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="howToPayRadio"
                                  id="howToPayRadio2"
                                  defaultChecked={values.st === 2}
                                  onClick={() => toggleHowToPay(2)}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="howToPayRadio2"
                                >
                                  ট্রেজারি
                                </label>
                              </div>
                            </span>
                          </div>

                          <div>
                            <div className={`${styles.howToPay}  my-4`}>
                              <div className="row">
                                {values.st === 2 && (
                                  <>
                                    <div className="col-md-4 pt-3">
                                      <div className="form-group">
                                        <label>ট্রেজারি চালান নং </label>
                                        <ClientFormikFormFieldMultiple
                                          placeholder="ট্রেজারি চালান নং"
                                          value={values.treasuryNo}
                                          name={`treasuryNo`}
                                          errors={
                                            errors?.treasuryNo &&
                                            errors?.treasuryNo
                                          }
                                          touched={
                                            touched?.treasuryNo &&
                                            touched?.treasuryNo
                                          }
                                          handleChange={handleChange}
                                          handleBlur={handleBlur}
                                        />
                                      </div>
                                    </div>
                                    <div className="col-md-4 pt-3">
                                      <div className="form-group">
                                        <label>তারিখ </label>
                                        <div
                                          className={`${styles.add2PanelBackground} pb-2`}
                                        >
                                          <BanglaCalender
                                            placeholder="তারিখ"
                                            value={values?.treasuryDate}
                                            onChange={(date) => {
                                              setValues((prevValues) => ({
                                                ...prevValues,
                                                treasuryDate:
                                                  globalDateFormat(date),
                                              }));
                                            }}
                                          />
                                        </div>
                                        <div>
                                          {errors.treasuryDate &&
                                            touched.treasuryDate && (
                                              <small className="my-2 text-danger">
                                                {errors.treasuryDate}
                                              </small>
                                            )}
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-md-4 pt-3">
                                      <div className="form-group">
                                        <label>ব্যাংকের নাম </label>
                                        <div>
                                          <Select
                                            onChange={(e) => {
                                              setBank_id(e.value);
                                              handleSelectChange(
                                                "treasuryBank",
                                                e
                                              );
                                            }}
                                            onBlur={handleBlur}
                                            value={
                                              values.treasuryBank
                                                ? treasuryBankName.find(
                                                    (option) =>
                                                      option.value ===
                                                      values.treasuryBank
                                                  )
                                                : defaultValue
                                            }
                                            options={treasuryBankName}
                                            classNamePrefix="react-select"
                                          />
                                          <div>
                                            {errors.treasuryBank &&
                                              touched.treasuryBank && (
                                                <small className="my-2 text-danger">
                                                  {errors.treasuryBank}
                                                </small>
                                              )}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-md-4 pt-3">
                                      <div className="form-group">
                                        <label>শাখা </label>
                                        <div>
                                          <AsyncSelect
                                            value={
                                              values.bankbranch_id
                                                ? bankBranches.find(
                                                    (option) =>
                                                      option.value ===
                                                      values.bankbranch_id
                                                  )
                                                : defaultValue
                                            }
                                            loadOptions={loadOptions}
                                            onMenuScrollToBottom={
                                              handleMenuScrollToBottom
                                            }
                                            cacheOptions
                                            defaultOptions={bankBranches}
                                            onChange={(e) =>
                                              handleSelectChange(
                                                "bankbranch_id",
                                                e
                                              )
                                            }
                                          />
                                        </div>
                                        <div>
                                          {errors.bankbranch_id &&
                                            touched.bankbranch_id && (
                                              <small className="my-2 text-danger">
                                                {errors.bankbranch_id}
                                              </small>
                                            )}
                                        </div>
                                      </div>
                                    </div>
                                  </>
                                )}
                                {values.st === 3 && (
                                  <>
                                    <div className="col-md-4 pt-3">
                                      <div className="form-group ">
                                        <img src={assets?.echalan} alt="Logo" />
                                      </div>
                                    </div>

                                    <div className="col-md-4 d-md-none">
                                      <Alert>
                                        পেমেন্ট বিবরণে আপনার নাম এবং ফোন নম্বর
                                        ইংরেজিতে লিখুন।
                                      </Alert>
                                    </div>
                                    <div className="col-md-4 pt-3">
                                      <div className="form-group">
                                        <label>নাম ( ইংরেজি )</label>
                                        <ClientFormikFormFieldMultiple
                                          placeholder="নাম ( ইংরেজি )"
                                          value={values.name}
                                          name={`name`}
                                          errors={errors?.name && errors?.name}
                                          touched={
                                            touched?.name && touched?.name
                                          }
                                          handleChange={handleChange}
                                          handleBlur={handleBlur}
                                        />
                                      </div>
                                    </div>
                                    <div className="col-md-4 pt-3">
                                      <div className="form-group">
                                        <label>মোবাইল ( ইংরেজি )</label>
                                        <ClientFormikFormFieldMultiple
                                          placeholder="মোবাইল ( ইংরেজি )"
                                          value={values.mobile}
                                          name={`mobile`}
                                          errors={
                                            errors?.mobile && errors?.mobile
                                          }
                                          touched={
                                            touched?.mobile && touched?.mobile
                                          }
                                          handleChange={handleChange}
                                          handleBlur={handleBlur}
                                        />
                                      </div>
                                    </div>
                                    <div className="col-md-4 d-none d-md-block">
                                      <Alert>
                                        পেমেন্ট বিবরণে আপনার নাম এবং ফোন নম্বর
                                        ইংরেজিতে লিখুন।
                                      </Alert>
                                    </div>

                                    <div className="col-md-4 pt-3">
                                      <div className="form-group">
                                        <label>ই-মেইল</label>
                                        <ClientFormikFormFieldMultiple
                                          placeholder="ই-মেইল"
                                          value={values.email}
                                          name={`email`}
                                          errors={
                                            errors?.email && errors?.email
                                          }
                                          touched={
                                            touched?.email && touched?.email
                                          }
                                          handleChange={handleChange}
                                          handleBlur={handleBlur}
                                        />
                                      </div>
                                    </div>
                                  </>
                                )}
                                <div className="col-md-4 pt-3">
                                  <div className="form-group">
                                    <label>টাকার পরিমাণ</label>
                                    <div>
                                      <div>
                                        <Select
                                          onChange={(e) => {
                                            console.log(e)
                                            handleSelectChange("amountFor", e);
                                          }}
                                          onBlur={handleBlur}
                                          options={amountBD}
                                          value={selectedOption}
                                          classNamePrefix="react-select"
                                        />
                                        <div>
                                          {errors.amountFor &&
                                            touched.amountFor && (
                                              <small className="my-2 text-danger">
                                                {errors.amountFor}
                                              </small>
                                            )}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                {values.st === 2 && (
                                  <div className="col-md-4 pt-3">
                                    <div
                                      className={`${styles.add2FormGroupImagePreviewBox} form-group text-center`}
                                    >
                                      <label>
                                        ট্রেজারি চালান ( স্ক্যান কপি )
                                      </label>
                                      <br />
                                      <div
                                        className="py-1 px-3 rounded rounded-1"
                                        style={{
                                          border: "1px dotted #848484",
                                          cursor: "pointer",
                                        }}
                                        onClick={() => {
                                          setPreviewModal(
                                            values.treasury ? true : false
                                          );
                                          setPreviewFile(values.treasury);
                                        }}
                                      >
                                        <span>
                                          {values.treasury
                                            ? "Preview"
                                            : "No select file"}
                                        </span>
                                      </div>
                                      <div>
                                        {errors.treasury && (
                                          <small className="my-2 text-danger">
                                            {errors.treasury}
                                          </small>
                                        )}
                                      </div>
                                      <label className="custombtn5 mb-3">
                                        <input
                                          type="file"
                                          accept=".png,.jpg,.jpeg,.pdf"
                                          className="d-none"
                                          onChange={(e) => {
                                            handleFileChange(`treasury`, e);
                                          }}
                                        />{" "}
                                        নির্বাচন করুন
                                      </label>
                                    </div>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="applicationForm-body-footer text-center mt-5">
                          <button
                            className="custombtn5"
                            type="submit"
                            onClick={handleSubmit}
                            disabled={isLoadingCreate}
                          >
                            {isLoadingCreate ? (
                              <BeatLoader />
                            ) : values.st === 2 ? (
                              "দাখিল করুন"
                            ) : (
                              <>
                                <img
                                  style={{ width: "30px" }}
                                  src={assets?.pay}
                                  alt="Logo"
                                />
                                <span>পেমেন্ট করুন</span>
                              </>
                            )}
                          </button>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {previewModal && (
        <FilePreviewModal
          setPreviewModal={setPreviewModal}
          previewModal={previewModal}
          previewFile={previewFile}
          setPreviewFile={setPreviewFile}
        />
      )}
      {previewModalURL && (
        <ShowPreviewFiles
          setPreviewModal={setPreviewModalURL}
          previewModal={previewModalURL}
          previewUrl={previewFile}
          setPreviewUrl={setPreviewFile}
        />
      )}
    </div>
  );
}
