import React from "react";
import SubmittedAgreement from "../../sumitted_agreement/SubmittedAgreement";

const UnauthorizedAgreement = () => {
  const fileStatus = 6;
  const applicationPath = {
    copyright: "/admin/unauthorized-copyright",
    agreement: "/admin/unauthorized-agreement",
  };
  
  // Add route to details page
  const detailsRoute = "/admin/unauthorized-agreement-details";
  const pageTitle  = "চূড়ান্তভাবে অননুমোদন";

  return (
    <SubmittedAgreement
      fileStatus={fileStatus}
      applicationPath={applicationPath}
      detailsRoute={detailsRoute}
      pageTitle={pageTitle}
    />
  );
};

export default UnauthorizedAgreement;
