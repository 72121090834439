import { useState } from "react";

export const useTextToggle = (text, maxLength = 50) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleExpansion = () => {
    setIsExpanded(!isExpanded);
  };

  const shouldTruncate = text?.length > maxLength;
  const displayText =
    isExpanded || !shouldTruncate ? text : text.substring(0, maxLength) + "...";

  return { displayText, isExpanded, toggleExpansion, shouldTruncate };
};
