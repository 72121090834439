import { CATALOG_REGISTRATION } from "../constants";
import { apiSlice } from "./apiSlice";

const catalogRegistrationApiSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getCatalogRegistration: builder.query({
            query: (slug) => {
                if (slug) {
                    return `${CATALOG_REGISTRATION}?slug=${slug}`;
                }
                return CATALOG_REGISTRATION;
            },
        }),
    }),
});


export const {useGetCatalogRegistrationQuery} = catalogRegistrationApiSlice;