import React from "react";
import { Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import { convertToBanglaNumerals } from "../../utils/convertToBanglaNumerals";
import { localDateFormat } from "../../utils/dateFormat";
import CommentCell from "../CommentCell";

const AgreementTable = ({ data }) => {
  const enTobn = (num) => {
    // Convert English numbers to Bengali numbers
    const enToBnMap = {
      0: "০",
      1: "১",
      2: "২",
      3: "৩",
      4: "৪",
      5: "৫",
      6: "৬",
      7: "৭",
      8: "৮",
      9: "৯",
    };
    return num
      .toString()
      .split("")
      .map((char) => enToBnMap[char] || char)
      .join("");
  };

  const getCategorySubs = (dc) => {
    let catSubs = "";
    if (dc.workTitle) {
      catSubs += `${dc.workTitle}, `;
    }
    catSubs += dc.category.name;
    if (dc.subCategory) {
      catSubs += `, ${dc.subCategory.name}`; // Adjust according to your actual structure
    }
    return catSubs;
  };

  return (
    <div style={{ overflowX: "auto" }}>
      <Table striped bordered>
        <thead>
          <tr className="reg_book">
            <th>ক্রমিক নং </th>
            <th> নথি নাম্বার </th>
            <th> নিবন্ধন সংখ্যা </th>
            <th>আবেদনকারীর ধরণ - নাম ও ঠিকানা</th>
            <th>পক্ষদ্বয়ের নাম ও ঠিকানা</th>
            <th>সাক্ষীর নাম ও ঠিকানা</th>
            <th>কর্মটির শিরোনাম সেক্টর ও উপশ্রেণী</th>
            <th>কর্মটির স্বত্ব প্রাপ্তির উৎস</th>
            <th>দলিলের মেয়াদ ও সম্পাদনের তারিখ</th>
            <th>স্বত্ব নিয়োগের ভৌগলিক পরিধি</th>
            <th>বিনিময় মূল্য ও রয়্যালিটির পরিমান</th>
            <th>মন্তব্য&nbsp;(&nbsp;যদি&nbsp;থাকে&nbsp;)</th>
          </tr>
        </thead>
        <tbody className="reg_book_content">
          {data?.data.map((archives, index) => {
            const dc = archives.document;
            const ac = archives.archive;
            const profiletypes = dc.profiletypes;
            let title = "";

            switch (Number(dc.source)) {
              case 1:
                title = "প্রণেতা";
                break;
              case 2:
                title = "বিনিয়োগকারী প্রযোজক";
                break;
              case 3:
                title = "উত্তরাধিকার";
                break;
              case 4:
                title = "ক্রয়সূত্রে";
                break;
              default:
                title = "";
            }

            const catSubs = getCategorySubs(dc);
            const color =
              ac.certificate_canceled === 1 ? "bg-danger text-white" : "";

            const generateText = (profiletypes, protype) => {
              return profiletypes
                .filter((favor) => Number(favor.protype) === protype)
                .map((favor) => `${favor.name}, ${favor.address}`)
                .join(", ");
            };

            const fullTextOne = `
              <b>১ম পক্ষ</b> - ${generateText(
                profiletypes,
                1
              )}<br /><div>&nbsp; </div>
              <b>২য় পক্ষ</b> - ${generateText(profiletypes, 2)}
            `;
            const fullTextTwo = `
              <b>১ম পক্ষ</b> - ${generateText(
                profiletypes,
                3
              )}<br /><div>&nbsp; </div>
              <b>২য় পক্ষ</b> - ${generateText(profiletypes, 4)}
            `;

            return (
              <tr key={index} className={color}>
                <td className={color}>{convertToBanglaNumerals(index + 1)}</td>
                <td className={color}>
                  {Number(ac.certificate_canceled) === 1 ? (
                    archives.trackReg
                  ) : (
                    <Link to={`/edit/${ac.id}/`}>{archives.trackReg}</Link>
                  )}
                </td>
                <td className={`${color} text-center`}>
                  {ac.registration_no || "---"}
                </td>
                <td className={color}>{`${
                  Number(archives?.teamType) === 1
                    ? "স্বত্বনিয়োগ কর্ম"
                    : "লাইসেন্স প্রদান কর্ম"
                } - ${archives.puname}, ${archives.communicationAddress}`}</td>
                <td className={color}>
                  <CommentCell maxLength={45} text={fullTextOne} />
                </td>
                <td className={color}>
                  <CommentCell maxLength={45} text={fullTextTwo} />
                </td>
                <td className={color}>{catSubs}</td>
                <td className={color}>{title}</td>
                <td className={color}>{`${convertToBanglaNumerals(
                  dc.documentExpire
                )}, ${localDateFormat(dc.contractDate)}`}</td>
                <td className={color}>{dc.geoperimeter}</td>
                <td
                  className={color}
                >{`${dc.geoperimeter}, ${dc.quantity}`}</td>
                <td className={color}>
                  <CommentCell maxLength={30} text={ac.comments} />
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </div>
  );
};

export default AgreementTable;
