import { Copyright, Home, Settings } from "@mui/icons-material";
import { Box } from "@mui/material";
import React, { useEffect } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import IconBreadcrumbs from "../../../components/custom/IconBreadcrumbs";
import Loader from "../../../components/custom/Loader";
import {
  useCreateOrUpdateCopyrightOfficeMutation,
  useGetCopyrightOfficeQuery,
} from "../../../redux/api/copyrightOfficeApiSlice";
import FormikFormField from "../../../components/custom/form/FormikFormField";
import toast from "react-hot-toast";
import { transformErrorsToObjectStructure } from "../../../utils/transformErrorsToObjectStructure";
import { Button } from "react-bootstrap";

const CopyrightOffice = () => {
  const { data, isLoading } = useGetCopyrightOfficeQuery();
  const [
    createOrUpdate,
    {
      isLoading: isLoadingSubmit,
      isError: isErrorUpdate,
      isSuccess: isSuccessUpdate,
      error: errorUpdate,
      data: dataUpdate,
    },
  ] = useCreateOrUpdateCopyrightOfficeMutation();

  const formik = useFormik({
    initialValues: {
      title1: data?.data?.title1 || "",
      title2: data?.data?.title2 || "",
      description1: data?.data?.description1 || "",
      description2: data?.data?.description2 || "",
    },
    validationSchema: yup.object().shape({
      title1: yup.string().required("শিরোনাম প্রযোজ্য।।"),
      title2: yup.string().required("শিরোনাম প্রযোজ্য।।"),
      description1: yup.string().required("বিবরণ প্রযোজ্য।।"),
      description2: yup.string().required("বিবরণ প্রযোজ্য।।"),
    }),
    onSubmit: async (values) => {
      try {
        await createOrUpdate(values).unwrap(); // Unwrap to get the result or error
      } catch (error) {
        console.error("Submission error:", error); // Handle error as needed
      }
    },
    enableReinitialize: true, // Reset form when data changes
  });

  const { values, touched, errors, handleBlur, handleChange, handleSubmit } =
    formik;

  useEffect(() => {
    if (isErrorUpdate) {
      toast.error(errorUpdate?.data?.message || "সংযোগে ত্রুটি ঘটেছে।");
      formik.setErrors(
        transformErrorsToObjectStructure(errorUpdate?.data?.data || {})
      );
    }
    if (isSuccessUpdate) {
      toast.success(dataUpdate?.message || "তথ্য সফলভাবে আপডেট হয়েছে।");
    }
  }, [isErrorUpdate, isSuccessUpdate]);

  const breadcrumbsData = [
    { icon: Home, title: "হোম", url: "/" },
    { icon: Settings, title: "সেটিংস" },
    {
      icon: Copyright,
      title: "কপিরাইট অফিস",
      active: true,
    },
  ];

  if (isLoading) {
    return <Loader />;
  }

  return (
    <div>
      <Box
        display={"flex"}
        justifyContent={"space-between"}
        alignItems={"center"}
        py={1}
        mb={1}
      >
        <IconBreadcrumbs breadcrumbs={breadcrumbsData} />
      </Box>
      <div className="slideUp dashboard-layout p-4">
        <h5 className="mb-3">কপিরাইট অফিস</h5>
        <form onSubmit={handleSubmit}>
          <div className="row">
            <div className="col-md-6">
              <FormikFormField
                value={values.title1}
                name="title1"
                label="শিরোনাম ১"
                placeholder="শিরোনাম ১ লিখুন"
                errors={errors}
                touched={touched}
                handleChange={handleChange}
                handleBlur={handleBlur}
              />
            </div>
            <div className="col-md-6">
              <FormikFormField
                value={values.title2}
                name="title2"
                label="শিরোনাম ২"
                placeholder="শিরোনাম ২ লিখুন"
                errors={errors}
                touched={touched}
                handleChange={handleChange}
                handleBlur={handleBlur}
              />
            </div>
            <div className="col-md-6">
              <FormikFormField
                value={values.description1}
                name="description1"
                label="বিবরণ ১"
                placeholder="বিবরণ ১ লিখুন"
                errors={errors}
                touched={touched}
                handleChange={handleChange}
                handleBlur={handleBlur}
                additional={{
                  as: "textarea",
                  rows: "8",
                }}
              />
            </div>
            <div className="col-md-6">
              <FormikFormField
                value={values.description2}
                name="description2"
                label="বিবরণ ২"
                placeholder="বিবরণ ২ লিখুন"
                errors={errors}
                touched={touched}
                handleChange={handleChange}
                handleBlur={handleBlur}
                additional={{
                  as: "textarea",
                  rows: "8  ",
                }}
              />
            </div>
          </div>
          <div className="col-md-12 text-center">
            <Button type="submit" disabled={isLoadingSubmit}>
              {isLoadingSubmit ? "Submitting..." : "দাখিল করুন"}
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default CopyrightOffice;
