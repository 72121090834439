export const transformAgreementData = (documents) => {
  const forInfo = {
    st: Number(documents?.st),
    tradelicense: documents?.tradelicense,
    tincertificate: documents?.tincertificate,

    // value
    treasury:
      Number(documents.st) === 2 ? documents?.treasury : documents?.echallanId,
    applicant: documents?.applicant,
    nid: documents?.nid,
    istructure: documents?.istructure,
  };

  return forInfo;
};

