import React, { useEffect, useState } from "react";
import styles from "./ContractCopy.module.css";
import { localDateFormat } from "../../../../utils/dateFormat";
import { useAuth } from "../../../../utils/authorization";
import ClientFormikFormFieldMultiple from "../../../../components/custom/form/ClientFormikFormFieldMultiple";
import { useGetAgreementsQuery } from "../../../../redux/api/agreementsApiSlice";
import { Link, useParams } from "react-router-dom";
import ShowFilePreviewModal from "../form_two/ShowPreviewFiles";
import Loader from "../../../../components/custom/Loader";
import AgreementPreviewFormOne from "../../../../components/preview_applications/agreement/AgreementPreviewFormOne";

export default function ContractCopyPreview() {
  const { id } = useParams();
  const { authUser } = useAuth();

  const [previewModal, setPreviewModal] = useState(false);
  const [previewFile, setPreviewFile] = useState("");
  const handlePreview = (file) => {
    setPreviewFile(file);
    setPreviewModal(true);
  };
  
  const {
    data: showData,
    isLoading: isLoadingShow,
    refetch,
  } = useGetAgreementsQuery(id);
  const mainData = showData?.data;

  useEffect(() => {
    if (id) {
      refetch();
    }
  }, [id]);

  const toDay = localDateFormat(new Date());


  if (isLoadingShow) {
    return <Loader />;
  }
  return (
    <div>
      <section className="section pt-3 pb-5 chuktipotro2 service-single">
        <div className="container">
          <div className="d-flex justify-content-center align-items-center">
            <div className="card w-100">
              <nav>
                <div className="nav nav-tabs" id="nav-tab" role="tablist">
                  <button
                    className="nav-link active"
                    id="nav-application-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#nav-application"
                    type="button"
                    role="tab"
                    aria-controls="nav-application"
                    aria-selected="true"
                  >
                    প্রথম অংশ
                  </button>
                </div>
              </nav>
              <div
                className="tab-content p-4 border border-top-0 "
                id="nav-tabContent"
              >
                <div
                  className="tab-pane fade active show"
                  id="nav-application"
                  role="tabpanel"
                  aria-labelledby="nav-application-tab"
                >
                  <div className="applicationForm">
                    <div
                      className={`${styles.applicationFormheader} text-center`}
                    >
                      <h4 className="">
                        মেধাস্বত্ব হস্তান্তর / লাইসেন্স প্রদান সংক্রান্ত দলিল
                        রেজিস্ট্রেশন ফরম
                      </h4>
                    </div>
                    <div className="applicationForm-body">
                      <div className="applicationForm-body-top mt-5">
                        <p className="mb-0">বরাবর</p>
                        <p className="unbold">
                          রেজিস্ট্রার অব কপিরাইটস <br />
                          বাংলাদেশ কপিরাইট অফিস।
                        </p>
                        <p className="mb-0">জনাব</p>
                        <p className="unbold">
                          কপিরাইট আইন (২০২৩ সালের ৩৪ নং আইন এবং সংশোধিত- ২০০৫)
                          এর ৫৪ ধারা অনুযায়ী আমি মেধাস্বত্ব হস্তান্তর / লাইসেন্স
                          প্রদান সংক্রান্ত দলিল নিবন্ধনের জন্য আবেদন করছি এবং
                          দলিল নিবন্ধন বহিতে হস্তান্তরিত কর্ম সংক্রান্ত তথ্যসমূহ
                          লিপিবদ্ধ করার জন্য দুইকপি সংশ্লিষ্ট কর্ম ও বিবরণী
                          সংযুক্ত করছি।
                        </p>

                        <div className="my-3">
                          <div className="row">
                            <div class="col-sm-2">
                              <p className="mb-0">১. আবেদন কারীর ধরণ -</p>
                            </div>

                            <div className="col-sm-10">
                              <label className="radio-inline">
                                <input
                                  name="source"
                                  type="radio"
                                  value="1"
                                  checked={Number(mainData?.teamType) === 1}
                                  readOnly
                                />{" "}
                                ক. সত্মনিয়োগ কর্ম
                              </label>
                              <label className="radio-inline ms-3">
                                <input
                                  name="source"
                                  type="radio"
                                  value="2"
                                  checked={Number(mainData?.teamType) === 2}
                                  readOnly
                                />{" "}
                                খ. লাইসেন্স প্রদান কর্ম
                              </label>
                            </div>
                          </div>
                        </div>

                        <div className="my-2">
                            <div className={styles.add2sectiontitle}>
                                <p className="mb-1"> ২. সংযুক্তিঃ</p>
                            </div>
                            <div className="table-responsive">
                                <table className="table text-center table-striped mb-2 table-bordered hover">
                                    <thead>
                                        <tr>
                                            <th>নিয়োগকারীর ছবি</th>
                                            <th>পরিচয় পত্রের স্ক্যান কপি</th>
                                            <th>মূল দলিল ও নোটারি পাবলিক ফটোকপি</th>
                                            <th>ট্রেড লাইসেন্সের স্ক্যান কপি</th>
                                            <th>টিন সার্টিফিকেটের স্ক্যান কপি</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            {[
                                                { label: "applicant" },
                                                { label: "nid" },
                                                { label: "istructure" },
                                                { label: "tradelicense" },
                                                { label: "tincertificate" },
                                            ].map(({ label }, index) => (
                                                <td key={label}>
                                                    {index === 0 ? (                                 
                                                        <div className={`${styles.imagePreviewAdd2}`}>
                                                            {mainData?.[label] ? (
                                                                <img
                                                                    src={mainData[label]}
                                                                    alt={label}
                                                                    style={{ width: "100px", height: "auto" }}
                                                                />
                                                            ) : (
                                                                "কোনো ফাইল নেই"
                                                            )}
                                                        </div>
                                                    ) : (                                                      
                                                        <div
                                                            style={{ cursor: "pointer" }}
                                                            onClick={() => handlePreview(mainData?.[label])}
                                                        >
                                                            <div className={`${styles.imagePreviewAdd2}`}>
                                                                <span className="custombtn55 mb-1">
                                                                    {mainData?.[label]
                                                                        ? "এখানে দেখুন"
                                                                        : "কোনো ফাইল নেই"}
                                                                </span>
                                                            </div>
                                                        </div>
                                                    )}
                                                </td>
                                            ))}
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            {previewModal && (
                                <ShowFilePreviewModal
                                    file={previewFile}
                                    onClose={() => setPreviewModal(false)}
                                />
                            )}
                        </div>

                      </div>

                      <div className="applicationForm-body-bottom mt-5">
                        <div className="row">
                          <div className="col-lg-4 col-md-4 col-sm-4">
                            <div className="form-group">
                                <div className="div">
                                <p className="border-bottom-dotted">{authUser?.email}</p>
                                </div>
                                <div className="div">
                                <p className="border-bottom-dotted">{mainData?.place}</p>
                                </div>
                                <div className="div">
                                <p className="">
                                    {mainData?.sendDate && localDateFormat(mainData?.sendDate)}
                                </p>
                                </div>
                            </div>
                          </div>

                          <div className="col-lg-4 col-md-4 col-sm-4"></div>

                          <div className="col-lg-4 col-md-4 col-sm-4">
                            <div className="form-group text-center">
                                <div className="div">
                                <p className="border-bottom-dotted mb-2">আপনার বিশ্বস্ত</p>
                                </div>                                 
                                <div className="mb-1 attachment-container">
                                {mainData?.sign && (
                                    <img className="img-fluid" src={mainData?.sign} alt="Signature Preview" />
                                )}
                                </div>
                                <div>
                                <p>{mainData?.puname}</p>
                                </div>
                            </div>
                          </div>

                          {Number(mainData?.document?.form_status) !==
                            2 && (
                            <div className="applicationForm-body-footer d-flex justify-content-center gap-2 text-center mt-5">
                              <Link
                                to={`/agreement-form-add-two/${id}`}
                                className="custombtn5 mr-3"
                              >
                                অগ্রসর হউন
                              </Link> 
                              <Link
                                to={`/agreement-form-add?id=${id}`}
                                className="custombtn5"
                              >
                                পরিবর্তন
                              </Link>
                            </div>
                          )}
                        </div>
                      </div>

                      {previewModal && (
                        <ShowFilePreviewModal
                          setPreviewModal={setPreviewModal}
                          previewModal={previewModal}
                          previewUrl={previewFile}
                          setPreviewUrl={setPreviewFile}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
