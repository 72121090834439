import { Handshake, Home } from "@mui/icons-material";
import debounce from "lodash/debounce";
import React, { useCallback, useEffect, useState } from "react";
import IconBreadcrumbs from "../../../../components/custom/IconBreadcrumbs";
import ReusableTable from "../../../../components/custom/data_table/ReusableTable";
import { useGetCommentAndOrderedQuery } from "../../../../redux/api/commenAndOrderedApiSlice";
import { useAuth } from "../../../../utils/authorization";
import {
  globalDateFormat,
  localDateFormat,
} from "../../../../utils/dateFormat";
import BanglaCalender from "../../../frontend/userPanel/form_two/BanglaCalender";
import ReplyOrdered from "./ReplyOrdered";

const CommentAndOrdered = () => {
  // State to track issue status for each certificate
  const [issueStatus, setIssueStatus] = useState({});
  const [date, setDate] = useState("");
  const [category_id, setCategoryId] = useState("");
  const [catId, setCatId] = useState("");
  const [subCategory, setSubCategory] = useState("");
  const [page, setPage] = useState(1);
  const [searchText, setSearchText] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const { authUser } = useAuth();
  const [showModal, setShowModal] = useState(false);
  const [replyData, setReplyData] = useState(null);

  const { data, isLoading, refetch } = useGetCommentAndOrderedQuery({
    date: globalDateFormat(date),
    trackReg: searchValue,
    page,
  });

  const breadcrumbsData = [
    { icon: Home, title: "হোম", url: "/" },
    {
      icon: Handshake,
      title: "নথি অনুসন্ধান আদেশ",
      active: true,
    },
  ];

  // data table start
  const totalData = data?.meta?.total;
  const lastPage = data?.meta?.last_page;

  const debouncedSearch = useCallback(
    debounce((value) => {
      setPage(1);
      setSearchValue(value);
      refetch();
    }, 400),
    []
  );

  useEffect(() => {
    // Cleanup function to cancel the debounce when the component unmounts or debouncedSearch changes
    return () => {
      debouncedSearch?.cancel();
    };
  }, [debouncedSearch]);

  const handleSearchChange = (value) => {
    setSearchText(value);
    debouncedSearch(value);
  };

  const handlePageChange = (page) => {
    setPage(page);
    refetch();
  };

  const columns = [
    { label: "তারিখ", name: "sendDate" },
    {
      label: "আবেদন নং",
      name: "trackReg",
      options: {
        customBodyRender: (value, tableMeta) => {
          return (
            <span
              className="text-primary cursor-pointer"
              onClick={() => {
                setShowModal(true);
                setReplyData(tableMeta?.rowData);
              }}
            >
              {value}
            </span>
          );
        },
      },
    },
    { label: "আবেদন ধরণ", name: "type" },
    {
      label: "মন্তব্য",
      name: "comment",
      options: {
        customBodyRender: (value, tableMeta) => {
          return (
            <div>
              <span>{value}</span> <br />
              <span>{tableMeta?.rowData?.userName}</span> <br />
              <span>{tableMeta?.rowData?.userRole}</span>
            </div>
          );
        },
      },
    },
    { label: "নথি অবস্থা", name: "fileStatusName" },
  ];

  const dataTable = data?.data.map((item) => ({
    sendDate: localDateFormat(item?.date),
    type: Number(item?.apptype) === 1 ? "কপিরাইট" : "চুক্তিপত্র",
    ...item,
  }));

  // if (isLoading) {
  //   return <Loader />;
  // }
  // handleDateChange
  const handleDateChange = (newDate) => {
    setDate(newDate);
    setPage(1);
  };

  return (
    <div>
      <div className="py-3">
        <IconBreadcrumbs breadcrumbs={breadcrumbsData} />
      </div>
      <div className="slideUp dashboard-layout p-3">
        <div
          style={{
            backgroundColor: "rgba(0, 0, 0, 0.1)",
          }}
          className="rounded mb-3 p-2"
        >
          <div className="row gap-2 gap-md-0">
            <div className="col-md-3 m-0">
              <BanglaCalender
                placeholder={"তারিখ"}
                value={date}
                onChange={handleDateChange}
              />
            </div>
          </div>
        </div>
        <div>
          <ReusableTable
            title={"নথি অনুসন্ধানের আবেদন পত্র"}
            columns={columns}
            data={dataTable}
            isLoading={isLoading}
            currentPage={page}
            lastPage={lastPage}
            totalData={totalData}
            onSearchChange={handleSearchChange}
            searchText={searchText}
            onPageChange={handlePageChange}
          />
        </div>
      </div>
      <ReplyOrdered
        data={replyData}
        show={replyData}
        onHide={() => {
          setShowModal(false);
          setReplyData(null);
        }}
      />
    </div>
  );
};

export default CommentAndOrdered;
