import React from "react";
import Form from "react-bootstrap/Form";

const ClientFormikYearSelector = ({
  lang = false,
  value,
  name,
  errors,
  touched,
  handleChange,
  handleBlur,
  additional,
  defaultOptions,
}) => {
  const years = Array.from(
    { length: 100 },
    (_, i) => new Date().getFullYear() - i
  );

  const formatInBangla = (number) => {
    return number
      .toString()
      .split("")
      .map((digit) => "০১২৩৪৫৬৭৮৯"[digit])
      .join("");
  };

  return (
    <Form.Group controlId={name}>
      <Form.Control
        as="select"
        value={value || ""}
        onChange={handleChange}
        onBlur={handleBlur}
        name={name}
        isInvalid={!!errors && !!errors[name] && touched && !!touched[name]}
        isValid={!!touched && touched[name] && !errors[name]}
        {...additional}
      >
        <option value="">
          {defaultOptions ? defaultOptions : lang ? "Year" : "বছর"}
        </option>
        {years.map((year) => (
          <option key={year} value={year}>
            {lang ? year : formatInBangla(year)}
          </option>
        ))}
      </Form.Control>
      {errors && touched && <small className="text-danger">{errors}</small>}
    </Form.Group>
  );
};

export default ClientFormikYearSelector;
