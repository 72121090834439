import React, { useCallback, useEffect, useState } from "react";
import ReusableTable from "../../../components/custom/data_table/ReusableTable";
import IconBreadcrumbs from "../../../components/custom/IconBreadcrumbs";
import { Box, Tooltip } from "@mui/material";
import Loader from "../../../components/custom/Loader";
import { Home, NotificationAdd } from "@mui/icons-material";
import { convertToBanglaNumerals } from "../../../utils/convertToBanglaNumerals";
import { Badge } from "react-bootstrap";
import {
  useDeleteAdminAttentionMessageMutation,
  useGetAdminAttentionMessagesQuery,
} from "../../../redux/api/attentionsMessage";
import debounce from "lodash/debounce";
import EditUpdate from "./EditUpdate";
import toast from "react-hot-toast";
import Swal from "sweetalert2";

const AttentionMessagesAdmin = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [searchText, setSearchText] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const [editData, setEditData] = useState("");
  const [deleteData, { isLoading: isLoadingDelete }] =
    useDeleteAdminAttentionMessageMutation();

  const {
    data: adminUser,
    isLoading,
    refetch,
  } = useGetAdminAttentionMessagesQuery({
    currentPage,
    title: searchValue,
  });

  const totalData = adminUser?.meta?.total;
  const lastPage = adminUser?.meta?.last_page;

  const debouncedSearch = useCallback(
    debounce((value) => {
      setCurrentPage(1);
      setSearchValue(value);
      refetch();
    }, 400),
    []
  );

  useEffect(() => {
    // Cleanup function to cancel the debounce when the component unmounts or debouncedSearch changes
    return () => {
      debouncedSearch?.cancel();
    };
  }, [debouncedSearch]);

  const handleSearchChange = (value) => {
    setSearchText(value);
    debouncedSearch(value);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
    refetch();
  };

  // handle delete
  const handleDelete = (id) => {
    Swal.fire({
      text: "আপনি কি নিশ্চিত?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      cancelButtonText: "না",
      confirmButtonText: "হ্যাঁ",
    }).then((result) => {
      if (result.isConfirmed) {
        deleteData(id).then(() => {
          toast.success("Deleted successfully");
        });
      }
    });
  };
  const columns = [
    { label: "ক্রমিক নং", name: "si" },

    { label: "এটেনশন বার্তা", name: "title" },

    {
      label: "স্ট্যাটাস",
      name: "status",
      options: {
        customBodyRender: (value) => {
          let status, color;

          switch (value) {
            case 1:
              status = "active";
              color = "success";
              break;
            case 0:
              status = "Inactive";
              color = "danger";
              break;
            default:
              status = null;
              color = null;
          }

          return <Badge bg={color}>{status}</Badge>;
        },
      },
    },
    {
      label: "ক্রিয়াকলাপ",
      name: "action",
      options: {
        customBodyRender: (value, tableMeta) => (
          <Box display={"flex"} gap={1}>
            <Tooltip title="Edit" placement="top" arrow>
              <Badge
                bg="warning"
                className="p-2"
                onClick={() => {
                  setEditData(tableMeta?.rowData);
                }}
                style={{ cursor: "pointer" }}
              >
                Edit
              </Badge>
            </Tooltip>

            <Tooltip title="Delete" placement="top" arrow>
              <Badge
                bg="danger"
                className="p-2"
                onClick={() => handleDelete(tableMeta?.rowData?.id)}
                style={{ cursor: "pointer" }}
              >
                {isLoadingDelete ? "Deleting..." : "Delete"}
              </Badge>
            </Tooltip>
          </Box>
        ),
      },
    },
  ];

  const data = adminUser?.data.map((item, i) => ({
    si: convertToBanglaNumerals(item?.sorting_index),
    ...item,
  }));

  const breadcrumbsData = [
    { icon: Home, title: "হোম" },
    {
      icon: NotificationAdd,
      title: "এটেনশন বার্তা সমুহঃ",
      active: true,
    },
  ];

  if (isLoading) {
    return <Loader />;
  }

  return (
    <div>
      <Box
        display={"flex"}
        justifyContent={"space-between"}
        alignItems={"center"}
        py={1}
        mb={1}
      >
        <IconBreadcrumbs breadcrumbs={breadcrumbsData} />
      </Box>
      <div className="slideUp dashboard-layout p-4">
        <div className="row">
          <div className="col-md-8">
            <ReusableTable
              title={"এটেনশন বার্তা সমুহঃ"}
              columns={columns}
              data={data}
              isLoading={isLoading}
              currentPage={currentPage}
              lastPage={lastPage}
              totalData={totalData}
              onSearchChange={handleSearchChange}
              searchText={searchText}
              onPageChange={handlePageChange}
            />
          </div>
          <div className="col-md-4">
            <EditUpdate data={editData} setData={setEditData} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AttentionMessagesAdmin;
