import * as Yup from "yup";

export const initialValues = {
  submit: 1, // Default to Draft(Not agree T&C)
  form_status: 1, // Default to Draft(Not agree T&C)
  agreement_id: "",
  nationality: "বাংলাদেশী",
  source: 1,
  onwer: "",
  address: "",
  exchangerate: "",
  documentexpire: "", // Consider using a default date if needed
  date: "", // Consider using a default date if needed
  quantity: "",
  category_id: "",
  subCategory: "",
  worktitle: "",
  geoperimeter: "",
  extensions: "",
  profiletypesa: [
    {
      name: "",
      fathername: "",
      mothername: "",
      address: "",
      nidno: "",
      mobile: "",
      email: "",
    },
  ],
  profiletypesb: [
    {
      name: "",
      fathername: "",
      mothername: "",
      address: "",
      nidno: "",
      mobile: "",
      email: "",
    },
  ],
  profiletypesc: [
    {
      name: "",
      fathername: "",
      mothername: "",
      address: "",
      nidno: "",
      mobile: "",
      email: "",
    },
  ],
  profiletypesd: [
    {
      name: "",
      fathername: "",
      mothername: "",
      address: "",
      nidno: "",
      mobile: "",
      email: "",
    },
  ],
};

const validationSchema = Yup.object().shape({
  address: Yup.string().required("ঠিকানা প্রযোজ্য।"),
  form_status: Yup.number().required("ফর্ম স্টেটাস প্রযোজ্য।"),
  agreement_id: Yup.number().required("এগ্রিমেন্ট আইডি প্রযোজ্য।"),
  nationality: Yup.string().required("জাতীয়তা প্রযোজ্য।"),
  source: Yup.number().required("সূত্র প্রযোজ্য।"),
  onwer: Yup.string().required("মালিকানা প্রযোজ্য।"),
  exchangerate: Yup.string().required("বিনিময় মূল্য প্রযোজ্য।"),
  documentexpire: Yup.string().required("দলিলের মেয়াদ প্রযোজ্য।"),
  date: Yup.date().required("চুক্তি সম্পাদনের তারিখ প্রযোজ্য।"),
  quantity: Yup.string().required("রয়্যালিটির পরিমাণ প্রযোজ্য।"),
  geoperimeter: Yup.string().required(
    "স্বত্ব নিয়োগের ভৌগোলিক পরিধি প্রযোজ্য।"
  ),
  category_id: Yup.number().required("শ্রেণী প্রযোজ্য।"),
  subCategory: Yup.number().required("উপ-শ্রেণী প্রযোজ্য।"),
  profiletypesa: Yup.array().of(
    Yup.object().shape({
      name: Yup.string().required("নাম প্রযোজ্য।"),
      fathername: Yup.string().required("নাম প্রযোজ্য।"),
      mothername: Yup.string().required("নাম প্রযোজ্য।"),
      address: Yup.string().required("ঠিকানা প্রযোজ্য।"),
      nidno: Yup.string().required("জাতীয় পরিচয়পত্র নম্বর প্রযোজ্য।"),
      mobile: Yup.string()
        .matches(
          /^01\d{9}$/,
          "মোবাইল নম্বর অবশ্যই ০১ দিয়ে শুরু হতে হবে এবং মোট ১১ সংখ্যা হতে হবে"
        )
        .required("মোবাইল প্রযোজ্য।"),
      email: Yup.string().email("ইমেল সঠিক নয়।").required("ইমেল প্রযোজ্য।"),
    })
  ),
  profiletypesb: Yup.array().of(
    Yup.object().shape({
      name: Yup.string().required("নাম প্রযোজ্য।"),
      fathername: Yup.string().required("নাম প্রযোজ্য।"),
      mothername: Yup.string().required("নাম প্রযোজ্য।"),
      address: Yup.string().required("ঠিকানা প্রযোজ্য।"),
      nidno: Yup.string().required("জাতীয় পরিচয়পত্র নম্বর প্রযোজ্য।"),
      mobile: Yup.string()
        .matches(
          /^01\d{9}$/,
          "মোবাইল নম্বর অবশ্যই ০১ দিয়ে শুরু হতে হবে এবং মোট ১১ সংখ্যা হতে হবে"
        )
        .required("মোবাইল প্রযোজ্য।"),
      email: Yup.string().email("ইমেল সঠিক নয় ইমেল।").required("ইমেল প্রযোজ্য।"),
    })
  ),
});

export default validationSchema;
