import { AddCircle, FastForward, Home, Settings } from "@mui/icons-material";
import { Box, Tooltip } from "@mui/material";
import debounce from "lodash/debounce";
import React, { useCallback, useEffect, useState } from "react";
import { Badge, Button } from "react-bootstrap";
import toast from "react-hot-toast";
import Swal from "sweetalert2";
import ReusableTable from "../../../components/custom/data_table/ReusableTable";
import IconBreadcrumbs from "../../../components/custom/IconBreadcrumbs";
import Loader from "../../../components/custom/Loader";
import {
  useDeleteFaqMutation,
  useGetFaqQuery,
} from "../../../redux/api/faqApiSlice";
import ShowFilePreviewModal from "../../frontend/userPanel/form_two/ShowPreviewFiles";
import AddData from "./AddData";
import { convertToBanglaNumerals } from "../../../utils/convertToBanglaNumerals";

const Faq = () => {
  const [previewModal, setPreviewModal] = useState(false);
  const [previewFile, setPreviewFile] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [searchText, setSearchText] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [editData, setEditData] = useState(null);

  const { data, isLoading, refetch } = useGetFaqQuery({
    currentPage,
    searchValue,
  });
  const [
    deleteData,
    {
      isLoading: isLoadingDelete,
      isError: isErrorDelete,
      error: errorDelete,
      isSuccess: isSuccessDelete,
      data: dataDelete,
    },
  ] = useDeleteFaqMutation();

  const totalData = data?.meta?.total;
  const lastPage = data?.meta?.last_page;

  const debouncedSearch = useCallback(
    debounce((value) => {
      setCurrentPage(1);
      setSearchValue(value);
      refetch();
    }, 400),
    []
  );

  useEffect(() => {
    // Cleanup function to cancel the debounce when the component unmounts or debouncedSearch changes
    return () => {
      debouncedSearch.cancel();
    };
  }, [debouncedSearch]);

  const handleSearchChange = (value) => {
    setSearchText(value);
    debouncedSearch(value);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
    refetch();
  };
  // handle delete
  const handleDeleteData = (id) => {
    Swal.fire({
      title: "আপনি কি নিশ্চিত?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        deleteData(id);
      }
    });
  };
  useEffect(() => {
    if (isSuccessDelete && dataDelete) {
      toast.success(dataDelete?.message);
    }
    if (isErrorDelete && errorDelete) {
      toast.error(errorDelete.message);
    }
  }, [
    isErrorDelete,
    isSuccessDelete,
    dataDelete,
    isLoadingDelete,
    errorDelete,
  ]);
  const columns = [
    { label: "ইনডেক্স", name: "si" },
    { label: "প্রশ্ন", name: "question" },
    {
      label: "উত্তর",
      name: "answer",
      options: {
        customBodyRender: (value) => {
          return value ? (
            <span
              className="m-1 py-1 px-3 border rounded"
              style={{ fontSize: "14px" }}
            >
              {value}
            </span>
          ) : (
            ""
          );
        },
      },
    },
    {
      label: "স্ট্যাটাস",
      name: "status",
      options: {
        customBodyRender: (value) => {
          let status, color;

          switch (value) {
            case 1:
              status = "active";
              color = "success";
              break;
            case 2:
              status = "In-Active";
              color = "danger";
              break;
            default:
              status = null;
              color = null;
          }

          return <Badge bg={color}>{status}</Badge>;
        },
      },
    },
    {
      label: "ক্রিয়াকলাপ",
      name: "action",
      options: {
        customBodyRender: (value, tableMeta) => (
          <Box display={"flex"} gap={1}>
            <Tooltip title="Edit" placement="top" arrow>
              <Badge
                bg="warning"
                className="p-2"
                onClick={() => {
                  setEditData(tableMeta.rowData);
                  setShowModal(true);
                }}
                style={{ cursor: "pointer" }}
              >
                Edit
              </Badge>
            </Tooltip>
            <Tooltip title="Delete" placement="top" arrow>
              <Badge
                bg="danger"
                className="p-2"
                onClick={() => handleDeleteData(tableMeta.rowData?.id)}
                style={{ cursor: "pointer" }}
              >
                Delete
              </Badge>
            </Tooltip>
          </Box>
        ),
      },
    },
  ];

  const dataTable = data?.data.map((item, i) => ({
    id: item.id,
    question: item.question,
    answer: item.answer,
    sorting_index: item.sorting_index,
    si: convertToBanglaNumerals(item.sorting_index),
    status: item.status,
  }));

  const breadcrumbsData = [
    { icon: Home, title: "হোম", url: "/" },
    { icon: Settings, title: "সেটিংস" },
    {
      icon: FastForward,
      title: "প্রশ্ন এবং উত্তর",
      active: true,
    },
  ];

  if (isLoading) {
    return <Loader />;
  }

  return (
    <div>
      <Box
        display={"flex"}
        justifyContent={"space-between"}
        alignItems={"center"}
        py={1}
        mb={1}
      >
        <IconBreadcrumbs breadcrumbs={breadcrumbsData} />
        <Button
          variant="outline-secondary"
          onClick={() => {
            setEditData(null);
            setShowModal(true);
          }}
        >
          <AddCircle />
        </Button>
      </Box>
      <div className="slideUp dashboard-layout p-4">
        <ReusableTable
          title={"প্রশ্ন এবং উত্তর"}
          columns={columns}
          data={dataTable}
          isLoading={isLoading}
          currentPage={currentPage}
          lastPage={lastPage}
          totalData={totalData}
          onSearchChange={handleSearchChange}
          searchText={searchText}
          onPageChange={handlePageChange}
        />
      </div>
      <AddData
        editData={editData}
        show={showModal}
        onHide={() => {
          setShowModal(false);
          setEditData(null);
        }}
      />

      <ShowFilePreviewModal
        setPreviewModal={setPreviewModal}
        previewModal={previewModal}
        previewUrl={previewFile}
        setPreviewUrl={setPreviewFile}
      />
    </div>
  );
};

export default Faq;
