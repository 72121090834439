import React, { useState, useRef } from "react";
import { ImPlus } from "react-icons/im";
import BanglaCalender from "./BanglaCalender";
import { IoCloseSharp } from "react-icons/io5";
import { ImCross } from "react-icons/im";
import { IoMdEye } from "react-icons/io";
import styles from "./Add2.module.css";
import ClientFormikFormFieldMultiple from "../../../../components/custom/form/ClientFormikFormFieldMultiple";
import { globalDateFormat } from "../../../../utils/dateFormat";
import ShowFilePreviewModal from "../form_two/ShowPreviewFiles";
import Swal from "sweetalert2";
import toast from "react-hot-toast";
import {
  useDeleteAuthorizeTypeMutation,
  useDeleteBasicPublishMutation,
  useDeleteExistsFilesMutation,
  useDeleteWorkTypeMutation,
} from "../../../../redux/api/editFormApiSlice";

const WorkMoulikBox = ({
  mainData,
  handleChange,
  handleBlur,
  values,
  handleFileChange,
  errors,
  touched,
  baseIndex,
  setValues,
  setPreviewModal,
  setPreviewFile,
}) => {
  const [previewModalOld, setPreviewModalOld] = useState(false);
  const [previewImgUrl, setPreviewImgUrl] = useState("");
  const [deleteExistsFile] = useDeleteExistsFilesMutation();

  const [deleteWorkType] = useDeleteWorkTypeMutation();
  const [deleteBasicPublish] = useDeleteBasicPublishMutation();
  const [deleteAuthorizeType] = useDeleteAuthorizeTypeMutation();

  const handleWorkOnubadok = (val) => {
    setValues((prevValues) => ({
      ...prevValues,
      basicworktypes: prevValues.basicworktypes.map((item, i) =>
        i === baseIndex
          ? {
              ...item,
              authorize: val,
              authorizetypes: [
                { name: "", address: "", nationality: "", approval_sc: "" },
              ],
            }
          : item
      ),
    }));
  };

  const handleDateChange = (index, date) => {
    setValues((prevValues) => ({
      ...prevValues,
      basicworktypes: prevValues.basicworktypes.map((item, i) =>
        i === baseIndex
          ? {
              ...item,
              worktypes: item.worktypes.map((worktype, j) =>
                j === index
                  ? {
                      ...worktype,
                      deadDate: globalDateFormat(date),
                    }
                  : worktype
              ),
            }
          : item
      ),
    }));
  };

  const handleAddFieldSetWorkOwnerLife = () => {
    const newFieldSet = {
      name: "",
      address: "",
      nationality: "",
      deadDate: null,
    };
    setValues((prevValues) => ({
      ...prevValues,
      basicworktypes: prevValues.basicworktypes.map((item, i) =>
        i === baseIndex
          ? {
              ...item,
              worktypes: [...item.worktypes, newFieldSet],
            }
          : item
      ),
    }));
  };

  const handleRemoveFieldSetWorkOwnerLife = async (index, itemId) => {
    const updateCheckboxState = () => {
      setValues((prevValues) => ({
        ...prevValues,
        basicworktypes: prevValues.basicworktypes.map((item, i) =>
          i === baseIndex
            ? {
                ...item,
                worktypes: item.worktypes.filter((_, j) => j !== index),
              }
            : item
        ),
      }));
    };

    if (itemId) {
      const result = await Swal.fire({
        text: "আপনি কি মুছে ফেলতে চান?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "না",
        confirmButtonText: "হ্যাঁ",
      });

      if (result.isConfirmed) {
        try {
          await deleteWorkType({
            id: itemId,
            fromData: {
              basicworktype_id: values?.basicworktypes[baseIndex]?.id,
            },
          }).unwrap();
          updateCheckboxState(); // Update checkbox state after successful deletion
          toast.success("মুছে ফেলা হয়েছে");
        } catch (err) {
          toast.error(err?.data?.message || "মুছে ফেলতে ব্যর্থ হয়েছে");
        }
      }
    } else {
      updateCheckboxState(); // Update checkbox state directly when itemId is not present
    }
  };

  const handleAddFieldSetWorkPublisher = () => {
    const newFieldSet = {
      name: "",
      address: "",
      nationality: "",
    };
    setValues((prevValues) => ({
      ...prevValues,
      basicworktypes: prevValues.basicworktypes.map((item, i) =>
        i === baseIndex
          ? {
              ...item,
              basicpublishes: [...item.basicpublishes, newFieldSet],
            }
          : item
      ),
    }));
  };

  const handleRemoveFieldSetWorkPublisher = async (index, itemId) => {
    const updateCheckboxState = () => {
      setValues((prevValues) => ({
        ...prevValues,
        basicworktypes: prevValues.basicworktypes.map((item, i) =>
          i === baseIndex
            ? {
                ...item,
                basicpublishes: item.basicpublishes.filter(
                  (_, j) => j !== index
                ),
              }
            : item
        ),
      }));
    };

    if (itemId) {
      const result = await Swal.fire({
        text: "আপনি কি মুছে ফেলতে চান?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "না",
        confirmButtonText: "হ্যাঁ",
      });

      if (result.isConfirmed) {
        try {
          await deleteBasicPublish({
            id: itemId,
            fromData: {
              basicworktype_id: values?.basicworktypes[baseIndex]?.id,
            },
          }).unwrap();
          updateCheckboxState(); // Update checkbox state after successful deletion
          toast.success("মুছে ফেলা হয়েছে");
        } catch (err) {
          toast.error(err?.data?.message || "মুছে ফেলতে ব্যর্থ হয়েছে");
        }
      }
    } else {
      updateCheckboxState(); // Update checkbox state directly when itemId is not present
    }
  };

  const handleAddFieldSetWorkOnubadok = () => {
    const newFieldSet = {
      name: "",
      address: "",
      nationality: "",
    };
    setValues((prevValues) => ({
      ...prevValues,
      basicworktypes: prevValues.basicworktypes.map((item, i) =>
        i === baseIndex
          ? {
              ...item,
              authorizetypes: [...item.authorizetypes, newFieldSet],
            }
          : item
      ),
    }));
  };

  const handleRemoveFieldSetWorkOnubadok = async (index, itemId) => {
    const updateCheckboxState = () => {
      setValues((prevValues) => ({
        ...prevValues,
        basicworktypes: prevValues.basicworktypes.map((item, i) =>
          i === baseIndex
            ? {
                ...item,
                authorizetypes: item.authorizetypes.filter(
                  (_, j) => j !== index
                ),
              }
            : item
        ),
      }));
    };

    if (itemId) {
      const result = await Swal.fire({
        text: "আপনি কি মুছে ফেলতে চান?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "না",
        confirmButtonText: "হ্যাঁ",
      });

      if (result.isConfirmed) {
        try {
          await deleteAuthorizeType({
            id: itemId,
            fromData: {
              basicworktype_id: values?.basicworktypes[baseIndex]?.id,
            },
          }).unwrap();
          updateCheckboxState(); // Update checkbox state after successful deletion
          toast.success("মুছে ফেলা হয়েছে");
        } catch (err) {
          toast.error(err?.data?.message || "মুছে ফেলতে ব্যর্থ হয়েছে");
        }
      }
    } else {
      updateCheckboxState(); // Update checkbox state directly when itemId is not present
    }
  };

  const fileInputRefs = useRef([]);

  const extractIdentifier = (url) => {
    const parts = url.split("/");
    const name = parts[parts.length - 1];
    const folderName = parts[parts.length - 2];
    const fileName = name.split(".")[0];
    return { folderName, fileName };
  };

  const handleDynamicFileRemove = async (
    baseIndex,
    index,
    imgUrl,
    newValues
  ) => {
    const updateState = () => {
      setValues((prev) => ({
        ...prev,
        basicworktypes: prev.basicworktypes.map((item, i) => {
          if (i === baseIndex) {
            return {
              ...item,
              authorizetypes: item.authorizetypes.map((item2, i2) => {
                if (i2 === index) {
                  const { approval_sc, ...rest } = item2;
                  return rest;
                }
                return item2;
              }),
            };
          }
          return item;
        }),
      }));
    };

    if (!newValues) {
      const result = await Swal.fire({
        text: "আপনি কি মুছে ফেলতে চান?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "না",
        confirmButtonText: "হ্যাঁ",
      });

      if (result.isConfirmed) {
        const { folderName, fileName } = extractIdentifier(imgUrl);
        try {
          await deleteExistsFile({
            folder: folderName,
            fileName: fileName,
          }).unwrap();
          toast.success("মুছে ফেলা হয়েছে");
        } catch (err) {
          toast.error(err?.data?.message || "মুছে ফেলতে ব্যর্থ হয়েছে");
        }
      }
    } else {
      updateState();
    }
  };

  return (
    <div className="work-moulik-box-content copyrightformone mt-3 mleft0 ms-3">
      <div className="form-group mb-2">
        <div className="row">
          <div className="col-md-3">
            <label className="">(ক) মৌলিক কর্মটির শিরোনাম</label>
          </div>
          <div className="col-md-9 mb-2">
            <ClientFormikFormFieldMultiple
              placeholder="এখানে লিখুন ..."
              value={values?.basicworktypes[baseIndex]?.title}
              name={`basicworktypes[${baseIndex}].title`}
              errors={
                errors?.basicworktypes &&
                errors.basicworktypes?.length > 0 &&
                errors?.basicworktypes[baseIndex]?.title
              }
              touched={
                touched?.basicworktypes &&
                touched.basicworktypes?.length > 0 &&
                touched?.basicworktypes[baseIndex]?.title
              }
              handleChange={handleChange}
              handleBlur={handleBlur}
            />
          </div>
        </div>
      </div>
      <div className="form-group mb-2">
        <div className="row">
          <div className="col-md-3">
            <label className="">(খ) মৌলিক কর্মটির ভাষা</label>
          </div>
          <div className="col-md-9 mb-2">
            <ClientFormikFormFieldMultiple
              placeholder="এখানে লিখুন ..."
              value={values?.basicworktypes[baseIndex]?.language}
              name={`basicworktypes[${baseIndex}].language`}
              errors={
                errors?.basicworktypes &&
                errors.basicworktypes?.length > 0 &&
                errors?.basicworktypes[baseIndex]?.language
              }
              touched={
                touched?.basicworktypes &&
                touched.basicworktypes?.length > 0 &&
                touched?.basicworktypes[baseIndex]?.language
              }
              handleChange={handleChange}
              handleBlur={handleBlur}
            />
          </div>
        </div>
      </div>

      <div className="form-group mb-2">
        <div className="row">
          <div className="col-md-12 me-5 mb-2">
            <label className="">
              (গ) মৌলিক কর্মটির লেখক/ রচয়িতা/ প্রণেতা জীবিত না মৃত ?
            </label>
          </div>
          <div className="col-md-12 me-5 mb-2">
            {values?.basicworktypes[baseIndex]?.worktypes?.map(
              (item, index) => (
                <div key={index} className="row">
                  <div className="col-md-10">
                    <div className="row">
                      <div className="col-md-3 mb-3">
                        <div className="form-group">
                          <label>নাম</label>
                          <ClientFormikFormFieldMultiple
                            placeholder="নাম"
                            value={item.name}
                            name={`basicworktypes[${baseIndex}].worktypes[${index}].name`}
                            errors={
                              errors?.basicworktypes?.[baseIndex]?.worktypes?.[
                                index
                              ]?.name
                            }
                            touched={
                              touched?.basicworktypes?.[baseIndex]?.worktypes?.[
                                index
                              ]?.name
                            }
                            handleChange={handleChange}
                            handleBlur={handleBlur}
                          />
                        </div>
                      </div>
                      <div className="col-md-3 mb-3">
                        <div className="form-group">
                          <label>ঠিকানা</label>
                          <ClientFormikFormFieldMultiple
                            placeholder="ঠিকানা"
                            value={item.address}
                            name={`basicworktypes[${baseIndex}].worktypes[${index}].address`}
                            errors={
                              errors?.basicworktypes?.[baseIndex]?.worktypes?.[
                                index
                              ]?.address
                            }
                            touched={
                              touched?.basicworktypes?.[baseIndex]?.worktypes?.[
                                index
                              ]?.address
                            }
                            handleChange={handleChange}
                            handleBlur={handleBlur}
                          />
                        </div>
                      </div>
                      <div className="col-md-3 mb-3">
                        <div className="form-group">
                          <label>জাতীয়তা</label>
                          <ClientFormikFormFieldMultiple
                            placeholder="জাতীয়তা"
                            value={item.nationality}
                            name={`basicworktypes[${baseIndex}].worktypes[${index}].nationality`}
                            errors={
                              errors?.basicworktypes?.[baseIndex]?.worktypes?.[
                                index
                              ]?.nationality
                            }
                            touched={
                              touched?.basicworktypes?.[baseIndex]?.worktypes?.[
                                index
                              ]?.nationality
                            }
                            handleChange={handleChange}
                            handleBlur={handleBlur}
                          />
                        </div>
                      </div>
                      <div className="col-md-3 mb-3">
                        <div className="form-group">
                          <label>মৃত্যুর তারিখ</label>
                          <BanglaCalender
                            placeholder={"তারিখ নির্বাচন করুন"}
                            value={item.deadDate}
                            onChange={(date) => handleDateChange(index, date)}
                          />
                        </div>
                        {errors?.basicworktypes?.[baseIndex]?.worktypes?.[index]
                          ?.deadDate &&
                          touched?.basicworktypes?.[baseIndex]?.worktypes?.[
                            index
                          ]?.deadDate && (
                            <small className="my-2 text-danger">
                              {
                                errors.basicworktypes[baseIndex].worktypes[
                                  index
                                ].deadDate
                              }
                            </small>
                          )}
                      </div>
                    </div>
                  </div>

                  <div className="col-md-2 centeraddremovebtn">
                    <div className="form-group">
                      <div className="mtop2 mt-4">
                        {(values.basicworktypes[baseIndex].worktypes.length ===
                          index + 1 ||
                          values.basicworktypes[baseIndex].worktypes.length ===
                            1) && (
                          <ImPlus
                            className="customAddButton rounded"
                            onClick={handleAddFieldSetWorkOwnerLife}
                          />
                        )}
                        {values.basicworktypes[baseIndex].worktypes.length >
                          1 &&
                          index !== 0 && (
                            <IoCloseSharp
                              className="customRemoveButton rounded"
                              onClick={() =>
                                handleRemoveFieldSetWorkOwnerLife(
                                  index,
                                  item?.id
                                )
                              }
                            />
                          )}
                      </div>
                    </div>
                  </div>
                </div>
              )
            )}
          </div>
        </div>
      </div>

      <div className="form-group mb-2">
        <div className="row">
          <div className="col-md-12 mb-2">
            <label>(ঘ) কর্মটির প্রকাশক(যদি থাকে)</label>
          </div>
          <div className="col-md-12 mb-2">
            {values?.basicworktypes[baseIndex]?.basicpublishes?.map(
              (publisher, index) => (
                <div key={index} className="row">
                  <div className="col-md-10">
                    <div className="row">
                      <div className="col-md-4 mb-3">
                        <div className="form-group">
                          <label>নাম</label>
                          <ClientFormikFormFieldMultiple
                            placeholder="নাম"
                            value={publisher.name}
                            name={`basicworktypes[${baseIndex}].basicpublishes[${index}].name`}
                            errors={
                              errors?.basicworktypes?.[baseIndex]
                                ?.basicpublishes?.[index]?.name
                            }
                            touched={
                              touched?.basicworktypes?.[baseIndex]
                                ?.basicpublishes?.[index]?.name
                            }
                            handleChange={handleChange}
                            handleBlur={handleBlur}
                          />
                        </div>
                      </div>
                      <div className="col-md-4 mb-3">
                        <div className="form-group">
                          <label>ঠিকানা</label>
                          <ClientFormikFormFieldMultiple
                            placeholder="ঠিকানা"
                            value={publisher.address}
                            name={`basicworktypes[${baseIndex}].basicpublishes[${index}].address`}
                            errors={
                              errors?.basicworktypes?.[baseIndex]
                                ?.basicpublishes?.[index]?.address
                            }
                            touched={
                              touched?.basicworktypes?.[baseIndex]
                                ?.basicpublishes?.[index]?.address
                            }
                            handleChange={handleChange}
                            handleBlur={handleBlur}
                          />
                        </div>
                      </div>
                      <div className="col-md-4 mb-3">
                        <div className="form-group">
                          <label>জাতীয়তা</label>
                          <ClientFormikFormFieldMultiple
                            placeholder="জাতীয়তা"
                            value={publisher.nationality}
                            name={`basicworktypes[${baseIndex}].basicpublishes[${index}].nationality`}
                            errors={
                              errors?.basicworktypes?.[baseIndex]
                                ?.basicpublishes?.[index]?.nationality
                            }
                            touched={
                              touched?.basicworktypes?.[baseIndex]
                                ?.basicpublishes?.[index]?.nationality
                            }
                            handleChange={handleChange}
                            handleBlur={handleBlur}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-2 centeraddremovebtn">
                    <div className="form-group">
                      <div className="mt-4 mtop2">
                        {(values.basicworktypes[baseIndex].basicpublishes
                          .length ===
                          index + 1 ||
                          values.basicworktypes[baseIndex].basicpublishes
                            .length === 1) && (
                          <ImPlus
                            className="customAddButton rounded"
                            onClick={handleAddFieldSetWorkPublisher}
                          />
                        )}
                        {values.basicworktypes[baseIndex].basicpublishes
                          .length > 1 &&
                          index !== 0 && (
                            <IoCloseSharp
                              className="customRemoveButton rounded"
                              onClick={() =>
                                handleRemoveFieldSetWorkPublisher(
                                  index,
                                  publisher?.id
                                )
                              }
                            />
                          )}
                      </div>
                    </div>
                  </div>
                </div>
              )
            )}
          </div>
        </div>
      </div>

      <div className="form-group mb-2">
        <div className="row">
          <div className="col-md-12 mb-2">
            <div className="row">
              <div className="col-md-5 mt-2 mtop0 ">
                <label>
                  (ঙ) কর্মটির অনুবাদ কিংবা অভিযোজন করার ক্ষমতাপ্রাপ্ত ব্যক্তি কি
                  আছেন?
                </label>
              </div>
              <div className="col-md-7 my-1 col-12">
                <span className="d-flex ptop0 pt-1">
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="radio"
                      name={`basicworktypes[${baseIndex}].authorize1`}
                      id={`basicworktypes[${baseIndex}].authorize1`}
                      checked={
                        values.basicworktypes[baseIndex]?.authorize === 1
                      }
                      onClick={() => handleWorkOnubadok(1)}
                    />
                    <label
                      className="form-check-label"
                      htmlFor={`basicworktypes[${baseIndex}].authorize1`}
                    >
                      হ্যাঁ
                    </label>
                  </div>
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="radio"
                      name={`basicworktypes[${baseIndex}].authorize2`}
                      id={`basicworktypes[${baseIndex}].authorize2`}
                      checked={
                        values.basicworktypes[baseIndex]?.authorize === 2
                      }
                      onClick={() => handleWorkOnubadok(2)}
                    />
                    <label
                      className="form-check-label"
                      htmlFor={`basicworktypes[${baseIndex}].authorize2`}
                    >
                      না
                    </label>
                  </div>
                </span>
              </div>
            </div>
          </div>

          {values.basicworktypes[baseIndex]?.authorize === 1 && (
            <div className="col-md-12 mb-2">
              {values?.basicworktypes[baseIndex].authorizetypes.map(
                (translator, index) => (
                  <div key={index} className="row">
                    <div className="col-md-10">
                      <div className="row">
                        <div className="col-md-3 mb-2">
                          <div className="form-group">
                            <label>নাম</label>
                            <ClientFormikFormFieldMultiple
                              placeholder="নাম"
                              value={translator.name}
                              name={`basicworktypes[${baseIndex}].authorizetypes[${index}].name`}
                              errors={
                                errors?.basicworktypes?.[baseIndex]
                                  ?.authorizetypes?.[index]?.name
                              }
                              touched={
                                touched?.basicworktypes?.[baseIndex]
                                  ?.authorizetypes?.[index]?.name
                              }
                              handleChange={handleChange}
                              handleBlur={handleBlur}
                            />
                          </div>
                        </div>
                        <div className="col-md-3 mb-2">
                          <div className="form-group">
                            <label>ঠিকানা</label>
                            <ClientFormikFormFieldMultiple
                              placeholder="ঠিকানা"
                              value={translator.address}
                              name={`basicworktypes[${baseIndex}].authorizetypes[${index}].address`}
                              errors={
                                errors?.basicworktypes?.[baseIndex]
                                  ?.authorizetypes?.[index]?.address
                              }
                              touched={
                                touched?.basicworktypes?.[baseIndex]
                                  ?.authorizetypes?.[index]?.address
                              }
                              handleChange={handleChange}
                              handleBlur={handleBlur}
                            />
                          </div>
                        </div>
                        <div className="col-md-3 mb-2">
                          <div className="form-group">
                            <label>জাতীয়তা</label>
                            <ClientFormikFormFieldMultiple
                              placeholder="জাতীয়তা"
                              value={translator.nationality}
                              name={`basicworktypes[${baseIndex}].authorizetypes[${index}].nationality`}
                              errors={
                                errors?.basicworktypes?.[baseIndex]
                                  ?.authorizetypes?.[index]?.nationality
                              }
                              touched={
                                touched?.basicworktypes?.[baseIndex]
                                  ?.authorizetypes?.[index]?.nationality
                              }
                              handleChange={handleChange}
                              handleBlur={handleBlur}
                            />
                          </div>
                        </div>

                        <div className="col-md-3">
                          <div className="border marginBottomm p-1 bordercolorr margin-10px rounded paddingg0 mb-3 ptop0">
                            <div className="form-group text-center imgmffargin">
                              <label className="text-center" style={{}}>
                                অনুমোদনের স্ক্যান কপি
                              </label>
                              <br />
                              <div className={styles.imagePreviewAdd2}>
                                {translator?.approval_sc ||
                                mainData?.basicWorkTypes?.[baseIndex]
                                  ?.authorizeTypes?.[index]?.approvalSc ? (
                                  <span>
                                    <div className="d-flex justify-content-center">
                                      <div
                                        className="showiconss cursor-pointer"
                                        onClick={() => {
                                          if (translator?.approval_sc) {
                                            setPreviewModal(true);
                                            setPreviewFile(
                                              translator?.approval_sc
                                            );
                                          } else {
                                            setPreviewModalOld(true);
                                            setPreviewImgUrl(
                                              mainData?.basicWorkTypes?.[
                                                baseIndex
                                              ]?.authorizeTypes?.[index]
                                                ?.approvalSc
                                            );
                                          }
                                        }}
                                      >
                                        <IoMdEye
                                          className={styles.showiconss}
                                        />
                                      </div>
                                      <div
                                        className="removesssIcon"
                                        onClick={() =>
                                          handleDynamicFileRemove(
                                            baseIndex,
                                            index,
                                            mainData?.basicWorkTypes?.[
                                              baseIndex
                                            ]?.authorizeTypes?.[index]
                                              ?.approvalSc,
                                            translator?.approval_sc
                                          )
                                        }
                                      >
                                        <ImCross
                                          className={styles.removesssIcon}
                                        />
                                      </div>
                                    </div>
                                  </span>
                                ) : (
                                  <span className={styles.placeholder}>
                                    - - - - -
                                  </span>
                                )}
                              </div>

                              <div>
                                {errors?.basicworktypes?.[baseIndex]
                                  ?.authorizetypes?.[index]?.approval_sc && (
                                  <small className="my-2 text-danger">
                                    {
                                      errors.basicworktypes[baseIndex]
                                        .authorizetypes[index].approval_sc
                                    }
                                  </small>
                                )}
                              </div>

                              <label className="custombtnn5 mb-3">
                                <input
                                  type="file"
                                  accept=".pdf"
                                  className="d-none"
                                  onChange={(e) => {
                                    handleFileChange(
                                      `basicworktypes[${baseIndex}].authorizetypes[${index}].approval_sc`,
                                      e
                                    );
                                    e.currentTarget.value = null;
                                  }}
                                />
                                {translator?.approval_sc ||
                                mainData?.basicWorkTypes?.[baseIndex]
                                  ?.authorizeTypes?.[index]?.approvalSc
                                  ? "পরিবর্তন করুন"
                                  : "নির্বাচন করুন"}
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-2 centeraddremovebtn">
                      <div className="form-group">
                        <div className="mt-4 mtop0">
                          {(values.basicworktypes[baseIndex].authorizetypes
                            .length ===
                            index + 1 ||
                            values.basicworktypes[baseIndex].authorizetypes
                              .length === 1) && (
                            <ImPlus
                              className="customAddButton rounded"
                              onClick={handleAddFieldSetWorkOnubadok}
                            />
                          )}
                          {values.basicworktypes[baseIndex].authorizetypes
                            .length > 1 &&
                            index !== 0 && (
                              <IoCloseSharp
                                className="customRemoveButton rounded"
                                onClick={() =>
                                  handleRemoveFieldSetWorkOnubadok(
                                    index,
                                    translator?.id
                                  )
                                }
                              />
                            )}
                        </div>
                      </div>
                    </div>
                  </div>
                )
              )}
            </div>
          )}
        </div>
      </div>
      <ShowFilePreviewModal
        setPreviewModal={setPreviewModalOld}
        previewModal={previewModalOld}
        previewUrl={previewImgUrl}
        setPreviewUrl={setPreviewImgUrl}
      />
    </div>
  );
};

export default WorkMoulikBox;
