import * as yup from "yup";

export const createInitializeValues = {
  name: "",
  parent_id: "",
  status: "",
  code: "",
  image: null,
};

export const createValidationSchema = yup.object().shape({
  name: yup
    .string()
    .required("নাম দেওয়া প্রযোজ্য")
    .max(255, "নাম সর্বাধিক ২৫৫ অক্ষরের হতে হবে"),
  status: yup
    .string()
    .required("স্ট্যাটাস দেওয়া প্রযোজ্য")
    .max(255, "স্ট্যাটাস সর্বাধিক ২৫৫ অক্ষরের হতে হবে"),
  code: yup
    .string()
    .required("কোড দেওয়া প্রযোজ্য")
    .max(255, "কোড সর্বাধিক ২৫৫ অক্ষরের হতে হবে"),
  image: yup
    .mixed()
    .nullable()
    .test("fileType", "অবাঞ্ছিত ফাইল ফরম্যাট", (value) => {
      // Return true if the value is null or undefined, allowing nullable values
      if (value === null || value === undefined) return true;
      // If there is a value, check if it is a File and if it has an acceptable file type
      if (value && value instanceof File) {
        return ["image/jpeg", "image/png", "image/gif"].includes(value.type);
      }
      // If value is neither null nor a valid File, return false
      return true;
    }),
});

