import { useFormik } from "formik";
import React, { useEffect } from "react";
import Swal from "sweetalert2";
import * as Yup from "yup";
import { useCertificateTypeUpdateMutation } from "../../redux/api/submittedCopyrightAgreementApiSlice";
import toast from "react-hot-toast";
import { transformErrorsToObjectStructure } from "../../utils/transformErrorsToObjectStructure";
import Form from "react-bootstrap/Form"; // Correctly import Form from react-bootstrap
import { globalDateFormat } from "../../utils/dateFormat";
import BanglaCalender from "../../pages/frontend/userPanel/form_two/BanglaCalender";
import { Button } from "react-bootstrap";
import { BeatLoader } from "react-spinners";

const CertificateType = ({ data, refetch, onHide }) => {
  const [
    submitData,
    {
      isLoading: isLoadingSubmit,
      isSuccess: isSuccessSubmit,
      error: errorSubmit,
      isError: isErrorSubmit,
      data: dataSubmit,
    },
  ] = useCertificateTypeUpdateMutation();

  const id = data?.id;
  const ac = data?.archive;

  const initialValues = {
    archive_id: ac?.id || "",
    certificate_type: Number(ac?.certificate_type) || "",
    registration_no: ac?.registration_no || "",
    life_plus: ac?.life_plus || "",
    expiry_date: ac?.expiry_date || "",
    expiry_year: ac?.expiry_year || "",
    ownergain: ac?.ownergain || "",
    tc_licence: ac?.tc_licence || "",
  };

  const validationSchema = Yup.object().shape({
    certificate_type: Yup.number()
      .required("সার্টিফিকেটের ধরণ নির্বাচন করুন")
      .oneOf([1, 2, 3, 4]),

    registration_no: Yup.string().required("রেজিস্ট্রেশন নম্বর প্রযোজ্য"),

    expiry_date: Yup.string()
      .matches(/^\d{4}-\d{2}-\d{2}$/, "সঠিক তারিখ প্রযোজ্য")
      .test(
        "expiry-date-or-year",
        "মেয়াদ শেষের তারিখ প্রযোজ্য",
        function (value) {
          const { expiry_year } = this.parent;
          return value || expiry_year;
        }
      ),
    expiry_year: Yup.string().test(
      "expiry-year-or-date",
      "বছর প্রযোজ্য",
      function (value) {
        const { expiry_date } = this.parent;
        return value || expiry_date;
      }
    ),

    ownergain: Yup.string().required("ওনার গেইন প্রযোজ্য"),
    tc_licence: Yup.string().when("certificate_type", {
      is: 2, // লাইসেন্স
      then: () =>
        Yup.string()
          .required("টিসি লাইসেন্স প্রযোজ্য")
          .min(2, "অন্তত ২ অক্ষর হতে হবে"),
    }),
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      const formData = {
        ...values,
        registration_no: values.registration_no.replace(/\s+/g, ""),
      };
      Swal.fire({
        text: "আপনি কি নিশ্চিত?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "না",
        confirmButtonText: "হ্যাঁ",
      }).then((result) => {
        if (result.isConfirmed) {
          submitData({ id, formData });
        }
      });
    },
    enableReinitialize: true,
  });

  useEffect(() => {
    if (isSuccessSubmit) {
      toast.success(dataSubmit?.message);
      refetch();
      onHide();
    }
    if (isErrorSubmit) {
      toast.error(errorSubmit?.data?.message);
      formik.setErrors(
        transformErrorsToObjectStructure(errorSubmit.data?.data || {})
      );
    }
  }, [isErrorSubmit, isSuccessSubmit]);

  const certificateType = [
    { label: "পার্মানেন্ট", value: 1 },
    { label: "লাইসেন্স", value: 2 },
    { label: "রিলেটেড রাইট", value: 3 },
    { label: "ব্রডকাস্টিং", value: 4 },
  ];

  const handleCheckChange = (e) => {
    const val = e.target.checked ? 1 : 0;
    formik.setValues((prev) => ({ ...prev, life_plus: val }));
  };

  return (
    <div className="row py-3">
      <div className="col-md-12 mb-3">
        <div className="d-flex align-items-center flex-wrap gap-3 mb-2">
          <span className="fw-semibold">সার্টিফিকেটের ধরণঃ</span>
          {certificateType.map((type, i) => (
            <Form.Check
              className="mr-3"
              key={i}
              type="radio"
              label={type.label}
              value={type.value}
              id={`default-${type.label}`}
              onChange={() =>
                formik.setValues((prev) => ({
                  ...prev,
                  certificate_type: type.value,
                }))
              }
              checked={formik.values.certificate_type === type.value}
            />
          ))}
        </div>
      </div>
      <div className="col-md-6 mb-2">
        <Form.Group controlId="registrationNo">
          <Form.Label>রেজিস্ট্রেশন নম্বর</Form.Label>
          <Form.Control
            type="text"
            value={formik.values.registration_no}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            isInvalid={
              formik.touched.registration_no && !!formik.errors.registration_no
            }
            name="registration_no"
          />
          <Form.Control.Feedback type="invalid">
            {formik.errors.registration_no}
          </Form.Control.Feedback>
        </Form.Group>
      </div>
      <div className="col-md-6 mb-2">
        <Form.Group controlId="expiryDate">
          <Form.Label>মেয়াদ উত্তীর্ণের তারিখ</Form.Label>
          <BanglaCalender
            value={formik.values?.expiry_date}
            onChange={(date) => {
              formik.setValues((prevValues) => ({
                ...prevValues,
                expiry_date: globalDateFormat(date),
              }));
            }}
          />
          {formik.errors.expiry_date && (
            <span className="text-danger">{formik.errors.expiry_date}</span>
          )}
        </Form.Group>
      </div>
      <div className="col-md-12 mb-4">
        <Form.Label>মেয়াদ উত্তীর্ণের বছর</Form.Label>
        <div className="d-flex align-items-center gap-3">
          <div className="">
            <Form.Check
              type="switch"
              id="custom-switch"
              label={"Life +"}
              value={formik.values.life_plus}
              checked={formik.values.life_plus === 1}
              onChange={handleCheckChange}
            />
          </div>
          <div className="">
            <Form.Select
              value={formik.values?.expiry_year}
              name="expiry_year"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              isInvalid={
                formik.touched.expiry_year && !!formik.errors.expiry_year
              }
              placeholder="YY"
            >
              <option value="">বছর</option>
              {Array.from({ length: 60 }).map((_, index) => (
                <option key={index + 1} value={index + 1}>
                  {index + 1}
                </option>
              ))}
            </Form.Select>
            <Form.Control.Feedback type="invalid">
              {formik.errors.expiry_year}
            </Form.Control.Feedback>
          </div>
        </div>
      </div>
      <div className="col-md-12 mb-2">
        <Form.Group controlId="ownergain">
          <Form.Label>
            স্বত্ব প্রাপ্তির মাধ্যম / Name of Licensor & Authority
          </Form.Label>
          <Form.Control
            type="text"
            value={formik.values.ownergain}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            isInvalid={formik.touched.ownergain && !!formik.errors.ownergain}
            name="ownergain"
          />
          <Form.Control.Feedback type="invalid">
            {formik.errors.ownergain}
          </Form.Control.Feedback>
        </Form.Group>
      </div>
      {formik.values.certificate_type === 2 && (
        <div className="col-md-12 mb-2">
          <Form.Group controlId="tcLicence">
            <Form.Label>Type & Conditions of Licence</Form.Label>
            <Form.Control
              type="text"
              value={formik.values.tc_licence}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              isInvalid={
                formik.touched.tc_licence && !!formik.errors.tc_licence
              }
              name="tc_licence"
            />
            <Form.Control.Feedback type="invalid">
              {formik.errors.tc_licence}
            </Form.Control.Feedback>
          </Form.Group>
        </div>
      )}
      <div className="col-md-12 mt-4 text-center">
        <Button
          type="submit"
          className="btn btn-primary"
          onClick={formik.handleSubmit}
          disabled={isLoadingSubmit}
        >
          {isLoadingSubmit ? <BeatLoader /> : "সম্পাদন করুন"}
        </Button>
      </div>
    </div>
  );
};

export default CertificateType;
