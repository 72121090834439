import { ContactMail, Home, Settings } from "@mui/icons-material";
import { Box, Tooltip } from "@mui/material";
import debounce from "lodash/debounce";
import React, { useCallback, useEffect, useState } from "react";
import { Badge } from "react-bootstrap";
import toast from "react-hot-toast";
import Swal from "sweetalert2";
import ReusableTable from "../../../components/custom/data_table/ReusableTable";
import IconBreadcrumbs from "../../../components/custom/IconBreadcrumbs";
import Loader from "../../../components/custom/Loader";
import {
  useDeleteContactsMutation,
  useGetContactsQuery,
} from "../../../redux/api/contactsApiSlice";
import { convertToBanglaNumerals } from "../../../utils/convertToBanglaNumerals";
import { localDateFormat } from "../../../utils/dateFormat";
import ReplyContact from "./ReplyContact";
import ViewData from "./ViewData";

const Contacts = () => {
  const [showReplyModal, setShowReplyModal] = useState(false);
  const [replyData, setReplyData] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [searchText, setSearchText] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [showData, setShowData] = useState(null);

  const { data, isLoading, refetch } = useGetContactsQuery({
    currentPage,
    searchValue,
  });
  const [
    deleteData,
    {
      isLoading: isLoadingDelete,
      isError: isErrorDelete,
      error: errorDelete,
      isSuccess: isSuccessDelete,
      data: dataDelete,
    },
  ] = useDeleteContactsMutation();

  const totalData = data?.meta?.total;
  const lastPage = data?.meta?.last_page;

  const debouncedSearch = useCallback(
    debounce((value) => {
      setCurrentPage(1);
      setSearchValue(value);
      refetch();
    }, 400),
    []
  );

  useEffect(() => {
    // Cleanup function to cancel the debounce when the component unmounts or debouncedSearch changes
    return () => {
      debouncedSearch.cancel();
    };
  }, [debouncedSearch]);

  const handleSearchChange = (value) => {
    setSearchText(value);
    debouncedSearch(value);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
    refetch();
  };
  // handle delete
  const handleDeleteData = (id) => {
    Swal.fire({
      title: "আপনি কি নিশ্চিত?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        deleteData(id);
      }
    });
  };
  useEffect(() => {
    if (isSuccessDelete && dataDelete) {
      toast.success(dataDelete?.message);
    }
    if (isErrorDelete && errorDelete) {
      toast.error(errorDelete.message);
    }
  }, [
    isErrorDelete,
    isSuccessDelete,
    dataDelete,
    isLoadingDelete,
    errorDelete,
  ]);
  const columns = [
    { label: "ইনডেক্স", name: "si" },
    { label: "বিষয়", name: "subject" },
    { label: "নাম", name: "name" },
    {
      label: "ইমেইল",
      name: "email",
      options: {
        customBodyRender: (value) => {
          return value ? (
            <span
              className="m-1 py-1 px-3 border rounded"
              style={{ fontSize: "14px" }}
            >
              {value}
            </span>
          ) : (
            ""
          );
        },
      },
    },
    { label: "তারিখ", name: "date" },
    {
      label: "স্ট্যাটাস",
      name: "read_unread",
      options: {
        customBodyRender: (value) => {
          let status, color;

          switch (value) {
            case 1:
              status = "Read";
              color = "success";
              break;
            default:
              status = "Unread";
              color = "danger";
          }

          return <Badge bg={color}>{status}</Badge>;
        },
      },
    },
    {
      label: "ক্রিয়াকলাপ",
      name: "action",
      options: {
        customBodyRender: (value, tableMeta) => (
          <Box display={"flex"} gap={1}>
            <Tooltip title="View" placement="top" arrow>
              <Badge
                bg="warning"
                className="p-2"
                onClick={() => {
                  setShowData(tableMeta.rowData);
                  setShowModal(true);
                }}
                style={{ cursor: "pointer" }}
              >
                View
              </Badge>
            </Tooltip>
            <Tooltip title="Reply" placement="top" arrow>
              <Badge
                bg="primary"
                className="p-2"
                onClick={() => {
                  setReplyData(tableMeta.rowData);
                  setShowReplyModal(true);
                }}
                style={{ cursor: "pointer" }}
              >
                Reply
              </Badge>
            </Tooltip>
            <Tooltip title="Delete" placement="top" arrow>
              <Badge
                bg="danger"
                className="p-2"
                onClick={() => handleDeleteData(tableMeta.rowData?.id)}
                style={{ cursor: "pointer" }}
              >
                Delete
              </Badge>
            </Tooltip>
          </Box>
        ),
      },
    },
  ];

  const dataTable = data?.data.map((item, i) => ({
    id: item.id,
    name: item.name,
    number: item.number,
    email: item.email,
    subject: item.subject,
    message: item.message,
    read_unread: item.read_unread,
    date: localDateFormat(item.date),
    si: convertToBanglaNumerals((currentPage - 1) * 10 + i + 1),
  }));

  const breadcrumbsData = [
    { icon: Home, title: "হোম", url: "/" },
    { icon: Settings, title: "সেটিংস" },
    {
      icon: ContactMail,
      title: "যোগাযোগ",
      active: true,
    },
  ];

  if (isLoading) {
    return <Loader />;
  }

  return (
    <div>
      <Box
        display={"flex"}
        justifyContent={"space-between"}
        alignItems={"center"}
        py={1}
        mb={1}
      >
        <IconBreadcrumbs breadcrumbs={breadcrumbsData} />
      </Box>
      <div className="slideUp dashboard-layout p-4">
        <ReusableTable
          title={"যোগাযোগ"}
          columns={columns}
          data={dataTable}
          isLoading={isLoading}
          currentPage={currentPage}
          lastPage={lastPage}
          totalData={totalData}
          onSearchChange={handleSearchChange}
          searchText={searchText}
          onPageChange={handlePageChange}
        />
      </div>
      <ViewData
        showData={showData}
        show={showModal}
        onHide={() => {
          setShowModal(false);
          setShowData(null);
        }}
      />
      <ReplyContact
        data={replyData}
        show={showReplyModal}
        onHide={() => {
          setShowReplyModal(false);
          setReplyData(null);
        }}
      />
    </div>
  );
};

export default Contacts;
