import React from "react";
import { Button, Modal } from "react-bootstrap";

const ShowApplication = ({ infoModal, onHide, ...props }) => {
  const renderContent = (data) => {
    if (Array.isArray(data) && data.length > 0) {
      return data.map((url, index) => {
        if (!url) {
          return <div>No data available</div>;
        }
  
        const isImage = url.match(/\.(jpeg|jpg|gif|png)$/) != null;
        const isPDF = url.match(/\.(pdf)$/) != null;
        const isFile = isImage || isPDF;

        if (isFile) {
          if (isImage) {
            return (
              <img
                key={index}
                src={url}
                alt={`attachment-${index}`}
                className="img-fluid"
              />
            );
          }
          if (isPDF) {
            return (
              <iframe
                key={index}
                src={url}
                title={`attachment-${index}`}
                width="100%"
                height="500px"
              ></iframe>
            );
          }
        }
        return (
          <div key={index}>
            <a href={url} target="_blank" rel="noopener noreferrer">
              {url}
            </a>
          </div>
        );
      });
    } else if (typeof data === "string") {

      if (!data) {
        return <div>No data available</div>;
      }

      const isImage = data.match(/\.(jpeg|jpg|gif|png)$/) != null;
      const isPDF = data.match(/\.(pdf)$/) != null;
      const isFile = isImage || isPDF;

      if (isFile) {
        if (isImage) {
          return <img src={data} alt={`attachment-}`} className="img-fluid" />;
        }
        if (isPDF) {
          return (
            <iframe
              src={data}
              title={`attachment-}`}
              width="100%"
              height="500px"
            ></iframe>
          );
        }
      } else {
        return <div className="card py-5">{data}</div>;
      }
    }
    return <div>No data available</div>;
  };

  return (
    <Modal
      {...props}
      onHide={onHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body>
        <div className="text-center py-4">{renderContent(infoModal)}</div>

        <div className="d-flex justify-content-center pt-3">
          <Button
            variant="secondary"
            onClick={onHide}
            style={{ width: "100px" }}
          >
            বন্ধ
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ShowApplication;
