import React from "react";
import { Card, Modal, Tab, Tabs } from "react-bootstrap";
import CertificateType from "./CertificateType";
import AttentionMessage from "./AttentionMessage";
import CertificateLang from "./CertificateLang";
import ArtisticWork from "./ArtisticWork";

const UpdateCertificate = ({ open, onHide, data, refetch }) => {
  const pd = data?.profileDetail;
  return (
    <Modal
      show={open}
      onHide={onHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      //   centered
    >
      <Modal.Header closeButton>
        <h5>সার্টিফিকেট সম্পাদন</h5>
      </Modal.Header>
      <div className="m-3">
        <Tabs
          defaultActiveKey="certificate_type"
          id="justify-tab-example"
          justify
        >
          <Tab
            eventKey="certificate_type"
            title="সার্টিফিকেটের ধরন"
            className="custom-tabs"
          >
            <div className="border border-top-0 p-3">
              <CertificateType data={data} refetch={refetch} onHide={onHide} />
            </div>
          </Tab>
          <Tab
            eventKey="certificate_lang"
            title="সার্টিফিকেটের ভাষা"
            className="custom-tabs"
          >
            <div className="border border-top-0 p-3">
              <CertificateLang data={data} refetch={refetch} onHide={onHide} />
            </div>
          </Tab>
          <Tab
            eventKey="attention_message"
            title="এটেনশন বার্তা"
            className="custom-tabs"
          >
            <div className="border border-top-0 p-3">
              <AttentionMessage data={data} refetch={refetch} onHide={onHide} />
            </div>
          </Tab>
          {pd?.category?.id === 3 && pd?.workSoftCopies.length > 0 && (
            <Tab
              eventKey="image_of_the_artistic_work"
              title="Artistic Work"
              className="custom-tabs"
            >
              <div className="border border-top-0 p-3">
                <ArtisticWork data={data} refetch={refetch} onHide={onHide} />
              </div>
            </Tab>
          )}
        </Tabs>
      </div>
    </Modal>
  );
};

export default UpdateCertificate;
