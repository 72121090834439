import React from "react";
import SubmittedAgreement from "../sumitted_agreement/SubmittedAgreement";
import { useAuth } from "../../../../utils/authorization";

const RecentAgreementApplication = () => {
  const { authUser } = useAuth();
  const fileStatus = authUser.role_id === 8 ? 1 : 3;
  const applicationPath = {
    copyright: "/admin/submitted-copyright",
    agreement: "/admin/submitted-agreement",
  };
  return (
    <SubmittedAgreement
      fileStatus={fileStatus}
      applicationPath={applicationPath}
    />
  );
};

export default RecentAgreementApplication;
