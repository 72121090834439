import React from "react";
import { useLocation, useParams } from "react-router-dom";
import {
  useShowCorrectionPaymentQuery,
  useUpdateCorrectionPaymentMutation,
} from "../../../../redux/api/correctionFormApiSlice";
import TreasuryPaymentUi from "../../../../components/TreasuryPaymentUi";

const CorrectionPayment = () => {
  const { id } = useParams();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const trackReg = query.get("trackReg");
  const { data, isLoading } = useShowCorrectionPaymentQuery({ id, trackReg });
  const mainData = data?.data;

  const [
    updateApplication,
    {
      isLoading: isLoadingUpdate,
      error: errorUpdate,
      isError: isErrorUpdate,
      data: dataUpdate,
      isSuccess: isSuccessUpdate,
    },
  ] = useUpdateCorrectionPaymentMutation();

  const redirectUrl = `/success-correction/${mainData?.apptype}`;

  return (
    <TreasuryPaymentUi
      redirectUrl={redirectUrl}
      mainData={mainData}
      isLoading={isLoading}
      isLoadingUpdate={isLoadingUpdate}
      errorUpdate={errorUpdate}
      isErrorUpdate={isErrorUpdate}
      dataUpdate={dataUpdate}
      isSuccessUpdate={isSuccessUpdate}
      updateApplication={updateApplication}
    />
  );
};
export default CorrectionPayment;
