import {
  COMMENT_AND_ORDERED_SEARCH,
  EMAIL_FOR_CORRECTION,
  FILE_LOCATION_SEARCH,
  RESTORE_SENT_APPLICATION,
} from "../constants";
import { apiSlice } from "./apiSlice";

const backendUtilsApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    // FILE_LOCATION_SEARCH
    getFileLocationSearch: builder.query({
      query: (trackReg) => `${FILE_LOCATION_SEARCH}?trackReg=${trackReg}`,
      providesTags: ["file_location_search"],
    }),
    // post COMMENT_AND_ORDERED_SEARCH
    postCommentAndOrderedSearch: builder.mutation({
      query: (formData) => ({
        url: COMMENT_AND_ORDERED_SEARCH,
        method: "POST",
        body: formData,
      }),
      invalidatesTags: ["file_location_search"],
    }),
    // email for correction
    sendEmailForCorrection: builder.mutation({
      query: ({ formData, id }) => ({
        url: EMAIL_FOR_CORRECTION + "/" + id,
        method: "POST",
        body: formData,
      }),
      invalidatesTags: ["file_location_search"],
    }),
    // /admin/restore-sent-application
    restoreSentApplication: builder.mutation({
      query: ({ formData, id }) => ({
        url: RESTORE_SENT_APPLICATION + "/" + id,
        method: "POST",
        body: formData,
      }),
      invalidatesTags: ["submittedCopyright", "submittedAgreement"],
    }),
  }),
});

export const {
  useLazyGetFileLocationSearchQuery,
  usePostCommentAndOrderedSearchMutation,
  useSendEmailForCorrectionMutation,
  useRestoreSentApplicationMutation,
} = backendUtilsApiSlice;
