import { useFormik } from "formik";
import { useEffect, useRef, useState } from "react";
import toast from "react-hot-toast";
import { ImCross, ImPlus } from "react-icons/im";
import { IoMdEye } from "react-icons/io";
import { IoCloseSharp } from "react-icons/io5";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Select from "react-select";
import { BeatLoader } from "react-spinners";
import Swal from "sweetalert2";
import FilePreviewModal from "../../../../../components/ApllicantionFilePreview";
import ClientFormikFormFieldMultiple from "../../../../../components/custom/form/ClientFormikFormFieldMultiple";
import ClientFormikYearSelector from "../../../../../components/custom/form/year/ClientFormikYearSelector";
import Loader from "../../../../../components/custom/Loader";
import { useGetCategoriesQuery } from "../../../../../redux/api/categoryApiSlice";
import {
  useGetCopyrightQuery,
  useGetOtherSubCategoryQuery,
  useGetRelatedRightQuery,
  useLazyGetSubCategoryCatalogQuery,
} from "../../../../../redux/api/categoryCatalogApiSlice";
import {
  useShowCorrectionCopyrightSecondPartQuery,
  useUpdateCorrectionCopyrightSecondPartMutation,
} from "../../../../../redux/api/correctionFormApiSlice";
import {
  useDeleteBasicWorkTypeMutation,
  useDeleteExistsFilesMutation,
  useDeleteOwnerTypeMutation,
  useDeletePowerTypeMutation,
  useDeleteProfileDetailsSelfTypeMutation,
  useDeleteProfileDetailsTypeMutation,
  useDeletePublishDetailsMutation,
  useDeleteWorkSoftCopyTypeMutation,
  useDeleteWriterDetailsMutation,
} from "../../../../../redux/api/editFormApiSlice";
import { useDeleteCertificateLanguageMutation } from "../../../../../redux/api/submittedCopyrightAgreementApiSlice";
import { applicationFormTitle } from "../../../../../utils/applicationFormTitle";
import ClientFormikDateSelector from "../../../../../utils/dataSelector";
import {
  globalDateFormat,
  mergeDateValues,
} from "../../../../../utils/dateFormat";
import { extractPublicationDate } from "../../../../../utils/extractPublicationDate";
import { transformErrorsToObjectStructure } from "../../../../../utils/transformErrorsToObjectStructure";
import styles from "../../form_two/Add2.module.css";
import BanglaCalender from "../../form_two/BanglaCalender";
import ShowFilePreviewModal from "../../form_two/ShowPreviewFiles";
import WorkMoulikBox from "../../form_two/WorkMoulikBox";
import { createFormDataAdd2 } from "./validation_second_part/makeFormData";
import {
  initialValues,
  validationSchema,
} from "./validation_second_part/validation";

const CorrectionCopyrightSecondPart = () => {
  const { id } = useParams();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const trackReg = query.get("trackReg");
  const navigate = useNavigate();

  const [previewModal, setPreviewModal] = useState(false);
  const [previewFile, setPreviewFile] = useState("");
  const [checkBoxKeyType, setCheckBoxKeyType] = useState([]);

  const [previewModalOld, setPreviewModalOld] = useState(false);
  const [previewImgUrl, setPreviewImgUrl] = useState("");

  const { data: editData, isError } = useShowCorrectionCopyrightSecondPartQuery(
    {
      id,
      trackReg,
    }
  );

  const mainData = editData?.data;

  const [
    updateFormSecond,
    {
      isLoading: isLoadingUpdate,
      isError: isErrorUpdate,
      error: errorUpdate,
      isSuccess: isSuccessUpdate,
      data: dataUpdate,
    },
  ] = useUpdateCorrectionCopyrightSecondPartMutation();

  const { data: categoryData, isSuccess: isSuccessCategory } =
    useGetCategoriesQuery();
  const { data: otherSubCategory, isLoading } = useGetOtherSubCategoryQuery();
  const [
    fetchSubCategory,
    { data: subCategoryData, isLoading: isLoadingSubCat, isFetching },
  ] = useLazyGetSubCategoryCatalogQuery();

  // copyright and related right
  const { data: copyrightData, isLoading: isLoadingCopyright } =
    useGetCopyrightQuery();
  const { data: relatedRightData, isLoading: isLoadingRelatedRight } =
    useGetRelatedRightQuery();

  // handle deleting files
  const [deleteProfileType] = useDeleteProfileDetailsTypeMutation();

  // handle deleting self type
  const [deleteSelfType] = useDeleteProfileDetailsSelfTypeMutation();

  // basic work type
  const [deleteBasicWorkType] = useDeleteBasicWorkTypeMutation();
  const [deleteWriterDetails] = useDeleteWriterDetailsMutation();
  const [deletePublishDetails] = useDeletePublishDetailsMutation();
  const [deleteOwnerType] = useDeleteOwnerTypeMutation();
  const [deletePowerType] = useDeletePowerTypeMutation();
  const [deleteExistsFile] = useDeleteExistsFilesMutation();
  const [deleteWorkSoftCopyType] = useDeleteWorkSoftCopyTypeMutation();
  const [deleteCertificate] = useDeleteCertificateLanguageMutation();

  // Copyright

  const publicUrl = process.env.PUBLIC_URL;

  /// formik start
  const {
    values,
    touched,
    errors,
    handleBlur,
    handleChange,
    handleSubmit,
    setValues,
    setErrors,
    setFieldValue,
  } = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema({
      otherSubCategoryData: otherSubCategory?.data || [],
      checkBoxKeyType,
    }),
    context: { keytype: initialValues.basicworktypes[0].keytype },
    onSubmit: async (values) => {
      if (
        checkBoxKeyType.length <= 0 &&
        [7, 17, 18].includes(values.rightTypes)
      ) {
        toast.error("অন্তত একটি চেকবক্স নির্বাচন করুন");
        return;
      }

      const formData = createFormDataAdd2(values);

      if (!isCorrection.includes("3") && !isCorrection.includes("4")) {
        formData.append("submit", 2);
        const result = await Swal.fire({
          text: "আপনি কি দাখিল দিতে চান ?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          cancelButtonText: "না",
          confirmButtonText: "হ্যাঁ",
        });

        if (!result.isConfirmed) {
          return; // Exit if the user cancels
        }
      }

      const publishyear = mergeDateValues({
        year: values.year,
        month: values.month,
        day: values.day,
      });
      const pub_unpub_year = mergeDateValues({
        year: values.pub_year,
        month: values.pub_month,
        day: values.pub_day,
      });
      if (publishyear) {
        formData.append("publishyear", publishyear);
        formData.append("pub_unpub_year", publishyear);
      } else {
        formData.append("pub_unpub_year", pub_unpub_year);
      }
      await updateFormSecond({ id: values.id, formData });
    },
  });

  const isCorrection = mainData?.profile?.isCorrection?.split(",") || [];

  let button_title;
  if (isCorrection.includes("3") || isCorrection.includes("4")) {
    button_title = "সংরক্ষণ করে অগ্রসর হউন";
  } else {
    button_title = "দাখিল";
  }

  useEffect(() => {
    if (isErrorUpdate) {
      setErrors(
        transformErrorsToObjectStructure(errorUpdate?.data?.data || {})
      );
      toast.error(errorUpdate?.data?.message);
    }
    if (isSuccessUpdate) {
      if (isCorrection.includes("4") || isCorrection.includes("3")) {
        navigate(`/correction-payment/${id}?trackReg=${trackReg}`);
      } else {
        navigate(`/success-correction/1`, {
          replace: true,
        });
      }
      toast.success(dataUpdate?.message);
    }

    // eslint-disable-next-line
  }, [isErrorUpdate, isSuccessUpdate]);
  // Multiple item create and remove functions

  // step 2
  const handleAddFieldSet = () => {
    const newFieldSet = {
      name: "",
      email: "",
      phone: "",
      address: "",
      nid: "",
      image: "",
      photo: "",
    };
    setValues((prevValues) => ({
      ...prevValues,
      profiledetailtypes: [
        ...(prevValues.profiledetailtypes || []),
        newFieldSet,
      ],
    }));
  };

  const removeJoutho = (idToRemove) => {
    const updatedFieldSets = values.profiledetailtypes.filter(
      (_, i) => i !== idToRemove
    );
    setValues((preValues) => ({
      ...preValues,
      profiledetailtypes: updatedFieldSets,
    }));
  };

  const handleRemoveFieldSet = async (idToRemove, itemId) => {
    if (itemId) {
      const result = await Swal.fire({
        text: "আপনি কি মুছে ফেলতে চান?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "না",
        confirmButtonText: "হ্যাঁ",
      });

      if (result.isConfirmed) {
        try {
          await deleteProfileType({
            id: itemId,
            fromData: { profiledetail: mainData?.id },
          }).unwrap();
          removeJoutho(idToRemove);
          toast.success("মুছে ফেলা হয়েছে");
        } catch (err) {
          toast.error(err?.data?.message || "মুছে ফেলতে ব্যর্থ হয়েছে");
        }
      }
    } else {
      removeJoutho(idToRemove);
    }
  };

  const fileInputRefs = useRef([]);

  const fileInputRef = useRef(null);
  const fileInputRefNid = useRef(null);
  const fileInputRefLicense = useRef(null);
  const fileInputRefTin = useRef(null);
  const fileInputRefMemorandum = useRef(null);
  const fileInputRefInstStruct = useRef(null);
  const fileInputRefselfLegacy = useRef(null);
  const fileInputRefMusic_filmm = useRef(null);
  const fileInputRefLiterature_doc = useRef(null);

  const handleFileChange = (field, e) => {
    const file = e.currentTarget.files[0];
    setFieldValue(field, file);
  };

  // const handleFileRemove = (field, ref) => {
  //   setFieldValue(field, null);
  //   if (ref.current) {
  //     ref.current.value = "";
  //   }
  // };
  const extractIdentifier = (url) => {
    const parts = url.split("/");
    const name = parts[parts.length - 1];
    const folderName = parts[parts.length - 2];
    const fileName = name.split(".")[0];
    return { folderName, fileName };
  };

  // const handleDynamicFileRemove = (field, index) => {
  //   setFieldValue(field, null);
  //   if (fileInputRefs.current[index]) {
  //     fileInputRefs.current[index].value = "";
  //   }
  // };
  const handleDynamicFileRemove = async (field, index, imgUrl, newValues) => {
    const updateState = () => {
      setFieldValue(field, null);
      if (fileInputRefs.current[index]) {
        fileInputRefs.current[index].value = "";
      }
    };

    if (!newValues) {
      const result = await Swal.fire({
        text: "আপনি কি মুছে ফেলতে চান?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "না",
        confirmButtonText: "হ্যাঁ",
      });

      if (result.isConfirmed) {
        const { folderName, fileName } = extractIdentifier(imgUrl);
        try {
          await deleteExistsFile({
            folder: folderName,
            fileName: fileName,
          }).unwrap();
          toast.success("মুছে ফেলা হয়েছে");
        } catch (err) {
          toast.error(err?.data?.message || "মুছে ফেলতে ব্যর্থ হয়েছে");
        }
      }
    } else {
      updateState();
    }
  };
  const handleFileRemove = async (field, ref, imgUrl, newValues) => {
    const updateState = () => {
      setFieldValue(field, null);
      if (ref.current) {
        ref.current.value = "";
      }
    };

    if (!newValues) {
      const result = await Swal.fire({
        text: "আপনি কি মুছে ফেলতে চান?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "না",
        confirmButtonText: "হ্যাঁ",
      });

      if (result.isConfirmed) {
        const { folderName, fileName } = extractIdentifier(imgUrl);
        try {
          await deleteExistsFile({
            folder: folderName,
            fileName: fileName,
          }).unwrap();
          toast.success("মুছে ফেলা হয়েছে");
        } catch (err) {
          toast.error(err?.data?.message || "মুছে ফেলতে ব্যর্থ হয়েছে");
        }
      }
    } else {
      updateState();
    }
  };

  // step 4
  const handleAddFieldSetWorkSoftCopy = () => {
    const newFieldSet = {
      workSoftCopy: "",
    };
    setValues((prevValues) => ({
      ...prevValues,
      workSoftCopies: [...prevValues.workSoftCopies, newFieldSet],
    }));
  };

  const handleRemoveFieldSetWorkSoftCopy = async (idToRemove, item) => {
    const updateCheckboxState = () => {
      const updatedFieldSets = values.workSoftCopies.filter(
        (_, i) => i !== idToRemove
      );
      setValues((preValues) => ({
        ...preValues,
        workSoftCopies: updatedFieldSets,
      }));
    };

    if (item?.name) {
      const result = await Swal.fire({
        text: "আপনি কি মুছে ফেলতে চান?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "না",
        confirmButtonText: "হ্যাঁ",
      });

      if (result.isConfirmed) {
        try {
          await deleteWorkSoftCopyType({
            id: mainData?.id,
            fileName: item?.name,
          }).unwrap();
          updateCheckboxState(); // Update checkbox state after successful deletion
          toast.success("মুছে ফেলা হয়েছে");
        } catch (err) {
          toast.error(err?.data?.message || "মুছে ফেলতে ব্যর্থ হয়েছে");
        }
      }
    } else {
      updateCheckboxState(); // Update checkbox state directly when itemId is not present
    }
  };

  // step 4
  const handleAddFieldSetBoxPartSeven = () => {
    const newFieldSet = {
      writerName: "",
      writerAddress: "",
      writerNationality: "বাংলাদেশী",
      writerDeadDate: "",
    };
    setValues((prevValues) => ({
      ...prevValues,
      writerdetails: [...prevValues.writerdetails, newFieldSet],
    }));
  };

  const handleRemoveFieldSetBoxPartSeven = async (idToRemove, itemId) => {
    const updateCheckboxState = () => {
      const updatedFieldSets = values.writerdetails.filter(
        (_, i) => i !== idToRemove
      );
      setValues((preValues) => ({
        ...preValues,
        writerdetails: updatedFieldSets,
      }));
    };

    if (itemId) {
      const result = await Swal.fire({
        text: "আপনি কি মুছে ফেলতে চান?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "না",
        confirmButtonText: "হ্যাঁ",
      });

      if (result.isConfirmed) {
        try {
          await deleteWriterDetails({
            id: itemId,
            fromData: {
              profiledetail: mainData?.id,
            },
          }).unwrap();
          updateCheckboxState(); // Update checkbox state after successful deletion
          toast.success("মুছে ফেলা হয়েছে");
        } catch (err) {
          toast.error(err?.data?.message || "মুছে ফেলতে ব্যর্থ হয়েছে");
        }
      }
    } else {
      updateCheckboxState(); // Update checkbox state directly when itemId is not present
    }
  };

  // step 10
  const handleAddFieldSetBoxPartNine = () => {
    const newFieldSet = {
      year: "",
      place: "",
      name: "",
      address: "",
      nationality: "বাংলাদেশী",
      literature_more_doc: "",
    };
    setValues((prevValues) => ({
      ...prevValues,
      publishdetails: [...prevValues.publishdetails, newFieldSet],
    }));
  };

  const handleRemoveFieldSetBoxPartNine = async (idToRemove, itemId) => {
    const updateCheckboxState = () => {
      const updatedFieldSets = values.publishdetails.filter(
        (_, i) => i !== idToRemove
      );
      setValues((preValues) => ({
        ...preValues,
        publishdetails: updatedFieldSets,
      }));
    };

    if (itemId) {
      const result = await Swal.fire({
        text: "আপনি কি মুছে ফেলতে চান?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "না",
        confirmButtonText: "হ্যাঁ",
      });

      if (result.isConfirmed) {
        try {
          await deletePublishDetails({
            id: itemId,
            fromData: {
              profiledetail: mainData?.id,
            },
          }).unwrap();
          updateCheckboxState(); // Update checkbox state after successful deletion
          toast.success("মুছে ফেলা হয়েছে");
        } catch (err) {
          toast.error(err?.data?.message || "মুছে ফেলতে ব্যর্থ হয়েছে");
        }
      }
    } else {
      updateCheckboxState(); // Update checkbox state directly when itemId is not present
    }
  };

  // step 11
  const handleAddFieldSetBoxPartTen = () => {
    const newFieldSet = {
      ownerName: "",
      ownerAddress: "",
      ownerNationality: "বাংলাদেশী",
      partTitle: "",
    };
    setValues((prevValues) => ({
      ...prevValues,
      ownertypes: [...prevValues.ownertypes, newFieldSet],
    }));
  };
  const handleRemoveFieldSetBoxPartTen = async (idToRemove, itemId) => {
    const updateCheckboxState = () => {
      const updatedFieldSets = values.ownertypes.filter(
        (_, i) => i !== idToRemove
      );
      setValues((preValues) => ({
        ...preValues,
        ownertypes: updatedFieldSets,
      }));
    };

    if (itemId) {
      const result = await Swal.fire({
        text: "আপনি কি মুছে ফেলতে চান?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "না",
        confirmButtonText: "হ্যাঁ",
      });

      if (result.isConfirmed) {
        try {
          await deleteOwnerType({
            id: itemId,
            fromData: {
              profiledetail: mainData?.id,
            },
          }).unwrap();
          updateCheckboxState(); // Update checkbox state after successful deletion
          toast.success("মুছে ফেলা হয়েছে");
        } catch (err) {
          toast.error(err?.data?.message || "মুছে ফেলতে ব্যর্থ হয়েছে");
        }
      }
    } else {
      updateCheckboxState(); // Update checkbox state directly when itemId is not present
    }
  };

  // step 12
  const handleAddFieldSetBoxPartEleven = () => {
    const newFieldSet = {
      name: "",
      address: "",
      nationality: "বাংলাদেশী",
    };
    setValues((prevValues) => ({
      ...prevValues,
      powertypes: [...prevValues.powertypes, newFieldSet],
    }));
  };

  const handleRemoveFieldSetBoxPartEleven = async (idToRemove, itemId) => {
    const updateCheckboxState = () => {
      const updatedFieldSets = values.powertypes.filter(
        (_, i) => i !== idToRemove
      );
      setValues((preValues) => ({
        ...preValues,
        powertypes: updatedFieldSets,
      }));
    };

    if (itemId) {
      const result = await Swal.fire({
        text: "আপনি কি মুছে ফেলতে চান?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "না",
        confirmButtonText: "হ্যাঁ",
      });

      if (result.isConfirmed) {
        try {
          await deletePowerType({
            id: itemId,
            fromData: {
              profiledetail: mainData?.id,
            },
          }).unwrap();
          updateCheckboxState(); // Update checkbox state after successful deletion
          toast.success("মুছে ফেলা হয়েছে");
        } catch (err) {
          toast.error(err?.data?.message || "মুছে ফেলতে ব্যর্থ হয়েছে");
        }
      }
    } else {
      updateCheckboxState(); // Update checkbox state directly when itemId is not present
    }
  };

  // step 14
  const handleAddFieldSetEngCertificate = () => {
    const newFieldSet = {
      author_creator: "",
      owner_right: "",
      address: "",
    };
    setValues((prevValues) => ({
      ...prevValues,
      certificatelanguages: [...prevValues.certificatelanguages, newFieldSet],
    }));
  };

  const handleRemoveFieldSetEngCertificate = async (idToRemove, itemId) => {
    const updateCheckboxState = () => {
      const updatedFieldSets = values.certificatelanguages.filter(
        (_, i) => i !== idToRemove
      );
      setValues((preValues) => ({
        ...preValues,
        certificatelanguages: updatedFieldSets,
      }));
    };

    if (itemId) {
      const result = await Swal.fire({
        text: "আপনি কি মুছে ফেলতে চান?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "না",
        confirmButtonText: "হ্যাঁ",
      });

      if (result.isConfirmed) {
        try {
          await deleteCertificate({ id1: mainData?.id, id2: itemId }).unwrap();
          updateCheckboxState(); // Update checkbox state after successful deletion
          toast.success("মুছে ফেলা হয়েছে");
        } catch (err) {
          toast.error(err?.data?.message || "মুছে ফেলতে ব্যর্থ হয়েছে");
        }
      }
    } else {
      updateCheckboxState(); // Update checkbox state directly when itemId is not present
    }
  };

  // step 3
  const handleAddFieldSetProneta = () => {
    const newFieldSet = {
      relatedright_id: 13,
      noc: "",
      sphoto: "",
    };
    setValues((prevValues) => ({
      ...prevValues,
      selftypes: [...prevValues.selftypes, newFieldSet],
    }));
  };

  // self type
  const removeSelftTypes = (idToRemove) => {
    const updatedFieldSets = values.selftypes.filter(
      (_, i) => i !== idToRemove
    );
    setValues((preValues) => ({
      ...preValues,
      selftypes: updatedFieldSets,
    }));
  };

  const handleRemoveFieldSetProneta = async (idToRemove, itemId) => {
    if (itemId) {
      const result = await Swal.fire({
        text: "আপনি কি মুছে ফেলতে চান?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "না",
        confirmButtonText: "হ্যাঁ",
      });

      if (result.isConfirmed) {
        try {
          await deleteSelfType({
            id: itemId,
            fromData: {
              profiledetail: mainData?.id,
            },
          }).unwrap();
          removeJoutho(idToRemove);
          toast.success("মুছে ফেলা হয়েছে");
        } catch (err) {
          toast.error(err?.data?.message || "মুছে ফেলতে ব্যর্থ হয়েছে");
        }
      }
    } else {
      removeSelftTypes(idToRemove);
    }
  };

  // Toggole Dropdown
  const toggleSection2 = (side) => {
    setValues((prevValues) => ({
      ...prevValues,
      type: side,
    }));
  };

  const toggleSection3 = (side) => {
    setValues((prevState) => ({
      ...prevState,
      selfType: Number(side),
    }));
  };

  const handleCheckboxClick = async (box, itemId) => {
    // Update the checkbox state regardless of itemId
    const updateCheckboxState = () => {
      setCheckBoxKeyType((prev) =>
        prev.includes(box)
          ? prev.filter((item) => item !== box)
          : [...prev, box]
      );

      setValues((prevValues) => {
        const updatedSelftypes = prevValues.basicworktypes.map((item, i) => {
          // Ensure the item exists before attempting to update it
          if (!item) return item;

          const iniData = {
            title: "",
            language: "",
            worktypes: [
              { name: "", address: "", nationality: "বাংলাদেশী", deadDate: "" },
            ],
            basicpublishes: [
              { name: "", address: "", nationality: "বাংলাদেশী" },
            ],
            authorize: 2,
            authorizetypes: [
              {
                name: "",
                address: "",
                nationality: "বাংলাদেশী",
                approval_sc: "",
              },
            ],
          };

          if (i === box) {
            // Toggle keytype between box and null if already selected
            const updatedKeytype = item.keytype === box ? "" : box;
            return { ...item, keytype: updatedKeytype, ...iniData };
          } else {
            // Keep existing item unchanged
            return item;
          }
        });

        return {
          ...prevValues,
          basicworktypes: updatedSelftypes,
        };
      });
    };

    if (itemId) {
      const result = await Swal.fire({
        text: "আপনি কি মুছে ফেলতে চান?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "না",
        confirmButtonText: "হ্যাঁ",
      });

      if (result.isConfirmed) {
        try {
          await deleteBasicWorkType({
            id: itemId,
            fromData: { profiledetail: mainData?.id },
          }).unwrap();
          updateCheckboxState(); // Update checkbox state after successful deletion
          toast.success("মুছে ফেলা হয়েছে");
        } catch (err) {
          toast.error(err?.data?.message || "মুছে ফেলতে ব্যর্থ হয়েছে");
        }
      }
    } else {
      updateCheckboxState(); // Update checkbox state directly when itemId is not present
    }
  };

  const toggleSection7 = (bool) => {
    setValues((prevState) => ({
      ...prevState,
      workPublishtype: bool,
      pub_unpub: bool,
      publishyear: "",
      publishplace: "",
      publishname: "",
      publishaddress: "",
      publishnationality: "বাংলাদেশী",
      literature_doc: "",
      year: "",
      month: "",
      day: "",
    }));
  };

  const toggleSection9 = (bool) => {
    setValues((prevState) => ({
      ...prevState,
      otherPublishtype: bool,
    }));
  };

  const toggleSection10 = (bool) => {
    setValues((prevState) => ({
      ...prevState,
      ownerType: bool,
    }));
  };

  const toggleSection11 = (bool) => {
    setValues((prevState) => ({
      ...prevState,
      powerType: bool,
    }));
  };

  const toggleSection14 = (bool) => {
    setValues((prevState) => ({
      ...prevState,
      certificate: bool,
    }));
  };

  const handleWorkMoulik = (val) => {
    setValues((prevState) => ({
      ...prevState,
      basicWorktype: val,
    }));
  };

  const RelatedRightList = relatedRightData?.data?.map((data) => {
    return {
      value: data.id,
      label: data.name,
    };
  });

  // Selected defoult items
  const CopyrightListRR = copyrightData?.data?.map((data) => {
    return {
      value: data.id,
      label: data.name,
    };
  });

  const finOptionData =
    values?.selfType === 1 ? CopyrightListRR : RelatedRightList;

  const handleSelectChange = (selectedOption) => {
    setValues((prevValues) => ({
      ...prevValues,
      rightTypes: selectedOption.value,
    }));
  };

  const textSection3 = applicationFormTitle(values.rightTypes);

  useEffect(() => {
    if (isSuccessCategory) {
      fetchSubCategory({ parent_id: values.category_id });
    }
  }, [isSuccessCategory]);

  const WorkTypeList = categoryData?.data?.map((data) => {
    return {
      value: data.id,
      label: data.name,
    };
  });

  const WorkTypeSubList = subCategoryData?.data?.map((data) => {
    return {
      value: data.id,
      label: data.name,
    };
  });

  useEffect(() => {
    const defaultWorkTypes = [
      {
        keytype: "",
        testkeytype: 0,
        title: "",
        language: "",
        worktypes: [
          { name: "", address: "", nationality: "বাংলাদেশী", deadDate: "" },
        ],
        basicpublishes: [{ name: "", address: "", nationality: "বাংলাদেশী" }],
        authorize: 2,
        authorizetypes: [
          { name: "", address: "", nationality: "বাংলাদেশী", approval_sc: "" },
        ],
      },
      {
        keytype: "",
        testkeytype: 1,
        title: "",
        language: "",
        worktypes: [
          { name: "", address: "", nationality: "বাংলাদেশী", deadDate: "" },
        ],
        basicpublishes: [{ name: "", address: "", nationality: "বাংলাদেশী" }],
        authorize: 2,
        authorizetypes: [
          { name: "", address: "", nationality: "বাংলাদেশী", approval_sc: "" },
        ],
      },
      {
        keytype: "",
        testkeytype: 2,
        title: "",
        language: "",
        worktypes: [
          { name: "", address: "", nationality: "বাংলাদেশী", deadDate: "" },
        ],
        basicpublishes: [{ name: "", address: "", nationality: "বাংলাদেশী" }],
        authorize: 2,
        authorizetypes: [
          { name: "", address: "", nationality: "বাংলাদেশী", approval_sc: "" },
        ],
      },
      {
        keytype: "",
        testkeytype: 3,
        title: "",
        language: "",
        worktypes: [
          { name: "", address: "", nationality: "বাংলাদেশী", deadDate: "" },
        ],
        basicpublishes: [{ name: "", address: "", nationality: "বাংলাদেশী" }],
        authorize: 2,
        authorizetypes: [
          { name: "", address: "", nationality: "বাংলাদেশী", approval_sc: "" },
        ],
      },
    ];

    const getValueType = (type) => ({
      id: type.id || "",
      keytype: type.keytype || 0,
      testkeytype: type.keytype || 0,
      title: type.title || "",
      language: type.language || "",
      worktypes:
        type?.workTypes && type?.workTypes?.length > 0
          ? type?.workTypes.map((item) => ({
              id: item.id,
              name: item.name,
              address: item.address,
              nationality: item.nationality,
              deadDate: item?.deadDate !== null ? item.deadDate : "",
            }))
          : [{ name: "", address: "", nationality: "বাংলাদেশী", deadDate: "" }],
      basicpublishes: type?.basicPublishes || [
        { name: "", address: "", nationality: "বাংলাদেশী" },
      ],
      authorize: Number(type?.authorize) || 2,
      authorizetypes:
        type?.authorizeTypes && type?.authorizeTypes?.length > 0
          ? type.authorizeTypes.map((item) => ({
              id: item.id,
              name: item.name,
              address: item.address,
              nationality: item.nationality,
              approval_sc: "",
            }))
          : [
              {
                name: "",
                address: "",
                nationality: "বাংলাদেশী",
                approval_sc: "",
              },
            ],
    });

    // Initialize a mapping for testkeytypes
    const workTypeMap = {
      0: null,
      1: null,
      2: null,
      3: null,
    };

    if (mainData?.basicWorkTypes) {
      mainData.basicWorkTypes.forEach((type) => {
        const testKey = type.keytype;
        if (workTypeMap[testKey] === null) {
          workTypeMap[testKey] = getValueType(type);
        }
      });
    }

    // Create the final result ensuring we always have the four testkeytypes
    const resultWorkTypes = Object.values(workTypeMap).map(
      (item, index) => item || defaultWorkTypes[index]
    );

    // console.log(resultWorkTypes);

    if (mainData?.basicWorkTypes) {
      setCheckBoxKeyType(mainData.basicWorkTypes.map((type) => type.keytype));
    }

    if (mainData) {
      fetchSubCategory({ parent_id: mainData?.category?.id });

      setValues((prev) => ({
        ...prev,

        trackReg: trackReg,
        application_id: mainData.profile_id,
        id: mainData?.id,
        // for প্রথম প্রকাশনার বছর mainData?.publish?.year
        year: extractPublicationDate(mainData?.publish?.year, "year"),
        month: extractPublicationDate(mainData?.publish?.year, "month"),
        day: extractPublicationDate(mainData?.publish?.year, "day"),
        // for publication pub_unpub_year
        pub_year: extractPublicationDate(mainData?.pub_unpub_year, "year"),
        pub_month: extractPublicationDate(mainData?.pub_unpub_year, "month"),
        pub_day: extractPublicationDate(mainData?.pub_unpub_year, "day"),

        type: Number(mainData?.type) || 1,
        profiledetailtypes:
          mainData?.profileDetailType && mainData?.profileDetailType.length > 0
            ? mainData?.profileDetailType.map((item, index) => ({
                id: item.id,
                name: item.name,
                email: item.email,
                nationality: item.nationality,
                address: item.address,
                mobile: item.mobile,

                companyName: item?.companyName,
                companyAddress: item?.companyAddress,
                pRegno: item?.pRegno,
                designation: item?.designation,
                photo: item?.photo,

                image: "",
                nid: "",
                license: "",
                tin: "",
                memorandum: "",
                inst_struct: "",
              }))
            : [
                {
                  name: "",
                  email: "",
                  mobile: "",
                  address: "",
                  image: "",
                  nid: "",
                  license: "",
                  tin: "",
                  memorandum: "",
                  inst_struct: "",
                  nationality: "বাংলাদেশী",
                  companyName: "",
                  companyAddress: "",
                  designation: "",
                  pRegno: "",
                },
              ],

        // step 3
        rightTypes: mainData?.selfTypes?.[0]?.relatedRight?.id || 13,
        selfType: Number(mainData?.selfType) || 1,
        selfOther: mainData?.selfTypes?.[0]?.others || "",
        selfLegacy: "",
        selftypes: mainData?.selfTypes
          ? mainData?.selfTypes.map((item) => ({
              relatedright_id: item?.relatedRight?.id,
              noc: "",
              sphoto: item?.sphoto,
              id: item?.id,
            }))
          : [{ relatedright_id: 13, noc: "" }],

        category_id: mainData?.category?.id || "",
        subCategory: mainData?.subCategory?.id || "",
        otherSubCategory: mainData?.otherSubCategory || "",
        workDescription: mainData?.workDescription || "",
        workSoftCopies:
          mainData?.workSoftCopies && mainData?.workSoftCopies.length > 0
            ? mainData?.workSoftCopies.map((item) => ({
                nameSoftCopy: item.name,
                workSoftCopy: "",
              }))
            : [
                {
                  workSoftCopy: "",
                },
              ],
        music_film: "",

        basicWorktype: Number(mainData?.basicWorktype) || 1,
        basicworktypes: resultWorkTypes,

        // step 5 6 7
        workTitle: mainData?.workTitle || "",
        workLanguage: mainData?.workLanguage || "",
        writerdetails: mainData?.writerDetails || [
          {
            writerName: "",
            writerAddress: "",
            writerNationality: "বাংলাদেশী",
            writerDeadDate: "",
          },
        ],

        // step 8 9
        workPublishtype: Number(mainData?.workPublishtype) || 2,
        publishyear: mainData?.publish?.year || "",
        publishplace: mainData?.publish?.place || "",
        publishname: mainData?.publish?.name || "",
        publishaddress: mainData?.publish?.address || "",
        publishnationality: mainData?.publish?.nationality || "বাংলাদেশী",
        literature_doc: "",

        // step 10
        otherPublishtype: Number(mainData?.otherPublishtype) || 2,
        publishdetails:
          mainData?.publishDetails && mainData?.publishDetails?.length > 0
            ? mainData?.publishDetails.map((item) => ({
                id: item.id,
                year: item.year || "",
                place: item.place || "",
                name: item.name || "",
                address: item.address || "",
                nationality: item.nationality || "বাংলাদেশী",
                literature_more_doc: "",
              }))
            : [
                {
                  year: "",
                  place: "",
                  name: "",
                  address: "",
                  nationality: "বাংলাদেশী",
                  literature_more_doc: "",
                },
              ],

        // step 11
        ownerType: Number(mainData?.ownerType) || 2,
        ownertypes: mainData?.ownerTypes || [
          {
            ownerName: "",
            ownerAddress: "",
            ownerNationality: "বাংলাদেশী",
            partTitle: "",
          },
        ],

        // step 12
        powerType: Number(mainData?.powerType) || 2,
        powertypes:
          mainData?.powerTypes && mainData?.powerTypes.length > 0
            ? mainData?.powerTypes.map((item) => ({
                id: item.id,
                name: item.name,
                address: item.address,
                nationality: item.nationality,
                license_pay: "",
              }))
            : [
                {
                  name: "",
                  address: "",
                  nationality: "বাংলাদেশী",
                  license_pay: "",
                },
              ],
        // STEP 13
        industryactions: mainData?.industryActions
          ? [mainData?.industryActions]
          : [
              {
                place: "",
                name: "",
                address: "",
                nationality: "বাংলাদেশী",
                endYear: "",
              },
            ],

        // step 14
        comment: mainData?.comment || "",
        certificate: Number(mainData?.certificate) || 1,
        work_title: mainData?.work_title || "",
        certificatelanguages: mainData?.certificateLangs || [
          {
            author_creator: "",
            owner_right: "",
            address: "",
          },
        ],
        pub_unpub: Number(mainData?.pub_unpub) || 2,
        pub_unpub_year: mainData?.pub_unpub_year || "",
      }));
    }
  }, [mainData]);

  console.log(values);

  useEffect(() => {
    if (isError) {
      navigate("/page-not-found");
    }
  }, [isError]);

  if (isLoadingCopyright || isLoadingRelatedRight || isLoading) {
    return <Loader />;
  }

  const basicWorktypeId = (val) => {
    return values.basicworktypes[val]?.id || "";
  };

  return (
    <div>
      <section className="section py-5 copyrightformone service-single">
        <div className="container">
          <div className="d-flex justify-content-center align-items-center">
            <div className="card border-0 shadow w-100">
              <div
                className="tab-content rounded border py-4 px-4 paddddingnone border-1"
                id="nav-tabContent"
              >
                <div className="text-end me-4">
                  <div className="">
                    {" "}
                    <img
                      src={`${publicUrl}/assets/images/p2.png`}
                      alt="Logo"
                      style={{
                        height: "60px",
                        width: "60px",
                        marginTop: "-47px",
                      }}
                      className="img-fluid pataresponsive"
                    />{" "}
                  </div>
                </div>

                <div
                  className="tab-pane fade active show"
                  id="nav-application"
                  role="tabpanel"
                  aria-labelledby="nav-application-tab"
                >
                  <div className="applicationForm">
                    <div
                      style={{ marginTop: "-20px" }}
                      className={`${styles.add2ApplicationHeader} applicationForm-header text-center`}
                    >
                      <h4 className="mb-0 form2fontsizeee">
                        <b>বিষয়াবলীর বিবরণী </b>
                      </h4>
                      <p className="form2fontsizeee2 mb-4">
                        <b>কপিরাইট রেজিস্ট্রেশনের আবেদন ফরম</b>
                      </p>
                    </div>

                    <div className="mb-3 mt-4 form2fontsizeee3 pt-2">
                      <div className={styles.add2sectiontitle}>
                        <div className="add2-section-title">
                          <div className="d-flex widtghh ">
                            <h5 className="mt-2">
                              <b>১. নিবন্ধন সংখ্যাঃ </b>
                            </h5>

                            <h5 className="px-3 widtghhh ms-3  py-2 text-center rounded border text-success">
                              <b> কপিরাইট অফিস কর্তৃক পূরণীয় </b>
                            </h5>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className={`${styles.add2Section2}`}>
                      <div className="row">
                        <div className="col-md-12">
                          <div className="add2-section-title">
                            <div className="row">
                              <div className="col-md-3 mt-2 mtop0 col-sm-12 col-12">
                                <label>২. প্রনেতা/স্বত্বাধিকারীর ধরনঃ</label>
                              </div>
                              <div className="col-md-9 my-1 col-sm-12 col-12">
                                <span className="d-flex ptop0 pt-1">
                                  <div className="form-check form-check-inline">
                                    <input
                                      className="form-check-input"
                                      type="radio"
                                      name="sideRadio"
                                      id="sideRadio1"
                                      checked={values.type === 1}
                                      onClick={() => toggleSection2(1)}
                                    />
                                    <label
                                      className="form-check-label"
                                      htmlFor="sideRadio1"
                                    >
                                      ব্যক্তি
                                    </label>
                                  </div>
                                  <div className="form-check form-check-inline">
                                    <input
                                      className="form-check-input"
                                      type="radio"
                                      name="sideRadio"
                                      id="sideRadio2"
                                      checked={values.type === 2}
                                      onClick={() => toggleSection2(2)}
                                    />
                                    <label
                                      className="form-check-label"
                                      htmlFor="sideRadio2"
                                    >
                                      যৌথ
                                    </label>
                                  </div>
                                  <div className="form-check form-check-inline">
                                    <input
                                      className="form-check-input"
                                      type="radio"
                                      name="sideRadio"
                                      id="sideRadio3"
                                      checked={values.type === 3}
                                      onClick={() => toggleSection2(3)}
                                    />
                                    <label
                                      className="form-check-label"
                                      htmlFor="sideRadio3"
                                    >
                                      প্রতিষ্ঠান
                                    </label>
                                  </div>
                                </span>
                              </div>
                            </div>
                          </div>

                          {values.type === 1 && (
                            <div
                              className={`${styles.add2PanelBackground} mb-2`}
                            >
                              <div className="row">
                                <div className="col-md-4">
                                  <div className="row align-items-end">
                                    {/* Profile Picture */}
                                    <div className="col-md-6 col-sm-12 mtop0 my-1">
                                      <div
                                        className={`${styles.add2FormGroupImagePreviewBox} form-group imgmffargin text-center`}
                                      >
                                        <label>
                                          প্রনেতা/স্বত্বাধিকারীর ছবি <br /> (
                                          আবশ্যিক )
                                        </label>
                                        <br />
                                        <div
                                          className={`${styles.imagePreviewAdd2}`}
                                        >
                                          <span>
                                            {values.profiledetailtypes?.[0]
                                              ?.image ||
                                            mainData?.profileDetailType?.[0]
                                              ?.image ? (
                                              <img
                                                src={
                                                  values.profiledetailtypes?.[0]
                                                    ?.image
                                                    ? URL.createObjectURL(
                                                        values
                                                          .profiledetailtypes[0]
                                                          .image
                                                      )
                                                    : mainData
                                                        ?.profileDetailType?.[0]
                                                        ?.image || ""
                                                }
                                                alt="প্রিভিউ"
                                              />
                                            ) : (
                                              "- - - - -"
                                            )}
                                          </span>
                                        </div>

                                        <div>
                                          {errors.profiledetailtypes?.[0]
                                            ?.image && (
                                            <small className="my-2 text-danger w-100">
                                              {
                                                errors.profiledetailtypes[0]
                                                  .image
                                              }
                                            </small>
                                          )}
                                        </div>

                                        <label className="custombtnn5">
                                          <input
                                            ref={fileInputRef}
                                            type="file"
                                            accept=".png,.jpg,.jpeg"
                                            className="d-none"
                                            onChange={(e) =>
                                              handleFileChange(
                                                "profiledetailtypes[0].image",
                                                e
                                              )
                                            }
                                          />
                                          {values.profiledetailtypes?.[0]
                                            ?.image ||
                                          mainData?.profileDetailType?.[0]
                                            ?.image
                                            ? "পরিবর্তন করুন"
                                            : "নির্বাচন করুন"}
                                        </label>
                                      </div>
                                    </div>

                                    {/* NID/Passport */}
                                    <div className="col-md-6 col-sm-12 my-1">
                                      <div
                                        className={`${styles.add2FormGroupImagePreviewBox} form-group imgmffargin text-center`}
                                      >
                                        <label>
                                          জাতীয় পরিচয় পত্র/জন্ম
                                          নিবন্ধন/পাসপোর্টের স্ক্যান কপি (
                                          আবশ্যিক )
                                        </label>
                                        <br />
                                        <div
                                          className={styles.imagePreviewAdd2}
                                        >
                                          {values.profiledetailtypes?.[0]
                                            ?.nid ||
                                          mainData?.profileDetailType?.[0]
                                            ?.nid ? (
                                            <span>
                                              <div className="d-flex">
                                                <div
                                                  className="showiconss"
                                                  onClick={() => {
                                                    if (
                                                      values
                                                        .profiledetailtypes?.[0]
                                                        ?.nid
                                                    ) {
                                                      setPreviewModal(true);
                                                      setPreviewFile(
                                                        values
                                                          .profiledetailtypes?.[0]
                                                          ?.nid
                                                      );
                                                    } else {
                                                      setPreviewModalOld(true);
                                                      setPreviewImgUrl(
                                                        mainData
                                                          ?.profileDetailType?.[0]
                                                          ?.nid
                                                      );
                                                    }
                                                  }}
                                                >
                                                  <IoMdEye
                                                    className={
                                                      styles.showiconss
                                                    }
                                                  />
                                                </div>
                                                {values.profiledetailtypes?.[0]
                                                  ?.nid && (
                                                  <div
                                                    className="removesssIcon"
                                                    onClick={() => {
                                                      handleFileRemove(
                                                        "profiledetailtypes[0].nid",
                                                        fileInputRef,
                                                        mainData
                                                          ?.profileDetailType?.[0]
                                                          ?.nid,
                                                        values
                                                          .profiledetailtypes?.[0]
                                                          ?.nid
                                                      );
                                                    }}
                                                  >
                                                    <ImCross
                                                      className={
                                                        styles.removesssIcon
                                                      }
                                                    />
                                                  </div>
                                                )}
                                              </div>
                                            </span>
                                          ) : (
                                            <span
                                              className={styles.placeholder}
                                            >
                                              - - - - -
                                            </span>
                                          )}
                                        </div>

                                        <div>
                                          {errors.profiledetailtypes?.[0]
                                            ?.nid && (
                                            <small className="my-2 text-danger">
                                              {errors.profiledetailtypes[0].nid}
                                            </small>
                                          )}
                                        </div>

                                        <label className="custombtnn5">
                                          <input
                                            ref={fileInputRef}
                                            type="file"
                                            accept=".png,.jpg,.jpeg,.pdf"
                                            className="d-none"
                                            onChange={(e) =>
                                              handleFileChange(
                                                "profiledetailtypes[0].nid",
                                                e
                                              )
                                            }
                                          />
                                          {values.profiledetailtypes?.[0]
                                            ?.nid ||
                                          mainData?.profileDetailType?.[0]?.nid
                                            ? "পরিবর্তন করুন"
                                            : "নির্বাচন করুন"}
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div className="col-md-8">
                                  <div className="row">
                                    <div className="col-md-4 my-1">
                                      <div className="form-group">
                                        <label>আবেদনকারীর নাম</label>
                                        <ClientFormikFormFieldMultiple
                                          placeholder="আবেদনকারীর নাম"
                                          value={
                                            values?.profiledetailtypes[0].name
                                          }
                                          name={`profiledetailtypes[0].name`}
                                          errors={
                                            errors?.profiledetailtypes?.[0]
                                              ?.name
                                          }
                                          touched={
                                            touched?.profiledetailtypes?.[0]
                                              ?.name
                                          }
                                          handleChange={handleChange}
                                          handleBlur={handleBlur}
                                        />
                                      </div>
                                    </div>

                                    <div className="col-md-8 my-1">
                                      <div className="form-group">
                                        <label>ঠিকানা</label>
                                        <ClientFormikFormFieldMultiple
                                          placeholder="ঠিকানা"
                                          value={
                                            values?.profiledetailtypes[0]
                                              .address
                                          }
                                          name={`profiledetailtypes[0].address`}
                                          errors={
                                            errors?.profiledetailtypes?.[0]
                                              ?.address
                                          }
                                          touched={
                                            touched?.profiledetailtypes?.[0]
                                              ?.address
                                          }
                                          handleChange={handleChange}
                                          handleBlur={handleBlur}
                                        />
                                      </div>
                                    </div>

                                    <div className="col-md-4 my-1">
                                      <div className="form-group">
                                        <label>জাতীয়তা</label>
                                        <ClientFormikFormFieldMultiple
                                          placeholder="জাতীয়তা"
                                          value={
                                            values?.profiledetailtypes[0]
                                              .nationality
                                          }
                                          name={`profiledetailtypes[0].nationality`}
                                          errors={
                                            errors?.profiledetailtypes?.[0]
                                              ?.nationality
                                          }
                                          touched={
                                            touched?.profiledetailtypes?.[0]
                                              ?.nationality
                                          }
                                          handleChange={handleChange}
                                          handleBlur={handleBlur}
                                        />
                                      </div>
                                    </div>

                                    <div className="col-md-4 my-1">
                                      <div className="form-group">
                                        <label>মোবাইল নম্বর</label>
                                        <ClientFormikFormFieldMultiple
                                          placeholder="মোবাইল নম্বর"
                                          value={
                                            values?.profiledetailtypes[0].mobile
                                          }
                                          name={`profiledetailtypes[0].mobile`}
                                          errors={
                                            errors?.profiledetailtypes?.[0]
                                              ?.mobile
                                          }
                                          touched={
                                            touched?.profiledetailtypes?.[0]
                                              ?.mobile
                                          }
                                          handleChange={handleChange}
                                          handleBlur={handleBlur}
                                        />
                                      </div>
                                    </div>

                                    <div className="col-md-4 my-1">
                                      <div className="form-group">
                                        <label>ই-মেইল</label>
                                        <ClientFormikFormFieldMultiple
                                          placeholder="ই-মেইল"
                                          value={
                                            values?.profiledetailtypes[0].email
                                          }
                                          name={`profiledetailtypes[0].email`}
                                          errors={
                                            errors?.profiledetailtypes?.[0]
                                              ?.email
                                          }
                                          touched={
                                            touched?.profiledetailtypes?.[0]
                                              ?.email
                                          }
                                          handleChange={handleChange}
                                          handleBlur={handleBlur}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}

                          {values.type === 2 && (
                            <div>
                              {values?.profiledetailtypes.map((item, index) => (
                                <div
                                  key={index}
                                  className={`${styles.add2PanelBackground} mb-2`}
                                >
                                  <div className="row">
                                    <div className="col-md-4 col-sm-12">
                                      <div className="row align-items-end">
                                        <div className="col-md-6 col-sm-12 mtop0 my-1">
                                          <div
                                            className={`${styles.add2FormGroupImagePreviewBox} form-group imgmffargin text-center`}
                                          >
                                            <label>
                                              প্রনেতা/স্বত্বাধিকারীর ছবি <br />{" "}
                                              ( আবশ্যিক )
                                            </label>
                                            <br />
                                            <div
                                              className={`${styles.imagePreviewAdd2}`}
                                            >
                                              <span>
                                                {values.profiledetailtypes?.[
                                                  index
                                                ]?.image ||
                                                mainData.profileDetailType?.[
                                                  index
                                                ]?.image ? (
                                                  <>
                                                    <img
                                                      src={
                                                        values
                                                          .profiledetailtypes?.[
                                                          index
                                                        ]?.image
                                                          ? URL.createObjectURL(
                                                              values
                                                                .profiledetailtypes?.[
                                                                index
                                                              ]?.image
                                                            )
                                                          : mainData
                                                              .profileDetailType?.[
                                                              index
                                                            ]?.image || ""
                                                      }
                                                      alt="প্রিভিউ"
                                                    />
                                                  </>
                                                ) : (
                                                  "- - - - -"
                                                )}
                                              </span>
                                            </div>

                                            <div>
                                              {errors.profiledetailtypes?.[
                                                index
                                              ]?.image &&
                                                touched.profiledetailtypes?.[
                                                  index
                                                ]?.image && (
                                                  <small className="my-2 text-danger">
                                                    {" "}
                                                    {
                                                      errors
                                                        .profiledetailtypes?.[
                                                        index
                                                      ]?.image
                                                    }
                                                  </small>
                                                )}
                                            </div>

                                            <label className="custombtnn5">
                                              <input
                                                type="file"
                                                className="d-none"
                                                accept=".png,.jpg,.jpeg"
                                                onChange={(e) =>
                                                  handleFileChange(
                                                    `profiledetailtypes[${index}].image`,

                                                    e
                                                  )
                                                }
                                              />
                                              {values.profiledetailtypes?.[
                                                index
                                              ]?.image ||
                                              mainData.profileDetailType?.[
                                                index
                                              ]?.image
                                                ? "পরিবর্তন করুন"
                                                : "নির্বাচন করুন"}
                                            </label>
                                          </div>
                                        </div>

                                        <div className="col-md-6 col-sm-12 my-1">
                                          <div
                                            className={`${styles.add2FormGroupImagePreviewBox} form-group imgmffargin text-center`}
                                          >
                                            <label>
                                              জাতীয় পরিচয় পত্র/জন্ম
                                              নিবন্ধন/পাসপোর্টের স্ক্যান কপি (
                                              আবশ্যিক )
                                            </label>
                                            <br />
                                            <div
                                              className={
                                                styles.imagePreviewAdd2
                                              }
                                            >
                                              {values.profiledetailtypes?.[
                                                index
                                              ]?.nid ||
                                              mainData?.profileDetailType?.[
                                                index
                                              ]?.nid ? (
                                                <span>
                                                  <div className="d-flex">
                                                    <div
                                                      className="showiconss"
                                                      onClick={() => {
                                                        if (
                                                          values
                                                            .profiledetailtypes?.[
                                                            index
                                                          ]?.nid
                                                        ) {
                                                          setPreviewModal(true);
                                                          setPreviewFile(
                                                            values
                                                              .profiledetailtypes?.[
                                                              index
                                                            ]?.nid
                                                          );
                                                        } else {
                                                          setPreviewModalOld(
                                                            true
                                                          );
                                                          setPreviewImgUrl(
                                                            mainData
                                                              ?.profileDetailType?.[
                                                              index
                                                            ]?.nid
                                                          );
                                                        }
                                                      }}
                                                    >
                                                      <IoMdEye
                                                        className={
                                                          styles.showiconss
                                                        }
                                                      />
                                                    </div>
                                                    {values
                                                      .profiledetailtypes?.[0]
                                                      ?.nid && (
                                                      <div
                                                        className="removesssIcon"
                                                        onClick={() =>
                                                          handleDynamicFileRemove(
                                                            `profiledetailtypes[${index}].nid`,
                                                            index,
                                                            mainData
                                                              ?.profileDetailType?.[
                                                              index
                                                            ]?.nid,
                                                            values
                                                              .profiledetailtypes?.[
                                                              index
                                                            ]?.nid
                                                          )
                                                        }
                                                      >
                                                        <ImCross
                                                          className={
                                                            styles.removesssIcon
                                                          }
                                                        />
                                                      </div>
                                                    )}
                                                  </div>
                                                </span>
                                              ) : (
                                                <span
                                                  className={styles.placeholder}
                                                >
                                                  - - - - -
                                                </span>
                                              )}
                                            </div>

                                            <div>
                                              {errors.profiledetailtypes?.[
                                                index
                                              ]?.nid && (
                                                <small className="my-2 text-danger">
                                                  {
                                                    errors.profiledetailtypes[
                                                      index
                                                    ].nid
                                                  }
                                                </small>
                                              )}
                                            </div>

                                            <label className="custombtnn5">
                                              <input
                                                ref={(el) =>
                                                  (fileInputRefs.current[
                                                    index
                                                  ] = el)
                                                }
                                                type="file"
                                                accept=".png,.jpg,.jpeg,.pdf"
                                                className="d-none"
                                                onChange={(e) =>
                                                  handleFileChange(
                                                    `profiledetailtypes[${index}].nid`,
                                                    e
                                                  )
                                                }
                                              />
                                              {values.profiledetailtypes?.[
                                                index
                                              ]?.nid ||
                                              mainData?.profileDetailType?.[
                                                index
                                              ]?.nid
                                                ? "পরিবর্তন করুন"
                                                : "নির্বাচন করুন"}
                                            </label>
                                          </div>
                                        </div>
                                      </div>
                                    </div>

                                    <div className="col-md-8">
                                      <div className="row">
                                        <div className="col-md-4 my-1">
                                          <div className="form-group">
                                            <label>আবেদনকারীর নাম</label>
                                            <ClientFormikFormFieldMultiple
                                              placeholder="আবেদনকারীর নাম"
                                              value={item.name}
                                              name={`profiledetailtypes[${index}].name`}
                                              errors={
                                                errors?.profiledetailtypes &&
                                                errors.profiledetailtypes
                                                  ?.length > 0 &&
                                                errors?.profiledetailtypes[
                                                  index
                                                ]?.name
                                              }
                                              touched={
                                                touched?.profiledetailtypes &&
                                                touched.profiledetailtypes
                                                  ?.length > index &&
                                                touched?.profiledetailtypes[
                                                  index
                                                ]?.name
                                              }
                                              handleChange={handleChange}
                                              handleBlur={handleBlur}
                                            />
                                          </div>
                                        </div>

                                        <div className="col-md-4 my-1">
                                          <div className="form-group">
                                            <label>ঠিকানা</label>
                                            <ClientFormikFormFieldMultiple
                                              placeholder="ঠিকানা"
                                              value={item.address}
                                              name={`profiledetailtypes[${index}].address`}
                                              errors={
                                                errors?.profiledetailtypes &&
                                                errors.profiledetailtypes
                                                  ?.length > 0 &&
                                                errors?.profiledetailtypes[
                                                  index
                                                ]?.address
                                              }
                                              touched={
                                                touched?.profiledetailtypes &&
                                                touched.profiledetailtypes
                                                  ?.length > index &&
                                                touched?.profiledetailtypes[
                                                  index
                                                ]?.address
                                              }
                                              handleChange={handleChange}
                                              handleBlur={handleBlur}
                                            />
                                          </div>
                                        </div>

                                        <div className="col-md-4 my-1">
                                          <div className="form-group">
                                            <label>জাতীয়তা</label>
                                            <ClientFormikFormFieldMultiple
                                              placeholder="জাতীয়তা"
                                              value={item.nationality}
                                              name={`profiledetailtypes[${index}].nationality`}
                                              errors={
                                                errors?.profiledetailtypes &&
                                                errors.profiledetailtypes
                                                  ?.length > 0 &&
                                                errors?.profiledetailtypes[
                                                  index
                                                ]?.nationality
                                              }
                                              touched={
                                                touched?.profiledetailtypes &&
                                                touched.profiledetailtypes
                                                  ?.length > index &&
                                                touched?.profiledetailtypes[
                                                  index
                                                ]?.nationality
                                              }
                                              handleChange={handleChange}
                                              handleBlur={handleBlur}
                                            />
                                          </div>
                                        </div>

                                        <div className="col-md-4 my-1">
                                          <div className="form-group">
                                            <label>মোবাইল নম্বর</label>
                                            <ClientFormikFormFieldMultiple
                                              placeholder="মোবাইল নম্বর"
                                              value={item.mobile}
                                              name={`profiledetailtypes[${index}].mobile`}
                                              errors={
                                                errors?.profiledetailtypes &&
                                                errors.profiledetailtypes
                                                  ?.length > 0 &&
                                                errors?.profiledetailtypes[
                                                  index
                                                ]?.mobile
                                              }
                                              touched={
                                                touched?.profiledetailtypes &&
                                                touched.profiledetailtypes
                                                  ?.length > index &&
                                                touched?.profiledetailtypes[
                                                  index
                                                ]?.mobile
                                              }
                                              handleChange={handleChange}
                                              handleBlur={handleBlur}
                                            />
                                          </div>
                                        </div>

                                        <div className="col-md-4 my-1">
                                          <div className="form-group">
                                            <label>ই-মেইল</label>
                                            <ClientFormikFormFieldMultiple
                                              placeholder="ই-মেইল"
                                              value={item.email}
                                              name={`profiledetailtypes[${index}].email`}
                                              errors={
                                                errors?.profiledetailtypes &&
                                                errors.profiledetailtypes
                                                  ?.length > 0 &&
                                                errors?.profiledetailtypes[
                                                  index
                                                ]?.email
                                              }
                                              touched={
                                                touched?.profiledetailtypes &&
                                                touched.profiledetailtypes
                                                  ?.length > index &&
                                                touched?.profiledetailtypes[
                                                  index
                                                ]?.email
                                              }
                                              handleChange={handleChange}
                                              handleBlur={handleBlur}
                                            />
                                          </div>
                                        </div>

                                        <div className="col-md-4 centeraddremovebtn my-1">
                                          <div className="form-group">
                                            <div className="mtop2 mt-4">
                                              {(values.profiledetailtypes
                                                .length ===
                                                index + 1 ||
                                                values.profiledetailtypes
                                                  .length === 1) && (
                                                <ImPlus
                                                  className="customAddButton  rounded"
                                                  onClick={handleAddFieldSet}
                                                />
                                              )}
                                              {values.profiledetailtypes
                                                .length > 1 &&
                                                index !== 0 && (
                                                  <IoCloseSharp
                                                    className="customRemoveButton rounded"
                                                    onClick={() =>
                                                      handleRemoveFieldSet(
                                                        index,
                                                        item?.id
                                                      )
                                                    }
                                                  />
                                                )}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              ))}
                            </div>
                          )}

                          {values.type === 3 && (
                            <div
                              className={`${styles.add2PanelBackground} mb-2`}
                            >
                              <div className="row">
                                <div className="col-md-3 mtop0 my-1">
                                  <div className="form-group">
                                    <label>প্রতিষ্ঠানের নাম</label>
                                    <ClientFormikFormFieldMultiple
                                      placeholder="প্রতিষ্ঠানের নাম"
                                      value={
                                        values?.profiledetailtypes[0]
                                          .companyName
                                      }
                                      name={`profiledetailtypes[0].companyName`}
                                      errors={
                                        errors?.profiledetailtypes &&
                                        errors.profiledetailtypes?.length > 0 &&
                                        errors?.profiledetailtypes[0]
                                          ?.companyName
                                      }
                                      touched={
                                        touched?.profiledetailtypes &&
                                        touched.profiledetailtypes?.length >
                                          0 &&
                                        touched?.profiledetailtypes[0]
                                          ?.companyName
                                      }
                                      handleChange={handleChange}
                                      handleBlur={handleBlur}
                                    />
                                  </div>
                                </div>

                                <div className="col-md-3 my-1">
                                  <div className="form-group">
                                    <label>প্রতিষ্ঠানের ঠিকানা</label>
                                    <ClientFormikFormFieldMultiple
                                      placeholder="প্রতিষ্ঠানের ঠিকানা"
                                      value={
                                        values?.profiledetailtypes[0]
                                          .companyAddress
                                      }
                                      name={`profiledetailtypes[0].companyAddress`}
                                      errors={
                                        errors?.profiledetailtypes &&
                                        errors.profiledetailtypes?.length > 0 &&
                                        errors?.profiledetailtypes[0]
                                          ?.companyAddress
                                      }
                                      touched={
                                        touched?.profiledetailtypes &&
                                        touched.profiledetailtypes?.length >
                                          0 &&
                                        touched?.profiledetailtypes[0]
                                          ?.companyAddress
                                      }
                                      handleChange={handleChange}
                                      handleBlur={handleBlur}
                                    />
                                  </div>
                                </div>

                                <div className="col-md-3 my-1">
                                  <div className="form-group">
                                    <label>
                                      প্রতিষ্ঠানের পক্ষে আবেদনকারীর নাম
                                    </label>
                                    <ClientFormikFormFieldMultiple
                                      placeholder="প্রতিষ্ঠানের পক্ষে আবেদনকারীর নাম"
                                      value={values?.profiledetailtypes[0].name}
                                      name={`profiledetailtypes[0].name`}
                                      errors={
                                        errors?.profiledetailtypes &&
                                        errors.profiledetailtypes?.length > 0 &&
                                        errors?.profiledetailtypes[0]?.name
                                      }
                                      touched={
                                        touched?.profiledetailtypes &&
                                        touched.profiledetailtypes?.length >
                                          0 &&
                                        touched?.profiledetailtypes[0]?.name
                                      }
                                      handleChange={handleChange}
                                      handleBlur={handleBlur}
                                    />
                                  </div>
                                </div>

                                <div className="col-md-3 my-1">
                                  <div className="form-group">
                                    <label>আবেদনকারীর ঠিকানা</label>
                                    <ClientFormikFormFieldMultiple
                                      placeholder="আবেদনকারীর ঠিকানা"
                                      value={
                                        values?.profiledetailtypes[0].address
                                      }
                                      name={`profiledetailtypes[0].address`}
                                      errors={
                                        errors?.profiledetailtypes &&
                                        errors.profiledetailtypes?.length > 0 &&
                                        errors?.profiledetailtypes[0]?.address
                                      }
                                      touched={
                                        touched?.profiledetailtypes &&
                                        touched.profiledetailtypes?.length >
                                          0 &&
                                        touched?.profiledetailtypes[0]?.address
                                      }
                                      handleChange={handleChange}
                                      handleBlur={handleBlur}
                                    />
                                  </div>
                                </div>

                                <div className="col-md-3 my-1">
                                  <div className="form-group">
                                    <label>আবেদনকারীর পদবী</label>
                                    <ClientFormikFormFieldMultiple
                                      placeholder="আবেদনকারীর পদবী"
                                      value={
                                        values?.profiledetailtypes[0]
                                          .designation
                                      }
                                      name={`profiledetailtypes[0].designation`}
                                      errors={
                                        errors?.profiledetailtypes &&
                                        errors.profiledetailtypes?.length > 0 &&
                                        errors?.profiledetailtypes[0]
                                          ?.designation
                                      }
                                      touched={
                                        touched?.profiledetailtypes &&
                                        touched.profiledetailtypes?.length >
                                          0 &&
                                        touched?.profiledetailtypes[0]
                                          ?.designation
                                      }
                                      handleChange={handleChange}
                                      handleBlur={handleBlur}
                                    />
                                  </div>
                                </div>

                                <div className="col-md-3 my-1">
                                  <div className="form-group">
                                    <label>মোবাইল নম্বর</label>
                                    <ClientFormikFormFieldMultiple
                                      placeholder="মোবাইল নম্বর"
                                      value={
                                        values?.profiledetailtypes[0].mobile
                                      }
                                      name={`profiledetailtypes[0].mobile`}
                                      errors={
                                        errors?.profiledetailtypes &&
                                        errors.profiledetailtypes?.length > 0 &&
                                        errors?.profiledetailtypes[0]?.mobile
                                      }
                                      touched={
                                        touched?.profiledetailtypes &&
                                        touched.profiledetailtypes?.length >
                                          0 &&
                                        touched?.profiledetailtypes[0]?.mobile
                                      }
                                      handleChange={handleChange}
                                      handleBlur={handleBlur}
                                    />
                                  </div>
                                </div>

                                <div className="col-md-3 my-1">
                                  <div className="form-group">
                                    <label>ই-মেইল</label>
                                    <ClientFormikFormFieldMultiple
                                      placeholder="ই-মেইল"
                                      value={
                                        values?.profiledetailtypes[0].email
                                      }
                                      name={`profiledetailtypes[0].email`}
                                      errors={
                                        errors?.profiledetailtypes &&
                                        errors.profiledetailtypes?.length > 0 &&
                                        errors?.profiledetailtypes[0]?.email
                                      }
                                      touched={
                                        touched?.profiledetailtypes &&
                                        touched.profiledetailtypes?.length >
                                          0 &&
                                        touched?.profiledetailtypes[0]?.email
                                      }
                                      handleChange={handleChange}
                                      handleBlur={handleBlur}
                                    />
                                  </div>
                                </div>

                                <div className="col-md-3 my-1">
                                  <div className="form-group">
                                    <label>
                                      রেজিস্ট্রেশন নম্বর ( সামাজিক সংগঠনের
                                      ক্ষেত্রে)
                                    </label>
                                    <ClientFormikFormFieldMultiple
                                      placeholder="সামাজিক সংগঠনের রেজিস্ট্রেশন নম্বর"
                                      value={
                                        values?.profiledetailtypes[0].pRegno
                                      }
                                      name={`profiledetailtypes[0].pRegno`}
                                      errors={
                                        errors?.profiledetailtypes &&
                                        errors.profiledetailtypes?.length > 0 &&
                                        errors?.profiledetailtypes[0]?.pRegno
                                      }
                                      touched={
                                        touched?.profiledetailtypes &&
                                        touched.profiledetailtypes?.length >
                                          0 &&
                                        touched?.profiledetailtypes[0]?.pRegno
                                      }
                                      handleChange={handleChange}
                                      handleBlur={handleBlur}
                                    />
                                  </div>
                                </div>

                                <div className="col-md-12 mt-3">
                                  <div className="row align-items-end">
                                    <div className="col-md-2">
                                      <div
                                        className={`${styles.add2FormGroupImagePreviewBox} form-group imgmffargin text-center`}
                                      >
                                        <label>
                                          প্রনেতা/স্বত্বাধিকারীর ছবি <br /> (
                                          আবশ্যিক )
                                        </label>
                                        <br />
                                        <div
                                          className={`${styles.imagePreviewAdd2}`}
                                        >
                                          <span>
                                            {values.profiledetailtypes?.[0]
                                              ?.image ||
                                            mainData?.profileDetailType?.[0]
                                              ?.image ? (
                                              <>
                                                <img
                                                  src={
                                                    values
                                                      .profiledetailtypes?.[0]
                                                      ?.image
                                                      ? URL.createObjectURL(
                                                          values
                                                            .profiledetailtypes?.[0]
                                                            ?.image
                                                        )
                                                      : mainData
                                                          ?.profileDetailType?.[0]
                                                          ?.image || ""
                                                  }
                                                  alt="প্রিভিউ"
                                                />
                                              </>
                                            ) : (
                                              "- - - - -"
                                            )}
                                          </span>
                                        </div>

                                        <div>
                                          {errors.profiledetailtypes?.[0]
                                            ?.image && (
                                            <small className="my-2 text-danger">
                                              {
                                                errors.profiledetailtypes?.[0]
                                                  ?.image
                                              }
                                            </small>
                                          )}
                                        </div>

                                        <label className="custombtnn5">
                                          <input
                                            type="file"
                                            accept=".png,.jpg,.jpeg"
                                            className="d-none"
                                            onChange={(e) => {
                                              handleFileChange(
                                                `profiledetailtypes[0].image`,

                                                e
                                              );
                                            }}
                                          />
                                          {values.profiledetailtypes?.[0]
                                            ?.image ||
                                          mainData?.profileDetailType?.[0]
                                            ?.image
                                            ? "পরিবর্তন করুন"
                                            : "নির্বাচন করুন"}
                                        </label>
                                      </div>
                                    </div>

                                    <div className="col-md-2">
                                      <div
                                        className={`${styles.add2FormGroupImagePreviewBox} form-group imgmffargin text-center`}
                                      >
                                        <label>
                                          জাতীয় পরিচয় পত্র/জন্ম
                                          নিবন্ধন/পাসপোর্টের স্ক্যান কপি (
                                          আবশ্যিক )
                                        </label>
                                        <br />
                                        <div
                                          className={styles.imagePreviewAdd2}
                                        >
                                          {values.profiledetailtypes?.[0]
                                            ?.nid ||
                                          mainData?.profileDetailType?.[0]
                                            ?.nid ? (
                                            <span>
                                              <div className="d-flex">
                                                <div
                                                  className="showiconss"
                                                  onClick={() => {
                                                    if (
                                                      values
                                                        .profiledetailtypes?.[0]
                                                        ?.nid
                                                    ) {
                                                      setPreviewModal(true);
                                                      setPreviewFile(
                                                        values
                                                          .profiledetailtypes?.[0]
                                                          ?.nid
                                                      );
                                                    } else {
                                                      setPreviewModalOld(true);
                                                      setPreviewImgUrl(
                                                        mainData
                                                          ?.profileDetailType?.[0]
                                                          ?.nid
                                                      );
                                                    }
                                                  }}
                                                >
                                                  <IoMdEye
                                                    className={
                                                      styles.showiconss
                                                    }
                                                  />
                                                </div>
                                                {values.profiledetailtypes?.[0]
                                                  ?.nid && (
                                                  <div
                                                    className="removesssIcon"
                                                    onClick={() => {
                                                      handleFileRemove(
                                                        "profiledetailtypes[0].nid",
                                                        fileInputRef,
                                                        mainData
                                                          ?.profileDetailType?.[0]
                                                          ?.nid,
                                                        values
                                                          .profiledetailtypes?.[0]
                                                          ?.nid
                                                      );
                                                    }}
                                                  >
                                                    <ImCross
                                                      className={
                                                        styles.removesssIcon
                                                      }
                                                    />
                                                  </div>
                                                )}
                                              </div>
                                            </span>
                                          ) : (
                                            <span
                                              className={styles.placeholder}
                                            >
                                              - - - - -
                                            </span>
                                          )}
                                        </div>

                                        <div>
                                          {errors.profiledetailtypes?.[0]
                                            ?.nid && (
                                            <small className="my-2 text-danger">
                                              {errors.profiledetailtypes[0].nid}
                                            </small>
                                          )}
                                        </div>

                                        <label className="custombtnn5">
                                          <input
                                            ref={fileInputRef}
                                            type="file"
                                            accept=".png,.jpg,.jpeg,.pdf"
                                            className="d-none"
                                            onChange={(e) =>
                                              handleFileChange(
                                                "profiledetailtypes[0].nid",
                                                e
                                              )
                                            }
                                          />
                                          {values.profiledetailtypes?.[0]
                                            ?.nid ||
                                          mainData?.profileDetailType?.[0]?.nid
                                            ? "পরিবর্তন করুন"
                                            : "নির্বাচন করুন"}
                                        </label>
                                      </div>
                                    </div>

                                    <div className="col-md-2">
                                      <div
                                        className={`${styles.add2FormGroupImagePreviewBox} form-group imgmffargin text-center`}
                                      >
                                        <label>
                                          প্রতিষ্ঠানের ট্রেড লাইসেন্সের স্ক্যান
                                          কপি ( ব্যবসায়িক প্রতিষ্ঠানের ক্ষেত্রে
                                          প্রযোজ্য )
                                        </label>
                                        <br />

                                        <div
                                          className={styles.imagePreviewAdd2}
                                        >
                                          {values.profiledetailtypes?.[0]
                                            ?.license ||
                                          mainData?.profileDetailType?.[0]
                                            ?.license ? (
                                            <span>
                                              <div className="d-flex">
                                                <div
                                                  className="showiconss"
                                                  onClick={() => {
                                                    if (
                                                      values
                                                        .profiledetailtypes?.[0]
                                                        ?.license
                                                    ) {
                                                      setPreviewModal(true);
                                                      setPreviewFile(
                                                        values
                                                          .profiledetailtypes?.[0]
                                                          ?.license
                                                      );
                                                    } else {
                                                      setPreviewModalOld(true);
                                                      setPreviewImgUrl(
                                                        mainData
                                                          ?.profileDetailType?.[0]
                                                          ?.license
                                                      );
                                                    }
                                                  }}
                                                >
                                                  <IoMdEye
                                                    className={
                                                      styles.showiconss
                                                    }
                                                  />
                                                </div>
                                                {values.profiledetailtypes?.[0]
                                                  ?.nid && (
                                                  <div
                                                    className="removesssIcon"
                                                    onClick={() =>
                                                      handleFileRemove(
                                                        "profiledetailtypes[0].license",
                                                        fileInputRefLicense,
                                                        mainData
                                                          ?.profileDetailType?.[0]
                                                          ?.license,
                                                        values
                                                          .profiledetailtypes?.[0]
                                                          ?.license
                                                      )
                                                    }
                                                  >
                                                    <ImCross
                                                      className={
                                                        styles.removesssIcon
                                                      }
                                                    />
                                                  </div>
                                                )}
                                              </div>
                                            </span>
                                          ) : (
                                            <span
                                              className={styles.placeholder}
                                            >
                                              - - - - -
                                            </span>
                                          )}
                                        </div>

                                        <div>
                                          {errors.profiledetailtypes?.[0]
                                            ?.license && (
                                            <small className="my-2 text-danger">
                                              {
                                                errors.profiledetailtypes?.[0]
                                                  ?.license
                                              }
                                            </small>
                                          )}
                                        </div>

                                        <label className="custombtnn5">
                                          <input
                                            ref={fileInputRefLicense}
                                            type="file"
                                            className="d-none"
                                            accept=".png,.jpg,.jpeg,.pdf"
                                            onChange={(e) =>
                                              handleFileChange(
                                                "profiledetailtypes[0].license",
                                                e
                                              )
                                            }
                                          />
                                          {values.profiledetailtypes?.[0]
                                            ?.license ||
                                          mainData?.profileDetailType?.[0]
                                            ?.license
                                            ? "পরিবর্তন করুন"
                                            : "নির্বাচন করুন"}
                                        </label>
                                      </div>
                                    </div>

                                    <div className="col-md-2">
                                      <div
                                        className={`${styles.add2FormGroupImagePreviewBox} form-group imgmffargin text-center`}
                                      >
                                        <label>
                                          প্রতিষ্ঠানের টিন সার্টিফিকেটের স্ক্যান
                                          কপি ( প্রযোজ্য ক্ষেত্রে )
                                        </label>
                                        <br />

                                        <div
                                          className={styles.imagePreviewAdd2}
                                        >
                                          {values.profiledetailtypes?.[0]
                                            ?.tin ||
                                          mainData?.profileDetailType?.[0]
                                            ?.tin ? (
                                            <span>
                                              <div className="d-flex">
                                                <div
                                                  className="showiconss"
                                                  onClick={() => {
                                                    if (
                                                      values
                                                        .profiledetailtypes?.[0]
                                                        ?.tin
                                                    ) {
                                                      setPreviewModal(true);
                                                      setPreviewFile(
                                                        values
                                                          .profiledetailtypes?.[0]
                                                          ?.tin
                                                      );
                                                    } else {
                                                      setPreviewModalOld(true);
                                                      setPreviewImgUrl(
                                                        mainData
                                                          ?.profileDetailType?.[0]
                                                          ?.tin
                                                      );
                                                    }
                                                  }}
                                                >
                                                  <IoMdEye
                                                    className={
                                                      styles.showiconss
                                                    }
                                                  />
                                                </div>
                                                {values.profiledetailtypes?.[0]
                                                  ?.nid && (
                                                  <div
                                                    className="removesssIcon"
                                                    onClick={() =>
                                                      handleFileRemove(
                                                        "profiledetailtypes[0].tin",
                                                        fileInputRefTin,
                                                        mainData
                                                          ?.profileDetailType?.[0]
                                                          ?.tin,
                                                        values
                                                          .profiledetailtypes?.[0]
                                                          ?.tin
                                                      )
                                                    }
                                                  >
                                                    <ImCross
                                                      className={
                                                        styles.removesssIcon
                                                      }
                                                    />
                                                  </div>
                                                )}
                                              </div>
                                            </span>
                                          ) : (
                                            <span
                                              className={styles.placeholder}
                                            >
                                              - - - - -
                                            </span>
                                          )}
                                        </div>

                                        <div>
                                          {errors.profiledetailtypes?.[0]
                                            ?.tin && (
                                            <small className="my-2 text-danger">
                                              {
                                                errors.profiledetailtypes?.[0]
                                                  ?.tin
                                              }
                                            </small>
                                          )}
                                        </div>

                                        <label className="custombtnn5">
                                          <input
                                            ref={fileInputRefTin}
                                            type="file"
                                            className="d-none"
                                            accept=".png,.jpg,.jpeg,.pdf"
                                            onChange={(e) =>
                                              handleFileChange(
                                                `profiledetailtypes[0].tin`,
                                                e
                                              )
                                            }
                                          />
                                          {values.profiledetailtypes?.[0]
                                            ?.tin ||
                                          mainData?.profileDetailType?.[0]?.tin
                                            ? "পরিবর্তন করুন"
                                            : "নির্বাচন করুন"}
                                        </label>
                                      </div>
                                    </div>

                                    <div className="col-md-2">
                                      <div
                                        className={`${styles.add2FormGroupImagePreviewBox} form-group imgmffargin text-center`}
                                      >
                                        <label>
                                          প্রতিষ্ঠানের মেমোর‍্যান্ডামের স্ক্যান
                                          কপি (প্রযোজ্য ক্ষেত্রে)
                                        </label>
                                        <br />
                                        <div
                                          className={styles.imagePreviewAdd2}
                                        >
                                          {values.profiledetailtypes?.[0]
                                            ?.memorandum ||
                                          mainData?.profileDetailType?.[0]
                                            ?.memorandum ? (
                                            <span>
                                              <div className="d-flex">
                                                <div
                                                  className="showiconss"
                                                  onClick={() => {
                                                    if (
                                                      values
                                                        .profiledetailtypes?.[0]
                                                        ?.memorandum
                                                    ) {
                                                      setPreviewModal(true);
                                                      setPreviewFile(
                                                        values
                                                          .profiledetailtypes?.[0]
                                                          ?.memorandum
                                                      );
                                                    } else {
                                                      setPreviewModalOld(true);
                                                      setPreviewImgUrl(
                                                        mainData
                                                          ?.profileDetailType?.[0]
                                                          ?.memorandum
                                                      );
                                                    }
                                                  }}
                                                >
                                                  <IoMdEye
                                                    className={
                                                      styles.showiconss
                                                    }
                                                  />
                                                </div>

                                                {values.profiledetailtypes?.[0]
                                                  ?.nid && (
                                                  <div
                                                    className="removesssIcon"
                                                    onClick={() =>
                                                      handleFileRemove(
                                                        "profiledetailtypes[0].memorandum",
                                                        fileInputRefMemorandum,
                                                        mainData
                                                          ?.profileDetailType?.[0]
                                                          ?.memorandum,
                                                        values
                                                          .profiledetailtypes?.[0]
                                                          ?.memorandum
                                                      )
                                                    }
                                                  >
                                                    <ImCross
                                                      className={
                                                        styles.removesssIcon
                                                      }
                                                    />
                                                  </div>
                                                )}
                                              </div>
                                            </span>
                                          ) : (
                                            <span
                                              className={styles.placeholder}
                                            >
                                              - - - - -
                                            </span>
                                          )}
                                        </div>

                                        <div>
                                          {errors.profiledetailtypes?.[0]
                                            ?.memorandum && (
                                            <small className="my-2 text-danger">
                                              {
                                                errors.profiledetailtypes?.[0]
                                                  ?.memorandum
                                              }
                                            </small>
                                          )}
                                        </div>

                                        <label className="custombtnn5">
                                          <input
                                            ref={fileInputRefMemorandum}
                                            type="file"
                                            className="d-none"
                                            accept=".png,.jpg,.jpeg,.pdf"
                                            onChange={(e) =>
                                              handleFileChange(
                                                "profiledetailtypes[0].memorandum",
                                                e
                                              )
                                            }
                                          />
                                          {values.profiledetailtypes?.[0]
                                            ?.memorandum ||
                                          mainData?.profileDetailType?.[0]
                                            ?.memorandum
                                            ? "পরিবর্তন করুন"
                                            : "নির্বাচন করুন"}
                                        </label>
                                      </div>
                                    </div>

                                    <div className="col-md-2">
                                      <div
                                        className={`${styles.add2FormGroupImagePreviewBox} form-group mbottom0 imgmffargin text-center`}
                                      >
                                        <label>
                                          প্রতিষ্ঠানের গঠনতন্ত্রের স্ক্যান কপি (
                                          সামাজিক সংগঠনের ক্ষেত্রে প্রযোজ্য )
                                        </label>
                                        <br />
                                        <div
                                          className={styles.imagePreviewAdd2}
                                        >
                                          {values.profiledetailtypes?.[0]
                                            ?.inst_struct ||
                                          mainData?.profileDetailType?.[0]
                                            ?.instStruct ? (
                                            <span>
                                              <div className="d-flex">
                                                <div
                                                  className="showiconss"
                                                  onClick={() => {
                                                    if (
                                                      values
                                                        .profiledetailtypes?.[0]
                                                        ?.inst_struct
                                                    ) {
                                                      setPreviewModal(true);
                                                      setPreviewFile(
                                                        values
                                                          .profiledetailtypes?.[0]
                                                          ?.inst_struct
                                                      );
                                                    } else {
                                                      setPreviewModalOld(true);
                                                      setPreviewImgUrl(
                                                        mainData
                                                          ?.profileDetailType?.[0]
                                                          ?.instStruct
                                                      );
                                                    }
                                                  }}
                                                >
                                                  <IoMdEye
                                                    className={
                                                      styles.showiconss
                                                    }
                                                  />
                                                </div>

                                                {values.profiledetailtypes?.[0]
                                                  ?.nid && (
                                                  <div
                                                    className="removesssIcon"
                                                    onClick={() =>
                                                      handleFileRemove(
                                                        "profiledetailtypes[0].inst_struct",
                                                        fileInputRefInstStruct,
                                                        mainData
                                                          ?.profileDetailType?.[0]
                                                          ?.instStruct,
                                                        values
                                                          .profiledetailtypes?.[0]
                                                          ?.inst_struct
                                                      )
                                                    }
                                                  >
                                                    <ImCross
                                                      className={
                                                        styles.removesssIcon
                                                      }
                                                    />
                                                  </div>
                                                )}
                                              </div>
                                            </span>
                                          ) : (
                                            <span
                                              className={styles.placeholder}
                                            >
                                              - - - - -
                                            </span>
                                          )}
                                        </div>
                                        <div>
                                          {errors.profiledetailtypes?.[0]
                                            ?.inst_struct && (
                                            <small className="my-2 text-danger">
                                              {
                                                errors.profiledetailtypes?.[0]
                                                  ?.inst_struct
                                              }
                                            </small>
                                          )}
                                        </div>
                                        <label className="custombtnn5">
                                          <input
                                            ref={fileInputRefInstStruct}
                                            type="file"
                                            className="d-none"
                                            accept=".png,.jpg,.jpeg,.pdf"
                                            onChange={(e) =>
                                              handleFileChange(
                                                "profiledetailtypes[0].inst_struct",
                                                e
                                              )
                                            }
                                          />
                                          {values.profiledetailtypes?.[0]
                                            ?.inst_struct ||
                                          mainData?.profileDetailType?.[0]
                                            ?.instStruct
                                            ? "পরিবর্তন করুন"
                                            : "নির্বাচন করুন"}
                                        </label>{" "}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>

                    <div className="add2-section-3 my-4">
                      <div className="row">
                        <div className="col-md-12">
                          <div className="add2-section-title">
                            <div className="row">
                              <div className="col-md-3 mt-2 mtop0 col-12">
                                <label>৩. কপিরাইটে স্বার্থের ধরনঃ</label>
                              </div>
                              <div className="col-md-6 my-1 col-12">
                                <span className="d-flex ptop0 pt-1">
                                  <div className="form-check form-check-inline">
                                    <input
                                      className="form-check-input"
                                      type="radio"
                                      name="section3Radio"
                                      id="section3Radio1"
                                      checked={values?.selfType === 1}
                                      onClick={() => toggleSection3(1)}
                                    />
                                    <label
                                      className="form-check-label"
                                      htmlFor="section3Radio1"
                                    >
                                      কপিরাইট
                                    </label>
                                  </div>

                                  <div className="form-check form-check-inline">
                                    <input
                                      className="form-check-input"
                                      type="radio"
                                      name="section3Radio"
                                      id="section3Radio2"
                                      checked={values?.selfType === 2}
                                      onClick={() => toggleSection3(2)}
                                    />
                                    <label
                                      className="form-check-label"
                                      htmlFor="section3Radio2"
                                    >
                                      রিলেটেড রাইট
                                    </label>
                                  </div>
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-12">
                          <div className={`${styles.add2PanelBackground} mb-2`}>
                            <div className="row">
                              <div className="col-md-3 col-12 mtop0 my-1">
                                <div className="form-group">
                                  <label>
                                    {values?.selfType === 1
                                      ? "কপিরাইটের ধরন"
                                      : "রিলেটেড রাইট"}
                                  </label>
                                  <div>
                                    <Select
                                      options={finOptionData}
                                      value={finOptionData?.find(
                                        (item) =>
                                          item.value === values.rightTypes
                                      )}
                                      onChange={handleSelectChange}
                                      classNamePrefix="react-select"
                                      placeholder="-- শ্রেণি --"
                                    />
                                  </div>
                                </div>
                              </div>

                              <div className="col-md-9 my-1">
                                <div className="row">
                                  <div className="col-md-4">
                                    <div className="row">
                                      {[10, 16].includes(values.rightTypes) && (
                                        <div className="col-md-12 mtop3">
                                          <div
                                            className={`${styles.add2FormGroupImagePreviewBox} form-group imgmffargin2 text-center`}
                                          >
                                            <label className="text-center">
                                              উত্তরাধিকার সনদপত্রের স্ক্যান কপি
                                              ( আবশ্যিক )
                                            </label>
                                            <br />
                                            <div
                                              className={
                                                styles.imagePreviewAdd2
                                              }
                                            >
                                              {values.selfLegacy ||
                                              mainData?.selfTypes?.[0]
                                                ?.selfLegacy ? (
                                                <span>
                                                  <div className="d-flex justify-content-center">
                                                    <div
                                                      className="showiconss"
                                                      onClick={() => {
                                                        if (values.selfLegacy) {
                                                          setPreviewModal(true);
                                                          setPreviewFile(
                                                            values.selfLegacy
                                                          );
                                                        } else {
                                                          setPreviewModalOld(
                                                            true
                                                          );
                                                          setPreviewImgUrl(
                                                            mainData
                                                              ?.selfTypes?.[0]
                                                              ?.selfLegacy
                                                          );
                                                        }
                                                      }}
                                                    >
                                                      <IoMdEye
                                                        className={
                                                          styles.showiconss
                                                        }
                                                      />
                                                    </div>

                                                    <div
                                                      className="removesssIcon"
                                                      onClick={() =>
                                                        handleFileRemove(
                                                          "selfLegacy",
                                                          fileInputRefselfLegacy,
                                                          mainData
                                                            ?.selfTypes?.[0]
                                                            ?.selfLegacy,
                                                          values.selfLegacy
                                                        )
                                                      }
                                                    >
                                                      <ImCross
                                                        className={
                                                          styles.removesssIcon
                                                        }
                                                      />
                                                    </div>
                                                  </div>
                                                </span>
                                              ) : (
                                                <span
                                                  className={styles.placeholder}
                                                >
                                                  - - - - -
                                                </span>
                                              )}
                                            </div>

                                            <div>
                                              {errors.selfLegacy && (
                                                <small className="my-2 text-danger">
                                                  {errors.selfLegacy}
                                                </small>
                                              )}
                                            </div>

                                            <label className="custombtnn5">
                                              <input
                                                ref={fileInputRefselfLegacy}
                                                type="file"
                                                accept=".png,.jpg,.jpeg,.pdf"
                                                className="d-none"
                                                onChange={(e) =>
                                                  handleFileChange(
                                                    `selfLegacy`,
                                                    e
                                                  )
                                                }
                                              />
                                              {values.selfLegacy ||
                                              mainData?.selfTypes?.[0]
                                                ?.selfLegacy
                                                ? "পরিবর্তন করুন"
                                                : "নির্বাচন করুন"}
                                            </label>
                                          </div>
                                        </div>
                                      )}

                                      {[6, 13, 19].includes(
                                        values.rightTypes
                                      ) && (
                                        <div className="col-md-12 col-sm-12">
                                          <div className="form-group">
                                            <label>
                                              {values.rightTypes === 13
                                                ? "প্রণেতা / রচয়িতার ধরন"
                                                : values.rightTypes === 19
                                                ? "অন্যান্য সূত্রে ধরন"
                                                : ""}
                                            </label>
                                            <div className="input">
                                              <ClientFormikFormFieldMultiple
                                                placeholder="এখানে লিখুন ... "
                                                value={values?.selfOther}
                                                name={`selfOther`}
                                                errors={
                                                  errors?.selfOther &&
                                                  errors?.selfOther
                                                }
                                                touched={
                                                  touched?.selfOther &&
                                                  touched?.selfOther
                                                }
                                                handleChange={handleChange}
                                                handleBlur={handleBlur}
                                              />
                                            </div>
                                          </div>
                                        </div>
                                      )}
                                    </div>
                                  </div>

                                  <div className="col-md-8 mtop3">
                                    {values?.selftypes.map((item, index) => (
                                      <div className="row" key={index}>
                                        <div className="col-md-9">
                                          <div
                                            className={`${styles.add2FormGroupImagePreviewBox} form-group imgmffargin2 imgmffargin3 text-center text-start`}
                                          >
                                            <label className="text-center">
                                              {textSection3}
                                            </label>
                                            <br />
                                            <div
                                              className={
                                                styles.imagePreviewAdd2
                                              }
                                            >
                                              {values.selftypes?.[index]?.noc ||
                                              mainData?.selfTypes?.[index]
                                                ?.noc ? (
                                                <span>
                                                  <div className="d-flex">
                                                    <div
                                                      className="showiconss cursor-pointer"
                                                      onClick={() => {
                                                        if (
                                                          values.selftypes?.[
                                                            index
                                                          ]?.noc
                                                        ) {
                                                          setPreviewModal(true);
                                                          setPreviewFile(
                                                            values.selftypes?.[
                                                              index
                                                            ]?.noc
                                                          );
                                                        } else {
                                                          setPreviewModalOld(
                                                            true
                                                          );
                                                          setPreviewImgUrl(
                                                            mainData
                                                              ?.selfTypes?.[
                                                              index
                                                            ]?.noc
                                                          );
                                                        }
                                                      }}
                                                    >
                                                      <IoMdEye
                                                        className={
                                                          styles.showiconss
                                                        }
                                                      />
                                                    </div>
                                                    {(values.selftypes?.[index]
                                                      ?.noc ||
                                                      (mainData?.selfTypes?.[0]
                                                        ?.noc &&
                                                        mainData?.selfTypes
                                                          ?.length === 1)) && (
                                                      <div
                                                        className="removesssIcon cursor-pointer"
                                                        onClick={() =>
                                                          handleDynamicFileRemove(
                                                            `selftypes[${index}].noc`,
                                                            index,
                                                            mainData
                                                              ?.selfTypes?.[
                                                              index
                                                            ]?.noc,
                                                            values.selftypes?.[
                                                              index
                                                            ]?.noc
                                                          )
                                                        }
                                                      >
                                                        <ImCross
                                                          className={
                                                            styles.removesssIcon
                                                          }
                                                        />
                                                      </div>
                                                    )}
                                                  </div>
                                                </span>
                                              ) : (
                                                <span
                                                  className={styles.placeholder}
                                                >
                                                  - - - - -
                                                </span>
                                              )}
                                            </div>

                                            <div>
                                              {errors.selftypes?.[index]
                                                ?.noc && (
                                                <small className="my-2 text-danger">
                                                  {
                                                    errors.selftypes?.[index]
                                                      ?.noc
                                                  }
                                                </small>
                                              )}
                                            </div>

                                            <label className="custombtnn5 mbottom0 mb-2">
                                              <input
                                                type="file"
                                                ref={(el) =>
                                                  (fileInputRefs.current[
                                                    index
                                                  ] = el)
                                                }
                                                accept=".png,.jpg,.jpeg,.pdf"
                                                className="d-none"
                                                onChange={(e) =>
                                                  handleFileChange(
                                                    `selftypes[${index}].noc`,
                                                    e
                                                  )
                                                }
                                              />
                                              {values.selftypes?.[index]?.noc ||
                                              mainData?.selfTypes?.[index]?.noc
                                                ? "পরিবর্তন করুন"
                                                : "নির্বাচন করুন"}
                                            </label>
                                          </div>
                                        </div>
                                        <div className="col-md-3  centeraddremovebtn addremovemargin">
                                          <div className="form-group">
                                            <div className="">
                                              {(values.selftypes.length ===
                                                index + 1 ||
                                                values.selftypes.length ===
                                                  1) && (
                                                <ImPlus
                                                  className="customAddButton  rounded"
                                                  onClick={
                                                    handleAddFieldSetProneta
                                                  }
                                                />
                                              )}

                                              {values.selftypes.length > 1 &&
                                                index !== 0 && (
                                                  <IoCloseSharp
                                                    className="customRemoveButton rounded"
                                                    onClick={() =>
                                                      handleRemoveFieldSetProneta(
                                                        index,
                                                        item?.id
                                                      )
                                                    }
                                                  />
                                                )}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    ))}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="add2-section-4">
                      <div className="row">
                        <div className="col-md-12">
                          <div className="add2-section-title">
                            <h5>
                              <b>৪. কর্মটির প্রকৃতি এবং বিবরণঃ</b>
                            </h5>
                          </div>
                          <div className={`${styles.add2PanelBackground}`}>
                            <div className="form-group mb-3">
                              <div className="row">
                                <div className="col-md-2">
                                  <label>ক. কর্মটির প্রকৃতি</label>
                                </div>

                                <div className="col-md-5 col-12 mb-2">
                                  <Select
                                    onBlur={handleBlur}
                                    options={WorkTypeList}
                                    value={
                                      WorkTypeList?.find(
                                        (item) =>
                                          item.value === values.category_id
                                      ) || {
                                        value: "",
                                        label: " --- বিভাগ ---",
                                        isDisabled: true,
                                      }
                                    }
                                    onChange={(selectedOption) => {
                                      setValues((prevValues) => ({
                                        ...prevValues,
                                        category_id: selectedOption.value,
                                        subCategory: "",
                                      }));
                                      fetchSubCategory({
                                        parent_id: selectedOption.value,
                                      });
                                    }}
                                    classNamePrefix="react-select"
                                  />
                                  <div>
                                    {errors.category_id &&
                                      touched.category_id && (
                                        <small className="my-2 text-danger">
                                          {errors.category_id}
                                        </small>
                                      )}
                                  </div>
                                </div>
                                <div className="col-md-5 col-12">
                                  <div className="row">
                                    <div className="col-md-12">
                                      <Select
                                        onChange={(selectedOption) => {
                                          setValues((prevValues) => ({
                                            ...prevValues,
                                            subCategory: selectedOption.value,
                                          }));
                                        }}
                                        onBlur={handleBlur}
                                        options={WorkTypeSubList}
                                        value={
                                          WorkTypeSubList?.find(
                                            (item) =>
                                              item.value === values.subCategory
                                          ) || {
                                            value: "",
                                            label: "--- উপবিভাগ ---",
                                            isDisabled: true,
                                          }
                                        }
                                        classNamePrefix="react-select"
                                        isLoading={
                                          isLoadingSubCat || isFetching
                                        }
                                        isDisabled={
                                          isLoadingSubCat || isFetching
                                        }
                                      />
                                      <div>
                                        {errors.subCategory &&
                                          touched.subCategory && (
                                            <small className="my-2 text-danger">
                                              {errors.subCategory}
                                            </small>
                                          )}
                                      </div>
                                    </div>
                                    {otherSubCategory?.data.includes(
                                      values?.subCategory
                                    ) && (
                                      <div className="col-md-12 mt-3">
                                        <ClientFormikFormFieldMultiple
                                          placeholder={"এখানে লিখুন ......"}
                                          value={values?.otherSubCategory}
                                          name={`otherSubCategory`}
                                          errors={
                                            errors?.otherSubCategory &&
                                            errors?.otherSubCategory
                                          }
                                          touched={
                                            touched?.otherSubCategory &&
                                            touched?.otherSubCategory
                                          }
                                          handleChange={handleChange}
                                          handleBlur={handleBlur}
                                        />
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="form-group mb-2">
                              <div className="row">
                                <div className="col-md-2">
                                  <label>খ. কর্মটির বিবরণ</label>
                                </div>
                                <div className="col-md-10 mb-2">
                                  <ClientFormikFormFieldMultiple
                                    placeholder={
                                      values.rightTypes === 3
                                        ? "শিল্পকর্মটির অন্তর্নিহিত ব্যাখ্যা ( থিম )"
                                        : values.rightTypes === 9
                                        ? "সফ্‌টওয়্যারটির ব্যবহার উপযোগিতা"
                                        : "এখানে লিখুন ......"
                                    }
                                    value={values?.workDescription}
                                    name={`workDescription`}
                                    errors={
                                      errors?.workDescription &&
                                      errors?.workDescription
                                    }
                                    touched={
                                      touched?.workDescription &&
                                      touched?.workDescription
                                    }
                                    handleChange={handleChange}
                                    handleBlur={handleBlur}
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="form-group mt-3 mtop0">
                              <div className="row">
                                <div className="col-md-3 col-lg-2 col-sm-12">
                                  <label>গ. কর্মের সফ্‌ট কপি</label>
                                </div>

                                <div className="col-md-10 mb-2">
                                  <div className="row">
                                    <div className="col-md-6">
                                      {values?.workSoftCopies?.map(
                                        (item, index) => (
                                          <div key={index} className="row">
                                            <div className="col-md-7 col-sm-12 mbottom0 mb-2">
                                              <div className="border bordercolorr rounded ptop0 paddingg0 p-3 pt-2">
                                                <div
                                                  className={`${styles.add2FormGroupImagePreviewBox} form-group imgmffargin text-center`}
                                                >
                                                  <label>নমুনা</label>
                                                  <br />
                                                  <div
                                                    className={
                                                      styles.imagePreviewAdd2
                                                    }
                                                  >
                                                    {values.workSoftCopies?.[
                                                      index
                                                    ]?.workSoftCopy ||
                                                    mainData?.workSoftCopies?.[
                                                      index
                                                    ]?.workSoftCopy ? (
                                                      <span>
                                                        <div className="d-flex">
                                                          <div
                                                            className="showiconss cursor-pointer"
                                                            onClick={() => {
                                                              if (
                                                                values
                                                                  .workSoftCopies?.[
                                                                  index
                                                                ]?.workSoftCopy
                                                              ) {
                                                                setPreviewModal(
                                                                  true
                                                                );
                                                                setPreviewFile(
                                                                  values
                                                                    .workSoftCopies?.[
                                                                    index
                                                                  ]
                                                                    ?.workSoftCopy
                                                                );
                                                              } else {
                                                                setPreviewModalOld(
                                                                  true
                                                                );
                                                                setPreviewImgUrl(
                                                                  mainData
                                                                    ?.workSoftCopies?.[
                                                                    index
                                                                  ]
                                                                    ?.workSoftCopy
                                                                );
                                                              }
                                                            }}
                                                          >
                                                            <IoMdEye
                                                              className={
                                                                styles.showiconss
                                                              }
                                                            />
                                                          </div>
                                                          <div
                                                            className="removesssIcon cursor-pointer"
                                                            onClick={() =>
                                                              handleDynamicFileRemove(
                                                                `workSoftCopies[${index}].workSoftCopy`,
                                                                index,
                                                                mainData
                                                                  ?.workSoftCopies?.[
                                                                  index
                                                                ]?.workSoftCopy,
                                                                values
                                                                  .workSoftCopies?.[
                                                                  index
                                                                ]?.workSoftCopy
                                                              )
                                                            }
                                                          >
                                                            <ImCross
                                                              className={
                                                                styles.removesssIcon
                                                              }
                                                            />
                                                          </div>
                                                        </div>
                                                      </span>
                                                    ) : (
                                                      <span
                                                        className={
                                                          styles.placeholder
                                                        }
                                                      >
                                                        - - - - -
                                                      </span>
                                                    )}
                                                  </div>

                                                  <div>
                                                    {errors.workSoftCopies?.[
                                                      index
                                                    ]?.workSoftCopy && (
                                                      <small className="my-2 text-danger">
                                                        {
                                                          errors
                                                            .workSoftCopies?.[
                                                            index
                                                          ]?.workSoftCopy
                                                        }
                                                      </small>
                                                    )}
                                                  </div>

                                                  <label className="custombtnn5">
                                                    <input
                                                      type="file"
                                                      ref={(el) =>
                                                        (fileInputRefs.current[
                                                          index
                                                        ] = el)
                                                      }
                                                      className="d-none"
                                                      accept=".png,.jpg,.jpeg,.pdf"
                                                      onChange={(e) =>
                                                        handleFileChange(
                                                          `workSoftCopies[${index}].workSoftCopy`,
                                                          e
                                                        )
                                                      }
                                                    />
                                                    {values.workSoftCopies?.[
                                                      index
                                                    ]?.workSoftCopy ||
                                                    mainData?.workSoftCopies?.[
                                                      index
                                                    ]?.workSoftCopy
                                                      ? "পরিবর্তন করুন"
                                                      : "নির্বাচন করুন"}
                                                  </label>
                                                </div>
                                              </div>
                                            </div>
                                            <div className="col-md-5 centeraddremovebtn">
                                              <div className="form-group">
                                                <div className="mt-4 mtop0 mb-2 pb-1 mtop2 ptop0 pt-3">
                                                  {(values.workSoftCopies
                                                    .length ===
                                                    index + 1 ||
                                                    values.workSoftCopies
                                                      .length === 1) && (
                                                    <ImPlus
                                                      className="customAddButton rounded"
                                                      title="আরও যোগ করুন"
                                                      onClick={
                                                        handleAddFieldSetWorkSoftCopy
                                                      }
                                                    />
                                                  )}
                                                  {values.workSoftCopies
                                                    .length > 1 &&
                                                    index !== 0 && (
                                                      <IoCloseSharp
                                                        className="customRemoveButton rounded"
                                                        title="বন্ধ করুন"
                                                        onClick={() =>
                                                          handleRemoveFieldSetWorkSoftCopy(
                                                            index,
                                                            item
                                                          )
                                                        }
                                                      />
                                                    )}
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        )
                                      )}
                                    </div>

                                    {(values.category_id === 2 ||
                                      values.category_id === 7) && (
                                      <div className="col-md-6">
                                        <div className="border bordercolorr rounded ptop0 paddingg0 p-3 pt-2">
                                          <div
                                            className={`${styles.add2FormGroupImagePreviewBox} form-group imgmffargin text-center text-start`}
                                          >
                                            <label className="text-start">
                                              {values.category_id === 2
                                                ? "গানের তালিকা ( গানের ক্ষেত্রে প্রযোজ্য )"
                                                : "চলচ্চিত্র সেন্সর সনদ ( চলচ্চিত্রের ক্ষেত্রে প্রযোজ্য )"}
                                            </label>
                                            <br />

                                            <div
                                              className={
                                                styles.imagePreviewAdd2
                                              }
                                            >
                                              {values.music_film ||
                                              mainData?.musicOrFilm ? (
                                                <span>
                                                  <div className="d-flex justify-content-center">
                                                    <div
                                                      className="showiconss"
                                                      onClick={() => {
                                                        if (values.music_film) {
                                                          setPreviewModal(true);
                                                          setPreviewFile(
                                                            values.music_film
                                                          );
                                                        } else {
                                                          setPreviewModalOld(
                                                            true
                                                          );
                                                          setPreviewImgUrl(
                                                            mainData?.musicOrFilm
                                                          );
                                                        }
                                                      }}
                                                    >
                                                      <IoMdEye
                                                        className={
                                                          styles.showiconss
                                                        }
                                                      />
                                                    </div>

                                                    <div
                                                      className="removesssIcon"
                                                      onClick={() =>
                                                        handleFileRemove(
                                                          "music_film",
                                                          fileInputRefMusic_filmm,
                                                          mainData?.musicOrFilm,
                                                          values.music_film
                                                        )
                                                      }
                                                    >
                                                      <ImCross
                                                        className={
                                                          styles.removesssIcon
                                                        }
                                                      />
                                                    </div>
                                                  </div>
                                                </span>
                                              ) : (
                                                <span
                                                  className={styles.placeholder}
                                                >
                                                  - - - - -
                                                </span>
                                              )}
                                            </div>

                                            <div>
                                              {errors.music_film && (
                                                <small className="my-2 text-danger">
                                                  {errors.music_film}
                                                </small>
                                              )}
                                            </div>

                                            <label className="custombtnn5">
                                              <input
                                                ref={fileInputRefMusic_filmm}
                                                type="file"
                                                className="d-none"
                                                accept=".pdf"
                                                onChange={(e) =>
                                                  handleFileChange(
                                                    "music_film",
                                                    e
                                                  )
                                                }
                                              />
                                              {values.music_film ||
                                              mainData?.musicOrFilm
                                                ? "পরিবর্তন করুন"
                                                : "নির্বাচন করুন"}
                                            </label>
                                          </div>
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="form-group mt-2">
                              <div className="row">
                                <div className="col-md-3 mtop0 mt-2">
                                  <label>ঘ. ইহা কি একটি মৌলিক কর্ম ?</label>
                                </div>
                                <div className="col-md-9 my-1 col-12">
                                  <span className="d-flex mtop0 pt-1">
                                    <div className="form-check form-check-inline">
                                      <input
                                        className="form-check-input"
                                        type="radio"
                                        name="sideRadioMoulik"
                                        id="sideRadioMoulik1"
                                        checked={values.basicWorktype === 1}
                                        onClick={() => handleWorkMoulik(1)}
                                      />
                                      <label
                                        className="form-check-label"
                                        htmlFor="sideRadioMoulik1"
                                      >
                                        হ্যাঁ
                                      </label>
                                    </div>
                                    <div className="form-check form-check-inline">
                                      <input
                                        className="form-check-input"
                                        type="radio"
                                        name="sideRadioMoulik"
                                        id="sideRadioMoulik2"
                                        checked={values.basicWorktype === 2}
                                        onClick={() => handleWorkMoulik(2)}
                                      />
                                      <label
                                        className="form-check-label"
                                        htmlFor="sideRadioMoulik2"
                                      >
                                        না
                                      </label>
                                    </div>
                                  </span>
                                </div>
                                <div className="col-md-12">
                                  {(values.basicWorktype === 2 ||
                                    [7, 17, 18].includes(
                                      values.rightTypes
                                    )) && (
                                    <div>
                                      {![18].includes(values.rightTypes) && (
                                        <>
                                          {/* Part One */}
                                          {values.rightTypes !== 6 &&
                                            values.rightTypes !== 12 && (
                                              <div className="work-moulik-box mb-2">
                                                <div className="form-check form-check-inline">
                                                  <input
                                                    className="form-check-input"
                                                    type="checkbox"
                                                    name="add2-checkbox1"
                                                    id="add2-checkbox1"
                                                    checked={checkBoxKeyType.includes(
                                                      0
                                                    )}
                                                    onChange={() =>
                                                      handleCheckboxClick(
                                                        0,
                                                        basicWorktypeId(0)
                                                      )
                                                    }
                                                  />
                                                  <label
                                                    className="form-check-label"
                                                    htmlFor="add2-checkbox1"
                                                  >
                                                    সকলের জন্য প্রকাশ্য কোন
                                                    কর্মের অনুবাদ ?
                                                  </label>
                                                </div>
                                                {(checkBoxKeyType.includes(0) ||
                                                  [7, 17].includes(
                                                    values.rightTypes
                                                  )) && (
                                                  <WorkMoulikBox
                                                    mainData={mainData}
                                                    handleChange={handleChange}
                                                    handleBlur={handleBlur}
                                                    values={values}
                                                    errors={errors}
                                                    touched={touched}
                                                    baseIndex={0}
                                                    setValues={setValues}
                                                    handleFileChange={
                                                      handleFileChange
                                                    }
                                                    setPreviewModal={
                                                      setPreviewModal
                                                    }
                                                    setPreviewFile={
                                                      setPreviewFile
                                                    }
                                                  />
                                                )}
                                              </div>
                                            )}

                                          {/* Part Two */}
                                          {values.rightTypes !== 6 &&
                                            values.rightTypes !== 12 && (
                                              <div className="work-moulik-box mb-2">
                                                <div className="form-check form-check-inline">
                                                  <input
                                                    className="form-check-input"
                                                    type="checkbox"
                                                    name="add2-checkbox2"
                                                    id="add2-checkbox2"
                                                    checked={checkBoxKeyType.includes(
                                                      1
                                                    )}
                                                    onChange={() =>
                                                      handleCheckboxClick(
                                                        1,
                                                        basicWorktypeId(1)
                                                      )
                                                    }
                                                  />
                                                  <label
                                                    className="form-check-label"
                                                    htmlFor="add2-checkbox2"
                                                  >
                                                    কপিরাইটকৃত কোন কর্মের
                                                    অনুবাদ?
                                                  </label>
                                                </div>
                                                {(checkBoxKeyType.includes(1) ||
                                                  [7, 17].includes(
                                                    values.rightTypes
                                                  )) && (
                                                  <WorkMoulikBox
                                                    mainData={mainData}
                                                    handleChange={handleChange}
                                                    handleBlur={handleBlur}
                                                    values={values}
                                                    errors={errors}
                                                    touched={touched}
                                                    baseIndex={1}
                                                    setValues={setValues}
                                                    handleFileChange={
                                                      handleFileChange
                                                    }
                                                    setPreviewModal={
                                                      setPreviewModal
                                                    }
                                                    setPreviewFile={
                                                      setPreviewFile
                                                    }
                                                  />
                                                )}
                                              </div>
                                            )}
                                        </>
                                      )}
                                      {![7, 17].includes(values.rightTypes) && (
                                        <>
                                          {/* Part Three */}
                                          {values.rightTypes !== 5 &&
                                            values.rightTypes !== 11 && (
                                              <div className="work-moulik-box mb-2">
                                                <div className="form-check form-check-inline">
                                                  <input
                                                    className="form-check-input"
                                                    type="checkbox"
                                                    name="add2-checkbox3"
                                                    id="add2-checkbox3"
                                                    checked={checkBoxKeyType.includes(
                                                      2
                                                    )}
                                                    onChange={() =>
                                                      handleCheckboxClick(
                                                        2,
                                                        basicWorktypeId(2)
                                                      )
                                                    }
                                                  />
                                                  <label
                                                    className="form-check-label"
                                                    htmlFor="add2-checkbox3"
                                                  >
                                                    সকলের জন্য প্রকাশ্য কোন
                                                    কর্মের অভিযোজন ?
                                                  </label>
                                                </div>
                                                {(checkBoxKeyType.includes(2) ||
                                                  [18].includes(
                                                    values.rightTypes
                                                  )) && (
                                                  <WorkMoulikBox
                                                    mainData={mainData}
                                                    handleChange={handleChange}
                                                    handleBlur={handleBlur}
                                                    values={values}
                                                    errors={errors}
                                                    touched={touched}
                                                    baseIndex={2}
                                                    setValues={setValues}
                                                    handleFileChange={
                                                      handleFileChange
                                                    }
                                                    setPreviewModal={
                                                      setPreviewModal
                                                    }
                                                    setPreviewFile={
                                                      setPreviewFile
                                                    }
                                                  />
                                                )}
                                              </div>
                                            )}

                                          {/* Part Four */}
                                          {values.rightTypes !== 5 &&
                                            values.rightTypes !== 11 && (
                                              <div className="work-moulik-box mb-2">
                                                <div className="form-check form-check-inline">
                                                  <input
                                                    className="form-check-input"
                                                    type="checkbox"
                                                    name="add2-checkbox4"
                                                    id="add2-checkbox4"
                                                    checked={checkBoxKeyType.includes(
                                                      3
                                                    )}
                                                    onChange={() =>
                                                      handleCheckboxClick(
                                                        3,
                                                        basicWorktypeId(3)
                                                      )
                                                    }
                                                  />
                                                  <label
                                                    className="form-check-label"
                                                    htmlFor="add2-checkbox4"
                                                  >
                                                    কপিরাইটকৃত কোন কর্মের
                                                    অভিযোজন ?
                                                  </label>
                                                </div>
                                                {(checkBoxKeyType.includes(3) ||
                                                  [18].includes(
                                                    values.rightTypes
                                                  )) && (
                                                  <WorkMoulikBox
                                                    mainData={mainData}
                                                    handleChange={handleChange}
                                                    handleBlur={handleBlur}
                                                    values={values}
                                                    errors={errors}
                                                    touched={touched}
                                                    baseIndex={3}
                                                    setValues={setValues}
                                                    handleFileChange={
                                                      handleFileChange
                                                    }
                                                    setPreviewModal={
                                                      setPreviewModal
                                                    }
                                                    setPreviewFile={
                                                      setPreviewFile
                                                    }
                                                  />
                                                )}
                                              </div>
                                            )}
                                        </>
                                      )}
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="add2-section-5 my-3">
                      <div className="row">
                        <div className="col-md-6">
                          <div className="add2-section-title">
                            <h5>
                              <b>৫. কর্মটির শিরোনামঃ</b>
                            </h5>
                          </div>
                          <div className={`${styles.add2PanelBackgrounddd}`}>
                            <div className="form-group">
                              <div className="row">
                                <div className="col-md-12">
                                  <ClientFormikFormFieldMultiple
                                    placeholder="এখানে লিখুন ......"
                                    value={values?.workTitle}
                                    name={`workTitle`}
                                    handleChange={handleChange}
                                    handleBlur={handleBlur}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          {errors?.workTitle && touched?.workTitle && (
                            <small className="error ms-2">
                              {errors.workTitle}
                            </small>
                          )}
                        </div>

                        <div className="col-md-6 mtop3">
                          <div className="add2-section-title">
                            <h5>
                              <b>৬. কর্মটির ভাষাঃ</b>
                            </h5>
                          </div>
                          <div className={`${styles.add2PanelBackgrounddd}`}>
                            <div className="form-group">
                              <div className="row">
                                <div className="col-md-12">
                                  <ClientFormikFormFieldMultiple
                                    placeholder="এখানে লিখুন ......"
                                    value={values?.workLanguage}
                                    name={`workLanguage`}
                                    handleChange={handleChange}
                                    handleBlur={handleBlur}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          {errors?.workLanguage && touched?.workLanguage && (
                            <small className="error ms-2">
                              {errors.workLanguage}
                            </small>
                          )}
                        </div>
                      </div>
                    </div>

                    <div className="add2-section-6 mt-3">
                      <div className="row">
                        <div className="col-md-12">
                          <div className="add2-section-title">
                            <h5>
                              <b>
                                ৭. লেখক / রচয়িতা / প্রণেতার নাম, ঠিকানা ও
                                জাতীয়তা এবং লেখক / রচয়িতা / প্রণেতা মৃত হইলে
                                মৃত্যুর তারিখ -
                              </b>
                            </h5>
                          </div>
                          {values?.writerdetails?.map((item, index) => (
                            <div key={index} className="row mb-3">
                              <div className="col-md-12">
                                <div
                                  className={`${styles.add2PanelBackgrounddd}`}
                                >
                                  <div className="row">
                                    <div className="col-md-10">
                                      <div className="row">
                                        <div className="col-md-3 my-1">
                                          <div className="form-group">
                                            <ClientFormikFormFieldMultiple
                                              placeholder="লেখকের নাম লিখুন"
                                              value={
                                                values?.writerdetails[index]
                                                  .writerName
                                              }
                                              name={`writerdetails[${index}].writerName`}
                                              errors={
                                                errors?.writerdetails &&
                                                errors.writerdetails?.length >
                                                  index &&
                                                errors?.writerdetails[index]
                                                  ?.writerName
                                              }
                                              touched={
                                                touched?.writerdetails &&
                                                touched.writerdetails?.length >
                                                  index &&
                                                touched?.writerdetails[index]
                                                  ?.writerName
                                              }
                                              handleChange={handleChange}
                                              handleBlur={handleBlur}
                                            />
                                          </div>
                                        </div>
                                        <div className="col-md-3 my-1">
                                          <div className="form-group">
                                            <ClientFormikFormFieldMultiple
                                              placeholder="লেখকের ঠিকানা লিখুন"
                                              value={
                                                values?.writerdetails[index]
                                                  .writerAddress
                                              }
                                              name={`writerdetails[${index}].writerAddress`}
                                              errors={
                                                errors?.writerdetails &&
                                                errors.writerdetails?.length >
                                                  index &&
                                                errors?.writerdetails[index]
                                                  ?.writerAddress
                                              }
                                              touched={
                                                touched?.writerdetails &&
                                                touched.writerdetails?.length >
                                                  index &&
                                                touched?.writerdetails[index]
                                                  ?.writerAddress
                                              }
                                              handleChange={handleChange}
                                              handleBlur={handleBlur}
                                            />
                                          </div>
                                        </div>
                                        <div className="col-md-3 my-1">
                                          <div className="form-group">
                                            <ClientFormikFormFieldMultiple
                                              placeholder="লেখকের জাতীয়তা লিখুন"
                                              value={
                                                values?.writerdetails[index]
                                                  .writerNationality
                                              }
                                              name={`writerdetails[${index}].writerNationality`}
                                              errors={
                                                errors?.writerdetails &&
                                                errors.writerdetails?.length >
                                                  index &&
                                                errors?.writerdetails[index]
                                                  ?.writerNationality
                                              }
                                              touched={
                                                touched?.writerdetails &&
                                                touched.writerdetails?.length >
                                                  index &&
                                                touched?.writerdetails[index]
                                                  ?.writerNationality
                                              }
                                              handleChange={handleChange}
                                              handleBlur={handleBlur}
                                            />
                                          </div>
                                        </div>
                                        <div className="col-md-3 my-1">
                                          <BanglaCalender
                                            placeholder="মৃত্যুর তারিখ (প্রযোজ্য ক্ষেত্রে)"
                                            value={
                                              values?.writerdetails[index]
                                                .writerDeadDate
                                            }
                                            onChange={(date) => {
                                              setValues((prevValues) => ({
                                                ...prevValues,
                                                writerdetails:
                                                  prevValues.writerdetails.map(
                                                    (detail, i) =>
                                                      i === index
                                                        ? {
                                                            ...detail,
                                                            writerDeadDate:
                                                              globalDateFormat(
                                                                date
                                                              ),
                                                          }
                                                        : detail
                                                  ),
                                              }));
                                            }}
                                          />
                                          <div>
                                            {errors.writerdetails?.[index]
                                              ?.writerDeadDate &&
                                              touched.writerdetails?.[index]
                                                ?.writerDeadDate && (
                                                <small className="my-2 text-danger">
                                                  {
                                                    errors.writerdetails?.[
                                                      index
                                                    ]?.writerDeadDate
                                                  }
                                                </small>
                                              )}
                                          </div>
                                        </div>
                                      </div>
                                    </div>

                                    <div className="col-md-2 my-1 centeraddremovebtn">
                                      <div className="form-group">
                                        <div className="">
                                          {(values.writerdetails.length ===
                                            index + 1 ||
                                            values.writerdetails.length ===
                                              1) && (
                                            <ImPlus
                                              className="customAddButton rounded"
                                              onClick={
                                                handleAddFieldSetBoxPartSeven
                                              }
                                            />
                                          )}
                                          {values.writerdetails.length > 1 &&
                                            index !== 0 && (
                                              <IoCloseSharp
                                                className="customRemoveButton rounded"
                                                onClick={() =>
                                                  handleRemoveFieldSetBoxPartSeven(
                                                    index,
                                                    item?.id
                                                  )
                                                }
                                              />
                                            )}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>

                    <div className="add2-section-7">
                      <div className="row">
                        <div className="col-md-12">
                          <div className="add2-section-title">
                            <div className="row">
                              <div className="col-md-5 mtop0 mt-2 col-12">
                                <label>
                                  {" "}
                                  ৮. কর্মটি ইতোমধ্যে প্রকাশিত হইয়াছে কি না ?
                                </label>
                              </div>
                              <div className="col-md-6 my-1 col-12">
                                <span className="d-flex mtop0 pt-1">
                                  <div className="form-check form-check-inline">
                                    <input
                                      className="form-check-input"
                                      type="radio"
                                      name="section7Radio"
                                      id="section7Radio1"
                                      checked={values.workPublishtype === 1}
                                      onClick={() => toggleSection7(1)}
                                    />
                                    <label
                                      className="form-check-label"
                                      htmlFor="section7Radio1"
                                    >
                                      হ্যাঁ
                                    </label>
                                  </div>
                                  <div className="form-check form-check-inline">
                                    <input
                                      className="form-check-input"
                                      type="radio"
                                      name="section7Radio"
                                      id="section7Radio2"
                                      checked={values.workPublishtype === 2}
                                      onClick={() => toggleSection7(2)}
                                    />
                                    <label
                                      className="form-check-label"
                                      htmlFor="section7Radio2"
                                    >
                                      না
                                    </label>
                                  </div>
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="add2-section-8 mt-2">
                      <div className="row">
                        <div className="col-md-12">
                          <div className="add2-section-title">
                            <div className="row">
                              <div className="col-md-12 col-12">
                                <h5>
                                  <b>
                                    ৯. প্রথম প্রকাশনার বছর, স্থান এবং প্রকাশকের
                                    নাম, ঠিকানা ও জাতীয়তা
                                  </b>
                                </h5>
                              </div>
                              {values.workPublishtype === 1 && (
                                <div className="col-md-12">
                                  <div
                                    className={`${styles.add2PanelBackground}`}
                                  >
                                    <div className="row">
                                      <div className="col-md-4">
                                        <div className="form-group">
                                          <label>প্রথম প্রকাশনার বছর</label>
                                          <ClientFormikDateSelector
                                            yearValue={values.year}
                                            monthValue={values.month}
                                            dayValue={values.day}
                                            yearName="year"
                                            monthName="month"
                                            dayName="day"
                                            errors={errors}
                                            touched={touched}
                                            handleChange={handleChange}
                                            handleBlur={handleBlur}
                                            additional={{
                                              style: {
                                                borderRadius: "3px",
                                                marginBottom: "7px",
                                              },
                                            }}
                                          />
                                        </div>
                                      </div>
                                      <div className="col-md-4">
                                        <div className="form-group mb-2">
                                          <label>প্রথম প্রকাশনার স্থান</label>
                                          <ClientFormikFormFieldMultiple
                                            placeholder="প্রথম প্রকাশনার স্থান"
                                            value={values?.publishplace}
                                            name={`publishplace`}
                                            errors={
                                              errors?.publishplace &&
                                              errors?.publishplace
                                            }
                                            touched={
                                              touched?.publishplace &&
                                              touched?.publishplace
                                            }
                                            handleChange={handleChange}
                                            handleBlur={handleBlur}
                                          />
                                        </div>
                                      </div>
                                      <div className="col-md-4">
                                        <div className="form-group mb-2">
                                          <label>প্রকাশকের নাম</label>
                                          <ClientFormikFormFieldMultiple
                                            placeholder="প্রকাশকের নাম"
                                            value={values?.publishname}
                                            name={`publishname`}
                                            errors={
                                              errors?.publishname &&
                                              errors?.publishname
                                            }
                                            touched={
                                              touched?.publishname &&
                                              touched?.publishname
                                            }
                                            handleChange={handleChange}
                                            handleBlur={handleBlur}
                                          />
                                        </div>
                                      </div>
                                      <div className="col-md-4">
                                        <div className="form-group mb-2">
                                          <label>প্রকাশকের ঠিকানা</label>
                                          <ClientFormikFormFieldMultiple
                                            placeholder="প্রকাশকের ঠিকানা"
                                            value={values?.publishaddress}
                                            name={`publishaddress`}
                                            errors={
                                              errors?.publishaddress &&
                                              errors?.publishaddress
                                            }
                                            touched={
                                              touched?.publishaddress &&
                                              touched?.publishaddress
                                            }
                                            handleChange={handleChange}
                                            handleBlur={handleBlur}
                                          />
                                        </div>
                                      </div>
                                      <div className="col-md-4">
                                        <div className="form-group mb-2">
                                          <label>প্রকাশকের জাতীয়তা</label>
                                          <ClientFormikFormFieldMultiple
                                            placeholder="প্রকাশকের জাতীয়তা"
                                            value={values?.publishnationality}
                                            name={`publishnationality`}
                                            errors={
                                              errors?.publishnationality &&
                                              errors?.publishnationality
                                            }
                                            touched={
                                              touched?.publishnationality &&
                                              touched?.publishnationality
                                            }
                                            handleChange={handleChange}
                                            handleBlur={handleBlur}
                                          />
                                        </div>
                                      </div>

                                      {values.category_id === 1 && (
                                        <div className="col-md-4">
                                          <div className="form-group border bordercolorr paddingg0 rounded p-1">
                                            <div
                                              className={`${styles.add2FormGroupImagePreviewBox} form-group imgmffargin text-center`}
                                            >
                                              <label>
                                                প্রচ্ছদ কর্মের হস্তান্তর দলিলের
                                                স্ক্যান কপি
                                              </label>
                                              <br />

                                              <div
                                                className={
                                                  styles.imagePreviewAdd2
                                                }
                                              >
                                                {values?.literature_doc ||
                                                mainData?.literature_doc ? (
                                                  <span>
                                                    <div className="d-flex">
                                                      <div
                                                        className="showiconss"
                                                        onClick={() => {
                                                          if (
                                                            values.literature_doc
                                                          ) {
                                                            setPreviewModal(
                                                              true
                                                            );
                                                            setPreviewFile(
                                                              values?.literature_doc
                                                            );
                                                          } else {
                                                            setPreviewModalOld(
                                                              true
                                                            );
                                                            setPreviewImgUrl(
                                                              mainData?.literature_doc
                                                            );
                                                          }
                                                        }}
                                                      >
                                                        <IoMdEye
                                                          className={
                                                            styles.showiconss
                                                          }
                                                        />
                                                      </div>

                                                      <div
                                                        className="removesssIcon"
                                                        onClick={() =>
                                                          handleFileRemove(
                                                            "literature_doc",
                                                            fileInputRefLiterature_doc,
                                                            mainData?.literature_doc,
                                                            values?.literature_doc
                                                          )
                                                        }
                                                      >
                                                        <ImCross
                                                          className={
                                                            styles.removesssIcon
                                                          }
                                                        />
                                                      </div>
                                                    </div>
                                                  </span>
                                                ) : (
                                                  <span
                                                    className={
                                                      styles.placeholder
                                                    }
                                                  >
                                                    - - - - -
                                                  </span>
                                                )}
                                              </div>

                                              <div>
                                                {errors?.literature_doc && (
                                                  <small className="my-2 text-danger">
                                                    {errors.literature_doc}
                                                  </small>
                                                )}
                                              </div>

                                              <label className="custombtnn5">
                                                <input
                                                  ref={
                                                    fileInputRefLiterature_doc
                                                  }
                                                  type="file"
                                                  accept=".png,.jpg,.jpeg,.pdf"
                                                  className="d-none"
                                                  onChange={(e) =>
                                                    handleFileChange(
                                                      "literature_doc",
                                                      e
                                                    )
                                                  }
                                                />
                                                {values?.literature_doc ||
                                                mainData?.literature_doc
                                                  ? "পরিবর্তন করুন"
                                                  : "নির্বাচন করুন"}
                                              </label>

                                              <br />
                                              <small>
                                                ( প্রণেতা ব্যতীত অন্য কেউ
                                                প্রচ্ছদকারী হলে সেক্ষেত্রে
                                                প্রযোজ্য )
                                              </small>
                                            </div>
                                          </div>
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="add2-section-9 mt-2">
                      <div className="row">
                        <div className="col-md-12">
                          <div className="add2-section-title">
                            <div className="row">
                              <div className="col-md-5 mtop0 mt-2 col-12">
                                <label>
                                  ১০ . পরবর্তীকালে / সর্বশেষ কর্মটি আরো প্রকাশিত
                                  হইয়াছে কি ?
                                </label>
                              </div>

                              <div className="col-md-6 my-1 col-12">
                                <span className="d-flex mtop0 pt-1">
                                  <div className="form-check form-check-inline">
                                    <input
                                      className="form-check-input"
                                      type="radio"
                                      name="section9Radio"
                                      id="section9Radio1"
                                      checked={values.otherPublishtype === 1}
                                      onClick={() => toggleSection9(1)}
                                    />
                                    <label
                                      className="form-check-label"
                                      htmlFor="section9Radio1"
                                    >
                                      হ্যাঁ
                                    </label>
                                  </div>
                                  <div className="form-check form-check-inline">
                                    <input
                                      className="form-check-input"
                                      type="radio"
                                      name="section9Radio"
                                      id="section9Radio2"
                                      checked={values.otherPublishtype === 2}
                                      onClick={() => toggleSection9(2)}
                                    />
                                    <label
                                      className="form-check-label"
                                      htmlFor="section7Radio2"
                                    >
                                      না
                                    </label>
                                  </div>
                                </span>
                              </div>
                            </div>

                            <div className="row">
                              {values.otherPublishtype === 1 &&
                                values.publishdetails.map((item, index) => (
                                  <div key={index} className="col-md-12 mb-3">
                                    <div
                                      className={`${styles.add2PanelBackground}`}
                                    >
                                      <div className="row">
                                        <div className="col-md-4">
                                          <div className="form-group mb-2">
                                            <label>
                                              পরবর্তীকালে / সর্বশেষ প্রকাশনার
                                              বছর
                                            </label>
                                            <ClientFormikYearSelector
                                              value={
                                                values?.publishdetails &&
                                                values.publishdetails[index] &&
                                                values.publishdetails[index]
                                                  .year
                                              }
                                              name={`publishdetails[${index}].year`}
                                              errors={
                                                errors?.publishdetails &&
                                                errors.publishdetails?.length >
                                                  index &&
                                                errors.publishdetails[index]
                                                  ?.year
                                              }
                                              touched={
                                                touched?.publishdetails &&
                                                touched.publishdetails?.length >
                                                  index &&
                                                touched.publishdetails[index]
                                                  ?.year
                                              }
                                              handleChange={handleChange}
                                              handleBlur={handleBlur}
                                            />
                                          </div>
                                        </div>
                                        <div className="col-md-4">
                                          <div className="form-group mb-2">
                                            <label>
                                              পরবর্তীকালে / সর্বশেষ প্রকাশনার
                                              স্থান
                                            </label>
                                            <ClientFormikFormFieldMultiple
                                              placeholder="পরবর্তীকালে / সর্বশেষ প্রকাশনার স্থান"
                                              value={
                                                values?.publishdetails[index]
                                                  .place
                                              }
                                              name={`publishdetails[${index}].place`}
                                              errors={
                                                errors?.publishdetails &&
                                                errors.publishdetails?.length >
                                                  index &&
                                                errors?.publishdetails[index]
                                                  ?.place
                                              }
                                              touched={
                                                touched?.publishdetails &&
                                                touched.publishdetails?.length >
                                                  index &&
                                                touched?.publishdetails[index]
                                                  ?.place
                                              }
                                              handleChange={handleChange}
                                              handleBlur={handleBlur}
                                            />
                                          </div>
                                        </div>
                                        <div className="col-md-4">
                                          <div className="form-group mb-2">
                                            <label>
                                              পরবর্তীকালে / সর্বশেষ প্রকাশকের
                                              নাম
                                            </label>
                                            <ClientFormikFormFieldMultiple
                                              placeholder="পরবর্তীকালে / সর্বশেষ প্রকাশকের নাম"
                                              value={
                                                values?.publishdetails[index]
                                                  .name
                                              }
                                              name={`publishdetails[${index}].name`}
                                              errors={
                                                errors?.publishdetails &&
                                                errors.publishdetails?.length >
                                                  index &&
                                                errors?.publishdetails[index]
                                                  ?.name
                                              }
                                              touched={
                                                touched?.publishdetails &&
                                                touched.publishdetails?.length >
                                                  index &&
                                                touched?.publishdetails[index]
                                                  ?.name
                                              }
                                              handleChange={handleChange}
                                              handleBlur={handleBlur}
                                            />
                                          </div>
                                        </div>
                                        <div className="col-md-4">
                                          <div className="form-group mb-2">
                                            <label>
                                              পরবর্তীকালে / সর্বশেষ প্রকাশকের
                                              ঠিকানা
                                            </label>
                                            <ClientFormikFormFieldMultiple
                                              placeholder="পরবর্তীকালে / সর্বশেষ প্রকাশকের ঠিকানা"
                                              value={
                                                values?.publishdetails[index]
                                                  .address
                                              }
                                              name={`publishdetails[${index}].address`}
                                              errors={
                                                errors?.publishdetails &&
                                                errors.publishdetails?.length >
                                                  index &&
                                                errors?.publishdetails[index]
                                                  ?.address
                                              }
                                              touched={
                                                touched?.publishdetails &&
                                                touched.publishdetails?.length >
                                                  index &&
                                                touched?.publishdetails[index]
                                                  ?.address
                                              }
                                              handleChange={handleChange}
                                              handleBlur={handleBlur}
                                            />
                                          </div>
                                        </div>

                                        <div className="col-md-4">
                                          <div className="form-group mb-2">
                                            <label>
                                              পরবর্তীকালে / সর্বশেষ প্রকাশকের
                                              জাতীয়তা
                                            </label>
                                            <ClientFormikFormFieldMultiple
                                              placeholder="পরবর্তীকালে / সর্বশেষ প্রকাশকের ঠিকানা"
                                              value={
                                                values?.publishdetails[index]
                                                  .nationality
                                              }
                                              name={`publishdetails[${index}].nationality`}
                                              errors={
                                                errors?.publishdetails &&
                                                errors.publishdetails?.length >
                                                  index &&
                                                errors?.publishdetails[index]
                                                  ?.nationality
                                              }
                                              touched={
                                                touched?.publishdetails &&
                                                touched.publishdetails?.length >
                                                  index &&
                                                touched?.publishdetails[index]
                                                  ?.nationality
                                              }
                                              handleChange={handleChange}
                                              handleBlur={handleBlur}
                                            />
                                          </div>
                                        </div>
                                        {values.category_id === 1 && (
                                          <div className="col-md-2">
                                            <div className="form-group border bordercolorr paddingg0 rounded p-1">
                                              <div
                                                className={`${styles.add2FormGroupImagePreviewBox} form-group imgmffargin text-center`}
                                              >
                                                <label>
                                                  প্রচ্ছদ কর্মের হস্তান্তর
                                                  দলিলের স্ক্যান কপি
                                                </label>
                                                <br />
                                                <div
                                                  className={
                                                    styles.imagePreviewAdd2
                                                  }
                                                >
                                                  {values.publishdetails?.[
                                                    index
                                                  ]?.literature_more_doc ||
                                                  mainData?.publishDetails?.[
                                                    index
                                                  ]?.literatureDoc ? (
                                                    <span>
                                                      <div className="d-flex">
                                                        <div
                                                          className="showiconss cursor-pointer"
                                                          onClick={() => {
                                                            if (
                                                              values
                                                                .publishdetails?.[
                                                                index
                                                              ]
                                                                ?.literature_more_doc
                                                            ) {
                                                              setPreviewModal(
                                                                true
                                                              );
                                                              setPreviewFile(
                                                                values
                                                                  .publishdetails?.[
                                                                  index
                                                                ]
                                                                  ?.literature_more_doc
                                                              );
                                                            } else {
                                                              setPreviewModalOld(
                                                                true
                                                              );
                                                              setPreviewImgUrl(
                                                                mainData
                                                                  ?.publishDetails?.[
                                                                  index
                                                                ]?.literatureDoc
                                                              );
                                                            }
                                                          }}
                                                        >
                                                          <IoMdEye
                                                            className={
                                                              styles.showiconss
                                                            }
                                                          />
                                                        </div>
                                                        <div
                                                          className="removesssIcon cursor-pointer"
                                                          onClick={() =>
                                                            handleDynamicFileRemove(
                                                              `publishdetails[${index}].literature_more_doc`,
                                                              index,
                                                              mainData
                                                                ?.publishDetails?.[
                                                                index
                                                              ]?.literatureDoc,
                                                              values
                                                                .publishdetails?.[
                                                                index
                                                              ]
                                                                ?.literature_more_doc
                                                            )
                                                          }
                                                        >
                                                          <ImCross
                                                            className={
                                                              styles.removesssIcon
                                                            }
                                                          />
                                                        </div>
                                                      </div>
                                                    </span>
                                                  ) : (
                                                    <span
                                                      className={
                                                        styles.placeholder
                                                      }
                                                    >
                                                      - - - - -
                                                    </span>
                                                  )}
                                                </div>
                                                <div>
                                                  {errors.publishdetails?.[
                                                    index
                                                  ]?.literature_more_doc && (
                                                    <small className="my-2 text-danger">
                                                      {
                                                        errors.publishdetails?.[
                                                          index
                                                        ]?.literature_more_doc
                                                      }
                                                    </small>
                                                  )}
                                                </div>
                                                <label className="custombtnn5">
                                                  <input
                                                    ref={(el) =>
                                                      (fileInputRefs.current[
                                                        index
                                                      ] = el)
                                                    } // Use the same method to assign refs
                                                    type="file"
                                                    accept=".png,.jpg,.jpeg,.pdf"
                                                    className="d-none"
                                                    onChange={(e) =>
                                                      handleFileChange(
                                                        `publishdetails[${index}].literature_more_doc`,
                                                        e
                                                      )
                                                    }
                                                  />
                                                  {values.publishdetails?.[
                                                    index
                                                  ]?.literature_more_doc ||
                                                  mainData?.publishDetails?.[
                                                    index
                                                  ]?.literatureDoc
                                                    ? "পরিবর্তন করুন"
                                                    : "নির্বাচন করুন"}
                                                </label>

                                                <br />
                                                <small>
                                                  ( প্রণেতা ব্যতীত অন্য কেউ
                                                  প্রচ্ছদকারী হলে সেক্ষেত্রে
                                                  প্রযোজ্য )
                                                </small>
                                              </div>
                                            </div>
                                          </div>
                                        )}
                                        <div className="col-md-2 centeraddremovebtn">
                                          <div className="form-group">
                                            <div className="mt-4 mtop3">
                                              {(values.publishdetails.length ===
                                                index + 1 ||
                                                values.publishdetails.length ===
                                                  1) && (
                                                <ImPlus
                                                  className="customAddButton  rounded"
                                                  onClick={
                                                    handleAddFieldSetBoxPartNine
                                                  }
                                                />
                                              )}
                                              {values.publishdetails.length >
                                                1 &&
                                                index !== 0 && (
                                                  <IoCloseSharp
                                                    className="customRemoveButton rounded"
                                                    onClick={() =>
                                                      handleRemoveFieldSetBoxPartNine(
                                                        index,
                                                        item?.id
                                                      )
                                                    }
                                                  />
                                                )}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                ))}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="add2-section-10 mt-2">
                      <div className="row">
                        <div className="col-md-12">
                          <div className="add2-section-title">
                            <div className="row">
                              <div className="col-md-5 mtop0 mt-2 col-12">
                                <label>
                                  {" "}
                                  ১১. কর্মটির কপিরাইটভুক্ত অন্য কোন
                                  স্বত্বাধিকারী আছে কি ?
                                </label>
                              </div>

                              <div className="col-md-6 py-1 col-12">
                                <span className="d-flex mtop0 pt-1">
                                  <div className="form-check form-check-inline">
                                    <input
                                      className="form-check-input"
                                      type="radio"
                                      name="section10Radio"
                                      id="section10Radio1"
                                      checked={values.ownerType === 1}
                                      onClick={() => toggleSection10(1)}
                                    />
                                    <label
                                      className="form-check-label"
                                      htmlFor="section10Radio1"
                                    >
                                      হ্যাঁ
                                    </label>
                                  </div>
                                  <div className="form-check form-check-inline">
                                    <input
                                      className="form-check-input"
                                      type="radio"
                                      name="section10Radio"
                                      id="section10Radio2"
                                      checked={values.ownerType === 2}
                                      onClick={() => toggleSection10(2)}
                                    />
                                    <label
                                      className="form-check-label"
                                      htmlFor="section10Radio2"
                                    >
                                      না
                                    </label>
                                  </div>
                                </span>
                              </div>
                            </div>

                            <div className="row">
                              {values.ownerType === 1 &&
                                values.ownertypes.map((item, index) => (
                                  <div key={index} className="col-md-12 mb-3">
                                    <div
                                      className={`${styles.add2PanelBackground}`}
                                    >
                                      <div className="row">
                                        <div className="col-md-10">
                                          <div className="row">
                                            <div className="col-md-3">
                                              <div className="form-group mb-2">
                                                <label>
                                                  স্বত্বাধিকারীর নাম
                                                </label>
                                                <ClientFormikFormFieldMultiple
                                                  placeholder="স্বত্বাধিকারীর নাম"
                                                  value={
                                                    values?.ownertypes[index]
                                                      .ownerName
                                                  }
                                                  name={`ownertypes[${index}].ownerName`}
                                                  errors={
                                                    errors?.ownertypes &&
                                                    errors.ownertypes?.length >
                                                      index &&
                                                    errors?.ownertypes[index]
                                                      ?.ownerName
                                                  }
                                                  touched={
                                                    touched?.ownertypes &&
                                                    touched.ownertypes?.length >
                                                      index &&
                                                    touched?.ownertypes[index]
                                                      ?.ownerName
                                                  }
                                                  handleChange={handleChange}
                                                  handleBlur={handleBlur}
                                                />
                                              </div>
                                            </div>
                                            <div className="col-md-3">
                                              <div className="form-group mb-2">
                                                <label>
                                                  স্বত্বাধিকারীর ঠিকানা
                                                </label>
                                                <ClientFormikFormFieldMultiple
                                                  placeholder="স্বত্বাধিকারীর ঠিকানা"
                                                  value={
                                                    values?.ownertypes[index]
                                                      .ownerAddress
                                                  }
                                                  name={`ownertypes[${index}].ownerAddress`}
                                                  errors={
                                                    errors?.ownertypes &&
                                                    errors.ownertypes?.length >
                                                      index &&
                                                    errors?.ownertypes[index]
                                                      ?.ownerAddress
                                                  }
                                                  touched={
                                                    touched?.ownertypes &&
                                                    touched.ownertypes?.length >
                                                      index &&
                                                    touched?.ownertypes[index]
                                                      ?.ownerAddress
                                                  }
                                                  handleChange={handleChange}
                                                  handleBlur={handleBlur}
                                                />
                                              </div>
                                            </div>
                                            <div className="col-md-3">
                                              <div className="form-group mb-2">
                                                <label>
                                                  স্বত্বাধিকারীর জাতীয়তা
                                                </label>
                                                <ClientFormikFormFieldMultiple
                                                  placeholder="স্বত্বাধিকারীর জাতীয়তা"
                                                  value={
                                                    values?.ownertypes[index]
                                                      .ownerNationality
                                                  }
                                                  name={`ownertypes[${index}].ownerNationality`}
                                                  errors={
                                                    errors?.ownertypes &&
                                                    errors.ownertypes?.length >
                                                      index &&
                                                    errors?.ownertypes[index]
                                                      ?.ownerNationality
                                                  }
                                                  touched={
                                                    touched?.ownertypes &&
                                                    touched.ownertypes?.length >
                                                      index &&
                                                    touched?.ownertypes[index]
                                                      ?.ownerNationality
                                                  }
                                                  handleChange={handleChange}
                                                  handleBlur={handleBlur}
                                                />
                                              </div>
                                            </div>
                                            <div className="col-md-3">
                                              <div className="form-group mb-2">
                                                <label>স্বত্বের অংশ</label>
                                                <ClientFormikFormFieldMultiple
                                                  placeholder="স্বত্বের অংশ"
                                                  value={
                                                    values?.ownertypes[index]
                                                      .partTitle
                                                  }
                                                  name={`ownertypes[${index}].partTitle`}
                                                  errors={
                                                    errors?.ownertypes &&
                                                    errors.ownertypes?.length >
                                                      index &&
                                                    errors?.ownertypes[index]
                                                      ?.partTitle
                                                  }
                                                  touched={
                                                    touched?.ownertypes &&
                                                    touched.ownertypes?.length >
                                                      index &&
                                                    touched?.ownertypes[index]
                                                      ?.partTitle
                                                  }
                                                  handleChange={handleChange}
                                                  handleBlur={handleBlur}
                                                />
                                              </div>
                                            </div>
                                          </div>
                                        </div>

                                        <div className="col-md-2 centeraddremovebtn">
                                          <div className="form-group">
                                            <div className="mt-4 mtop3">
                                              {(values.ownertypes.length ===
                                                index + 1 ||
                                                values.ownertypes.length ===
                                                  1) && (
                                                <ImPlus
                                                  className="customAddButton  rounded"
                                                  onClick={
                                                    handleAddFieldSetBoxPartTen
                                                  }
                                                />
                                              )}
                                              {values.ownertypes.length > 1 &&
                                                index !== 0 && (
                                                  <IoCloseSharp
                                                    className="customRemoveButton rounded"
                                                    onClick={() =>
                                                      handleRemoveFieldSetBoxPartTen(
                                                        index,
                                                        item?.id
                                                      )
                                                    }
                                                  />
                                                )}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                ))}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="add2-section-11 mt-2">
                      <div className="row">
                        <div className="col-md-12">
                          <div className="add2-section-title">
                            <div className="row">
                              <div className="col-md-5 mtop0 mt-2 col-12">
                                <label>
                                  {" "}
                                  ১২. কপিরাইটভুক্ত বিভিন্ন স্বত্ব নিয়োগ বা
                                  লাইসেন্স প্রদানের ক্ষমতাপ্রাপ্ত ব্যক্তি আছে
                                  কি?
                                </label>
                              </div>

                              <div className="col-md-6 py-1 col-12">
                                <span className="d-flex mtop0 pt-1">
                                  <div className="form-check form-check-inline">
                                    <input
                                      className="form-check-input"
                                      type="radio"
                                      name="section11Radio"
                                      id="section11Radio1"
                                      checked={values.powerType === 1}
                                      onClick={() => toggleSection11(1)}
                                    />
                                    <label
                                      className="form-check-label"
                                      htmlFor="section11Radio1"
                                    >
                                      হ্যাঁ
                                    </label>
                                  </div>
                                  <div className="form-check form-check-inline">
                                    <input
                                      className="form-check-input"
                                      type="radio"
                                      name="section11Radio"
                                      id="section11Radio2"
                                      checked={values.powerType === 2}
                                      onClick={() => toggleSection11(2)}
                                    />
                                    <label
                                      className="form-check-label"
                                      htmlFor="section11Radio2"
                                    >
                                      না
                                    </label>
                                  </div>
                                </span>
                              </div>
                            </div>

                            <div className="row">
                              {values.powerType === 1 &&
                                values?.powertypes.map((item, index) => (
                                  <div key={index} className="col-md-12 mb-3">
                                    <div
                                      className={`${styles.add2PanelBackground}`}
                                    >
                                      <div className="row">
                                        <div className="col-md-10">
                                          <div className="row">
                                            <div className="col-md-3">
                                              <div className="form-group mb-2">
                                                <label>ব্যক্তির নাম</label>
                                                <ClientFormikFormFieldMultiple
                                                  placeholder="ব্যক্তির নাম"
                                                  value={
                                                    values?.powertypes[index]
                                                      .name
                                                  }
                                                  name={`powertypes[${index}].name`}
                                                  errors={
                                                    errors?.powertypes &&
                                                    errors.powertypes?.length >
                                                      index &&
                                                    errors?.powertypes[index]
                                                      ?.name
                                                  }
                                                  touched={
                                                    touched?.powertypes &&
                                                    touched.powertypes?.length >
                                                      index &&
                                                    touched?.powertypes[index]
                                                      ?.name
                                                  }
                                                  handleChange={handleChange}
                                                  handleBlur={handleBlur}
                                                />
                                              </div>
                                            </div>
                                            <div className="col-md-3">
                                              <div className="form-group mb-2">
                                                <label>ব্যক্তির ঠিকানা</label>
                                                <ClientFormikFormFieldMultiple
                                                  placeholder="ব্যক্তির ঠিকানা"
                                                  value={
                                                    values?.powertypes[index]
                                                      .address
                                                  }
                                                  name={`powertypes[${index}].address`}
                                                  errors={
                                                    errors?.powertypes &&
                                                    errors.powertypes?.length >
                                                      index &&
                                                    errors?.powertypes[index]
                                                      ?.address
                                                  }
                                                  touched={
                                                    touched?.powertypes &&
                                                    touched.powertypes?.length >
                                                      index &&
                                                    touched?.powertypes[index]
                                                      ?.address
                                                  }
                                                  handleChange={handleChange}
                                                  handleBlur={handleBlur}
                                                />
                                              </div>
                                            </div>
                                            <div className="col-md-3">
                                              <div className="form-group mb-2">
                                                <label>ব্যক্তির জাতীয়তা</label>
                                                <ClientFormikFormFieldMultiple
                                                  placeholder="ব্যক্তির জাতীয়তা"
                                                  value={
                                                    values?.powertypes[index]
                                                      .nationality
                                                  }
                                                  name={`powertypes[${index}].nationality`}
                                                  errors={
                                                    errors?.powertypes &&
                                                    errors.powertypes?.length >
                                                      index &&
                                                    errors?.powertypes[index]
                                                      ?.nationality
                                                  }
                                                  touched={
                                                    touched?.powertypes &&
                                                    touched.powertypes?.length >
                                                      index &&
                                                    touched?.powertypes[index]
                                                      ?.nationality
                                                  }
                                                  handleChange={handleChange}
                                                  handleBlur={handleBlur}
                                                />
                                              </div>
                                            </div>

                                            <div className="col-md-3">
                                              <div className="form-group border bordercolorr paddingg0 rounded p-1">
                                                <div
                                                  className={`${styles.add2FormGroupImagePreviewBox} form-group imgmffargin text-center`}
                                                >
                                                  <label>
                                                    লাইসেন্স প্রদানের ক্ষমতা
                                                    পত্র
                                                    <br />
                                                    (প্রযোজ্য ক্ষেত্রে)
                                                  </label>
                                                  <br />

                                                  <div
                                                    className={
                                                      styles.imagePreviewAdd2
                                                    }
                                                  >
                                                    {values.powertypes?.[index]
                                                      ?.license_pay ||
                                                    mainData?.powertypes?.[
                                                      index
                                                    ]?.license_pay ? (
                                                      <span>
                                                        <div className="d-flex">
                                                          <div
                                                            className="showiconss cursor-pointer"
                                                            onClick={() => {
                                                              if (
                                                                values
                                                                  .powertypes?.[
                                                                  index
                                                                ]?.license_pay
                                                              ) {
                                                                setPreviewModal(
                                                                  true
                                                                );
                                                                setPreviewFile(
                                                                  values
                                                                    .powertypes?.[
                                                                    index
                                                                  ]?.license_pay
                                                                );
                                                              } else {
                                                                setPreviewModalOld(
                                                                  true
                                                                );
                                                                setPreviewImgUrl(
                                                                  mainData
                                                                    ?.powertypes?.[
                                                                    index
                                                                  ]?.license_pay
                                                                );
                                                              }
                                                            }}
                                                          >
                                                            <IoMdEye
                                                              className={
                                                                styles.showiconss
                                                              }
                                                            />
                                                          </div>
                                                          <div
                                                            className="removesssIcon cursor-pointer"
                                                            onClick={() =>
                                                              handleDynamicFileRemove(
                                                                `powertypes[${index}].license_pay`,
                                                                index,
                                                                mainData
                                                                  ?.powertypes?.[
                                                                  index
                                                                ]?.license_pay,
                                                                values
                                                                  .powertypes?.[
                                                                  index
                                                                ]?.license_pay
                                                              )
                                                            }
                                                          >
                                                            <ImCross
                                                              className={
                                                                styles.removesssIcon
                                                              }
                                                            />
                                                          </div>
                                                        </div>
                                                      </span>
                                                    ) : (
                                                      <span
                                                        className={
                                                          styles.placeholder
                                                        }
                                                      >
                                                        - - - - -
                                                      </span>
                                                    )}
                                                  </div>

                                                  <div>
                                                    {errors.powertypes?.[index]
                                                      ?.license_pay && (
                                                      <small className="my-2 text-danger">
                                                        {
                                                          errors.powertypes?.[
                                                            index
                                                          ]?.license_pay
                                                        }
                                                      </small>
                                                    )}
                                                  </div>

                                                  <label className="custombtnn5">
                                                    <input
                                                      type="file"
                                                      ref={(el) =>
                                                        (fileInputRefs.current[
                                                          index
                                                        ] = el)
                                                      }
                                                      className="d-none"
                                                      accept=".pdf"
                                                      onChange={(e) =>
                                                        handleFileChange(
                                                          `powertypes[${index}].license_pay`,
                                                          e
                                                        )
                                                      }
                                                    />
                                                    {values.powertypes?.[index]
                                                      ?.license_pay ||
                                                    mainData?.powertypes?.[
                                                      index
                                                    ]?.license_pay
                                                      ? "পরিবর্তন করুন"
                                                      : "নির্বাচন করুন"}
                                                  </label>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>

                                        <div className="col-md-2 centeraddremovebtn">
                                          <div className="form-group">
                                            <div className="mt-4 mtop3">
                                              {(values.powertypes.length ===
                                                index + 1 ||
                                                values.powertypes.length ===
                                                  1) && (
                                                <ImPlus
                                                  className="customAddButton  rounded"
                                                  onClick={
                                                    handleAddFieldSetBoxPartEleven
                                                  }
                                                />
                                              )}
                                              {values.powertypes.length > 1 &&
                                                index !== 0 && (
                                                  <IoCloseSharp
                                                    className="customRemoveButton rounded"
                                                    onClick={() =>
                                                      handleRemoveFieldSetBoxPartEleven(
                                                        index,
                                                        item?.id
                                                      )
                                                    }
                                                  />
                                                )}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                ))}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="add2-section-12 mt-2">
                      <div className="row">
                        <div className="col-md-12">
                          <div className="add2-section-title">
                            <div className="row">
                              <div className="col-md-5 col-12">
                                <h5>
                                  <b>
                                    ১৩. শিল্পকর্ম ( শুধু শিল্পকর্মের জন্য
                                    প্রযোজ্য )
                                  </b>
                                </h5>
                              </div>

                              {values.category_id === 3 && (
                                <div className="col-md-12">
                                  <div
                                    className={`${styles.add2PanelBackground}`}
                                  >
                                    <div className="row">
                                      <div className="col-md-4">
                                        <div className="form-group mb-2">
                                          <label>
                                            ক. মূল শিল্পকর্মটি কোথায় আছে ?
                                          </label>
                                          <ClientFormikFormFieldMultiple
                                            value={
                                              values?.industryactions[0].place
                                            }
                                            name={`industryactions[0].place`}
                                            errors={
                                              errors?.industryactions &&
                                              errors.industryactions?.length >
                                                0 &&
                                              errors?.industryactions[0]?.place
                                            }
                                            touched={
                                              touched?.industryactions &&
                                              touched.industryactions?.length >
                                                0 &&
                                              touched?.industryactions[0]?.place
                                            }
                                            handleChange={handleChange}
                                            handleBlur={handleBlur}
                                          />
                                        </div>
                                      </div>
                                      <div className="col-md-4">
                                        <div className="form-group mb-2">
                                          <label>
                                            খ. যাদের আয়ত্বে আছে তাদের নাম
                                          </label>
                                          <ClientFormikFormFieldMultiple
                                            value={
                                              values?.industryactions[0].name
                                            }
                                            name={`industryactions[0].name`}
                                            errors={
                                              errors?.industryactions &&
                                              errors.industryactions?.length >
                                                0 &&
                                              errors?.industryactions[0]?.name
                                            }
                                            touched={
                                              touched?.industryactions &&
                                              touched.industryactions?.length >
                                                0 &&
                                              touched?.industryactions[0]?.name
                                            }
                                            handleChange={handleChange}
                                            handleBlur={handleBlur}
                                          />
                                        </div>
                                      </div>
                                      <div className="col-md-4">
                                        <div className="form-group mb-2">
                                          <label>
                                            গ. যাদের আয়ত্বে আছে তাদের ঠিকানা
                                          </label>
                                          <ClientFormikFormFieldMultiple
                                            value={
                                              values?.industryactions[0].address
                                            }
                                            name={`industryactions[0].address`}
                                            errors={
                                              errors?.industryactions &&
                                              errors.industryactions?.length >
                                                0 &&
                                              errors?.industryactions[0]
                                                ?.address
                                            }
                                            touched={
                                              touched?.industryactions &&
                                              touched.industryactions?.length >
                                                0 &&
                                              touched?.industryactions[0]
                                                ?.address
                                            }
                                            handleChange={handleChange}
                                            handleBlur={handleBlur}
                                          />
                                        </div>
                                      </div>
                                      <div className="col-md-4">
                                        <div className="form-group mb-2">
                                          <label>
                                            ঘ. যাদের আয়ত্বে আছে তাদের জাতীয়তা
                                          </label>
                                          <ClientFormikFormFieldMultiple
                                            value={
                                              values?.industryactions[0]
                                                .nationality
                                            }
                                            name={`industryactions[0].nationality`}
                                            errors={
                                              errors?.industryactions &&
                                              errors.industryactions?.length >
                                                0 &&
                                              errors?.industryactions[0]
                                                ?.nationality
                                            }
                                            touched={
                                              touched?.industryactions &&
                                              touched.industryactions?.length >
                                                0 &&
                                              touched?.industryactions[0]
                                                ?.nationality
                                            }
                                            handleChange={handleChange}
                                            handleBlur={handleBlur}
                                          />
                                        </div>
                                      </div>
                                      <div className="col-md-4">
                                        <div className="form-group mb-2">
                                          <label>
                                            ঙ. কর্ম সমাপ্তির বছর ( স্থাপত্য
                                            শিল্পের ক্ষেত্রে উল্লেখ করতে হবে )
                                          </label>
                                          <ClientFormikYearSelector
                                            value={
                                              values?.industryactions &&
                                              values.industryactions[0] &&
                                              values.industryactions[0].endYear
                                            }
                                            name={`industryactions[0].endYear`}
                                            errors={
                                              errors?.industryactions &&
                                              errors.industryactions?.length >
                                                0 &&
                                              errors.industryactions[0]?.endYear
                                            }
                                            touched={
                                              touched?.industryactions &&
                                              touched.industryactions?.length >
                                                0 &&
                                              touched.industryactions[0]
                                                ?.endYear
                                            }
                                            handleChange={handleChange}
                                            handleBlur={handleBlur}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="add2-section-13 mt-3">
                      <div className="row">
                        <div className="col-md-12">
                          <div className="add2-section-title">
                            <div className="row">
                              <div className="col-md-5 col-12">
                                <h5>
                                  <b>১৪. মন্তব্য লিখুন ( যদি থাকে )</b>
                                </h5>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className={`${styles.add2PanelBackground} pb-2`}>
                            <div className="form-group mb-2">
                              <textarea
                                className="form-control"
                                rows="4"
                                name="comment"
                                value={values.comment}
                                onChange={handleChange}
                                onBlur={handleBlur}
                              ></textarea>
                              {touched.comment && errors.comment && (
                                <div className="text-danger">
                                  {errors.comment}
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="add2-section-14 mt-3">
                      <div className="row">
                        <div className="col-md-12">
                          <div className="add2-section-title">
                            <div className="row">
                              <div className="col-md-5 mtop0 mt-2 col-12">
                                <label> ** সার্টিফিকেট কোন ভাষায় চান ?</label>
                              </div>

                              <div className="col-md-6 py-1 col-12">
                                <span className="d-flex mtop0 pt-1">
                                  <div className="form-check form-check-inline">
                                    <input
                                      className="form-check-input"
                                      type="radio"
                                      name="section14Radio"
                                      id="section14Radio1"
                                      checked={values.certificate === 1}
                                      onClick={() => toggleSection14(1)}
                                    />
                                    <label
                                      className="form-check-label"
                                      htmlFor="section14Radio1"
                                    >
                                      বাংলা
                                    </label>
                                  </div>
                                  <div className="form-check form-check-inline">
                                    <input
                                      className="form-check-input"
                                      type="radio"
                                      name="section14Radio"
                                      id="section14Radio2"
                                      checked={values.certificate === 2}
                                      onClick={() => toggleSection14(2)}
                                    />
                                    <label
                                      className="form-check-label"
                                      htmlFor="section14Radio2"
                                    >
                                      ইংরেজি
                                    </label>
                                  </div>
                                </span>
                              </div>
                            </div>

                            {values.certificate === 1 && (
                              <div className="row">
                                <div className="col-md-12">
                                  <div
                                    className={`${styles.add2PanelBackground}`}
                                  >
                                    <div className="row">
                                      <div className="col-md-6 mb-2">
                                        <label>কর্মটির শিরোনাম</label>
                                        <ClientFormikFormFieldMultiple
                                          placeholder="বাংলায় লিখুন"
                                          value={values?.workTitle}
                                          name={`workTitle`}
                                          errors={
                                            errors?.workTitle &&
                                            errors?.workTitle
                                          }
                                          touched={
                                            touched?.workTitle &&
                                            touched?.workTitle
                                          }
                                          handleChange={handleChange}
                                          handleBlur={handleBlur}
                                        />
                                      </div>
                                      <div className="col-md-6">
                                        <label>
                                          {values?.workPublishtype === 1
                                            ? "কর্ম প্রকাশনার বছর"
                                            : "কর্ম সম্পন্নের বছর"}
                                        </label>
                                        {values?.workPublishtype === 1 ? (
                                          <div className="fw-bold px-2 py-1 bg-white border rounded">
                                            {mergeDateValues({
                                              year: values.year,
                                              month: values.month,
                                              day: values.day,
                                            })}
                                          </div>
                                        ) : (
                                          <ClientFormikDateSelector
                                            yearValue={values.pub_year}
                                            monthValue={values.pub_month}
                                            dayValue={values.pub_day}
                                            yearName="pub_year"
                                            monthName="pub_month"
                                            dayName="pub_day"
                                            errors={errors}
                                            touched={touched}
                                            handleChange={handleChange}
                                            handleBlur={handleBlur}
                                            additional={{
                                              style: {
                                                borderRadius: "3px",
                                                marginBottom: "7px",
                                              },
                                            }}
                                          />
                                        )}
                                      </div>
                                      <div className="col-md-12 ">
                                        {values?.writerdetails?.map(
                                          (item, index) => (
                                            <div className="row pt-2">
                                              <div className="col-md-6">
                                                <div className="form-group pb-2">
                                                  <label
                                                    htmlFor={`writerdetails[${index}].writerName`}
                                                  >
                                                    লেখকের নাম ({index + 1})
                                                  </label>
                                                  <ClientFormikFormFieldMultiple
                                                    placeholder="বাংলায় লিখুন"
                                                    value={
                                                      values?.writerdetails[
                                                        index
                                                      ].writerName
                                                    }
                                                    name={`writerdetails[${index}].writerName`}
                                                    errors={
                                                      errors?.writerdetails &&
                                                      errors.writerdetails
                                                        ?.length > index &&
                                                      errors?.writerdetails[
                                                        index
                                                      ]?.writerName
                                                    }
                                                    touched={
                                                      touched?.writerdetails &&
                                                      touched.writerdetails
                                                        ?.length > index &&
                                                      touched?.writerdetails[
                                                        index
                                                      ]?.writerName
                                                    }
                                                    handleChange={handleChange}
                                                    handleBlur={handleBlur}
                                                  />
                                                </div>
                                              </div>
                                              <div className="col-md-6">
                                                <div className="form-group pb-2">
                                                  <label
                                                    htmlFor={`writerdetails[${index}].writerAddress`}
                                                  >
                                                    লেখকের ঠিকানা ({index + 1})
                                                  </label>
                                                  <ClientFormikFormFieldMultiple
                                                    placeholder="বাংলায় লিখুন"
                                                    value={
                                                      values?.writerdetails[
                                                        index
                                                      ].writerAddress
                                                    }
                                                    name={`writerdetails[${index}].writerAddress`}
                                                    errors={
                                                      errors?.writerdetails &&
                                                      errors.writerdetails
                                                        ?.length > index &&
                                                      errors?.writerdetails[
                                                        index
                                                      ]?.writerAddress
                                                    }
                                                    touched={
                                                      touched?.writerdetails &&
                                                      touched.writerdetails
                                                        ?.length > index &&
                                                      touched?.writerdetails[
                                                        index
                                                      ]?.writerAddress
                                                    }
                                                    handleChange={handleChange}
                                                    handleBlur={handleBlur}
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                          )
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}
                            {values.certificate === 2 && (
                              <div className="row">
                                <div className="col-md-12">
                                  <div
                                    className={`${styles.add2PanelBackground}`}
                                  >
                                    <div className="row">
                                      <div className="col-md-12">
                                        <div className="row">
                                          <div className="col-md-6">
                                            <div className="form-group mb-2">
                                              <label>Title of the work</label>
                                              <ClientFormikFormFieldMultiple
                                                placeholder="Write only in English"
                                                value={values?.work_title}
                                                name={`work_title`}
                                                errors={
                                                  errors?.work_title &&
                                                  errors?.work_title
                                                }
                                                touched={
                                                  touched?.work_title &&
                                                  touched?.work_title
                                                }
                                                handleChange={handleChange}
                                                handleBlur={handleBlur}
                                              />
                                            </div>
                                          </div>

                                          <div className="col-md-6">
                                            <div className="form-group mb-2">
                                              <label>
                                                {values?.workPublishtype === 1
                                                  ? "Year of Publication"
                                                  : "Year of Completion"}
                                              </label>

                                              {values?.workPublishtype === 1 ? (
                                                <div className="fw-bold  px-2 py-1 bg-white border rounded">
                                                  {mergeDateValues({
                                                    year: values.year,
                                                    month: values.month,
                                                    day: values.day,
                                                  })}
                                                </div>
                                              ) : (
                                                <ClientFormikDateSelector
                                                  lang={true}
                                                  yearValue={values.pub_year}
                                                  monthValue={values.pub_month}
                                                  dayValue={values.pub_day}
                                                  yearName="pub_year"
                                                  monthName="pub_month"
                                                  dayName="pub_day"
                                                  errors={errors}
                                                  touched={touched}
                                                  handleChange={handleChange}
                                                  handleBlur={handleBlur}
                                                  additional={{
                                                    style: {
                                                      borderRadius: "3px",
                                                      marginBottom: "7px",
                                                    },
                                                  }}
                                                />
                                              )}
                                            </div>
                                          </div>

                                          {values.certificatelanguages.map(
                                            (item, index) => (
                                              <div
                                                key={index}
                                                className="col-md-12"
                                              >
                                                <div className="row">
                                                  <div className="col-md-10">
                                                    <div className="row">
                                                      <div className="col-md-4">
                                                        <div className="form-group">
                                                          <label>
                                                            Author / Creator
                                                          </label>
                                                          <ClientFormikFormFieldMultiple
                                                            placeholder="Write Name in English"
                                                            value={
                                                              values
                                                                ?.certificatelanguages[
                                                                index
                                                              ].author_creator
                                                            }
                                                            name={`certificatelanguages[${index}].author_creator`}
                                                            errors={
                                                              errors?.certificatelanguages &&
                                                              errors
                                                                .certificatelanguages
                                                                ?.length >
                                                                index &&
                                                              errors
                                                                ?.certificatelanguages[
                                                                index
                                                              ]?.author_creator
                                                            }
                                                            touched={
                                                              touched?.certificatelanguages &&
                                                              touched
                                                                .certificatelanguages
                                                                ?.length > 0 &&
                                                              touched
                                                                ?.certificatelanguages[
                                                                index
                                                              ]?.author_creator
                                                            }
                                                            handleChange={
                                                              handleChange
                                                            }
                                                            handleBlur={
                                                              handleBlur
                                                            }
                                                          />
                                                        </div>
                                                      </div>
                                                      <div className="col-md-4">
                                                        <div className="form-group mb-2">
                                                          <label>
                                                            Owner of the rights
                                                          </label>
                                                          <ClientFormikFormFieldMultiple
                                                            placeholder="Write Name in English"
                                                            value={
                                                              values
                                                                ?.certificatelanguages[
                                                                index
                                                              ].owner_right
                                                            }
                                                            name={`certificatelanguages[${index}].owner_right`}
                                                            errors={
                                                              errors?.certificatelanguages &&
                                                              errors
                                                                .certificatelanguages
                                                                ?.length >
                                                                index &&
                                                              errors
                                                                ?.certificatelanguages[
                                                                index
                                                              ]?.owner_right
                                                            }
                                                            touched={
                                                              touched?.certificatelanguages &&
                                                              touched
                                                                .certificatelanguages
                                                                ?.length >
                                                                index &&
                                                              touched
                                                                ?.certificatelanguages[
                                                                index
                                                              ]?.owner_right
                                                            }
                                                            handleChange={
                                                              handleChange
                                                            }
                                                            handleBlur={
                                                              handleBlur
                                                            }
                                                          />
                                                        </div>
                                                      </div>
                                                      <div className="col-md-4">
                                                        <div className="form-group mb-2">
                                                          <label>Address</label>
                                                          <ClientFormikFormFieldMultiple
                                                            placeholder="Write only in English"
                                                            value={
                                                              values
                                                                ?.certificatelanguages[
                                                                index
                                                              ].address
                                                            }
                                                            name={`certificatelanguages[${index}].address`}
                                                            errors={
                                                              errors?.certificatelanguages &&
                                                              errors
                                                                .certificatelanguages
                                                                ?.length >
                                                                index &&
                                                              errors
                                                                ?.certificatelanguages[
                                                                index
                                                              ]?.address
                                                            }
                                                            touched={
                                                              touched?.certificatelanguages &&
                                                              touched
                                                                .certificatelanguages
                                                                ?.length >
                                                                index &&
                                                              touched
                                                                ?.certificatelanguages[
                                                                index
                                                              ]?.address
                                                            }
                                                            handleChange={
                                                              handleChange
                                                            }
                                                            handleBlur={
                                                              handleBlur
                                                            }
                                                          />
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                  <div className="col-md-2 centeraddremovebtn">
                                                    <div className="form-group">
                                                      <div className="mt-4 mtop3">
                                                        {(values
                                                          .certificatelanguages
                                                          .length ===
                                                          index + 1 ||
                                                          values
                                                            .certificatelanguages
                                                            .length === 1) && (
                                                          <ImPlus
                                                            className="customAddButton  rounded"
                                                            onClick={
                                                              handleAddFieldSetEngCertificate
                                                            }
                                                          />
                                                        )}
                                                        {values
                                                          .certificatelanguages
                                                          .length > 1 &&
                                                          index !== 0 && (
                                                            <IoCloseSharp
                                                              className="customRemoveButton rounded"
                                                              onClick={() =>
                                                                handleRemoveFieldSetEngCertificate(
                                                                  index,
                                                                  item?.id
                                                                )
                                                              }
                                                            />
                                                          )}
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            )
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="submit-area mt-5 mtop4">
                      <div className="add2-section-15 mt-4">
                        <div className="row">
                          <div className="col-md-12">
                            <div className="add2-section-title">
                              <div className="row">
                                <div className="col-md-12 col-12">
                                  <div
                                    className={`${styles.add2PanelBackground}`}
                                  >
                                    <div className="d-flex align-items-center gap-3">
                                      <div className="d-flex">
                                        <div
                                          className={`${styles.customCheckbox}`}
                                        >
                                          <label
                                            className="form-check-label d-flex align-items-center"
                                            htmlFor="checkIsAgree"
                                          >
                                            <input
                                              className="form-check-input"
                                              type="checkbox"
                                              name="checkIsAgree"
                                              checked
                                              id="checkIsAgree"
                                            />
                                            <span
                                              class={`${styles.checkmark}`}
                                              title="অঙ্গীকারনামা ভালো করে পড়ুন , সম্মত হলে নির্বাচন করে দাখিল করুন ।"
                                            ></span>
                                          </label>
                                        </div>
                                      </div>
                                      <p className="mb-0">
                                        আমি / আমরা ঘোষণা করছি যে, কপিরাইট
                                        রেজিস্ট্রেশনের আবেদনে আমার পূরণকৃত সকল
                                        তথ্য ও সংযুক্ত দলিলসমূহ সত্য ও নির্ভুল
                                        এবং এ কর্মটিকে কেন্দ্র করে কোন মামলা কোন
                                        বিজ্ঞ আদালত বা ট্রাইব্যুনালে বিচারাধীন
                                        নেই । উল্লিখিত তথ্য বা দলিলে কোন ভুল ও
                                        অসত্য তথ্য উদ্‌ঘাটিত হলে আমি / আমরা এর
                                        জন্য ব্যক্তিগতভাবে দায়ী থাকব এবং দেশের
                                        প্রচলিত আইন অনুযায়ী যেকোন দন্ড গ্রহণে
                                        বাধ্য হবো ।
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="submit-area mt-5 mtop4">
                        <div className="text-center">
                          <button
                            disabled={isLoadingUpdate}
                            className="custombtn5"
                            onClick={handleSubmit}
                            type="submit"
                          >
                            {isLoadingUpdate ? (
                              <BeatLoader color="#fff" />
                            ) : (
                              button_title
                            )}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <FilePreviewModal
        setPreviewModal={setPreviewModal}
        previewModal={previewModal}
        previewFile={previewFile}
        setPreviewFile={setPreviewFile}
      />
      <ShowFilePreviewModal
        setPreviewModal={setPreviewModalOld}
        previewModal={previewModalOld}
        previewUrl={previewImgUrl}
        setPreviewUrl={setPreviewImgUrl}
      />
    </div>
  );
};

export default CorrectionCopyrightSecondPart;
