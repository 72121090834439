import React, { useState } from "react";
import ShowFilePreviewModal from "../../../pages/frontend/userPanel/form_two/ShowPreviewFiles";
import styles from "../../../pages/frontend/userPanel/form_two/Add2.module.css";
import { Link } from "react-router-dom";
import { applicationFormShortTitle } from "../../../utils/applicationFormShortTitle";
import { localDateFormat } from "../../../utils/dateFormat";

const CopyrightPreviewFormTwo = ({ data }) => {
  const [previewModal, setPreviewModal] = useState(false);
  const [previewFile, setPreviewFile] = useState(null);
  const applicationFormTwo = data;
  const publicUrl = process.env.PUBLIC_URL;

  return (
    <div>
      <div className="tab-content p-4" id="nav-tabContent">
        <div
          className="tab-pane fade active show"
          id="nav-application"
          role="tabpanel"
          aria-labelledby="nav-application-tab"
        >
          <div className="applicationForm">
            <div className="print-header" style={{ display: "none" }}>
              <img src={`${publicUrl}/assets/images/logoo.png`} alt="Logo" />
            </div>

            <div class="applicationForm-header text-center add2-application-header">
              <h4>বিষয়াবলীর বিবরণী</h4>{" "}
              <p>
                {" "}
                <b>কপিরাইট রেজিস্ট্রেশনের আবেদন ফরম </b>{" "}
              </p>
            </div>

            <div className="my-2 mt-5">
              <div className={styles.add2sectiontitle}>
                <div className="d-flex">
                  <p className="mt-2">১. নিবন্ধন সংখ্যাঃ </p>
                  <p className="ms-5 px-3 py-2 rounded border text-success">
                    {" "}
                    কপিরাইট অফিস কর্তৃক পূরণীয়
                  </p>
                </div>
              </div>
            </div>

            <div className="my-3">
              <div className="row">
                <div className="col-sm-4 col-md-3 col-lg-3">
                  <p className="mb-0">২. প্রনেতা/স্বত্বাধিকারীর ধরনঃ</p>
                </div>

                <div className="col-sm-8 col-md-9 col-lg-9">
                  <label className="radio-inline">
                    <input
                      name="sources"
                      type="radio"
                      value="1"
                      checked={applicationFormTwo?.type === "1"}
                      readOnly
                    />{" "}
                    ব্যক্তি
                  </label>
                  <label className="radio-inline ms-3">
                    <input
                      name="sources"
                      type="radio"
                      value="2"
                      checked={applicationFormTwo?.type === "2"}
                      readOnly
                    />{" "}
                    যৌথ
                  </label>
                  <label className="radio-inline ms-3">
                    <input
                      name="sources"
                      type="radio"
                      value="3"
                      checked={applicationFormTwo?.type === "3"}
                      readOnly
                    />{" "}
                    প্রতিষ্ঠান
                  </label>
                </div>
              </div>
            </div>

            <div className="add2-section-2">
              <div className="row">
                <div className="col-md-12">
                  {applicationFormTwo?.type === "1" && (
                    <div className="mb-2">
                      <div className="row">
                        <div className="col-md-12">
                          <div className="table-responsive">
                            <table className="table text-center table-striped mb-2 table-bordered hover">
                              <thead>
                                <tr>
                                  <th>নাম</th>
                                  <th>ছবি</th>
                                  <th>ঠিকানা</th>
                                  <th>জাতীয়তা</th>
                                  <th>মোবাইল নম্বর</th>
                                  <th>ই-মেইল</th>
                                  <th className="noprint">পরিচয় পত্রের কপি</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td>
                                    {
                                      applicationFormTwo?.profileDetailType?.[0]
                                        ?.name
                                    }
                                  </td>
                                  <td>
                                    {applicationFormTwo?.profileDetailType?.[0]
                                      ?.image ? (
                                      <img
                                        src={
                                          applicationFormTwo
                                            .profileDetailType[0].image
                                        }
                                        alt="Preview"
                                        style={{ height: "50px" }}
                                      />
                                    ) : (
                                      "No select file"
                                    )}
                                  </td>
                                  <td>
                                    {
                                      applicationFormTwo?.profileDetailType?.[0]
                                        ?.address
                                    }
                                  </td>
                                  <td>
                                    {
                                      applicationFormTwo?.profileDetailType?.[0]
                                        ?.nationality
                                    }
                                  </td>
                                  <td>
                                    {
                                      applicationFormTwo?.profileDetailType?.[0]
                                        ?.mobile
                                    }
                                  </td>
                                  <td>
                                    {" "}
                                    {
                                      applicationFormTwo?.profileDetailType?.[0]
                                        ?.email
                                    }
                                  </td>

                                  <td className="noprint">
                                    <Link
                                      onClick={() => {
                                        setPreviewModal(
                                          applicationFormTwo
                                            .profileDetailType?.[0]?.nid
                                            ? true
                                            : false
                                        );
                                        setPreviewFile(
                                          applicationFormTwo
                                            .profileDetailType?.[0]?.nid
                                        );
                                      }}
                                    >
                                      <div
                                        className={`${styles.imagePreviewAdd2}`}
                                      >
                                        <span className="custombtn55">
                                          {applicationFormTwo
                                            .profileDetailType?.[0]?.nid
                                            ? "এখানে দেখুন"
                                            : "কোনো ফাইল নেই"}
                                        </span>
                                      </div>
                                    </Link>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}

                  {applicationFormTwo?.type === "2" && (
                    <div className="mb-2">
                      <div className="row">
                        <div className="col-md-12">
                          <div className="table-responsive">
                            <table className="table text-center table-striped mb-2 table-bordered hover">
                              <thead>
                                <tr>
                                  <th>নাম</th>
                                  <th>ছবি</th>
                                  <th>ঠিকানা</th>
                                  <th>জাতীয়তা</th>
                                  <th>মোবাইল নম্বর</th>
                                  <th>ই-মেইল</th>
                                  <th className="noprint">পরিচয় পত্রের কপি</th>
                                </tr>
                              </thead>
                              <tbody>
                                {applicationFormTwo?.profileDetailType?.map(
                                  (item, index) => (
                                    <tr key={index}>
                                      <td>{item?.name}</td>
                                      <td>
                                        {item?.image ? (
                                          <img
                                            src={item.image}
                                            alt="Preview"
                                            style={{ height: "50px" }}
                                          />
                                        ) : (
                                          "No select file"
                                        )}
                                      </td>
                                      <td>{item?.address}</td>
                                      <td>{item?.nationality}</td>
                                      <td>{item?.mobile}</td>
                                      <td>{item?.email}</td>
                                      <td className="noprint">
                                        <Link
                                          onClick={() => {
                                            setPreviewModal(
                                              item?.nid ? true : false
                                            );
                                            setPreviewFile(item?.nid);
                                          }}
                                        >
                                          <div
                                            className={`${styles.imagePreviewAdd2}`}
                                          >
                                            <span className="custombtn55">
                                              {item?.nid
                                                ? "এখানে দেখুন"
                                                : "কোনো ফাইল নেই"}
                                            </span>
                                          </div>
                                        </Link>
                                      </td>
                                    </tr>
                                  )
                                )}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}

                  {applicationFormTwo?.type === "3" && (
                    <div className="table-responsive">
                      <table className="table text-center table-striped mb-2 table-bordered hover">
                        <thead>
                          <tr>
                            <th scope="col">প্রতিষ্ঠানের নাম</th>
                            <th scope="col">প্রতিষ্ঠানের ঠিকানা</th>
                            <th scope="col">আবেদনকারীর নাম</th>
                            <th scope="col" className="">
                              আবেদনকারীর ছবি
                            </th>
                            <th scope="col">আবেদনকারীর ঠিকানা</th>
                            <th scope="col" className="">
                              আবেদনকারীর পদবী
                            </th>
                            <th scope="col" className="noprint">
                              মোবাইল নম্বর
                            </th>
                            <th scope="col" className="noprint">
                              ই-মেইল
                            </th>
                            <th scope="col" className="noprint">
                              রেজিস্ট্রেশন নম্বর
                            </th>
                            <th scope="col" className="noprint">
                              পরিচয় পত্রের কপি
                            </th>
                            <th scope="col" className="noprint">
                              ট্রেড লাইসেন্সের কপি
                            </th>
                            <th scope="col" className="noprint">
                              টিন সার্টিফিকেটের কপি
                            </th>
                            <th scope="col" className="noprint">
                              মেমোর‍্যান্ডামের কপি
                            </th>
                            <th scope="col" className="noprint">
                              গঠনতন্ত্রের কপি
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {applicationFormTwo?.profileDetailType?.map(
                            (item, index) => (
                              <tr key={index}>
                                <td>{item?.companyName}</td>
                                <td>{item?.companyAddress}</td>
                                <td>{item?.name}</td>
                                <td className="">
                                  {item?.image ? (
                                    <img
                                      src={item.image}
                                      alt="preview"
                                      style={{ height: "50px" }}
                                    />
                                  ) : (
                                    "No File selected"
                                  )}
                                </td>
                                <td>{item?.address}</td>
                                <td>{item?.designation}</td>
                                <td className="noprint">{item?.mobile}</td>
                                <td className="noprint">{item?.email}</td>
                                <td className="noprint">{item?.pRegno}</td>
                                <td className="noprint">
                                  <Link
                                    onClick={() => {
                                      setPreviewModal(item?.nid ? true : false);
                                      setPreviewFile(item?.nid);
                                    }}
                                  >
                                    <div
                                      className={`${styles.imagePreviewAdd2}`}
                                    >
                                      <span className="custombtn55">
                                        {item?.nid
                                          ? "এখানে দেখুন"
                                          : "কোনো ফাইল নেই"}
                                      </span>
                                    </div>
                                  </Link>
                                </td>
                                <td className="noprint">
                                  <Link
                                    onClick={() => {
                                      setPreviewModal(
                                        item?.tradeLicense ? true : false
                                      );
                                      setPreviewFile(item?.tradeLicense);
                                    }}
                                  >
                                    <div
                                      className={`${styles.imagePreviewAdd2}`}
                                    >
                                      <span className="custombtn55">
                                        {item?.tradeLicense
                                          ? "এখানে দেখুন"
                                          : "কোনো ফাইল নেই"}
                                      </span>
                                    </div>
                                  </Link>
                                </td>
                                <td className="noprint">
                                  <Link
                                    onClick={() => {
                                      setPreviewModal(
                                        item?.tinCertificate ? true : false
                                      );
                                      setPreviewFile(item?.tinCertificate);
                                    }}
                                  >
                                    <div
                                      className={`${styles.imagePreviewAdd2}`}
                                    >
                                      <span className="custombtn55">
                                        {item?.tinCertificate
                                          ? "এখানে দেখুন"
                                          : "কোনো ফাইল নেই"}
                                      </span>
                                    </div>
                                  </Link>
                                </td>
                                <td className="noprint">
                                  <Link
                                    onClick={() => {
                                      setPreviewModal(
                                        item?.memorandum ? true : false
                                      );
                                      setPreviewFile(item?.memorandum);
                                    }}
                                  >
                                    <div
                                      className={`${styles.imagePreviewAdd2}`}
                                    >
                                      <span className="custombtn55">
                                        {item?.memorandum
                                          ? "এখানে দেখুন"
                                          : "কোনো ফাইল নেই"}
                                      </span>
                                    </div>
                                  </Link>
                                </td>
                                <td className="noprint">
                                  <Link
                                    onClick={() => {
                                      setPreviewModal(
                                        item?.instStruct ? true : false
                                      );
                                      setPreviewFile(item?.instStruct);
                                    }}
                                  >
                                    <div
                                      className={`${styles.imagePreviewAdd2}`}
                                    >
                                      <span className="custombtn55">
                                        {item?.instStruct
                                          ? "এখানে দেখুন"
                                          : "কোনো ফাইল নেই"}
                                      </span>
                                    </div>
                                  </Link>
                                </td>
                              </tr>
                            )
                          )}
                        </tbody>
                      </table>
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div className="my-4">
              <div className="row">
                <div className="col-sm-4 col-md-3 col-lg-3">
                  <p className="mb-0">৩. কপিরাইটে স্বার্থের ধরনঃ</p>
                </div>
                <div className="col-sm-8 col-md-9 col-lg-9">
                  <label className="radio-inline">
                    <input
                      name="source"
                      type="radio"
                      value="1"
                      checked={applicationFormTwo?.selfType === "1"}
                      readOnly
                    />{" "}
                    কপিরাইট
                  </label>
                  <label className="radio-inline ms-3">
                    <input
                      name="source"
                      type="radio"
                      value="2"
                      checked={applicationFormTwo?.selfType === "2"}
                      readOnly
                    />{" "}
                    রিলেটেড রাইট
                  </label>
                </div>
                <div className="col-md-12 mt-2">
                  <div className="table-responsive">
                    <table className="table tableeeee text-center table-striped mb-2 table-bordered hover">
                      <tbody>
                        <tr>
                          <td className="col-sm-3 col-md-3 col-lg-3 text-center col-12">
                            <div className="form-group">
                              <label>
                                {applicationFormTwo?.selfType === "1"
                                  ? "কপিরাইটের ধরন"
                                  : "রিলেটেড রাইট"}
                              </label>
                              <div>
                                {
                                  applicationFormTwo?.selfTypes?.[0]
                                    ?.relatedRight.name
                                }
                              </div>
                            </div>
                          </td>

                          <td className="col-md-10">
                            <table className="table mb-0 tableeeee table-bordered">
                              <tbody>
                                {!applicationFormTwo?.selfTypes?.[0]
                                  ?.relatedRight?.id === 16 &&
                                !applicationFormTwo?.selfTypes?.[0]
                                  ?.relatedRight?.id === 10 &&
                                !applicationFormTwo?.selfTypes?.[0]
                                  ?.relatedRight?.id === 6 &&
                                !applicationFormTwo?.selfTypes?.[0]
                                  ?.relatedRight?.id === 13 &&
                                !applicationFormTwo?.selfTypes?.[0]
                                  ?.relatedRight?.id === 19 ? (
                                  <tr>
                                    <td className="w-100">
                                      <div className="form-group">
                                        <label>
                                          {applicationFormTwo?.selfTypes?.[0]
                                            ?.relatedRight?.id === 13
                                            ? "প্রণেতা / রচয়িতার ধরন"
                                            : applicationFormTwo?.selfTypes?.[0]
                                                ?.relatedRight?.id === 19
                                            ? "অন্যান্য সূত্রে ধরন"
                                            : null}
                                        </label>
                                        <div className="input-group">
                                          {applicationFormTwo?.selfTypes?.[0]
                                            ?.others || ""}
                                        </div>
                                      </div>
                                    </td>
                                  </tr>
                                ) : (
                                  <tr>
                                    <td className="col-md-4">
                                      {(applicationFormTwo?.selfTypes?.[0]
                                        ?.relatedRight?.id === 16 ||
                                        applicationFormTwo?.selfTypes?.[0]
                                          ?.relatedRight?.id === 10) && (
                                        <div className="col-md-12">
                                          <div
                                            className={`${styles.add2FormGroupImagePreviewBox} form-group text-center`}
                                          >
                                            <label className="text-start">
                                              উত্তরাধিকার সনদপত্রের স্ক্যান কপি
                                            </label>
                                            <br />
                                            <div
                                              className={`${styles.imagePreviewAdd2}`}
                                            >
                                              <Link
                                                onClick={() => {
                                                  setPreviewModal(
                                                    applicationFormTwo
                                                      ?.selfTypes?.[0]
                                                      ?.selfLegacy
                                                      ? true
                                                      : false
                                                  );
                                                  setPreviewFile(
                                                    applicationFormTwo
                                                      ?.selfTypes?.[0]
                                                      ?.selfLegacy
                                                  );
                                                }}
                                              >
                                                <div
                                                  className={`${styles.imagePreviewAdd2}`}
                                                >
                                                  {applicationFormTwo
                                                    ?.selfTypes?.[0]
                                                    ?.selfLegacy ? (
                                                    <span className="custombtn55 d-none printiconn">
                                                      <img
                                                        src={`${publicUrl}/assets/images/check.png`}
                                                        className=""
                                                        alt="Check Icon"
                                                      />
                                                    </span>
                                                  ) : (
                                                    <span className="custombtn55 d-none printiconn">
                                                      <img
                                                        src={`${publicUrl}/assets/images/cros.png`}
                                                        className=""
                                                        alt="cros Icon"
                                                      />
                                                    </span>
                                                  )}

                                                  <span className="custombtn55 printnone">
                                                    {applicationFormTwo
                                                      ?.selfTypes?.[0]
                                                      ?.selfLegacy
                                                      ? "এখানে দেখুন"
                                                      : "কোনো ফাইল নেই"}
                                                  </span>
                                                </div>
                                              </Link>
                                            </div>
                                          </div>
                                        </div>
                                      )}
                                    </td>

                                    <td className="col-md-8">
                                      {applicationFormTwo?.selfTypes?.map(
                                        (item, index) => (
                                          <div className="row" key={index}>
                                            <div className="col-md-12">
                                              <div
                                                className={`${styles.add2FormGroupImagePreviewBox} form-group text-center`}
                                              >
                                                <label className="text-center">
                                                  {applicationFormShortTitle(
                                                    item?.relatedRight?.id
                                                  )}
                                                </label>{" "}
                                                <br />
                                                <Link
                                                  onClick={() => {
                                                    setPreviewModal(
                                                      item?.noc ? true : false
                                                    );
                                                    setPreviewFile(item?.noc);
                                                  }}
                                                >
                                                  <div
                                                    className={`${styles.imagePreviewAdd2}`}
                                                  >
                                                    {item?.noc ? (
                                                      <span className="custombtn55 d-none printiconn">
                                                        <img
                                                          src={`${publicUrl}/assets/images/check.png`}
                                                          className=""
                                                          alt="Check Icon"
                                                        />
                                                      </span>
                                                    ) : (
                                                      <span className="custombtn55 d-none printiconn">
                                                        <img
                                                          src={`${publicUrl}/assets/images/cros.png`}
                                                          className=""
                                                          alt="cros Icon"
                                                        />
                                                      </span>
                                                    )}

                                                    <span className="custombtn55 printnone">
                                                      {item?.noc
                                                        ? "এখানে দেখুন"
                                                        : "কোনো ফাইল নেই"}
                                                    </span>
                                                  </div>
                                                </Link>
                                              </div>
                                            </div>
                                          </div>
                                        )
                                      )}
                                    </td>
                                  </tr>
                                )}
                              </tbody>
                            </table>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>

            <div className="add2-section-4">
              <div className="row">
                <div className="col-md-12">
                  <div className="add2-section-title">
                    <p className="mb-0">৪. কর্মটির প্রকৃতি এবং বিবরণঃ</p>
                  </div>
                  <div className="mx-3">
                    <div className="form-group my-3">
                      <div className="row">
                        <div className="col-sm-4 col-md-3 col-lg-3">
                          <p className="mb-0">ক. কর্মটির প্রকৃতি -</p>
                        </div>
                        <div className="col-sm-4 col-md-3 col-lg-3">
                          <p className="mb-0">
                            <strong>শ্রেণি : </strong>
                            {applicationFormTwo?.category?.name || ""}{" "}
                          </p>
                        </div>
                        <div className="col-sm-4 col-md-3 col-lg-3">
                          <p className="mb-0">
                            <strong>উপশ্রেণি : </strong>{" "}
                            {applicationFormTwo?.subCategory?.name || ""}{" "}
                            {applicationFormTwo?.otherSubCategory && (
                              <div>{applicationFormTwo.otherSubCategory}</div>
                            )}
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="form-group my-3">
                      <div className="row">
                        <div className="col-sm-4 col-md-3 col-lg-3">
                          <p className="mb-0">খ. কর্মটির বিবরণ -</p>
                        </div>

                        <div className="col-sm-8 col-md-9 col-lg-9">
                          {applicationFormTwo?.workDescription || ""}
                        </div>
                      </div>
                    </div>

                    <div className="form-group">
                      <div className="row">
                        <div className="col-sm-4 col-md-3 col-lg-3 mt-2">
                          <p className="mb-0">গ. কর্মের সফ্‌ট কপি -</p>
                        </div>
                        <div className="col-sm-8 col-md-9 col-lg-9 mb-2">
                          <div className="row">
                            <div className="col-md-12">
                              <div className="row">
                                {applicationFormTwo?.workSoftCopies?.map(
                                  (item, index) => (
                                    <div
                                      className="col-sm-4 col-md-4 col-lg-4"
                                      key={index}
                                    >
                                      <div
                                        className={`${styles.add2FormGroupImagePreviewBox}`}
                                      >
                                        <Link
                                          onClick={() => {
                                            setPreviewModal(
                                              item?.workSoftCopy ? true : false
                                            );
                                            setPreviewFile(item?.workSoftCopy);
                                          }}
                                        >
                                          <div
                                            className={`${styles.imagePreviewAdd2}`}
                                          >
                                            {item?.workSoftCopy ? (
                                              <span className="custombtn56 text-center d-none printiconn">
                                                <img
                                                  src={`${publicUrl}/assets/images/check.png`}
                                                  alt="Check Icon"
                                                />
                                              </span>
                                            ) : (
                                              <span className="custombtn56 text-center d-none printiconn">
                                                <img
                                                  src={`${publicUrl}/assets/images/cros.png`}
                                                  alt="cros Icon"
                                                />
                                              </span>
                                            )}

                                            <span className="custombtn56 text-center printnone">
                                              {item?.workSoftCopy
                                                ? "এখানে দেখুন"
                                                : "কোনো ফাইল নেই"}
                                            </span>
                                          </div>
                                        </Link>
                                      </div>
                                    </div>
                                  )
                                )}

                                {(applicationFormTwo?.category?.id === 7 ||
                                  applicationFormTwo?.category?.id === 2) && (
                                  <div className="col-md-8 col-lg-8 col-sm-8">
                                    <div
                                      className={`${styles.add2FormGroupImagePreviewBox} form-group`}
                                    >
                                      <Link
                                        onClick={() => {
                                          setPreviewModal(
                                            applicationFormTwo?.musicOrFilm
                                              ? true
                                              : false
                                          );
                                          setPreviewFile(
                                            applicationFormTwo?.musicOrFilm
                                          );
                                        }}
                                      >
                                        <div
                                          className={`${styles.imagePreviewAdd2}`}
                                        >
                                          {applicationFormTwo?.musicOrFilm ? (
                                            <span className="custombtn55 text-center d-none printiconn">
                                              <img
                                                src={`${publicUrl}/assets/images/check.png`}
                                                alt="Check Icon"
                                              />
                                            </span>
                                          ) : (
                                            <span className="custombtn55 text-center d-none printiconn">
                                              <img
                                                src={`${publicUrl}/assets/images/cros.png`}
                                                alt="cros Icon"
                                              />
                                            </span>
                                          )}

                                          <span className="custombtn56 text-center printnone">
                                            {applicationFormTwo?.category
                                              ?.id === 2
                                              ? applicationFormTwo?.musicOrFilm
                                                ? "গানের তালিকা দেখুন"
                                                : "কোনো ফাইল নেই"
                                              : applicationFormTwo?.musicOrFilm
                                              ? "চলচ্চিত্র সেন্সর সনদ দেখুন"
                                              : "কোনো ফাইল নেই"}
                                          </span>
                                        </div>
                                      </Link>
                                    </div>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="form-group mt-2">
                      <div className="row">
                        <div className="col-sm-4 col-md-3 col-lg-3">
                          <p>ঘ. ইহা কি একটি মৌলিক কর্ম ?</p>
                        </div>
                        <div className="col-sm-8 col-md-9 col-lg-9">
                          <span className="d-flex">
                            <div className="form-check">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="sideRadioMoulik"
                                id="sideRadioMoulik1"
                                checked={
                                  applicationFormTwo?.basicWorktype === "1"
                                }
                              />
                              <label
                                className="form-check-label"
                                htmlFor="sideRadioMoulik1"
                              >
                                হ্যাঁ
                              </label>
                            </div>
                            <div className="form-check ms-2">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="sideRadioMoulik"
                                id="sideRadioMoulik2"
                                checked={
                                  applicationFormTwo?.basicWorktype === "2"
                                }
                              />
                              <label
                                className="form-check-label"
                                htmlFor="sideRadioMoulik2"
                              >
                                না
                              </label>
                            </div>
                          </span>
                        </div>

                        <div className="col-md-12">
                          {applicationFormTwo?.basicWorktype === "2" && (
                            <div>
                              {applicationFormTwo?.basicWorkTypes?.map(
                                (item, index) => (
                                  <div className="row mb-3" key={index}>
                                    <div
                                      className="py-2"
                                      style={{ backgroundColor: "#c5c5c5" }}
                                    >
                                      {item?.keyName}
                                    </div>

                                    <div className="col-md-12 border p-2 py-3">
                                      <div className="container">
                                        <div className="row">
                                          <div className="col-md-3 col-lg-3 col-sm-4">
                                            <p>(ক) মৌলিক কর্মটির শিরোনাম : </p>
                                          </div>
                                          <div className="col-md-9 col-lg-9 col-sm-8">
                                            {item?.title}
                                          </div>
                                          <div className="col-md-3 col-lg-3 col-sm-4">
                                            <p>(খ) মৌলিক কর্মটির ভাষা : </p>
                                          </div>
                                          <div className="col-md-9 col-lg-9 col-sm-8">
                                            {item?.language}
                                          </div>
                                        </div>

                                        <div className="row mt-1 mb-2">
                                          <div className="col-md-12">
                                            <p>
                                              (গ) মৌলিক কর্মটির লেখক/ রচয়িতা/
                                              প্রণেতা জীবিত না মৃত ?
                                            </p>
                                          </div>
                                          <div className="col-md-12">
                                            <div className="table-responsive">
                                              <table className="table text-center table-striped mb-2 table-bordered hover">
                                                <thead>
                                                  <tr>
                                                    <th>নাম</th>
                                                    <th>ঠিকানা</th>
                                                    <th>জাতীয়তা</th>
                                                    <th>মৃত্যুর তারিখ</th>
                                                  </tr>
                                                </thead>
                                                <tbody>
                                                  {item?.workTypes?.map(
                                                    (items, indexs) => (
                                                      <tr key={indexs}>
                                                        <td>{items.name}</td>
                                                        <td>{items.address}</td>
                                                        <td>
                                                          {items.nationality}
                                                        </td>
                                                        <td>
                                                          {localDateFormat(
                                                            items?.deadDate
                                                          )}
                                                        </td>
                                                      </tr>
                                                    )
                                                  )}
                                                </tbody>
                                              </table>
                                            </div>
                                          </div>
                                        </div>

                                        <div className="row mt-2">
                                          <div className="col-md-12">
                                            <p>(ঘ) কর্মটির প্রকাশক(যদি থাকে)</p>
                                          </div>

                                          <div className="col-md-12">
                                            <div className="table-responsive">
                                              <table className="table text-center table-striped mb-2 table-bordered hover">
                                                <thead>
                                                  <tr>
                                                    <th>নাম</th>
                                                    <th>ঠিকানা</th>
                                                    <th>জাতীয়তা</th>
                                                  </tr>
                                                </thead>
                                                <tbody>
                                                  {item?.basicPublishes?.map(
                                                    (items, indexs) => (
                                                      <tr key={indexs}>
                                                        <td>{items.name}</td>
                                                        <td>{items.address}</td>
                                                        <td>
                                                          {items.nationality}
                                                        </td>
                                                      </tr>
                                                    )
                                                  )}
                                                </tbody>
                                              </table>
                                            </div>
                                          </div>
                                        </div>

                                        <div className="row mt-2">
                                          <div className="col-md-6 col-sm-9 col-lg-6">
                                            <p className="mb-0">
                                              (ঙ) কর্মটির অনুবাদ কিংবা অভিযোজন
                                              করার ক্ষমতাপ্রাপ্ত ব্যক্তি কি
                                              আছেন?
                                            </p>
                                          </div>
                                          <div className="col-md-6 col-sm-3 col-lg-6">
                                            <span className="d-flex mx-3 pt-1">
                                              <div className="form-check mx-3">
                                                <input
                                                  className="form-check-input"
                                                  type="radio"
                                                  name={`authorizeTypes11${index}`}
                                                  id={`authorizeTypes11${index}`}
                                                  checked={
                                                    item?.authorize === "1"
                                                  }
                                                />
                                                <label
                                                  className="form-check-label"
                                                  htmlFor={`authorizeTypes11${index}`}
                                                >
                                                  হ্যাঁ
                                                </label>
                                              </div>
                                              <div className="form-check me-3">
                                                <input
                                                  className="form-check-input"
                                                  type="radio"
                                                  name={`authorizeTypes22${index}`}
                                                  id={`authorizeTypes22${index}`}
                                                  checked={
                                                    item?.authorize === "2"
                                                  }
                                                />
                                                <label
                                                  className="form-check-label"
                                                  htmlFor={`authorizeTypes22${index}`}
                                                >
                                                  না
                                                </label>
                                              </div>
                                            </span>
                                          </div>

                                          {item?.authorize === "1" && (
                                            <div className="col-md-12 mt-2">
                                              <div className="table-responsive">
                                                <table className="table text-center centerdddadd table-striped mb-2 table-bordered hover">
                                                  <thead>
                                                    <tr>
                                                      <th>নাম</th>
                                                      <th>ঠিকানা</th>
                                                      <th>জাতীয়তা</th>
                                                      <th>
                                                        অনুমোদনের স্ক্যান কপি
                                                      </th>
                                                    </tr>
                                                  </thead>
                                                  <tbody>
                                                    {item?.authorizeTypes?.map(
                                                      (items, indexs) => (
                                                        <tr key={indexs}>
                                                          <td>{items.name}</td>
                                                          <td>
                                                            {items.address}
                                                          </td>
                                                          <td>
                                                            {items.nationality}
                                                          </td>
                                                          <td className="centerddddd">
                                                            <div
                                                              className=""
                                                              style={{
                                                                cursor:
                                                                  "pointer",
                                                              }}
                                                              onClick={() => {
                                                                setPreviewModal(
                                                                  items.approvalSc
                                                                    ? true
                                                                    : false
                                                                );
                                                                setPreviewFile(
                                                                  items.approvalSc
                                                                );
                                                              }}
                                                            >
                                                              <div
                                                                className={`${styles.imagePreviewAdd2}`}
                                                              >
                                                                {items.approvalSc ? (
                                                                  <span className="custombtn55 text-center d-none printiconn">
                                                                    <img
                                                                      src={`${publicUrl}/assets/images/check.png`}
                                                                      alt="Check Icon"
                                                                    />
                                                                  </span>
                                                                ) : (
                                                                  <span className="custombtn55 text-center d-none printiconn">
                                                                    <img
                                                                      src={`${publicUrl}/assets/images/cros.png`}
                                                                      alt="cros Icon"
                                                                    />
                                                                  </span>
                                                                )}

                                                                <span className="custombtn55 text-center printnone">
                                                                  {items.approvalSc
                                                                    ? "এখানে দেখুন"
                                                                    : "কোনো ফাইল নেই"}
                                                                </span>
                                                              </div>
                                                            </div>
                                                          </td>
                                                        </tr>
                                                      )
                                                    )}
                                                  </tbody>
                                                </table>
                                              </div>
                                            </div>
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                )
                              )}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row mt-3">
              <div className="col-md-3 col-lg-3 col-sm-4">
                <p>৫. কর্মটির শিরোনামঃ</p>
              </div>
              <div className="col-md-9 col-lg-9 col-sm-8">
                {applicationFormTwo?.workTitle}
              </div>
              <div className="col-md-3 col-lg-3 col-sm-4">
                <p>৬. কর্মটির ভাষাঃ</p>
              </div>
              <div className="col-md-9 col-lg-9 col-sm-8">
                {applicationFormTwo?.workLanguage}
              </div>
            </div>

            <div className="row">
              <div className="col-md-12">
                <p className="mb-0">
                  {" "}
                  ৭. লেখক / রচয়িতা / প্রণেতার নাম, ঠিকানা ও জাতীয়তা এবং লেখক /
                  রচয়িতা / প্রণেতা মৃত হইলে মৃত্যুর তারিখ -{" "}
                </p>
                <div className="row mt-2">
                  <div className="col-md-12">
                    <div className="table-responsive">
                      <table className="table text-center table-striped mb-2 table-bordered hover">
                        <thead>
                          <tr>
                            <th>নাম</th>
                            <th>ঠিকানা</th>
                            <th>জাতীয়তা</th>
                            <th>মৃত্যুর তারিখ</th>
                          </tr>
                        </thead>
                        <tbody>
                          {applicationFormTwo?.writerDetails?.map(
                            (item, index) => (
                              <tr key={index}>
                                <td>{item.writerName}</td>
                                <td>{item.writerAddress}</td>
                                <td>{item.writerNationality}</td>
                                <td>{localDateFormat(item?.writerDeadDate)}</td>
                              </tr>
                            )
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row mt-3">
              <div className="col-md-12">
                <div className="add2-section-title">
                  <div className="row">
                    <div className="col-md-4 col-sm-6 col-lg-4">
                      <p className="mb-0">
                        {" "}
                        ৮. কর্মটি ইতোমধ্যে প্রকাশিত হইয়াছে কি না ?{" "}
                      </p>
                    </div>
                    <div className="col-md-8 col-sm-6 col-lg-8">
                      <span className="d-flex mx-3 pt-1">
                        <div className="form-check mx-3">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="section7Radio"
                            id=""
                            checked={
                              applicationFormTwo?.workPublishtype === "1"
                            }
                            readOnly
                          />
                          <label className="form-check-label" htmlFor="">
                            হ্যাঁ
                          </label>
                        </div>
                        <div className="form-check me-3">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="section7Radio"
                            id=""
                            checked={
                              applicationFormTwo?.workPublishtype === "2"
                            }
                            readOnly
                          />
                          <label className="form-check-label" htmlFor="">
                            না
                          </label>
                        </div>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-12">
                <p className="mb-0">
                  {" "}
                  ৯. প্রথম প্রকাশনার বছর, স্থান এবং প্রকাশকের নাম, ঠিকানা ও
                  জাতীয়তা
                </p>

                {applicationFormTwo?.workPublishtype === "1" && (
                  <div className="row mt-2">
                    <div className="col-md-12">
                      <div className="table-responsive">
                        <table className="table text-center table-striped mb-2 table-bordered hover">
                          <thead>
                            <tr>
                              <th>প্রথম প্রকাশনার বছর</th>
                              <th>প্রথম প্রকাশনার স্থান</th>
                              <th>প্রথম প্রকাশকের নাম</th>
                              <th>প্রথম প্রকাশকের ঠিকানা</th>
                              <th>প্রথম প্রকাশকের জাতীয়তা</th>
                              {applicationFormTwo?.category?.id === 1 && (
                                <th className="noprint">
                                  প্রচ্ছদ কর্মের হস্তান্তর দলিলের স্ক্যান কপি
                                </th>
                              )}
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>
                                {localDateFormat(
                                  applicationFormTwo?.publish?.year
                                )}
                              </td>
                              <td>{applicationFormTwo?.publish?.place}</td>
                              <td>{applicationFormTwo?.publish?.name}</td>
                              <td>{applicationFormTwo?.publish?.address}</td>
                              <td>
                                {applicationFormTwo?.publish?.nationality}
                              </td>
                              {applicationFormTwo?.category?.id === 1 && (
                                <td className="noprint">
                                  <div className="form-group mb-2">
                                    <div className="form-group add2-form-group-image-preview-box">
                                      <div
                                        style={{ cursor: "pointer" }}
                                        onClick={() => {
                                          setPreviewModal(
                                            applicationFormTwo?.publish
                                              ?.literatureDoc
                                              ? true
                                              : false
                                          );
                                          setPreviewFile(
                                            applicationFormTwo?.publish
                                              ?.literatureDoc
                                          );
                                        }}
                                      >
                                        <div
                                          className={`${styles.imagePreviewAdd2}`}
                                        >
                                          <span className="custombtn55">
                                            {applicationFormTwo?.publish
                                              ?.literatureDoc
                                              ? "এখানে দেখুন"
                                              : "কোনো ফাইল নেই"}
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </td>
                              )}
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>

            <div className="row mt-3">
              <div className="col-md-4 col-sm-7 col-lg-4">
                <p className="mb-0">
                  ১০ . পরবর্তীকালে / সর্বশেষ কর্মটি আরো প্রকাশিত হইয়াছে কি ?
                </p>
              </div>
              <div className="col-md-8 col-sm-5 col-lg-8">
                <span className="d-flex mx-3">
                  <div className="form-check mx-3">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="aa"
                      id=""
                      checked={applicationFormTwo?.otherPublishtype === "1"}
                      readOnly
                    />
                    <label className="form-check-label" htmlFor="">
                      হ্যাঁ
                    </label>
                  </div>
                  <div className="form-check me-3">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="aa"
                      id=""
                      checked={applicationFormTwo?.otherPublishtype === "2"}
                      readOnly
                    />
                    <label className="form-check-label" htmlFor="">
                      না
                    </label>
                  </div>
                </span>
              </div>
              {applicationFormTwo?.otherPublishtype === "1" && (
                <div className="col-md-12 mt-2">
                  <div className="table-responsive">
                    <table className="table text-center table-striped mb-2 table-bordered hover">
                      <thead>
                        <tr>
                          <th>সর্বশেষ প্রকাশনার বছর</th>
                          <th>সর্বশেষ প্রকাশনার স্থান</th>
                          <th>সর্বশেষ প্রকাশকের নাম</th>
                          <th>সর্বশেষ প্রকাশকের ঠিকানা</th>
                          <th>সর্বশেষ প্রকাশকের জাতীয়তা</th>
                          {applicationFormTwo?.category?.id === 1 && (
                            <th className="noprint">
                              প্রচ্ছদ কর্মের হস্তান্তর দলিলের স্ক্যান কপি
                            </th>
                          )}
                        </tr>
                      </thead>
                      <tbody>
                        {applicationFormTwo.publishDetails.map(
                          (item, index) => (
                            <tr key={index}>
                              <td>{localDateFormat(item?.year)}</td>
                              <td>{item?.place}</td>
                              <td>{item?.name}</td>
                              <td>{item?.address}</td>
                              <td>{item?.nationality}</td>
                              {applicationFormTwo?.category?.id === 1 && (
                                <td className="noprint">
                                  <div className="form-group mb-2">
                                    <div className="form-group text-center add2-form-group-image-preview-box">
                                      <div
                                        style={{ cursor: "pointer" }}
                                        onClick={() => {
                                          setPreviewModal(
                                            item?.literatureDoc ? true : false
                                          );
                                          setPreviewFile(item?.literatureDoc);
                                        }}
                                      >
                                        <div
                                          className={`${styles.imagePreviewAdd2}`}
                                        >
                                          <span className="custombtn55">
                                            {item?.literatureDoc
                                              ? "এখানে দেখুন"
                                              : "কোনো ফাইল নেই"}
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </td>
                              )}
                            </tr>
                          )
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              )}
            </div>

            <div className="row mt-3">
              <div className="col-md-4 col-sm-7 col-lg-4">
                <p className="mb-0">
                  {" "}
                  ১১. কর্মটির কপিরাইটভুক্ত অন্য কোন স্বত্বাধিকারী আছে কি ?
                </p>
              </div>
              <div className="col-md-8 col-sm-5 col-lg-8">
                <span className="d-flex mx-3">
                  <div className="form-check mx-3">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="adda"
                      id=""
                      checked={applicationFormTwo?.ownerType === "1"}
                      readOnly
                    />
                    <label className="form-check-label" htmlFor="">
                      হ্যাঁ
                    </label>
                  </div>
                  <div className="form-check me-3">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="adda"
                      id=""
                      checked={applicationFormTwo?.ownerType === "2"}
                      readOnly
                    />
                    <label className="form-check-label" htmlFor="">
                      না
                    </label>
                  </div>
                </span>
              </div>
              {applicationFormTwo?.ownerType === "1" && (
                <div className="col-md-12 mt-2">
                  <div className="table-responsive">
                    <table className="table text-center table-striped mb-2 table-bordered hover">
                      <thead>
                        <tr>
                          <th>স্বত্বাধিকারীর নাম</th>
                          <th>স্বত্বাধিকারীর ঠিকানা</th>
                          <th>স্বত্বাধিকারীর জাতীয়তা</th>
                          <th>স্বত্বের অংশ</th>
                        </tr>
                      </thead>
                      <tbody>
                        {applicationFormTwo?.ownerTypes?.map((item, index) => (
                          <tr key={index}>
                            <td>{item?.ownerName}</td>
                            <td>{item?.ownerAddress}</td>
                            <td>{item?.ownerNationality}</td>
                            <td>{item?.partTitle}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              )}
            </div>

            <div className="row mt-3">
              <div className="col-md-7 col-sm-8 col-lg-7">
                <p className="mb-0">
                  ১২. কপিরাইটভুক্ত বিভিন্ন স্বত্ব নিয়োগ বা লাইসেন্স প্রদানের
                  ক্ষমতাপ্রাপ্ত ব্যক্তি আছেন কি ?
                </p>
              </div>
              <div className="col-md-5 col-sm-4 col-lg-5">
                <span className="d-flex mx-3">
                  <div className="form-check mx-3">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="addssa"
                      id=""
                      checked={applicationFormTwo?.powerType === "1"}
                      readOnly
                    />
                    <label className="form-check-label" htmlFor="">
                      হ্যাঁ
                    </label>
                  </div>
                  <div className="form-check me-3">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="addssa"
                      id=""
                      checked={applicationFormTwo?.powerType === "2"}
                      readOnly
                    />
                    <label className="form-check-label" htmlFor="">
                      না
                    </label>
                  </div>
                </span>
              </div>
              {applicationFormTwo?.powerType === "1" && (
                <div className="col-md-12 mt-2">
                  <div className="table-responsive">
                    <table className="table text-center table-striped mb-2 table-bordered hover">
                      <thead>
                        <tr>
                          <th>ব্যক্তির নাম</th>
                          <th>ব্যক্তির ঠিকানা</th>
                          <th>ব্যক্তির জাতীয়তা</th>
                          <th>
                            লাইসেন্স প্রদানের ক্ষমতা পত্র ( প্রযোজ্য ক্ষেত্রে )
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {applicationFormTwo?.powerTypes?.map((item, index) => (
                          <tr key={index}>
                            <td>{item?.name}</td>
                            <td>{item?.address}</td>
                            <td>{item?.nationality}</td>
                            <td className="centerddddd">
                              <div className="form-group text-center">
                                <div className="form-group add2-form-group-image-preview-box">
                                  <div
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      setPreviewModal(
                                        item?.licensePay ? true : false
                                      );
                                      setPreviewFile(item?.licensePay);
                                    }}
                                  >
                                    <div
                                      className={`${styles.imagePreviewAdd2}`}
                                    >
                                      {item?.licensePay ? (
                                        <span className="custombtn56 text-center d-none printiconn">
                                          <img
                                            src={`${publicUrl}/assets/images/check.png`}
                                            alt="Check Icon"
                                          />
                                        </span>
                                      ) : (
                                        <span className="custombtn55 text-center d-none printiconn">
                                          <img
                                            src={`${publicUrl}/assets/images/cros.png`}
                                            alt="cros Icon"
                                          />
                                        </span>
                                      )}

                                      <span className="custombtn55 text-center printnone">
                                        {item?.licensePay
                                          ? "এখানে দেখুন"
                                          : "কোনো ফাইল নেই"}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              )}
            </div>

            <div className="row mt-3">
              <div className="col-md-12 col-12">
                <p className="mb-0">
                  ১৩. শিল্পকর্ম ( শুধু শিল্পকর্মের জন্য প্রযোজ্য )
                </p>
              </div>
              {applicationFormTwo?.category?.id === 3 && (
                <div className="col-md-12 mt-2">
                  <div className="table-responsive">
                    <table className="table text-center table-striped mb-2 table-bordered hover">
                      <thead>
                        <tr>
                          <th>মূল শিল্পকর্মটি কোথায় আছে ?</th>
                          <th>যাদের আয়ত্বে আছে তাদের নাম</th>
                          <th>যাদের আয়ত্বে আছে তাদের ঠিকানা</th>
                          <th>যাদের আয়ত্বে আছে তাদের জাতীয়তা</th>
                          <th>কর্ম সমাপ্তির বছর</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>{applicationFormTwo?.industryActions?.place}</td>
                          <td>{applicationFormTwo?.industryActions?.name}</td>
                          <td>
                            {applicationFormTwo?.industryActions?.address}
                          </td>
                          <td>
                            {applicationFormTwo?.industryActions?.nationality}
                          </td>
                          <td>
                            {applicationFormTwo?.industryActions?.endYear}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              )}
            </div>

            <div className="row mt-3">
              <div className="col-md-3 col-lg-3 col-sm-4">
                <p className="mb-0">১৪. মন্তব্য লিখুন ( যদি থাকে ) -</p>
              </div>

              <div className="col-md-9 col-lg-9 col-sm-8">
                <p className="mb-0">{applicationFormTwo?.comment}</p>
              </div>
            </div>

            <div className="row mt-3">
              <div className="col-md-4 col-sm-4 col-lg-4">
                <p className="mb-0">সার্টিফিকেট কোন ভাষায় চান ?</p>
              </div>

              <div className="col-md-8 col-sm-8 col-lg-8">
                <span className="d-flex mx-3">
                  <div className="form-check mx-3">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="bangla"
                      id=""
                      checked={applicationFormTwo?.certificate === "1"}
                      readOnly
                    />
                    <label className="form-check-label" htmlFor="">
                      বাংলা
                    </label>
                  </div>
                  <div className="form-check me-3">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="english"
                      id=""
                      checked={applicationFormTwo?.certificate === "2"}
                      readOnly
                    />
                    <label className="form-check-label" htmlFor="">
                      ইংরেজি
                    </label>
                  </div>
                </span>
              </div>

              {applicationFormTwo?.certificate === "1" && (
                <div className="col-md-12 mt-2">
                  <div className="table-responsive">
                    <table className="table text-center table-striped mb-2 table-bordered hover">
                      <thead>
                        <tr>
                          <th>কর্মটির শিরোনাম</th>
                          <th>
                            {applicationFormTwo?.workPublishtype === 1
                              ? "প্রকাশনার বছর"
                              : "সম্পন্নের বছর"}
                          </th>
                          <th>লেখকের নাম</th>
                          <th>লেখকের ঠিকানা</th>
                        </tr>
                      </thead>
                      <tbody>
                        {applicationFormTwo?.writerDetails?.map(
                          (item, index) => (
                            <tr key={index}>
                              <td>
                                {index === 0
                                  ? applicationFormTwo?.workTitle
                                  : ""}
                              </td>

                              <td>
                                {" "}
                                {index === 0
                                  ? applicationFormTwo?.pub_unpub_year
                                  : ""}
                              </td>
                              <td>{item?.writerName}</td>
                              <td>{item?.writerAddress}</td>
                            </tr>
                          )
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              )}

              {applicationFormTwo?.certificate === "2" && (
                <div className="col-md-12 mt-2">
                  <div className="table-responsive">
                    <table className="table text-center table-striped mb-2 table-bordered hover">
                      <thead>
                        <tr>
                          <th>Title of the work</th>
                          <th>Year of Completion</th>
                          <th>Author / Creator</th>
                          <th>Owner of the rights</th>
                          <th>Address</th>
                        </tr>
                      </thead>
                      <tbody>
                        {applicationFormTwo?.certificateLangs?.map(
                          (item, index) => (
                            <tr key={index}>
                              {index === 0 && (
                                <>
                                  <td
                                    rowSpan={
                                      applicationFormTwo.certificateLangs.length
                                    }
                                  >
                                    {applicationFormTwo?.work_title}
                                  </td>
                                  <td
                                    rowSpan={
                                      applicationFormTwo.certificateLangs.length
                                    }
                                  >
                                    {applicationFormTwo?.pub_unpub_year}
                                  </td>
                                </>
                              )}
                              <td>{item?.author_creator}</td>
                              <td>{item?.owner_right}</td>
                              <td>{item?.address}</td>
                            </tr>
                          )
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              )}
            </div>

            <div className="row mt-4">
              <div class="col-md-12 m-auto text-center">
                <p className="mb-0 unbold">
                  <input
                    type="checkbox"
                    className="me-2"
                    checked={Number(data?.form_status) === 2}
                  />
                  আমি / আমরা ঘোষণা করছি যে, কপিরাইট রেজিস্ট্রেশনের আবেদনে আমার
                  পূরণকৃত সকল তথ্য ও সংযুক্ত দলিলসমূহ সত্য ও নির্ভুল এবং এ
                  কর্মটিকে কেন্দ্র করে কোন মামলা কোন বিজ্ঞ আদালত বা
                  ট্রাইব্যুনালে বিচারাধীন নেই । উল্লিখিত তথ্য বা দলিলে কোন ভুল ও
                  অসত্য তথ্য উদ্‌ঘাটিত হলে আমি / আমরা এর জন্য ব্যক্তিগতভাবে দায়ী
                  থাকব এবং দেশের প্রচলিত আইন অনুযায়ী যেকোন দন্ড গ্রহণে বাধ্য হবো
                  ।
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <ShowFilePreviewModal
        setPreviewModal={setPreviewModal}
        previewModal={previewModal}
        previewUrl={previewFile}
        setPreviewUrl={setPreviewFile}
      />
    </div>
  );
};

export default CopyrightPreviewFormTwo;
