import React from "react";
import SubmittedAgreement from "../../sumitted_agreement/SubmittedAgreement";

const RecordedAgreementApplication = () => {
  const fileStatus = 19;
  const applicationPath = {
    copyright: "/admin/recorded-application-copyright",
    agreement: "/admin/recorded-application-agreement",
  };
  const detailsRoute = "/admin/recorded-agreement-details";
  const pageTitle = "চূড়ান্তভাবে নথিজাত";
  return (
    <SubmittedAgreement
      fileStatus={fileStatus}
      applicationPath={applicationPath}
      detailsRoute={detailsRoute}
      pageTitle={pageTitle}
    />
  );
};

export default RecordedAgreementApplication;
