import { useFormik } from "formik";
import React, { useEffect } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import toast from "react-hot-toast";
import { BeatLoader } from "react-spinners";
import Swal from "sweetalert2";
import * as Yup from "yup";
import { useRestoreSentApplicationMutation } from "../../redux/api/backendUtilsApiSlice";
import { transformErrorsToObjectStructure } from "../../utils/transformErrorsToObjectStructure";

const RestoreApplication = ({ open, onClose, data, appTitle }) => {
  const [
    restoreApplication,
    {
      isLoading,
      isSuccess: isSuccessSubmit,
      error: errorSubmit,
      isError: isErrorSubmit,
      data: dataSubmit,
    },
  ] = useRestoreSentApplicationMutation();

  const validationSchema = Yup.object().shape({
    comments: Yup.string().required("মন্তব্য প্রযোজ্য।"),
    user_id: Yup.number().required("প্রাপক প্রযোজ্য।"),
  });

  const pageAleart = "আপনি সংশোধনের জন্য পাঠানো আবেদন পুনরুদ্ধার করতে চান?";

  const formik = useFormik({
    initialValues: {
      user_id: data?.sendUserId,
      comments: "",
    },
    validationSchema,
    onSubmit: (values) => {
      const formData = values;
      Swal.fire({
        text: pageAleart,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "না",
        confirmButtonText: "হ্যাঁ",
      }).then((result) => {
        if (result.isConfirmed) {
          restoreApplication({ id: data?.id, formData });
        }
      });
    },
    enableReinitialize: true,
  });

  useEffect(() => {
    if (isSuccessSubmit) {
      toast.success(dataSubmit?.message);
      formik.resetForm();
      onClose();
    }
    if (isErrorSubmit) {
      toast.error(errorSubmit?.data?.message);
      formik.setErrors(
        transformErrorsToObjectStructure(errorSubmit.data?.data || {})
      );
    }
    // eslint-disable-next-line
  }, [isErrorSubmit, isSuccessSubmit]);

  return (
    <Modal show={open} onHide={onClose} size="lg">
      <Modal.Header closeButton>
        <Modal.Title className="fs-6">
          {appTitle} - আবেদন পুনরুদ্ধার
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <>
          <div>
            <div className="">
              <div className="d-flex align-items-center gap-3 pb-2">
                <label htmlFor="">মন্তব্য</label>
              </div>
              <>
                <Form.Control
                  name="comments"
                  placeholder="এখানে লিখুন"
                  aria-label="Username"
                  aria-describedby="basic-addon1"
                  as={"textarea"}
                  rows={6}
                  value={formik.values.comments}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  isInvalid={formik.touched.comments && formik.errors.comments}
                />
                {formik.touched.comments && formik.errors.comments ? (
                  <div className="text-danger">{formik.errors.comments}</div>
                ) : null}
              </>
            </div>
          </div>
        </>
      </Modal.Body>
      <Modal.Footer>
        <div className="d-flex justify-content-between w-100">
          <Button variant="secondary" onClick={onClose}>
            বন্ধ করুন
          </Button>
          <div>
            <Button
              variant="success"
              onClick={formik.handleSubmit}
              disabled={isLoading}
            >
              {isLoading ? <BeatLoader /> : "দাখিল করুন"}
            </Button>
          </div>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default RestoreApplication;
