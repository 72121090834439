import { useFormik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import toast from "react-hot-toast";
import { ImCross } from "react-icons/im";
import { IoMdEye } from "react-icons/io";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { BeatLoader } from "react-spinners";
import FilePreviewModal from "../../../../../components/ApllicantionFilePreview";
import Loader from "../../../../../components/custom/Loader";
import ClientFormikFormFieldMultiple from "../../../../../components/custom/form/ClientFormikFormFieldMultiple";
import {
  useShowCorrectionAgreementFirstPartQuery,
  useUpdateCorrectionAgreementFirstPartMutation,
} from "../../../../../redux/api/correctionFormApiSlice";
import { useAuth } from "../../../../../utils/authorization";
import { localDateFormat } from "../../../../../utils/dateFormat";
import { createFormData } from "../../../../../utils/formDataTransform";
import ShowFilePreviewModal from "../../../userPanel/form_two/ShowPreviewFiles";
import styles from "./ContractCopy.module.css";
import {
  initialValues,
  validationSchema,
  validationSchemaEdit,
} from "./validation/ContractOne";
import Swal from "sweetalert2";

export default function CorrectionAgreementFirstPart() {
  const { authUser } = useAuth();
  const [previewModal, setPreviewModal] = useState(false);
  const [previewModalOld, setPreviewModalOld] = useState(false);
  const [previewFile, setPreviewFile] = useState("");
  const [signature, setSignature] = useState(null);

  const publicUrl = process.env.PUBLIC_URL;
  const { id } = useParams();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const trackReg = query.get("trackReg");

  const {
    data: showData,
    isLoading: isLoadingShow,
    isError,
  } = useShowCorrectionAgreementFirstPartQuery({ id, trackReg });

  const navigate = useNavigate();

  const [
    updateAgreement,
    {
      isLoading: isLoadingUpdate,
      error: errorUpdate,
      isError: isErrorUpdate,
      data: dataUpdate,
      isSuccess: isSuccessUpdate,
    },
  ] = useUpdateCorrectionAgreementFirstPartMutation();

  const isCorrection = showData?.data?.isCorrection.split(",") || [];

  let button_title;
  if (
    isCorrection.includes("2") ||
    isCorrection.includes("3") ||
    isCorrection.includes("4")
  ) {
    button_title = "সংরক্ষণ করে অগ্রসর হউন";
  } else {
    button_title = "দাখিল";
  }

  /// create a new agreement
  const {
    values,
    touched,
    errors,
    handleBlur,
    handleChange,
    handleSubmit,
    setValues,
    setFieldValue,
    setErrors,
  } = useFormik({
    initialValues,
    validationSchema: showData?.data?.id
      ? validationSchemaEdit
      : validationSchema,
    onSubmit: async (values, { setSubmitting }) => {
      try {
        const formData = createFormData(values);
        formData.append("id", showData?.data?.id);
        formData.append("user_id", showData?.data?.user?.id);
        formData.append("trackReg", showData?.data?.trackReg);
        if (
          !isCorrection.includes("2") &&
          !isCorrection.includes("3") &&
          !isCorrection.includes("4")
        ) {
          formData.append("submit", 2);
          formData.append("submit", 2);
          const result = await Swal.fire({
            text: "আপনি কি দাখিল দিতে চান ?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            cancelButtonText: "না",
            confirmButtonText: "হ্যাঁ",
          });

          if (!result.isConfirmed) {
            setSubmitting(false);
            return; // Exit if the user cancels
          }
        }
        await updateAgreement({ id: showData?.data?.id, formData });
      } catch (error) {
        setSubmitting(false);
      }
    },
  });

  useEffect(() => {
    if (showData) {
      setValues((prev) => {
        return {
          ...prev,
          teamType: Number(showData?.data?.teamType),
          place: showData?.data?.place,
          puname: showData?.data?.puname,
        };
      });
    }
  }, [showData]);

  const fileInputRefs = useRef([]);

  const handleFileChange = (field, e) => {
    const file = e.currentTarget.files[0];
    if (file) {
      setFieldValue(field, file);
      if (field === "sign") {
        setSignature(file);
      }
    }
  };

  const handleDynamicFileRemove = (field, index) => {
    setFieldValue(field, null);
    if (fileInputRefs.current[index]) {
      fileInputRefs.current[index].value = "";
    }
  };

  useEffect(() => {
    if (isErrorUpdate) {
      setErrors(errorUpdate?.data?.data || {});
      toast.error(errorUpdate?.data?.message);
    }
    if (isSuccessUpdate) {
      if (isCorrection.includes("4") || isCorrection.includes("2")) {
        navigate(
          `/correction-agreement-second-part/${id}?trackReg=${trackReg}`
        );
      } else if (isCorrection.includes("3")) {
        navigate(`/correction-payment/${id}?trackReg=${trackReg}`);
      } else {
        navigate(`/success-correction/1`, {
          replace: true,
        });
      }
      toast.success(dataUpdate?.message);
    }
  }, [isErrorUpdate, isSuccessUpdate]);

  const toDay = localDateFormat(new Date());

  const handleSwitch = (val) => {
    setValues((prevValues) => ({
      ...prevValues,
      teamType: val,
    }));
  };

  if (isError) {
    navigate(`/page-not-found`, {
      replace: true,
    });
  }

  if (isLoadingShow) {
    return <Loader />;
  }
  return (
    <div>
      <section className="section py-5 copyrightformone crossiconshowicon service-single">
        <div className="container">
          <div className="d-flex justify-content-center align-items-center">
            <div className="card shadow border-0 w-100">
              <div
                className="tab-content rounded border py-4 paddddingnone px-5 border-1"
                id="nav-tabContent"
              >
                <div className="text-end me-4">
                  <div className="">
                    {" "}
                    <img
                      src={`${publicUrl}/assets/images/p1.png`}
                      alt="Logo"
                      style={{
                        height: "70px",
                        width: "60px",
                        marginTop: "-47px",
                      }}
                      className="img-fluid pataresponsive"
                    />{" "}
                  </div>
                </div>

                <div
                  className="tab-pane fade active show"
                  id="nav-application"
                  role="tabpanel"
                  aria-labelledby="nav-application-tab"
                >
                  <div className="applicationForm">
                    <div
                      style={{ marginTop: "-20px" }}
                      className="applicationForm-header text-center"
                    >
                      <h5 className="mb-0 form2fontsizeee">ফরম - ১.</h5>
                      <h5 className="mt-1 form2fontsizeee">
                        মেধাস্বত্ব হস্তান্তর / লাইসেন্স প্রদান সংক্রান্ত দলিল
                        রেজিস্ট্রেশন ফরম
                      </h5>
                    </div>

                    <div className="applicationForm-body">
                      <div className="applicationForm-body-top">
                        <div className="d-flex justify-content-between">
                          <div className="div">
                            <p className="mb-0 mt-2">
                              <b>বরাবর</b>
                            </p>
                            <p className="unbold">
                              রেজিস্ট্রার অব কপিরাইটস <br />
                              বাংলাদেশ কপিরাইট অফিস।
                            </p>
                          </div>
                        </div>

                        <p className="mb-0">
                          <b>জনাব,</b>
                        </p>
                        <p className="unbold">
                          কপিরাইট আইন (২০২৩ সালের ৩৪ নং আইন এবং সংশোধিত- ২০০৫)
                          এর ৫৪ ধারা অনুযায়ী আমি মেধাস্বত্ব হস্তান্তর / লাইসেন্স
                          প্রদান সংক্রান্ত দলিল নিবন্ধনের জন্য আবেদন করছি এবং
                          দলিল নিবন্ধন বহিতে হস্তান্তরিত কর্ম সংক্রান্ত তথ্যসমূহ
                          লিপিবদ্ধ করার জন্য দুইকপি সংশ্লিষ্ট কর্ম ও বিবরণী
                          সংযুক্ত করছি।
                        </p>

                        <div className="row mb-2">
                          <div className="col-md-2 mt-2 mtop0 col-sm-12 col-12">
                            <label>১. আবেদন কারীর ধরনঃ </label>
                          </div>
                          <div className="col-md-10 my-1 col-sm-12 col-12">
                            <span className="d-flex ptop0 pt-1">
                              <div className="form-check form-check-inline">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="sideRadio"
                                  id="sideRadio1"
                                  checked={values.teamType === 1}
                                  onClick={() => handleSwitch(1)}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="sideRadio1"
                                >
                                  স্বত্বনিয়োগ কর্ম
                                </label>
                              </div>
                              <div className="form-check form-check-inline">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="sideRadio"
                                  id="sideRadio2"
                                  checked={values.teamType === 2}
                                  onClick={() => handleSwitch(2)}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="sideRadio2"
                                >
                                  লাইসেন্স প্রদান কর্ম
                                </label>
                              </div>
                            </span>
                          </div>

                          <div className="col-12">
                            {errors.teamType && touched.teamType && (
                              <small className="my-2 text-danger">
                                {errors.teamType}
                              </small>
                            )}
                          </div>
                        </div>

                        <label>২. সংযুক্তি </label>
                        <div className="row">
                          <div className="col-md-12">
                            <div
                              className={`${styles.attachment} rounded mt-2 mb-4`}
                            >
                              <div className="row mx">
                                {[
                                  {
                                    label:
                                      "স্বত্বনিয়োগকারী / লাইসেন্স প্রদান কারীর ছবি ( আবশ্যিক )",
                                    field: "applicant",
                                  },
                                  {
                                    label:
                                      "জাতীয় পরিচয় পত্র/জন্ম নিবন্ধন/পাসপোর্টের স্ক্যান কপি ( আবশ্যিক )",
                                    field: "nid",
                                  },
                                  {
                                    label:
                                      "দলিলের মূলকপি ও নোটারি পাবলিক দ্বারা সত্যায়িত ফটোকপি ( আবশ্যিক )",
                                    field: "istructure",
                                  },
                                  {
                                    label:
                                      "ট্রেড লাইসেন্সের স্ক্যান কপি ( ব্যবসায়িক প্রতিষ্ঠানের ক্ষেত্রে প্রযোজ্য )",
                                    field: "tradelicense",
                                  },
                                  {
                                    label:
                                      "টিন সার্টিফিকেটের স্ক্যান কপি ( প্রতিষ্ঠানের ক্ষেত্রে প্রযোজ্য )",
                                    field: "tincertificate",
                                  },
                                ].map(({ label, field }, index) => (
                                  <div key={field} className="col-md-4 my-3">
                                    <div
                                      className={`${styles.add2FormGroupImagePreviewBox} form-group imgmffargin text-center`}
                                    >
                                      <div className="form-group text-center">
                                        <label>{label}</label>
                                        <br />
                                        <div
                                          className={`${styles.imagePreviewAdd2}`}
                                        >
                                          {values[field] ||
                                          showData?.data?.[field] ? (
                                            <span>
                                              <div className="d-flex">
                                                <div
                                                  className="showiconsss"
                                                  onClick={(e) => {
                                                    e.stopPropagation();
                                                    if (values[field]) {
                                                      setPreviewModal(true);
                                                      setPreviewFile(
                                                        values[field]
                                                      );
                                                    } else {
                                                      setPreviewModalOld(
                                                        showData?.data[field]
                                                          ? true
                                                          : false
                                                      );
                                                      setPreviewFile(
                                                        showData?.data[field]
                                                      );
                                                    }
                                                  }}
                                                >
                                                  <IoMdEye
                                                    className={`${styles.showiconsss}`}
                                                  />
                                                </div>

                                                {values[field] && (
                                                  <div
                                                    className="removesssIconn"
                                                    onClick={(e) => {
                                                      e.stopPropagation();
                                                      handleDynamicFileRemove(
                                                        field,
                                                        index
                                                      );
                                                    }}
                                                  >
                                                    <ImCross
                                                      className={`${styles.removesssIconn}`}
                                                    />
                                                  </div>
                                                )}
                                              </div>
                                            </span>
                                          ) : (
                                            <span className="fontsize18">
                                              - - - - -
                                            </span>
                                          )}
                                        </div>

                                        <div>
                                          {errors[field] && touched[field] && (
                                            <small className="my-2 text-danger">
                                              {errors[field]}
                                            </small>
                                          )}
                                        </div>

                                        <label className="custombtnn5">
                                          <input
                                            type="file"
                                            ref={(el) =>
                                              (fileInputRefs.current[index] =
                                                el)
                                            }
                                            className="d-none"
                                            accept=".png,.jpg,.jpeg,.pdf"
                                            onChange={(e) =>
                                              handleFileChange(field, e)
                                            }
                                          />
                                          {values[field]
                                            ? "পরিবর্তন করুন"
                                            : "নির্বাচন করুন"}
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                ))}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="applicationForm-body-bottom">
                        <div className="row">
                          <div className="col-md-4">
                            <div
                              className={`${styles.applicationUserInfoPartLeft} mt-10`}
                            >
                              <div className="form-group">
                                <input
                                  type="email"
                                  defaultValue={authUser?.email}
                                  disabled
                                  className="custom-input-field"
                                />
                              </div>
                              <div className="form-group">
                                <ClientFormikFormFieldMultiple
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  title="স্থান"
                                  placeholder="স্থানের নাম লিখুন"
                                  value={values.place}
                                  name={"place"}
                                  errors={errors?.place}
                                  touched={touched?.place}
                                  handleChange={handleChange}
                                  handleBlur={handleBlur}
                                />
                              </div>
                              <div className="form-group pt-3">
                                <input
                                  type="text"
                                  className="custom-input-field"
                                  value={toDay}
                                  disabled
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-md-4"></div>

                          <div className="col-md-4">
                            <div
                              className={`${styles.applicationUserInfoPartRight}`}
                            >
                              <div className="text-center">
                                <p className="mb-0 mt-1">
                                  আপনার বিশ্বস্ত :{" "}
                                  <span className="text-danger">*</span>{" "}
                                </p>
                              </div>

                              <div className="form-group">
                                <ClientFormikFormFieldMultiple
                                  placeholder="নাম লিখুন"
                                  value={values.puname}
                                  name={"puname"}
                                  errors={errors?.puname}
                                  touched={touched?.puname}
                                  handleChange={handleChange}
                                  handleBlur={handleBlur}
                                />
                              </div>

                              <div className="form-group text-center">
                                <label htmlFor="signatureUpload">
                                  স্বাক্ষর (200px * 80px)
                                </label>
                                <br />
                                <div className={styles.imagePreviewSignature}>
                                  {signature ? (
                                    <img
                                      src={URL.createObjectURL(signature)}
                                      id="Signature"
                                      alt="Signature Preview"
                                    />
                                  ) : showData?.data?.sign ? (
                                    <img
                                      src={showData?.data?.sign}
                                      id="Signature"
                                      alt="Signature Preview"
                                    />
                                  ) : (
                                    <p className="text-muted"></p>
                                  )}
                                </div>

                                <div>
                                  {touched.sign && errors.sign && (
                                    <small
                                      className="text-danger mt-0"
                                      style={{ fontSize: "13px" }}
                                    >
                                      {errors.sign}
                                    </small>
                                  )}
                                </div>

                                <label
                                  htmlFor="signatureUpload"
                                  className="custombtnn5"
                                >
                                  <input
                                    type="file"
                                    id="signatureUpload"
                                    className="d-none"
                                    name="sign"
                                    onChange={(e) =>
                                      handleFileChange("sign", e)
                                    }
                                    accept=".jpg, .jpeg, .png"
                                  />
                                  {signature || values?.sign
                                    ? "পরিবর্তন করুন"
                                    : "নির্বাচন করুন"}
                                </label>
                              </div>
                            </div>
                          </div>

                          <div className="applicationForm-body-footer text-center mt-5">
                            <button
                              className="custombtn5"
                              type="submit"
                              onClick={handleSubmit}
                              disabled={isLoadingUpdate}
                            >
                              {isLoadingUpdate ? (
                                <BeatLoader color="#fff" />
                              ) : (
                                <>{button_title}</>
                              )}
                            </button>
                          </div>
                        </div>
                      </div>

                      {previewModal && (
                        <FilePreviewModal
                          setPreviewModal={setPreviewModal}
                          previewModal={previewModal}
                          previewFile={previewFile}
                          setPreviewFile={setPreviewFile}
                        />
                      )}
                      {previewModalOld && (
                        <ShowFilePreviewModal
                          setPreviewModal={setPreviewModalOld}
                          previewModal={previewModalOld}
                          previewUrl={previewFile}
                          setPreviewUrl={setPreviewFile}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
