import React from "react";
import SubmittedAgreement from "../../sumitted_agreement/SubmittedAgreement";

const RecordedAgreement = () => {
  const fileStatus = 27;
  const applicationPath = {
    copyright: "/admin/recorded-copyright",
    agreement: "/admin/recorded-agreement",
  };
  
  // Add route to details page
  const detailsRoute = "/admin/recorded-agreement-details";
  const pageTitle  = "নথিজাত নথি";

  return (
    <SubmittedAgreement
      fileStatus={fileStatus}
      applicationPath={applicationPath}
      detailsRoute={detailsRoute}
      pageTitle={pageTitle}
    />
  );
};

export default RecordedAgreement;
