import { Print } from "@mui/icons-material";
import { useFormik } from "formik";
import JoditEditor from "jodit-react";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { Button, Form } from "react-bootstrap";
import toast from "react-hot-toast";
import { Link, useNavigate, useParams } from "react-router-dom";
import Select from "react-select";
import ReactToPrint from "react-to-print";
import Swal from "sweetalert2";
import * as Yup from "yup";
import { assets } from "../../assets";
import BanglaCalender from "../../pages/frontend/userPanel/form_two/BanglaCalender";
import { useLazyGetByRoleQuery } from "../../redux/api/applicationCopyrightApiSlice";
import { useGetRoleInQuery } from "../../redux/api/categoryApiSlice";
import { useCorrectionLetterApprovalMutation } from "../../redux/api/submittedCopyrightAgreementApiSlice";
import { useAuth } from "../../utils/authorization";
import { config } from "../../utils/config";
import {
  globalDateFormat,
  localDateFormat,
  localDateFormatName,
  localDateFormatNameBN,
  localTimeFormat,
} from "../../utils/dateFormat";
import { transformErrorsToObjectStructure } from "../../utils/transformErrorsToObjectStructure";

const LetterCorrectionContent = ({ info }) => {
  const [applicationParts, setApplicationParts] = useState([]);
  const [isEdit, setIsEdit] = useState(false);
  const { authUser } = useAuth();
  const { id } = useParams();
  const navigate = useNavigate();
  const [content, setContent] = useState("");
  const [errors, setErrors] = useState(false);
  const editor = useRef(null);
  const printLetter = useRef();
  const [
    Verify,
    {
      isLoading: isLoadingVerify,
      isSuccess: isSuccessSubmit,
      error: errorSubmit,
      isError: isErrorSubmit,
      data: dataSubmit,
    },
  ] = useCorrectionLetterApprovalMutation();

  const roleMapping = "[2, 4, 5, 11]";

  const { data: roles } = useGetRoleInQuery(roleMapping);
  const rolesOption =
    roles?.data?.map((data) => ({
      value: data.id,
      label: data.bn_name,
    })) || [];

  const [findUser, { data: users }] = useLazyGetByRoleQuery();

  const usersOption =
    (users?.data &&
      Object.entries(users.data)
        .filter(([key]) => authUser.id !== Number(key))
        .map(([key, value]) => ({
          value: Number(key),
          label: value,
        }))) ||
    [];

  const validationSchema = Yup.object().shape({
    record_no: Yup.string().required("রেকর্ড নম্বর প্রযোজ্য।"),
    record_date: Yup.date().required("রেকর্ড তারিখ প্রযোজ্য।"),
    subject: Yup.string().required("বিষয় প্রযোজ্য।"),
    role_id: Yup.number().required("পদবী প্রযোজ্য।"),
    user_id: Yup.number().required("প্রাপক প্রযোজ্য।"),
    // applicationParts: Yup.array().min(
    //   1,
    //   "কমপক্ষে একটি চেকবক্স নির্বাচন করতে হবে।"
    // ),
    applicationParts: Yup.array().when("$fileStatus", {
      is: (val) => val === 22,
      then: () => Yup.array().min(1, "কমপক্ষে একটি চেকবক্স নির্বাচন করতে হবে।"),
      otherwise: () => Yup.array().nullable(),
    }),
  });

  const pageAleart =
    info.fileStatus === 7
      ? "আপনি কি চিঠি অনুমোদনের জন্য পাঠাতে চান?"
      : info.fileStatus === 21
      ? "আপনি কি চিঠি অনুমোদন দিতে চান?"
      : "আপনি কি চিঠি প্রেরণ করতে চান?";

  const formik = useFormik({
    initialValues: {
      record_no: "",
      record_date: globalDateFormat(new Date()),
      subject: "",
      correction: "",
      role_id: "",
      user_id: "",
      applicationParts: [],
    },
    validationSchema,
    context: { fileStatus: info.fileStatus },
    onSubmit: (values) => {
      if (!content) {
        return setErrors("প্রযোজ্য।");
      }
      setErrors("");
      const formData = { ...values, correction: content };
      Swal.fire({
        text: pageAleart,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "না",
        confirmButtonText: "হ্যাঁ",
      }).then((result) => {
        if (result.isConfirmed) {
          Verify({ id, formData, fileStatus: info.fileStatus });
        }
      });
    },
  });
  useEffect(() => {
    if (isSuccessSubmit) {
      toast.success(dataSubmit?.message);
      formik.resetForm();
      navigate(-1);
    }
    if (isErrorSubmit) {
      toast.error(errorSubmit?.data?.message);
      formik.setErrors(
        transformErrorsToObjectStructure(errorSubmit.data?.data || {})
      );
    }
    // eslint-disable-next-line
  }, [isErrorSubmit, isSuccessSubmit]);

  useEffect(() => {
    if (info?.correction?.correction) {
      if (info?.correction?.user?.role?.id) {
        findUser(info?.correction?.user?.role?.id);
      }
      const sub = `${info.workTitle} নামক ${info.category} কর্মের কপিরাইট রেজিস্ট্রেশন সংশোধন ।`;
      formik.setValues((prev) => ({
        ...prev,
        record_no: info?.correction?.record_no,
        record_date:
          info?.correction?.record_date ?? globalDateFormat(new Date()),
        subject: info?.correction?.subject ?? sub,
        role_id: info?.correction?.user?.role?.id ?? "",
        user_id: info?.correction?.user?.id ?? "",
      }));
      setContent(info?.correction?.correction);
    } else {
      if (info?.correction?.user?.role?.id) {
        findUser(info?.correction?.user?.role?.id);
      }
      const sub =
        info.fileStatus === 7
          ? `${info.workTitle} নামক ${info.category} কর্মের কপিরাইট রেজিস্ট্রেশন সংশোধন ।`
          : "";
      formik.setValues((prev) => ({
        ...prev,
        subject: info?.correction?.subject ?? sub,
        role_id: info?.correction?.user?.role?.id ?? "",
        user_id: info?.correction?.user?.id ?? "",
      }));
      setIsEdit(true);
    }
  }, [info?.correction]);

  const defaultValue = {
    value: "",
    label: "নির্বাচন করুন",
  };

  const handleEditorChange = useCallback((newContent) => {
    setContent(newContent);
  }, []);

  const isAuthorized = [2, 4, 11, 3].includes(authUser.role_id);
  const user = info?.correction?.user;

  const pageTitle =
    info.fileStatus === 22
      ? "সংশোধনের চিঠি প্রেরণ"
      : info.fileStatus === 7
      ? "সংশোধনের চিঠি প্রস্তুত"
      : "সংশোধনের চিঠি অনুমোদন";

  const applicationPart =
    info?.st === 2
      ? [
          { label: "১ম অংশ", value: 1 },
          { label: "২য় অংশ", value: 2 },
          { label: "পেমেন্ট ( ট্রেজারি )", value: 3 },
        ]
      : [
          { label: "১ম অংশ", value: 1 },
          { label: "২য় অংশ", value: 2 },
        ];

  const handleChangeCheck = (e) => {
    const value = parseInt(e.target.value);
    const isChecked = e.target.checked;

    setApplicationParts((prev) => {
      if (value === 4) {
        // If "select all" is checked/unchecked
        if (isChecked) {
          // If selecting all, include all parts
          return applicationPart.map((item) => item.value);
        } else {
          // If deselecting all, return empty array
          return [];
        }
      } else {
        // For individual checkboxes
        if (isChecked) {
          // Add individual part if checked
          return [...prev, value];
        } else {
          // Remove individual part if unchecked
          return prev.filter((item) => item !== value);
        }
      }
    });
  };

  useEffect(() => {
    formik.setFieldValue("applicationParts", []);
    formik.setFieldValue("applicationParts", applicationParts);
  }, [applicationParts]);

  return (
    <>
      <div className="d-flex justify-content-between align-items-center bg-slate p-2">
        <div className="fw-semibold">{pageTitle}</div>
        <ReactToPrint
          trigger={() => <Print className="fs-4 text-primary cursor-pointer" />}
          content={() => printLetter.current}
        />
      </div>
      {info.fileStatus === 7 && (
        <div className="card p-2 mt-2">
          <div>
            <label htmlFor="">সংশোধনের আদেশ</label>
            <p>{info?.correction?.comment}</p>
          </div>
          <div className="d-flex justify-content-end">
            <div className="text-center">
              <div className="fw-semibold">আদেশক্রমে</div>
              <p className="m-0">{info?.correction?.user?.name}</p>
              <p className="m-0">{info?.correction?.user?.role?.bnName}</p>
              <div>তারিখ : {localDateFormat(info?.correction?.date)}</div>
              <div>সময় : {localTimeFormat(info?.correction?.date)}</div>
            </div>
          </div>
        </div>
      )}
      {info.fileStatus === 21 && (
        <div className="card p-2 mt-2">
          <span className="fw-semibold">হইতে, </span>{" "}
          {info?.correction?.sendBy.name} (
          {info?.correction?.sendBy?.role?.bnName})
        </div>
      )}

      <form onSubmit={formik.handleSubmit}>
        <div className="py-2" ref={printLetter}>
          <div className="card p-3">
            <div ref={printLetter}>
              <div className="text-center">
                <img
                  className="my-3"
                  src={assets.logoBlack}
                  style={{ width: "350px" }}
                  alt=""
                />
                <p className="m-0">কপিরাইট অফিস, সংস্কৃতি বিষয়ক মন্ত্রণালয়</p>

                <p className="m-0">
                  জাতীয় গ্রন্থাগার ভবন, আগারগাঁও, শেরেবাংলা নগর, ঢাকা।
                </p>

                <p className="m-0">
                  ওয়েবসাইট :{" "}
                  <Link target="_blank" to={"//www.copyrightoffice.gov.bd"}>
                    www.copyrightoffice.gov.bd
                  </Link>
                </p>
              </div>
              <div
                className={`d-flex justify-content-between py-3 mt-2 align-items-center`}
              >
                <div
                  className={`d-flex gap-2 ${!isEdit && "align-items-center "}`}
                >
                  {!isEdit ? (
                    <> {formik.values.record_no}</>
                  ) : (
                    <span>
                      {" "}
                      <Form.Control
                        placeholder="নথি / স্বারক / রেজিঃ নং "
                        value={formik.values.record_no}
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        errors={formik.errors}
                        touched={formik.touched}
                        name="record_no"
                      />
                      {formik.errors.record_no && formik.touched.record_no && (
                        <span className="text-danger">
                          {formik.errors.record_no}
                        </span>
                      )}
                    </span>
                  )}
                </div>
                <div
                  className={`d-flex gap-3 ${!isEdit && "align-items-center "}`}
                >
                  <div
                    className={`d-flex gap-3 ${isEdit && "mt-4"} fw-semibold`}
                  >
                    তারিখঃ
                  </div>
                  <div>
                    {!isEdit ? (
                      <>
                        {localDateFormatNameBN(formik.values.record_date)}
                        <hr className="m-0" />
                        {localDateFormatName(formik.values.record_date)}
                      </>
                    ) : (
                      <>
                        {localDateFormatNameBN(formik.values.record_date)}
                        <BanglaCalender
                          placeholder={"তারিখ"}
                          value={formik.values.record_date}
                          onChange={(val) => {
                            formik.setFieldValue(
                              "record_date",
                              globalDateFormat(val)
                            );
                          }}
                        />
                        {formik.errors?.record_date ? (
                          <span className="text-danger">
                            {formik.errors.record_date}
                          </span>
                        ) : (
                          <div style={{ height: "24px" }}></div>
                        )}
                      </>
                    )}
                  </div>
                </div>
              </div>
              <div>
                <p className={`fw-semibold mt-4 w-100 d-flex gap-2`}>
                  <span className={`${isEdit && "mt-2"} `}>বিষয়ঃ </span>
                  {!isEdit ? (
                    <> {formik.values.subject}</>
                  ) : (
                    <div className="w-100">
                      {" "}
                      <Form.Control
                        value={formik.values.subject}
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        errors={formik.errors}
                        touched={formik.touched}
                        name="subject"
                      />
                      {formik.errors.subject && formik.touched.subject && (
                        <span className="text-danger">
                          {formik.errors.subject}
                        </span>
                      )}
                    </div>
                  )}
                </p>
              </div>
              <p className="pb-4" style={{ textAlign: "justify" }}>
                {!isEdit ? (
                  <p
                    className="m-0"
                    dangerouslySetInnerHTML={{ __html: content }}
                  ></p>
                ) : (
                  <>
                    <JoditEditor
                      ref={editor}
                      value={content}
                      config={config}
                      tabIndex={1}
                      onBlur={handleEditorChange}
                    />
                    {errors ? (
                      <span className="text-danger">{errors}</span>
                    ) : (
                      <div style={{ height: "24px" }}></div>
                    )}
                  </>
                )}
              </p>
              <div className="d-flex justify-content-between my-2">
                <div>
                  <div className="mt-3">
                    {info.name && (
                      <>
                        <p className="m-0">{info?.name}</p>
                        <p className="m-0">{info?.email}</p>
                        <p className="m-0">{info?.address}</p>
                      </>
                    )}
                  </div>
                </div>
                <div>
                  <div className="mt-3 text-end">
                    {(isAuthorized || info.fileStatus === 22) && (
                      <>
                        {user?.sign && (
                          <>
                            <img
                              style={{ height: "40px" }}
                              src={user?.sign}
                              alt=""
                            />
                          </>
                        )}
                        <p className="m-0">{user?.name}</p>
                        <p className="m-0">{user?.role?.bnName}</p>
                        <p className="m-0">{user?.email}</p>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {!isAuthorized && info.fileStatus !== 22 && (
          <div className="row">
            <div className="col-md-6">
              <label className="my-2 fw-semibold" htmlFor="role_id">
                পদবী
              </label>
              <Select
                name="role_id"
                options={rolesOption}
                value={
                  formik.values.role_id
                    ? rolesOption.find(
                        (option) => option.value === formik.values.role_id
                      )
                    : defaultValue
                }
                onChange={(selectedOption) => {
                  findUser(selectedOption.value);
                  formik.setValues((prev) => {
                    return {
                      ...prev,
                      user_id: "",
                    };
                  });
                  formik.setFieldValue("role_id", selectedOption.value);
                }}
                onBlur={formik.handleBlur}
                classNamePrefix="react-select"
              />
              {formik.touched.role_id && formik.errors.role_id ? (
                <div className="text-danger">{formik.errors.role_id}</div>
              ) : null}
            </div>
            <div className="col-md-6">
              <label className="my-2 fw-semibold" htmlFor="user_id">
                প্রাপক
              </label>
              <Select
                name="user_id"
                options={usersOption}
                value={
                  formik.values.user_id
                    ? usersOption.find(
                        (option) => option.value === formik.values.user_id
                      )
                    : defaultValue
                }
                onChange={(selectedOption) =>
                  formik.setFieldValue("user_id", selectedOption.value)
                }
                onBlur={formik.handleBlur}
                classNamePrefix="react-select"
              />
              {formik.touched.user_id && formik.errors.user_id ? (
                <div className="text-danger">{formik.errors.user_id}</div>
              ) : null}
            </div>
          </div>
        )}
        {info.fileStatus === 22 && (
          <>
            <div className="card p-3">
              <div className="d-flex align-items-center gap-3 pb-2">
                <label htmlFor="">আবেদন পত্র সংশোধন</label>
              </div>
              <div
                className="pt-1"
                style={{
                  borderTop: "1px dashed #ccc",
                }}
              ></div>
              <div className="d-flex gap-3 pt-2">
                <div>
                  <Form.Check
                    type="checkbox"
                    value={4}
                    label={<span className="cursor-pointer">সবগুলো অংশ</span>}
                    id="disabled-default-সবগুলো"
                    onChange={handleChangeCheck}
                    checked={applicationParts.length === applicationPart.length}
                  />
                </div>
                <div className="border-start px-3">
                  {applicationPart.map((item) => (
                    <Form.Check
                      key={item.value} // Added key for better performance
                      type="checkbox"
                      value={item.value}
                      label={
                        <span className="cursor-pointer">{item.label}</span>
                      }
                      id={`disabled-default-${item.label}`}
                      onChange={handleChangeCheck}
                      checked={applicationParts.includes(item.value)}
                    />
                  ))}
                  {info?.st === 3 && (
                    <Form.Check
                      type="checkbox"
                      label={
                        <span className="cursor-pointer">
                          পেমেন্ট ( ই-চালান )
                        </span>
                      }
                      id={`disabled-default`}
                      onChange={handleChangeCheck}
                      disabled
                    />
                  )}
                </div>
              </div>
            </div>

            {formik.touched.applicationParts &&
            formik.errors.applicationParts ? (
              <div className="text-danger mt-2">
                {formik.errors.applicationParts}
              </div>
            ) : null}
          </>
        )}
        <div className="my-4 text-center d-flex justify-content-center gap-3">
          {info.fileStatus !== 22 && (
            <>
              <div className="mr-3">
                <Button
                  onClick={() => setIsEdit(!isEdit)}
                  disabled={isLoadingVerify}
                >
                  {isEdit ? "প্রিভিউ" : "সম্পাদনা করুন"}
                </Button>
              </div>
            </>
          )}

          <div className="mr-3">
            <Button variant="success" type="submit" disabled={isLoadingVerify}>
              {isAuthorized
                ? "অনুমোদন"
                : info.fileStatus === 22
                ? "চিঠি পাঠান"
                : "দাখিল করুন"}
            </Button>
          </div>
        </div>
      </form>
    </>
  );
};

export default LetterCorrectionContent;
