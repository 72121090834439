export const AgreementGenerateAttachments = (forInfo) => {
  const { st, tradelicense, tincertificate } = forInfo;

  let attach = {};

  attach[1] = st === 3 ? "ই-চালান নম্বর" : "ট্রেজারি চালানের স্ক্যান কপি";

  attach[2] = "আবেদনকারীর ছবি";
  attach[3] = "জাতীয় পরিচয় পত্রের কপি";
  attach[4] = "চুক্তিনামার মূলকপি ও নোটারি পাবলিক দ্বারা সত্যায়িত ফটোকপি";

  if (fileExists(tradelicense)) {
    attach[5] = "প্রতিষ্ঠানের ট্রেড লাইসেন্সের স্ক্যান কপি";
  }

  if (fileExists(tincertificate)) {
    attach[6] = "প্রতিষ্ঠানের টিন সার্টিফিকেটের স্ক্যান কপি";
  }

  return attach;
};

// Mock implementation of fileExists function
const fileExists = (file) => {
  // Implement your file existence logic here
  return file !== undefined && file !== null; // Example condition
};

export const AgreementKeyAttachArray = (forInfo) => {
  const attach = {};

  attach[1] =
    forInfo?.st === 3 ? "ই-চালান নম্বর" : "ট্রেজারি চালানের স্ক্যান কপি";

  attach[2] = "আবেদনকারীর ছবি";
  attach[3] = "জাতীয় পরিচয় পত্রের কপি";
  attach[4] = "চুক্তিনামার মূলকপি ও নোটারি পাবলিক দ্বারা সত্যায়িত ফটোকপি";
  attach[5] = "প্রতিষ্ঠানের ট্রেড লাইসেন্সের স্ক্যান কপি";
  attach[6] = "প্রতিষ্ঠানের টিন সার্টিফিকেটের স্ক্যান কপি";

  // Function to get the title based on the key
  const getAttachmentTitle = (item) => {
    const [key] = item.split(":"); // Split the string 'key:value', extract key
    return attach[Number(key)] || "No title found";
  };

  // Create a single object to store key-value pairs
  const result = {};

  forInfo?.status.forEach((item) => {
    const [key] = item.split(":"); // Extract key
    result[key] = getAttachmentTitle(item); // Set key-value pair in the result object
  });

  return result;
};

export const AgreementGenerateAttachmentsValue = (forInfo) => {
  const {
    st,
    tradelicense,
    tincertificate,
    treasury,
    applicant,
    nid,
    istructure,
  } = forInfo;

  let attach = {};

  attach[1] = treasury;

  attach[2] = applicant;
  attach[3] = nid;
  attach[4] = istructure;

  if (fileExists(tradelicense)) {
    attach[5] = tradelicense;
  }

  if (fileExists(tincertificate)) {
    attach[6] = tincertificate;
  }

  return attach;
};
