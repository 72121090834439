import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { BeatLoader } from "react-spinners";
import Swal from "sweetalert2";
import { validationSchema } from "../../frontend/userPanel/form_one/validation";
import { createFormData } from "../../../utils/formDataTransform";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  useDeleteCopyrightFirstPartTeamTypesMutation,
  useShowCopyrightFirstPartQuery,
  useUpdateCopyrightFirstPartMutation,
} from "../../../redux/api/editFormApiSlice";
import { useAuth } from "../../../utils/authorization";
import { transformErrorsToObjectStructure } from "../../../utils/transformErrorsToObjectStructure";
import toast from "react-hot-toast";
import Loader from "../../../components/custom/Loader";
import { globalDateFormat, localDateFormat } from "../../../utils/dateFormat";
import ClientFormikFormFieldMultiple from "../../../components/custom/form/ClientFormikFormFieldMultiple";
import BanglaCalender from "../../frontend/userPanel/form_two/BanglaCalender";
import { ImPlus } from "react-icons/im";
import { IoCloseSharp } from "react-icons/io5";
import styles from "./../../frontend/userPanel/form_one/ApplicationForCopyright.module.css";

const CopyrightFirstPartEdit = () => {
  const { id, trackReg } = useParams();
  const {
    data: editData,
    isLoading: isLoadingEdit,
    refetch: refetchEdit,
  } = useShowCopyrightFirstPartQuery({ id, trackReg });

  const mainData = editData?.data;

  const navigate = useNavigate();

  const { authUser } = useAuth();
  const [sideHas, setSideHas] = useState(2);
  const [signature, setSignature] = useState(null);
  const publicUrl = process.env.PUBLIC_URL;

  const [
    updateFormOne,
    {
      isLoading: isLoadingUpdate,
      isError: isErrorUpdate,
      error: errorUpdate,
      isSuccess: isSuccessUpdate,
      data: dataUpdate,
    },
  ] = useUpdateCopyrightFirstPartMutation();

  const [
    deleteFormOne,
    {
      isError: isErrorDelete,
      error: errorDelete,
      isSuccess: isSuccessDelete,
      data: dataDelete,
    },
  ] = useDeleteCopyrightFirstPartTeamTypesMutation();

  const handleDeleteFormOne = (id, index) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        deleteFormOne({ id, applicant: mainData?.user?.id }).then((result) => {
          handleRemoveFieldSet(index);
        });
      }
    });
  };

  const toggleSideHas = (side) => {
    setSideHas(side);
    setValues((prevValues) => ({
      ...prevValues,
      teamType: side,
      profileteamtypes: [
        {
          teamName: "",
          teamAddress: "",
          teamEmail: "",
          teamMobile: "",
          teamSenddate: "",
        },
      ],
    }));
  };

  const {
    values,
    touched,
    errors,
    handleBlur,
    handleChange,
    handleSubmit,
    setValues,
    setErrors,
    resetForm,
  } = useFormik({
    initialValues: {
      editId: id,
      applicant: mainData?.user?.id,
      trackReg: trackReg,
      teamType: Number(mainData?.teamType) || 2,
      communicationAddress: mainData?.communicationAddress || "",
      place: mainData?.place || "",
      puname: mainData?.puname || "",
      sign: "",
      profileteamtypes:
        mainData?.profileTeamTypes && Number(mainData?.teamType) === 1
          ? mainData?.profileTeamTypes
          : [
              {
                teamName: "",
                teamAddress: "",
                teamEmail: "",
                teamMobile: "",
                teamSenddate: "",
              },
            ],
    },
    validationSchema: validationSchema,
    onSubmit: async (values, { setSubmitting }) => {
      try {
        const formData = createFormData(values);
        formData.append("teamType", sideHas);

        await updateFormOne({
          id,
          formData,
        });
      } catch (error) {
        setSubmitting(false);
      }
    },
    enableReinitialize: true,
  });

  const handleAddFieldSet = () => {
    const newRow = {
      teamName: "",
      teamAddress: "",
      teamEmail: "",
      teamMobile: "",
      teamSenddate: "",
    };
    setValues((prevValues) => ({
      ...prevValues,
      profileteamtypes: [...prevValues.profileteamtypes, newRow],
    }));
  };

  const handleRemoveFieldSet = (index) => {
    const filteredRows = values.profileteamtypes.filter(
      (_, idx) => idx !== index
    );
    setValues({ ...values, profileteamtypes: filteredRows });
  };

  useEffect(() => {
    if (isErrorUpdate) {
      setErrors(
        transformErrorsToObjectStructure(errorUpdate?.data?.data || {})
      );
      toast.error(errorUpdate?.data?.message);
    }
    if (isSuccessUpdate) {
      refetchEdit();
      toast.success(dataUpdate?.message);
      navigate(-1);
    }
  }, [isErrorUpdate, isSuccessUpdate]);

  useEffect(() => {
    if (isErrorDelete) {
      toast.error(errorDelete?.data?.message);
    }
    if (isSuccessDelete) {
      toast.success(dataDelete?.message);
    }
  }, [isErrorDelete, isSuccessDelete]);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSignature(file);
    if (file) {
      setValues((prevValues) => ({
        ...prevValues,
        sign: file,
      }));
    }
  };

  useEffect(() => {
    if (editData?.data) {
      setSideHas(editData?.data?.teamType);
    }
  }, [editData?.data]);
  if (isLoadingEdit) {
    return <Loader />;
  }

  const toDay = localDateFormat(new Date());
  return (
    <div>
      <section className="section py-5 copyrightformone service-single">
        <div className="container">
          <div className="d-flex justify-content-center align-items-center">
            <div className="card shadow border-0 w-100">
              <div
                className="tab-content rounded border py-4 paddddingnone px-5 border-1"
                id="nav-tabContent"
              >
                <div className="text-end me-4">
                  <div className="">
                    {" "}
                    <img
                      src={`${publicUrl}/assets/images/p1.png`}
                      alt="Logo"
                      style={{
                        height: "70px",
                        width: "60px",
                        marginTop: "-47px",
                      }}
                      className="img-fluid pataresponsive"
                    />{" "}
                  </div>
                </div>

                <div
                  className="tab-pane fade active show"
                  id="nav-application"
                  role="tabpanel"
                  aria-labelledby="nav-application-tab"
                >
                  <div className="applicationForm">
                    <div
                      style={{ marginTop: "-20px" }}
                      className="applicationForm-header text-center"
                    >
                      <h5 className="mb-0 form2fontsizeee">ফরম - ১.</h5>
                      <b className="form2fontsizeee2">
                        কপিরাইট রেজিস্ট্রেশনের আবেদন পত্র
                      </b>
                      <h5 className="mt-1 form2fontsizeee">
                        [ বিধি ৪ (১) দ্রষ্টব্য ]
                      </h5>
                    </div>
                    <div className="applicationForm-body">
                      <div className="applicationForm-body-top">
                        <div className="d-flex justify-content-between">
                          <div className="div">
                            <p className="mb-0 mt-2">
                              <b>বরাবর</b>
                            </p>
                            <p className="unbold">
                              রেজিস্ট্রার অব কপিরাইটস <br />
                              বাংলাদেশ কপিরাইট অফিস।
                            </p>
                          </div>
                        </div>

                        <p className="mb-0">
                          <b>জনাব,</b>
                        </p>
                        <p className="unbold">
                          কপিরাইট আইন (২০২৩ সালের ৩৪ নং আইন এবং সংশোধিত- ২০০৫)
                          এর ৫৪ ধারা অনুযায়ী আমি মেধাস্বত্ব হস্তান্তর / লাইসেন্স
                          প্রদান সংক্রান্ত দলিল নিবন্ধনের জন্য আবেদন করছি এবং
                          দলিল নিবন্ধন বহিতে হস্তান্তরিত কর্ম সংক্রান্ত তথ্যসমূহ
                          লিপিবদ্ধ করার জন্য দুইকপি সংশ্লিষ্ট কর্ম ও বিবরণী
                          সংযুক্ত করছি।
                        </p>

                        <p className="mb-0">
                          ১. এই কর্মের সঙ্গে সম্পর্কযুক্ত অন্যান্য বিষয়াবলীর
                          বিবরণী ও যথাযথভাবে পূরণ করিয়া পাঠাইলাম ।{" "}
                        </p>

                        <div className="d-flex mt-2">
                          <p className="mb-0 ">২. কোন পক্ষ আছে কি ?</p>

                          <span className="d-flex mx-3">
                            <div className="form-check mx-3">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="sideRadio"
                                id="sideRadio1"
                                checked={Number(values.teamType) === 1}
                                onClick={() => toggleSideHas(1)}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="sideRadio1"
                              >
                                হ্যাঁ
                              </label>
                            </div>
                            <div className="form-check">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="sideRadio"
                                id="sideRadio2"
                                checked={Number(values.teamType) === 2}
                                onClick={() => toggleSideHas(2)}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="sideRadio2"
                              >
                                না
                              </label>
                            </div>
                          </span>
                        </div>
                        {Number(values.teamType) === 1 && (
                          <div>
                            {values?.profileteamtypes?.map((item, index) => (
                              <div key={index} className="isSideHas my-2">
                                <div className="row pb-3 border-bottom">
                                  <div className="col-md-4 my-1">
                                    <div className="form-group">
                                      <label>পক্ষের নাম</label>
                                      <ClientFormikFormFieldMultiple
                                        placeholder="নাম"
                                        value={item.teamName}
                                        name={`profileteamtypes[${index}].teamName`}
                                        errors={
                                          errors?.profileteamtypes &&
                                          errors.profileteamtypes?.length > 0 &&
                                          errors?.profileteamtypes[index]
                                            ?.teamName
                                        }
                                        touched={
                                          touched?.profileteamtypes &&
                                          touched.profileteamtypes?.length >
                                            index &&
                                          touched?.profileteamtypes[index]
                                            ?.teamName
                                        }
                                        handleChange={handleChange}
                                        handleBlur={handleBlur}
                                      />
                                    </div>
                                  </div>
                                  <div className="col-md-4 my-1">
                                    <div className="form-group">
                                      <label>পক্ষের ঠিকানা </label>
                                      <ClientFormikFormFieldMultiple
                                        placeholder="ঠিকানা "
                                        value={item.teamAddress}
                                        name={`profileteamtypes[${index}].teamAddress`}
                                        errors={
                                          errors?.profileteamtypes &&
                                          errors.profileteamtypes?.length > 0 &&
                                          errors?.profileteamtypes[index]
                                            ?.teamAddress
                                        }
                                        touched={
                                          touched?.profileteamtypes &&
                                          touched.profileteamtypes?.length >
                                            index &&
                                          touched?.profileteamtypes[index]
                                            ?.teamAddress
                                        }
                                        handleChange={handleChange}
                                        handleBlur={handleBlur}
                                      />
                                    </div>
                                  </div>
                                  <div className="col-md-4 my-1">
                                    <div className="form-group">
                                      <label>
                                        প্রেরণের তারিখ / ডেসপাচের তারিখ
                                      </label>
                                      <BanglaCalender
                                        placeholder={"তারিখ"}
                                        value={item.teamSenddate}
                                        onChange={(date) => {
                                          setValues((prevValues) => ({
                                            ...prevValues,
                                            profileteamtypes:
                                              prevValues.profileteamtypes.map(
                                                (detail, i) =>
                                                  i === index
                                                    ? {
                                                        ...detail,
                                                        teamSenddate:
                                                          globalDateFormat(
                                                            date
                                                          ),
                                                      }
                                                    : detail
                                              ),
                                          }));
                                        }}
                                      />
                                    </div>
                                    <div>
                                      {" "}
                                      {errors.profileteamtypes?.[index]
                                        ?.teamSenddate &&
                                        touched.profileteamtypes?.[index]
                                          ?.teamSenddate && (
                                          <small className="my-2 text-danger">
                                            {
                                              errors.profileteamtypes?.[index]
                                                ?.teamSenddate
                                            }
                                          </small>
                                        )}
                                    </div>
                                  </div>
                                  <div className="col-md-4 my-1">
                                    <div className="form-group">
                                      <label>ই-মেইল</label>

                                      <ClientFormikFormFieldMultiple
                                        placeholder="ই-মেইল"
                                        value={item.teamEmail}
                                        name={`profileteamtypes[${index}].teamEmail`}
                                        errors={
                                          errors?.profileteamtypes &&
                                          errors.profileteamtypes?.length > 0 &&
                                          errors?.profileteamtypes[index]
                                            ?.teamEmail
                                        }
                                        touched={
                                          touched?.profileteamtypes &&
                                          touched.profileteamtypes?.length >
                                            index &&
                                          touched?.profileteamtypes[index]
                                            ?.teamEmail
                                        }
                                        handleChange={handleChange}
                                        handleBlur={handleBlur}
                                        type="email"
                                      />
                                    </div>
                                  </div>
                                  <div className="col-md-4 my-1">
                                    <div className="form-group">
                                      <label>মোবাইল নম্বর</label>
                                      <ClientFormikFormFieldMultiple
                                        placeholder="মোবাইল নম্বর"
                                        value={item.teamMobile}
                                        name={`profileteamtypes[${index}].teamMobile`}
                                        errors={
                                          errors?.profileteamtypes &&
                                          errors.profileteamtypes?.length > 0 &&
                                          errors?.profileteamtypes[index]
                                            ?.teamMobile
                                        }
                                        touched={
                                          touched?.profileteamtypes &&
                                          touched.profileteamtypes?.length >
                                            index &&
                                          touched?.profileteamtypes[index]
                                            ?.teamMobile
                                        }
                                        handleChange={handleChange}
                                        handleBlur={handleBlur}
                                      />
                                    </div>
                                  </div>
                                  <div className="col-md-4 my-1">
                                    <div className="form-group centeraddremovebtn addremovemargin mt-4">
                                      <div className="">
                                        {(values.profileteamtypes.length ===
                                          index + 1 ||
                                          values.profileteamtypes.length ===
                                            1) && (
                                          <ImPlus
                                            className="customAddButton rounded"
                                            onClick={handleAddFieldSet}
                                          />
                                        )}

                                        {values.profileteamtypes.length > 1 &&
                                          index !== 0 && (
                                            <IoCloseSharp
                                              className="customRemoveButton rounded"
                                              onClick={() => {
                                                if (item.id) {
                                                  handleDeleteFormOne(
                                                    item.id,
                                                    index
                                                  );
                                                } else {
                                                  handleRemoveFieldSet(index);
                                                }
                                              }}
                                            />
                                          )}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ))}
                          </div>
                        )}
                        <br />
                        <p className="mb-3">
                          কপিরাইট বিধিমালা, ২০০৬ এর বিধি-৪ এর উপ-বিধি (৩)
                          অনুযায়ী আমি এই পত্র এবং তদসংযুক্ত বিবরণীসমূহের কপি
                          নিম্নোক্ত পদ্ধতিতে অন্যান্য পক্ষের নিকট হাতে
                          হাতে/অগ্রিম রেজিস্টার্ড ডাকযোগে প্রেরণ করিয়াছি"
                          শব্দগুলির স্থলে "কপিরাইট বিধিমালা, ২০০৬ এর ৪(৩) উপ-
                          বিধি অনুযায়ী, অনলাইনে দাখিলকৃত আবেদনপত্রের হার্ডকপি
                          বাংলাদেশ কপিরাইট অফিসে দাখিলের পূর্বে আমি সংশ্লিষ্ট
                          কর্মের কপিরাইট স্বত্বের সহিত স্বার্থসংশ্লিষ্ট সকল
                          ব্যক্তির নিকট আবেদনপত্রের কপি এবং তদসংযুক্ত
                          বিবরণীসমূহের কপি হাতে হাতে। রেজিস্টার্ড ডাকযোগে প্রেরণ
                          করিব এবং উক্ত কার্যের প্রমাণক আবেদনপত্রের হার্ডকপির
                          সহিত বাংলাদেশ কপিরাইট অফিসে দাখিল করিব
                        </p>

                        <p className="m-0">৩. যোগাযোগের ঠিকানা :</p>
                        <div className="row pb-5 pt-1">
                          <div className="col-md-12">
                            <div className="applicationAddress">
                              <div className="form-group">
                                <textarea
                                  className="form-control"
                                  rows="4"
                                  name="communicationAddress"
                                  value={values.communicationAddress}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                ></textarea>
                                {touched.communicationAddress &&
                                  errors.communicationAddress && (
                                    <small
                                      className="text-danger mt-1"
                                      style={{ fontSize: "13px" }}
                                    >
                                      {errors.communicationAddress}
                                    </small>
                                  )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="applicationForm-body-bottom">
                        <div className="row">
                          <div className="col-md-4">
                            <div className="applicationUserInfoPartLeft mt-10">
                              <div className="form-group">
                                <input
                                  type="email"
                                  defaultValue={authUser?.email}
                                  disabled
                                  className="form-control"
                                />
                              </div>
                              <div className="form-group my-3">
                                <ClientFormikFormFieldMultiple
                                  placeholder="স্থানের নাম লিখুন"
                                  value={values.place}
                                  name={"place"}
                                  errors={errors?.place}
                                  touched={touched?.place}
                                  handleChange={handleChange}
                                  handleBlur={handleBlur}
                                />
                              </div>

                              <div className="form-group">
                                <input
                                  type="text"
                                  className="form-control"
                                  value={toDay}
                                  disabled
                                />
                              </div>
                            </div>
                          </div>

                          <div className="col-md-4"></div>

                          <div className="col-md-4">
                            <div className="applicationUserInfoPartRight">
                              <div className="text-center">
                                <p className="mb-0 mt-1">
                                  আপনার বিশ্বস্ত :{" "}
                                  <span className="text-danger">*</span>{" "}
                                </p>
                              </div>
                              <div className="form-group">
                                <ClientFormikFormFieldMultiple
                                  placeholder="নাম লিখুন"
                                  value={values.puname}
                                  name={"puname"}
                                  errors={errors?.puname}
                                  touched={touched?.puname}
                                  handleChange={handleChange}
                                  handleBlur={handleBlur}
                                />
                              </div>
                              <div className="form-group text-center">
                                <label>স্বাক্ষর (200px * 80px)</label>
                                <br />
                                <div className={styles.imagePreviewSignature}>
                                  {signature && (
                                    <img
                                      src={URL.createObjectURL(signature)}
                                      id="Signature"
                                      alt="Preview"
                                    />
                                  )}
                                  {editData?.data?.sign && !signature && (
                                    <img
                                      src={editData?.data?.sign}
                                      id="Signature"
                                      alt="Preview"
                                    />
                                  )}
                                </div>

                                <div>
                                  {touched.sign && errors.sign && (
                                    <small
                                      className="text-danger mt-0"
                                      style={{ fontSize: "13px" }}
                                    >
                                      {errors.sign}
                                    </small>
                                  )}
                                </div>

                                <label className="custombtnn5">
                                  <input
                                    type="file"
                                    className="d-none"
                                    name="sign"
                                    onChange={handleFileChange}
                                    accept=".jpg, .jpeg, .png"
                                  />
                                  {signature || editData?.data?.sign
                                    ? "পরিবর্তন করুন"
                                    : "নির্বাচন করুন"}
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="applicationForm-body-footer text-center mt-5">
                        <button
                          disabled={isLoadingUpdate}
                          className="custombtn5"
                          onClick={handleSubmit}
                          type="submit"
                        >
                          {isLoadingUpdate ? (
                            <BeatLoader className="mt-1" />
                          ) : (
                            "সম্পাদন করুন"
                          )}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default CopyrightFirstPartEdit;
