import React, { useEffect, useState } from "react";
import { useUpdateSortingCatalogMutation } from "../redux/api/categoryCatalogApiSlice";
import Loader from "./custom/Loader";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import toast from "react-hot-toast";
import { Button } from "react-bootstrap";
import { BeatLoader } from "react-spinners";

const ShorthenComponent = ({ initialData }) => {
  const [update, { isLoading, isSuccess, isError, error }] =
    useUpdateSortingCatalogMutation();

  const [items, setItems] = useState([]);
  const [isDragging, setIsDragging] = useState(false);
  const [draggedItemId, setDraggedItemId] = useState(null);

  useEffect(() => {
    if (initialData) {
      setItems(initialData);
    }
  }, [initialData]);

  const handleOnDragStart = (start) => {
    setIsDragging(true);
    const draggedItem = items[start.source.index];
    setDraggedItemId(draggedItem.id);
  };

  const handleOnDragEnd = (result) => {
    setIsDragging(false);
    setDraggedItemId(null);

    if (!result.destination) return;

    const reorderedItems = Array.from(items);
    const [reorderedItem] = reorderedItems.splice(result.source.index, 1);
    reorderedItems.splice(result.destination.index, 0, reorderedItem);

    setItems(reorderedItems);
  };

  const updateSortingIndex = (val) => {
    return val.map((category, index) => ({
      ...category,
      sorting_index: index + 1,
    }));
  };

  const handleSave = () => {
    const updatedCategoriesData = updateSortingIndex(items);
    update(updatedCategoriesData);
  };

  useEffect(() => {
    if (isSuccess) {
      toast.success("Data updated successfully:");
    }
  }, [isSuccess]);

  return (
    <>
      <DragDropContext
        onDragStart={handleOnDragStart}
        onDragEnd={handleOnDragEnd}
      >
        {items.length > 0 && (
          <Droppable droppableId="droppable">
            {(provided, snapshot) => (
              <div
                ref={provided.innerRef}
                {...provided.droppableProps}
                className="col-md-12" // Adjust the width of the Droppable area
              >
                {items.map((item, index) => (
                  <Draggable
                    key={item.id}
                    draggableId={item.id.toString()}
                    index={index}
                  >
                    {(provided) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        className="col-md-6 mb-3" // Ensure col-md-6 is applied correctly
                      >
                        <div className="card">
                          <div
                            className="d-flex justify-content-between align-items-center p-2"
                            style={{ cursor: "grab" }}
                          >
                            <p className="m-0">{item.name}</p>
                            <DragIndicatorIcon className="text-secondary" />
                          </div>
                        </div>
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        )}
      </DragDropContext>
      <div className="col-md-6 text-center">
        <Button disabled={isLoading} onClick={handleSave}>
          {isLoading ? <BeatLoader color="#fff" /> : "আপডেট করুন"}
        </Button>
      </div>
    </>
  );
};

export default ShorthenComponent;
