export const createFormData = (values) => {
  const formData = new FormData();

  const appendFormData = (formData, prefix, object) => {
    if (object instanceof File || object instanceof Blob) {
      formData.append(prefix, object);
    } else if (typeof object === "object" && object !== null) {
      Object.keys(object).forEach((key) => {
        const value = object[key];
        const newKey = prefix ? `${prefix}[${key}]` : key;

        if (Array.isArray(value)) {
          value.forEach((item, index) => {
            const arrayKey = `${newKey}[${index}]`;
            if (item !== undefined && item !== null && item !== "") {
              appendFormData(formData, arrayKey, item);
            } else {
              appendFormData(formData, arrayKey, "");
            }
          });
        } else {
          if (value !== undefined && value !== null && value !== "") {
            appendFormData(formData, newKey, value);
          } else {
            appendFormData(formData, newKey, "");
          }
        }
      });
    } else {
      if (object !== undefined && object !== null && object !== "") {
        formData.append(prefix, object);
      } else {
        formData.append(prefix, "");
      }
    }
  };

  Object.keys(values).forEach((key) => {
    appendFormData(formData, key, values[key]);
  });

  return formData;
};

// export const createFormData = (values) => {
//   const formData = new FormData();

//   const appendFormData = (formData, key, value) => {
//     if (value) {
//       if (Array.isArray(value)) {
//         value.forEach((item, index) => {
//           if (typeof item === "object" && item) {
//             console.log(item);
//             Object.keys(item).forEach((subKey) => {
//               if (item[subKey]) {
//                 formData.append(`${key}[${index}][${subKey}]`, item[subKey]);
//               }
//             });
//           } else {
//             formData.append(`${key}[${index}]`, item);
//           }
//         });
//       }
//       // else if (typeof value === "object" && value) {
//       //   Object.keys(value).forEach((subKey) => {
//       //     appendFormData(formData, `${key}[${subKey}]`, value[subKey]);
//       //   });
//       // }
//       else {
//         formData.append(key, value);
//       }
//     }
//   };

//   Object.keys(values).forEach((key) => {
//     appendFormData(formData, key, values[key]);
//   });

//   return formData;
// };

// Object.keys(values).forEach((key) => {
//   Object.keys(ignore).forEach((ignoreKey) => {
//     if (
//       key !== ignoreKey &&
//       key !== ignoreKey &&
//       key !== ignoreKey &&
//       key !== ignoreKey &&
//       key !== ignoreKey &&
//       key !== ignoreKey
//     ) {
//       appendFormData(formData, key, values[key]);
//     }
//   });
// });
