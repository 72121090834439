import React from "react";
import { useGetContractFullFormPreviewQuery } from "../../../../redux/api/agreementsApiSlice";
import { useParams } from "react-router-dom";
import Loader from "../../../../components/custom/Loader";
import AgreementFormPreview from "../../../../components/preview_applications/full-form-preview-copyright/AgreementFormPreview";

const ContractFullFormPreview = () => {
  const { id } = useParams();
  const { data, isLoading } = useGetContractFullFormPreviewQuery(id);

  if (isLoading) {
    return <Loader />;
  }

  return (
    <div>
      <AgreementFormPreview data={data} />
    </div>
  );
};

export default ContractFullFormPreview;
