export const extractPublicationDate = (date, field) => {
  if (date) {
    const dateParts = date.split("-");
    let year = "";
    let month = "";
    let day = "";

    if (dateParts.length === 1) {
      // Only year provided
      year = dateParts[0]; // e.g., "2020"
    } else if (dateParts.length === 2) {
      // Month and year provided
      year = dateParts[0]; // e.g., "2020"
      month = dateParts[1]; // e.g., "09"
    } // No day provided (e.g., "2020-09")
    else if (dateParts.length === 3) {
      // Full date provided
      year = dateParts[0]; // e.g., "2020"
      month = dateParts[1]; // e.g., "09"
      day = dateParts[2]; // e.g., "12"
    }

    // Return the requested field if it exists
    switch (field) {
      case "year":
        return year || "";
      case "month":
        return month || "";
      case "day":
        return day || "";
      default:
        return ""; // Invalid field
    }
  }
  return "";
};
