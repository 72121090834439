import React, { useCallback, useEffect, useState } from "react";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import CreateIcon from "@mui/icons-material/Create";
import DoubleArrowIcon from "@mui/icons-material/DoubleArrow";
import { Link } from "react-router-dom";
import debounce from "lodash/debounce";
import { useGetApplicationsListQuery } from "../../../redux/api/copyrightApplicationFormOneApiSlice";
import Loader from "../../../components/custom/Loader";
import ReusableTable from "../../../components/custom/data_table/ReusableTable";
import { Badge } from "react-bootstrap";
import { localDateFormat } from "../../../utils/dateFormat";
import { Create, Payment, RemoveRedEye } from "@mui/icons-material";
import EditIcon from "../../../components/EditIcon";
import { convertToBanglaNumerals } from "../../../utils/convertToBanglaNumerals";

export default function Applications() {
  const [currentPage, setCurrentPage] = useState(1);
  const [searchText, setSearchText] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const {
    data: applications,
    isLoading,
    refetch,
  } = useGetApplicationsListQuery({
    currentPage,
    searchValue,
  });

  const totalData = applications?.meta?.total;
  const lastPage = applications?.meta?.last_page;

  const debouncedSearch = useCallback(
    debounce((value) => {
      setCurrentPage(1);
      setSearchValue(value);
      refetch();
    }, 400),
    []
  );

  useEffect(() => {
    // Cleanup function to cancel the debounce when the component unmounts or debouncedSearch changes
    return () => {
      debouncedSearch.cancel();
    };
  }, [debouncedSearch]);

  const handleSearchChange = (value) => {
    setSearchText(value);
    debouncedSearch(value);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
    refetch();
  };

  const columns = [
    { label: "ক্রমিক নং.", name: "index" },
    {
      label: "আবেদনের তারিখ",
      name: "sendDate",
      options: {
        customBodyRender: (value) => {
          return value && localDateFormat(value);
        },
      },
    },
    {
      label: "ধরণ",
      name: "apptype",
      options: {
        customBodyRender: (value) => {
          const lab = value === "1" ? "কপিরাইট" : "চুক্তিপত্র";
          return lab;
        },
      },
    },
    { label: "ট্র্যাকিং নম্বর", name: "trackReg" },
    {
      label: "ট্রেজারি/ই-চালান নং",
      name: "treasuryNo",
      options: {
        customBodyRender: (value, tableMeta) => {
          const body = tableMeta.rowData;
          return Number(body?.st) === 3 ? body?.echallanId : body?.treasuryNo;
        },
      },
    },

    {
      label: "ব্যাংকের নাম",
      name: "treasuryBank",
      options: {
        customBodyRender: (value, tableMeta) => {
          const body = tableMeta.rowData;
          return Number(body?.st) === 3
            ? "ই-চালান"
            : body?.bankbranches?.bank?.name;
        },
      },
    },

    {
      label: "শাখা",
      name: "bankbranches",
      options: {
        customBodyRender: (value, tableMeta) => {
          const body = tableMeta.rowData;
          return Number(body?.st) === 3
            ? "অনলাইন পেমেন্ট"
            : body?.bankbranches?.name;
        },
      },
    },

    {
      label: "১ম ধাপ",
      name: "step1",
      options: {
        customBodyRender: (value, tableMeta) => {
          const body = tableMeta.rowData;
          return (
            <>
              <Link
                to={
                  Number(body?.apptype) === 1
                    ? `/applicant/applications/${body.id}/preview`
                    : `/agreement-form-add/${body.id}/preview`
                }
                className="me-2"
                title="দেখুন"
              >
                <RemoveRedEyeIcon style={{ fontSize: "20px" }} />
              </Link>
              {Number(body?.apptype) === 1 ? (
                <>
                  {Number(body?.profileDetail?.form_status) !== 2 && (
                    <Link
                      to={`/applicant/applications?id=${body.id}`}
                      title="সম্পাদন করুন"
                    >
                      <EditIcon />
                    </Link>
                  )}
                </>
              ) : (
                <>
                  {Number(body?.document?.form_status) !== 2 && (
                    <Link
                      to={`/agreement-form-add?id=${body.id}`}
                      title="সম্পাদন করুন"
                    >
                      <EditIcon />
                    </Link>
                  )}
                </>
              )}
            </>
          );
        },
      },
    },
    {
      label: "২য় ধাপ",
      name: "step2",
      options: {
        customBodyRender: (value, tableMeta) => {
          const body = tableMeta.rowData;
          return (
            <div className="d-flex gap-2">
              {(body?.profileDetail || body?.document) && (
                <Link
                  to={
                    Number(body?.apptype) === 1
                      ? `/applicant/application-details/${body?.id}/${body?.profileDetail?.id}/preview`
                      : `/agreement-form-add-two/${body?.document?.id}/preview`
                  }
                  title="দেখুন"
                >
                  <RemoveRedEyeIcon style={{ fontSize: "20px" }} />
                </Link>
              )}

              {Number(body?.apptype) === 1 ? (
                <>
                  {!body?.profileDetail && (
                    <Link
                      to={`/applicant/application-details/${body.id}`}
                      title="২য় ধাপ দাখিল করুন"
                    >
                      <DoubleArrowIcon style={{ fontSize: "20px" }} />
                    </Link>
                  )}
                  {body?.profileDetail &&
                    Number(body?.profileDetail?.form_status) !== 2 && (
                      <Link
                        to={`/applicant/application-second-part-update/${body?.profileDetail?.id}`}
                        title="সম্পাদন করুন"
                      >
                        <EditIcon style={{ fontSize: "20px" }} />
                      </Link>
                    )}
                </>
              ) : (
                <>
                  {!body?.document && (
                    <Link
                      to={`/agreement-form-add-two/${body.id}`}
                      title="২য় ধাপ দাখিল করুন"
                    >
                      <DoubleArrowIcon style={{ fontSize: "20px" }} />
                    </Link>
                  )}
                  {body?.document &&
                    Number(body?.document?.form_status) !== 2 && (
                      <Link
                        to={`/agreement-form-add-two/${body.id}/${body?.document?.id}/edit`}
                        title="সম্পাদন করুন"
                      >
                        <EditIcon style={{ fontSize: "20px" }} />
                      </Link>
                    )}
                </>
              )}
            </div>
          );
        },
      },
    },
    {
      label: "পেমেন্ট",
      name: "payemnt",
      options: {
        customBodyRender: (value, tableMeta) => {
          const body = tableMeta.rowData;
          return (
            <div className="d-flex gap-2">
              {((body?.profileDetail &&
                Number(body?.profileDetail?.form_status) === 2) ||
                (body?.document &&
                  Number(body?.document?.form_status) === 2)) && (
                <Link
                  to={`/applicant/application-payment/${body?.id}`}
                  title="পেমেন্ট"
                >
                  <Payment style={{ fontSize: "20px" }} />
                </Link>
              )}
            </div>
          );
        },
      },
    },
  ];

  const data =
    applications?.data?.map((item, i) => ({
      index: convertToBanglaNumerals((currentPage - 1) * 10 + i + 1),
      id: item.id,
      ...item,
    })) || [];

  if (isLoading) {
    return <Loader />;
  }
  return (
    <div>
      <section className="section py-5 service-single">
        <div className="container">
          <div className="d-flex justify-content-center align-items-center bg-light">
            <div className="card p-3 shadow w-100">
              <ReusableTable
                title={"আবেদন সমূহ"}
                columns={columns}
                data={data}
                isLoading={isLoading}
                currentPage={currentPage}
                lastPage={lastPage}
                totalData={totalData}
                onSearchChange={handleSearchChange}
                searchText={searchText}
                onPageChange={handlePageChange}
              />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
