import { Box, Typography } from "@mui/material";
import { useFormik } from "formik";
import React, { memo, useEffect, useState } from "react";
import { Badge, Button, Modal } from "react-bootstrap";
import { BeatLoader } from "react-spinners";
import * as yup from "yup";
import FormikFormField from "../../../components/custom/form/FormikFormField";
import {
  useFindContactsQuery,
  useReplyContactsMutation,
  useUpdateContactsMutation,
} from "../../../redux/api/contactsApiSlice";
import { localDateFormat } from "../../../utils/dateFormat";
import toast from "react-hot-toast";
import { transformErrorsToObjectStructure } from "../../../utils/transformErrorsToObjectStructure";
import { convertToBanglaNumerals } from "../../../utils/convertToBanglaNumerals";

const ReplyContact = ({ onHide, data, ...props }) => {
  const [page, setPage] = useState(1);
  const [replyData, setReplyData] = useState([]);
  const [updateSeen] = useUpdateContactsMutation();
  const [
    replyContact,
    { isLoading: isLoadingReply, isSuccess, isError, error, data: dataReply },
  ] = useReplyContactsMutation();

  const {
    data: findData,
    isLoading,
    isFetching,
  } = useFindContactsQuery({
    id: data?.id,
    page: page,
  });

  useEffect(() => {
    if (findData && findData.reply_contacts) {
      setReplyData((prev) => [...prev, ...findData.reply_contacts.data]);
    }
  }, [findData]);

  useEffect(() => {
    if (data?.read_unread !== 1) {
      updateSeen({ formData: { _method: "PUT" }, id: data?.id });
    }
  }, [data?.read_unread]);

  const formik = useFormik({
    initialValues: {
      subject: data?.subject || "",
      message: "",
    },
    validationSchema: yup.object().shape({
      subject: yup.string().required("বিষয় প্রযোজ্য"),
      message: yup.string().required("বার্তার প্রযোজ্য"),
    }),
    onSubmit: async (values) => {
      try {
        await replyContact({ id: data.id, formData: values }).unwrap();
      } catch (error) {
        console.error("Submission error:", error);
      }
    },
    enableReinitialize: true,
  });

  useEffect(() => {
    if (isError) {
      toast.error(error?.data?.message);
      formik.setErrors(
        transformErrorsToObjectStructure(error?.data?.data || {})
      );
    }
    if (isSuccess) {
      setReplyData([]);
      formik.resetForm();
      toast.success(dataReply?.message);
    }
  }, [isError, isSuccess]);

  // if (isLoading || isFetching) {
  //   return <Loader />;
  // }

  const handleMore = () => {
    setPage(page + 1);
  };

  return (
    <Modal
      {...props}
      onHide={onHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header style={{ display: "flex", justifyContent: "center" }}>
        <Modal.Title id="contained-modal-title-vcenter">
          <Typography variant="h5" component="h2" className="fw-bold">
            বার্তার জবাব
          </Typography>
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Box p={3}>
          <Typography className="fw-bold">To,</Typography>
          <Typography className="fw-bold">
            নাম: <span className="fw-normal">{data?.name}</span>
          </Typography>
          <Typography className="fw-bold">
            ইমেইল: <span className="fw-normal">{data?.email}</span>
          </Typography>
          <form onSubmit={formik.handleSubmit} className="pt-3">
            <div className="row">
              <div className="col-md-12">
                <FormikFormField
                  value={formik.values.subject}
                  name="subject"
                  label="বিষয়"
                  placeholder="বিষয় লিখুন"
                  errors={formik.errors}
                  touched={formik.touched}
                  handleChange={formik.handleChange}
                  handleBlur={formik.handleBlur}
                />
              </div>
              <div className="col-md-12">
                <FormikFormField
                  value={formik.values.message}
                  name="message"
                  label="বার্তা"
                  placeholder="বার্তার লিখুন"
                  errors={formik.errors}
                  touched={formik.touched}
                  handleChange={formik.handleChange}
                  handleBlur={formik.handleBlur}
                  additional={{
                    as: "textarea",
                    rows: "4",
                  }}
                />
              </div>
            </div>
            <div className="col-md-12 text-center">
              <Button type="submit" disabled={isLoadingReply}>
                {isLoadingReply ? "পাঠানো হচ্ছে..." : "পাঠান"}
              </Button>
            </div>
          </form>
        </Box>
        {isLoading ? (
          <div
            className="p-3"
            style={{
              height: "200px",
              overflowY: "auto",
            }}
          >
            <BeatLoader />
          </div>
        ) : (
          <div
            className="p-3"
            style={{
              height: "200px",
              overflowY: "auto",
            }}
          >
            <Typography className="fw-bold">পূর্ববর্তী বার্তা</Typography>
            <span>
              মোট বার্তা:{" "}
              {convertToBanglaNumerals(findData?.reply_contacts?.total)}
            </span>
            {replyData.map((item, i) => (
              <div
                key={i}
                className="d-flex align-items-center pt-2 border-top my-2"
              >
                <div className="flex-grow-1">
                  <div className="d-flex gap-2">
                    <Badge>{convertToBanglaNumerals(i + 1)}</Badge>
                    <div>
                      <Typography className="fw-bold" sx={{ fontSize: "14px" }}>
                        <span className="fw-semibold">{item?.subject}</span>
                      </Typography>
                      <Typography className="fw-bold" sx={{ fontSize: "14px" }}>
                        <span className="fw-normal">{item?.message}</span>
                      </Typography>
                    </div>
                  </div>
                </div>
                <div className="text-right px-3">
                  <Typography variant="body2" className="text-muted">
                    {localDateFormat(item.created_at)}
                  </Typography>
                </div>
              </div>
            ))}
            {isLoadingReply && <BeatLoader />}
            {/* Load more  */}
            {findData?.reply_contacts?.next_page_url && (
              <div className="text-center">
                <span
                  className="text-primary cursor-pointer"
                  onClick={handleMore}
                >
                  {isFetching ? "Loading..." : "Load More"}
                </span>
              </div>
            )}
          </div>
        )}
      </Modal.Body>

      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          বন্ধ
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default memo(ReplyContact);
