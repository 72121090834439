import React, { useEffect, useState } from "react";
import styles from "./Add2.module.css";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  useCreateApplicationFormTwoMutation,
  useFindApplicationFormTwoQuery,
} from "../../../../redux/api/copyrightApplicationFormTwoApiSlice";
import { useFormik } from "formik";
import { initialValues, validationSchema } from "./validation";
import toast from "react-hot-toast";
import { transformErrorsToObjectStructure } from "../../../../utils/transformErrorsToObjectStructure";
import { createFormData } from "../../../../utils/formDataTransform";
import { useGetCategoriesQuery } from "../../../../redux/api/categoryApiSlice";
import {
  useGetCopyrightQuery,
  useGetOtherSubCategoryQuery,
  useGetRelatedRightQuery,
  useLazyGetSubCategoryCatalogQuery,
} from "../../../../redux/api/categoryCatalogApiSlice";
import { localDateFormat } from "../../../../utils/dateFormat";
import { BeatLoader } from "react-spinners";
import Loader from "../../../../components/custom/Loader";
import { applicationFormTitle } from "../../../../utils/applicationFormTitle";
import { applicationFormShortTitle } from "../../../../utils/applicationFormShortTitle";
import ShowFilePreviewModal from "./ShowPreviewFiles";
import CopyrightPreviewFormTwo from "../../../../components/preview_applications/copyright/CopyrightPreviewFormTwo";

export default function PreviewApplicationFormTwo() {
  const { profileDetailsId, profileId } = useParams();
  const [previewModal, setPreviewModal] = useState(false);
  const [previewFile, setPreviewFile] = useState(null);
  const navigate = useNavigate();

  const {
    data: showData,
    isLoading: isLoadingShow,
    refetch: refetchShow,
  } = useFindApplicationFormTwoQuery(profileDetailsId);

  useEffect(() => {
    refetchShow();
  }, [refetchShow, profileDetailsId]);

  const applicationFormTwo = showData?.data;
  console.log(applicationFormTwo);

  if (isLoadingShow) {
    return <Loader />;
  }
  return (
    <div>
      <section className="section pt-3 pb-5 chuktipotro2 service-single">
        <div className="container">
          <div className="d-flex justify-content-center align-items-center">
            <div className="card w-100">
              <nav>
                <div className="nav nav-tabs" id="nav-tab" role="tablist">
                  <button
                    className="nav-link active"
                    id="nav-application-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#nav-application"
                    type="button"
                    role="tab"
                    aria-controls="nav-application"
                    aria-selected="true"
                  >
                    দ্বিতীয় অংশ
                  </button>
                </div>
              </nav>
              <div
                className="tab-content p-4 border border-top-0"
                id="nav-tabContent"
              >
                <div
                  className="tab-pane fade active show"
                  id="nav-application"
                  role="tabpanel"
                  aria-labelledby="nav-application-tab"
                >
                  <CopyrightPreviewFormTwo data={applicationFormTwo} />

                  <div className="submit-area mt-4">
                    {Number(applicationFormTwo?.form_status) !== 2 && (
                      <div className="text-center d-flex gap-3 justify-content-center">
                        <div>
                          <Link
                            to={`/applicant/application-second-part-update/${profileDetailsId}`}
                            className="custombtn5"
                          >
                            পরিবর্তন করুন
                          </Link>
                        </div>
                        <div>
                          <Link
                            to={`/applicant/application-payment/${profileId}`}
                            className="custombtn5"
                          >
                            পেমেন্ট
                          </Link>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <ShowFilePreviewModal
        setPreviewModal={setPreviewModal}
        previewModal={previewModal}
        previewUrl={previewFile}
        setPreviewUrl={setPreviewFile}
      />
    </div>
  );
}
