import React from "react";
import { Box } from "@mui/material";
import { Spinner } from "react-bootstrap";

const LoadingOverlay = () => {
  return (
    <Box
      sx={{
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "rgba(0, 0, 0, 0.8)",
        gap: 2,
        zIndex: 999999999,
      }}
    >
      {/* Bootstrap Spinner */}
      <Spinner animation="border" role="status" variant="success"></Spinner>
      {/* Loading text */}
      <h6 className="fw-semibold text-success">Redirecting... </h6>
      <p className="fw-semibold text-success">Please wait for next processing.</p>
    </Box>
  );
};

export default LoadingOverlay;
