import {
  WEBSITE_GENERAL_SETTINGS_SHOW,
  WEBSITE_GENERAL_SETTINGS_UPDATE,
  WEBSITE_SETTINGS_CONTACT_SHOW,
  WEBSITE_SETTINGS_CONTACT_UPDATE,
  WEBSITE_SETTINGS_CONTRACT_SHOW,
  WEBSITE_SETTINGS_CONTRACT_UPDATE,
  WEBSITE_SETTINGS_COPYRIGHT_SHOW,
  WEBSITE_SETTINGS_COPYRIGHT_UPDATE,
  WEBSITE_SETTINGS_EMAIL_SHOW,
  WEBSITE_SETTINGS_EMAIL_UPDATE,
  WEBSITE_SETTINGS_FILE_SIZE_SHOW,
  WEBSITE_SETTINGS_FILE_SIZE_UPDATE,
} from "../constants";
import { apiSlice } from "./apiSlice";

const websiteSettingApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    //==file size website setting show and update==//
    showFileSizeWebsiteSetting: builder.query({
      query: () => WEBSITE_SETTINGS_FILE_SIZE_SHOW,
    }),
    updateFileSizeWebsiteSetting: builder.mutation({
      query: (formData) => ({
        url: WEBSITE_SETTINGS_FILE_SIZE_UPDATE,
        method: "POST",
        body: formData,
      }),
    }),

    //==website general setting show and update==//
    showWebsiteGeneralSetting: builder.query({
      query: () => WEBSITE_GENERAL_SETTINGS_SHOW,
    }),
    updateWebsiteGeneralSetting: builder.mutation({
      query: (formData) => ({
        url: WEBSITE_GENERAL_SETTINGS_UPDATE,
        method: "POST",
        body: formData,
      }),
    }),

    //==website setting copyright show and update==//
    showCopyrightWebsiteSetting: builder.query({
      query: () => WEBSITE_SETTINGS_COPYRIGHT_SHOW,
    }),
    updateCopyrightWebsiteSetting: builder.mutation({
      query: (formData) => ({
        url: WEBSITE_SETTINGS_COPYRIGHT_UPDATE,
        method: "POST",
        body: formData,
      }),
    }),

    //==website setting contract show and update==//
    showContractWebsiteSetting: builder.query({
      query: () => WEBSITE_SETTINGS_CONTRACT_SHOW,
    }),
    updateContractWebsiteSetting: builder.mutation({
      query: (formData) => ({
        url: WEBSITE_SETTINGS_CONTRACT_UPDATE,
        method: "POST",
        body: formData,
      }),
    }),

    //==website setting contract show and update==//
    showEmailWebsiteSetting: builder.query({
      query: () => WEBSITE_SETTINGS_EMAIL_SHOW,
    }),
    updateEmailWebsiteSetting: builder.mutation({
      query: (formData) => ({
        url: WEBSITE_SETTINGS_EMAIL_UPDATE,
        method: "POST",
        body: formData,
      }),
    }),

    //==website setting contract show and update==//
    showContactWebsiteSetting: builder.query({
      query: () => WEBSITE_SETTINGS_CONTACT_SHOW,
    }),
    updateContactWebsiteSetting: builder.mutation({
      query: (formData) => ({
        url: WEBSITE_SETTINGS_CONTACT_UPDATE,
        method: "POST",
        body: formData,
      }),
    }),
  }),
});

export const {
  useShowFileSizeWebsiteSettingQuery,
  useUpdateFileSizeWebsiteSettingMutation,
  useShowWebsiteGeneralSettingQuery,
  useUpdateWebsiteGeneralSettingMutation,
  useShowCopyrightWebsiteSettingQuery,
  useUpdateCopyrightWebsiteSettingMutation,
  useShowContractWebsiteSettingQuery,
  useUpdateContractWebsiteSettingMutation,
  useShowEmailWebsiteSettingQuery,
  useUpdateEmailWebsiteSettingMutation,
  useShowContactWebsiteSettingQuery,
  useUpdateContactWebsiteSettingMutation,
} = websiteSettingApiSlice;
