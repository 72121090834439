import {
  AGREEMENT_AGREEMENTS,
  APPLICATION_FORM_TWO_DETAILS_CREATE,
} from "../constants";
import { apiSlice } from "./apiSlice";

const agreementsApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAgreements: builder.query({
      query: (id) => `${AGREEMENT_AGREEMENTS}/${id}`,
      keepUnusedDataFor: 1,
      providesTags: ["AgreementFormOne"],
    }),

    createAgreements: builder.mutation({
      query: (data) => ({
        url: AGREEMENT_AGREEMENTS,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["AgreementFormOne", "applications_list"],
    }),

    updateAgreements: builder.mutation({
      query: ({ id, formData }) => {
        return {
          url: `${AGREEMENT_AGREEMENTS}/${id}`,
          method: "POST",
          body: formData,
        };
      },
      invalidateTags: ["AgreementFormOne", "applications_list"],
    }),

    //agreements details
    getAgreementDetails: builder.query({
      query: (id) => `${APPLICATION_FORM_TWO_DETAILS_CREATE}/${id}`,
      keepUnusedDataFor: 1,
      providesTags: ["AgreementFormTwo"],
    }),
    createAgreementDetails: builder.mutation({
      query: (data) => ({
        url: `${APPLICATION_FORM_TWO_DETAILS_CREATE}`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["AgreementFormTwo", "applications_list"],
    }),
    updateAgreementDetails: builder.mutation({
      query: ({ id, formData }) => ({
        url: `${APPLICATION_FORM_TWO_DETAILS_CREATE}/${id}`,
        method: "POST",
        body: formData,
      }),
      invalidateTags: ["AgreementFormTwo", "applications_list"],
    }),
    deleteAgreementDetails: builder.mutation({
      query: ({ itemId, applicant }) => {
        let url = `${APPLICATION_FORM_TWO_DETAILS_CREATE}/${itemId}`;
        if (applicant) {
          url += `?applicant=${applicant}`;
        }
        return {
          url: url,
          method: "DELETE",
        };
      },
      invalidatesTags: ["AgreementFormTwo"],
    }),

    getContractFullFormPreview: builder.query({
      query: (id) => `/api/applicant/agreements/${id}/edit`,
    }),
  }),
});
export const {
  useGetAgreementsQuery,
  useCreateAgreementsMutation,
  useUpdateAgreementsMutation,

  // agreements details
  useGetAgreementDetailsQuery,
  useCreateAgreementDetailsMutation,
  useUpdateAgreementDetailsMutation,
  useDeleteAgreementDetailsMutation,
  useGetContractFullFormPreviewQuery,
} = agreementsApiSlice;
