import {
  AccountBalance,
  Category,
  Close,
  Dashboard,
  Feed,
  FindInPage,
  LocalLibrary,
  NotificationAdd,
  People,
  Report,
  Settings,
  SettingsApplications,
} from "@mui/icons-material";
import {
  Box,
  Divider,
  Drawer,
  IconButton,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useEffect } from "react";
import { Menu, Sidebar } from "react-pro-sidebar";
import { Link, useLocation } from "react-router-dom";
import { assets } from "../../../assets";
import LoaderSidebar from "../../../components/custom/LoaderSidebar";
import { useAuthUser } from "../../../hooks/useAuthUser";
import { theme } from "../../../theme";
import { accessPermission, accessRoles } from "../../../utils/accessRoles";
import { useAuthorized } from "./../../../hooks/useAuthorized";
import CustomMenuItem from "./MenuItem";
import "./sidebar.css";
import { SubMenuCustom } from "./SubMenuCustom";
import { SubMenuItem } from "./SubMenuItem";

const SideBar = ({ setOpen, open, isCollapse, setIsCollapse }) => {
  const isMdScreen = useMediaQuery((theme) => theme.breakpoints.up("md"));
  const { isLoading, hasRole, hasUserMenuPermissions } = useAuthorized();
  const { user: authUser, isLoading: isLoadingUser } = useAuthUser();

  useEffect(() => {
    if (!isMdScreen) {
      setOpen(false);
    }
  }, [isMdScreen, setOpen]);

  const toggleDrawer = () => {
    setOpen(!open);
  };

  const location = useLocation();
  const applicationPath =
    location.pathname === "/admin/distribution/agreement"
      ? "/admin/distribution/agreement"
      : "/admin/distribution/applications";

  const submittedApplication =
    location.pathname === "/admin/submitted-agreement"
      ? "/admin/submitted-agreement"
      : "/admin/submitted-copyright";
  const submittedTitle =
    authUser?.role_id === 2 ? "চূড়ান্ত নথি" : "সাম্প্রতিক নথি";
  const correctionForm =
    location.pathname === "/admin/correction-form-agreement"
      ? "/admin/correction-form-agreement"
      : "/admin/correction-form-copyright";
  const explanation =
    location.pathname === "/admin/explanation-list-agreement"
      ? "/admin/explanation-list-agreement"
      : "/admin/explanation-list-copyright";
  const replyExplanation =
    location.pathname === "/admin/reply-to-additional-explanation-agreement"
      ? "/admin/reply-to-additional-explanation-agreement"
      : "/admin/reply-to-additional-explanation-copyright";
  const amendment =
    location.pathname === "/admin/amendment-application-agreement"
      ? "/admin/amendment-application-agreement"
      : "/admin/amendment-application-copyright";
  const hearing =
    location.pathname === "/admin/hearing-application-agreement"
      ? "/admin/hearing-application-agreement"
      : "/admin/hearing-application-copyright";
  const letterCorrection =
    location.pathname === "/admin/letter-of-correction-agreement"
      ? "/admin/letter-of-correction-agreement"
      : "/admin/letter-of-correction-copyright";
  const letterCorrectionApproved =
    location.pathname === "/admin/letter-of-correction-approved-agreement"
      ? "/admin/letter-of-correction-approved-agreement"
      : "/admin/letter-of-correction-approved-copyright";
  const replyOrdered =
    location.pathname === "/admin/reply-ordered-agreement"
      ? "/admin/reply-ordered-agreement"
      : "/admin/reply-ordered-copyright";
  const finalRevisedDocument =
    location.pathname === "/admin/final-revised-document-agreement"
      ? "/admin/final-revised-document-agreement"
      : "/admin/final-revised-document-copyright";
  const hearingApproved =
    location.pathname === "/admin/hearing-approved-agreement"
      ? "/admin/hearing-approved-agreement"
      : "/admin/hearing-approved-copyright";
  const hearingList =
    location.pathname === "/admin/hearing-agreement"
      ? "/admin/hearing-agreement"
      : "/admin/hearing-copyright";
  const hearing_schedule =
    location.pathname === "/admin/hearing-schedule-agreement"
      ? "/admin/hearing-schedule-agreement"
      : "/admin/hearing-schedule-copyright";
  const letterCorrectionSent =
    location.pathname === "/admin/letter-of-correction-sent-agreement"
      ? "/admin/letter-of-correction-sent-agreement"
      : "/admin/letter-of-correction-sent-copyright";
  const investigation =
    location.pathname === "/admin/investigation-agreement"
      ? "/admin/investigation-agreement"
      : "/admin/investigation-copyright";
  const investigation_report =
    location.pathname === "/admin/investigation-report-agreement"
      ? "/admin/investigation-report-agreement"
      : "/admin/investigation-report-copyright";
  const recorded =
    location.pathname === "/admin/recorded-agreement"
      ? "/admin/recorded-agreement"
      : "/admin/recorded-copyright";
  const recorded_application =
    location.pathname === "/admin/recorded-application-agreement"
      ? "/admin/recorded-application-agreement"
      : "/admin/recorded-application-copyright";
  const recorded_approved =
    location.pathname === "/admin/recorded-approved-agreement"
      ? "/admin/recorded-approved-agreement"
      : "/admin/recorded-approved-copyright";
  const unauthorized =
    location.pathname === "/admin/unauthorized-agreement"
      ? "/admin/unauthorized-agreement"
      : "/admin/unauthorized-copyright";
  const unauthorized_application =
    location.pathname === "/admin/unauthorized-application-agreement"
      ? "/admin/unauthorized-application-agreement"
      : "/admin/unauthorized-application-copyright";
  const unauthorized_approved =
    location.pathname === "/admin/unauthorized-approved-agreement"
      ? "/admin/unauthorized-approved-agreement"
      : "/admin/unauthorized-approved-copyright";
  const final_approved =
    location.pathname === "/admin/final-approved-agreement"
      ? "/admin/final-approved-agreement"
      : "/admin/final-approved-copyright";
  const unauthorized_reg =
    location.pathname === "/admin/unauthorized-register-book-agreement"
      ? "/admin/unauthorized-register-book-agreement"
      : "/admin/unauthorized-register-book-copyright";
  const recorded_reg =
    location.pathname === "/admin/recorded-register-book-agreement"
      ? "/admin/recorded-register-book-agreement"
      : "/admin/recorded-register-book-copyright";
  const reg_book =
    location.pathname === "/admin/register-book-agreement"
      ? "/admin/register-book-agreement"
      : "/admin/register-book-copyright";
  const indexer_book =
    location.pathname === "/admin/indexer-register-book-agreement"
      ? "/admin/indexer-register-book-agreement"
      : "/admin/indexer-register-book-copyright";
  const certificate_endorse =
    location.pathname === "/admin/endorse-agreement"
      ? "/admin/endorse-agreement"
      : "/admin/certificates-copyright";

  const breakPoint = true;

  return (
    <>
      <Box sx={{ display: "flex" }} position={"relative"}>
        {isMdScreen ||
          (open && (
            <IconButton
              color="inherit"
              aria-label="Open sidebar"
              edge="start"
              onClick={toggleDrawer}
              sx={{
                display: {
                  xs: "block",
                  md: "none",
                  position: "absolute",
                  top: "35px",
                  left: "330px",
                  zIndex: 9999,
                },
              }}
            >
              <Close />
            </IconButton>
          ))}
        <Drawer
          sx={{
            zIndex: 1000,
            position: "absolute",
            width: "20px",
            flexShrink: 0,
            "& .MuiDrawer-paper": {
              width: isCollapse ? "82px" : "260px",
              boxSizing: "border-box",
              overflowY: "auto",
              borderRight: "0px",
              boxShadow: theme.palette.boxShadow,
            },
            "& .MuiDrawer-paper::-webkit-scrollbar": {
              width: "3px",
            },
            "& .MuiDrawer-paper::-webkit-scrollbar-track": {
              background: "#f1f1f1",
            },
            "& .MuiDrawer-paper::-webkit-scrollbar-thumb": {
              background: "#888",
            },
            display: isMdScreen ? "block" : open ? "block" : "none",
          }}
          variant="persistent"
          anchor="left"
          open={isMdScreen || open}
        >
          <Sidebar width="100%" collapsed={isCollapse} breakPoint={breakPoint}>
            {!isLoading && !isLoadingUser ? (
              <>
                <div className="pb-4 text-center bg-success">
                  <Link to="/">
                    <img
                      style={{ width: "100%", padding: "10px" }}
                      src={assets.logo}
                      alt=""
                      className="shadow-sm"
                    />
                  </Link>
                  {authUser?.image ? (
                    <img
                      style={{ height: "80px", margin: "auto" }}
                      src={authUser?.image}
                      alt={authUser?.name}
                      className="rounded p-2 pt-2"
                    />
                  ) : (
                    <img
                      style={{ height: "80px", margin: "auto" }}
                      src={assets.avatar}
                      alt=""
                      className="rounded p-2 pt-2"
                    />
                  )}
                  <Typography
                    className={`${
                      isCollapse ? "d-none" : "d-block"
                    } text-white mt-2`}
                  >
                    {authUser?.name}
                  </Typography>
                  <Typography
                    className={`${
                      isCollapse ? "d-none" : "d-block"
                    } text-center text-white`}
                  >
                    {authUser?.role?.bn_name}
                  </Typography>
                </div>
                <Divider />

                <Menu className="min-h-screen bg-white">
                  <CustomMenuItem
                    title={"ড্যাসবোর্ড"}
                    path={"/admin/dashboard"}
                    icon={<Dashboard sx={{ fontSize: "20px" }} />}
                  />

                  {/* super admin start  */}
                  {hasRole([accessRoles.CopyrightAdmin]) && (
                    <>
                      <CustomMenuItem
                        title={"ইউজার"}
                        path={"/admin/users"}
                        icon={<People sx={{ fontSize: "20px" }} />}
                      />
                      <CustomMenuItem
                        title={"ক্যাটাগরি"}
                        path={"/admin/category"}
                        icon={<Category sx={{ fontSize: "20px" }} />}
                      />
                      <CustomMenuItem
                        title={"ব্যাংক শাখা"}
                        path={"/admin/branches"}
                        icon={<AccountBalance sx={{ fontSize: "20px" }} />}
                      />

                      <SubMenuCustom
                        label={"সেটিংস"}
                        icon={<Settings sx={{ fontSize: "20px" }} />}
                        url={[
                          "/admin/settings",
                          "/admin/registration-systems",
                          "/admin/sample-attachment",
                          "/admin/faq",
                          "/admin/contacts",
                          "/admin/copyright-office",
                        ]}
                      >
                        <SubMenuItem
                          title={"ওয়েবসাইট সেটিংস"}
                          url={"/admin/settings"}
                        />
                        <SubMenuItem
                          title={"রেজিস্ট্রেশনের নিয়মাবলী"}
                          url={"/admin/registration-systems"}
                        />
                        <SubMenuItem
                          title={"প্রশ্ন এবং উত্তর"}
                          url={"/admin/faq"}
                        />
                        <SubMenuItem
                          title={"কপিরাইট অফিস"}
                          url={"/admin/copyright-office"}
                        />
                        <SubMenuItem
                          title={"যোগাযোগ"}
                          url={"/admin/contacts"}
                        />
                      </SubMenuCustom>
                    </>
                  )}

                  {hasRole([
                    accessRoles.Register,
                    accessRoles.AssistantRegister,
                    accessRoles.DeputyRegister,
                    accessRoles.Examiner,
                    accessRoles.AssistantExaminer,
                    accessRoles.Indexer,
                    accessRoles.Inspector,
                    accessRoles.Programmer,
                  ]) && (
                    <CustomMenuItem
                      title={submittedTitle}
                      path={submittedApplication}
                      icon={<Feed sx={{ fontSize: "20px" }} />}
                    />
                  )}
                  {hasRole([
                    accessRoles.AssistantRegister,
                    accessRoles.DeputyRegister,
                    accessRoles.Examiner,
                    accessRoles.AssistantExaminer,
                    accessRoles.Indexer,
                    accessRoles.Inspector,
                    accessRoles.Programmer,
                  ]) && (
                    <>
                      <CustomMenuItem
                        title={"সংরক্ষিত নথি"}
                        path={correctionForm}
                        icon={<Feed sx={{ fontSize: "20px" }} />}
                      />
                      <CustomMenuItem
                        title={"ব্যাখ্যার নথি "}
                        path={explanation}
                        icon={<Feed sx={{ fontSize: "20px" }} />}
                      />
                    </>
                  )}
                  {hasRole([accessRoles.Register]) && (
                    <CustomMenuItem
                      title={"লাইব্রেরিয়ান প্রদত্ত নথি"}
                      path={replyOrdered}
                      icon={<LocalLibrary sx={{ fontSize: "20px" }} />}
                    />
                  )}
                  {hasRole([
                    accessRoles.Register,
                    accessRoles.AssistantRegister,
                    accessRoles.DeputyRegister,
                    accessRoles.Examiner,
                    accessRoles.AssistantExaminer,
                    accessRoles.Indexer,
                    accessRoles.Programmer,
                  ]) && (
                    <CustomMenuItem
                      title={"ব্যাখ্যার জবাব"}
                      path={replyExplanation}
                      icon={<Feed sx={{ fontSize: "20px" }} />}
                    />
                  )}
                  {hasRole([
                    accessRoles.Examiner,
                    accessRoles.AssistantExaminer,
                    accessRoles.Indexer,
                  ]) && (
                    <>
                      {/* correction letter  */}
                      <CustomMenuItem
                        title={"সংশোধনের চিঠি"}
                        path={letterCorrection}
                        icon={<Feed sx={{ fontSize: "20px" }} />}
                      />
                      <CustomMenuItem
                        title={"সংশোধনের চিঠি (অনুমোদিত)"}
                        path={letterCorrectionApproved}
                        icon={<Feed sx={{ fontSize: "20px" }} />}
                      />
                      <CustomMenuItem
                        title={"সংশোধিত নথি"}
                        path={finalRevisedDocument}
                        icon={<Feed sx={{ fontSize: "20px" }} />}
                      />
                      {/* hearing  */}
                      <CustomMenuItem
                        title={"শুনানীর চিঠি"}
                        path={hearingList}
                        icon={<Feed sx={{ fontSize: "20px" }} />}
                      />
                      <CustomMenuItem
                        title={"শুনানীর চিঠি (অনুমোদিত)"}
                        path={hearingApproved}
                        icon={<Feed sx={{ fontSize: "20px" }} />}
                      />
                    </>
                  )}
                  {hasRole([
                    accessRoles.Register,
                    accessRoles.DeputyRegister,
                    accessRoles.Programmer,
                    accessRoles.Examiner,
                  ]) && (
                    <>
                      <CustomMenuItem
                        title={"সংশোধনের নথি"}
                        path={amendment}
                        icon={<Feed sx={{ fontSize: "20px" }} />}
                      />
                      <CustomMenuItem
                        title={"শুনানীর নথি"}
                        path={hearing}
                        icon={<Feed sx={{ fontSize: "20px" }} />}
                      />
                    </>
                  )}

                  {hasRole([
                    accessRoles.CopyrightAdmin,
                    accessRoles.Register,
                    accessRoles.DeputyRegister,
                    accessRoles.Programmer,
                    accessRoles.Examiner,
                    accessRoles.AssistantExaminer,
                    accessRoles.Indexer,
                    accessRoles.Inspector,
                  ]) && (
                    <CustomMenuItem
                      title={"সংশোধনের জন্য প্রেরিত"}
                      path={letterCorrectionSent}
                      icon={<Feed sx={{ fontSize: "20px" }} />}
                    />
                  )}
                  {/* শুনানী গ্রহনের নথি */}
                  {hasRole([
                    accessRoles.Register,
                    accessRoles.DeputyRegister,
                    accessRoles.Examiner,
                    accessRoles.AssistantExaminer,
                    accessRoles.Indexer,
                  ]) && (
                    <CustomMenuItem
                      title={`শুনানী গ্রহনের নথি`}
                      path={hearing_schedule}
                      icon={<Feed sx={{ fontSize: "20px" }} />}
                    />
                  )}
                  {/* তদন্তের  নথি */}
                  {hasRole([
                    accessRoles.AssistantRegister,
                    accessRoles.DeputyRegister,
                    accessRoles.Examiner,
                    accessRoles.AssistantExaminer,
                    accessRoles.Indexer,
                    accessRoles.Inspector,
                    accessRoles.Programmer,
                  ]) && (
                    <CustomMenuItem
                      title={`তদন্তের নথি`}
                      path={investigation}
                      icon={<Feed sx={{ fontSize: "20px" }} />}
                    />
                  )}
                  {/* তদন্তের রিপোর্ট */}
                  {hasRole([
                    accessRoles.Register,
                    accessRoles.DeputyRegister,
                  ]) && (
                    <CustomMenuItem
                      title={`তদন্তের রিপোর্ট`}
                      path={investigation_report}
                      icon={<Feed sx={{ fontSize: "20px" }} />}
                    />
                  )}
                  {/* নথিজাত  */}
                  {hasRole([
                    accessRoles.Register,
                    accessRoles.DeputyRegister,
                  ]) && (
                    <>
                      {/* // recorded */}
                      <CustomMenuItem
                        title={`নথিজাত নথি`}
                        path={recorded}
                        icon={<Feed sx={{ fontSize: "20px" }} />}
                      />
                      {/* // unauthorized */}
                      <CustomMenuItem
                        title={`অননুমোদিত নথি`}
                        path={unauthorized_application}
                        icon={<Feed sx={{ fontSize: "20px" }} />}
                      />
                    </>
                  )}
                  {hasRole([accessRoles.Indexer]) && (
                    <>
                      {/* recorded  */}
                      <CustomMenuItem
                        title={`চূড়ান্তভাবে নথিজাত`}
                        path={recorded_application}
                        icon={<Feed sx={{ fontSize: "20px" }} />}
                      />
                      <CustomMenuItem
                        title={`নথিজাত অনুমোদিত`}
                        path={recorded_approved}
                        icon={<Feed sx={{ fontSize: "20px" }} />}
                      />

                      {/* unauthorized */}
                      <CustomMenuItem
                        title={`চূড়ান্তভাবে অননুমোদন`}
                        path={unauthorized}
                        icon={<Feed sx={{ fontSize: "20px" }} />}
                      />
                      <CustomMenuItem
                        title={`অননুমোদন অননুমোদিত`}
                        path={unauthorized_approved}
                        icon={<Feed sx={{ fontSize: "20px" }} />}
                      />
                    </>
                  )}
                  {hasRole([accessRoles.Calligrapher]) && (
                    <>
                      {/* recorded  */}
                      <CustomMenuItem
                        title={`চূড়ান্তভাবে অনুমোদিত নথি`}
                        path={final_approved}
                        icon={<Feed sx={{ fontSize: "20px" }} />}
                      />
                    </>
                  )}
                  {hasRole([accessRoles.Librarian]) && (
                    <>
                      <CustomMenuItem
                        title={`নথি অনুসন্ধান আদেশ`}
                        path={"/admin/comment-and-ordered-search-list"}
                        icon={<Feed sx={{ fontSize: "20px" }} />}
                      />
                      {/* register  */}
                      <CustomMenuItem
                        title={`রেজিস্টার বুক`}
                        path={reg_book}
                        icon={<Feed sx={{ fontSize: "20px" }} />}
                      />
                    </>
                  )}
                  {hasRole([accessRoles.Librarian, accessRoles.Indexer]) && (
                    <>
                      {/* unauthorized  */}
                      <CustomMenuItem
                        title={`অননুমোদিত রেজিস্টার বুক`}
                        path={unauthorized_reg}
                        icon={<Feed sx={{ fontSize: "20px" }} />}
                      />
                      {/* recorded  */}
                      <CustomMenuItem
                        title={`নথিজাত রেজিস্টার বুক`}
                        path={recorded_reg}
                        icon={<Feed sx={{ fontSize: "20px" }} />}
                      />
                    </>
                  )}
                  {hasRole([accessRoles.Indexer]) && (
                    <>
                      {/* indexer book  */}
                      <CustomMenuItem
                        title={`ইনডেক্স রেজিস্টার বুক`}
                        path={indexer_book}
                        icon={<Feed sx={{ fontSize: "20px" }} />}
                      />
                    </>
                  )}
                  {hasRole([
                    accessRoles.Register,
                    accessRoles.Calligrapher,
                  ]) && (
                    <>
                      {/* সার্টিফিকেট & এন্ডোর্স */}
                      <CustomMenuItem
                        title={`সার্টিফিকেট & এন্ডোর্স`}
                        path={certificate_endorse}
                        icon={<Feed sx={{ fontSize: "20px" }} />}
                      />
                    </>
                  )}
                  {hasRole([
                    accessRoles.Calligrapher,
                    accessRoles.CopyrightAdmin,
                  ]) && (
                    <>
                      <CustomMenuItem
                        title={`এটেনশন বার্তা`}
                        path={"/admin/attention-messages"}
                        icon={<NotificationAdd sx={{ fontSize: "20px" }} />}
                      />
                    </>
                  )}
                  {hasRole([
                    accessRoles.CopyrightAdmin,
                    accessRoles.Register,
                    accessRoles.DeputyRegister,
                  ]) && (
                    <SubMenuCustom
                      label={"নথি রিপোর্ট"}
                      icon={<Report sx={{ fontSize: "20px" }} />}
                      url={[
                        "/admin/file-activity-list",
                        "/admin/user-file-count",
                      ]}
                    >
                      <SubMenuItem
                        title={"কর্মসূচি"}
                        url={"/admin/file-activity-list"}
                      />
                      <SubMenuItem
                        title={"নথি পর্যবেক্ষণ"}
                        url={"/admin/user-file-count"}
                      />
                    </SubMenuCustom>
                  )}

                  {hasUserMenuPermissions([accessPermission.search]) && (
                    <>
                      <CustomMenuItem
                        title={
                          hasRole([
                            accessRoles.Register,
                            accessRoles.CopyrightAdmin,
                          ])
                            ? `নথি সন্ধান`
                            : `আবেদনের অবস্থান অনুসন্ধান`
                        }
                        path={"/admin/applications-tracking"}
                        icon={<FindInPage sx={{ fontSize: "20px" }} />}
                      />
                    </>
                  )}

                  {/* super admin end  */}
                  {hasUserMenuPermissions([accessPermission.bonthon]) && (
                    <CustomMenuItem
                      title={"আবেদন পত্র বণ্টন"}
                      path={applicationPath}
                      icon={<SettingsApplications sx={{ fontSize: "20px" }} />}
                    />
                  )}
                  {hasUserMenuPermissions([accessPermission.transfer]) && (
                    <CustomMenuItem
                      title={"নথি স্থানান্তর"}
                      path={"/admin/all-applications-transfer"}
                      icon={<SettingsApplications sx={{ fontSize: "20px" }} />}
                    />
                  )}
                </Menu>
              </>
            ) : (
              <LoaderSidebar />
            )}
          </Sidebar>
          <div className="py-5"></div>
        </Drawer>
      </Box>
    </>
  );
};

export default SideBar;
