import React from "react";
import CopyrightFormPreview from "../preview_applications/full-form-preview-copyright/CopyrightFormPreview";
import Loader from "../custom/Loader";
import { useShowCopyrightFullFormQuery } from "../../redux/api/submittedCopyrightAgreementApiSlice";

const AdminFullFormCopyright = ({ info, printRef }) => {
  const { id, trackReg } = info;
  const { data, isLoading } = useShowCopyrightFullFormQuery({
    id,
    trackReg,
  });

  if (isLoading) {
    return <Loader />;
  }

  return (
    <div className="slideUp">
      <CopyrightFormPreview printRef={printRef} data={data} />
    </div>
  );
};

export default AdminFullFormCopyright;
