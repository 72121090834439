import React from "react";
import { Container, Box, Typography } from "@mui/material";
import { assets } from "../../../assets";
import { useParams } from "react-router-dom";

function SuccessCorrection() {
  const { appType } = useParams();
  setTimeout(() => {
    window.location.href = "/applicant";
  }, [3000]);
  return (
    <Container
      className="d-flex align-items-center justify-content-center mt-5"
      style={{ minHeight: "250px" }}
    >
      <Box textAlign="center" py={3}>
        <img
          src={assets?.success}
          style={{ height: "200px" }}
          className="mb-3"
          alt=""
        />

        <Typography variant="h6" component="h1" gutterBottom>
          আপনার{" "}
          {Number(appType) === 1
            ? "কপিরাইট আবেদন পত্রটি"
            : "চুক্তিপত্র নিবন্ধনটি"}{" "}
          সফলভাবে সংশোধন করা হয়েছে।
        </Typography>
      </Box>
    </Container>
  );
}

export default SuccessCorrection;
