import React from "react";
import { useParams } from "react-router-dom";
import {
  useShowPaymentQuery,
  useUpdateTresaryPaymentMutation,
} from "../../../redux/api/editFormApiSlice";
import TreasuryPaymentUi from "../../../components/TreasuryPaymentUi";

const TreasuryPaymentEdit = () => {
  const { id, trackReg } = useParams();
  const { data, isLoading } = useShowPaymentQuery({ id, trackReg });
  const mainData = data?.data;

  const [
    updateApplication,
    {
      isLoading: isLoadingUpdate,
      error: errorUpdate,
      isError: isErrorUpdate,
      data: dataUpdate,
      isSuccess: isSuccessUpdate,
    },
  ] = useUpdateTresaryPaymentMutation();

  return (
    <TreasuryPaymentUi
      mainData={mainData}
      isLoading={isLoading}
      isLoadingUpdate={isLoadingUpdate}
      errorUpdate={errorUpdate}
      isErrorUpdate={isErrorUpdate}
      dataUpdate={dataUpdate}
      isSuccessUpdate={isSuccessUpdate}
      updateApplication={updateApplication}
    />
  );
};

export default TreasuryPaymentEdit;
