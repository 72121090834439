import {
  KeyboardArrowDownRounded,
  KeyboardArrowUpRounded,
} from "@mui/icons-material";
import QuizRoundedIcon from "@mui/icons-material/QuizRounded";
import React from "react";
import { Link } from "react-router-dom";
import { useFetchFaqClientQuery } from "../../redux/api/faqApiSlice";
import Loader from "../../components/custom/Loader";

export default function FAQ() {
  const { isLoading, data } = useFetchFaqClientQuery();

  if (isLoading) {
    return <Loader />;
  }
  return (
    <div className="">
      <div className="breadcrumbs" data-stellar-background-ratio="0.5">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-6 col-12">
              <div className="breadcrumbs-content left">
                <h1 className="page-title">জিজ্ঞাসা সমূহ</h1>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-12">
              <div className="breadcrumbs-content right">
                <ul className="breadcrumb-nav">
                  <li>
                    <Link to="/" style={{
                          fontSize: 20,
                        }}>হোম পেইজ</Link>
                  </li>
                  <li style={{
                          fontSize: 20,
                        }}>জিজ্ঞাসা সমূহ</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <section id="faq" className="faq section-bg pt-5 mb-5 pb-0 section">
        <div className="container" data-aos="fade-up">
          <div className="accordion-list ">
            <ul>
              {data?.data.map((item, i) => (
                <li
                  data-aos="fade-up p-5"
                  key={i}
                  style={{
                    padding: "70px",
                  }}
                >
                  <div className="icon-help ms-0">
                    <QuizRoundedIcon />
                  </div>

                  <a
                    data-bs-toggle="collapse"
                    className="collapse"
                    data-bs-target={`#accordion-list${i}`}
                    style={{
                      fontSize: "20px",
                      fontWeight: "bold",
                    }}
                  >
                    {item?.question}{" "}
                    <div className=" icon-show">
                      {" "}
                      <KeyboardArrowDownRounded />{" "}
                    </div>{" "}
                    <div className="icon-close">
                      {" "}
                      <KeyboardArrowUpRounded />{" "}
                    </div>{" "}
                  </a>
                  <div
                    id={`accordion-list${i}`}
                    className={`collapse ${i === 0 ? "show" : ""}`}
                    data-bs-parent=".accordion-list"
                  >
                    <p
                      style={{
                        fontSize: "17px",
                      }}
                    >
                      {item?.answer}
                    </p>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </section>
    </div>
  );
}
