import React from "react";
import SubmittedCopyright from "../sumitted_copyright/SubmittedCopyright";

const AmendmentCopyrightApplication = () => {
  const fileStatus = 21;
  const applicationPath = {
    copyright: "/admin/amendment-application-copyright",
    agreement: "/admin/amendment-application-agreement",
  };
  const detailsRoute = "/admin/letter-of-correction-copyright-details";
  const pageTitle = "সংশোধনের নথি";
  return (
    <SubmittedCopyright
      fileStatus={fileStatus}
      applicationPath={applicationPath}
      detailsRoute={detailsRoute}
      pageTitle={pageTitle}
    />
  );
};

export default AmendmentCopyrightApplication;
