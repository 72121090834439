import React, { useEffect } from "react";
import { Button } from "react-bootstrap";
import { RestartAlt } from "@mui/icons-material";
import Select from "react-select";
import { useGetRoleNotInQuery } from "../../../../redux/api/categoryApiSlice";
import { useLazyGetByRoleQuery } from "../../../../redux/api/applicationCopyrightApiSlice";
import { useAuth } from "../../../../utils/authorization";
import { useGetRolesQuery } from "../../../../redux/api/userApiSlice";

const UserFileCountFilter = ({
  user_id,
  setUserId,
  role_id,
  setRoleId,
  appType,
  setAppType,
}) => {
  const { authUser } = useAuth();
  const { data: roles } = useGetRolesQuery();
  const rolesOption =
    roles?.data?.map((data) => ({
      value: data.id,
      label: data.bn_name,
    })) || [];

  const [findUser, { data: users, isLoading }] = useLazyGetByRoleQuery();

  const usersOption =
    (users?.data &&
      Object.entries(users.data)
        .filter(([key]) => authUser.id !== Number(key))
        .map(([key, value]) => ({
          value: Number(key),
          label: value,
        }))) ||
    [];

  useEffect(() => {
    if (role_id) {
      findUser(role_id);
    }
  }, [role_id]);

  const handleReset = () => {
    setUserId("");
    setRoleId("");
    setAppType("");
  };

  const type = [
    {
      value: "",
      label: "নির্বাচন করুন",
    },
    {
      value: 1,
      label: "কপিরাইট",
    },
    {
      value: 2,
      label: "চুক্তিপত্র",
    },
  ];

  return (
    <>
      <div>
        <div
          style={{
            backgroundColor: "rgba(0, 0, 0, 0.1)",
          }}
          className="rounded mb-3 p-2"
        >
          <div className="row gap-2 gap-md-0">
            <div className="col-md-3 m-0">
              <Select
                options={rolesOption}
                value={
                  role_id
                    ? rolesOption.find((option) => option.value === role_id)
                    : { value: "", label: "--- পদবী ---", isDisabled: true }
                }
                onChange={(selectedOption) => {
                  setRoleId(selectedOption.value);
                }}
                classNamePrefix="react-select"
              />
            </div>
            <div className="col-md-3 m-0">
              <Select
                options={usersOption}
                value={
                  user_id
                    ? usersOption.find((option) => option.value === user_id)
                    : {
                        value: "",
                        label: "--- ব্যবহারকারী ---",
                        isDisabled: true,
                      }
                }
                onChange={(selectedOption) => {
                  setUserId(selectedOption.value);
                }}
                isDisabled={isLoading}
                classNamePrefix="react-select"
              />
            </div>
            {/* <div className="col-md-3 m-0">
              <Select
                options={type}
                value={
                  appType
                    ? type.find((option) => option.value === appType)
                    : {
                        value: "",
                        label: "--- নির্বাচন করুন ---",
                        isDisabled: true,
                      }
                }
                onChange={(selectedOption) => {
                  setAppType(selectedOption.value);
                }}
                classNamePrefix="react-select"
              />
            </div> */}

            <div className="col-md-4 m-0"></div>
            <div className="col-md-2 m-0">
              <div className=" d-flex justify-content-end">
                <Button
                  onClick={handleReset}
                  variant="danger"
                  className="w-100 text-center"
                >
                  <div className="d-flex align-items-center justify-content-center">
                    <RestartAlt /> Reset
                  </div>
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UserFileCountFilter;
