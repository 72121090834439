import { apiSlice } from "./apiSlice";
import { COPYRIGHT_OFFICE, COPYRIGHT_OFFICE_UPDATE } from "../constants";

export const copyrightOfficeApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getCopyrightOffice: builder.query({
      query: () => COPYRIGHT_OFFICE,
      providesTags: ["copyright_office"],
    }),
    createOrUpdateCopyrightOffice: builder.mutation({
      query: (formData) => ({
        url: `${COPYRIGHT_OFFICE_UPDATE}`,
        method: "POST",
        body: formData,
      }),
      invalidatesTags: ["copyright_office"],
    }),
  }),
});

export const {
  useGetCopyrightOfficeQuery,
  useCreateOrUpdateCopyrightOfficeMutation,
} = copyrightOfficeApiSlice;
