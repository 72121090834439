import { useFormik } from "formik";
import React, { useEffect, useRef } from "react";
import { Button, Form } from "react-bootstrap";
import toast from "react-hot-toast";
import { useNavigate, useParams } from "react-router-dom";
import Select from "react-select";
import { BeatLoader } from "react-spinners";
import Swal from "sweetalert2";
import * as Yup from "yup";
import { useLazyGetByRoleQuery } from "../../redux/api/applicationCopyrightApiSlice";
import { useGetRoleNotInQuery } from "../../redux/api/categoryApiSlice";
import { useForwardSentAgreementDetailsMutation } from "../../redux/api/submittedCopyrightAgreementApiSlice";
import { useAuth } from "../../utils/authorization";
import { transformErrorsToObjectStructure } from "../../utils/transformErrorsToObjectStructure";

const SentLetterCorrectionContent = ({ info }) => {
  const { authUser } = useAuth();
  const { id } = useParams();
  const navigate = useNavigate();
  const printLetter = useRef();
  const [
    submitData,
    {
      isLoading,
      isSuccess: isSuccessSubmit,
      error: errorSubmit,
      isError: isErrorSubmit,
      data: dataSubmit,
    },
  ] = useForwardSentAgreementDetailsMutation();

  const roleMapping = "[1,9,10,12]";

  const { data: roles } = useGetRoleNotInQuery(roleMapping);
  const rolesOption =
    roles?.data?.map((data) => ({
      value: data.id,
      label: data.bn_name,
    })) || [];

  const [findUser, { data: users }] = useLazyGetByRoleQuery();

  const usersOption =
    (users?.data &&
      Object.entries(users.data)
        .filter(([key]) => authUser.id !== Number(key))
        .map(([key, value]) => ({
          value: Number(key),
          label: value,
        }))) ||
    [];

  const validationSchema = Yup.object().shape({
    role_id: Yup.number().required("পদবী প্রযোজ্য।"),
    user_id: Yup.number().required("প্রাপক প্রযোজ্য।"),
    comments: Yup.string().required("মন্তব্য প্রযোজ্য।"),
  });

  const formik = useFormik({
    initialValues: {
      role_id: "",
      user_id: "",
      comments: "",
    },
    validationSchema,
    onSubmit: (values) => {
      const formData = values;
      Swal.fire({
        text: "আপনি কি নিশ্চিত?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "না",
        confirmButtonText: "হ্যাঁ",
      }).then((result) => {
        if (result.isConfirmed) {
          submitData({ id, formData });
        }
      });
    },
  });
  useEffect(() => {
    if (isSuccessSubmit) {
      toast.success(dataSubmit?.message);
      formik.resetForm();
      navigate(-1);
    }
    if (isErrorSubmit) {
      toast.error(errorSubmit?.data?.message);
      formik.setErrors(
        transformErrorsToObjectStructure(errorSubmit.data?.data || {})
      );
    }
    // eslint-disable-next-line
  }, [isErrorSubmit, isSuccessSubmit]);

  const defaultValue = {
    value: "",
    label: "নির্বাচন করুন",
  };

  return (
    <>
      <div className="bg-slate p-2 mb-3">
        <div className="fw-semibold">মন্তব্য</div>
      </div>

      <form onSubmit={formik.handleSubmit}>
        <div className="row">
          <div className="col-md-12">
            <>
              <Form.Control
                name="comments"
                placeholder="এখানে লিখুন"
                aria-label="Username"
                aria-describedby="basic-addon1"
                as={"textarea"}
                rows={6}
                value={formik.values.comments}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                isInvalid={formik.touched.comments && formik.errors.comments}
              />
              {formik.touched.comments && formik.errors.comments ? (
                <div className="text-danger">{formik.errors.comments}</div>
              ) : null}
            </>
          </div>
          <div className="col-md-6">
            <label className="my-2 fw-semibold" htmlFor="role_id">
              পদবী
            </label>
            <Select
              name="role_id"
              options={rolesOption}
              value={
                formik.values.role_id
                  ? rolesOption.find(
                      (option) => option.value === formik.values.role_id
                    )
                  : defaultValue
              }
              onChange={(selectedOption) => {
                findUser(selectedOption.value);
                formik.setValues((prev) => {
                  return {
                    ...prev,
                    user_id: "",
                  };
                });
                formik.setFieldValue("role_id", selectedOption.value);
              }}
              onBlur={formik.handleBlur}
              classNamePrefix="react-select"
            />
            {formik.touched.role_id && formik.errors.role_id ? (
              <div className="text-danger">{formik.errors.role_id}</div>
            ) : null}
          </div>
          <div className="col-md-6">
            <label className="my-2 fw-semibold" htmlFor="user_id">
              প্রাপক
            </label>
            <Select
              name="user_id"
              options={usersOption}
              value={
                formik.values.user_id
                  ? usersOption.find(
                      (option) => option.value === formik.values.user_id
                    )
                  : defaultValue
              }
              onChange={(selectedOption) =>
                formik.setFieldValue("user_id", selectedOption.value)
              }
              onBlur={formik.handleBlur}
              classNamePrefix="react-select"
            />
            {formik.touched.user_id && formik.errors.user_id ? (
              <div className="text-danger">{formik.errors.user_id}</div>
            ) : null}
          </div>

          <div className="mt-4 text-center">
            <Button variant="success" type="submit" disabled={isLoading}>
              {isLoading ? <BeatLoader /> : "দাখিল করুন"}
            </Button>
          </div>
        </div>
      </form>
    </>
  );
};

export default SentLetterCorrectionContent;
