export const detailsUrlCopyright = (role, id) => {
  // switch case
  switch (role) {
    case 8:
      return `/admin/submitted-copyright-details-inspector/${id}`;
    case 7:
      return `/admin/submitted-copyright-details-indexer/${id}`;
    default:
      return `/admin/submitted-copyright-details-verify/${id}`;
  }
};
export const detailsUrlAgreement = (role, id) => {
  // switch case
  switch (role) {
    case 8:
      return `/admin/submitted-agreement-details-inspector/${id}`;
    case 7:
      return `/admin/submitted-agreement-details-indexer/${id}`;
    default:
      return `/admin/submitted-agreement-details-verify/${id}`;
  }
};
