import { useFormik } from "formik";
import React, { useEffect } from "react";
import { Button, Form, Table } from "react-bootstrap";
import toast from "react-hot-toast";
import { useCopyrightOriginalityTestMutation } from "../../../../../redux/api/submittedCopyrightAgreementApiSlice";
import { BeatLoader } from "react-spinners";
import * as yup from "yup";
import { Search } from "@mui/icons-material";

const OriginalityTestCopyright = ({ info, setOriginalityData }) => {
  const [originalityTest, { isLoading, isError, isSuccess, error, data }] =
    useCopyrightOriginalityTestMutation();

  const formik = useFormik({
    initialValues: {
      id: "",
      workTitle: "",
      category_id: "",
      workLanguage: "",
      workDescription: "",
    },
    onSubmit: (values) => {
      originalityTest(values);
    },
  });

  useEffect(() => {
    if (info) {
      formik.setValues({
        id: info.id,
        workTitle: info.workTitle,
        category_id: info?.categoryData
          ? info?.categoryData?.id
          : info.category?.id,
        workLanguage: info.workLanguage,
        workDescription: info.workDescription,
      });
    }
  }, [info]);

  useEffect(() => {
    if (isSuccess) {
      setOriginalityData(data.data);
    }
    if (isError) {
      toast.error(error?.message);
    }
  }, [isSuccess, isError]);

  return (
    <>
      <div className="card h-100">
        <div className="bg-slate p-2 fw-semibold ">মৌলিকত্ব পরীক্ষা</div>
        <div className="pt-3 p-3">
          <div className="row">
            <div className="col-md-12">
              <label className="fw-semibold">কর্মের শিরোনাম লিখুন</label>
              <Form.Control
                aria-label="Username"
                aria-describedby="basic-addon1"
                value={formik.values.workTitle}
                onChange={formik.handleChange}
                as={"textarea"}
                rows={4}
                readOnly
              />
            </div>
            <div className="col-md-6 mt-3">
              <label className="fw-semibold">কর্মের শ্রেণি</label>
              <Form.Control
                placeholder="কর্মের শ্রেণি"
                aria-label="Username"
                aria-describedby="basic-addon1"
                value={
                  info?.categoryData
                    ? info?.categoryData?.name
                    : info.category?.name
                }
                readOnly
              />
            </div>
            <div className="col-md-6 mt-3">
              <label className="fw-semibold">কর্মের ভাষা</label>
              <Form.Control
                placeholder="কর্মের ভাষা"
                aria-label="Username"
                aria-describedby="basic-addon1"
                value={formik.values.workLanguage}
                readOnly
              />
            </div>
            <div className="col-md-12 mt-3">
              <label className="fw-semibold">কর্মের বিবরন</label>

              <Form.Control
                placeholder="কর্মের বিবরন"
                aria-label="Usernasame"
                aria-describedby="basic-addasdon1"
                value={formik.values.workDescription}
                onChange={formik.handleChange}
                as={"textarea"}
                rows={4}
                readOnly
              />
            </div>
            <div className="col-md-12 mt-4">
              <div className="text-center">
                <Button
                  onClick={formik.handleSubmit}
                  disabled={isLoading}
                  className="px-4"
                  variant="success"
                >
                  {isLoading ? (
                    <BeatLoader color="#fff" />
                  ) : (
                    <>
                      <Search /> খুঁজুন
                    </>
                  )}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default OriginalityTestCopyright;
