import React from "react";
import SubmittedAgreement from "../sumitted_agreement/SubmittedAgreement";

const LetterCorrectionApprovedAgreement = () => {
  const fileStatus = 22;
  const applicationPath = {
    copyright: "/admin/letter-of-correction-approved-copyright",
    agreement: "/admin/letter-of-correction-approved-agreement",
  };
  
  // Add route to details page
  const detailsRoute = "/admin/letter-of-correction-agreement-details";
  const pageTitle = "সংশোধনের চিঠি (অনুমোদিত)";

  return (
    <SubmittedAgreement
      fileStatus={fileStatus}
      applicationPath={applicationPath}
      detailsRoute={detailsRoute}
      pageTitle={pageTitle}
    />
  );
};

export default LetterCorrectionApprovedAgreement;
