import React, { useEffect } from "react";
import { Button } from "react-bootstrap";
import { RestartAlt } from "@mui/icons-material";
import Select from "react-select";
import Loader from "../../../../components/custom/Loader";
import { globalDateFormat } from "../../../../utils/dateFormat";
import BanglaCalender from "../../../frontend/userPanel/form_two/BanglaCalender";
import { useGetAdminUserQuery } from "../../../../redux/api/userApiSlice";

const FileActivityFilter = ({
  setDate,
  date,
  setSendTo,
  sendTo,
  setSendFrom,
  sendFrom,
  setPage,
}) => {
  // category
  const { data, isLoading } = useGetAdminUserQuery({ per_page: 500 });

  if (isLoading) return <Loader />;

  const user = data?.data?.map((data) => ({
    value: data.id,
    label: data.name,
  }));

  const handleDateChange = (d) => {
    setPage(1);
    setDate(globalDateFormat(d));
  };

  const handleReset = () => {
    setPage(1);
    setDate("");
    setSendTo("");
    setSendFrom("");
  };

  return (
    <>
      <div>
        <div
          style={{
            backgroundColor: "rgba(0, 0, 0, 0.1)",
          }}
          className="rounded mb-3 p-2"
        >
          <div className="row gap-2 gap-md-0">
            <div className="col-md-3 m-0">
              <BanglaCalender
                placeholder={"তারিখ"}
                value={date}
                onChange={handleDateChange}
              />
            </div>
            <div className="col-md-3 m-0">
              <Select
                options={user}
                value={
                  sendFrom
                    ? user.find((option) => option.value === sendFrom)
                    : { value: "", label: "--- হইতে ---", isDisabled: true }
                }
                onChange={(selectedOption) => {
                  setPage(1);
                  setSendFrom(selectedOption.value);
                }}
                classNamePrefix="react-select"
              />
            </div>
            <div className="col-md-3 m-0">
              <Select
                options={user}
                value={
                  sendTo
                    ? user.find((option) => option.value === sendTo)
                    : { value: "", label: "--- প্রতি ---", isDisabled: true }
                }
                onChange={(selectedOption) => {
                  setPage(1);
                  setSendTo(selectedOption.value);
                }}
                classNamePrefix="react-select"
              />
            </div>

            <div className="col-md-3 m-0">
              <div className=" d-flex justify-content-end">
                <Button
                  onClick={handleReset}
                  variant="danger"
                  className="w-100 text-center"
                >
                  <div className="d-flex align-items-center justify-content-center">
                    <RestartAlt /> Reset
                  </div>
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FileActivityFilter;
