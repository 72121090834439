import React from "react";
import CommonDetailsIndexer from "./CommonDetailsIndexer";
import AgreementPreviewFormOne from "../../../../components/preview_applications/agreement/AgreementPreviewFormOne";
import AgreementPreviewFormTwo from "../../../../components/preview_applications/agreement/AgreementPreviewFormTwo";
import { useParams } from "react-router-dom";
import Loader from "../../../../components/custom/Loader";
import {
  useCopyrightTreasuryVerifyMutation,
  useGetSubmittedCopyrightDetailsQuery,
} from "../../../../redux/api/submittedCopyrightAgreementApiSlice";
import CopyrightPreviewFormOne from "../../../../components/preview_applications/copyright/CopyrightPreviewFormOne";
import CopyrightPreviewFormTwo from "../../../../components/preview_applications/copyright/CopyrightPreviewFormTwo";
import PaymentPreview from "../../../../components/preview_applications/payment/PaymentPreview";

const IndexerCopyrightDetails = () => {
  const { id } = useParams();
  const { data, isLoading } = useGetSubmittedCopyrightDetailsQuery(id, {
    refetchOnMountOrArgChange: true,
  });
  const treasuryChalanVerify = useCopyrightTreasuryVerifyMutation();

  if (isLoading) {
    return <Loader />;
  }

  const info = {
    noteNumber: data?.data?.trackReg,
    st: Number(data?.data?.st),
    id: Number(data?.data?.id),
    apptype: Number(data?.data?.apptype) === 1 ? "কপিরাইট" : "চুক্তিপত্র",
    treasuryDate: data?.data?.treasuryDate || new Date(),
    challanid: data?.data?.challanid,
    workTitle: data?.data?.profileDetail?.workTitle,
    category: data?.data?.profileDetail?.category,
    subCategory: data?.data?.profileDetail?.subCategory,
    workDescription: data?.data?.profileDetail?.workDescription,
    workLanguage: data?.data?.profileDetail?.workLanguage,
    admin: data?.data?.admin,

    treasuryNo: data?.data?.treasuryNo,
    bankbranch: data?.data?.bankbranch,
    appType: Number(data?.data?.apptype),

    basicStatus: Number(data?.data?.basicStatus),
    treasuryStatus: Number(data?.data?.treasuryStatus),
  };

  const formOne = (
    <div className="pt-5">
      <CopyrightPreviewFormOne data={data?.data} />
    </div>
  );
  const formTwo = (
    <div className="pt-5">
      <CopyrightPreviewFormTwo data={data?.data?.profileDetail} />
    </div>
  );
  const url = `/admin/submitted-copyright-details-indexer-second-step/${info.id}`;
  return (
    <div>
      <CommonDetailsIndexer
        url={url}
        formOne={formOne}
        formTwo={formTwo}
        payment={
          <div className="p-4 mt-5">
            <PaymentPreview data={data?.data} />
          </div>
        }
        info={info}
        treasuryChalanVerify={treasuryChalanVerify}
      />
    </div>
  );
};

export default IndexerCopyrightDetails;
