import React from "react";
import SubmittedAgreement from "../../sumitted_agreement/SubmittedAgreement";

const UnauthorizedAgreementApplication = () => {
  const fileStatus = 29;
  const applicationPath = {
    copyright: "/admin/unauthorized-application-copyright",
    agreement: "/admin/unauthorized-application-agreement",
  };
  const detailsRoute = "/admin/unauthorized-agreement-details";
  const pageTitle = "অননুমোদিত নথি";
  return (
    <SubmittedAgreement
      fileStatus={fileStatus}
      applicationPath={applicationPath}
      detailsRoute={detailsRoute}
      pageTitle={pageTitle}
    />
  );
};

export default UnauthorizedAgreementApplication;
