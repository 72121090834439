export const certificateTitles = (type) => {
  if (type === 1) {
    return {
      a: "সৃজনশীল মেধা কর্মের শিরোনাম / Title of Creative Intellectual Work",
      b: "সৃজনশীল কর্মের প্রণেতা (স্বত্বের অংশ) / Author of the Creative Work (Type of Author & Proportion)",
      c: "সৃজনশীল কর্মের স্বত্বা ধিকারী (স্বত্বের অংশ) / Owner of the Creative Work (Proportion)",
      d: "সৃজনশীল কর্মের ক্ষেত্র / Field of the Creative Work",
    };
  } else if (type === 2) {
    return {
      a: "Title of Work",
      b: "Name of Licensee",
      c: "Creator of the Work",
      d: "Field of Copyright",
    };
  } else if (type === 3) {
    return {
      a: "সম্পাদিত কর্মের শিরোনাম / Title of Performed Work",
      b: "সম্পাদনকারী (সম্পাদিত বিষয় ও অংশ ) / Performer (Performed Work & Proportion)",
      c: "সম্পাদিত কর্মের স্বত্বাধিকারী (স্বত্বের অংশ) / Owner of the Performed Work (Proportion)",
      d: "সম্পাদিত কর্মের ক্ষেত্র / Field of the Performed Work",
    };
  } else {
    // type === 4
    return {
      a: "সৃজনশীল মেধা কর্মের শিরোনাম / Title of Creative Intellectual Work",
      b: "সৃজনশীল কর্মের প্রনেতা / Author of the Creative Work",
      c: "সম্প্রচারকারী (সম্প্রচারের মাধ্যম) / Broadcaster (Broadcasting Medium)",
      d: "সৃজনশীল কর্মের - শ্রেণী (উপশ্রেণী) / Creative Work - Category (Subcategory)",
    };
  }
};
