import React from "react";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import FileSizeWebsiteSettings from "../../components/FileSizeWebsiteSettings";
import WebsiteGeneralSettings from "../../components/WebsiteGeneralSettings";
import CopyrightWebsitesettings from "../../components/CopyrightWebsitesettings";
import EmailConfigurationSettings from "../../components/EmailConfigurationSettings";
import ContactWebsiteSetting from "../../components/ContactWebsiteSetting";
import ShartherDhoron from "../../components/ShartherDhoron";
import { Home, Settings } from "@mui/icons-material";
import IconBreadcrumbs from "../../components/custom/IconBreadcrumbs";

const Setting = () => {
  const breadcrumbsData = [
    { icon: Home, title: "হোম", url: "/" },
    { icon: Settings, title: "সেটিংস", active: true },
  ];
  return (
    <>
      <div className="py-3">
        <IconBreadcrumbs breadcrumbs={breadcrumbsData} />
      </div>
      <div className="slideUp dashboard-layout p-3">
        <Tabs
          defaultActiveKey="general"
          id="uncontrolled-tab-example"
          className="mb-3"
        >
          <Tab eventKey="general" title="সাইট সেটিংস">
            <WebsiteGeneralSettings />
          </Tab>

          <Tab eventKey="home" title="ফাইল সাইজ">
            <FileSizeWebsiteSettings />
          </Tab>

          <Tab eventKey="copyright" title="আবেদন ফি">
            <CopyrightWebsitesettings />
          </Tab>

          <Tab eventKey="email" title="ইমেইল কনফিগারেশন">
            <EmailConfigurationSettings />
          </Tab>

          <Tab eventKey="contact" title="যোগাযোগ">
            <ContactWebsiteSetting />
          </Tab>
          <Tab eventKey="shrtherDhoron" title="স্বার্থের ধরণ (বিন্যাস)">
            <ShartherDhoron />
          </Tab>
        </Tabs>
      </div>
    </>
  );
};

export default Setting;
