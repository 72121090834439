import React, { useEffect, useState } from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Badge, Button } from "react-bootstrap";
import {
  useCategoriesUpdateCatalogMutation,
  useGetCategoryCatalogQuery,
} from "../../../redux/api/categoryCatalogApiSlice";
import Loader from "../../../components/custom/Loader";
import { AddCircle, Edit, Home } from "@mui/icons-material";
import CategoryIcon from "@mui/icons-material/Category";
import IconBreadcrumbs from "../../../components/custom/IconBreadcrumbs";
import toast from "react-hot-toast";
import { BeatLoader } from "react-spinners";
import AddCategory from "./AddData";
import UpdateCategory from "./UpdateData";

export default function Category() {
  const [editUpdateData, setEditUpdateData] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [showModalEdit, setShowModalEdit] = useState(false);
  const { data: categoriesData, isLoading } = useGetCategoryCatalogQuery();
  const initialData = categoriesData?.data;
  const [
    updatedCategories,
    {
      isLoading: isLoadingUpdate,
      isSuccess: isSuccessUpdate,
      isError: isErrorUpdate,
      error: errorUpdate,
      data: dataUpdate,
    },
  ] = useCategoriesUpdateCatalogMutation();

  const [categories, setCategories] = useState([]);
  const [draggedItem, setDraggedItem] = useState(null);
  const [draggedItemIndex, setDraggedItemIndex] = useState(null);
  const [draggedCategoryIndex, setDraggedCategoryIndex] = useState(null);
  const [draggedParentCategory, setDraggedParentCategory] = useState(null);
  const [draggedParentCategoryIndex, setDraggedParentCategoryIndex] =
    useState(null);

  const handleDragStart = (event, categoryIndex, childIndex) => {
    event.stopPropagation();
    if (childIndex !== null) {
      setDraggedItem(categories[categoryIndex].childCategories[childIndex]);
      setDraggedItemIndex(childIndex);
      setDraggedCategoryIndex(categoryIndex);
    } else {
      setDraggedParentCategory(categories[categoryIndex]);
      setDraggedParentCategoryIndex(categoryIndex);
    }
    event.dataTransfer.effectAllowed = "move";
  };

  const handleDragOver = (event, categoryIndex, childIndex) => {
    event.preventDefault();
    event.stopPropagation();

    if (
      draggedItem &&
      draggedCategoryIndex === categoryIndex &&
      draggedItemIndex !== childIndex
    ) {
      const newCategories = structuredClone(categories);
      const childCategories = newCategories[categoryIndex].childCategories;
      childCategories.splice(draggedItemIndex, 1);
      childCategories.splice(childIndex, 0, draggedItem);
      newCategories[categoryIndex].childCategories = childCategories;
      setCategories(newCategories);
      setDraggedItemIndex(childIndex);
    }

    if (draggedParentCategory && draggedParentCategoryIndex !== categoryIndex) {
      const newCategories = structuredClone(categories);
      newCategories.splice(draggedParentCategoryIndex, 1);
      newCategories.splice(categoryIndex, 0, draggedParentCategory);
      setCategories(newCategories);
      setDraggedParentCategoryIndex(categoryIndex);
    }
  };

  const handleDrop = () => {
    setDraggedItem(null);
    setDraggedItemIndex(null);
    setDraggedCategoryIndex(null);
    setDraggedParentCategory(null);
    setDraggedParentCategoryIndex(null);
  };

  const updateSortingIndex = (categories) => {
    return categories.map((category, index) => ({
      ...category,
      sorting_index: index + 1,
      childCategories: category.childCategories.map((child, childIndex) => ({
        ...child,
        sorting_index: childIndex + 1,
      })),
    }));
  };

  const handleSave = () => {
    const updatedCategoriesData = updateSortingIndex(categories);
    updatedCategories(updatedCategoriesData);
  };

  useEffect(() => {
    if (isSuccessUpdate) {
      toast.success(dataUpdate?.message);
    }
    if (isErrorUpdate) {
      toast.error(errorUpdate?.data?.message);
    }
  }, [isSuccessUpdate, isErrorUpdate]);

  useEffect(() => {
    if (initialData) {
      setCategories(structuredClone(initialData));
    }
  }, [initialData]);

  if (isLoading) {
    return <Loader />;
  }

  const breadcrumbsData = [
    { icon: Home, title: "হোম", url: "/" },
    {
      icon: CategoryIcon,
      title: "ক্যাটাগরির তালিকাঃ",
      url: "/admin/users",
      active: true,
    },
  ];

  return (
    <>
      <Box
        display={"flex"}
        justifyContent={"space-between"}
        alignItems={"center"}
        py={1}
        mb={1}
      >
        <IconBreadcrumbs breadcrumbs={breadcrumbsData} />
        <Button
          variant="outline-secondary"
          onClick={() => {
            setShowModal(true);
          }}
        >
          <AddCircle /> ক্যাটাগরি যোগ করুন
        </Button>
      </Box>
      <div className="slideUp dashboard-layout p-3">
        <h4 className="text-secondary">Category List</h4>
        {categories?.map((item, categoryIndex) => (
          <Accordion
            key={item.id}
            className="rounded shadow-none border mb-2"
            defaultExpanded={categoryIndex === 0}
            draggable
            onDragStart={(event) => handleDragStart(event, categoryIndex, null)}
            onDragOver={(event) => handleDragOver(event, categoryIndex, null)}
            onDrop={handleDrop}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls={`panel${item.id}-content`}
              id={`panel${item.id}-header`}
              className="border-bottom"
              style={{ backgroundColor: "#f5f5f5", cursor: "grab" }}
            >
              <div style={{ paddingRight: "20px" }}> {item.name}</div>
              <Edit
                sx={{
                  fontSize: "19px",
                }}
                onClick={() => {
                  setShowModalEdit(true);
                  setEditUpdateData(item);
                }}
              />
            </AccordionSummary>
            <AccordionDetails>
              <div className="d-flex flex-wrap gap-2 pt-3">
                <div className="row w-100">
                  {item?.childCategories?.length > 0 ? (
                    item.childCategories.map((child, childIndex) => (
                      <div className="col-md-3">
                        <div
                          key={child.id}
                          draggable
                          onDragStart={(event) =>
                            handleDragStart(event, categoryIndex, childIndex)
                          }
                          onDragOver={(event) =>
                            handleDragOver(event, categoryIndex, childIndex)
                          }
                          onDrop={handleDrop}
                          className={`m-1 py-1 px-3 w-100  draggable ${
                            draggedItem === child
                              ? "dragging"
                              : "border rounded"
                          }`}
                          onMouseEnter={(e) =>
                            (e.currentTarget.style.backgroundColor = "#f0f0f0")
                          }
                          onMouseLeave={(e) =>
                            (e.currentTarget.style.backgroundColor = "white")
                          }
                          style={{
                            fontSize: "14px",
                            cursor: "grab",
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          {child.name} - {childIndex + 1}{" "}
                          <Edit
                            sx={{ fontSize: "16px" }}
                            onClick={() => {
                              setShowModalEdit(true);
                              setEditUpdateData(child);
                            }}
                          />
                        </div>
                      </div>
                    ))
                  ) : (
                    <div className="col-md-12">No data found</div>
                  )}
                </div>
              </div>
            </AccordionDetails>
          </Accordion>
        ))}
        <div className="text-center">
          <Button
            variant="success"
            disabled={isLoadingUpdate}
            onClick={handleSave}
            className="mt-3 px-5"
          >
            {isLoadingUpdate ? <BeatLoader color="#fff" /> : "Save"}
          </Button>
        </div>
      </div>
      <AddCategory show={showModal} onHide={() => setShowModal(false)} />
      <UpdateCategory
        data={editUpdateData}
        show={showModalEdit}
        onHide={() => {
          setShowModalEdit(false);
          setEditUpdateData({});
        }}
      />
    </>
  );
}
