import React, { memo, useEffect } from "react";
import { Grid, Typography, Card, Avatar, Tooltip } from "@mui/material";
import { theme } from "../../theme";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { toast } from "react-hot-toast";
import { useAuth } from "../../utils/authorization";
import { ChangePassword } from "../../components/ChangePassword";
import AdminProfileData from "../../components/AdminProfileData";
import { assets } from "../../assets";
import { useAuthUser } from "../../hooks/useAuthUser";
import Loader from "../../components/custom/Loader";
import { useUpdateUserImageMutation } from "../../redux/api/userApiSlice";

const AdminProfile = () => {
  const [image, setImage] = React.useState(null);
  const [originalImage, setOriginalImage] = React.useState(null);
  const { user: authUser, isLoading: isLoadingUser } = useAuthUser();
  const [updateImage, { isError, error, isSuccess, data }] =
    useUpdateUserImageMutation();

  const handleChange = (e) => {
    setImage(e.target.files[0]);
    if (e.target.files[0]) {
      const formData = new FormData();
      formData.append("image", e.target.files[0]);
      updateImage(formData);
    }
  };

  useEffect(() => {
    if (isSuccess) {
      toast.success(data?.message);
      setOriginalImage(data?.data?.image);
    }
    if (isError) {
      setImage(null);
      toast.error(error?.data?.message);
    }
  }, [isError, error, isSuccess, data]);

  if (isLoadingUser) {
    return <Loader />;
  }
  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={3}>
        <div className="slideUp">
          <Card
            sx={{
              boxShadow: theme.palette.boxShadow,
              borderRadius: "10px",
              padding: theme.spacing(2),
              bgcolor: "#ffffff",
              py: 3,
              minHeight: "50vh",
            }}
          >
            <div className="d-flex flex-column justify-content-center align-items-center gap-3">
              <Tooltip title="Change image">
                <input
                  type="file"
                  id="image-upload"
                  hidden
                  onChange={handleChange}
                />
                <label htmlFor="image-upload">
                  <Avatar
                    src={
                      image
                        ? URL.createObjectURL(image)
                        : originalImage
                        ? originalImage
                        : authUser?.image
                        ? authUser?.image
                        : assets.avatar
                    }
                    sx={{ width: 150, height: 150, cursor: "pointer" }}
                    alt={authUser?.name || "User Avatar"}
                    accept="jpeg,png,jpg"
                  />
                </label>
              </Tooltip>
              <div className="text-center">
                {error?.data?.data?.image && (
                  <Typography
                    variant="body2"
                    gutterBottom
                    className="text-danger"
                  >
                    {error?.data?.data?.image}
                  </Typography>
                )}
                <Typography variant="body2" gutterBottom>
                  {authUser?.name || "Name not available"}
                </Typography>
                <Typography variant="body2" gutterBottom>
                  {authUser?.role?.bn_name || "Role not available"}
                </Typography>
                <Typography variant="body2" gutterBottom>
                  {authUser?.email || "Email not available"}
                </Typography>
              </div>
            </div>
          </Card>
        </div>
      </Grid>
      <Grid item xs={12} md={9}>
        <div className="slideUp">
          {" "}
          <Card
            sx={{
              boxShadow: theme.palette.boxShadow,
              borderRadius: "10px",
              padding: theme.spacing(2),
              bgcolor: "#ffffff",
              minHeight: "50vh",
            }}
          >
            <Tabs
              defaultActiveKey="home"
              id="justify-tab-example"
              className="mb-3"
              justify
            >
              <Tab eventKey="home" title="প্রোফাইল এডিট">
                <AdminProfileData />
              </Tab>
              <Tab eventKey="password" title="পাসওয়ার্ড পরিবর্তন">
                <ChangePassword />
              </Tab>
            </Tabs>
          </Card>
        </div>
      </Grid>
    </Grid>
  );
};

export default memo(AdminProfile);
