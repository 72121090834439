import React, { useEffect, useState } from "react";
import { useVerifySubmittedAgreementMutation } from "../../redux/api/submittedCopyrightAgreementApiSlice";
import Swal from "sweetalert2";
import { useNavigate, useParams } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import ShowApplication from "../../pages/admin/submitted_application/details/ShowApplication";
import { Button, Form, Table } from "react-bootstrap";
import toast from "react-hot-toast";
import Select from "react-select";
import { useGetRoleNotInQuery } from "../../redux/api/categoryApiSlice";
import { useLazyGetByRoleQuery } from "../../redux/api/applicationCopyrightApiSlice";
import Loader from "../custom/Loader";
import { DriveFileMove } from "@mui/icons-material";
import { Tooltip } from "@mui/material";
import { localDateFormat, localTimeFormat } from "../../utils/dateFormat";
import { useAuth } from "../../utils/authorization";
import { transformErrorsToObjectStructure } from "../../utils/transformErrorsToObjectStructure";

const InspectorCommonContent = ({ comments, info, infoTable, infoModal }) => {
  const { authUser } = useAuth();
  const { id } = useParams();
  const [initCheckBox, setInitCheckBox] = useState({});
  const [modalData, setModalData] = useState("");
  const navigate = useNavigate();
  const [openModal, setOpenModal] = useState(false);
  const [
    inspectorVerify,
    {
      isLoading: isLoadingInspectorVerify,
      isSuccess: isSuccessSubmit,
      error: errorSubmit,
      isError: isErrorSubmit,
      data: dataSubmit,
    },
  ] = useVerifySubmittedAgreementMutation();

  useEffect(() => {
    const initialCheckBoxState = Object.keys(infoTable).reduce((acc, key) => {
      acc[key] = 0;
      return acc;
    }, {});
    if (!comments?.status) {
      setInitCheckBox(initialCheckBoxState);
    }
  }, [infoTable, comments?.status]);

  const { data: roles } = useGetRoleNotInQuery("[1,9,10,12]");
  const rolesOption =
    roles?.data?.map((data) => ({
      value: data.id,
      label: data.bn_name,
    })) || [];

  const [findUser, { data: users, isLoading }] = useLazyGetByRoleQuery();

  const usersOption =
    (users?.data &&
      Object.entries(users.data)
        .filter(([key]) => authUser.id !== Number(key))
        .map(([key, value]) => ({
          value: Number(key),
          label: value,
        }))) ||
    [];

  const handleSwitchChange = (e, key) => {
    setInitCheckBox((prev) => ({
      ...prev,
      [key]: e.target.checked ? 1 : 0,
    }));
  };

  const validationSchema = Yup.object().shape({
    comments: Yup.string().when("submit", {
      is: (val) => val !== 2,
      then: () => Yup.string().required("মন্তব্য প্রযোজ্য।"),
      otherwise: () => Yup.string().nullable(),
    }),
    role_id: Yup.number().when("submit", {
      is: (val) => val !== 2,
      then: () => Yup.number().required("পদবী প্রযোজ্য।"),
      otherwise: () => Yup.number().nullable(),
    }),
    user_id: Yup.number().when("submit", {
      is: (val) => val !== 2,
      then: () => Yup.number().required("প্রাপক প্রযোজ্য।"),
      otherwise: () => Yup.number().nullable(),
    }),
  });

  const formik = useFormik({
    initialValues: {
      submit: 2,
      comments: "",
      role_id: null,
      user_id: null,
    },
    validationSchema,
    onSubmit: (values) => {
      const formattedArray = Object.entries(initCheckBox).map(
        ([key, value]) => `${key}:${value}`
      );
      const formData = {
        ...values,
        attachments: formattedArray,
      };
      if (values.submit === 3) {
        Swal.fire({
          // title: "আপনি কি নিশ্চিত?",
          text: "আপনি কি দাখিল করতে চান?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          cancelButtonText: "না",
          confirmButtonText: "হ্যাঁ",
        }).then((result) => {
          if (result.isConfirmed) {
            inspectorVerify({ id, formData });
          }
        });
      } else {
        inspectorVerify({ id, formData });
      }
    },
  });
  useEffect(() => {
    if (isSuccessSubmit) {
      toast.success(dataSubmit?.message);
      formik.resetForm();
      navigate(-1);
    }
    if (isErrorSubmit) {
      toast.error(errorSubmit?.data?.message);
      formik.setErrors(transformErrorsToObjectStructure(errorSubmit.data?.data || {}));
      if (errorSubmit.data.data) {
        formik.setErrors({
          comments: errorSubmit.data.data.comments?.[0],
          role_id: errorSubmit.data.data.role_id?.[0],
          user_id: errorSubmit.data.data.user_id?.[0],
        });
      }
    }
    // eslint-disable-next-line
  }, [isErrorSubmit, isSuccessSubmit]);

  const handleModalOpen = (key) => {
    const data = infoModal[key] || {};
    setModalData(data);
    setOpenModal(true);
  };

  const arrayToObject = (arr) => {
    return arr.reduce((acc, str) => {
      const [key, value] = str.split(":");
      acc[key] = Number(value); // convert value to number if needed
      return acc;
    }, {});
  };
  useEffect(() => {
    if (comments) {
      findUser(comments?.user?.id);
      setInitCheckBox(arrayToObject(comments?.status));
      formik.setValues((prev) => ({
        ...prev,
        comments: comments?.comments,
      }));
    }
  }, [comments]);

  const defaultValue = {
    value: "",
    label: "নির্বাচন করুন",
  };

  return (
    <>
      {" "}
      <div>
        <Table bordered hover>
          <thead>
            <tr>
              <th className="light-gray">তথ্য ও সংযুক্তি</th>
              <th className="text-end light-gray">তথ্য যাচাই</th>
            </tr>
          </thead>
          <tbody>
            {Object.entries(infoTable).map(([key, value]) => (
              <tr key={key}>
                <td
                  className="cursor-pointer"
                  onClick={() => handleModalOpen(key)}
                >
                  <div className="d-flex align-items-center gap-2 fileCheckModal">
                    <DriveFileMove className="text-primary" />{" "}
                    <span>{value}</span>
                  </div>
                </td>
                <td className="text-end">
                  <Tooltip
                    title={initCheckBox[key] === 1 ? "না" : "হ্যাঁ"}
                    placement="right"
                  >
                    <Form.Check
                      type="switch"
                      id={`custom-switch${value}`}
                      value={value}
                      className="text-success"
                      isValid
                      checked={initCheckBox[key] === 1}
                      onChange={(e) => handleSwitchChange(e, key)}
                    />
                  </Tooltip>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
      <hr />
      <form onSubmit={formik.handleSubmit}>
        <label className="my-2 fw-semibold" htmlFor="comments">
          মন্তব্য
        </label>
        <Form.Control
          name="comments"
          placeholder="এখানে লিখুন"
          aria-label="Username"
          aria-describedby="basic-addon1"
          as={"textarea"}
          rows={6}
          value={formik.values.comments}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          isInvalid={formik.touched.comments && formik.errors.comments}
        />
        {formik.touched.comments && formik.errors.comments ? (
          <div className="text-danger">{formik.errors.comments}</div>
        ) : null}
        <div className="text-end py-3">
          <div>
            <div className="d-flex justify-content-end">
              <div className="text-center">
                <div className="text-primary">{info?.admin?.name}</div>
                <div className="text-primary">{info?.admin?.role?.bnName}</div>
                <div>তারিখ : {localDateFormat(new Date())}</div>
                <div>সময় : {localTimeFormat(new Date())}</div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <label className="my-2 fw-semibold" htmlFor="role_id">
              পদবী
            </label>
            <Select
              name="role_id"
              options={rolesOption}
              value={
                formik.values.role_id
                  ? rolesOption.find(
                      (option) => option.value === formik.values.role_id
                    )
                  : defaultValue
              }
              onChange={(selectedOption) => {
                findUser(selectedOption.value);
                formik.setValues((prev) => {
                  return {
                    ...prev,
                    user_id: "",
                  };
                });
                formik.setFieldValue("role_id", selectedOption.value);
              }}
              onBlur={formik.handleBlur}
              classNamePrefix="react-select"
            />
            {formik.touched.role_id && formik.errors.role_id ? (
              <div className="text-danger">{formik.errors.role_id}</div>
            ) : null}
          </div>
          <div className="col-md-6">
            <label className="my-2 fw-semibold" htmlFor="user_id">
              প্রাপক
            </label>
            <Select
              name="user_id"
              options={usersOption}
              value={
                formik.values.user_id
                  ? usersOption.find(
                      (option) => option.value === formik.values.user_id
                    )
                  : defaultValue
              }
              onChange={(selectedOption) =>
                formik.setFieldValue("user_id", selectedOption.value)
              }
              onBlur={formik.handleBlur}
              classNamePrefix="react-select"
            />
            {formik.touched.user_id && formik.errors.user_id ? (
              <div className="text-danger">{formik.errors.user_id}</div>
            ) : null}
          </div>
        </div>
        <div className="my-4 text-center d-flex justify-content-center gap-3">
          <div>
            <Button
              type="submit"
              onClick={() => formik.setFieldValue("submit", 2)}
              disabled={isLoadingInspectorVerify}
            >
              সংরক্ষণ করুন
            </Button>
          </div>
          <div className="mr-3">
            <Button
              variant="success"
              onClick={() => formik.setFieldValue("submit", 3)}
              type="submit"
              disabled={isLoadingInspectorVerify}
            >
              দাখিল করুন
            </Button>
          </div>
        </div>
      </form>
      <ShowApplication
        infoModal={modalData}
        onHide={() => setOpenModal(false)}
        show={openModal}
      />
    </>
  );
};

export default InspectorCommonContent;
