import React from "react";
import SubmittedCopyright from "../../sumitted_copyright/SubmittedCopyright";

const UnauthorizedApprovedCopyright = () => {
  const fileStatus = 30;
  const applicationPath = {
    copyright: "/admin/unauthorized-approved-copyright",
    agreement: "/admin/unauthorized-approved-agreement",
  };
  const detailsRoute = "/admin/unauthorized-copyright-details";
  const pageTitle = "অননুমোদন অননুমোদিত";
  return (
    <SubmittedCopyright
      fileStatus={fileStatus}
      applicationPath={applicationPath}
      detailsRoute={detailsRoute}
      pageTitle={pageTitle}
    />
  );
};

export default UnauthorizedApprovedCopyright;
