import React, { useRef } from "react";
import { Button, Modal } from "react-bootstrap";
import { useShowHearingLetterQuery } from "../redux/api/submittedCopyrightAgreementApiSlice";
import Loader from "./custom/Loader";
import { assets } from "../assets";
import {
  localDateFormatName,
  localDateFormatNameBN,
} from "../utils/dateFormat";
import { bnTimeFormat } from "../utils/englishToBengaliNumber";
import { Link } from "react-router-dom";
import ReactToPrint from "react-to-print";
import { Print } from "@mui/icons-material";

const ShowLetter = ({ id, showModal, onHide }) => {
  const printLetter = useRef();
  const { data, isLoading } = useShowHearingLetterQuery(id);

  if (isLoading) {
    return <Loader />;
  }

  const hearing = data?.data?.hearing;
  const user = data?.data?.hearing?.user;

  const info = {
    name:
      data?.data?.puname ??
      data?.data?.profileDetail?.profileDetailType?.[0].name,
    email:
      data?.data?.user?.email ??
      data?.data?.profileDetail?.profileDetailType?.[0].mobile,
    address:
      data?.data?.communicationAddress ??
      data?.data?.profileDetail?.profileDetailType?.[0].address,
  };
  return (
    <Modal
      show={showModal}
      onHide={onHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body>
        <div className="py-2" ref={printLetter}>
          <div className="card p-3">
            <div ref={printLetter}>
              <div className="text-center">
                <img
                  className="my-3"
                  src={assets.logoBlack}
                  style={{ width: "350px" }}
                  alt=""
                />
                <p className="m-0">কপিরাইট অফিস, সংস্কৃতি বিষয়ক মন্ত্রণালয়</p>

                <p className="m-0">
                  জাতীয় গ্রন্থাগার ভবন, আগারগাঁও, শেরেবাংলা নগর, ঢাকা।
                </p>

                <p className="m-0">
                  ওয়েবসাইট :{" "}
                  <Link target="_blank" to={"//www.copyrightoffice.gov.bd"}>
                    www.copyrightoffice.gov.bd
                  </Link>
                </p>
              </div>
              <div
                className={`d-flex justify-content-between py-3 mt-2 align-items-center`}
              >
                <div className={`d-flex gap-2 align-items-center`}>
                  <> {hearing?.record_no}</>
                </div>
                <div className={`d-flex gap-3 align-items-center`}>
                  <div className={`d-flex gap-3 mt-2 fw-semibold`}>তারিখঃ</div>
                  <div>
                    <>
                      {localDateFormatNameBN(hearing?.record_date)}
                      <hr className="m-0" />
                      {localDateFormatName(hearing?.record_date)}
                    </>
                  </div>
                </div>
              </div>
              <div>
                <p
                  className={`fw-semibold mt-4 w-100 d-flex gap-2`}
                >
                  <span>বিষয়ঃ </span>
                  <> {hearing?.subject}</>
                </p>
              </div>
              <p className="pb-4" style={{ textAlign: "justify" }}>
                <p
                  className="m-0"
                  dangerouslySetInnerHTML={{ __html: hearing?.detail }}
                ></p>
              </p>
              <div className="d-flex justify-content-between my-2">
                <div>
                  <div className={`d-flex gap-3 align-items-center`}>
                    <div className={`mt-2 fw-semibold`}>
                      শুনানির তারিখ ও সময়ঃ
                    </div>
                  </div>
                  <div className={`d-flex gap-3 align-items-center`}>
                    <div>
                      <>
                        {localDateFormatName(hearing?.date) +
                          " ও " +
                          bnTimeFormat(hearing?.time)}
                      </>
                    </div>
                  </div>
                </div>
                <div className="mt-3 text-center">
                  {info.name && (
                    <>
                      <p className="m-0">{info?.name}</p>
                      <p className="m-0">{info?.email}</p>
                      <p className="m-0">{info?.address}</p>
                    </>
                  )}
                </div>
              </div>
              <div>
                <div className="mt-3">
                  <>
                    {user?.sign && (
                      <>
                        <img
                          style={{ height: "40px" }}
                          src={user?.sign}
                          alt=""
                        />
                      </>
                    )}
                    <p className="m-0">{user?.name}</p>
                    <p className="m-0">{user?.role?.bnName}</p>
                    <p className="m-0">{user?.email}</p>
                  </>
                </div>
              </div>
            </div>
            <div>
              <div className="d-flex gap-3 mt-3">
                {hearing?.isComplainant === 1 && (
                  <span className="fw-semibold">বাদীঃ</span>
                )}
              </div>
              <div>
                {hearing?.isComplainant === 1 && (
                  <div className="mt-3 row">
                    {hearing?.complainants.map((complainant, i) => (
                      <div key={i} className="col-md-12 mt-2">
                        <div className="mb-2">
                          <span>{complainant?.name}</span>
                        </div>
                        <div className="mb-2">
                          <span>{complainant?.mobile}</span>
                        </div>
                        <div className="mb-2">
                          <span>{complainant?.email}</span>
                        </div>
                        <div className="mb-2">
                          <span>{complainant?.address}</span>
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Header>
        <ReactToPrint
          trigger={() => (
            <Button>
              <Print className="fs-4 cursor-pointer" />
            </Button>
          )}
          content={() => printLetter.current}
        />
        <Button variant="secondary" onClick={onHide}>
          বন্ধ করুন
        </Button>
      </Modal.Header>
    </Modal>
  );
};

export default ShowLetter;
