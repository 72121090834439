import React, { useState } from "react";
import { localDateFormat } from "../../../utils/dateFormat";
import Table from "react-bootstrap/Table";
import { useAuth } from "../../../utils/authorization";

const CopyrightPreviewFormOne = ({ data }) => {
  const applicationFormOne = data;
  const publicUrl = process.env.PUBLIC_URL;

  return (
    <div>
      <div className="tab-content p-4" id="nav-tabContent">
        <div
          className="tab-pane fade active show"
          id="nav-application"
          role="tabpanel"
          aria-labelledby="nav-application-tab"
        >
          <div className="applicationForm">
            <div
              className="print-header printheaderrr"
              style={{ display: "none" }}
            >
              <img src={`${publicUrl}/assets/images/logoo.png`} alt="Logo" />
            </div>

            <div className="applicationForm-header text-center">
              <h2>ফরম - ১.</h2>
              <b>কপিরাইট রেজিস্ট্রেশনের আবেদন পত্র</b>
              <h5>[ বিধি ৪ (১) দ্রষ্টব্য ]</h5>
            </div>
            <div className="applicationForm-body mt-4">
              <div className="applicationForm-body-top">
                <div className="d-flex justify-content-between">
                  <div className="div">
                    <p className="mb-0 mt-2">বরাবর</p>
                    <p className="unbold">
                      রেজিস্ট্রার অব কপিরাইটস <br />
                      বাংলাদেশ কপিরাইট অফিস।
                    </p>
                  </div>
                  <div className="div">
                    <p className="mb-0 mt-2">
                      {" "}
                      ট্র্যাকিং নম্বর :{" "}
                      <span> {applicationFormOne?.trackReg} </span>{" "}
                    </p>
                  </div>
                </div>

                <p className="mb-0">জনাব,</p>
                <p className="unbold text-dark">
                  কপিরাইট আইন (২০২৩ সালের ৩৪ নং আইন এবং সংশোধিত- ২০০৫) এর ৫৪
                  ধারা অনুযায়ী আমি মেধাস্বত্ব হস্তান্তর / লাইসেন্স প্রদান
                  সংক্রান্ত দলিল নিবন্ধনের জন্য আবেদন করছি এবং দলিল নিবন্ধন
                  বহিতে হস্তান্তরিত কর্ম সংক্রান্ত তথ্যসমূহ লিপিবদ্ধ করার জন্য
                  দুইকপি সংশ্লিষ্ট কর্ম ও বিবরণী সংযুক্ত করছি।
                </p>

                <p className="mb-0">
                  {" "}
                  ১. এই কর্মের সঙ্গে সম্পর্কযুক্ত অন্যান্য বিষয়াবলীর বিবরণী ও
                  যথাযথভাবে পূরণ করিয়া পাঠাইলাম ।{" "}
                </p>

                <div className="my-3">
                  <div className="row">
                    <div class="col-sm-3">
                      <p className="mb-0">২. কোন পক্ষ আছে কি ?</p>
                    </div>

                    <div className="col-sm-9">
                      <label className="radio-inline">
                        <input
                          name="sourcrrr"
                          type="radio"
                          value="1"
                          checked={Number(applicationFormOne?.teamType) === 1}
                          readOnly
                        />{" "}
                        হ্যাঁ
                      </label>
                      <label className="radio-inline ms-3">
                        <input
                          name="sourcrrr"
                          type="radio"
                          value="2"
                          checked={Number(applicationFormOne?.teamType) === 2}
                          readOnly
                        />{" "}
                        না
                      </label>
                    </div>
                  </div>
                </div>

                {Number(applicationFormOne?.teamType) === 1 &&
                  applicationFormOne?.profileTeamTypes.length > 0 && (
                    <div className="table-responsive text-center">
                      <Table striped bordered hover>
                        <thead>
                          <tr>
                            <th>পক্ষের নাম</th>
                            <th>পক্ষের ঠিকানা</th>
                            <th>প্রেরণের তারিখ / ডেসপাচের তারিখ</th>
                            <th>ই-মেইল</th>
                            <th>মোবাইল নম্বর</th>
                          </tr>
                        </thead>
                        <tbody>
                          {applicationFormOne?.profileTeamTypes.map(
                            (item, i) => (
                              <tr key={i}>
                                <td>{item.teamName}</td>
                                <td>{item.teamAddress}</td>
                                <td>{localDateFormat(item.teamSenddate)}</td>
                                <td>{item.teamEmail}</td>
                                <td>{item.teamMobile}</td>
                              </tr>
                            )
                          )}
                        </tbody>
                      </Table>
                    </div>
                  )}

                <p className="mt-2 unbold">
                  কপিরাইট বিধিমালা, ২০০৬ এর বিধি-৪ এর উপ-বিধি (৩) অনুযায়ী আমি এই
                  পত্র এবং তদসংযুক্ত বিবরণীসমূহের কপি নিম্নোক্ত পদ্ধতিতে
                  অন্যান্য পক্ষের নিকট হাতে হাতে/অগ্রিম রেজিস্টার্ড ডাকযোগে
                  প্রেরণ করিয়াছি" শব্দগুলির স্থলে "কপিরাইট বিধিমালা, ২০০৬ এর
                  ৪(৩) উপ- বিধি অনুযায়ী, অনলাইনে দাখিলকৃত আবেদনপত্রের হার্ডকপি
                  বাংলাদেশ কপিরাইট অফিসে দাখিলের পূর্বে আমি সংশ্লিষ্ট কর্মের
                  কপিরাইট স্বত্বের সহিত স্বার্থসংশ্লিষ্ট সকল ব্যক্তির নিকট
                  আবেদনপত্রের কপি এবং তদসংযুক্ত বিবরণীসমূহের কপি হাতে হাতে।
                  রেজিস্টার্ড ডাকযোগে প্রেরণ করিব এবং উক্ত কার্যের প্রমাণক
                  আবেদনপত্রের হার্ডকপির সহিত বাংলাদেশ কপিরাইট অফিসে দাখিল করিব
                </p>

                <div className="m4-5">
                  <div className="row">
                    <div class="col-sm-3 col-md-3 col-lg-3">
                      <p className="mb-0">৩. যোগাযোগের ঠিকানা :</p>
                    </div>
                    <div className="col-md-9 col-md-9 col-sm-9 ">
                      <p className="mb-0">
                        {applicationFormOne?.communicationAddress}
                      </p>
                    </div>
                  </div>
                </div>

                <div className="applicationForm-body-bottom mt-5">
                  <div className="row">
                    <div className="col-lg-4 col-md-4 col-sm-4">
                      <div className="form-group">
                        <div className="div">
                          <p className="border-bottom-dotted">
                            {applicationFormOne?.user?.email}
                          </p>
                        </div>
                        <div className="div">
                          <p className="border-bottom-dotted">
                            {applicationFormOne?.place}
                          </p>
                        </div>
                        <div className="div">
                          <p className="">
                            {applicationFormOne?.sendDate &&
                              localDateFormat(applicationFormOne?.sendDate)}
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-4 col-md-4 col-sm-4"></div>

                    <div className="col-lg-4 col-md-4 col-sm-4">
                      <div className="form-group text-center">
                        <div className="div">
                          <p className="border-bottom-dotted mb-2">
                            আপনার বিশ্বস্ত
                          </p>
                        </div>
                        <div className="mb-1 attachment-container">
                          {applicationFormOne?.sign && (
                            <img
                              className="img-fluid"
                              src={applicationFormOne?.sign}
                              alt="Signature Preview"
                            />
                          )}
                        </div>
                        <div>
                          <p>{applicationFormOne?.puname}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CopyrightPreviewFormOne;
