import { useFormik } from "formik";
import React, { useEffect } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import toast from "react-hot-toast";
import { BeatLoader } from "react-spinners";
import * as Yup from "yup";

import Swal from "sweetalert2";
import { usePostCommentAndOrderedMutation } from "../../../../redux/api/commenAndOrderedApiSlice";

const ReplyOrdered = ({ onHide, data, ...props }) => {
  const [
    submitComment,
    { isError, error, isSuccess, data: dataSuccess, isLoading },
  ] = usePostCommentAndOrderedMutation();

  const validationSchema = Yup.object({
    reply: Yup.string()
      .required("মন্তব্য আবশ্যক")
      .min(10, "মন্তব্য কমপক্ষে ১০ অক্ষর হতে হবে"),
  });

  const formik = useFormik({
    initialValues: {
      trackReg: data?.trackReg || "",
      reply: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values, { setSubmitting }) => {
      const result = await Swal.fire({
        text: "আপনি কি নিশ্চিত? আপনি কি দাখিল করতে চান?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "না",
        confirmButtonText: "হ্যাঁ",
      });

      if (result.isConfirmed) {
        try {
          submitComment({
            id: data?.id,
            formData: values,
          });
        } catch (error) {
          setSubmitting(false);
        }
      }
    },
    enableReinitialize: true,
  });

  const {
    values,
    touched,
    errors,
    handleBlur,
    handleChange,
    handleSubmit,
    setErrors,
    resetForm,
  } = formik;

  useEffect(() => {
    if (isError) {
      toast.error(error?.data?.message);
      setErrors(handleServerErrors(error?.data?.data || {}));
    }
    if (isSuccess) {
      onHide();
      resetForm();
      toast.success(dataSuccess?.message);
    }
  }, [isError, isSuccess]);

  const handleServerErrors = (serverErrors) => {
    const erroras = {};
    Object.keys(serverErrors).forEach((field) => {
      errors[field] = serverErrors[field].join(", ");
    });
    return errors;
  };

  const type = Number(data?.apptype) === 1 ? "কপিরাইট" : "চুক্তিপত্র";
  const name = Number(data?.apptype) === 1 ? "সনদ" : "এন্ডোর্স";
  const fileStatus = Number(data?.fileStatus);

  return (
    <Modal
      {...props}
      onHide={() => {
        onHide();
        resetForm();
      }}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
    >
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">
          <span
            className="fw-bold"
            style={{
              fontSize: "18px",
            }}
          >
            {type}ঃ-{" "}
            {fileStatus === 6
              ? `অ-অনুমোদিত নথি অনুসন্ধানের জবাব`
              : fileStatus === 8
              ? `${name} বাতিলের কারণ`
              : fileStatus === 19 && "নথিজাত নথি অনুসন্ধানের জবাব"}
          </span>
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <form onSubmit={handleSubmit}>
          <div className="row pb-2">
            <div className="col-md-12">
              <div>
                <label className="my-2 fw-semibold" htmlFor="reply">
                  মন্তব্য
                </label>
                <Form.Control
                  name="reply"
                  placeholder="এখানে লিখুন..."
                  as="textarea"
                  rows={6}
                  value={values.reply}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isInvalid={touched.reply && !!errors.reply}
                />
                {touched.reply && errors.reply && (
                  <div className="text-danger">{errors.reply}</div>
                )}
              </div>
            </div>

            <div className="col-md-12">
              <hr />
              <div className="d-flex justify-content-between align-items-center pt-2">
                <Button
                  variant="secondary"
                  onClick={() => {
                    onHide();
                    resetForm();
                  }}
                  style={{ width: "100px" }}
                >
                  বন্ধ
                </Button>
                <Button
                  variant="primary"
                  type="submit"
                  style={{ width: "150px" }}
                  disabled={isLoading}
                >
                  {isLoading ? (
                    <BeatLoader className="text-white" />
                  ) : (
                    "দাখিল করুন"
                  )}
                </Button>
              </div>
            </div>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
};

export default ReplyOrdered;
