export const applicationFormTitle = (dropdownSectionCopyright3) => {
  switch (dropdownSectionCopyright3) {
    case 13:
      return `অনাপত্তি পত্রের/হস্তান্তর দলিলের
          স্ক্যান কপি ( প্রযোজ্য ক্ষেত্রে )
          ( একাধিক ব্যক্তি কর্মের প্রণেতা
          হলে যদি স্বত্বের অংশবিশেষ
          হস্তান্তর করা হয় কিংবা অনাপত্তি
          পত্র প্রদান করা হয় সেক্ষেত্রে
          প্রযোজ্য )`;
    case 19:
      return `প্রাসঙ্গিক দলিলের / প্রমাণাদির স্ক্যান কপি (প্রযোজ্য ক্ষেত্রে)`;
    case 14:
    case 8:
      return `হস্তান্তর দলিলের স্ক্যান কপি ( আবশ্যিক )`;
    case 15:
    case 9:
      return `নিয়োগ পত্রের স্ক্যান কপি ( প্রযোজ্য ক্ষেত্রে )
( প্রতিষ্ঠানের প্রধান প্রণেতা হলে নিয়োগপত্রের প্রয়োজন নেই তবে প্রতিষ্ঠানে চাকরিরত এক বা একাধিক ব্যক্তি কর্মটির প্রণেতা হলে সেক্ষেত্রে নিয়োগপত্রের স্ক্যান কপি জমা দিতে হবে । )`;
    case 16:
    case 10:
      return `অনাপত্তি পত্রের / সম্মতি পত্রের / হস্তান্তর দলিলের স্ক্যান কপি ( প্রযোজ্য ক্ষেত্রে )
( আবেদনকারী ব্যতীত অন্য কোন উত্তরাধিকারী থাকলে সেক্ষেত্রে প্রযোজ্য )`;
    case 17:
      return `মূল লেখকের / প্রকাশকের/বর্তমান কপিরাইট স্বত্বাধিকারীর সন্মতিপত্রের/অনুমতিপত্রের স্ক্যান কপি ( প্রযোজ্য ক্ষেত্রে )`;
    case 18:
      return `মূল প্রণেতার অনাপত্তিপত্রের / সন্মতিপত্রের স্ক্যান কপি ( প্রযোজ্য ক্ষেত্রে )`;
    // case 14:
    //   return ``;
    default:
      return `অনাপত্তি পত্রের / সম্মতি পত্রের স্ক্যান কপি ( প্রযোজ্য ক্ষেত্রে )
অথবা হস্তান্তর দলিল ( হস্তান্তর সূত্রে )`;
  }
};
