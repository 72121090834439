import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { useCrateContactsMutation } from "../../redux/api/contactsApiSlice";
import { useFormik } from "formik";
import * as Yup from "yup";
import { transformErrorsToObjectStructure } from "../../utils/transformErrorsToObjectStructure";
import toast from "react-hot-toast";
import { BeatLoader } from "react-spinners";
import {
  loadCaptchaEnginge,
  LoadCanvasTemplate,
  validateCaptcha,
} from "react-simple-captcha";

export default function Contact() {
  const [isCaptchaValid, setIsCaptchaValid] = useState(false);
  const [captchaVal, setCaptchaVal] = useState("");
  const captchaInputRef = useRef(null);
  const [sendMessage, { isLoading }] = useCrateContactsMutation();

  const formik = useFormik({
    initialValues: {
      name: "",
      number: "",
      email: "",
      subject: "",
      message: "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required("আপনার নাম প্রয়োজন"),
      number: Yup.string().required("মোবাইল নম্বর প্রয়োজন"),
      email: Yup.string().email("ভুল ইমেইল ঠিকানা").required("ইমেইল প্রয়োজন"),
      subject: Yup.string().required("বিষয় প্রয়োজন"),
      message: Yup.string().required("বার্তা প্রয়োজন"),
    }),
    onSubmit: async (values, { setErrors, resetForm }) => {
      if (!isCaptchaValid) {
        toast.error(
          "দয়া করে CAPTCHA সম্পূর্ণ করুন"
        );
        return;
      }

      try {
        const response = await sendMessage(values).unwrap();
        resetForm();
        toast.success(response?.message);
      } catch (err) {
        console.error("Error sending message: ", err);
        toast.error(err?.data?.message);
        setErrors(transformErrorsToObjectStructure(err?.data?.data));
      } finally {
        setCaptchaVal("");
      }
    },
  });

  const handleCaptchaChange = () => {
    const userCaptchaValue = captchaInputRef.current.value;
    setCaptchaVal(userCaptchaValue);
    if (validateCaptcha(userCaptchaValue)) {
      setIsCaptchaValid(true);
    } else {
      setIsCaptchaValid(false);
      toast.error("CAPTCHA ভুল। দয়া করে আবার চেষ্টা করুন।");
    }
  };

  useEffect(() => {
    loadCaptchaEnginge(6);
  }, []);

  return (
    <div>
      {/* Start Breadcrumbs */}
      <div className="breadcrumbs" data-stellar-background-ratio="0.5">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-6 col-12">
              <div className="breadcrumbs-content left">
                <h1 className="page-title">আমাদের সাথে যোগাযোগ করুন</h1>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-12">
              <div className="breadcrumbs-content right">
                <ul className="breadcrumb-nav">
                  <li>
                    <Link to="/" style={{ fontSize: 20 }}>
                      হোম পেইজ
                    </Link>
                  </li>
                  <li style={{ fontSize: 20 }}>যোগাযোগ</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* End Breadcrumbs */}

      {/* Start Contact Area */}
      <div className="contact-area contact-page pt-5 my-3 pb-5 section">
        <div className="container">
          <div className="contact-inner">
            <div className="row">
              <div
                className="col-lg-12 col-md-10 m-auto card"
                style={{ border: "none" }}
              >
                <div className="row">
                  <div className="col-lg-5 col-md-12">
                    <div className="contact-info">
                      {/* Contact information goes here */}
                      <div className="contact-item mb-4">
                        <h5>রবিবার - বৃহস্পতিবার - </h5>
                        <p>
                          সকালঃ ৯.৩০ → বিকালঃ ৫.০০ পর্যন্ত (সরকারী ছুটি ব্যতীত)
                        </p>
                      </div>
                      <div className="contact-item mb-4">
                        <h5>অতিরিক্ত তথ্যের জন্য যোগাযোগ -</h5>
                        <p> +8801511-440044 </p>
                      </div>
                      <div className="contact-item mb-4">
                        <h5>ঠিকানা -</h5>
                        <p>
                          কপিরাইট অফিস, সংস্কৃতি বিষয়ক মন্ত্রণালয়, জাতীয়
                          গ্রন্থাগার ভবন (৩য় তলা), ৩২, বিচারপতি এসএম মোর্শেদ
                          সরণী, আগারগাঁও, শেরেবাংলা নগর, ঢাকা-1207
                        </p>
                      </div>
                      <div className="contact-item mb-4">
                        <h5>ওয়েবসাইট - </h5>
                        <p>
                          <a
                            href="http://www.copyrightoffice.gov.bd"
                            className="acolor"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            www.copyrightoffice.gov.bd
                          </a>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-7 col-lg-7 col-md-8 col-12">
                    <div className="contact-wrapper" data-wow-delay="0.6s">
                      <form
                        className="contacts-form"
                        onSubmit={formik.handleSubmit}
                      >
                        <div className="row">
                          <div className="col-lg-6 col-12">
                            <div className="contacts-icon contactss-name">
                              <input
                                type="text"
                                name="name"
                                placeholder="আপনার নাম"
                                value={formik.values.name}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                              />
                              {formik.touched.name && formik.errors.name ? (
                                <div className="error py-2">
                                  {formik.errors.name}
                                </div>
                              ) : (
                                <div className="py-3"></div>
                              )}
                            </div>
                          </div>
                          <div className="col-lg-6 col-12">
                            <div className="contacts-icon contactss-name">
                              <input
                                type="text"
                                name="number"
                                placeholder="মোবাইল নম্বর"
                                value={formik.values.number}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                              />
                              {formik.touched.number && formik.errors.number ? (
                                <div className="error py-2">
                                  {formik.errors.number}
                                </div>
                              ) : (
                                <div className="py-3"></div>
                              )}
                            </div>
                          </div>
                          <div className="col-lg-6 col-12">
                            <div className="contacts-icon contactss-email">
                              <input
                                type="email"
                                name="email"
                                placeholder="আপনার ইমেইল"
                                value={formik.values.email}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                              />
                              {formik.touched.email && formik.errors.email ? (
                                <div className="error py-2">
                                  {formik.errors.email}
                                </div>
                              ) : (
                                <div className="py-3"></div>
                              )}
                            </div>
                          </div>
                          <div className="col-lg-6 col-12">
                            <div className="contacts-icon contactss-name">
                              <input
                                type="text"
                                name="subject"
                                placeholder="আপনার বিষয়"
                                value={formik.values.subject}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                              />
                              {formik.touched.subject &&
                              formik.errors.subject ? (
                                <div className="error py-2">
                                  {formik.errors.subject}
                                </div>
                              ) : (
                                <div className="py-3"></div>
                              )}
                            </div>
                          </div>
                          <div className="col-12">
                            <div className="contacts-icon contactss-message">
                              <textarea
                                name="message"
                                rows={7}
                                placeholder="আপনার বার্তা"
                                value={formik.values.message}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                              />
                              {formik.touched.message &&
                              formik.errors.message ? (
                                <div className="error py-2">
                                  {formik.errors.message}
                                </div>
                              ) : (
                                <div className="py-3"></div>
                              )}
                            </div>
                          </div>
                          <div className="col-12">
                            <div className="text-center mb-3">
                              <LoadCanvasTemplate />
                            </div>
                            <input
                              ref={captchaInputRef}
                              type="text"
                              placeholder="CAPTCHA লিখুন"
                              onBlur={handleCaptchaChange}
                              className={`form-control ${
                                isCaptchaValid ? "is-valid" : ""
                              }`}
                              value={captchaVal}
                              onChange={(e) => {
                                setCaptchaVal(e.target.value);
                              }}
                            />
                          </div>
                          <div className="col-12 mt-4">
                            <div className="contacts-button button2">
                              <button
                                disabled={isLoading}
                                type="submit"
                                className="btn button2 mouse-dir white-bg"
                              >
                                {isLoading ? (
                                  <BeatLoader size={10} />
                                ) : (
                                  "বার্তা পাঠান"
                                )}
                                <span className="dir-part" />
                              </button>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* End Contact Area */}
    </div>
  );
}
