import React from "react";
import SubmittedCopyright from "../sumitted_copyright/SubmittedCopyright";
import { useAuth } from "../../../../utils/authorization";

const RecentCopyrightApplication = () => {
  const { authUser } = useAuth();
  const fileStatus = authUser.role_id === 8 ? 1 : 3;
  const applicationPath = {
    copyright: "/admin/submitted-copyright",
    agreement: "/admin/submitted-agreement",
  };
  return <SubmittedCopyright fileStatus={fileStatus} applicationPath={applicationPath} />;
};

export default RecentCopyrightApplication;
