import React from "react";
import CommonDetailsIndexer from "./CommonDetailsIndexer";
import AgreementPreviewFormOne from "../../../../components/preview_applications/agreement/AgreementPreviewFormOne";
import AgreementPreviewFormTwo from "../../../../components/preview_applications/agreement/AgreementPreviewFormTwo";
import { useParams } from "react-router-dom";
import Loader from "../../../../components/custom/Loader";
import {
  useAgreementTreasuryVerifyMutation,
  useGetSubmittedAgreementDetailsQuery,
} from "../../../../redux/api/submittedCopyrightAgreementApiSlice";
import PaymentPreview from "../../../../components/preview_applications/payment/PaymentPreview";

const IndexerAgreementDetails = () => {
  const { id } = useParams();
  const { data, isLoading } = useGetSubmittedAgreementDetailsQuery(id, {
    refetchOnMountOrArgChange: true,
  });
  const treasuryChalanVerify = useAgreementTreasuryVerifyMutation();
  const documents = data?.data;

  if (isLoading) {
    return <Loader />;
  }

  const info = {
    noteNumber: data?.data?.trackReg,
    st: Number(data?.data?.st),
    id: Number(data?.data?.id),
    apptype: Number(data?.data?.apptype) === 1 ? "কপিরাইট" : "চুক্তিপত্র",
    treasuryDate: data?.data?.treasuryDate || new Date(),
    challanid: data?.data?.challanid,
    workTitle: data?.data?.documents?.worktitle,
    category: data?.data?.documents?.category,
    subCategory: data?.data?.documents?.subCategory,
    admin: data?.data?.admin,

    treasuryNo: data?.data?.treasuryNo,
    bankbranch: data?.data?.bankbranch,
    appType: Number(data?.data?.apptype),

    basicStatus: Number(data?.data?.basicStatus),
    treasuryStatus: Number(data?.data?.treasuryStatus),
  };

  const formOne = (
    <div className="p-4 mt-5">
      <AgreementPreviewFormOne data={data?.data} />
    </div>
  );
  const formTwo = (
    <div className="pt-5">
      <AgreementPreviewFormTwo data={data?.data} />
    </div>
  );

  const url = `/admin/submitted-agreement-details-indexer-second-step/${info.id}`;

  return (
    <div>
      <CommonDetailsIndexer
        url={url}
        formOne={formOne}
        formTwo={formTwo}
        payment={<div  className="p-4 mt-5"><PaymentPreview data={data?.data} /></div>}
        info={info}
        treasuryChalanVerify={treasuryChalanVerify}
      />
    </div>
  );
};

export default IndexerAgreementDetails;
