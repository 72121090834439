import React, { useEffect, useState } from "react";
import { useCertificateArtistWorkUpdateMutation } from "../../redux/api/submittedCopyrightAgreementApiSlice";
import * as Yup from "yup";
import Swal from "sweetalert2";
import { useFormik } from "formik";
import { Button, Form } from "react-bootstrap";
import { BeatLoader } from "react-spinners";
import toast from "react-hot-toast";
import { apiSlice } from "../../redux/api/apiSlice";
import { useDispatch } from "react-redux";

const ArtisticWork = ({ data, refetch, onHide }) => {
  const dispatch = useDispatch();
  const [preview, setPreview] = useState(null); // State for image preview
  const [
    submitData,
    {
      isLoading: isLoadingSubmit,
      isSuccess: isSuccessSubmit,
      error: errorSubmit,
      isError: isErrorSubmit,
      data: dataSubmit,
    },
  ] = useCertificateArtistWorkUpdateMutation();

  const id = data?.id;
  const ac = data?.archive;
  const pd = data?.profileDetail;

  const initialValues = {
    archive_id: ac?.id,
    workSoftCopy: null, // File input starts as null
    soft_copy_name: pd?.workSoftCopies[0]?.name, // File input starts as null
  };

  const validationSchema = Yup.object().shape({
    workSoftCopy: Yup.mixed()
      .required("পরিচয়পত্রের স্ক্যান কপি প্রযোজ্য")
      .test(
        "fileType",
        "শুধুমাত্র ইমেজ ফাইলগুলি অনুমোদিত",
        (value) =>
          value && ["image/jpeg", "image/png", "image/jpg"].includes(value.type)
      ),
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      const formData = new FormData();
      formData.append("archive_id", values.archive_id);
      formData.append("soft_copy_name", values.soft_copy_name);
      if (values.workSoftCopy) {
        formData.append("workSoftCopy", values.workSoftCopy);
      }

      Swal.fire({
        text: "আপনি কি নিশ্চিত?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "না",
        confirmButtonText: "হ্যাঁ",
      }).then((result) => {
        if (result.isConfirmed) {
          submitData({ id, formData });
        }
      });
    },
    enableReinitialize: true,
  });

  useEffect(() => {
    if (isSuccessSubmit) {
      toast.success(dataSubmit?.message);
      formik.resetForm();
      setPreview(null); // Reset preview after submission
      dispatch(apiSlice.util.resetApiState());
      refetch();
      onHide();
    }
    if (isErrorSubmit) {
      toast.error(errorSubmit?.data?.message);
    }
  }, [isErrorSubmit, isSuccessSubmit]);

  const handleFileChange = (event) => {
    const file = event.currentTarget.files[0];
    formik.setFieldValue("workSoftCopy", file);

    if (file && file.type.startsWith("image")) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreview(reader.result); // Set the image preview URL
      };
      reader.readAsDataURL(file);
    } else {
      setPreview(null); // If it's not an image, remove the preview
    }
  };

  return (
    <div>
      <div
        className="rounded mb-4"
        style={{
          background: "#f4f4f4",
          padding: "10px",
        }}
      >
        <label className="pb-1">ফাইল আপলোড করুন</label>
        <Form.Group>
          <Form.Control
            type="file"
            id="workSoftCopy"
            name="workSoftCopy"
            onChange={handleFileChange}
            label="অনুবাদকর্মের কপিরাইটভুক্ত অন্য কোন স্বত্বাধিকারীর ফাইল"
            accept="image/png,image/jpg,image/jpeg"
          />

          {formik.touched.workSoftCopy && formik.errors.workSoftCopy && (
            <div className="text-danger">{formik.errors.workSoftCopy}</div>
          )}
        </Form.Group>

        {/* Preview Section */}
        {preview && (
          <div className="mt-3">
            <img
              src={preview}
              alt="File preview"
              style={{
                height: "100px",
                borderRadius: "5px",
                border: "1px solid #ddd",
              }}
            />
          </div>
        )}
      </div>

      <div className="mt-4 text-center">
        <Button
          type="submit"
          className="btn btn-primary"
          onClick={formik.handleSubmit}
          disabled={isLoadingSubmit}
        >
          {isLoadingSubmit ? <BeatLoader /> : "সম্পাদন করুন"}
        </Button>
      </div>
    </div>
  );
};

export default ArtisticWork;
