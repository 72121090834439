import { Handshake, Home } from "@mui/icons-material";
import React, { useState } from "react";
import RegisterBookContent from "../../../../../components/reg_book/RegisterBookContent";
import { useGetRegBookAgreementQuery } from "../../../../../redux/api/submittedCopyrightAgreementApiSlice";
import AgreementTable from "../../../../../components/reg_book/AgreementTable";
import Loader from "../../../../../components/custom/Loader";

const RegisterBookAgreement = () => {
  const bookType = 1;
  const [trackReg, setTrackReg] = useState("");
  const [regNo, setRegNo] = useState("");
  const { data, isLoading } = useGetRegBookAgreementQuery({
    bookType,
    trackReg,
    registration_no: regNo,
  });
  const breadcrumbsData = [
    { icon: Home, title: "হোম", url: "/" },
    {
      icon: Handshake,
      title: "চুক্তিপত্র - রেজিস্টার বুক",
      active: true,
    },
  ];

  const applicationPath = {
    copyright: "/admin/register-book-copyright",
    agreement: "/admin/register-book-agreement",
  };

  if (isLoading) {
    return <Loader />;
  }
  return (
    <RegisterBookContent
      data={data}
      trackReg={trackReg}
      setTrackReg={setTrackReg}
      regNo={regNo}
      setRegNo={setRegNo}
      showRegNo={true}
      content={<AgreementTable data={data} />}
      breadcrumbsData={breadcrumbsData}
      applicationPath={applicationPath}
    />
  );
};

export default RegisterBookAgreement;
