import { apiSlice } from "./apiSlice";
import { FAQ, FAQ_CLIENT } from "../constants";

export const faqApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    fetchFaqClient: builder.query({
      query: () => FAQ_CLIENT,
      providesTags: ["faq"],
    }),
    getFaq: builder.query({
      query: ({ searchValue = "", currentPage = 1 }) => {
        let url = `${FAQ}?page=${currentPage}&per_page=10`;
        if (searchValue) {
          url += `&question=${searchValue}`;
        }

        return {
          url: url,
          method: "GET",
        };
      },
      providesTags: ["faq"],
    }),
    findFaq: builder.query({
      query: (id) => FAQ + "/" + id,
    }),
    crateFaq: builder.mutation({
      query: (formData) => ({
        url: `${FAQ}`,
        method: "POST",
        body: formData,
      }),
      invalidatesTags: ["faq"],
    }),
    updateFaq: builder.mutation({
      query: ({ formData, id }) => ({
        url: `${FAQ}/${id}`,
        method: "POST",
        body: formData,
      }),
      invalidatesTags: ["faq"],
    }),
    deleteFaq: builder.mutation({
      query: (id) => ({
        url: FAQ + "/" + id,
        method: "DELETE",
      }),
      invalidatesTags: ["faq"],
    }),
  }),
});

export const {
  useFetchFaqClientQuery,
  useGetFaqQuery,
  useFindFaqQuery,
  useCrateFaqMutation,
  useUpdateFaqMutation,
  useDeleteFaqMutation,
} = faqApiSlice;
