import React from "react";
import styles from "./CertificateOne.module.css";
import HeadingPart from "./HeadingPart";

const CertificateOfRelatedRight = ({ data }) => {
  const publicUrl = process.env.PUBLIC_URL;

  return (
    <div
      style={{
        margin: "auto",
      }}
      className={`${styles.certificate}`}
      id={`${styles.printArea}`}
    >
      <div className={`${styles.section1}`}>
        <div className={`${styles.header}`}>
          <h1>গণপ্রজাতন্ত্রী বাংলাদেশ সরকার</h1>
          <p>Government of the People's Republic of Bangladesh</p>
        </div>
      </div>

      <HeadingPart data={data} />

      <div className={`${styles.section3}`}>
        <div className={`${styles.heading}`}>
          <h4>বাংলাদেশ কপিরাইট অফিস</h4>
          <h3>Bangladesh Copyright Office</h3>
        </div>
        <div className={`${styles.subHeading}`}>
          <div>
            <h4>রিলেটেড রাইট রেজিস্ট্রেশন সনদ</h4>
            <h3 className={`${styles.adorsholipiEng}`}>
              Certificate of Related Right Registration
            </h3>
          </div>
        </div>
      </div>

      <div className={`${styles.section4}`}>
        <table class={`${styles.my_certificate_table} table text-center`}>
          <tbody>
            <tr className={`${styles.cusTopBorder}`}>
              <td colspan="2">
                <span
                  class={`${styles.sp1} ${styles.borderDashed} ${styles.fontAdorshoLipi}`}
                >
                  সম্পাদিত কর্মের শিরোনাম
                </span>
                <br />
                <span
                  class={`${styles.sp2} ${styles.borderDashed} ${styles.fontGaramond}`}
                >
                  Title of Performed Work
                </span>
                <br />
                <span class={`${styles.sp3} ${styles.fontCalligraphy}`}>
                  {data.title}
                </span>
              </td>
            </tr>

            <tr className={`${styles.cusTopBorder}`}>
              <td colspan="2">
                <span
                  class={`${styles.sp1} ${styles.borderDashed} ${styles.fontAdorshoLipi}`}
                >
                  সম্পাদনকারী (সম্পাদিত বিষয় ও অংশ )
                </span>
                <br />
                <span
                  class={`${styles.sp2} ${styles.borderDashed} ${styles.fontGaramond}`}
                >
                  Performer (Performed Work & Proportion)
                </span>
                <br />
                <span class={`${styles.sp3} ${styles.fontCalligraphy}`}>
                  {data.author}
                </span>
              </td>
            </tr>

            <tr className={`${styles.cusTopBorder}`}>
              <td colspan="2">
                <span
                  class={`${styles.sp1} ${styles.borderDashed} ${styles.fontAdorshoLipi}`}
                >
                  সম্পাদিত কর্মের স্বত্বাধিকারী (স্বত্বের অংশ)
                </span>
                <br />
                <span
                  class={`${styles.sp2} ${styles.borderDashed} ${styles.fontGaramond}`}
                >
                  Owner of the Performed Work (Proportion)
                </span>
                <br />
                <span class={`${styles.sp3} ${styles.fontCalligraphy}`}>
                  {data.owner}
                </span>
              </td>
            </tr>

            <tr className={`${styles.cusTopBorder2}`}>
              <td>
                <span
                  class={`${styles.sp1} ${styles.borderDashed} ${styles.fontAdorshoLipi}`}
                >
                  স্বত্ব প্রাপ্তির মাধ্যম
                </span>
                <br />
                <span
                  class={`${styles.sp2} ${styles.borderDashed} ${styles.fontGaramond}`}
                >
                  Mode of Ownership Gain
                </span>
                <br />
                <span class={`${styles.sp3} ${styles.fontCalligraphy}`}>
                  {data.owner_gain}
                </span>
              </td>
              <td>
                <span
                  class={`${styles.sp1} ${styles.borderDashed} ${styles.fontAdorshoLipi}`}
                >
                  সম্পাদিত কর্মের ক্ষেত্র
                </span>
                <br />
                <span
                  class={`${styles.sp2} ${styles.borderDashed} ${styles.fontGaramond}`}
                >
                  Field of the Performed Work
                </span>
                <br />
                <span class={`${styles.sp3} ${styles.fontCalligraphy}`}>
                  {data.field_work}
                </span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div className={`${styles.section5}`}>
        <span className={`${styles.adorsholipiEng}`}>
          The Registrar of Copyrights, Bangladesh Copyright Office is honored to
          issue this Intellectual Property Right <br /> Certificate Under the
          Bangladesh Copyright Act.
        </span>
      </div>

      <div className={`${styles.section6}`}>
        <div className={`${styles.signaturePart}`}>
          <img src={data.sign} alt="signature" id="" /> <br />
          <br />
          <span className={`${styles.adorsholipiEng}`}>
            Registrar of Copyrights
          </span>{" "}
          <br />
          <small className={`${styles.fontGaramond}`}>
            www.copyrightoffice.gov.bd
          </small>
        </div>
      </div>

      {data.imageArtistic && (
        <div className={`${styles.section7}`}>
          <div className={`${styles.imagePart}`}>
            <p className={`${styles.fontGaramond}`}>
              Image of the Artistic Work
            </p>
            <img
              style={{
                width: "120px",
              }}
              src={data.imageArtistic}
              alt="60002224"
              id=""
            />
          </div>
        </div>
      )}
      {data.attention && data.attention.length > 0 && (
        <div className={`${styles.section8}`}>
          <p className={`${styles.fontGaramond}`}>Attention:</p>
          <div className={`${styles.attentionBox}`}>
            {data.attention.map((item, index) => (
              <p key={index} className={`${styles.adorsholipiEng}`}>
                <span className={`${styles.ulStar}`}>*</span> {item}
              </p>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default CertificateOfRelatedRight;
