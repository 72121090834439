import { PAYMENT_RESPONSE, PAYMENT_STATUS } from "../constants";
import { apiSlice } from "./apiSlice";

const paymentApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    paymentResponse: builder.query({
      query: ({ status, transId }) =>
        PAYMENT_RESPONSE + "/" + status + "?transId=" + transId,
    }),
    paymentStatus: builder.query({
      query: (transaction_id) => PAYMENT_STATUS + "/" + transaction_id,
    }),
  }),
});

export const { usePaymentResponseQuery, usePaymentStatusQuery } =
  paymentApiSlice;
