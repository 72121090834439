import React from "react";
import { useShowHearingAgreementDetailsQuery } from "../../../../../redux/api/submittedCopyrightAgreementApiSlice";
import Loader from "../../../../../components/custom/Loader";
import { useParams } from "react-router-dom";
import { AgreementGenerateAttachments } from "../../../../../utils/agreementGenerateAttachArray";
import AgreementPreviewFormOne from "../../../../../components/preview_applications/agreement/AgreementPreviewFormOne";
import AgreementPreviewFormTwo from "../../../../../components/preview_applications/agreement/AgreementPreviewFormTwo";
import CommonDetails from "../../details/CommonDetails";
import { useAuth } from "../../../../../utils/authorization";
import { transformAgreementData } from "../../../../../utils/transformAgreementData";
import HearingContent from "../../../../../components/common-content/HearingContent";
import PaymentPreview from "../../../../../components/preview_applications/payment/PaymentPreview";

const HearingAgreementDetails = () => {
  const { authUser } = useAuth();
  const { id } = useParams();
  const { data, isLoading } = useShowHearingAgreementDetailsQuery(id, {
    refetchOnMountOrArgChange: true,
  });

  const documents = data?.data;

  if (isLoading) {
    return <Loader />;
  }

  const forInfo = transformAgreementData(documents);

  const infoTable = AgreementGenerateAttachments(forInfo);

  const info = {
    fileStatus: Number(data?.data?.fileStatus),
    noteNumber: data?.data?.trackReg,
    regNumber: "",
    applicantName: data?.data?.applicantName,
    workTitle: data?.data?.documents?.worktitle,
    category: data?.data?.documents?.category?.name,
    amount: data?.data?.amount,
    challan: Number(data?.data?.st) === 3 ? "চালান" : "ট্রেজারি",
    challanNumber:
      Number(data?.data?.st) === 3
        ? data?.data?.echallanId
        : data?.data?.treasuryNo,
    treasuryDate: data?.data?.treasuryDate || new Date(),
    admin: data?.data?.admin,
    hearing: data?.data?.hearing,

    // TODO: certificate data
    name: data?.data?.applicantName,
    email: data?.data?.user?.email,
    address: data?.data?.communicationAddress,
  };

  const formOne = (
    <div className="p-4 mt-5">
      <AgreementPreviewFormOne data={data?.data} />
    </div>
  );
  const formTwo = (
    <div className="pt-5">
      <AgreementPreviewFormTwo data={data?.data} />
    </div>
  );

  const comments =
    authUser?.id === 8
      ? data?.data?.comments.find((c) => Number(c.submit) === 2)
      : data?.data?.comments.length > 0 && data?.data?.comments[0];

  const commentsValue = data?.data?.comments.find(
    (c) => Number(c.submit) === 2
  );

  const noteSheetList =
    data?.data?.comments.filter((c) => Number(c.submit) === 1) ?? [];

  const content = <HearingContent comments={commentsValue} info={info} />;

  const commentsNoteSheet = {
    noteSheetList,
    noteSheetValues: comments?.status,
  };
  const pageTitle =
    info.fileStatus === 20
      ? "শুনানীর চিঠি (অনুমোদিত)"
      : info.fileStatus === 10
      ? "শুনানীর চিঠি"
      : "শুনানীর নথি";
  return (
    <div>
      <CommonDetails
        content={content}
        commentsNoteSheet={commentsNoteSheet}
        formOne={formOne}
        formTwo={formTwo}
        payment={<div  className="p-4 mt-5"><PaymentPreview data={data?.data} /></div>}
        info={info}
        infoTable={infoTable}
        breadcrumbsTitle={pageTitle}
      />
    </div>
  );
};

export default HearingAgreementDetails;
