import { Feed, Home, Print } from "@mui/icons-material";
import { Tooltip } from "@mui/material";
import React, { useRef, useState } from "react";
import { Button, Tab, Tabs } from "react-bootstrap";
import { FaMinus, FaPlus } from "react-icons/fa";
import { useReactToPrint } from "react-to-print";
import IconBreadcrumbs from "../../../../components/custom/IconBreadcrumbs";
import NoteSheet from "../../../../components/NoteSheet";

const CommonDetails = ({
  commentsNoteSheet,
  formOne,
  formTwo,
  payment,
  info,
  infoTable,
  content,
  breadcrumbsTitle, 
  breadcrumbsTitle2, 
}) => {
  const [showForm, setShowForm] = useState(false);
  const printRef = useRef(null);
  const breadcrumbsData = [
    { icon: Home, title: "হোম", url: "/" },
    { icon: Feed, title: breadcrumbsTitle2 ?? "আবেদন পত্র" },
    { icon: Feed, title: breadcrumbsTitle ?? "আবেদন পত্র", active: true },
  ];

  const handlePrint = useReactToPrint({
    content: () => printRef.current,
  });

  return (
    <div>
      <div>
        <div className="py-3">
          <IconBreadcrumbs breadcrumbs={breadcrumbsData} />
        </div>
        <div className="slideUp dashboard-layout p-3">
          <div className="row">
            <div className="col-md-12 mb-3">
              <div className="bg-slate p-2 fw-semibold d-flex justify-content-between align-items-center">
                <span>আবেদন নং | {info?.noteNumber}</span>
                <div className="d-flex justify-content-between align-items-center gap-2">
                  <Tooltip
                    title={
                      showForm ? "আবেদনপত্র বন্ধ করুন" : "আবেদন পত্র দেখুন"
                    }
                  >
                    <Button
                      variant={showForm ? "outline-danger" : "outline-success"}
                      size="sm"
                      onClick={() => setShowForm(!showForm)}
                      className="px-4"
                    >
                      {showForm ? <FaMinus /> : <FaPlus />}
                    </Button>
                  </Tooltip>
                </div>
              </div>
            </div>
            {!showForm ? (
              <>
                <div className="col-md-6 slideUp">{content}</div>

                <div className="col-md-6 slideUp">
                  <div className="d-flex justify-content-between align-items-center bg-slate p-2 ">
                    <div className="fw-semibold">সম্পূর্ণ নোট শীট</div>

                    <span onClick={handlePrint}>
                      <Print className="text-primary fs-4 cursor-pointer" />
                    </span>
                  </div>
                  <div
                    className="card my-2 p-2"
                    style={{ height: "800px", overflow: "auto" }}
                  >
                    <NoteSheet
                      commentsNoteSheet={commentsNoteSheet}
                      info={info}
                      infoTable={infoTable}
                      printRef={printRef}
                    />
                  </div>
                </div>
              </>
            ) : (
              <div className="col-12 slideUp">
                <Tabs
                  defaultActiveKey="step1"
                  id="uncontrolled-tab-example"
                  fill
                >
                  <Tab eventKey="step1" title="আবেদন পত্র - ১ম অংশ">
                    <div className="border border-top-0 mb-3 slideUp">
                      {formOne}
                    </div>
                  </Tab>
                  <Tab eventKey="step2" title="আবেদন ফরম - ২য় অংশ">
                    <div className="border border-top-0 mb-3 slideUp">
                      {formTwo}
                    </div>
                  </Tab>
                  <Tab eventKey="step3" title="পেমেন্ট">
                    <div className="border border-top-0 mb-3 slideUp">
                      {payment}
                    </div>
                  </Tab>
                </Tabs>
              </div>
            )}
          </div>
        </div>
        <div style={{ height: "30vh" }}></div>
      </div>
    </div>
  );
};

export default CommonDetails;
