import { CATALOG_DOCUMENT } from "../constants";
import { apiSlice } from "./apiSlice";

const catalogDocumentApiSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getCatalogDocument: builder.query({
            query: () => {
                return CATALOG_DOCUMENT;
            },
        }),
    }),
});


export const {useGetCatalogDocumentQuery} = catalogDocumentApiSlice;
