import * as yup from "yup";

export const initializeValues = {
  question: "",
  answer: "",
  status: "",
  sorting_index: "",
};

export const validationSchema = yup.object().shape({
  question: yup.string().required("প্রশ্ন প্রযোজ্য।"),
  answer: yup.string().required("উত্তর প্রযোজ্য।"),
  sorting_index: yup.string().required("ইনডেক্স  প্রযোজ্য।"),
  status: yup.string().required("স্ট্যাটাস প্রযোজ্য।"),
});
