import React, { act, useEffect, useRef, useState } from "react";
import {
  useCertificateEditPermissionMutation,
  useShowCertificateQuery,
} from "../../../../../redux/api/submittedCopyrightAgreementApiSlice";
import Loader from "../../../../../components/custom/Loader";
import { useNavigate, useParams } from "react-router-dom";
import CertificateOfCopyright from "../../../../frontend/certificate/CertificateOfCopyright";
import { Button, Card, Form } from "react-bootstrap";
import ReactToPrint from "react-to-print";
import CertificateOfRelatedRight from "../../../../frontend/certificate/CertificateOfRelatedRight";
import CertificateOfBroadcast from "../../../../frontend/certificate/CertificateOfBroadcast";
import LicenseCertificate from "../../../../frontend/certificate/LicenseCertificate";
import { enLocalDateFormat } from "../../../../../utils/dateFormat";
import { assets } from "../../../../../assets";
import {
  Copyright,
  Download,
  Edit,
  Print,
  ReplyAll,
  Home,
  Note,
} from "@mui/icons-material";
import IconBreadcrumbs from "../../../../../components/custom/IconBreadcrumbs";
import { accessRoles } from "../../../../../utils/accessRoles";
import { useAuthorized } from "../../../../../hooks/useAuthorized";
import toast from "react-hot-toast";
import UpdateCertificate from "../../../../../components/updated-certificate/UpdateCertificate";

const PrintCopyrightCertificate = () => {
  const printRef = useRef();
  const { id, user_id } = useParams();
  const { data, isLoading, refetch } = useShowCertificateQuery({ id, user_id });
  const navigate = useNavigate();
  const { isLoading: isLoadingAuthorized, hasRole } = useAuthorized();
  const [editPermission, setEditPermission] = useState("");
  const [editOpen, setEditOpen] = useState(false);

  const [updateCertificatePermission, { isSuccess, data: dataPermission }] =
    useCertificateEditPermissionMutation();

  const ac = data?.data?.archive;
  const pd = data?.data?.profileDetail;

  const publicationDate = () => {
    if (Number(pd?.certificate) === 1) {
      if (Number(pd?.workPublishtype) === 1) {
        if (ac?.publish_year) {
          return ac.publish_year;
        } else {
          return pd?.publish?.year;
        }
      } else {
        return "Unpublished";
      }
    } else {
      if (Number(pd?.pub_unpub) === 1) {
        return pd?.pub_unpub_year;
      } else {
        return "Unpublished";
      }
    }
  };

  const handleAuthor = () => {
    if (Number(pd?.certificate) === 1) {
      if (ac?.writers_name) {
        return ac?.writers_name;
      } else {
        // return pd?.writerDetails.map((item) => item.writerName);
        return pd?.writerDetails.map((item, index) =>
          index === pd.certificateLangs.length - 1
            ? item.writerName
            : item.writerName + ", "
        );
      }
    } else {
      return pd?.certificateLangs.map((item) => item.author_creator).join(", ");
      // return pd?.certificateLangs.map((item, index) =>
      //   index === pd.certificateLangs.length - 1
      //     ? item.author_creator
      //     : item.author_creator + ", "
      // );
    }
  };

  const handleOwner = () => {
    if (Number(pd?.certificate) === 1) {
      return ac?.cw_proportion;
    } else {
      // return pd?.certificateLangs.map((item) => item.owner_right);
      return pd?.certificateLangs.map((item, index) =>
        index === pd.certificateLangs.length - 1
          ? item.owner_right
          : item.owner_right + ", "
      );
    }
  };
  const handleFieldWork = () => {
    if (Number(pd?.certificate) === 1) {
      const category =
        pd?.category?.name +
        "(" +
        (pd?.otherSubCategory ? pd?.otherSubCategory : pd?.subCategory?.name) +
        ")";
      return category;
    } else {
      return ac?.creative_work;
    }
  };

  const handleSign = () => {
    if (ac?.edit_permission !== 1 && ac?.authorizer) {
      return ac.authorizer.sign;
    } else {
      return assets.sign;
    }
  };

  const certificateData = {
    // heading
    publication_date: publicationDate(),
    registration_no: ac?.registration_no,
    date_of_reg: enLocalDateFormat(ac?.date_of_reg),
    validity: ac?.expiry_year
      ? ac?.life_plus === 1
        ? `Life + ${ac.expiry_year} ${
            ac.expiry_year.length > 1 ? "years" : "year"
          }`
        : `${ac.expiry_year} ${ac.expiry_year.length > 1 ? "years" : "year"}`
      : enLocalDateFormat(ac?.expiry_date),

    // body
    title: Number(pd?.certificate) === 1 ? pd?.workTitle : pd?.work_title,
    author: handleAuthor(),
    owner: handleOwner(),
    owner_gain: ac?.ownergain,
    field_work: handleFieldWork(),

    // only for license
    tc_license: ac?.tc_licence,

    // footer
    attention: ac?.attention,
    sign: handleSign(),
    imageArtistic:
      pd?.category?.id === 3 && pd?.workSoftCopies.length > 0
        ? pd?.workSoftCopies[0].workSoftCopy
        : "",
  };

  const handleBack = () => {
    navigate(-1);
  };

  const breadcrumbsData = [
    { icon: Home, title: "হোম", url: "/" },
    {
      icon: Copyright,
      title: "কপিরাইট - সার্টিফিকেট",
    },
    {
      icon: Note,
      title: "কপিরাইট রেজিস্ট্রেশন সনদ",
      active: true,
    },
  ];

  const handlePermissionsChange = (e) => {
    const val = e.target.checked ? 1 : 2;
    setEditPermission(val);
    const formData = {
      edit_permission: val,
    };
    updateCertificatePermission({ id, formData }).then(() => {
      refetch();
    });
  };

  useEffect(() => {
    if (ac?.edit_permission) {
      setEditPermission(ac.edit_permission);
    }
  }, [ac?.edit_permission]);

  useEffect(() => {
    if (isSuccess) {
      toast.success(dataPermission?.message);
    }
  }, [isSuccess]);

  if (isLoading || isLoadingAuthorized) {
    return <Loader />;
  }

  return (
    <>
      {data?.data ? (
        <>
          <div className="py-3">
            <IconBreadcrumbs breadcrumbs={breadcrumbsData} />
          </div>
          <div className="dashboard-layout slideUp">
            <div className="py-4 text-center">
              <span>
                <Card variant="outlined">
                  <div className="d-flex p-2 gap-2">
                    <div className="cursor-pointer">
                      <ReactToPrint
                        trigger={() => (
                          <div>
                            <Print /> Print
                          </div>
                        )}
                        documentTitle="certificate"
                        content={() => printRef.current}
                      />
                    </div>
                    {/* <span className="border"></span>
                    <div className="cursor-pointer">
                      {" "}
                      <Download />
                      Download
                    </div> */}
                    {hasRole([accessRoles.Calligrapher]) &&
                      ac?.edit_permission === 1 && (
                        <>
                          <span className="border"></span>
                          <div
                            className="cursor-pointer"
                            onClick={() => setEditOpen(true)}
                          >
                            <Edit />
                            Edit
                          </div>
                        </>
                      )}
                    {hasRole([accessRoles.Register]) && (
                      <>
                        <span className="border"></span>
                        <div className="cursor-pointer">
                          <Form.Check
                            type="switch"
                            id="custom-switch"
                            value={editPermission}
                            label={
                              editPermission === 1
                                ? "সম্পাদনার অনুমতি চালু"
                                : "সম্পাদনার অনুমতি বন্ধ"
                            }
                            checked={editPermission === 1}
                            onChange={handlePermissionsChange}
                          />
                        </div>
                      </>
                    )}
                    <span className="border"></span>
                    <div className="cursor-pointer" onClick={handleBack}>
                      <ReplyAll />
                      Back
                    </div>
                  </div>
                </Card>
              </span>
            </div>
            <div className="pb-5">
              <div ref={printRef}>
                {ac?.certificate_type === 1 ? (
                  <CertificateOfCopyright data={certificateData} />
                ) : ac?.certificate_type === 2 ? (
                  <LicenseCertificate data={certificateData} />
                ) : ac?.certificate_type === 3 ? (
                  <CertificateOfRelatedRight data={certificateData} />
                ) : (
                  <CertificateOfBroadcast data={certificateData} />
                )}
              </div>
            </div>
          </div>
          <UpdateCertificate
            refetch={refetch}
            open={editOpen}
            data={data?.data}
            onHide={() => setEditOpen(false)}
          />
        </>
      ) : (
        <>no data found</>
      )}
    </>
  );
};

export default PrintCopyrightCertificate;
