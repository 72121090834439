import React from "react";
import SubmittedCopyright from "../sumitted_copyright/SubmittedCopyright";

const ReplyOrderedCopyright = () => {
  const fileStatus = 24;
  const applicationPath = {
    copyright: "/admin/reply-ordered-copyright",
    agreement: "/admin/reply-ordered-agreement",
  };
  const detailsRoute = "/admin/submitted-copyright-details-verify";
  const pageTitle = "লাইব্রেরিয়ান প্রদত্ত নথি";
  return (
    <SubmittedCopyright
      fileStatus={fileStatus}
      applicationPath={applicationPath}
      detailsRoute={detailsRoute}
      pageTitle={pageTitle}
    />
  );
};

export default ReplyOrderedCopyright;
