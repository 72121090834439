import { Download, Print } from "@mui/icons-material";
import React, { useRef } from "react";
import ReactToPrint from "react-to-print";
import AgreementPreviewFormOne from "../agreement/AgreementPreviewFormOne";
import AgreementPreviewFormTwo from "../agreement/AgreementPreviewFormTwo";
import PaymentPreview from "../payment/PaymentPreview";

const AgreementFormPreview = ({ data, printRef }) => {
  const printSectionRef = useRef();
  const mainData = data?.data;
  return (
    <section className="section pt-4 pb-5 chuktipotro2 service-single">
      {!printRef && (
        <div className="container">
          <div className="row">
            <div className="col-lg-10 mb-2 attentionmessage1">
              <p className="mb-0 border attentionmessage text-justify">
                আমি / আমরা ঘোষণা করছি যে, কপিরাইট রেজিস্ট্রেশনের আবেদনে আমার
                পূরণকৃত সকল তথ্য ও সংযুক্ত দলিলসমূহ সত্য ও নির্ভুল এবং এ
                কর্মটিকে কেন্দ্র করে কোন মামলা কোন বিজ্ঞ আদালত বা ট্রাইব্যুনালে
                বিচারাধীন নেই । উল্লিখিত তথ্য বা দলিলে কোন ভুল ও অসত্য তথ্য
                উদ্‌ঘাটিত হলে আমি / আমরা এর জন্য ব্যক্তিগতভাবে দায়ী থাকব এবং
                দেশের প্রচলিত আইন অনুযায়ী যেকোন দন্ড গ্রহণে বাধ্য হবো ।
              </p>
            </div>

            <div className="col-lg-2">
              <div className="row">
                <div className="col-md-12">
                  <ReactToPrint
                    trigger={() => (
                      <button className="custombtn555">
                        <Print /> প্রিন্ট করুন
                      </button>
                    )}
                    content={() => printSectionRef.current}
                  />
                </div>
                <div className="col-md-12">
                  <button className="custombtn5555">
                    <Download /> ডাউনলোড করুন
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      <div
        className="container mt-4 fullformprint printsection"
        ref={printRef || printSectionRef}
      >
        <div className={`${printRef ? "show-print" : ""}`}>
          <div className="border printborder">
            <div className="d-flex justify-content-center printborder borderbottomm align-items-center">
              <div className="card w-100">
                <div className="tab-content p-4" id="nav-tabContent">
                  <AgreementPreviewFormOne data={mainData} />
                </div>
              </div>
            </div>

            <div className="d-flex mt-2 pagebreak borderbottomm paddingbocfttom justify-content-center align-items-center">
              <div className="card w-100">
                <AgreementPreviewFormTwo data={mainData} />
              </div>
            </div>

            <div className="d-flex my-4 justify-content-center pagebreak printborder">
              <div className="card w-100 mx-4">
                <PaymentPreview data={mainData} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AgreementFormPreview;
