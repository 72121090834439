import React from "react";
import IconBreadcrumbs from "../custom/IconBreadcrumbs";
import { Link, useLocation } from "react-router-dom";
import { Typography } from "@mui/material";
import { Copyright, Handshake, Search } from "@mui/icons-material";
import { Form, InputGroup } from "react-bootstrap";

const RegisterBookContent = ({
  breadcrumbsData,
  applicationPath,
  content,
  trackReg,
  setTrackReg,

  regNo,
  setRegNo,
  showRegNo,
}) => {
  const location = useLocation();

  return (
    <div>
      <div className="py-3">
        <IconBreadcrumbs breadcrumbs={breadcrumbsData} />
      </div>
      <div className="slideUp dashboard-layout p-3">
        <div className="">
          <div className="row">
            <div className="col-md-6 mb-3">
              <span className="border border-success">
                <Link to={applicationPath.copyright}>
                  <Typography
                    fontWeight="bold"
                    className={`${
                      location.pathname === applicationPath.copyright &&
                      "text-white  bg-success"
                    } py-2 px-4  d-flex align-items-center gap-2`}
                  >
                    <Copyright /> কপিরাইট{" "}
                  </Typography>
                </Link>
                <Link to={applicationPath.agreement}>
                  <Typography
                    fontWeight="bold"
                    className={`${
                      location.pathname === applicationPath.agreement &&
                      "text-white  bg-success "
                    } py-2 px-4 d-flex align-items-center gap-2`}
                  >
                    <Handshake /> চুক্তিপত্র{" "}
                  </Typography>
                </Link>
              </span>
            </div>
            <div className="col-md-6">
              <div className="row justify-content-end">
                {showRegNo && (
                  <>
                    <div className="col-md-6">
                      <InputGroup className="mb-3">
                        <InputGroup.Text id="basic-reg">
                          নিবন্ধন সংখ্যা
                        </InputGroup.Text>
                        <Form.Control
                          value={regNo}
                          onChange={(e) => setRegNo(e.target.value)}
                          placeholder="খুঁজুন"
                          aria-label="search"
                          aria-describedby="basic-addon1"
                        />
                      </InputGroup>
                    </div>
                  </>
                )}
                <div className="col-md-6">
                  <InputGroup className="mb-3">
                    <InputGroup.Text id="basic-addon1">
                      নথি নাম্বার
                    </InputGroup.Text>
                    <Form.Control
                      value={trackReg}
                      onChange={(e) => setTrackReg(e.target.value)}
                      placeholder="খুঁজুন"
                      aria-label="search"
                      aria-describedby="basic-addon1"
                    />
                  </InputGroup>
                </div>
              </div>
            </div>
          </div>
          <div className="mt-1">{content}</div>
        </div>
      </div>
    </div>
  );
};

export default RegisterBookContent;
