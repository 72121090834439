import React from "react";
import styles from "./LicenceCertificate.module.css";
import stylesOne from "./CertificateOne.module.css";
import HeadingPart from "./HeadingPart";

const LicenseCertificate = ({ data }) => {
  return (
    <div
      style={{
        margin: "auto",
      }}
      className={`${styles.certificates}`}
      id={`${styles.printAreas}`}
    >
      <div className={`${styles.section1s}`}>
        <div className={`${styles.headers}`}>
          <h1>গণপ্রজাতন্ত্রী বাংলাদেশ সরকার</h1>
          <p>Government of the People's Republic of Bangladesh</p>
        </div>
      </div>

      <HeadingPart data={data} />

      <div className={`${styles.section3s}`}>
        <div className={`${styles.headings}`}>
          <h3>Copyright Office</h3>
        </div>
        <div className={`${styles.subHeadings}`}>
          <h3 className="font-eng">Certificate of License Registration</h3>
        </div>
      </div>

      <div className={`${styles.section4s}`}>
        <table class={`${styles.my_certificate_table} table text-center`}>
          <tbody>
            <tr className={`${styles.cusTopBorders}`}>
              <td colspan="2">
                <span class={`${styles.sp2s} ${styles.fontGaramond}`}>
                  Title of Work
                </span>
                <br />
                <div className={`${styles.cusMiddleBorderss}`}></div>
                <span class={`${styles.sp3s} ${styles.fontCalligraphy}`}>
                  {data.title}
                </span>
              </td>
            </tr>

            <tr className={`${styles.cusTopBorders}`}>
              <td colspan="2">
                <span class={`${styles.sp2s} ${styles.fontGaramond}`}>
                  Name of Licensee
                </span>
                <br />
                <div className={`${styles.cusMiddleBorderss}`}></div>
                <span class={`${styles.sp3s} ${styles.fontCalligraphy}`}>
                  {data.author}
                </span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div className={`${styles.sectionLicences}`}>
        <table class={`${styles.my_certificate_table} table text-center`}>
          <tbody>
            <tr className={`${styles.cusTopBorder2s}`}>
              <td>
                <div className={`${styles.licenceBox1s}`}>
                  <span
                    class={`${styles.sp2s} ${styles.borderSolids} ${styles.fontGaramond}`}
                  >
                    Field of Copyright
                  </span>
                  <br />
                  <span class={`${styles.sp3s} ${styles.fontCalligraphy}`}>
                    {data.field_work}
                  </span>
                </div>
                <div className={`${styles.licenceBox2s}`}>
                  <span
                    class={`${styles.sp2s} ${styles.borderSolids} ${styles.fontGaramond}`}
                  >
                    Type & Conditions of License
                  </span>
                  <br />
                  <span class={`${styles.sp3s} ${styles.fontCalligraphy}`}>
                    {data?.tc_license}
                  </span>
                </div>
              </td>
              <td>
                <div className={`${styles.licenceBox1s}`}>
                  <span
                    class={`${styles.sp2s} ${styles.borderSolids} ${styles.fontGaramond}`}
                  >
                    Creator of the Work
                  </span>
                  <br />
                  <span class={`${styles.sp3s} ${styles.fontCalligraphy}`}>
                    {data.owner}
                  </span>
                </div>
                <div className={`${styles.licenceBox2s}`}>
                  <span
                    class={`${styles.sp2s} ${styles.borderSolids} ${styles.fontGaramond}`}
                  >
                    Name of Licensor & Authority
                  </span>
                  <br />
                  <span class={`${styles.sp3s} ${styles.fontCalligraphy}`}>
                    {data.owner_gain}
                  </span>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div className={`${styles.section5s}`}>
        <span className={`${styles.adorsholipiEng}`}>
          The Certificate is issued by the Registrar of Copyrights, The People's
          Republic of Bangladesh under the <br />
          Bangladesh Copyright Act.
        </span>
      </div>

      <div className={`${styles.section6s}`}>
        <div className={`${styles.signatureParts}`}>
          <img src={data.sign} alt="signature" id="" /> <br />
          <br />
          <span className={`${styles.adorsholipiEng}`}>
            Registrar of Copyrights
          </span>{" "}
          <br />
          <small className={`${styles.fontGaramond}`}>
            www.copyrightoffice.gov.bd
          </small>
        </div>
      </div>

      {data.imageArtistic && (
        <div className={`${styles.section7s}`}>
          <div className={`${styles.imagePart}`}>
            <p className={`${styles.fontGaramond}`}>
              Image of the Artistic Work
            </p>
            <img
              style={{
                width: "120px",
              }}
              src={data.imageArtistic}
              alt="60002224"
              id=""
            />
          </div>
        </div>
      )}
      {data.attention && data.attention.length > 0 && (
        <div className={`${stylesOne.section8}`}>
          <p className={`${stylesOne.fontGaramond}`}>Attention:</p>
          <div className={`${stylesOne.attentionBox}`}>
            {data.attention.map((item, index) => (
              <p key={index} className={`${stylesOne.adorsholipiEng}`}>
                <span className={`${stylesOne.ulStar}`}>*</span> {item}
              </p>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default LicenseCertificate;
