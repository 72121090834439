import { Home, Report } from "@mui/icons-material";
import React, { useState } from "react";
import IconBreadcrumbs from "../../../../components/custom/IconBreadcrumbs";
import ReusableTable from "../../../../components/custom/data_table/ReusableTable";
import { useGetFileActivityQuery } from "../../../../redux/api/reportApiSlice";
import { localDateFormat } from "../../../../utils/dateFormat";
import ActivityHistoryCount from "./ActivityHistoryCount";
import FileActivityFilter from "./FileActivityFilter";

const FileActivity = () => {
  const [show, setShow] = useState(false);
  const [viewData, setViewData] = useState("");
  const [date, setDate] = useState("");
  const [sendTo, setSendTo] = useState("");
  const [sendFrom, setSendFrom] = useState("");
  const [page, setPage] = useState(1);

  const { data, isLoading } = useGetFileActivityQuery({
    date,
    page,
    sendTo,
    sendFrom,
  });

  const breadcrumbsData = [
    { icon: Home, title: "হোম" },
    { icon: Report, title: "নথি রিপোর্ট" },
    { icon: Report, title: "কর্মসূচি", active: true },
  ];

  // data table start
  const totalData = data?.meta?.total;
  const lastPage = data?.meta?.last_page;

  const handlePageChange = (page) => {
    setPage(page);
  };

  const columns = [
    { label: "তারিখ", name: "date" },

    {
      label: "ট্র্যাকিং নম্বর",
      name: "trackReg",
    },

    {
      label: "হইতে",
      name: "from",
      options: {
        customBodyRender: (value, tableMeta) => {
          const rowItem = tableMeta.rowData?.item;
          const view = {
            date: rowItem.date,
            user: rowItem.sendFrom,
          };
          return (
            <div>
              <label
                className="text-primary cursor-pointer"
                onClick={() => {
                  setShow(true);
                  setViewData(view);
                }}
              >
                {rowItem?.sendFrom?.name}
              </label>
              <p className="m-0">{rowItem?.sendFrom?.role?.bn_name}</p>
              <p className="m-0">{rowItem?.fromStatus}</p>
            </div>
          );
        },
      },
    },
    {
      label: "প্রতি",
      name: "to",
      options: {
        customBodyRender: (value, tableMeta) => {
          const rowItem = tableMeta.rowData?.item;
          const view = {
            date: rowItem.date,
            user: rowItem.sendTo,
          };
          return (
            <div>
              <label
                className="text-primary cursor-pointer"
                onClick={() => {
                  setShow(true);
                  setViewData(view);
                }}
              >
                {rowItem?.sendTo?.name}
              </label>
              <p className="m-0">{rowItem?.sendTo?.role?.bn_name}</p>
              <p className="m-0">{rowItem?.toStatus}</p>
            </div>
          );
        },
      },
    },
  ];

  const dataTable = data?.data.map((item, i) => ({
    id: item?.id,
    date: localDateFormat(item?.date),
    trackReg: item?.profile?.trackReg,
    item: item,
  }));

  return (
    <div>
      <div className="py-3">
        <IconBreadcrumbs breadcrumbs={breadcrumbsData} />
      </div>
      <div className="slideUp dashboard-layout p-3">
        <div className="pb-3">
          <FileActivityFilter
            setPage={setPage}
            setDate={setDate}
            date={date}
            setSendTo={setSendTo}
            sendTo={sendTo}
            setSendFrom={setSendFrom}
            sendFrom={sendFrom}
          />
        </div>

        <div>
          <ReusableTable
            title={`কর্মসূচী - ${
              !date
                ? "সর্বশেষ ১৫ দিনের তথ্য"
                : localDateFormat(date) + " তারিখের তথ্য"
            }`}
            columns={columns}
            data={dataTable}
            isLoading={isLoading}
            currentPage={page}
            lastPage={lastPage}
            totalData={totalData}
            onPageChange={handlePageChange}
          />
        </div>
      </div>
      <ActivityHistoryCount
        show={show}
        data={viewData}
        onHide={() => {
          setShow(false);
          setViewData("");
        }}
      />
    </div>
  );
};

export default FileActivity;
