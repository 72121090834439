import { apiSlice } from "./apiSlice";

const dashboardApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getDashboardApplicationReport: builder.query({
      query: ({ filter, date }) => ({
        url: "/api/admin/dashboard/application-count-group-category",
        params: { filter, date },
      }),
    }),
    getCurrentActivities: builder.query({
      query: () => "/api/admin/dashboard/current-activities-group-role",
    }),
    // /admin/dashboard/certificate-endorse-count-group-category
    getCertificateEndorseCountGroupCategory: builder.query({
      query: () =>
        "/api/admin/dashboard/certificate-endorse-count-group-category",
    }),
    // /admin/dashboard/statistics-at-a-glance
    getStatisticsAtAGlance: builder.query({
      query: () => "/api/admin/dashboard/statistics-at-a-glance",
    }),
  }),
});

export const {
  useGetDashboardApplicationReportQuery,
  useGetCurrentActivitiesQuery,
  useGetCertificateEndorseCountGroupCategoryQuery,
  useGetStatisticsAtAGlanceQuery,
} = dashboardApiSlice;
