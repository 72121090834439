import React from "react";
import CommonDetails from "./CommonDetails";
import { useParams } from "react-router-dom";
import { useGetSubmittedCopyrightDetailsQuery } from "../../../../redux/api/submittedCopyrightAgreementApiSlice";
import Loader from "../../../../components/custom/Loader";
import {
  CopyrightGenerateAttachArray,
  CopyrightGenerateValue,
} from "../../../../utils/CopyrightGenerateAttachArray";
import CopyrightPreviewFormTwo from "../../../../components/preview_applications/copyright/CopyrightPreviewFormTwo";
import CopyrightPreviewFormOne from "../../../../components/preview_applications/copyright/CopyrightPreviewFormOne";
import InspectorCommonContent from "../../../../components/common-content/InspectorCommonContent";
import { useAuth } from "../../../../utils/authorization";
import { transformCopyrightData } from "../../../../utils/transformCopyrightData";
import PaymentPreview from "../../../../components/preview_applications/payment/PaymentPreview";

const CopyrightDetailsSubmitted = () => {
  const { authUser } = useAuth();
  const { id } = useParams();
  const { data, isLoading } = useGetSubmittedCopyrightDetailsQuery(id, {
    refetchOnMountOrArgChange: true,
  });
  const details = data?.data?.profileDetail;

  const forInfo = transformCopyrightData(data);

  if (isLoading) {
    return <Loader />;
  }
  const infoTable = CopyrightGenerateAttachArray(forInfo);
  const infoModal = CopyrightGenerateValue(forInfo);

  const info = {
    fileStatus: Number(data?.data?.fileStatus),
    noteNumber: data?.data?.trackReg,
    regNumber: "",
    applicantName: details?.profileDetailType?.[0]?.name,
    workTitle: details?.workTitle,
    category: details?.category?.name,
    amount: data?.data?.amount,
    challan: Number(data?.data?.st) === 3 ? "চালান" : "ট্রেজারি",
    challanNumber:
      Number(data?.data?.st) === 3
        ? data?.data?.echallanId
        : data?.data?.treasuryNo,
    treasuryDate: data?.data?.treasuryDate || new Date(),
    admin: data?.data?.admin,
  };

  const formOne = (
    <div className="pt-5">
      <CopyrightPreviewFormOne data={data?.data} />
    </div>
  );
  const formTwo = (
    <div className="pt-5">
      <CopyrightPreviewFormTwo data={details} />
    </div>
  );

  const comments =
    authUser?.role_id === 8
      ? data?.data?.comments.find((c) => Number(c.submit) === 2)
      : data?.data?.comments.length > 0 && data?.data?.comments[0];

  const noteSheetList =
    data?.data?.comments.filter((c) => Number(c.submit) === 1) ?? [];

  const content = (
    <InspectorCommonContent
      comments={comments}
      info={info}
      infoTable={infoTable}
      infoModal={infoModal}
    />
  );
  const commentsNoteSheet = {
    noteSheetList,
    noteSheetValues: comments?.status,
  };

  return (
    <div>
      <CommonDetails
        content={content}
        commentsNoteSheet={commentsNoteSheet}
        formOne={formOne}
        formTwo={formTwo}
        payment={
          <div className="p-4 mt-5">
            <PaymentPreview data={data?.data} />
          </div>
        }
        info={info}
        infoTable={infoTable}
        breadcrumbsTitle={"সাম্প্রতিক নথি"}
      />
    </div>
  );
};

export default CopyrightDetailsSubmitted;
