// utils.js

const numberToBanglaBenjonBornoMap = {
    1: 'ক', 2: 'খ', 3: 'গ', 4: 'ঘ', 5: 'ঙ',
    6: 'চ', 7: 'ছ', 8: 'জ', 9: 'ঝ', 10: 'ঞ',
    11: 'ট', 12: 'ঠ', 13: 'ড', 14: 'ঢ', 15: 'ণ',
    16: 'ত', 17: 'থ', 18: 'দ', 19: 'ধ', 20: 'ন',
    21: 'প', 22: 'ফ', 23: 'ব', 24: 'ভ', 25: 'ম',
    26: 'য', 27: 'র', 28: 'ল', 29: 'শ', 30: 'ষ',
    31: 'স', 32: 'হ', 33: 'ড়', 34: 'ঢ়', 35: 'য়'
  };
  
  export const convertToBanglaBenjonBorno = (number) => {
    return numberToBanglaBenjonBornoMap[number] || number;
  };
  