import React from "react";
import { useLocation, useParams } from "react-router-dom";

const PaymentStatus = () => {
  const { status } = useParams();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const transId = query.get("transId");

  return <div>{status},{ transId}</div>;
};

export default PaymentStatus;
