import React from "react";
import CertificateFile from "../../../../components/certificate-endorse-file/CertificateFile";

const CertificateCopyright = () => {
  const fileStatus = 8;
  const applicationPath = {
    copyright: "/admin/certificates-copyright",
    agreement: "/admin/endorse-agreement",
  };
  const detailsRoute = "/admin/print-copyright-certificate";
  const pageTitle = "সার্টিফিকেট";
  return (
    <CertificateFile
      fileStatus={fileStatus}
      applicationPath={applicationPath}
      detailsRoute={detailsRoute}
      pageTitle={pageTitle}
    />
  );
};

export default CertificateCopyright;
