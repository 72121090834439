import React from "react";
import SubmittedAgreement from "../sumitted_agreement/SubmittedAgreement";
import { useAuthorized } from "../../../../hooks/useAuthorized";
import Loader from "../../../../components/custom/Loader";
import { accessRoles } from "../../../../utils/accessRoles";

const HearingScheduleAgreement = () => {
  const { hasRole, isLoading } = useAuthorized();
  if (isLoading) {
    return <Loader />;
  }
  const pageTitle = hasRole([
    accessRoles.Examiner,
    accessRoles.AssistantExaminer,
    accessRoles.Indexer,
  ])
    ? "শুনানী গ্রহনের নথি"
    : "চিঠিসহ শুনানী গ্রহনের নথি";
  const fileStatus = 23;
  const applicationPath = {
    copyright: "/admin/hearing-schedule-copyright",
    agreement: "/admin/hearing-schedule-agreement",
  };
  const detailsRoute = hasRole([
    accessRoles.Examiner,
    accessRoles.AssistantExaminer,
    accessRoles.Indexer,
  ])
    ? "/admin/hearing-schedule-agreement-details"
    : "/admin/submitted-agreement-details-verify";

  return (
    <SubmittedAgreement
      fileStatus={fileStatus}
      applicationPath={applicationPath}
      detailsRoute={detailsRoute}
      pageTitle={pageTitle}
    />
  );
};

export default HearingScheduleAgreement;
