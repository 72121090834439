import { useFormik } from "formik";
import React, { useEffect } from "react";
import { Button, Form } from "react-bootstrap";
import toast from "react-hot-toast";
import { useOriginalityTestMutation } from "../../../../../redux/api/submittedCopyrightAgreementApiSlice";
import { BeatLoader } from "react-spinners";
import * as yup from "yup";
import { Search } from "@mui/icons-material";

const OriginalityTest = ({ info, setOriginalityData }) => {
  const [originalityTest, { isLoading, isError, isSuccess, error, data }] =
    useOriginalityTestMutation();

  const formik = useFormik({
    initialValues: {
      id: "",
      workTitle: "",
      category_id: "",
      subCategory: "",
    },
    validationSchema: yup.object({
      id: yup.number().required("আইডি প্রযোজ্য"),
      workTitle: yup.string().required("কাজের শিরোনাম প্রযোজ্য"),
      category_id: yup.number().required("শ্রেণী নির্বাচন করুন"),
      subCategory: yup.number().required("উপশ্রেণী নির্বাচন করুন"),
    }),
    onSubmit: (values) => {
      originalityTest(values);
    },
  });

  useEffect(() => {
    if (info) {
      formik.setValues({
        id: info.id,
        workTitle: info.workTitle,
        category_id: info?.categoryData
          ? info?.categoryData?.id
          : info.category?.id,
        subCategory: info.subCategory?.id,
      });
    }
  }, [info]);

  useEffect(() => {
    if (isSuccess) {
      setOriginalityData(data.data);
    }
    if (isError) {
      toast.error(error?.message);
    }
  }, [isSuccess, isError]);

  return (
    <>
      <div className="card h-100">
        <div className="bg-slate p-2 fw-semibold ">
          {info?.apptype} মৌলিকত্ব পরীক্ষা
        </div>
        <div className="pt-3 p-3">
          <div className="row">
            <div className="col-md-12">
              <label className="fw-semibold">
                {info?.apptype} শিরোনাম লিখুন
              </label>
              <Form.Control
                name="workTitle"
                placeholder="শিরোনাম"
                aria-label="Username"
                aria-describedby="basic-addon1"
                value={formik.values.workTitle}
                onChange={formik.handleChange}
                as={"textarea"}
                rows={4}
                isInvalid={
                  formik.touched.workTitle && !!formik.errors.workTitle
                }
                readOnly
              />
              {formik.touched.workTitle && formik.errors.workTitle ? (
                <div className="text-danger">{formik.errors.workTitle}</div>
              ) : null}
            </div>
            <div className="col-md-6 mt-3">
              <label className="fw-semibold"> {info?.apptype} শ্রেণি</label>
              <Form.Control
                placeholder="শ্রেণি"
                aria-label="Username"
                aria-describedby="basic-addon1"
                value={
                  info?.categoryData
                    ? info?.categoryData?.name
                    : info.category?.name
                }
                readOnly
              />
            </div>
            <div className="col-md-6 mt-3">
              <label className="fw-semibold"> {info?.apptype} উপশ্রেণি</label>
              <Form.Control
                placeholder="উপশ্রেণি"
                aria-label="Username"
                aria-describedby="basic-addon1"
                value={info.subCategory?.name}
                readOnly
              />
            </div>
            <div className="col-md-12 mt-4">
              <div className="text-center">
                <Button
                  onClick={formik.handleSubmit}
                  disabled={isLoading}
                  className="px-4"
                  variant="success"
                >
                  {isLoading ? (
                    <BeatLoader color="#fff" />
                  ) : (
                    <>
                      <Search /> খুঁজুন
                    </>
                  )}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default OriginalityTest;
