import React from "react";
import SubmittedCopyright from "../sumitted_copyright/SubmittedCopyright";

const FinalApprovedCopyright = () => {
  const pageTitle = "চূড়ান্ত ভাবে অনুমোদিত আবেদন পত্র";
  const fileStatus = 5;
  const applicationPath = {
    copyright: "/admin/final-approved-copyright",
    agreement: "/admin/final-approved-agreement",
  };
  const detailsRoute = "/admin/final-approved-copyright-certificate-form";

  return (
    <SubmittedCopyright
      fileStatus={fileStatus}
      applicationPath={applicationPath}
      detailsRoute={detailsRoute}
      pageTitle={pageTitle}
    />
  );
};

export default FinalApprovedCopyright;
