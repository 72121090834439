import React from "react";
import { Modal } from "react-bootstrap";
import ErrorPage from "../../../../components/custom/status/ErrorPage";
import SuccessPage from "../../../../components/custom/status/SuccessPage";
import FailedPage from "../../../../components/custom/status/FailedPage";

const StatusModal = ({url, status, showModal }) => {
  return (
    <Modal
      show={showModal}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body>
        {status === "Complete" ? (
          <SuccessPage url={url} />
        ) : status === "Canceled" ? (
          <ErrorPage />
        ) : (
          <FailedPage />
        )}
      </Modal.Body>
    </Modal>
  );
};

export default StatusModal;
