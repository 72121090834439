import { Feed, Home, Search } from "@mui/icons-material";
import { Tooltip } from "@mui/material";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { Button, Form, Tab, Table, Tabs } from "react-bootstrap";
import toast from "react-hot-toast";
import { FaMinus, FaPlus } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { BeatLoader } from "react-spinners";
import Swal from "sweetalert2";
import IconBreadcrumbs from "../../../../components/custom/IconBreadcrumbs";
import {
  useInitialVerifyMutation,
  useVerifyEchalanMutation,
} from "../../../../redux/api/submittedCopyrightAgreementApiSlice";
import { localDateFormat } from "../../../../utils/dateFormat";
import BanglaCalender from "../../../frontend/userPanel/form_two/BanglaCalender";
import TreasuryChalanVerify from "./TreasuryChalanVerify/TreasuryChalanVerify";
import OriginalityTest from "./originality_test/OriginalityTest";
import OriginalityTestCopyright from "./originality_test/OriginalityTestCopyright";

const CommonDetailsIndexer = ({
  formOne,
  formTwo,
  payment,
  info,
  url,
  treasuryChalanVerify,
}) => {
  const [initialVerify, setInitialVerify] = useState({
    treasuryStatus: 0,
    basicStatus: 0,
  });
  const [showForm, setShowForm] = useState(false);
  const [originalityData, setOriginalityData] = useState([]);
  const navigate = useNavigate();
  const breadcrumbsData = [
    { icon: Home, title: "হোম", url: "/" },
    { icon: Feed, title: "আবেদন পত্র", active: true },
  ];

  const [
    initialVerifyIndexer,
    {
      isLoading: isLoadingInitialVerify,
      isError: isErrorInitialVerify,
      error: errorInitialVerify,
      data: dataInitialVerify,
      isSuccess: isSuccessInitialVerify,
    },
  ] = useInitialVerifyMutation();

  const handleNextStep = () => {
    if (info?.id) {
      Swal.fire({
        // title: "আপনি কি নিশ্চিত?",
        text: "আপনি কি পরবর্তী ধাপে যেতে চান?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "না",
        confirmButtonText: "হ্যাঁ",
      }).then((result) => {
        if (result.isConfirmed) {
          initialVerifyIndexer({ formData: initialVerify, id: info?.id });
        }
      });
    }
  };

  const [echalanVerify, { isLoading, isError, isSuccess, error, data }] =
    useVerifyEchalanMutation();
  const formik = useFormik({
    initialValues: {
      challanid: "",
      treasuryDate: "",
    },

    onSubmit: (values) => {
      echalanVerify(values);
    },
  });

  useEffect(() => {
    if (info) {
      formik.setValues({
        challanid: info.challanid,
        treasuryDate: info.treasuryDate,
      });
    }
  }, [info]);

  useEffect(() => {
    if (isSuccess) {
      toast.success(data.message);
    }
    if (isError) {
      toast.error(error?.message);
    }
    if (isErrorInitialVerify) {
      toast.error(errorInitialVerify?.message);
    }
    if (isSuccessInitialVerify) {
      setInitialVerify((prev) => ({
        ...prev,
        treasuryStatus: 0,
        basicStatus: 0,
      }));
      navigate(url);
      console.log(dataInitialVerify);
    }
  }, [isSuccess, isError, isErrorInitialVerify, isSuccessInitialVerify]);

  const handleSwitchChange = (e, key) => {
    setInitialVerify((prev) => ({
      ...prev,
      [key]: e.target.checked ? 1 : 0,
    }));
  };

  useEffect(() => {
    if (info?.basicStatus || info.treasuryStatus) {
      setInitialVerify((prev) => ({
        ...prev,
        treasuryStatus: info.treasuryStatus && info.treasuryStatus,
        basicStatus: info.basicStatus && info.basicStatus,
      }));
    } else {
      setInitialVerify((prev) => ({
        ...prev,
        treasuryStatus: 0,
        basicStatus: 0,
      }));
    }
  }, [info]);

  return (
    <div>
      <div>
        <div className="py-3">
          <IconBreadcrumbs breadcrumbs={breadcrumbsData} />
        </div>
        <div className="slideUp dashboard-layout p-3">
          <div className="row">
            <div className="col-md-12 mb-4">
              <div className="fw-semibold d-flex justify-content-between align-items-center">
                <span className="rounded border p-2">
                  আবেদন নং | {info?.noteNumber}
                </span>
                <div className="d-flex justify-content-between align-items-center gap-2">
                  <Tooltip
                    title={
                      showForm ? "আবেদনপত্র বন্ধ করুন" : "আবেদন পত্র দেখুন"
                    }
                  >
                    <Button
                      variant={showForm ? "outline-danger" : "outline-success"}
                      size="sm"
                      onClick={() => setShowForm(!showForm)}
                      className="px-4"
                    >
                      {showForm ? <FaMinus /> : <FaPlus />}
                    </Button>
                  </Tooltip>
                </div>
              </div>
            </div>
            {!showForm ? (
              <>
                {info.st === 3 && (
                  <div className="col-md-12 mb-5">
                    <div className="bg-slate p-2 fw-semibold ">
                      ই-চালান যাচাই
                    </div>
                    <div className="pt-3">
                      <div className="row">
                        <div className="col-md-4">
                          <label className="fw-semibold">চালান নং</label>
                          <Form.Control
                            name="challanid"
                            placeholder="চালান নং"
                            aria-label="Username"
                            aria-describedby="basic-addon1"
                            value={formik.values.challanid}
                            onChange={formik.handleChange}
                          />
                        </div>
                        <div className="col-md-4">
                          <label className="fw-semibold">তারিখ</label>
                          <BanglaCalender
                            onChange={(e) => {
                              console.log(e);
                              formik.setFieldValue("treasuryDate", e);
                            }}
                            value={formik.values.treasuryDate}
                            placeholder={"তারিখ"}
                          />
                        </div>
                        <div className="col-md-4 mt-4">
                          <div className="">
                            <Button
                              onClick={formik.handleSubmit}
                              disabled={isLoading}
                              className="px-4"
                              variant="success"
                            >
                              {isLoading ? (
                                <BeatLoader color="#fff" />
                              ) : (
                                <>
                                  <Search /> খুঁজুন
                                </>
                              )}
                            </Button>
                          </div>
                        </div>
                        {data?.data?.name && (
                          <div className="col-md-12">
                            <div className="mt-4">
                              <Table bordered hover>
                                <thead>
                                  <tr>
                                    <th className="light-gray">
                                      প্রদায়কের নাম
                                    </th>
                                    <th className="light-gray">ই-চালান</th>
                                    <th className="light-gray">পেমেন্ট আইডি</th>
                                    <th className="light-gray">টাকার পরিমাণ</th>
                                    <th className="light-gray">অবস্থা</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr>
                                    <td>{data?.data?.name}</td>
                                    <td>{data?.data?.echallanId}</td>
                                    <td>{data?.data?.paymentId}</td>

                                    <td>{data?.data?.amount}</td>
                                    <td>{data?.data?.status}</td>
                                  </tr>
                                </tbody>
                              </Table>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                )}
                {info.st === 2 && (
                  <>
                    <div className="col-md-6 mb-4 ">
                      <div className="card h-100">
                        <div className="bg-slate p-2 fw-semibold ">
                          অনলাইনে ট্রেজারি চালান যাচাই
                        </div>
                        <div className="p-1 mt-2">
                          <iframe
                            id="blockrandom"
                            name="iframe"
                            src="http://103.48.16.132/echalan/echalan_iframe.php"
                            scrolling="auto"
                            class="wrapper"
                            width="100%"
                            height="281"
                            align="top"
                            frameborder="0"
                          >
                            This option will not work correctly. Unfortunately,
                            your browser does not support Inline Frames
                          </iframe>
                        </div>
                      </div>
                    </div>

                    <TreasuryChalanVerify
                      info={info}
                      treasuryChalanVerify={treasuryChalanVerify}
                    />
                  </>
                )}

                {info.appType !== 1 ? (
                  <div className="col-md-6 mb-4">
                    <OriginalityTest
                      info={info}
                      setOriginalityData={setOriginalityData}
                    />
                  </div>
                ) : (
                  <div className="col-md-6 mb-4">
                    <OriginalityTestCopyright
                      info={info}
                      setOriginalityData={setOriginalityData}
                    />
                  </div>
                )}

                <div className="col-md-6 mb-4 ">
                  <div className="card h-100">
                    <div className="bg-slate p-2 fw-semibold ">
                      যাচাইকৃত তথ্য
                    </div>
                    <div className="d-flex align-items-center gap-3 mt-4">
                      <div className="mx-3">
                        <div>১। চালান চেক</div>
                        <div className="mt-3">২। কর্মের মৌলিকত্ব আছে কি ?</div>
                      </div>
                      <div>
                        <div>
                          <Tooltip
                            title={
                              initialVerify.treasuryStatus === 1
                                ? "সঠিক নয়"
                                : "সঠিক"
                            }
                            placement="right"
                          >
                            <Form.Check
                              type="switch"
                              id={`custom-switch${initialVerify.treasuryStatus}`}
                              value={initialVerify.treasuryStatus}
                              className="text-success"
                              isValid
                              checked={initialVerify.treasuryStatus === 1}
                              onChange={(e) =>
                                handleSwitchChange(e, "treasuryStatus")
                              }
                            />
                          </Tooltip>
                        </div>
                        <div className="mt-3">
                          <Tooltip
                            title={
                              initialVerify.basicStatus === 1 ? "না" : "হ্যাঁ"
                            }
                            placement="right"
                          >
                            <Form.Check
                              type="switch"
                              id={`custom-switch${initialVerify.basicStatus}`}
                              value={initialVerify.basicStatus}
                              className="text-success"
                              isValid
                              checked={initialVerify.basicStatus === 1}
                              onChange={(e) =>
                                handleSwitchChange(e, "basicStatus")
                              }
                            />
                          </Tooltip>
                        </div>
                      </div>
                    </div>
                    {/* <hr /> */}
                    <div className="mt-4 mx-3">
                      <Button
                        onClick={handleNextStep}
                        disabled={isLoadingInitialVerify}
                        className="px-4"
                        variant="success"
                      >
                        {isLoadingInitialVerify ? (
                          <BeatLoader color="#fff" />
                        ) : (
                          <>পরবর্তী ধাপ</>
                        )}
                      </Button>
                    </div>
                  </div>
                </div>

                {/* OriginalityTest  */}
                <div className="col-md-12">
                  {originalityData.length > 0 ? (
                    <div>
                      <div className="bg-slate p-2 my-1 fw-semibold ">
                        মৌলিকত্বের যাচাইকৃত তথ্য
                      </div>
                      <Table bordered hover>
                        <thead>
                          <tr>
                            <th className="light-gray">আবেদনপত্র নং</th>
                            <th className="light-gray">আবেদনকারীর নাম</th>
                            <th className="light-gray">শিরোনাম</th>
                            <th className="light-gray">শ্রেণী</th>
                            <th className="light-gray">উপশ্রেণী</th>
                            <th className="light-gray">আবেদনের তারিখ</th>
                          </tr>
                        </thead>
                        <tbody>
                          {originalityData.map((item) => (
                            <tr key={item.id}>
                              <td>{item.trackReg}</td>
                              <td>{item.puname ?? item.applicantName}</td>
                              <td>
                                {item.document?.workTitle ??
                                  item.profileDetail?.workTitle}
                              </td>
                              <td>
                                {item.document?.category?.name ??
                                  item.profileDetail?.category?.name}
                              </td>
                              <td>
                                {item.document?.subCategory?.name ??
                                  item.profileDetail?.subCategory?.name}
                              </td>
                              <td>
                                {localDateFormat(
                                  item?.treasuryDate || item?.sendDate
                                )}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </>
            ) : (
              <div className="col-12">
                <Tabs
                  defaultActiveKey="step1"
                  id="uncontrolled-tab-example"
                  fill
                >
                  <Tab eventKey="step1" title="আবেদন পত্র - ১ম অংশ">
                    <div className="border border-top-0 mb-4">{formOne}</div>
                  </Tab>
                  <Tab eventKey="step2" title="আবেদন ফরম - ২য় অংশ">
                    <div className="border border-top-0 mb-4">{formTwo}</div>
                  </Tab>
                  <Tab eventKey="step3" title="পেমেন্ট">
                    <div className="border border-top-0 mb-4">{payment}</div>
                  </Tab>
                </Tabs>
              </div>
            )}
          </div>
        </div>
        <div style={{ height: "30vh" }}></div>
      </div>
      {/* <ShowApplication
        infoModal={modalData}
        onHide={() => setOpenModal(false)}
        show={openModal}
      /> */}
    </div>
  );
};

export default CommonDetailsIndexer;
