import { BANK_BRANCH } from "../constants";
import { apiSlice } from "./apiSlice";

const bankBranchApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getBankBranchesList: builder.query({
      query: ({ name = "", page = 1, bank_id }) => {
        let url = `${BANK_BRANCH}?per_page=15`;
        if (name) {
          url += `&name=${name}`;
        }
        if (bank_id) {
          url += `&bank_id=${bank_id}`;
        }
        if (page) {
          url += `&page=${page}`;
        }
        return url;
      },
      providesTags: "bankBranches",
    }),

    createBankBranches: builder.mutation({
      query: ({formData}) => ({
        url: BANK_BRANCH,
        method: "POST",
        body: formData,
      }),
      invalidatesTags: ["bankBranches"],
    }),

    updateBankBranches: builder.mutation({
      query: ({ formData, id }) => ({
        url: `${BANK_BRANCH}/${id}`,
        method: "POST",
        body: formData,
      }),
      invalidatesTags: ["bankBranches"],
    }),
  }),
});

export const {
  useGetBankBranchesListQuery,
  useCreateBankBranchesMutation,
  useUpdateBankBranchesMutation,
} = bankBranchApiSlice;
