import { useFormik } from "formik";
import JoditEditor from "jodit-react";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import toast from "react-hot-toast";
import { Link, useNavigate } from "react-router-dom";
import { BeatLoader } from "react-spinners";
import Swal from "sweetalert2";
import * as Yup from "yup";
import { assets } from "../../assets";
import { useAuthorized } from "../../hooks/useAuthorized";
import BanglaCalender from "../../pages/frontend/userPanel/form_two/BanglaCalender";
import { useSendEmailForCorrectionMutation } from "../../redux/api/backendUtilsApiSlice";
import { config } from "../../utils/config";
import {
  globalDateFormat,
  localDateFormatName,
  localDateFormatNameBN,
} from "../../utils/dateFormat";
import { transformErrorsToObjectStructure } from "../../utils/transformErrorsToObjectStructure";

const EmailForCorrection = ({ open, onClose, info }) => {
  const [applicationParts, setApplicationParts] = useState([]);
  const [isEdit, setIsEdit] = useState(true);
  const { user } = useAuthorized();
  const navigate = useNavigate();
  const editor = useRef(null);
  const printLetter = useRef();

  const [
    sendEmail,
    {
      isLoading,
      isSuccess: isSuccessSubmit,
      error: errorSubmit,
      isError: isErrorSubmit,
      data: dataSubmit,
    },
  ] = useSendEmailForCorrectionMutation();

  const validationSchema = Yup.object().shape({
    record_no: Yup.string().required("রেকর্ড নম্বর প্রযোজ্য।"),
    record_date: Yup.date().required("রেকর্ড তারিখ প্রযোজ্য।"),
    subject: Yup.string().required("বিষয় প্রযোজ্য।"),
    comment: Yup.string().required("মন্তব্য প্রযোজ্য।"),
    message: Yup.string().required("মন্তব্য প্রযোজ্য।"),
    user_id: Yup.number().required("প্রাপক প্রযোজ্য।"),
    applicationParts: Yup.array().min(
      1,
      "কমপক্ষে একটি চেকবক্স নির্বাচন করতে হবে।"
    ),
  });

  const pageAleart = "আপনি কি ই-মেইল প্রেরণ করতে চান?";

  const sub = info?.workTitle
    ? `${info.workTitle} নামক ${info.catName} কর্মের কপিরাইট রেজিস্ট্রেশন সংশোধন ।`
    : "";

  const formik = useFormik({
    initialValues: {
      record_no: "",
      record_date: globalDateFormat(new Date()),
      subject: sub || "",
      message: "",
      user_id: info?.admin?.id,
      comment: "",
      applicationParts: [],
    },
    validationSchema,
    onSubmit: (values) => {
      const formData = values;
      Swal.fire({
        text: pageAleart,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "না",
        confirmButtonText: "হ্যাঁ",
      }).then((result) => {
        if (result.isConfirmed) {
          sendEmail({ id: info?.id, formData });
        }
      });
    },
    enableReinitialize: true,
  });
  console.log(formik.errors);
  console.log(formik.values);

  useEffect(() => {
    if (isSuccessSubmit) {
      toast.success(dataSubmit?.message);
      formik.resetForm({ values: { ...formik.values, applicationParts: [] } });
      onClose();
    }
    if (isErrorSubmit) {
      toast.error(errorSubmit?.data?.message);
      formik.setErrors(
        transformErrorsToObjectStructure(errorSubmit.data?.data || {})
      );
    }
    // eslint-disable-next-line
  }, [isErrorSubmit, isSuccessSubmit]);

  const handleEditorChange = useCallback((newContent) => {
    formik.setFieldValue("message", newContent);
  }, []);

  const applicationPart =
    info?.st === 2
      ? [
          { label: "১ম অংশ", value: 1 },
          { label: "২য় অংশ", value: 2 },
          { label: "পেমেন্ট ( ট্রেজারি )", value: 3 },
        ]
      : [
          { label: "১ম অংশ", value: 1 },
          { label: "২য় অংশ", value: 2 },
        ];

  const handleChangeCheck = (e) => {
    const value = parseInt(e.target.value);
    const isChecked = e.target.checked;

    setApplicationParts((prev) => {
      if (value === 4) {
        // If "select all" is checked/unchecked
        if (isChecked) {
          // If selecting all, include all parts
          return applicationPart.map((item) => item.value);
        } else {
          // If deselecting all, return empty array
          return [];
        }
      } else {
        // For individual checkboxes
        if (isChecked) {
          // Add individual part if checked
          return [...prev, value];
        } else {
          // Remove individual part if unchecked
          return prev.filter((item) => item !== value);
        }
      }
    });
  };

  useEffect(() => {
    formik.setFieldValue("applicationParts", []);
    formik.setFieldValue("applicationParts", applicationParts);
  }, [applicationParts]);

  return (
    <Modal show={open} onHide={onClose} size="lg">
      <Modal.Header closeButton>
        <Modal.Title className="fs-5">সংশোধনের ই-মেইল প্রেরণ</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <>
          <div>
            <div className="py-" ref={printLetter}>
              <div className="card p-3">
                <div ref={printLetter}>
                  <div className="text-center">
                    <img
                      className="my-3"
                      src={assets.logoBlack}
                      style={{ width: "350px" }}
                      alt=""
                    />
                    <p className="m-0">
                      কপিরাইট অফিস, সংস্কৃতি বিষয়ক মন্ত্রণালয়
                    </p>

                    <p className="m-0">
                      জাতীয় গ্রন্থাগার ভবন, আগারগাঁও, শেরেবাংলা নগর, ঢাকা।
                    </p>

                    <p className="m-0">
                      ওয়েবসাইট :{" "}
                      <Link target="_blank" to={"//www.copyrightoffice.gov.bd"}>
                        www.copyrightoffice.gov.bd
                      </Link>
                    </p>
                  </div>
                  <div
                    className={`d-flex justify-content-between py-3 mt-2 align-items-center`}
                  >
                    <div
                      className={`d-flex gap-2 ${
                        !isEdit && "align-items-center "
                      }`}
                    >
                      {!isEdit ? (
                        <> {formik.values.record_no}</>
                      ) : (
                        <span>
                          {" "}
                          <Form.Control
                            placeholder="নথি / স্বারক / রেজিঃ নং "
                            value={formik.values.record_no}
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            errors={formik.errors}
                            touched={formik.touched}
                            name="record_no"
                          />
                          {formik.errors.record_no &&
                            formik.touched.record_no && (
                              <span className="text-danger">
                                {formik.errors.record_no}
                              </span>
                            )}
                        </span>
                      )}
                    </div>
                    <div
                      className={`d-flex gap-3 ${
                        !isEdit && "align-items-center "
                      }`}
                    >
                      <div
                        className={`d-flex gap-3 ${
                          isEdit && "mt-4"
                        } fw-semibold`}
                      >
                        তারিখঃ
                      </div>
                      <div>
                        {!isEdit ? (
                          <>
                            {localDateFormatNameBN(formik.values.record_date)}
                            <hr className="m-0" />
                            {localDateFormatName(formik.values.record_date)}
                          </>
                        ) : (
                          <>
                            {localDateFormatNameBN(formik.values.record_date)}
                            <BanglaCalender
                              placeholder={"তারিখ"}
                              value={formik.values.record_date}
                              onChange={(val) => {
                                formik.setFieldValue(
                                  "record_date",
                                  globalDateFormat(val)
                                );
                              }}
                            />
                            {formik.errors?.record_date ? (
                              <span className="text-danger">
                                {formik.errors.record_date}
                              </span>
                            ) : (
                              <div style={{ height: "24px" }}></div>
                            )}
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                  <div>
                    <p className={`fw-semibold mt-4 w-100 d-flex gap-2`}>
                      <span className={`${isEdit && "mt-2"} `}>বিষয়ঃ </span>
                      {!isEdit ? (
                        <> {formik.values.subject}</>
                      ) : (
                        <div className="w-100">
                          {" "}
                          <Form.Control
                            value={formik.values.subject}
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            errors={formik.errors}
                            touched={formik.touched}
                            name="subject"
                          />
                          {formik.errors.subject && formik.touched.subject && (
                            <span className="text-danger">
                              {formik.errors.subject}
                            </span>
                          )}
                        </div>
                      )}
                    </p>
                  </div>
                  <p className="pb-4" style={{ textAlign: "justify" }}>
                    {!isEdit ? (
                      <p
                        className="m-0"
                        dangerouslySetInnerHTML={{
                          __html: formik.values.message,
                        }}
                      ></p>
                    ) : (
                      <>
                        <JoditEditor
                          ref={editor}
                          value={formik.values.message}
                          config={config}
                          tabIndex={1}
                          onBlur={handleEditorChange}
                        />
                        {formik.errors.message ? (
                          <span className="text-danger">
                            {formik.errors.message}
                          </span>
                        ) : (
                          <div style={{ height: "24px" }}></div>
                        )}
                      </>
                    )}
                  </p>
                  <div className="d-flex justify-content-end my-2">
                    <div className="">
                      <div className="mt-3 text-center">
                        {user?.data?.name && (
                          <>
                            {user?.data?.signature && (
                              <>
                                <img
                                  style={{ height: "40px" }}
                                  src={user?.data?.signature}
                                  alt=""
                                />
                              </>
                            )}
                            <p className="m-0">{user?.data?.name}</p>
                            <p className="m-0">{user?.data?.role?.bn_name}</p>
                            <p className="m-0">{user?.data?.email}</p>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="card p-3 mt-3">
              <div className="d-flex align-items-center gap-3 pb-2">
                <label htmlFor="">আবেদন পত্র সংশোধন</label>
              </div>
              <div
                className="pt-1"
                style={{
                  borderTop: "1px dashed #ccc",
                }}
              ></div>
              <div className="d-flex gap-3 pt-2">
                <div>
                  <Form.Check
                    type="checkbox"
                    value={4}
                    label={<span className="cursor-pointer">সবগুলো অংশ</span>}
                    id="disabled-default-সবগুলো"
                    onChange={handleChangeCheck}
                    checked={
                      formik.values.applicationParts.length ===
                      applicationPart.length
                    }
                  />
                </div>
                <div className="border-start px-3">
                  {applicationPart.map((item) => (
                    <Form.Check
                      key={item.value}
                      type="checkbox"
                      value={item.value}
                      label={
                        <span className="cursor-pointer">{item.label}</span>
                      }
                      id={`disabled-default-${item.label}`}
                      onChange={handleChangeCheck}
                      checked={
                        Array.isArray(formik.values.applicationParts) &&
                        formik.values.applicationParts.includes(item.value)
                      }
                    />
                  ))}
                  {Number(info?.st) === 3 && (
                    <Form.Check
                      type="checkbox"
                      label={
                        <span className="cursor-pointer">
                          পেমেন্ট ( ই-চালান )
                        </span>
                      }
                      id={`disabled-default`}
                      onChange={handleChangeCheck}
                      disabled
                    />
                  )}
                </div>
              </div>
            </div>
            {formik.touched.applicationParts &&
            formik.errors.applicationParts ? (
              <div className="text-danger mt-2">
                {formik.errors.applicationParts}
              </div>
            ) : null}
            <div className="mt-3">
              <div className="d-flex align-items-center gap-3 pb-2">
                <label htmlFor="">মন্তব্য</label>
              </div>
              <>
                <Form.Control
                  name="comment"
                  placeholder="এখানে লিখুন"
                  aria-label="Username"
                  aria-describedby="basic-addon1"
                  as={"textarea"}
                  rows={6}
                  value={formik.values.comment}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  isInvalid={formik.touched.comment && formik.errors.comment}
                />
                {formik.touched.comment && formik.errors.comment ? (
                  <div className="text-danger">{formik.errors.comment}</div>
                ) : null}
              </>
            </div>
          </div>
        </>
      </Modal.Body>
      <Modal.Footer>
        <div className="d-flex justify-content-between w-100">
          <Button onClick={onClose}>বন্ধ করুন</Button>
          <div>
            <Button
              onClick={() => setIsEdit(!isEdit)}
              disabled={isLoading}
              className={`mx-3 `}
            >
              {isEdit ? "প্রিভিউ" : "সম্পাদনা করুন"}
            </Button>
            <Button
              variant="success"
              onClick={formik.handleSubmit}
              disabled={isLoading}
            >
              {isLoading ? <BeatLoader /> : "ই-মেইল পাঠান"}
            </Button>
          </div>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default EmailForCorrection;
