import { Copyright, Handshake, RestartAlt } from "@mui/icons-material";
import { Typography } from "@mui/material";
import React, { useEffect } from "react";
import { Badge, Button } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import Select from "react-select";
import BanglaCalender from "../pages/frontend/userPanel/form_two/BanglaCalender";
import { useGetCategoriesQuery } from "../redux/api/categoryApiSlice";
import { useLazyGetSubCategoryCatalogQuery } from "../redux/api/categoryCatalogApiSlice";
import { convertToBanglaNumerals } from "../utils/convertToBanglaNumerals";
import { globalDateFormat } from "../utils/dateFormat";
import Loader from "./custom/Loader";

const SubmittedCommon = ({
  onPreview,
  preview,
  total,
  date,
  setDate,
  category_id,
  setCategoryId,
  subCategory,
  setSubCategory,
  applicationPath,
}) => {
  const location = useLocation();
  // category
  const { data: categoryData, isLoading: isLoadingCategory } =
    useGetCategoriesQuery();
  const [fetchSubCategory, { data: subCategoryData }] =
    useLazyGetSubCategoryCatalogQuery();

  // fetch sub category
  useEffect(() => {
    if (category_id) {
      fetchSubCategory({ parent_id: category_id });
    }
  }, [category_id]);

  if (isLoadingCategory) return <Loader />;

  const categoryOption = categoryData?.data?.map((data) => ({
    value: data.id,
    label: data.name,
  }));

  const subCategoryOption = subCategoryData?.data?.map((data) => ({
    value: data.id,
    label: data.name,
  }));

  const handleDateChange = (d) => {
    setDate(globalDateFormat(d));
  };

  const handleReset = () => {
    setDate("");
    setCategoryId("");
    setSubCategory("");
  };

  return (
    <>
      <div>
        <div
          style={{
            backgroundColor: "rgba(0, 0, 0, 0.1)",
          }}
          className="rounded mb-3 p-2"
        >
          <div className="row gap-2 gap-md-0">
            <div className="col-md-3 m-0">
              <BanglaCalender
                placeholder={"তারিখ"}
                value={date}
                onChange={handleDateChange}
              />
            </div>
            <div className="col-md-3 m-0">
              <Select
                options={categoryOption}
                value={
                  category_id
                    ? categoryOption.find(
                        (option) => option.value === category_id
                      )
                    : { value: "", label: "--- বিভাগ ---", isDisabled: true }
                }
                onChange={(selectedOption) => {
                  setSubCategory("");
                  setCategoryId(selectedOption.value);
                }}
                classNamePrefix="react-select"
              />
            </div>
            <div className="col-md-3 m-0">
              <Select
                options={subCategoryOption}
                value={
                  subCategory
                    ? subCategoryOption.find(
                        (option) => option.value === subCategory
                      )
                    : { value: "", label: "--- উপবিভাগ ---", isDisabled: true }
                }
                onChange={(selectedOption) =>
                  setSubCategory(selectedOption.value)
                }
                classNamePrefix="react-select"
              />
            </div>
            <div className="col-md-3 m-0">
              <div className=" d-flex justify-content-end">
                <Button
                  onClick={handleReset}
                  variant="danger"
                  className="w-100 text-center"
                >
                  <div className="d-flex align-items-center justify-content-center">
                    <RestartAlt /> Reset
                  </div>
                </Button>
              </div>
            </div>
          </div>
        </div>
        <div
          className="d-flex gap-3 mb-3 rounded justify-content-between align-items-center"
          style={{
            backgroundColor: "rgba(0, 0, 0, 0.1)",
          }}
        >
          <div>
            <Link to={applicationPath.copyright}>
              <Typography
                fontWeight="bold"
                className={`${
                  location.pathname === applicationPath.copyright &&
                  "text-dark  bg-success bg-opacity-10"
                } p-2  d-flex align-items-center gap-2`}
              >
                <Copyright /> কপিরাইট{" "}
                <div>
                  <Badge>{convertToBanglaNumerals(total.copyright)}</Badge>
                </div>
              </Typography>
            </Link>
            <Link to={applicationPath.agreement}>
              <Typography
                fontWeight="bold"
                className={`${
                  location.pathname === applicationPath.agreement &&
                  "text-dark  bg-success bg-opacity-10"
                } p-2 d-flex align-items-center gap-2`}
              >
                <Handshake /> চুক্তিপত্র{" "}
                <div>
                  <Badge>{convertToBanglaNumerals(total.agreement)}</Badge>
                </div>
              </Typography>
            </Link>
          </div>
          <div className="mx-1">
            {preview && (
              <Button
                size="sm"
                variant="danger"
                className="w-100 text-center"
                onClick={onPreview}
              >
                বন্ধ করুন
              </Button>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default SubmittedCommon;
