import React from "react";
import SubmittedCopyright from "../sumitted_copyright/SubmittedCopyright";

const ExplanationCopyright = () => {
  const applicationPath = {
    copyright: "/admin/explanation-list-copyright",
    agreement: "/admin/explanation-list-agreement",
  };
  const detailsRoute = "/admin/explanation-copyright-details";
  const pageTitle = "ব্যাখ্যার নথি ";
  return ( 
    <SubmittedCopyright fileStatus={17} applicationPath={applicationPath}  detailsRoute={detailsRoute} pageTitle={pageTitle} />
  );
};

export default ExplanationCopyright;
