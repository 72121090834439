import React from "react";
import { useGetCopyrightCategoriesQuery } from "../../redux/api/submittedCopyrightAgreementApiSlice";
import { Skeleton, Tab, Tabs } from "@mui/material";

const CountCategoryCopyright = ({ category_id, setCategoryId, fileStatus }) => {
  const { data: categories, isLoading: isLoadingCategory } =
    useGetCopyrightCategoriesQuery(fileStatus);
  return (
    <div>
      {" "}
      <div className="bg-slate mb-3">
        {isLoadingCategory || categories?.data.length <= 0 ? (
          <>
            <Skeleton variant="rounded" width={"100%"} height={38} />
          </>
        ) : (
          <div className="slideUp">
            <Tabs
              sx={{ paddingTop: "0px", minHeight: "1px" }}
              variant="scrollable"
              scrollButtons="auto"
              aria-label="scrollable auto tabs example"
            >
              {categories?.data.map((item, i) => (
                <Tab
                  key={i}
                  sx={{
                    textTransform: "capitalize",
                    minHeight: "1px",
                    padding: "8px",
                  }}
                  label={
                    <div
                      style={{
                        color: category_id === item.id && "#0021ff",
                        fontWeight: category_id === item.id && "bold",
                      }}
                    >
                      {item.name}
                      <span
                        className="py-1 px-2 mx-2 rounded"
                        style={{
                          border: "1px solid #575757",
                          color: "#575757",
                          fontSize: "10px",
                        }}
                      >
                        {item.totalFile}
                      </span>
                    </div>
                  }
                  onClick={() => setCategoryId(item?.id)}
                />
              ))}
            </Tabs>
          </div>
        )}
      </div>
    </div>
  );
};

export default CountCategoryCopyright;
