import React, { useEffect, useState } from "react";
import ApplicationsAgreement from "../../../../components/ApplicationsAgreement";
import { Copyright, Home } from "@mui/icons-material";
import Loader from "../../../../components/custom/Loader";
import {
  useGetApplicationCopyrightQuery,
  useGetCopyrightAgreementCountQuery,
  useSendApplicationCopyrightMutation,
} from "../../../../redux/api/applicationCopyrightApiSlice";
import toast from "react-hot-toast";

const ApplicationsDistribution = () => {
  const [date, setDate] = useState("");
  const [trackReg, setTrackReg] = useState("");
  const [category_id, setCategoryId] = useState("");
  const [subCategory, setSubCategory] = useState("");
  const [page, setPage] = useState(1);
  const { data, isLoading } = useGetApplicationCopyrightQuery({
    date,
    trackReg,
    category_id,
    subCategory,
    page,
  });
  const [
    update,
    {
      isLoading: isLoadingUpdate,
      isSuccess: isSuccessUpdate,
      isError: isErrorUpdate,
      data: dataUpdate,
      error: errorUpdate,
    },
  ] = useSendApplicationCopyrightMutation();
  const breadcrumbsData = [
    { icon: Home, title: "হোম", url: "/" },
    { icon: Copyright, title: "কপিরাইট" },
  ];

  const { data: commonCount, refetch } = useGetCopyrightAgreementCountQuery({
    role_id: 8,
    fileStatus: 1,
    appType: 1,
  });

  const handleSubmit = (data) => {
    update(data);
  };

  useEffect(() => {
    if (isErrorUpdate) {
      toast.error(errorUpdate?.data?.message);
    }
    if (isSuccessUpdate) {
      refetch();
      toast.success(dataUpdate?.message);
    }
    // eslint-disabled-next-line
  }, [isErrorUpdate, isSuccessUpdate, errorUpdate, dataUpdate]);

  if (isLoading) {
    return <Loader />;
  }
  return (
    <div>
      <ApplicationsAgreement
        handleSubmit={handleSubmit}
        breadcrumbs={breadcrumbsData}
        data={data?.data}
        isLoadingUpdate={isLoadingUpdate}
        date={date}
        setDate={setDate}
        trackReg={trackReg}
        setTrackReg={setTrackReg}
        category_id={category_id}
        setCategoryId={setCategoryId}
        subCategory={subCategory}
        setSubCategory={setSubCategory}
        commonCount={commonCount?.data}
      />
    </div>
  );
};

export default ApplicationsDistribution;
