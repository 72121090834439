import React from "react";
import SubmittedCopyright from "../../sumitted_copyright/SubmittedCopyright";

const UnauthorizedCopyrightApplication = () => {
  const fileStatus = 29;
  const applicationPath = {
    copyright: "/admin/unauthorized-application-copyright",
    agreement: "/admin/unauthorized-application-agreement",
  };
  const detailsRoute = "/admin/unauthorized-copyright-details";
  const pageTitle = "অননুমোদিত নথি";
  return (
    <SubmittedCopyright
      fileStatus={fileStatus}
      applicationPath={applicationPath}
      detailsRoute={detailsRoute}
      pageTitle={pageTitle}
    />
  );
};

export default UnauthorizedCopyrightApplication;
