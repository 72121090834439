export const transformCopyrightData = (data) => {
  const details = data?.data?.profileDetail;
  const forInfo = {
    st: Number(data?.data?.st),
    category: details?.category?.id,
    selfType: Number(details?.selfType),
    type: Number(details?.type),
    powerType: Number(details?.powerType),
    basicWorktype: Number(details?.basicWorktype),
    authorize: Number(details?.authorize),

    // description
    musicOrFilm: details?.musicOrFilm,
    treasury:
      Number(data?.data.st) === 2
        ? data?.data?.treasury
        : data?.data?.echallanId,
    workSoftCopies:
      details?.workSoftCopies?.map((item) => item.workSoftCopy) || [],
    image: details?.profileDetailType?.map((profile) => profile.image) || [],
    publish: details?.publish?.liter,
    selfTypes: details?.selfTypes?.map((item) => item.noc) || [],
    nid: details?.profileDetailType?.map((profile) => profile.nid) || [],
    tradeLicense:
      details?.profileDetailType?.map((profile) => profile.tradeLicense) || [],
    memorandum:
      details?.profileDetailType?.map((profile) => profile.memorandum) || [],
    tinCertificate:
      details?.profileDetailType?.map((profile) => profile.tinCertificate) ||
      [],
    inst_struct:
      details?.profileDetailType?.map((profile) => profile.inst_struct) || [],
    license_pay: details?.powertypes?.map((item) => item.license_pay) || [],
    approval_sc:
      details?.basicworktypes?.flatMap((item) =>
        item.authorizetypes.map((author) => author.approval_sc)
      ) || [],
  };

  return forInfo;
};
