import {
  APPLICATION_FREES,
  APPLICATION_PAYMENT_PROCESS,
  BANK,
  BANK_BRANCH,
} from "../constants";
import { apiSlice } from "./apiSlice";

const applicationPaymentProcessApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getBank: builder.query({
      query: () => BANK,
    }),
    getBankBranches: builder.query({
      query: ({ bank_id, page }) => {
        let url = `${BANK_BRANCH}?per_page=10`;

        if (bank_id) {
          url += `&bank_id=${bank_id}`;
        }
        if (page) {
          url += `&page=${page}`;
        }
        return url;
      },
    }),

    getInitialFee: builder.query({
      query: (profileId) => APPLICATION_FREES + "/" + profileId,
      keepUnusedDataFor: 1,
    }),
    createApplicationPaymentProcess: builder.mutation({
      query: (formData) => ({
        url: APPLICATION_PAYMENT_PROCESS,
        method: "POST",
        body: formData,
      }),
    }),
  }),
});

export const {
  useGetBankQuery,
  useGetBankBranchesQuery,
  useLazyGetBankBranchesQuery,
  useGetInitialFeeQuery,
  useCreateApplicationPaymentProcessMutation,
} = applicationPaymentProcessApiSlice;
