import React, { useEffect } from "react";
import { useGetFullFormPreviewQuery } from "../../../../redux/api/copyrightApplicationFormTwoApiSlice";
import Loader from "../../../../components/custom/Loader";
import { useParams } from "react-router-dom";
import CopyrightFormPreview from "../../../../components/preview_applications/full-form-preview-copyright/CopyrightFormPreview";

const FullFormPreview = () => {
  const params = useParams();
  const profileId = params?.profileId;

  const {
    data: dataOne,
    isLoading: isLoadingOne,
    refetch: refetchOne,
  } = useGetFullFormPreviewQuery(profileId);

  useEffect(() => {
    if (profileId) {
      refetchOne();
    }
  }, [profileId, refetchOne]);

  if (isLoadingOne) {
    return <Loader />;
  }

  return <CopyrightFormPreview data={dataOne} />;
};

export default FullFormPreview;
