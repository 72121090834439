import { CATEGORY, CATEGORY_SHOW, ROLE_IN, ROLE_NOT_IN } from "../constants";
import { apiSlice } from "./apiSlice";

const categoryApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getCategories: builder.query({
      query: () => CATEGORY + "?per_page=50",
      providesTags: ["categories"],
    }),

    findCategories: builder.query({
      query: (params) => CATEGORY_SHOW + "/" + params,
      invalidatesTags: ["categories"],
    }),

    // role where in and role where not in
    getRoleIn: builder.query({
      query: (query) => ROLE_IN + "?rolesId=" + query,
    }),
    getRoleNotIn: builder.query({
      query: (query) => ROLE_NOT_IN + "?rolesId=" + query,
    }),
  }),
});

export const {
  useGetCategoriesQuery,
  useFindCategoriesQuery,
  useGetRoleInQuery,
  useGetRoleNotInQuery,
} = categoryApiSlice;
