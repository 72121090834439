import React, { useEffect, useState } from "react";
import { useVerifyAssistantExaminerMutation } from "../../redux/api/submittedCopyrightAgreementApiSlice";
import Swal from "sweetalert2";
import { useNavigate, useParams } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Button, Form, Table } from "react-bootstrap";
import toast from "react-hot-toast";
import Select from "react-select";
import { useGetRoleNotInQuery } from "../../redux/api/categoryApiSlice";
import { useLazyGetByRoleQuery } from "../../redux/api/applicationCopyrightApiSlice";
import { CancelRounded, CheckCircleRounded } from "@mui/icons-material";
import { Tooltip } from "@mui/material";
import { localDateFormat, localTimeFormat } from "../../utils/dateFormat";
import { useAuth } from "../../utils/authorization";
import OriginalityTest from "../../pages/admin/submitted_application/indexer/originality_test/OriginalityTest";
import OriginalityTestCopyright from "../../pages/admin/submitted_application/indexer/originality_test/OriginalityTestCopyright";
import ShowLetter from "../ShowLetter";
import { transformErrorsToObjectStructure } from "../../utils/transformErrorsToObjectStructure";

const AssistantExaminerCommonContent = ({ comments, info }) => {
  const [originalityData, setOriginalityData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const { authUser } = useAuth();
  const { id } = useParams();
  const navigate = useNavigate();
  const [religiosityTest, setReligiosityTest] = useState(false);
  const [Verify, { isLoading, isSuccess, error, isError, data: dataSubmit }] =
    useVerifyAssistantExaminerMutation();

  const rolesId = authUser?.role_id === 2 ? "[1,9,10]" : "[1,9,10,12]";

  const { data: roles } = useGetRoleNotInQuery(rolesId);
  const rolesOption =
    roles?.data?.map((data) => ({
      value: data.id,
      label: data.bn_name,
    })) || [];

  const [findUser, { data: users }] = useLazyGetByRoleQuery();

  const usersOption =
    (users?.data &&
      Object.entries(users.data)
        .filter(([key]) => authUser.id !== Number(key))
        .map(([key, value]) => ({
          value: Number(key),
          label: value,
        }))) ||
    [];

  const validationSchema = Yup.object().shape({
    comments: Yup.string().when("submit", {
      is: (val) => val !== 2,
      then: () => Yup.string().required("মন্তব্য প্রযোজ্য।"),
      otherwise: () => Yup.string().nullable(),
    }),
    role_id: Yup.number().when("submit", {
      is: (val) => val !== 2,
      then: () => Yup.number().required("পদবী প্রযোজ্য।"),
      otherwise: () => Yup.number().nullable(),
    }),
    user_id: Yup.number().when("submit", {
      is: (val) => val !== 2,
      then: () => Yup.number().required("প্রাপক প্রযোজ্য।"),
      otherwise: () => Yup.number().nullable(),
    }),
  });

  const formik = useFormik({
    initialValues: {
      submit: 2,
      comments: "",
      basicStatus: "",
      role_id: "",
      user_id: "",
    },
    validationSchema,
    onSubmit: (values) => {
      const formData = values;
      const role_id = authUser?.role_id;
      Swal.fire({
        text: submitText(values.submit),
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "না",
        confirmButtonText: "হ্যাঁ",
      }).then((result) => {
        if (result.isConfirmed) {
          Verify({ id, formData, role_id });
        }
      });
    },
  });

  const handleSwitchChange = (e, key) => {
    formik.setValues((prev) => ({
      ...prev,
      [key]: e.target.checked ? 1 : 0,
    }));
  };

  useEffect(() => {
    if (isSuccess) {
      toast.success(dataSubmit?.message);
      formik.resetForm();
      navigate("/admin/submitted-copyright");
    }
    if (isError) {
      toast.error(error?.data?.message);
      formik.setErrors(
        transformErrorsToObjectStructure(error.data?.data || {})
      );
      if (error?.data?.data) {
        formik.setErrors({
          comments: error.data.data.comments?.[0],
          role_id: error.data.data.role_id?.[0],
          user_id: error.data.data.user_id?.[0],
        });
      }
    }
    // eslint-disable-next-line
  }, [isError, isSuccess]);

  useEffect(() => {
    if (comments) {
      formik.setValues((prev) => ({
        ...prev,
        comments: comments?.comments,
        basicStatus: Number(comments?.basicStatus),
      }));
    }
    setReligiosityTest(Number(comments?.basicStatus) === 1 ? true : false);
  }, [comments]);

  const handleReligiosityTest = () => {
    setReligiosityTest(!religiosityTest);
    formik.setValues((prev) => ({
      ...prev,
      basicStatus: "",
    }));
  };

  const defaultValue = {
    value: "",
    label: "নির্বাচন করুন",
  };

  const controlButton =
    authUser.role_id === 4
      ? [3, 5, 6, 7, 8, 11].includes(formik.values.role_id)
      : authUser.role_id === 6
      ? [7, 8].includes(formik.values.role_id)
      : authUser.role_id === 5
      ? [6, 7, 8].includes(formik.values.role_id)
      : authUser.role_id === 11
      ? [5, 6, 7, 8].includes(formik.values.role_id)
      : authUser.role_id === 3
      ? [5, 6, 7, 8, 11].includes(formik.values.role_id)
      : authUser.role_id === 2
      ? true
      : false;

  return (
    <>
      {authUser.role_id === 2 && (
        <div>
          <Table bordered hover>
            <thead>
              <tr>
                <th colSpan={2} className="light-gray">
                  <div className="d-flex justify-content-between align-items-center">
                    <div> প্রাথমিকভাবে যাচাইকৃত তথ্য</div>
                    <div></div>
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>১। চালান চেক</td>
                <td className="text-center">
                  {info.treasuryStatus === 1 ? (
                    <CheckCircleRounded className="text-success" />
                  ) : (
                    <CancelRounded className="text-danger" />
                  )}
                </td>
              </tr>
              <tr>
                <td>২। কর্মের মৌলিকত্ব আছে কি ?</td>
                <td className="text-center">
                  {info.basicStatus === 1 ? (
                    <CheckCircleRounded className="text-success" />
                  ) : (
                    <CancelRounded className="text-danger" />
                  )}
                </td>
              </tr>
            </tbody>
          </Table>
        </div>
      )}
      {Number(info.fileStatus) === 23 && (
        <div className="bg-slate p-2 mb-2" onClick={() => setShowModal(true)}>
          <div className="d-flex justify-content-between align-items-center">
            <div>
              <div>শুনানী গ্রহনের চিঠি</div>
            </div>
            <div>
              <>
                <span
                  style={{
                    fontSize: "14px",
                    cursor: "pointer",
                    fontWeight: "400",
                  }}
                  className="text-primary"
                >
                  চিঠি দেখুন
                </span>
              </>
            </div>
          </div>
        </div>
      )}
      <div>
        <Table bordered hover>
          <thead>
            <tr>
              <th colSpan={2} className="light-gray">
                মৌলিকত্ব পুনঃপরীক্ষা
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="pt-2">কর্মের মৌলিকত্ব পুনঃপরীক্ষা করতে চান?</td>
              <td>
                <Button variant="secondary" onClick={handleReligiosityTest}>
                  ক্লিক করুন
                </Button>
              </td>
            </tr>
          </tbody>
        </Table>
      </div>
      {religiosityTest && (
        <div className="mt-3">
          {info.appType !== 1 ? (
            <OriginalityTest
              info={info}
              setOriginalityData={setOriginalityData}
            />
          ) : (
            <OriginalityTestCopyright
              info={info}
              setOriginalityData={setOriginalityData}
            />
          )}
        </div>
      )}
      <div className="my-4">
        {originalityData.length > 0 ? (
          <div className="overflow-auto">
            <div className="p-2 my-1 fw-semibold w-100">
              মৌলিকত্বের যাচাইকৃত তথ্য
            </div>
            <Table bordered hover>
              <thead>
                <tr>
                  <th className="light-gray">আবেদনপত্র নং</th>
                  <th className="light-gray">আবেদনকারীর নাম</th>
                  <th className="light-gray">শিরোনাম</th>
                  <th className="light-gray">শ্রেণী</th>
                  <th className="light-gray">উপশ্রেণী</th>
                  <th className="light-gray">আবেদনের তারিখ</th>
                </tr>
              </thead>
              <tbody>
                {originalityData.map((item) => (
                  <tr key={item.id}>
                    <td>{item.trackReg}</td>
                    <td>{item.puname ?? item.applicantName}</td>
                    <td>
                      {item.document?.workTitle ??
                        item.profileDetail?.workTitle}
                    </td>
                    <td>
                      {item.document?.category?.name ??
                        item.profileDetail?.category?.name}
                    </td>
                    <td>
                      {item.document?.subCategory?.name ??
                        item.profileDetail?.subCategory?.name}
                    </td>
                    <td>{localDateFormat(item?.treasuryDate)}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        ) : (
          ""
        )}
      </div>
      <hr />
      <form onSubmit={formik.handleSubmit}>
        <label
          className="my-2 p-2 bg-slate w-100 fw-semibold"
          htmlFor="comments"
        >
          মন্তব্য
        </label>
        {religiosityTest && (
          <div className="d-flex align-items-center gap-3 mb-4">
            <div className="">
              <div className="mt-2">কর্মের মৌলিকত্ব আছে কি ?</div>
            </div>
            <div>
              <div className="mx-3 mt-2">
                <Tooltip
                  title={formik.values.basicStatus === 1 ? "না" : "হ্যাঁ"}
                  placement="right"
                >
                  <Form.Check
                    type="switch"
                    id={`custom-switch${formik.values.basicStatus}`}
                    value={formik.values.basicStatus}
                    className="text-success"
                    isValid
                    checked={formik.values.basicStatus === 1}
                    onChange={(e) => handleSwitchChange(e, "basicStatus")}
                  />
                </Tooltip>
              </div>
            </div>
          </div>
        )}
        <Form.Control
          name="comments"
          placeholder="এখানে লিখুন"
          aria-label="Username"
          aria-describedby="basic-addon1"
          as={"textarea"}
          rows={6}
          value={formik.values.comments}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          isInvalid={formik.touched.comments && formik.errors.comments}
        />
        {formik.touched.comments && formik.errors.comments ? (
          <div className="text-danger">{formik.errors.comments}</div>
        ) : null}
        <div className="text-end py-3">
          <div>
            <div className="d-flex justify-content-end">
              <div className="text-center">
                <div className="text-primary">{info?.admin?.name}</div>
                <div className="text-primary">{info?.admin?.role?.bnName}</div>
                <div>তারিখ : {localDateFormat(new Date())}</div>
                <div>সময় : {localTimeFormat(new Date())}</div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <label className="my-2 fw-semibold" htmlFor="role_id">
              পদবী
            </label>
            <Select
              name="role_id"
              options={rolesOption}
              value={
                formik.values.role_id
                  ? rolesOption.find(
                      (option) => option.value === formik.values.role_id
                    )
                  : defaultValue
              }
              onChange={(selectedOption) => {
                findUser(selectedOption.value);
                formik.setValues((prev) => {
                  return {
                    ...prev,
                    user_id: "",
                  };
                });
                formik.setFieldValue("role_id", selectedOption.value);
              }}
              onBlur={formik.handleBlur}
              classNamePrefix="react-select"
            />
            {formik.touched.role_id && formik.errors.role_id ? (
              <div className="text-danger">{formik.errors.role_id}</div>
            ) : null}
          </div>
          <div className="col-md-6">
            <label className="my-2 fw-semibold" htmlFor="user_id">
              প্রাপক
            </label>
            <Select
              name="user_id"
              options={usersOption}
              value={
                formik.values.user_id
                  ? usersOption.find(
                      (option) => option.value === formik.values.user_id
                    )
                  : defaultValue
              }
              onChange={(selectedOption) =>
                formik.setFieldValue("user_id", selectedOption.value)
              }
              onBlur={formik.handleBlur}
              classNamePrefix="react-select"
            />
            {formik.touched.user_id && formik.errors.user_id ? (
              <div className="text-danger">{formik.errors.user_id}</div>
            ) : null}
          </div>
        </div>
        <div className="my-4">
          <div className="row">
            {authUser?.role_id !== 2 && (
              <>
                <div className="col-md-4 mt-2">
                  <Button
                    className="w-100"
                    type="submit"
                    onClick={() => formik.setFieldValue("submit", 2)}
                    disabled={isLoading}
                  >
                    সংরক্ষণ করুন
                  </Button>
                </div>
                <div className="col-md-4 mt-2">
                  <Button
                    className="w-100"
                    variant="success"
                    onClick={() => formik.setFieldValue("submit", 3)}
                    type="submit"
                    disabled={isLoading}
                  >
                    দাখিল করুন
                  </Button>
                </div>
              </>
            )}
            <div className="col-md-4 mt-2">
              <Button
                className="w-100"
                variant="warning text-white"
                onClick={() => formik.setFieldValue("submit", 17)}
                type="submit"
                disabled={!controlButton || isLoading}
              >
                অতিরিক্ত ব্যাখ্যা
              </Button>
            </div>
            {authUser?.role_id === 2 && (
              <>
                <div className="col-md-4 mt-2">
                  <Button
                    className="w-100"
                    variant="success"
                    onClick={() => formik.setFieldValue("submit", 5)}
                    type="submit"
                    disabled={formik.values.role_id !== 12 || isLoading}
                  >
                    চূড়ান্তভাবে অনুমোদিত
                  </Button>
                </div>
                <div className="col-md-4 mt-2">
                  <Button
                    className="w-100 text-white"
                    variant="info"
                    onClick={() => formik.setFieldValue("submit", 6)}
                    type="submit"
                    disabled={formik.values.role_id !== 7 || isLoading}
                  >
                    চূড়ান্তভাবে অননুমোদন
                  </Button>
                </div>

                <div className="col-md-4 mt-2">
                  <Button
                    className="w-100"
                    variant="secondary"
                    onClick={() => formik.setFieldValue("submit", 19)}
                    type="submit"
                    disabled={formik.values.role_id !== 7 || isLoading}
                  >
                    নথিজাত
                  </Button>
                </div>
              </>
            )}
            {[2, 4].includes(authUser.role_id) && (
              <>
                <div className="col-md-4 mt-2">
                  <Button
                    className="w-100"
                    variant="danger"
                    onClick={() => formik.setFieldValue("submit", 7)}
                    type="submit"
                    disabled={
                      ![5, 6, 7].includes(formik.values.role_id) || isLoading
                    }
                  >
                    সংশোধন/সংযোজন
                  </Button>
                </div>
                <div className="col-md-4 mt-2">
                  <Button
                    className="w-100"
                    variant="dark"
                    onClick={() => formik.setFieldValue("submit", 10)}
                    type="submit"
                    disabled={
                      ![5, 6, 7].includes(formik.values.role_id) || isLoading
                    }
                  >
                    শুনানী
                  </Button>
                </div>
                <div className="col-md-4 mt-2">
                  <Button
                    className="w-100"
                    variant="primary"
                    onClick={() => formik.setFieldValue("submit", 14)}
                    type="submit"
                    disabled={!controlButton || isLoading}
                  >
                    তদন্ত
                  </Button>
                </div>
              </>
            )}
          </div>
        </div>
      </form>
      <ShowLetter
        showModal={showModal}
        id={info.id}
        onHide={() => setShowModal(false)}
      />
    </>
  );
};

export default AssistantExaminerCommonContent;

const submitText = (submit) => {
  switch (submit) {
    case 1:
      return "আপনি কি অনুমোদন দিতে চান?";
    case 2:
      return "আপনি কি সংরক্ষণ করতে চান?";
    case 3:
      return "আপনি কি দাখিল করতে চান?";
    case 5:
      return "আপনি কি চূড়ান্তভাবে অনুমোদিত করতে চান?";
    case 6:
      return "আপনি কি চূড়ান্তভাবে অননুমোদন করতে চান?";
    case 7:
      return "আপনি কি সংশোধন/সংযোজন করতে চান?";
    case 10:
      return "আপনি কি শুনানী করতে চান?";
    case 14:
      return "আপনি কি তদন্ত করতে চান?";
    case 17:
      return "আপনি কি অতিরিক্ত ব্যাখ্যা করতে চান?";
    case 19:
      return "আপনি কি নথিজাত করতে চান?";
    default:
      return "আপনি কি নিশ্চিত";
  }
};
