import {
  COMMENT_AND_ORDERED_SEARCH_LIST,
  REPLY_ORDERED_SEARCH,
} from "../constants";
import { apiSlice } from "./apiSlice";

const commentAndOrderedApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getCommentAndOrdered: builder.query({
      query: ({ trackReg = "", date }) => {
        let url = `${COMMENT_AND_ORDERED_SEARCH_LIST}?trackReg=${trackReg}`;
        if (date) {
          url += `&date=${date}`;
        }
        return {
          url: url,
          method: "GET",
          transformResponse: (response) => {
            const data = response.json();
            return data;
          },
        };
      },
      providesTags: ["comment_and_ordered"],
    }),
    // post REPLY_ORDERED_SEARCH
    postCommentAndOrdered: builder.mutation({
      query: ({ formData, id }) => ({
        url: REPLY_ORDERED_SEARCH + "/" + id,
        method: "POST",
        body: formData,
      }),
      invalidatesTags: ["comment_and_ordered"],
    }),
  }),
});

export const {
  useGetCommentAndOrderedQuery,
  usePostCommentAndOrderedMutation,
} = commentAndOrderedApiSlice;
