import React, { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Select from "react-select";
import AsyncSelect from "react-select/async";
import { useFormik } from "formik";
import * as Yup from "yup";
import { BeatLoader } from "react-spinners";
import toast from "react-hot-toast";
import { ImCross } from "react-icons/im";
import { IoMdEye } from "react-icons/io";
import {
  useGetBankQuery,
  useLazyGetBankBranchesQuery,
} from "../redux/api/applicationPaymentProcessApiSlice";
import { transformErrorsToObjectStructure } from "../utils/transformErrorsToObjectStructure";
import { createFormData } from "../utils/formDataTransform";
import Loader from "./custom/Loader";
import styles from "../pages/frontend/userPanel/copyright_payment/ApplicationForCopyright.module.css";
import { assets } from "../assets";
import { globalDateFormat, localDateFormat } from "../utils/dateFormat";
import BanglaCalender from "../pages/frontend/userPanel/form_two/BanglaCalender";
import FilePreviewModal from "./ApllicantionFilePreview";
import ShowPreviewFiles from "../pages/frontend/userPanel/form_two/ShowPreviewFiles";
import ClientFormikFormFieldMultiple from "./custom/form/ClientFormikFormFieldMultiple";

const validationSchema = Yup.object().shape({
  st: Yup.number().required("এসটি প্রযোজ্য"),
  amountFor: Yup.number().required("টাকার পরিমাণ প্রযোজ্য"),
  treasuryDate: Yup.date().required("তারিখ প্রযোজ্য"),
  treasuryBank: Yup.number().required("ব্যাংকের নাম প্রযোজ্য"),
  bankbranch_id: Yup.number().required("শাখার নাম প্রযোজ্য"),
});

const TreasuryPaymentUi = ({
  redirectUrl,
  mainData,
  isLoading,
  isLoadingUpdate,
  errorUpdate,
  isErrorUpdate,
  dataUpdate,
  isSuccessUpdate,
  updateApplication,
}) => {
  const [previewModal, setPreviewModal] = useState(false);
  const [previewFile, setPreviewFile] = useState("");
  const [previewModalURL, setPreviewModalURL] = useState(false);
  const { profileId } = useParams();
  const [searchText, setSearchText] = useState("");
  const [bank_id, setBank_id] = useState("");
  const [bankBranches, setBankBranches] = useState([]);
  const [page, setPage] = useState(1);
  const navigate = useNavigate();

  const fileInputRef = useRef(null);

  const { data: bankName, isFetching: isFetchingBank } = useGetBankQuery();
  const [fetchBankBranches, { isFetching }] = useLazyGetBankBranchesQuery();

  const treasuryBankName = bankName?.data.map((item) => {
    return { value: item.id, label: item.name };
  });

  const amountForList = mainData?.amountForList || {};
  const amountBD = Object.entries(amountForList).map(([value, label]) => ({
    value: Number(value),
    label: label,
  }));

  const defaultValue = {
    value: "",
    label: "নির্বাচন করুন",
  };

  const loadOptions = useCallback(
    async (inputValue, callback) => {
      setSearchText(inputValue);
      if (page !== 1) {
        setPage(1); // Reset page when search text changes
      }
      try {
        const data = await fetchBankBranches({
          bank_id,
          name: inputValue,
          page: page,
        });
        const branches = data?.data?.data.map((item) => ({
          value: item.id,
          label: item.name,
        }));
        setBankBranches(branches);
        callback(branches);
      } catch (error) {
        console.error("Error loading options:", error);
      }
    },
    [bank_id, page]
  );

  const handleMenuScrollToBottom = async () => {
    const newPage = page + 1;
    setPage(newPage);
    try {
      const data = await fetchBankBranches({
        bank_id,
        name: searchText,
        page: newPage,
      });
      const newBranches = data?.data?.data.map((item) => ({
        value: item.id,
        label: item.name,
      }));
      setBankBranches((prevBranches) => [...prevBranches, ...newBranches]);
    } catch (error) {
      console.error("Error scrolling to bottom:", error);
    }
  };

  const {
    values,
    touched,
    errors,
    handleBlur,
    handleChange,
    handleSubmit,
    setValues,
    setErrors,
    setFieldValue,
  } = useFormik({
    initialValues: {
      appType: Number(mainData?.apptype) || "",
      user_id: mainData?.user_id || "",
      trackReg: mainData?.trackReg || "",
      st: Number(mainData?.st) || "",
      amountFor: Number(mainData?.amountFor) || "",
      treasuryNo: mainData?.treasuryNo || "",
      treasuryDate: mainData?.treasuryDate || "",
      treasuryBank: mainData?.bankbranch?.bank?.id || "",
      bankbranch_id: mainData?.bankbranch?.id || "",
      treasury: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values, { setSubmitting }) => {
      try {
        const formData = createFormData(values);
        await updateApplication({ id: mainData?.id, formData });
      } catch (error) {
        console.error("Error submitting form:", error);
        setSubmitting(false);
      }
    },
    enableReinitialize: true,
  });

  useEffect(() => {
    if (mainData?.bankbranch?.bank?.id) {
      setBank_id(mainData?.bankbranch?.bank?.id);
      loadOptions();
    }
  }, [mainData?.bankbranch?.bank?.id]);

  useEffect(() => {
    if (isErrorUpdate) {
      setErrors(
        transformErrorsToObjectStructure(errorUpdate?.data?.data || {})
      );
      toast.error(errorUpdate?.data?.message);
    }
    if (isSuccessUpdate) {
      toast.success(dataUpdate?.message);
      if (redirectUrl) {
        navigate(redirectUrl, { replace: true });
      } else {
        navigate(-1);
      }
    }
    // eslint-disable-next-line
  }, [isErrorUpdate, isSuccessUpdate]);

  useEffect(() => {
    if (profileId) {
      setValues((prev) => ({ ...prev, application_id: Number(profileId) }));
    }
  }, [profileId]);

  const handleFileChange = (field, e) => {
    const file = e.currentTarget.files[0];
    setFieldValue(field, file);
  };

  const handleSelectChange = async (name, e) => {
    if (name === "treasuryBank") {
      setBank_id(e.value);
      setBankBranches([]); // Clear bank branches when bank_id changes
      setFieldValue("bankbranch_id", defaultValue.value); // Reset selected bank branch
      try {
        const data = await fetchBankBranches({
          bank_id: e.value,
          name: "",
          page: 1,
        });
        const branches = data?.data?.data.map((item) => ({
          value: item.id,
          label: item.name,
        }));
        setBankBranches(branches);
      } catch (error) {
        console.error("Error fetching bank branches:", error);
      }
    }
    setValues((prev) => ({ ...prev, [name]: e.value }));
  };

  if (isLoading) {
    return <Loader />;
  }

  const handleRemoveFile = () => {
    if (fileInputRef.current) {
      fileInputRef.current.value = null; // Clear the file input
    }
    setValues((prev) => ({ ...prev, treasury: "" })); // Reset state
  };

  return (
    <div className="pb-5">
      <section className="section py-5 service-single">
        <div className="container pb-5">
          <div className="d-flex justify-content-center align-items-center">
            <div className="card p-3 shadow w-100">
              <nav>
                <div className="nav nav-tabs" id="nav-tab" role="tablist">
                  <button
                    className="nav-link active fw-semibold"
                    id="nav-application-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#nav-application"
                    type="button"
                    role="tab"
                    aria-controls="nav-application"
                    aria-selected="true"
                  >
                    {Number(mainData?.apptype) === 1 ? "কপিরাইটঃ" : "চুক্তিপত্রঃ"}{" "}
                     রেজিস্ট্রেশন ফি এবং পেমেন্ট অপশন
                  </button>
                </div>
              </nav>
              <div
                className="tab-content p-3 border border-top-0"
                id="nav-tabContent"
              >
                <div
                  className="tab-pane fade active show"
                  id="nav-application"
                  role="tabpanel"
                  aria-labelledby="nav-application-tab"
                >
                  {Number(mainData?.st) === 3 ? (
                    <div className="applicationForm">
                      <div className="applicationForm-body">
                        <div className="applicationForm-body-top">
                          <div className="d-flex mt-4">
                            <span className="d-flex">
                              <div className="form-check mr-3">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="howToPayRadio"
                                  id="howToPayRadio1"
                                  checked={Number(mainData?.st) === 3}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="howToPayRadio1"
                                >
                                  ই-চালান
                                </label>
                              </div>
                              <div className="form-check mx-3">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="howToPayRadio"
                                  id="howToPayRadio2"
                                  checked={Number(mainData?.st) === 2}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="howToPayRadio2"
                                >
                                  ট্রেজারি
                                </label>
                              </div>
                            </span>
                          </div>

                          <div>
                            <div className={`${styles.howToPay}  my-4`}>
                              <div className="row">
                                <>
                                  <div className="col-md-4 pt-3">
                                    <div className="form-group ">
                                      <img src={assets?.echalan} alt="Logo" />
                                    </div>
                                  </div>
                                  <div className="col-md-4 pt-3">
                                    <div className="form-group">
                                      <label>ট্রানজ্যাকশন নম্বর</label>
                                      <div> {mainData?.transactionNo}</div>
                                    </div>
                                  </div>
                                  <div className="col-md-4 pt-3">
                                    <div className="form-group">
                                      <label>ই-চালান নম্বর</label>
                                      <div> {mainData?.echallanId}</div>
                                    </div>
                                  </div>
                                  <div className="col-md-4 pt-3"></div>
                                </>
                                <div className="col-md-4 pt-3">
                                  <div className="form-group">
                                    <label>টাকার পরিমাণ </label>
                                    <div>
                                      <div>{mainData?.amount}</div>
                                    </div>
                                  </div>
                                </div>

                                <div className="col-md-4 pt-3">
                                  <div className="form-group">
                                    <label>
                                      {Number(mainData?.st) === 3
                                        ? "ট্রানজ্যাকশন"
                                        : "ট্রেজারি"}{" "}
                                      তারিখ
                                    </label>
                                    <div>
                                      {" "}
                                      {localDateFormat(mainData?.treasuryDate)}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          {/* <div className="">
                            <Alert variant="warning">Message</Alert>
                          </div> */}
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="applicationForm">
                      <div className="applicationForm-body">
                        <div className="applicationForm-body-top">
                          <div className="d-flex mt-4">
                            <span className="d-flex">
                              <div className="form-check mr-3">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="howToPayRadio"
                                  id="howToPayRadio1"
                                  checked={Number(mainData?.st) === 3}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="howToPayRadio1"
                                >
                                  ই-চালান
                                </label>
                              </div>
                              <div className="form-check mx-3">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="howToPayRadio"
                                  id="howToPayRadio2"
                                  checked={Number(mainData?.st) === 2}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="howToPayRadio2"
                                >
                                  ট্রেজারি
                                </label>
                              </div>
                            </span>
                          </div>

                          <div>
                            <div className={`${styles.howToPay}  my-4`}>
                              <div className="row">
                                <>
                                  <div className="col-md-4 pt-3">
                                    <div className="form-group">
                                      <label>ট্রেজারি চালান নং </label>
                                      <ClientFormikFormFieldMultiple
                                        placeholder="ট্রেজারি চালান নং"
                                        value={values.treasuryNo}
                                        name={`treasuryNo`}
                                        errors={
                                          errors?.treasuryNo &&
                                          errors?.treasuryNo
                                        }
                                        touched={
                                          touched?.treasuryNo &&
                                          touched?.treasuryNo
                                        }
                                        handleChange={handleChange}
                                        handleBlur={handleBlur}
                                      />
                                    </div>
                                  </div>
                                  <div className="col-md-4 pt-3">
                                    <div className="form-group">
                                      <label>তারিখ </label>
                                      <div
                                        className={`${styles.add2PanelBackground} pb-2`}
                                      >
                                        <BanglaCalender
                                          placeholder="তারিখ"
                                          value={values?.treasuryDate}
                                          onChange={(date) => {
                                            setValues((prevValues) => ({
                                              ...prevValues,
                                              treasuryDate:
                                                globalDateFormat(date),
                                            }));
                                          }}
                                        />
                                      </div>
                                      <div>
                                        {errors.treasuryDate &&
                                          touched.treasuryDate && (
                                            <small className="my-2 text-danger">
                                              {errors.treasuryDate}
                                            </small>
                                          )}
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-md-4 pt-3">
                                    <div className="form-group">
                                      <label>ব্যাংকের নাম </label>
                                      <div>
                                        <Select
                                          onChange={(e) => {
                                            setBank_id(e.value);
                                            handleSelectChange(
                                              "treasuryBank",
                                              e
                                            );
                                          }}
                                          onBlur={handleBlur}
                                          value={
                                            values.treasuryBank
                                              ? treasuryBankName?.find(
                                                  (option) =>
                                                    option.value ===
                                                    values.treasuryBank
                                                )
                                              : defaultValue
                                          }
                                          options={treasuryBankName}
                                          classNamePrefix="react-select"
                                          isDisabled={isFetchingBank}
                                          isLoading={isFetchingBank}
                                        />
                                        <div>
                                          {errors.treasuryBank &&
                                            touched.treasuryBank && (
                                              <small className="my-2 text-danger">
                                                {errors.treasuryBank}
                                              </small>
                                            )}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-md-4 pt-3">
                                    <div className="form-group">
                                      <label>শাখা </label>
                                      <div>
                                        <AsyncSelect
                                          value={
                                            values.bankbranch_id
                                              ? bankBranches.find(
                                                  (option) =>
                                                    option.value ===
                                                    values.bankbranch_id
                                                )
                                              : defaultValue
                                          }
                                          loadOptions={loadOptions}
                                          onMenuScrollToBottom={
                                            handleMenuScrollToBottom
                                          }
                                          cacheOptions
                                          defaultOptions={bankBranches}
                                          onChange={(e) =>
                                            handleSelectChange(
                                              "bankbranch_id",
                                              e
                                            )
                                          }
                                          //   isDisabled={isFetching}
                                          //   isLoading={isFetching}
                                        />
                                      </div>
                                      <div>
                                        {errors.bankbranch_id &&
                                          touched.bankbranch_id && (
                                            <small className="my-2 text-danger">
                                              {errors.bankbranch_id}
                                            </small>
                                          )}
                                      </div>
                                    </div>
                                  </div>
                                </>
                                <div className="col-md-4 pt-3">
                                  <div className="form-group">
                                    <label>টাকার পরিমাণ</label>
                                    <div>
                                      <div>
                                        <Select
                                          onChange={(e) =>
                                            handleSelectChange("amountFor", e)
                                          }
                                          onBlur={handleBlur}
                                          options={amountBD}
                                          classNamePrefix="react-select"
                                          value={
                                            amountBD.find(
                                              (option) =>
                                                option.value ===
                                                values.amountFor
                                            ) || defaultValue
                                          }
                                        />
                                        <div>
                                          {errors.amountFor &&
                                            touched.amountFor && (
                                              <small className="my-2 text-danger">
                                                {errors.amountFor}
                                              </small>
                                            )}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-4 pt-3">
                                  <div
                                    className={`${styles.add2FormGroupImagePreviewBox} form-group text-center`}
                                  >
                                    <label>
                                      ট্রেজারি চালান ( স্ক্যান কপি )
                                    </label>
                                    <br />
                                    <div className="py-1 px-3">
                                      {values.treasury || mainData?.treasury ? (
                                        <span>
                                          <div className="d-flex gap-3">
                                            <div
                                              className="btn btn-success"
                                              onClick={() => {
                                                if (values.treasury) {
                                                  setPreviewFile(
                                                    values.treasury
                                                  );
                                                  setPreviewModal(true);
                                                } else {
                                                  setPreviewFile(
                                                    mainData?.treasury
                                                  );
                                                  setPreviewModalURL(true);
                                                }
                                              }}
                                            >
                                              <IoMdEye />
                                            </div>

                                            {values.treasury && (
                                              <div
                                                className="btn btn-danger"
                                                onClick={handleRemoveFile}
                                              >
                                                <ImCross />
                                              </div>
                                            )}
                                          </div>
                                        </span>
                                      ) : (
                                        <span className={styles.placeholder}>
                                          - - - - -
                                        </span>
                                      )}
                                    </div>
                                    <div>
                                      {errors.treasury && (
                                        <small className="my-2 text-danger">
                                          {errors.treasury}
                                        </small>
                                      )}
                                    </div>
                                    <label className="custombtn5 mb-3">
                                      <input
                                        ref={fileInputRef}
                                        type="file"
                                        accept=".png,.jpg,.jpeg,.pdf"
                                        className="d-none"
                                        onChange={(e) => {
                                          handleFileChange(`treasury`, e);
                                        }}
                                      />{" "}
                                      নির্বাচন করুন
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="applicationForm-body-footer text-center mt-3">
                          <button
                            className="custombtn5"
                            type="submit"
                            onClick={handleSubmit}
                            disabled={isLoadingUpdate}
                          >
                            {isLoadingUpdate ? (
                              <BeatLoader />
                            ) : values.st === 2 ? (
                              "দাখিল করুন"
                            ) : (
                              <>
                                <img
                                  style={{ width: "30px" }}
                                  src={assets?.pay}
                                  alt="Logo"
                                />
                                <span>পেমেন্ট করুন</span>
                              </>
                            )}
                          </button>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {previewModal && (
        <FilePreviewModal
          setPreviewModal={setPreviewModal}
          previewModal={previewModal}
          previewFile={previewFile}
          setPreviewFile={setPreviewFile}
        />
      )}
      {previewModalURL && (
        <ShowPreviewFiles
          setPreviewModal={setPreviewModalURL}
          previewModal={previewModalURL}
          previewUrl={previewFile}
          setPreviewUrl={setPreviewFile}
        />
      )}
    </div>
  );
};

export default TreasuryPaymentUi;
