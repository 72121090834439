import React from "react";
import SubmittedAgreement from "../sumitted_agreement/SubmittedAgreement";

const ExplanationAgreement = () => {
  const applicationPath = {
    copyright: "/admin/explanation-list-copyright",
    agreement: "/admin/explanation-list-agreement",
  };
  const detailsRoute = "/admin/explanation-agreement-details";
  const pageTitle = "ব্যাখ্যার নথি ";
  return (
    <SubmittedAgreement fileStatus={17} applicationPath={applicationPath} detailsRoute={detailsRoute} pageTitle={pageTitle} />
  );
};

export default ExplanationAgreement;
