import React, { useEffect } from "react";
import {
  useShowFileSizeWebsiteSettingQuery,
  useUpdateFileSizeWebsiteSettingMutation,
} from "../redux/api/websiteSettingsApiSlice";
import FormikFormField from "./custom/form/FormikFormField";
import * as Yup from "yup";
import { useFormik } from "formik";
import { Button, Form } from "react-bootstrap";
import Loader from "./custom/Loader";
import toast from "react-hot-toast";
import { BeatLoader } from "react-spinners";
import { transformErrorsToObjectStructure } from "../utils/transformErrorsToObjectStructure";

const initialValues = {
  treasury: "",
  delegation: "",
  singature: "",
  applicant: "",
  nationalIdentity: "",
  tradeLicense: "",
  organizationTin: "",
  memorandum: "",
  unpopularity: "",
  sample: "",
  coverAction: "",
  approval: "",
  masterpieces: "",
  warishnamera: "",
  unpoplarity1: "",
  agreement: "",
  license: "",
  structure: "",
  coverAction1: "",
  heir: "",
  singing: "",
};

const validationSchema = Yup.object().shape({
  treasury: Yup.string().required("ফাইল সাইজ প্রযোজ্য"),
  delegation: Yup.string().required("ফাইল সাইজ প্রযোজ্য"),
  singature: Yup.string().required("ফাইল সাইজ প্রযোজ্য"),
  applicant: Yup.string().required("ফাইল সাইজ প্রযোজ্য"),
  nationalIdentity: Yup.string().required("ফাইল সাইজ প্রযোজ্য"),
  tradeLicense: Yup.string().required("ফাইল সাইজ প্রযোজ্য"),
  organizationTin: Yup.string().required("ফাইল সাইজ প্রযোজ্য"),
  memorandum: Yup.string().required("ফাইল সাইজ প্রযোজ্য"),
  unpopularity: Yup.string().required("ফাইল সাইজ প্রযোজ্য"),
  sample: Yup.string().required("ফাইল সাইজ প্রযোজ্য"),
  coverAction: Yup.string().required("ফাইল সাইজ প্রযোজ্য"),
  approval: Yup.string().required("ফাইল সাইজ প্রযোজ্য"),
  masterpieces: Yup.string().required("ফাইল সাইজ প্রযোজ্য"),
  warishnamera: Yup.string().required("ফাইল সাইজ প্রযোজ্য"),
  unpoplarity1: Yup.string().required("ফাইল সাইজ প্রযোজ্য"),
  agreement: Yup.string().required("ফাইল সাইজ প্রযোজ্য"),
  license: Yup.string().required("ফাইল সাইজ প্রযোজ্য"),
  structure: Yup.string().required("ফাইল সাইজ প্রযোজ্য"),
  coverAction1: Yup.string().required("ফাইল সাইজ প্রযোজ্য"),
  heir: Yup.string().required("ফাইল সাইজ প্রযোজ্য"),
  singing: Yup.string().required("ফাইল সাইজ প্রযোজ্য"),
});

const FileSizeWebsiteSettings = () => {
  const { data, isLoading } = useShowFileSizeWebsiteSettingQuery();
  const [
    updateFileSizeWebsite,
    {
      isLoading: isLoadingUpdate,
      isError: isErrorUpdate,
      error: errorUpdate,
      isSuccess: isSuccessUpdate,
      data: dataUpdate,
    },
  ] = useUpdateFileSizeWebsiteSettingMutation();

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values, { setSubmitting }) => {
      const formData = { ...values, _method: "PUT" };
      await updateFileSizeWebsite(formData);
    },
  });

  const {
    values,
    touched,
    errors,
    handleBlur,
    handleChange,
    handleSubmit,
    setValues,
    setErrors,
    resetForm,
  } = formik;

  useEffect(() => {
    if (data?.data) {
      setValues(data.data);
    }
  }, [data?.data, setValues]);

  const handleServerErrors = (serverErrors) => {
    const errors = {};
    Object.keys(serverErrors).forEach((field) => {
      errors[field] = serverErrors[field].join(", ");
    });
    return errors;
  };

  useEffect(() => {
    if (isErrorUpdate) {
      setErrors(
        transformErrorsToObjectStructure(errorUpdate?.data?.data || {})
      );
      toast.error(errorUpdate?.data?.message);
    }
    if (isSuccessUpdate) {
      toast.success(dataUpdate?.message);
    }
    // eslint-disable-next-line
  }, [isErrorUpdate, isSuccessUpdate]);

  if (isLoading) {
    return <Loader />;
  }

  return (
    <Form onSubmit={handleSubmit}>
      <div className="row p-2">
        <div className="col-md-3 col-6">
          <div className="form-group">
            <label>ট্রেজারি চালান ( স্ক্যান কপি )</label>
            <FormikFormField
              placeholder="ট্রেজারি চালান ( স্ক্যান কপি )"
              value={values.treasury}
              name="treasury"
              errors={errors}
              touched={touched}
              handleChange={handleChange}
              handleBlur={handleBlur}
            />
          </div>
        </div>
        <div className="col-md-3 col-6">
          <div className="form-group">
            <label>অঙ্গীকারনামার স্ক্যান কপি</label>
            <FormikFormField
              placeholder="অঙ্গীকারনামার স্ক্যান কপি"
              value={values.delegation}
              name="delegation"
              errors={errors}
              touched={touched}
              handleChange={handleChange}
              handleBlur={handleBlur}
            />
          </div>
        </div>
        <div className="col-md-3 col-6">
          <div className="form-group">
            <label>স্বাক্ষর</label>
            <FormikFormField
              placeholder="স্বাক্ষর"
              value={values.singature}
              name="singature"
              errors={errors}
              touched={touched}
              handleChange={handleChange}
              handleBlur={handleBlur}
            />
          </div>
        </div>
        <div className="col-md-3 col-6">
          <div className="form-group">
            <label>আবেদনকারীর ছবি</label>
            <FormikFormField
              placeholder="আবেদনকারীর ছবি"
              value={values.applicant}
              name="applicant"
              errors={errors}
              touched={touched}
              handleChange={handleChange}
              handleBlur={handleBlur}
            />
          </div>
        </div>
        <div className="col-md-3 col-6">
          <div className="form-group">
            <label>জাতীয় পরিচয় পত্রের কপি</label>
            <FormikFormField
              placeholder="জাতীয় পরিচয় পত্রের কপি"
              value={values.nationalIdentity}
              name="nationalIdentity"
              errors={errors}
              touched={touched}
              handleChange={handleChange}
              handleBlur={handleBlur}
            />
          </div>
        </div>
        <div className="col-md-3 col-6">
          <div className="form-group">
            <label>প্রতিষ্ঠানের ট্রেড লাইসেন্সের</label>
            <FormikFormField
              placeholder="প্রতিষ্ঠানের ট্রেড লাইসেন্সের"
              value={values.tradeLicense}
              name="tradeLicense"
              errors={errors}
              touched={touched}
              handleChange={handleChange}
              handleBlur={handleBlur}
            />
          </div>
        </div>
        <div className="col-md-3 col-6">
          <div className="form-group">
            <label>প্রতিষ্ঠানের টিন সার্টিফিকেটের</label>
            <FormikFormField
              placeholder="প্রতিষ্ঠানের টিন সার্টিফিকেটের"
              value={values.organizationTin}
              name="organizationTin"
              errors={errors}
              touched={touched}
              handleChange={handleChange}
              handleBlur={handleBlur}
            />
          </div>
        </div>
        <div className="col-md-3 col-6">
          <div className="form-group">
            <label>প্রতিষ্ঠানের মেমোরান্ডামের</label>
            <FormikFormField
              placeholder="প্রতিষ্ঠানের মেমোরান্ডামের"
              value={values.memorandum}
              name="memorandum"
              errors={errors}
              touched={touched}
              handleChange={handleChange}
              handleBlur={handleBlur}
            />
          </div>
        </div>
        <div className="col-md-3 col-6">
          <div className="form-group">
            <label>অনাপত্তি পত্রের স্ক্যান কপি</label>
            <FormikFormField
              placeholder="অনাপত্তি পত্রের স্ক্যান কপি"
              value={values.unpopularity}
              name="unpopularity"
              errors={errors}
              touched={touched}
              handleChange={handleChange}
              handleBlur={handleBlur}
            />
          </div>
        </div>
        <div className="col-md-3 col-6">
          <div className="form-group">
            <label>নমুনা</label>
            <FormikFormField
              placeholder="নমুনা"
              value={values.sample}
              name="sample"
              errors={errors}
              touched={touched}
              handleChange={handleChange}
              handleBlur={handleBlur}
            />
          </div>
        </div>
        <div className="col-md-3 col-6">
          <div className="form-group">
            <label>প্রচ্ছদ কর্মের হস্তান্তর পত্র</label>
            <FormikFormField
              placeholder="প্রচ্ছদ কর্মের হস্তান্তর পত্র"
              value={values.coverAction}
              name="coverAction"
              errors={errors}
              touched={touched}
              handleChange={handleChange}
              handleBlur={handleBlur}
            />
          </div>
        </div>
        <div className="col-md-3 col-6">
          <div className="form-group">
            <label>অনুমোদনের স্ক্যান কপি</label>
            <FormikFormField
              placeholder="অনুমোদনের স্ক্যান কপি"
              value={values.approval}
              name="approval"
              errors={errors}
              touched={touched}
              handleChange={handleChange}
              handleBlur={handleBlur}
            />
          </div>
        </div>
        <div className="col-md-3 col-6">
          <div className="form-group">
            <label>প্রণেতার নিয়োগপত্র</label>
            <FormikFormField
              placeholder="প্রণেতার নিয়োগপত্র"
              value={values.masterpieces}
              name="masterpieces"
              errors={errors}
              touched={touched}
              handleChange={handleChange}
              handleBlur={handleBlur}
            />
          </div>
        </div>
        <div className="col-md-3 col-6">
          <div className="form-group">
            <label>ওয়ারিশনামার সার্টিফিকেটের কপি</label>
            <FormikFormField
              placeholder="ওয়ারিশনামার সার্টিফিকেটের কপি"
              value={values.warishnamera}
              name="warishnamera"
              errors={errors}
              touched={touched}
              handleChange={handleChange}
              handleBlur={handleBlur}
            />
          </div>
        </div>
        <div className="col-md-3 col-6">
          <div className="form-group">
            <label>অনুমতিপত্রের স্ক্যান কপি</label>
            <FormikFormField
              placeholder="অনুমতিপত্রের স্ক্যান কপি"
              value={values.unpoplarity1}
              name="unpoplarity1"
              errors={errors}
              touched={touched}
              handleChange={handleChange}
              handleBlur={handleBlur}
            />
          </div>
        </div>
        <div className="col-md-3 col-6">
          <div className="form-group">
            <label>চুক্তিপত্রের স্ক্যান কপি</label>
            <FormikFormField
              placeholder="চুক্তিপত্রের স্ক্যান কপি"
              value={values.agreement}
              name="agreement"
              errors={errors}
              touched={touched}
              handleChange={handleChange}
              handleBlur={handleBlur}
            />
          </div>
        </div>
        <div className="col-md-3 col-6">
          <div className="form-group">
            <label>লাইসেন্স প্রদানের ক্ষমতা পত্র</label>
            <FormikFormField
              placeholder="লাইসেন্স প্রদানের ক্ষমতা পত্র"
              value={values.license}
              name="license"
              errors={errors}
              touched={touched}
              handleChange={handleChange}
              handleBlur={handleBlur}
            />
          </div>
        </div>
        <div className="col-md-3 col-6">
          <div className="form-group">
            <label>প্রতিষ্ঠানের গঠনতন্ত্রের স্ক্যান কপি</label>
            <FormikFormField
              placeholder="প্রতিষ্ঠানের গঠনতন্ত্রের স্ক্যান কপি"
              value={values.structure}
              name="structure"
              errors={errors}
              touched={touched}
              handleChange={handleChange}
              handleBlur={handleBlur}
            />
          </div>
        </div>
        <div className="col-md-3 col-6">
          <div className="form-group">
            <label>প্রচ্ছদ কর্মের হস্তান্তর দলিল স্ক্যান কপি</label>
            <FormikFormField
              placeholder="প্রচ্ছদ কর্মের হস্তান্তর দলিল স্ক্যান কপি"
              value={values.coverAction1}
              name="coverAction1"
              errors={errors}
              touched={touched}
              handleChange={handleChange}
              handleBlur={handleBlur}
            />
          </div>
        </div>
        <div className="col-md-3 col-6">
          <div className="form-group">
            <label>উত্তরাধিকারির স্ক্যান কপি</label>
            <FormikFormField
              placeholder="উত্তরাধিকারির স্ক্যান কপি"
              value={values.heir}
              name="heir"
              errors={errors}
              touched={touched}
              handleChange={handleChange}
              handleBlur={handleBlur}
            />
          </div>
        </div>
        <div className="col-md-12 col-6">
          <div className="form-group">
            <label>
              গানের তালিকা/চলচ্চিত্র সেন্সর সনদ (গানের/চলচ্চিত্রের ক্ষেত্রে)
            </label>
            <FormikFormField
              placeholder="গানের তালিকা/চলচ্চিত্র সেন্সর সনদ (গানের/চলচ্চিত্রের ক্ষেত্রে)"
              value={values.singing}
              name="singing"
              errors={errors}
              touched={touched}
              handleChange={handleChange}
              handleBlur={handleBlur}
            />
          </div>
        </div>
      </div>
      <div className="text-center mb-2">
        <Button type="submit" disabled={isLoadingUpdate}>
          {isLoadingUpdate ? <BeatLoader color="#fff" /> : "আপডেট করুন"}
        </Button>
      </div>
    </Form>
  );
};

export default FileSizeWebsiteSettings;
