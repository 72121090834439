import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useGetCategoriesQuery } from "../../redux/api/categoryApiSlice";
import Loader from "../../components/custom/Loader";
import { useDispatch } from "react-redux";
import { logout } from "../../redux/features/auth/authSlice";

export default function Home() {
  const dispatch = useDispatch();
  const { data, isLoading } = useGetCategoriesQuery();
  if (isLoading) {
    return <Loader />;
  }



  return (
    <section className="portfolio-section mt-5 mb-4 pb-0">
      <div className="container">
        <div className="row grid">
          {data?.data?.map((item) => (
            <div
              key={item.id}
              className="col-lg- col-md-4 col-sm-6 grid-item sahitto"
            >
              <div className="card lormolist shadow-sm mb-4">
                <div
                  className="portfolio-item-wrapper wow "
                  data-wow-delay=".3s"
                >
                  <div className="portfolio-img">
                    <img src={item?.image} alt={item.name} />
                  </div>
                  <div className="portfolio-overlay">
                    <div className="overlay-content">
                      <h4>{item?.name}</h4>
                      <p>বাংলাদেশ কপিরাইট অফিস </p>
                      <Link
                        className="theme-btn border-btn"
                        to={`details/${item.slug}`}
                      >
                        বিস্তারিত দেখুন
                      </Link>
                    </div>
                  </div>
                </div>
                <Link
                  className="m-auto buttonn my-3 text-secondary"
                  to={`details/${item.slug}`}
                >
                  <h5 className="text-center">{item?.name}</h5>
                </Link>
              </div>
            </div>
          ))}        
        </div>
      </div>
    </section>
  );
}
