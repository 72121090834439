const englishToBengaliNumber = (num) => {
  const banglaNumbers = {
    0: "০",
    1: "১",
    2: "২",
    3: "৩",
    4: "৪",
    5: "৫",
    6: "৬",
    7: "৭",
    8: "৮",
    9: "৯",
  };
  return num
    .toString()
    .split("")
    .map((digit) => banglaNumbers[digit] || digit)
    .join("");
};

export const bnTimeFormat = (time24) => {
  if (time24) {
    const [hours, minutes] = time24.split(":");
    const hours24 = parseInt(hours, 10);
    const period = hours24 >= 12 ? "PM" : "AM";
    const hours12 = hours24 % 12 || 12; // Convert to 12-hour format
    return `${englishToBengaliNumber(hours12)}:${englishToBengaliNumber(
      minutes
    )} ${period}`;
  }
  return "";
};

export const timeFormat12 = (time24) => {
  if (time24) {
    const [hours, minutes] = time24.split(":");
    const hours24 = parseInt(hours, 10);
    const period = hours24 >= 12 ? "PM" : "AM";
    const hours12 = hours24 % 12 || 12; // Convert to 12-hour format
    return `${hours12}:${minutes} ${period}`;
  }
  return "";
};
