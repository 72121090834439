import { Copyright, Handshake, RestartAlt } from "@mui/icons-material";
import { Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Badge, Button, Table } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import Select from "react-select";
import { BeatLoader } from "react-spinners";
import BanglaCalender from "../pages/frontend/userPanel/form_two/BanglaCalender";
import {
  useGetApplicationAgreementQuery,
  useGetApplicationCopyrightQuery,
  useGetByRoleQuery,
} from "../redux/api/applicationCopyrightApiSlice";
import { useGetCategoriesQuery } from "../redux/api/categoryApiSlice";
import { useLazyGetSubCategoryCatalogQuery } from "../redux/api/categoryCatalogApiSlice";
import { globalDateFormat, localDateFormat } from "../utils/dateFormat";
import IconBreadcrumbs from "./custom/IconBreadcrumbs";
import Loader from "./custom/Loader";

const ApplicationsAgreement = ({
  isLoadingUpdate,
  data,
  breadcrumbs,
  handleSubmit,
  date,
  setDate,
  trackReg,
  setTrackReg,
  category_id,
  setCategoryId,
  subCategory,
  setSubCategory,
  commonCount,
}) => {
  /// copyright application length
  const { data: copyright } = useGetApplicationCopyrightQuery({
    date: "",
    trackReg: "",
    category_id: "",
    subCategory: "",
    page: "",
  });
  /// agreement application length
  const { data: agreement } = useGetApplicationAgreementQuery({
    date: "",
    trackReg: "",
    category_id: "",
    subCategory: "",
    page: "",
  });

  const { data: roleByUser, isLoading } = useGetByRoleQuery(8);
  const [selectedUsers, setSelectedUsers] = useState({});

  const location = useLocation();
  // category
  const { data: categoryData, isLoading: isLoadingCategory } =
    useGetCategoriesQuery();
  const [fetchSubCategory, { data: subCategoryData }] =
    useLazyGetSubCategoryCatalogQuery();

  // fetch sub category
  useEffect(() => {
    if (category_id) {
      fetchSubCategory({ parent_id: category_id });
    }
  }, [category_id]);

  if (isLoading || isLoadingCategory) return <Loader />;

  const user = Object.entries(roleByUser?.data).map(([key, value]) => ({
    value: key,
    label: value,
  }));

  const handleSelectChange = (itemId, userId) => {
    setSelectedUsers((prevState) => ({
      ...prevState,
      [itemId]: userId,
    }));
  };

  const handleFormSubmit = () => {
    const distributions = data
      .map((item) => {
        if (selectedUsers[item.id]) {
          return {
            id: item.id,
            user_id: selectedUsers[item.id],
          };
        }
        return null;
      })
      .filter((item) => item !== null);

    const submissionData = { distributions };
    if (submissionData?.distributions?.length > 0) {
      handleSubmit(submissionData);
    }
  };

  const categoryOption = categoryData?.data?.map((data) => ({
    value: data.id,
    label: data.name,
  }));

  const subCategoryOption = subCategoryData?.data?.map((data) => ({
    value: data.id,
    label: data.name,
  }));

  const handleDateChange = (d) => {
    setDate(globalDateFormat(d));
  };

  const handleReset = () => {
    setDate("");
    setCategoryId("");
    setSubCategory("");
    setTrackReg("");
  };

  const applicationPath =
    location.pathname === "/admin/distribution/applications"
      ? "/admin/distribution/applications"
      : "/admin/distribution/agreement";

  return (
    <>
      <div className="py-3">
        <IconBreadcrumbs breadcrumbs={breadcrumbs} />
      </div>
      {commonCount?.length > 0 && (
        <div className="slideUp dashboard-layout  p-3 mb-3">
          <div className="row">
            {commonCount?.map((item, index) => (
              <div key={index} className="col-md-3">
                <div className="border px-3 py-2 rounded d-flex align-content-center justify-content-between">
                  <Typography fontWeight={"bold"}>{item.name}</Typography>
                  <div>
                    <Badge variant="primary">{item.stored_file_count}</Badge>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
      <div className="slideUp dashboard-layout p-3">
        <div
          style={{
            backgroundColor: "rgba(0, 0, 0, 0.1)",
          }}
          className="rounded mb-3 p-2"
        >
          <div className="row gap-2 gap-md-0">
            <div className="col-md-2 m-0">
              <BanglaCalender
                placeholder={"তারিখ"}
                value={date}
                onChange={handleDateChange}
              />
            </div>
            <div className="col-md-2 m-0">
              <input
                type="text"
                className="form-control"
                placeholder="আবেদন নং"
                value={trackReg}
                onChange={(e) => setTrackReg(e.target.value)}
              />
            </div>
            <div className="col-md-3 m-0">
              <Select
                options={categoryOption}
                value={
                  category_id
                    ? categoryOption.find(
                        (option) => option.value === category_id
                      )
                    : { value: "", label: "--- বিভাগ ---", isDisabled: true }
                }
                onChange={(selectedOption) => {
                  setSubCategory("");
                  setCategoryId(selectedOption.value);
                }}
                classNamePrefix="react-select"
              />
            </div>
            <div className="col-md-3 m-0">
              <Select
                options={subCategoryOption}
                value={
                  subCategory
                    ? subCategoryOption.find(
                        (option) => option.value === subCategory
                      )
                    : { value: "", label: "--- উপবিভাগ ---", isDisabled: true }
                }
                onChange={(selectedOption) =>
                  setSubCategory(selectedOption.value)
                }
                classNamePrefix="react-select"
              />
            </div>
            <div className="col-md-2 m-0">
              <div className=" d-flex justify-content-end">
                <Button
                  onClick={handleReset}
                  variant="danger"
                  className="w-100 text-center"
                >
                  <div className="d-flex align-items-center justify-content-center">
                    {" "}
                    <RestartAlt /> Reset
                  </div>
                </Button>
              </div>
            </div>
          </div>
        </div>
        <div
          className="d-flex gap-3 mb-3 rounded"
          style={{
            backgroundColor: "rgba(0, 0, 0, 0.1)",
          }}
        >
          <Link to={"/admin/distribution/applications"}>
            <Typography
              fontWeight="bold"
              className={`${
                applicationPath === "/admin/distribution/applications" &&
                "text-dark  bg-success bg-opacity-10"
              } p-2  d-flex align-items-center gap-2`}
            >
              <Copyright /> কপিরাইট{" "}
              <div>
                <Badge>{copyright?.data?.length}</Badge>
              </div>
            </Typography>
          </Link>
          <Link to={"/admin/distribution/agreement"}>
            <Typography
              fontWeight="bold"
              className={`${
                applicationPath === "/admin/distribution/agreement" &&
                "text-dark  bg-success bg-opacity-10"
              } p-2 d-flex align-items-center gap-2`}
            >
              <Handshake /> চুক্তিপত্র{" "}
              <div>
                <Badge>{agreement?.data?.length}</Badge>
              </div>
            </Typography>
          </Link>
        </div>
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>তারিখ</th>
              <th>আবেদন নং</th>
              <th>বিভাগ</th>
              <th>উপবিভাগ</th>
              <th>কর্মের নাম</th>
              <th>পরিদর্শক</th>
            </tr>
          </thead>
          <tbody>
            {data.length > 0 ? (
              <>
                {data?.map((item, i) => (
                  <tr key={i}>
                    <td>{localDateFormat(item?.sendDate)}</td>
                    <td>{item?.trackReg}</td>
                    <td>
                      {item?.profileDetail
                        ? item?.profileDetail?.category?.name
                        : item?.document?.category?.name}
                    </td>
                    <td>
                      {item?.profileDetail
                        ? item?.profileDetail?.subCategory?.name
                        : item?.document?.subCategory?.name}
                    </td>
                    <td>
                      {item?.profileDetail
                        ? item?.profileDetail?.workTitle
                        : item?.document?.workTitle}
                    </td>
                    <td>
                      <select
                        className="form-select"
                        aria-label="Default select example"
                        name="user_id"
                        value={selectedUsers[item.id] || ""}
                        onChange={(e) =>
                          handleSelectChange(item.id, e.target.value)
                        }
                      >
                        <option value="">নির্বাচন করুন</option>
                        {user.map((user) => (
                          <option key={user.value} value={user.value}>
                            {user.label}
                          </option>
                        ))}
                      </select>
                    </td>
                  </tr>
                ))}
              </>
            ) : (
              <>
                <tr>
                  <td colspan="7" className="text-center">
                    No data found
                  </td>
                </tr>
              </>
            )}
          </tbody>
        </Table>
      </div>
      <div
        style={{
          position: "fixed",
          bottom: "30px",
          right: "15px",
          zIndex: "1000",
          backgroundColor: "rgba(0, 0, 0, 0.1)",
          borderRadius: "10px",
          padding: "10px",
        }}
      >
        <div className="text-end">
          <button
            className="btn btn-primary px-4"
            disabled={isLoadingUpdate}
            onClick={handleFormSubmit}
          >
            {isLoadingUpdate ? <BeatLoader color="#fff" /> : "দাখিল করুন"}
          </button>
        </div>
      </div>
    </>
  );
};

export default ApplicationsAgreement;
