import * as yup from "yup";

export const initializeValues = {
  description: "",
  type: "",
  status: "",
  sorting_index: "",
  document: "",
  category_id: "",
};

export const validationSchema = yup.object().shape({
  description: yup.string().required("বর্ণনা প্রযোজ্য।"),
  type: yup.string().required("টাইপ প্রযোজ্য।"),
  sorting_index: yup.string().required("ইনডেক্স  প্রযোজ্য।"),
  status: yup.string().required("স্ট্যাটাস প্রযোজ্য।"),
  document: yup.mixed().when(["type", "id"], {
    is: (type, id) => Number(type) === 2 && !id,
    then: () =>
      yup
        .mixed()
        .required("ফাইল দেওয়া প্রযোজ্য")
        .test("fileFormat", "অবাঞ্ছিত ফাইল ফরম্যাট", (value) => {
          return (
            value &&
            [
              "image/jpeg",
              "image/png",
              "image/jpg",
              "application/pdf",
            ].includes(value.type)
          );
        }),
    otherwise: () => yup.mixed().nullable(),
  }),
});
