import React, { useEffect } from "react";
import {
  useCertificateAttentionUpdateMutation,
  useGetAttentionMessagesQuery,
} from "../../redux/api/submittedCopyrightAgreementApiSlice";
import * as Yup from "yup";
import Swal from "sweetalert2";
import { useFormik } from "formik";
import { Button, Form } from "react-bootstrap";
import { BeatLoader } from "react-spinners";
import toast from "react-hot-toast";

const AttentionMessage = ({ data, refetch, onHide }) => {
  const { data: attentionMessage } = useGetAttentionMessagesQuery();
  const [
    submitData,
    {
      isLoading: isLoadingSubmit,
      isSuccess: isSuccessSubmit,
      error: errorSubmit,
      isError: isErrorSubmit,
      data: dataSubmit,
    },
  ] = useCertificateAttentionUpdateMutation();
  const id = data?.id;
  const ac = data?.archive;

  const initialValues = {
    archive_id: ac?.id,
    attention: ac?.attention || [],
  };

  const validationSchema = Yup.object().shape({
    attention: Yup.array().nullable(),
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      Swal.fire({
        text: "আপনি কি নিশ্চিত?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "না",
        confirmButtonText: "হ্যাঁ",
      }).then((result) => {
        if (result.isConfirmed) {
          submitData({ id, formData: values });
        }
      });
    },
    enableReinitialize: true,
  });

  useEffect(() => {
    if (isSuccessSubmit) {
      toast.success(dataSubmit?.message);
      formik.resetForm();
      refetch();
      onHide();
    }
    if (isErrorSubmit) {
      toast.error(errorSubmit?.data?.message);
    }
    // eslint-disable-next-line
  }, [isErrorSubmit, isSuccessSubmit]);

  return (
    <div>
      <div
        className="rounded mb-4"
        style={{
          background: "#f4f4f4",
          padding: "10px",
        }}
      >
        <label className="pb-1">এটেনশন বার্তা নির্বাচন করুনঃ</label>
        {attentionMessage?.data.map((item, i) => (
          <Form.Check
            key={i}
            type={"checkbox"}
            label={`${item.title}`}
            value={item.title}
            id={`default-${item.title}`}
            onChange={(e) => {
              const { checked, value } = e.target;
              if (checked) {
                formik.setValues((prev) => ({
                  ...prev,
                  attention: [...prev.attention, value],
                }));
              } else {
                formik.setValues((prev) => ({
                  ...prev,
                  attention: prev.attention.filter((att) => att !== value),
                }));
              }
            }}
            checked={formik.values.attention.includes(item.title)}
          />
        ))}
      </div>
      <div className=" mt-4 text-center">
        <Button
          type="submit"
          className="btn btn-primary"
          onClick={formik.handleSubmit}
          disabled={isLoadingSubmit}
        >
          {isLoadingSubmit ? <BeatLoader /> : "সম্পাদন করুন"}
        </Button>
      </div>
    </div>
  );
};

export default AttentionMessage;
