import {
  CATEGORY_CATALOG,
  CATEGORY_CATALOG_UPDATE,
  COPYRIGHT_RELATEDRIGHTS,
  OTHER_SUB_CATEGORY,
  UPDATE_SORTING_CATALOG,
} from "../constants";
import { apiSlice } from "./apiSlice";

const categoryCatalogApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getCategoryCatalog: builder.query({
      query: () => CATEGORY_CATALOG + "?parent_id=0&status=1&per_page=50",
      providesTags: ["categories_catalog"],
    }),
    getSubCategoryCatalog: builder.query({
      query: ({ parent_id = 0 }) => {
        let url =
          CATEGORY_CATALOG + `?parent_id=${parent_id}&status=1&per_page=100`;
        return {
          url: url,
          method: "GET",
        };
      },
      providesTags: ["categories_catalog"],
    }),
    updateCategoryCatalog: builder.mutation({
      query: ({ formData, id }) => ({
        url: CATEGORY_CATALOG + "/" + id,
        method: "POST",
        body: formData,
      }),
      invalidatesTags: ["categories_catalog"],
    }),
    createCategoryCatalog: builder.mutation({
      query: (formData) => ({
        url: CATEGORY_CATALOG,
        method: "POST",
        body: formData,
      }),
      invalidatesTags: ["categories_catalog"],
    }),

    categoriesUpdateCatalog: builder.mutation({
      query: (formData) => ({
        url: CATEGORY_CATALOG_UPDATE,
        method: "post",
        body: formData,
      }),
      invalidatesTags: ["categories_catalog", "categories"],
    }),
    // get copyright
    getCopyright: builder.query({
      query: () => COPYRIGHT_RELATEDRIGHTS + "?type=1",
      providesTags: "copyright",
    }),
    // get related rights
    getRelatedRight: builder.query({
      query: () => COPYRIGHT_RELATEDRIGHTS + "?type=2",
      providesTags: "relatedRight",
    }),
    updateSortingCatalog: builder.mutation({
      query: (formData) => ({
        url: UPDATE_SORTING_CATALOG,
        method: "POST",
        body: formData,
      }),
      invalidatesTags: ["relatedRight", "copyright"],
    }),
    getOtherSubCategory: builder.query({
      query: () => OTHER_SUB_CATEGORY,
    }),
  }),
});

export const {
  useGetCategoryCatalogQuery,
  useLazyGetSubCategoryCatalogQuery,
  useUpdateCategoryCatalogMutation,
  useCreateCategoryCatalogMutation,
  useCategoriesUpdateCatalogMutation,
  useGetCopyrightQuery,
  useGetRelatedRightQuery,
  useUpdateSortingCatalogMutation,
  useGetOtherSubCategoryQuery,
} = categoryCatalogApiSlice;
