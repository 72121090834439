import React from "react";
import SubmittedCopyright from "../../sumitted_copyright/SubmittedCopyright";

const ReplyExplanationCopyright = () => {
  const applicationPath = {
    copyright: "/admin/reply-to-additional-explanation-copyright",
    agreement: "/admin/reply-to-additional-explanation-agreement",
  };
  const pageTitle = "ব্যাখ্যার জবাব";
  
  return ( 
    <SubmittedCopyright fileStatus={18} applicationPath={applicationPath} pageTitle={pageTitle}   />
  );
};

export default ReplyExplanationCopyright;
