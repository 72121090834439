import { Home, NoTransfer } from "@mui/icons-material";
import { Box } from "@mui/material";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import Select from "react-select";
import { BeatLoader } from "react-spinners";
import Swal from "sweetalert2";
import * as Yup from "yup";
import IconBreadcrumbs from "../../components/custom/IconBreadcrumbs";
import Loader from "../../components/custom/Loader";
import { useAuthorized } from "../../hooks/useAuthorized";
import { useLazyGetByRoleQuery } from "../../redux/api/applicationCopyrightApiSlice";
import {
  useLazyGetFileStatusGroupQuery,
  useUserToUserFileTransferMutation,
} from "../../redux/api/reportApiSlice";
import { convertToBanglaNumerals } from "../../utils/convertToBanglaNumerals";

const AllApplicationTransfer = () => {
  const { user: authUser } = useAuthorized();
  const user = useSelector((state) => state.auth.userInfo);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  const [findUser, { data: users }] = useLazyGetByRoleQuery();

  const usersOption =
    (users?.data &&
      Object.entries(users.data)
        .filter(([key]) => user.id !== Number(key))
        .map(([key, value]) => ({
          value: Number(key),
          label: value,
        }))) ||
    [];

  const breadcrumbsData = [
    { icon: Home, title: "হোম", url: "/" },
    {
      icon: NoTransfer,
      title: `নথি স্থানান্তর`,
      active: true,
    },
  ];
  const [fetchFileStatus, { data, isLoading }] =
    useLazyGetFileStatusGroupQuery();

  useEffect(() => {
    if (user?.id) {
      fetchFileStatus(user?.id);
    }
    if (user?.role_id) {
      findUser(user?.role_id);
    }
  }, [user?.id]);

  const totalNothi = data?.data?.reduce((acc, item) => acc + item?.total, 0);

  const [fileTransfer, { isLoading: isLoadingTransfer }] =
    useUserToUserFileTransferMutation();
  const formik = useFormik({
    initialValues: {
      role_id: user.role_id,
      user_id: "",
    },
    validationSchema: Yup.object({
      user_id: Yup.number().required("ইউজার নির্বাচন করুন"),
    }),
    // TODO: handle form submission
    onSubmit: (values) => {
      console.log(values);
      // TODO: handle form submission
      Swal.fire({
        text: "আপনি কি নথি স্থানান্তর করতে চান?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "না",
        confirmButtonText: "হ্যাঁ",
      }).then((result) => {
        if (result.isConfirmed) {
          fileTransfer(values)
            .unwrap()
            .then((res) => {
              setIsButtonDisabled(true);
              toast.success(res?.message);

              Swal.fire({
                title: "অনুগ্রহ করে অপেক্ষা করুন",
                text: "নথি স্থিতি আপডেট হচ্ছে, অনুগ্রহ করে ১০ সেকেন্ড অপেক্ষা করুন...",
                timer: 10000,
                timerProgressBar: true,
                showConfirmButton: false,
                willClose: () => {
                  fetchFileStatus(user?.id); // Fetch file status after delay
                  setIsButtonDisabled(false); // Re-enable the button
                },
              });
            })
            .catch((err) => {
              toast.error("নথি স্থানান্তর করতে ব্যর্থ হয়েছে");
            });
        }
      });
    },
  });

  if (isLoading) {
    return <Loader />;
  }

  const defaultValue = {
    value: "",
    label: "নির্বাচন করুন",
  };

  return (
    <div>
      <div>
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
          py={1}
          mb={1}
        >
          <IconBreadcrumbs breadcrumbs={breadcrumbsData} />
        </Box>
        <div className="slideUp dashboard-layout p-4">
          <div className="py-2 row">
            <div className="col-md-4">
              <div className="card h-100">
                <div className=" bg-slate py-2 px-3 fw-semibold">
                  নথি স্থানান্তর{" "}
                </div>

                <div className="row p-3">
                  <div className="col-md-12">
                    <label className="fw-semibold" htmlFor="user_id">
                      প্রাপক
                    </label>
                    <Select
                      name="user_id"
                      options={usersOption}
                      value={
                        formik.values.user_id
                          ? usersOption.find(
                              (option) => option.value === formik.values.user_id
                            )
                          : defaultValue
                      }
                      onChange={(selectedOption) =>
                        formik.setFieldValue("user_id", selectedOption.value)
                      }
                      onBlur={formik.handleBlur}
                      classNamePrefix="react-select"
                    />
                    {formik.touched.user_id && formik.errors.user_id ? (
                      <div className="text-danger mt-2">
                        {formik.errors.user_id}
                      </div>
                    ) : null}
                  </div>
                  <div className="col-md-12">
                    <label className="mt-3 fw-semibold" htmlFor="user_id">
                      {authUser?.data?.role?.bn_name}
                    </label>
                  </div>
                  <div className="col-md-12 mt-3">
                    <button
                      type="submit"
                      className="btn btn-primary w-100"
                      disabled={
                        isLoadingTransfer || isButtonDisabled || !totalNothi
                      }
                      onClick={formik.handleSubmit}
                    >
                      {isLoadingTransfer ? <BeatLoader /> : "নথি পাঠান"}
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-8">
              <div className="card h-100">
                <div className="bg-slate  py-2 px-3 fw-semibold">
                  মোট নথিঃ {convertToBanglaNumerals(totalNothi)}
                </div>
                <div className="p-3">
                  <div className="row pt-2">
                    {data?.data?.length > 0 ? (
                      data?.data?.map((item, i) => (
                        <div className="col-md-4" key={i}>
                          <div className="card mb-3">
                            <span className="d-flex justify-content-between justify-items-center">
                              <label className="mx-2 p-2">
                                {item?.statusName}
                              </label>
                              <span className="border-start py-2 px-3 fw-semibold">
                                {convertToBanglaNumerals(item?.total)}
                              </span>
                            </span>
                          </div>
                        </div>
                      ))
                    ) : (
                      <label>No data found</label>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AllApplicationTransfer;
