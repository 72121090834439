import React from "react";
import EndorseFile from "../../../../components/certificate-endorse-file/EndorseFile";

const EndorseAgreement = () => {
  const fileStatus = 8;
  const applicationPath = {
    copyright: "/admin/certificates-copyright",
    agreement: "/admin/endorse-agreement",
  };
  const detailsRoute = "/admin/print-agreement-endorse";
  const pageTitle = "এন্ডোর্স";
  return (
    <EndorseFile
      fileStatus={fileStatus}
      applicationPath={applicationPath}
      detailsRoute={detailsRoute}
      pageTitle={pageTitle}
    />
  );
};

export default EndorseAgreement;
