import {
  AGREEMENT_FIRST_PART_CORRECTION_SHOW,
  AGREEMENT_FIRST_PART_CORRECTION_UPDATE,
  AGREEMENT_SECOND_PART_CORRECTION_SHOW,
  AGREEMENT_SECOND_PART_CORRECTION_UPDATE,
  APPLICATION_FIRST_PART_CORRECTION_SHOW,
  APPLICATION_FIRST_PART_CORRECTION_UPDATE,
  APPLICATION_SECOND_PART_CORRECTION_SHOW,
  APPLICATION_SECOND_PART_CORRECTION_UPDATE,
  CORRECTION_SHOW,
  PAYMENT_CORRECTION_SHOW,
  TREASURY_PAYMENT_CORRECTION,
} from "../constants";
import { apiSlice } from "./apiSlice";

const correctionApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    // CORRECTION_SHOW
    showCorrection: builder.query({
      query: ({ id, trackReg }) =>
        CORRECTION_SHOW + `/${id}?trackReg=${trackReg}`,
      providesTags: ["show_correction"],
    }),

    // ========================
    // copyright application
    // ========================

    // APPLICATION_FIRST_PART_CORRECTION_SHOW
    showCorrectionCopyrightFirstPart: builder.query({
      query: ({ id, trackReg }) =>
        APPLICATION_FIRST_PART_CORRECTION_SHOW + `/${id}?trackReg=${trackReg}`,
      providesTags: ["show_correction_copyright_first_part"],
    }),
    // APPLICATION_FIRST_PART_CORRECTION_UPDATE
    updateCorrectionCopyrightFirstPart: builder.mutation({
      query: ({ formData, id }) => ({
        url: APPLICATION_FIRST_PART_CORRECTION_UPDATE + "/" + id,
        method: "POST",
        body: formData,
      }),
      invalidatesTags: ["show_correction_copyright_first_part"],
    }),
    // APPLICATION_SECOND_PART_CORRECTION_SHOW
    showCorrectionCopyrightSecondPart: builder.query({
      query: ({ id, trackReg }) =>
        `${APPLICATION_SECOND_PART_CORRECTION_SHOW}/${id}?trackReg=${trackReg}`,
      providesTags: ["show_correction_copyright_second_part"],
    }),
    // APPLICATION_FIRST_PART_CORRECTION_UPDATE
    updateCorrectionCopyrightSecondPart: builder.mutation({
      query: ({ formData, id }) => ({
        url: APPLICATION_SECOND_PART_CORRECTION_UPDATE + "/" + id,
        method: "POST",
        body: formData,
      }),
      invalidatesTags: ["show_correction_copyright_second_part"],
    }),

    // ========================
    // agreement application
    // ========================
    // first part
    showCorrectionAgreementFirstPart: builder.query({
      query: ({ id, trackReg }) =>
        `${AGREEMENT_FIRST_PART_CORRECTION_SHOW}/${id}?trackReg=${trackReg}`,
      providesTags: ["show_correction_agreement_first_part"],
    }),
    updateCorrectionAgreementFirstPart: builder.mutation({
      query: ({ formData, id }) => ({
        url: `${AGREEMENT_FIRST_PART_CORRECTION_UPDATE}/${id}`,
        method: "POST",
        body: formData,
      }),
      invalidatesTags: ["show_correction_agreement_first_part"],
    }),

    // second part
    showCorrectionAgreementSecondPart: builder.query({
      query: ({ id, trackReg }) =>
        `${AGREEMENT_SECOND_PART_CORRECTION_SHOW}/${id}?trackReg=${trackReg}`,
      providesTags: ["show_correction_agreement_second_part"],
    }),
    updateCorrectionAgreementSecondPart: builder.mutation({
      query: ({ formData, id }) => ({
        url: `${AGREEMENT_SECOND_PART_CORRECTION_UPDATE}/${id}`,
        method: "POST",
        body: formData,
      }),
      invalidatesTags: ["show_correction_agreement_second_part"],
    }),

    // ========================
    // payment data show
    // ========================

    // PAYMENT_CORRECTION_SHOW
    showCorrectionPayment: builder.query({
      query: ({ id, trackReg }) =>
        `${PAYMENT_CORRECTION_SHOW}/${id}?trackReg=${trackReg}`,
      providesTags: ["show_correction_payment"],
    }),
    // TREASURY_PAYMENT_CORRECTION
    updateCorrectionPayment: builder.mutation({
      query: ({ formData, id }) => ({
        url: `${TREASURY_PAYMENT_CORRECTION}/${id}`,
        method: "POST",
        body: formData,
      }),
      invalidatesTags: ["show_correction_payment"],
    }),
  }),
});

export const {
  useShowCorrectionQuery,
  // COPYRIGHT
  useUpdateCorrectionCopyrightFirstPartMutation,
  useShowCorrectionCopyrightFirstPartQuery,
  useUpdateCorrectionCopyrightSecondPartMutation,
  useShowCorrectionCopyrightSecondPartQuery,
  // AGREEMENT
  useUpdateCorrectionAgreementFirstPartMutation,
  useShowCorrectionAgreementFirstPartQuery,
  useUpdateCorrectionAgreementSecondPartMutation,
  useShowCorrectionAgreementSecondPartQuery,
  // PAYMENT
  useShowCorrectionPaymentQuery,
  useUpdateCorrectionPaymentMutation,
} = correctionApiSlice;
