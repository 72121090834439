export const convertToBanglaNumerals = (input) => {
  const englishToBanglaMap = {
    0: "০",
    1: "১",
    2: "২",
    3: "৩",
    4: "৪",
    5: "৫",
    6: "৬",
    7: "৭",
    8: "৮",
    9: "৯",
  };

  // Ensure the input is a string
  const str = String(input);

  return str
    .split("")
    .map((char) =>
      englishToBanglaMap[char] !== undefined ? englishToBanglaMap[char] : char
    )
    .join("");
};
