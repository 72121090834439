import React, { useCallback, useState, useEffect } from "react";
import { Box, Tooltip } from "@mui/material";
import debounce from "lodash/debounce";
import ReusableTable from "../../../components/custom/data_table/ReusableTable";
import { Badge, Button } from "react-bootstrap";
import Loader from "../../../components/custom/Loader";
import { Link } from "react-router-dom";
import { AddCircle, Home, People } from "@mui/icons-material";
import IconBreadcrumbs from "../../../components/custom/IconBreadcrumbs";
import { assets } from "../../../assets";
import AddUpdateBranches from "./AddUpdateBranches";
import { convertToBanglaNumerals } from "../../../utils/convertToBanglaNumerals";
import { useGetBankBranchesListQuery } from "../../../redux/api/bankBranchApiSlice";
import { useGetBankQuery } from "../../../redux/api/applicationPaymentProcessApiSlice";

const Branches = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [searchText, setSearchText] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const [currentBank, setCurrentBank] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [editData, setEditData] = useState(null);

  const {
    data: fetchData,
    isLoading,
    refetch,
  } = useGetBankBranchesListQuery({
    page: currentPage,
    bank_id: currentBank,
    name: searchValue,
  });
  const { data: fetchBank, isLoading: isLoadingBank } = useGetBankQuery();

  const totalData = fetchData?.meta?.total;
  const lastPage = fetchData?.meta?.last_page;

  const debouncedSearch = useCallback(
    debounce((value) => {
      setCurrentPage(1);
      setSearchValue(value);
      refetch();
    }, 400),
    []
  );

  useEffect(() => {
    // Cleanup function to cancel the debounce when the component unmounts or debouncedSearch changes
    return () => {
      debouncedSearch?.cancel();
    };
  }, [debouncedSearch]);

  const handleSearchChange = (value) => {
    setSearchText(value);
    debouncedSearch(value);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
    refetch();
  };

  const columns = [
    { label: "ক্রমিক নং", name: "si" },

    { label: "ব্যাংকের নাম", name: "bank" },
    { label: "শাখার নাম", name: "branch" },
    {
      label: "স্ট্যাটাস",
      name: "status",
      options: {
        customBodyRender: (value) => (
          <Badge bg={value === 1 ? "success" : "danger"}>
            {value === 1 ? "Active" : "In-active"}
          </Badge>
        ),
      },
    },

    {
      label: "ক্রিয়াকলাপ",
      name: "action",
      options: {
        customBodyRender: (value, tableMeta) => (
          <Box display={"flex"} gap={1}>
            <Tooltip title="Edit" placement="right" arrow>
              <Badge
                bg="warning"
                className="p-2"
                onClick={() => {
                  setEditData(value);
                  setShowModal(true);
                }}
                style={{ cursor: "pointer" }}
              >
                Edit
              </Badge>
            </Tooltip>
          </Box>
        ),
      },
    },
  ];

  const data = fetchData?.data.map((item, i) => ({
    si: convertToBanglaNumerals((currentPage - 1) * 15 + i + 1),
    id: item.id,
    bank: item?.bank?.name,
    branch: item?.name,
    status: item?.status,
    action: {
      id: item.id,
      bank_id: item?.bank?.id,
      name: item?.name,
      status: item?.status,
    },
  }));

  const breadcrumbsData = [
    { icon: Home, title: "হোম", url: "/" },
    {
      icon: People,
      title: "ব্যাংক ও শাখাসমূহের তালিকাঃ",
      url: "/admin/branches",
      active: true,
    },
  ];

  const handleBanckChange = (e) => {
    setCurrentBank(e.target.value);
    setCurrentPage(1);
    setSearchValue("");
  };

  if (isLoading || isLoadingBank) {
    return <Loader />;
  }

  return (
    <div>
      <Box
        display={"flex"}
        justifyContent={"space-between"}
        alignItems={"center"}
        py={1}
        mb={1}
      >
        <IconBreadcrumbs breadcrumbs={breadcrumbsData} />
        <Button
          variant="outline-secondary"
          onClick={() => {
            setEditData(null);
            setShowModal(true);
          }}
        >
          <AddCircle />
          শাখা যোগ করুন
        </Button>
      </Box>
      <div className="slideUp dashboard-layout p-4">
        <div className="row">
          <div className="col-md-4">
            <div className="pb-3">
              <select className="form-control" onChange={handleBanckChange}>
                <option value="">ব্যাংক নির্বাচন করুন</option>
                {fetchBank?.data.map((item) => (
                  <option key={item.id} value={item.id}>
                    {item?.name}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>
        <ReusableTable
          title={"ব্যাংক ও শাখাসমূহের নাম"}
          columns={columns}
          data={data}
          isLoading={isLoading}
          currentPage={currentPage}
          lastPage={lastPage}
          totalData={totalData}
          onSearchChange={handleSearchChange}
          searchText={searchText}
          onPageChange={handlePageChange}
        />
      </div>
      <AddUpdateBranches
        data={editData}
        show={showModal}
        refetch={refetch}
        bank={fetchBank?.data}
        onHide={() => {
          setShowModal(false);
          setEditData(null);
        }}
      />
    </div>
  );
};

export default Branches;
