import React, { useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Button, Form } from "react-bootstrap";
import toast from "react-hot-toast";
import { BeatLoader } from "react-spinners";
import Loader from "./custom/Loader";
import FormikFormField from "./custom/form/FormikFormField";
import {
  useShowCopyrightWebsiteSettingQuery,
  useUpdateCopyrightWebsiteSettingMutation,
  useShowContractWebsiteSettingQuery,
  useUpdateContractWebsiteSettingMutation,
} from "../redux/api/websiteSettingsApiSlice";
import { transformErrorsToObjectStructure } from "../utils/transformErrorsToObjectStructure";

const WebsiteSettings = () => {
  const { data: copyrightData, isLoading: isLoadingCopyright } =
    useShowCopyrightWebsiteSettingQuery();
  const { data: contractData, isLoading: isLoadingContract } =
    useShowContractWebsiteSettingQuery();

  const [
    updateCopyrightWebsiteSettings,
    {
      isLoading: isLoadingUpdateCopyright,
      isError: isErrorUpdateCopyright,
      error: errorUpdateCopyright,
      isSuccess: isSuccessUpdateCopyright,
      data: dataUpdateCopyright,
    },
  ] = useUpdateCopyrightWebsiteSettingMutation();

  const [
    updateContractWebsiteSettings,
    {
      isLoading: isLoadingUpdateContract,
      isError: isErrorUpdateContract,
      error: errorUpdateContract,
      isSuccess: isSuccessUpdateContract,
      data: dataUpdateContract,
    },
  ] = useUpdateContractWebsiteSettingMutation();

  const initialValuesCopyright = {
    web_amount: "",
    other_amount: "",
    broadcasting_amount: "",
  };

  const initialValuesContract = {
    amount: "",
  };

  const validationSchemaCopyright = Yup.object().shape({
    web_amount: Yup.string().required("অ্যামাউন্ট প্রয়োজন"),
    other_amount: Yup.string().required("অ্যামাউন্ট প্রয়োজন"),
    broadcasting_amount: Yup.string().required("অ্যামাউন্ট প্রয়োজন"),
  });

  const validationSchemaContract = Yup.object().shape({
    amount: Yup.string().required("অ্যামাউন্ট প্রয়োজন"),
  });

  const formikCopyright = useFormik({
    initialValues: initialValuesCopyright,
    validationSchema: validationSchemaCopyright,
    onSubmit: async (values, { setSubmitting }) => {
      const formData = { ...values, _method: "PUT" };
      await updateCopyrightWebsiteSettings(formData);
      setSubmitting(false);
    },
  });

  const formikContract = useFormik({
    initialValues: initialValuesContract,
    validationSchema: validationSchemaContract,
    onSubmit: async (values, { setSubmitting }) => {
      const formData = { ...values, _method: "PUT" };
      await updateContractWebsiteSettings(formData);
      setSubmitting(false);
    },
  });

  useEffect(() => {
    if (copyrightData?.data) {
      formikCopyright.setValues(copyrightData.data);
    }
    if (contractData?.data) {
      formikContract.setValues(contractData.data);
    }
  }, [copyrightData?.data, contractData?.data]);

  useEffect(() => {
    if (isErrorUpdateCopyright) {
      formikCopyright.setErrors(
        transformErrorsToObjectStructure(errorUpdateCopyright?.data?.data || {})
      );
      toast.error(errorUpdateCopyright?.data?.message);
    }
    if (isSuccessUpdateCopyright) {
      toast.success(dataUpdateCopyright?.message);
    }
    if (isErrorUpdateContract) {
      formikContract.setErrors(
        transformErrorsToObjectStructure(errorUpdateContract?.data?.data || {})
      );
      toast.error(errorUpdateContract?.data?.message);
    }
    if (isSuccessUpdateContract) {
      toast.success(dataUpdateContract?.message);
    }
  }, [
    isErrorUpdateCopyright,
    isSuccessUpdateCopyright,
    isErrorUpdateContract,
    isSuccessUpdateContract,
  ]);

  if (isLoadingCopyright || isLoadingContract) {
    return <Loader />;
  }

  return (
    <div className="row p-2">
      <div className="col-md-6 col-12">
        <Form onSubmit={formikCopyright.handleSubmit}>
          <p className="bg-success py-2 text-center text-light font-weight-bold">
            কপিরাইট
          </p>
          <div className="form-group">
            <label>সম্প্রচার কর্ম</label>
            <FormikFormField
              placeholder="সম্প্রচার কর্ম"
              value={formikCopyright.values.broadcasting_amount}
              name="broadcasting_amount"
              errors={formikCopyright.errors}
              touched={formikCopyright.touched}
              handleChange={formikCopyright.handleChange}
              handleBlur={formikCopyright.handleBlur}
            />
          </div>
          <div className="form-group">
            <label>ওয়েবসাইট কর্ম</label>
            <FormikFormField
              placeholder="ওয়েবসাইট কর্ম"
              value={formikCopyright.values.web_amount}
              name="web_amount"
              errors={formikCopyright.errors}
              touched={formikCopyright.touched}
              handleChange={formikCopyright.handleChange}
              handleBlur={formikCopyright.handleBlur}
            />
          </div>
          <div className="form-group">
            <label>অন্যান্য সকল কর্ম</label>
            <FormikFormField
              placeholder="অন্যান্য সকল কর্ম"
              value={formikCopyright.values.other_amount}
              name="other_amount"
              errors={formikCopyright.errors}
              touched={formikCopyright.touched}
              handleChange={formikCopyright.handleChange}
              handleBlur={formikCopyright.handleBlur}
            />
          </div>
          <div className="text-center mb-2">
            <Button type="submit" disabled={isErrorUpdateCopyright}>
              {isErrorUpdateCopyright ? (
                <BeatLoader color="#fff" />
              ) : (
                "আপডেট করুন"
              )}
            </Button>
          </div>
        </Form>
      </div>
      <div className="col-md-6 col-12">
        <Form onSubmit={formikContract.handleSubmit}>
          <p className="bg-success py-2 text-center text-light font-weight-bold">
            চুক্তিপত্র
          </p>
          <div className="form-group">
            <label>চুক্তিপত্র</label>
            <FormikFormField
              placeholder="চুক্তিপত্র"
              value={formikContract.values.amount}
              name="amount"
              errors={formikContract.errors}
              touched={formikContract.touched}
              handleChange={formikContract.handleChange}
              handleBlur={formikContract.handleBlur}
            />
          </div>
          <div className="text-center mb-2">
            <Button type="submit" disabled={isErrorUpdateContract}>
              {isErrorUpdateContract ? (
                <BeatLoader color="#fff" />
              ) : (
                "আপডেট করুন"
              )}
            </Button>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default WebsiteSettings;
