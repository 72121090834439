import React from "react";
import SubmittedAgreement from "../sumitted_agreement/SubmittedAgreement";

const FinalApprovedAgreement = () => {
  const pageTitle = "চূড়ান্ত ভাবে অনুমোদিত আবেদন পত্র";
  const fileStatus = 5;
  const applicationPath = {
    copyright: "/admin/final-approved-copyright",
    agreement: "/admin/final-approved-agreement",
  };
  const detailsRoute = "/admin/final-approved-agreement-certificate-form";

  return (
    <SubmittedAgreement
      fileStatus={fileStatus}
      applicationPath={applicationPath}
      detailsRoute={detailsRoute}
      pageTitle={pageTitle}
    />
  );
};

export default FinalApprovedAgreement;
