import React from "react";
import CommonDetails from "../details/CommonDetails";
import CopyrightPreviewFormTwo from "../../../../components/preview_applications/copyright/CopyrightPreviewFormTwo";
import CopyrightPreviewFormOne from "../../../../components/preview_applications/copyright/CopyrightPreviewFormOne";
import Loader from "../../../../components/custom/Loader";
import { useGetSubmittedCopyrightDetailsQuery } from "../../../../redux/api/submittedCopyrightAgreementApiSlice";
import { CopyrightGenerateAttachArray } from "../../../../utils/CopyrightGenerateAttachArray";
import { useAuth } from "../../../../utils/authorization";
import { useParams } from "react-router-dom";
import AssistantExaminerCommonContent from "../../../../components/common-content/AssistantExaminerCommonContent";
import { transformCopyrightData } from "../../../../utils/transformCopyrightData";
import PaymentPreview from "../../../../components/preview_applications/payment/PaymentPreview";

const AssistantExaminerCopyrightDetails = () => {
  const { authUser } = useAuth();
  const { id } = useParams();
  const { data, isLoading } = useGetSubmittedCopyrightDetailsQuery(id, {
    refetchOnMountOrArgChange: true,
  });
  const details = data?.data?.profileDetail;
  // console.log(details);
  const forInfo = transformCopyrightData(data);

  if (isLoading) {
    return <Loader />;
  }
  const infoTable = CopyrightGenerateAttachArray(forInfo);

  const info = {
    id: data?.data?.id,
    fileStatus: Number(data?.data?.fileStatus),
    noteNumber: data?.data?.trackReg,
    regNumber: "",
    applicantName: details?.profileDetailType?.[0]?.name,
    workTitle: details?.workTitle,
    category: details?.category?.name,
    categoryData: details?.category,
    appType: Number(data?.data?.apptype),
    workLanguage: data?.data?.profileDetail?.workLanguage,
    workDescription: data?.data?.profileDetail?.workDescription,
    subCategory: details?.subCategory,
    amount: data?.data?.amount,
    challan: Number(data?.data?.st) === 3 ? "চালান" : "ট্রেজারি",
    challanNumber:
      Number(data?.data?.st) === 3
        ? data?.data?.echallanId
        : data?.data?.treasuryNo,
    treasuryDate: data?.data?.treasuryDate || new Date(),
    admin: data?.data?.admin,

    basicStatus: Number(data?.data?.basicStatus),
    treasuryStatus: Number(data?.data?.treasuryStatus),
  };

  const formOne = (
    <div className="pt-5">
      <CopyrightPreviewFormOne data={data?.data} />
    </div>
  );
  const formTwo = (
    <div className="pt-5">
      <CopyrightPreviewFormTwo data={details} />
    </div>
  );

  const comments =
    authUser?.id === 8
      ? data?.data?.comments.find((c) => Number(c.submit) === 2)
      : data?.data?.comments.length > 0 && data?.data?.comments[0];

  const commentsValue = data?.data?.comments.find(
    (c) => Number(c.submit) === 2
  );

  const noteSheetList =
    data?.data?.comments.filter((c) => Number(c.submit) === 1) ?? [];

  const content = (
    <AssistantExaminerCommonContent comments={commentsValue} info={info} />
  );

  const commentsNoteSheet = {
    noteSheetList,
    noteSheetValues: comments?.status,
  };

  const submittedTitle =
    authUser?.role_id === 2 ? "চূড়ান্ত নথি" : "সাম্প্রতিক নথি";

  const breadcrumbsTitle =
    info.fileStatus === 23
      ? "চিঠিসহ শুনানী গ্রহনের নথি"
      : info.fileStatus === 15
      ? "তদন্তের রিপোর্ট"
      : info.fileStatus === 18
      ? "ব্যাখ্যার জবাব"
      : submittedTitle;

  return (
    <div>
      <CommonDetails
        content={content}
        commentsNoteSheet={commentsNoteSheet}
        formOne={formOne}
        formTwo={formTwo}
        payment={
          <div className="p-4 mt-5">
            <PaymentPreview data={data?.data} />
          </div>
        }
        info={info}
        infoTable={infoTable}
        breadcrumbsTitle={breadcrumbsTitle}
      />
    </div>
  );
};

export default AssistantExaminerCopyrightDetails;
