import { apiSlice } from "./apiSlice";
import { REGISTRATION_SYSTEMS } from "../constants";

export const registrationSystemsApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getRegistrationSystems: builder.query({
      query: ({ searchValue = "", currentPage = 1, type = "" }) => {
        let url = `${REGISTRATION_SYSTEMS}?page=${currentPage}&per_page=10`;
        if (searchValue) {
          url += `&description=${searchValue}`;
        }
        if (type) {
          url += `&type=${type}`;
        }
        return {
          url: url,
          method: "GET",
        };
      },
      providesTags: ["registration_systems"],
    }),
    findRegistrationSystems: builder.query({
      query: (id) => REGISTRATION_SYSTEMS + "/" + id,
    }),
    crateRegistrationSystems: builder.mutation({
      query: (formData) => ({
        url: `${REGISTRATION_SYSTEMS}`,
        method: "POST",
        body: formData,
      }),
      invalidatesTags: ["registration_systems"],
    }),
    updateRegistrationSystems: builder.mutation({
      query: ({ formData, id }) => ({
        url: `${REGISTRATION_SYSTEMS}/${id}`,
        method: "POST",
        body: formData,
      }),
      invalidatesTags: ["registration_systems"],
    }),
    deleteRegistrationSystems: builder.mutation({
      query: (id) => ({
        url: REGISTRATION_SYSTEMS + "/" + id,
        method: "DELETE",
      }),
      invalidatesTags: ["registration_systems"],
    }),
  }),
});

export const {
  useGetRegistrationSystemsQuery,
  useFindRegistrationSystemsQuery,
  useCrateRegistrationSystemsMutation,
  useUpdateRegistrationSystemsMutation,
  useDeleteRegistrationSystemsMutation,
} = registrationSystemsApiSlice;
