import { Copyright, Handshake, NotificationsActive } from "@mui/icons-material";
import React from "react";
import { Button } from "react-bootstrap";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Loader from "../../../../components/custom/Loader";
import { useShowCorrectionQuery } from "../../../../redux/api/correctionFormApiSlice";

export const CorrectionForm = () => {
  const { id } = useParams();
  const location = useLocation();
  const url = new URLSearchParams(location.search);
  const trackReg = url.get("trackReg");
  const navigate = useNavigate();

  const { data, isLoading, isError } = useShowCorrectionQuery({ id, trackReg });
  const mainData = data?.data;

  const isCorrection = mainData?.isCorrection.split(",") || [];

  if (isError) {
    return navigate("/page-not-found");
  }

  if (isLoading) {
    return <Loader />;
  }

  return (
    <div className="d-flex justify-content-center mt-5">
      <div className="card w-50">
        <div className="card-header fw-bold">
          {Number(mainData?.apptype) === 1 ? (
            <>
              <Copyright /> কপিরাইট আবেদন পত্র সংশোধন
            </>
          ) : (
            <>
              <Handshake /> চুক্তিপত্র নিবন্ধন সংশোধন
            </>
          )}{" "}
        </div>

        <div className="card-body">
          <div className="mb-2">
            <strong>নামঃ </strong>{" "}
            {Number(mainData?.apptype) === 2
              ? mainData?.name
              : mainData?.pdDocDetail?.first_profiledetailtype?.name}{" "}
            <br />
            <strong>ই-মেইলঃ </strong>{" "}
            {Number(mainData?.apptype) === 2
              ? mainData?.email
              : mainData?.pdDocDetail?.first_profiledetailtype?.email}{" "}
            <br />
            <strong>ট্র্যাকিং নম্বরঃ </strong> {trackReg}
          </div>
          <div
            className="pt-1"
            style={{
              borderTop: "1px dashed #ccc",
            }}
          ></div>

          <div className="mt-2 mb-1">
            {(isCorrection.includes("1") || isCorrection.includes("4")) && (
              <Button
                className="me-3 px-4"
                size="sm"
                onClick={() => {
                  if (Number(mainData?.apptype) === 1) {
                    navigate(
                      `/correction-copyright-first-part/${mainData?.id}?trackReg=${mainData?.trackReg}`
                    );
                  } else {
                    navigate(
                      `/correction-agreement-first-part/${mainData?.id}?trackReg=${mainData?.trackReg}`
                    );
                  }
                }}
              >
                ১ম অংশ
              </Button>
            )}
            {(isCorrection.includes("2") || isCorrection.includes("4")) && (
              <Button
                className="me-3 px-4"
                variant="success"
                size="sm"
                onClick={() => {
                  if (Number(mainData?.apptype) === 1) {
                    navigate(
                      `/correction-copyright-second-part/${mainData?.id}?trackReg=${mainData?.trackReg}`
                    );
                  } else {
                    navigate(
                      `/correction-agreement-second-part/${mainData?.id}?trackReg=${mainData?.trackReg}`
                    );
                  }
                }}
              >
                ২য় অংশ
              </Button>
            )}
            {(isCorrection.includes("3") || isCorrection.includes("4")) && (
              <Button
                className="px-4"
                variant="warning"
                size="sm"
                onClick={() =>
                  navigate(
                    `/correction-payment/${mainData?.id}?trackReg=${mainData?.trackReg}`
                  )
                }
              >
                পেমেন্ট
              </Button>
            )}
          </div>
        </div>
        <div className="card-footer text-body-secondary d-flex gap-2">
          <NotificationsActive
            style={{
              fontSize: "16px",
              color: "#d90d0d",
            }}
          />
          <div
            style={{
              fontSize: "13px",
              fontStyle: "italic",
              color: "#d90d0d",
            }}
          >
            সংশোধনের জন্য আপনার ই-মেইলে পাঠানো বার্তা অনুযায়ী আপনার আবেদনপত্রটি
            সংশোধন করুন এবং আমাদের প্রেরণ করুন।
          </div>
        </div>
      </div>
    </div>
  );
};
