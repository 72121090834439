import * as Yup from "yup";

export const initialValues = {
  teamType: 1,
  applicant: "",
  nid: "",
  istructure: "",
  tradelicense: "",
  tincertificate: "",
  sign: "",
  place: "",
  puname: "",
};

export const validationSchema = Yup.object().shape({
  teamType: Yup.string().required("ধরন নির্বাচন করুন।"),
  nid: Yup.string().required("পরিচয় পত্র প্রযোজ্য ।"),
  istructure: Yup.string().required("সত্যায়িত কপি প্রযোজ্য।"),
  applicant: Yup.string().required("ছবি প্রযোজ্য।"),
  sign: Yup.string().required("স্বাক্ষর প্রযোজ্য।"),
  place: Yup.string().required("স্থান প্রযোজ্য।"),
  puname: Yup.string().required("নাম প্রযোজ্য।"),
});

export const validationSchemaEdit = Yup.object().shape({
  teamType: Yup.string().required("ধরন নির্বাচন করুন।"),
  place: Yup.string().required("স্থান প্রযোজ্য।"),
  puname: Yup.string().required("নাম প্রযোজ্য।"),
});
