import React from "react";
import SubmittedCopyright from "../../sumitted_copyright/SubmittedCopyright";

const RecordedCopyright = () => {
  const fileStatus = 27;
  const applicationPath = {
    copyright: "/admin/recorded-copyright",
    agreement: "/admin/recorded-agreement",
  };
  const detailsRoute = "/admin/recorded-copyright-details";
  const pageTitle  = "নথিজাত নথি";
  return (
    <SubmittedCopyright
      fileStatus={fileStatus}
      applicationPath={applicationPath}
      detailsRoute={detailsRoute}
      pageTitle={pageTitle}
    />
  );
};

export default RecordedCopyright;
