export const config = {
  readonly: false, // all options from https://xdsoft.net/jodit/doc/,
  placeholder: "লিখুন...",
  uploader: {
    insertImageAsBase64URI: true,
  },
  height: 250,
  removeButtons: [
    "fullsize",
    "about",
    "outdent",
    "indent",
    "video",
    "print",
    "superscript",
    "subscript",
    "file",
    "cut",
    "selectall",
  ],
};
