import React, { useEffect } from "react";
import {
  useShowWebsiteGeneralSettingQuery,
  useUpdateWebsiteGeneralSettingMutation,
} from "../redux/api/websiteSettingsApiSlice";
import FormikFormField from "./custom/form/FormikFormField";
import * as Yup from "yup";
import { useFormik } from "formik";
import { Button, Form } from "react-bootstrap";
import Loader from "./custom/Loader";
import toast from "react-hot-toast";
import { BeatLoader } from "react-spinners";
import { transformErrorsToObjectStructure } from "../utils/transformErrorsToObjectStructure";

const initialValues = {
  slogan: "",
  twitter: "",
  youtube: "",
  facebook: "",
  linkedin: "",
  metatags: "",
  googlemap: "",
  site_title: "",
  description: "",
  googleanalytics: "",
  metadescription: "",
};

const validationSchema = Yup.object().shape({
  slogan: Yup.string().required("স্লোগান প্রয়োজন"),
  googlemap: Yup.string().required("গুগল ম্যাপ প্রয়োজন"),
  site_title: Yup.string().required("সাইটের শিরোনাম প্রযোজ্য"),
});

const WebsiteGeneralSettings = () => {
  const { data, isLoading } = useShowWebsiteGeneralSettingQuery();

  const [
    UpdateWebsiteGeneralSettings,
    {
      isLoading: isLoadingUpdate,
      isError: isErrorUpdate,
      error: errorUpdate,
      isSuccess: isSuccessUpdate,
      data: dataUpdate,
    },
  ] = useUpdateWebsiteGeneralSettingMutation();

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values, { setSubmitting }) => {
      const formData = { ...values, _method: "PUT" };
      await UpdateWebsiteGeneralSettings(formData);
    },
  });

  const {
    values,
    touched,
    errors,
    handleBlur,
    handleChange,
    handleSubmit,
    setValues,
    setErrors,
    resetForm,
  } = formik;

  useEffect(() => {
    if (data?.data) {
      setValues(data.data);
    }
  }, [data?.data, setValues]);

  const handleServerErrors = (serverErrors) => {
    const errors = {};
    Object.keys(serverErrors).forEach((field) => {
      errors[field] = serverErrors[field].join(", ");
    });
    return errors;
  };

  useEffect(() => {
    if (isErrorUpdate) {
      setErrors(
        transformErrorsToObjectStructure(errorUpdate?.data?.data || {})
      );
      toast.error(errorUpdate?.data?.message);
    }
    if (isSuccessUpdate) {
      toast.success(dataUpdate?.message);
    }
  }, [isErrorUpdate, isSuccessUpdate]);

  if (isLoading) {
    return <Loader />;
  }

  return (
    <Form onSubmit={handleSubmit}>
      <div className="row p-2">
        <div className="col-md-4 col-12">
          <div className="form-group">
            <label>সাইটের শিরোনাম</label>
            <FormikFormField
              placeholder="সাইটের শিরোনাম"
              value={values.site_title}
              name="site_title"
              errors={errors}
              touched={touched}
              handleChange={handleChange}
              handleBlur={handleBlur}
            />
          </div>
        </div>
        <div className="col-md-4 col-12">
          <div className="form-group">
            <label>শ্লোগান</label>
            <FormikFormField
              placeholder="শ্লোগান"
              value={values.slogan}
              name="slogan"
              errors={errors}
              touched={touched}
              handleChange={handleChange}
              handleBlur={handleBlur}
            />
          </div>
        </div>
        <div className="col-md-4 col-12">
          <div className="form-group">
            <label>মেটাট্যাগ</label>
            <FormikFormField
              placeholder="মেটাট্যাগ"
              value={values.metatags}
              name="metatags"
              errors={errors}
              touched={touched}
              handleChange={handleChange}
              handleBlur={handleBlur}
            />
          </div>
        </div>
        <div className="col-md-4 col-12">
          <div className="form-group">
            <label>মেটা ডেসক্রিপশন</label>
            <FormikFormField
              placeholder="মেটা ডেসক্রিপশন"
              value={values.metadescription}
              name="metadescription"
              errors={errors}
              touched={touched}
              handleChange={handleChange}
              handleBlur={handleBlur}
            />
          </div>
        </div>
        <div className="col-md-4 col-12">
          <div className="form-group">
            <label>ফেসবুক</label>
            <FormikFormField
              placeholder="ফেসবুক"
              value={values.facebook}
              name="facebook"
              errors={errors}
              touched={touched}
              handleChange={handleChange}
              handleBlur={handleBlur}
            />
          </div>
        </div>
        <div className="col-md-4 col-12">
          <div className="form-group">
            <label>টুইটার</label>
            <FormikFormField
              placeholder="টুইটার"
              value={values.twitter}
              name="twitter"
              errors={errors}
              touched={touched}
              handleChange={handleChange}
              handleBlur={handleBlur}
            />
          </div>
        </div>
        <div className="col-md-4 col-12">
          <div className="form-group">
            <label>লিঙ্কডইন</label>
            <FormikFormField
              placeholder="লিঙ্কডইন"
              value={values.linkedin}
              name="linkedin"
              errors={errors}
              touched={touched}
              handleChange={handleChange}
              handleBlur={handleBlur}
            />
          </div>
        </div>
        <div className="col-md-4 col-12">
          <div className="form-group">
            <label>ইউটিউব</label>
            <FormikFormField
              placeholder="ইউটিউব"
              value={values.youtube}
              name="youtube"
              errors={errors}
              touched={touched}
              handleChange={handleChange}
              handleBlur={handleBlur}
            />
          </div>
        </div>
        <div className="col-md-4 col-12">
          <div className="form-group">
            <label>গুগল ম্যাপ</label>
            <FormikFormField
              placeholder="গুগল ম্যাপ"
              value={values.googlemap}
              name="googlemap"
              errors={errors}
              touched={touched}
              handleChange={handleChange}
              handleBlur={handleBlur}
            />
          </div>
        </div>
        <div className="col-md-12 col-12">
          <div className="form-group">
            <label>গুগল এনালিস্ট</label>
            <FormikFormField
              placeholder="গুগল এনালিস্ট"
              value={values.googleanalytics}
              name="googleanalytics"
              errors={errors}
              touched={touched}
              handleChange={handleChange}
              handleBlur={handleBlur}
            />
          </div>
        </div>
        <div className="col-md-12 col-12">
          <div className="form-group">
            <label>সংক্ষিপ্ত পরিচিতি</label>
            <FormikFormField
              placeholder="সংক্ষিপ্ত পরিচিতি"
              value={values.description}
              name="description"
              errors={errors}
              touched={touched}
              handleChange={handleChange}
              handleBlur={handleBlur}
              additional={{
                as: "textarea",
                rows: "6",
              }}
            />
          </div>
        </div>
      </div>
      <div className="text-center mb-2">
        <Button type="submit" disabled={isLoadingUpdate}>
          {isLoadingUpdate ? <BeatLoader color="#fff" /> : "আপডেট করুন"}
        </Button>
      </div>
    </Form>
  );
};

export default WebsiteGeneralSettings;
