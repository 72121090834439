import { Box } from "@mui/material";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import React, { useState } from "react";
import { localDateFormat, localTimeFormat } from "../../../utils/dateFormat";
import ExplanationContent from "./ExplanationContent";
import ExplanationSecondPart from "./ExplanationSecondPart";
import { LabelImportantTwoTone } from "@mui/icons-material";

const ExplanationCommon = ({ comments, info }) => {
  const [value, setValue] = useState("one");

  const handleChange = (key) => {
    setValue(key);
  };

  const titleOne =
    info.fileStatus === 14 ? "তদন্তের রিপোর্ট" : "অতিরিক্ত ব্যাখ্যার জবাব";
  const titleTwo =
    info.fileStatus === 14
      ? "তদন্তের নথি স্থানান্তর"
      : "ব্যাখ্যার নথি স্থানান্তর";

  return (
    <div>
      <Box sx={{ width: "100%", mb: 3 }}>
        <Tabs
          activeKey={value}
          onSelect={handleChange}
          aria-label="secondary tabs example"
        >
          <Tab eventKey="one" title={titleOne}>
            <div className="border border-top-0 px-3">
              <div className="pt-3">
                {info.fileStatus === 14 ? (
                  <>
                    {[2, 4].includes(info?.comments?.role?.id) ? (
                      <>
                        {" "}
                        <div className="card p-2 mt-2">
                          <div>
                            <label htmlFor="">তদন্তের আদেশ</label>
                            <p>{info?.comments?.comments}</p>
                          </div>
                          <div className="d-flex justify-content-end">
                            <div className="text-center">
                              <div className="fw-semibold">আদেশক্রমে</div>
                              <p className="m-0">
                                {info?.comments?.user?.name}
                              </p>
                              <p className="m-0">
                                {info?.comments?.role?.bnName}
                              </p>
                              <div>
                                তারিখ: {localDateFormat(info?.comments?.date)}
                              </div>
                              <div>
                                সময়: {localTimeFormat(info?.comments?.date)}
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    ) : (
                      <div className="py-2">
                        <span className="fw-semibold">
                          {" "}
                          <LabelImportantTwoTone fontSize="17px" /> হইতে,
                        </span>{" "}
                        {info?.comments?.user?.name} (
                        {info?.comments?.role?.bnName})
                      </div>
                    )}
                  </>
                ) : (
                  <div className="py-2">
                    <span className="fw-semibold">
                      <LabelImportantTwoTone fontSize="17px" /> হইতে,
                    </span>{" "}
                    {info?.fromAdmin?.name} ({info?.fromAdmin?.role?.bnName})
                  </div>
                )}
              </div>
              <ExplanationContent comments={comments} info={info} />
            </div>
          </Tab>
          <Tab eventKey="two" title={titleTwo}>
            <div className="border border-top-0 px-3">
              <div className="pt-3">
                {info.fileStatus === 14 ? (
                  <>
                    {[2, 4].includes(info?.comments?.role?.id) ? (
                      <>
                        {" "}
                        <div className="card p-2 mt-2">
                          <div>
                            <label htmlFor="">তদন্তের আদেশ</label>
                            <p>{info?.comments?.comments}</p>
                          </div>
                          <div className="d-flex justify-content-end">
                            <div className="text-center">
                              <div className="fw-semibold">আদেশক্রমে</div>
                              <p className="m-0">
                                {info?.comments?.user?.name}
                              </p>
                              <p className="m-0">
                                {info?.comments?.role?.bnName}
                              </p>
                              <div>
                                তারিখ: {localDateFormat(info?.comments?.date)}
                              </div>
                              <div>
                                সময়: {localTimeFormat(info?.comments?.date)}
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    ) : (
                      <div className="py-2">
                        <span className="fw-semibold">
                          {" "}
                          <LabelImportantTwoTone fontSize="17px" /> হইতে,
                        </span>{" "}
                        {info?.comments?.user?.name} (
                        {info?.comments?.role?.bnName})
                      </div>
                    )}
                  </>
                ) : (
                  <div className="py-2">
                    <span className="fw-semibold">
                      <LabelImportantTwoTone fontSize="17px" /> হইতে,
                    </span>{" "}
                    {info?.fromAdmin?.name} ({info?.fromAdmin?.role?.bnName})
                  </div>
                )}
              </div>
              <ExplanationSecondPart
                comments={comments}
                info={info}
                type={value}
              />
            </div>
          </Tab>
        </Tabs>
      </Box>
    </div>
  );
};

export default ExplanationCommon;
