import React, { useEffect } from "react";
import { useVerifyIndexerMutation } from "../../redux/api/submittedCopyrightAgreementApiSlice";
import Swal from "sweetalert2";
import { useNavigate, useParams } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Button, Form, Table } from "react-bootstrap";
import toast from "react-hot-toast";
import Select from "react-select";
import { useGetRoleNotInQuery } from "../../redux/api/categoryApiSlice";
import { useLazyGetByRoleQuery } from "../../redux/api/applicationCopyrightApiSlice";
import { CancelRounded, CheckCircleRounded } from "@mui/icons-material";
import { localDateFormat, localTimeFormat } from "../../utils/dateFormat";
import { useAuth } from "../../utils/authorization";
import { transformErrorsToObjectStructure } from "../../utils/transformErrorsToObjectStructure";

const IndexerCommonContent = ({ comments, info }) => {
  const { authUser } = useAuth();
  const { id } = useParams();
  const navigate = useNavigate();
  const [
    Verify,
    {
      isLoading: isLoadingVerify,
      isSuccess: isSuccessSubmit,
      error: errorSubmit,
      isError: isErrorSubmit,
      data: dataSubmit,
    },
  ] = useVerifyIndexerMutation();

  const { data: roles } = useGetRoleNotInQuery("[1,9,10,12]");
  const rolesOption =
    roles?.data?.map((data) => ({
      value: data.id,
      label: data.bn_name,
    })) || [];

  const [findUser, { data: users, isLoading }] = useLazyGetByRoleQuery();

  const usersOption =
    (users?.data &&
      Object.entries(users.data)
        .filter(([key]) => authUser.id !== Number(key))
        .map(([key, value]) => ({
          value: Number(key),
          label: value,
        }))) ||
    [];

  const validationSchema = Yup.object().shape({
    comments: Yup.string().when("submit", {
      is: (val) => val !== 2,
      then: () => Yup.string().required("মন্তব্য প্রযোজ্য।"),
      otherwise: () => Yup.string().nullable(),
    }),
    role_id: Yup.number().when("submit", {
      is: (val) => val !== 2,
      then: () => Yup.number().required("পদবী প্রযোজ্য।"),
      otherwise: () => Yup.number().nullable(),
    }),
    user_id: Yup.number().when("submit", {
      is: (val) => val !== 2,
      then: () => Yup.number().required("প্রাপক প্রযোজ্য।"),
      otherwise: () => Yup.number().nullable(),
    }),
  });

  const formik = useFormik({
    initialValues: {
      submit: 2,
      comments: "",
      role_id: null,
      user_id: null,
    },
    validationSchema,
    onSubmit: (values) => {
      const formData = values;
      if (values.submit === 3) {
        Swal.fire({
          // title: "আপনি কি নিশ্চিত?",
          text: "আপনি কি দাখিল দিতে চান?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          cancelButtonText: "না",
          confirmButtonText: "হ্যাঁ",
        }).then((result) => {
          if (result.isConfirmed) {
            Verify({ id, formData });
          }
        });
      } else {
        Verify({ id, formData });
      }
    },
  });
  useEffect(() => {
    if (isSuccessSubmit) {
      toast.success(dataSubmit?.message);
      formik.resetForm();
      navigate("/admin/submitted-copyright");
    }
    if (isErrorSubmit) {
      toast.error(errorSubmit?.data?.message);
      formik.setErrors(
        transformErrorsToObjectStructure(errorSubmit.data?.data || {})
      );
      if (errorSubmit.data.data) {
        formik.setErrors({
          comments: errorSubmit.data.data.comments?.[0],
          role_id: errorSubmit.data.data.role_id?.[0],
          user_id: errorSubmit.data.data.user_id?.[0],
        });
      }
    }
    // eslint-disable-next-line
  }, [isErrorSubmit, isSuccessSubmit]);

  useEffect(() => {
    if (comments) {
      findUser(comments?.user?.id);
      formik.setValues((prev) => ({
        ...prev,
        comments: comments?.comments,
      }));
    }
  }, [comments]);

  const defaultValue = {
    value: "",
    label: "নির্বাচন করুন",
  };

  return (
    <>
      {" "}
      <div>
        <Table bordered hover>
          <thead>
            <tr>
              <th colSpan={2} className="light-gray">
                প্রাথমিকভাবে যাচাইকৃত তথ্য
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>১। চালান চেক</td>
              <td className="text-center">
                {info.treasuryStatus === 1 ? (
                  <CheckCircleRounded className="text-success" />
                ) : (
                  <CancelRounded className="text-danger" />
                )}
              </td>
            </tr>
            <tr>
              <td>২। কর্মের মৌলিকত্ব আছে কি ?</td>
              <td className="text-center">
                {info.basicStatus === 1 ? (
                  <CheckCircleRounded className="text-success" />
                ) : (
                  <CancelRounded className="text-danger" />
                )}
              </td>
            </tr>
          </tbody>
        </Table>
      </div>
      <hr />
      <form onSubmit={formik.handleSubmit}>
        <label className="my-2 fw-semibold" htmlFor="comments">
          মন্তব্য
        </label>
        <Form.Control
          name="comments"
          placeholder="এখানে লিখুন"
          aria-label="Username"
          aria-describedby="basic-addon1"
          as={"textarea"}
          rows={6}
          value={formik.values.comments}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          isInvalid={formik.touched.comments && formik.errors.comments}
        />
        {formik.touched.comments && formik.errors.comments ? (
          <div className="text-danger">{formik.errors.comments}</div>
        ) : null}
        <div className="text-end py-3">
          <div>
            <div className="d-flex justify-content-end">
              <div className="text-center">
                <div className="text-primary">{info?.admin?.name}</div>
                <div className="text-primary">{info?.admin?.role?.bnName}</div>
                <div>তারিখ : {localDateFormat(new Date())}</div>
                <div>সময় : {localTimeFormat(new Date())}</div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <label className="my-2 fw-semibold" htmlFor="role_id">
              পদবী
            </label>
            <Select
              name="role_id"
              options={rolesOption}
              value={
                formik.values.role_id
                  ? rolesOption.find(
                      (option) => option.value === formik.values.role_id
                    )
                  : defaultValue
              }
              onChange={(selectedOption) => {
                findUser(selectedOption.value);
                formik.setValues((prev) => {
                  return {
                    ...prev,
                    user_id: "",
                  };
                });
                formik.setFieldValue("role_id", selectedOption.value);
              }}
              onBlur={formik.handleBlur}
              classNamePrefix="react-select"
            />
            {formik.touched.role_id && formik.errors.role_id ? (
              <div className="text-danger">{formik.errors.role_id}</div>
            ) : null}
          </div>
          <div className="col-md-6">
            <label className="my-2 fw-semibold" htmlFor="user_id">
              প্রাপক
            </label>
            <Select
              name="user_id"
              options={usersOption}
              value={
                formik.values.user_id
                  ? usersOption.find(
                      (option) => option.value === formik.values.user_id
                    )
                  : defaultValue
              }
              onChange={(selectedOption) =>
                formik.setFieldValue("user_id", selectedOption.value)
              }
              onBlur={formik.handleBlur}
              classNamePrefix="react-select"
            />
            {formik.touched.user_id && formik.errors.user_id ? (
              <div className="text-danger">{formik.errors.user_id}</div>
            ) : null}
          </div>
        </div>
        <div className="my-4 text-center d-flex justify-content-center gap-3">
          <div>
            <Button
              type="submit"
              onClick={() => formik.setFieldValue("submit", 2)}
              disabled={isLoadingVerify}
            >
              সংরক্ষণ করুন
            </Button>
          </div>
          <div className="mr-3">
            <Button
              variant="success"
              onClick={() => formik.setFieldValue("submit", 3)}
              type="submit"
              disabled={isLoadingVerify}
            >
              দাখিল করুন
            </Button>
          </div>
          <div className="mr-3">
            <Button
              variant="secondary"
              onClick={() => formik.setFieldValue("submit", 17)}
              type="submit"
              disabled={isLoadingVerify || formik.values.role_id !== 8}
            >
              অতিরিক্ত ব্যাখ্যা
            </Button>
          </div>
        </div>
      </form>
    </>
  );
};

export default IndexerCommonContent;
