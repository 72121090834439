import { useGetAuthorizeUserQuery } from "../redux/api/authApiSlice";

export const useAuthorized = () => {
  const {
    data: user,
    isLoading,
    isError,
  } = useGetAuthorizeUserQuery({
    refetchOnMountOrArgChange: true,
  });

  const permissions = user?.data?.permissions?.map(
    (permission) => permission?.slug
  );

  const hasUserMenuPermissions = (requiredPermissions) => {
    return requiredPermissions.every((permission) => {
      return permissions?.includes(permission);
    });
  };

  const userPermissions =
    user?.data?.role?.permissions.map((permission) => permission.group) || [];
  // has permission
  const hasPermission = (requiredPermissions) => {
    return requiredPermissions.every((permission) =>
      userPermissions.includes(permission)
    );
  };
  // has role
  const hasRole = (requiredRoles) => {
    return requiredRoles.includes(user?.data?.role?.id);
  };

  return {
    user,
    isLoading,
    hasUserMenuPermissions,
    isError,
    hasPermission,
    hasRole,
    userPermissions,
  };
};
