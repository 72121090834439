import { Box, Skeleton } from "@mui/material";
import React from "react";
import { PuffLoader } from "react-spinners";

const LoaderSidebar = () => {
  return (
    <Box>
      <Box display="flex" flexDirection={"column"} gap={1}>
        <div className="m-2">
          <Skeleton variant="rectangular" width={"100%"} height={40} />
        </div>
        <div className="text-center">
          <Skeleton className="m-auto rounded" variant="rectangular" width={"40%"} height={110} />
        </div>
        <Skeleton className="m-auto" variant="rectangular" width={"80%"} height={20} />
        <Skeleton className="m-auto mb-3" variant="rectangular" width={"60%"} height={12} />
        <Skeleton variant="rectangular" width={"100%"} height={40} />
        <Skeleton variant="rectangular" width={"100%"} height={40} />
        <Skeleton variant="rectangular" width={"100%"} height={40} />
        <Skeleton variant="rectangular" width={"100%"} height={40} />
        <Skeleton variant="rectangular" width={"100%"} height={40} />
        <Skeleton variant="rectangular" width={"100%"} height={40} />
        <Skeleton variant="rectangular" width={"100%"} height={40} />
        <Skeleton variant="rectangular" width={"100%"} height={40} />
        <Skeleton variant="rectangular" width={"100%"} height={40} />
      </Box>
    </Box>
  );
};

export default LoaderSidebar;
