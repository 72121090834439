import { apiSlice } from "./apiSlice";
import {
  CONTACT_MESSAGE_REPLY,
  CONTACT_US,
  CONTACT_US_CLIENT,
} from "../constants";

export const contactsApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getContacts: builder.query({
      query: ({ searchValue = "", currentPage = 1 }) => {
        let url = `${CONTACT_US}?page=${currentPage}&per_page=10`;
        if (searchValue) {
          url += `&searchText=${searchValue}`;
        }

        return {
          url: url,
          method: "GET",
        };
      },
      providesTags: ["contacts"],
    }),
    findContacts: builder.query({
      query: ({ id, page }) => CONTACT_US + "/" + id + "?page=" + page,
      providesTags: ["reply_contacts"],
    }),
    crateContacts: builder.mutation({
      query: (formData) => ({
        url: `${CONTACT_US_CLIENT}`,
        method: "POST",
        body: formData,
      }),
      invalidatesTags: ["contacts"],
    }),
    updateContacts: builder.mutation({
      query: ({ formData, id }) => ({
        url: `${CONTACT_US}/${id}`,
        method: "POST",
        body: formData,
      }),
      invalidatesTags: ["contacts"],
    }),
    replyContacts: builder.mutation({
      query: ({ formData, id }) => ({
        url: `${CONTACT_MESSAGE_REPLY}/${id}`,
        method: "POST",
        body: formData,
      }),
      invalidatesTags: ["reply_contacts"],
    }),
    deleteContacts: builder.mutation({
      query: (id) => ({
        url: CONTACT_US + "/" + id,
        method: "DELETE",
      }),
      invalidatesTags: ["contacts"],
    }),
  }),
});

export const {
  useFetchContactsClientQuery,
  useGetContactsQuery,
  useFindContactsQuery,
  useCrateContactsMutation,
  useUpdateContactsMutation,
  useReplyContactsMutation,
  useDeleteContactsMutation,
} = contactsApiSlice;
