import React, { useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import { useFormik } from "formik";
import toast from "react-hot-toast";
import {
  useCreateBankBranchesMutation,
  useUpdateBankBranchesMutation,
} from "../../../redux/api/bankBranchApiSlice";
import FormikFormField from "../../../components/custom/form/FormikFormField";
import FormikFormSelect from "../../../components/custom/form/FormikFormSelect";
import * as yup from "yup";
import { BeatLoader } from "react-spinners";

// Validation schema
const validationSchema = yup.object().shape({
  bank_id: yup.number().required("ব্যাংক আইডি প্রযোজ্য"),
  name: yup.string().required("নাম প্রযোজ্য"),
  status: yup.string().required("স্ট্যাটাস প্রযোজ্য"),
});

const AddUpdateBranches = ({ onHide, bank, data, ...props }) => {
  const [createData, { isError, error, isSuccess, data: createDataValue }] =
    useCreateBankBranchesMutation();

  const [
    updateData,
    {
      isError: isErrorUpdate,
      error: errorUpdate,
      isSuccess: isSuccessUpdate,
      data: dataUpdate,
    },
  ] = useUpdateBankBranchesMutation();

  const initialValues = {
    bank_id: data?.bank_id || "", // Ensure bank_id is set correctly when editing
    name: data?.name || "",
    status: data?.status || "",
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values) => {
      if (values.id) {
        const formData = {
          ...values,
          _method: "PUT",
        };
        await updateData({ formData, id: values.id });
      } else {
        await createData({ formData: values });
      }
    },
  });

  const {
    values,
    touched,
    errors,
    handleBlur,
    handleChange,
    handleSubmit,
    setValues,
    setErrors,
    isSubmitting,
    resetForm,
  } = formik;

  useEffect(() => {
    if (data) {
      setValues({
        ...initialValues,
        ...data,
      });
    }
  }, [data, setValues]);

  useEffect(() => {
    if (isError) {
      toast.error(error?.data?.message);
      setErrors(handleServerErrors(error?.data?.data || {}));
    }
    if (isSuccess) {
      onHide();
      resetForm();
      toast.success(createDataValue?.message);
    }
    // eslint-disable-next-line
  }, [isError, isSuccess, error, createDataValue, onHide, resetForm]);

  useEffect(() => {
    if (isErrorUpdate) {
      toast.error(errorUpdate?.data?.message);
      setErrors(handleServerErrors(errorUpdate?.data?.data || {}));
    }
    if (isSuccessUpdate) {
      onHide();
      resetForm();
      toast.success(dataUpdate?.message);
    }
    // eslint-disable-next-line
  }, [
    isErrorUpdate,
    isSuccessUpdate,
    errorUpdate,
    dataUpdate,
    onHide,
    resetForm,
  ]);
  const handleServerErrors = (serverErrors) => {
    const errors = {};
    Object.keys(serverErrors).forEach((field) => {
      errors[field] = serverErrors[field].join(", ");
    });
    return errors;
  };

  return (
    <Modal
      {...props}
      onHide={() => {
        onHide();
        resetForm();
      }}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header style={{ display: "flex", justifyContent: "center" }}>
        <Modal.Title id="contained-modal-title-vcenter">
          <span className="fw-bold px-3">
            {data ? "শাখা সম্পাদনা করুন" : "নতুন শাখা যোগ করুন"}
          </span>
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <form onSubmit={handleSubmit}>
          <div className="row">
            <div className="col-md-12">
              <FormikFormField
                value={values.name}
                name="name"
                label="শাখা"
                placeholder="শাখা লিখুন"
                errors={errors}
                touched={touched}
                handleChange={handleChange}
                handleBlur={handleBlur}
              />
            </div>

            <div className="col-md-12">
              <FormikFormSelect
                value={values.bank_id}
                name="bank_id"
                label="ব্যাংক"
                placeholder="ব্যাংক নির্বাচন করুন"
                errors={errors}
                touched={touched}
                handleChange={handleChange}
                handleBlur={handleBlur}
                options={bank.map((item) => ({
                  label: item.name,
                  value: item.id,
                }))}
              />
            </div>

            <div className="col-md-12">
              <FormikFormSelect
                value={values.status}
                name="status"
                label="স্ট্যাটাস"
                placeholder="স্ট্যাটাস নির্বাচন করুন"
                errors={errors}
                touched={touched}
                handleChange={handleChange}
                handleBlur={handleBlur}
                options={[
                  { label: "Active", value: 1 },
                  { label: "In-active", value: 0 },
                ]}
              />
            </div>

            <div className="col-md-12">
              <hr />
              <div className="d-flex justify-content-between align-items-center pt-2">
                <Button
                  variant="secondary"
                  onClick={() => {
                    onHide();
                    resetForm();
                  }}
                  style={{ width: "100px" }}
                >
                  বন্ধ
                </Button>
                <Button
                  variant="primary"
                  type="submit"
                  style={{ width: "150px" }}
                  disabled={isSubmitting}
                >
                  {isSubmitting ? (
                    <BeatLoader className="text-white" />
                  ) : (
                    "দাখিল করুন"
                  )}
                </Button>
              </div>
            </div>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
};

export default AddUpdateBranches;
