import { CATALOG_ATTENTION_MESSAGES } from "../constants";
import { apiSlice } from "./apiSlice";

export const attentionMessageApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    // Fetch admin attention messages
    getAdminAttentionMessages: builder.query({
      query: ({ title = "", status = "", currentPage = 1 }) => {
        // Construct the URL with query parameters
        let url = `${CATALOG_ATTENTION_MESSAGES}?page=${currentPage}&per_page=10`;
        if (title) {
          url += `&title=${encodeURIComponent(title)}`;
        }
        if (status) {
          url += `&status=${encodeURIComponent(status)}`;
        }
        return {
          url,
          method: "GET",
        };
      },
      providesTags: ["admin_attention_messages"],
    }),
    // Create a new admin attention message
    createAdminAttentionMessage: builder.mutation({
      query: (formData) => ({
        url: CATALOG_ATTENTION_MESSAGES,
        method: "POST",
        body: formData,
      }),
      invalidatesTags: ["admin_attention_messages"],
    }),
    // Update an existing admin attention message
    updateAdminAttentionMessage: builder.mutation({
      query: ({ id, formData }) => ({
        url: `${CATALOG_ATTENTION_MESSAGES}/${id}`,
        method: "PUT", // Consider using PUT or PATCH for updates
        body: formData,
      }),
      invalidatesTags: ["admin_attention_messages"],
    }),
    // Delete an admin attention message
    deleteAdminAttentionMessage: builder.mutation({
      query: (id) => ({
        url: `${CATALOG_ATTENTION_MESSAGES}/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["admin_attention_messages"],
    }),
  }),
});

export const {
  useGetAdminAttentionMessagesQuery,
  useCreateAdminAttentionMessageMutation,
  useUpdateAdminAttentionMessageMutation,
  useDeleteAdminAttentionMessageMutation,
} = attentionMessageApiSlice;
