import { useFormik } from "formik";
import React, { useEffect } from "react";
import { Button, Form, Table } from "react-bootstrap";
import toast from "react-hot-toast";
import { useOriginalityTestMutation } from "../../../../../redux/api/submittedCopyrightAgreementApiSlice";
import { BeatLoader } from "react-spinners";
import * as yup from "yup";
import { Search } from "@mui/icons-material";
import { localDateFormat } from "../../../../../utils/dateFormat";
import BanglaCalender from "../../../../frontend/userPanel/form_two/BanglaCalender";

const TreasuryChalanVerify = ({ info, treasuryChalanVerify }) => {
  const [treasuryVerify, { isLoading, isError, isSuccess, error, data }] =
    treasuryChalanVerify;
  const formik = useFormik({
    initialValues: {
      id: "",
      treasuryNo: "",
      treasuryDate: "",
      treasuryBank: "",
      bankbranch_id: "",
    },
    // validationSchema: yup.object({
    //   id: yup.number().required("আইডি প্রযোজ্য"),
    // }),
    onSubmit: (values) => {
      treasuryVerify(values);
    },
  });

  useEffect(() => {
    if (info) {
      formik.setValues({
        id: info.id,
        treasuryNo: info.treasuryNo,
        treasuryDate: info.treasuryDate,
        treasuryBank: info.bankbranch?.bank?.id,
        bankbranch_id: info.bankbranch?.id,
      });
    }
  }, [info]);

  useEffect(() => {
    if (isSuccess) {
      console.log(data.data);
    }
    if (isError) {
      toast.error(error?.message);
    }
  }, [isSuccess, isError]);

  return (
    <div className="col-md-6 mb-4">
      <div className="card h-100">
        <div className="bg-slate p-2 fw-semibold ">ট্রেজারি চালান যাচাই</div>
        <div className="pt-3 p-3">
          <div className="row">
            <div className="col-md-3 mt-3">
              <label className="fw-semibold">ট্রেজারি চালান নং</label>
              <Form.Control
                placeholder="ট্রেজারি চালান নং"
                aria-label="Username"
                aria-describedby="basic-addon1"
                value={info.treasuryNo}
                readOnly
              />
            </div>

            <div className="col-md-3 mt-3">
              <label className="fw-semibold">তারিখ</label>
              <BanglaCalender
                value={formik.values.treasuryDate}
                placeholder={"তারিখ"}
              />
            </div>
            <div className="col-md-3 mt-3">
              <label className="fw-semibold">ব্যাংকের নাম</label>
              <Form.Control
                placeholder="ব্যাংকের নাম"
                aria-label="Username"
                aria-describedby="basic-addon1"
                value={info.bankbranch?.bank?.name}
                readOnly
              />
            </div>
            <div className="col-md-3 mt-3">
              <label className="fw-semibold">শাখা</label>
              <Form.Control
                placeholder="শাখা"
                aria-label="Username"
                aria-describedby="basic-addon1"
                value={info?.bankbranch?.name}
                readOnly
              />
            </div>
            <div className="col-md-12 mt-4">
              <div className="text-center">
                <Button
                  onClick={formik.handleSubmit}
                  disabled={isLoading}
                  className="px-4"
                  variant="success"
                >
                  {isLoading ? (
                    <BeatLoader color="#fff" />
                  ) : (
                    <>
                      <Search /> খুঁজুন
                    </>
                  )}
                </Button>
              </div>
            </div>
          </div>
        </div>
        {data?.data?.length > 0 && (
          <div className="overflow- overflow-x-scroll">
            <Table bordered hover>
              <thead>
                <tr>
                  <th className="light-gray">আবেদনপত্র নং</th>
                  <th className="light-gray">আবেদনকারীর নাম</th>
                  <th className="light-gray">শিরোনাম</th>
                  <th className="light-gray">শ্রেণী</th>
                  <th className="light-gray">উপশ্রেণী</th>
                </tr>
              </thead>
              <tbody>
                {data?.data.map((item) => (
                  <tr key={item.id}>
                    <td>{item.trackReg}</td>
                    <td>{item.applicantName}</td>
                    <td>
                      {item?.profileDetail?.workTitle ??
                        item?.document?.workTitle}
                    </td>
                    <td>
                      {item?.profileDetail?.category?.name ??
                        item?.document?.category?.name}
                    </td>
                    <td>
                      {item?.profileDetail?.subCategory?.name ??
                        item?.document?.subCategory?.name}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        )}
      </div>
    </div>
  );
};

export default TreasuryChalanVerify;
