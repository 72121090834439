import React, { memo, useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import { useFormik } from "formik";
import toast from "react-hot-toast";
import FormikFormField from "../../../components/custom/form/FormikFormField";
import FormikFormSelect from "../../../components/custom/form/FormikFormSelect";
import { initializeValues, validationSchema } from "./form_config";
import { BeatLoader } from "react-spinners";
import {
  useCrateFaqMutation,
  useUpdateFaqMutation,
} from "../../../redux/api/faqApiSlice";
import { useGetCategoriesQuery } from "../../../redux/api/categoryApiSlice";

const AddData = ({ onHide, editData, ...props }) => {
  const { data: category } = useGetCategoriesQuery();
  const [createData, { isError, error, isSuccess, data }] =
    useCrateFaqMutation();

  const categoryData = category?.data?.map((data) => ({
    label: data.name,
    value: data.id,
  }));

  const [
    updateData,
    {
      isError: isErrorUpdate,
      error: errorUpdate,
      isSuccess: isSuccessUpdate,
      data: dataUpdate,
    },
  ] = useUpdateFaqMutation();

  const formik = useFormik({
    initialValues: initializeValues,
    validationSchema: validationSchema,
    onSubmit: async (values, { setSubmitting }) => {
      try {
        setSubmitting(true);

        const formData = new FormData();
        Object.keys(values).forEach((key) => {
          if (values[key]) {
            formData.append(key, values[key]);
          }
        });
        if (editData?.id) {
          formData.append("_method", "PUT");
        }

        if (editData?.id) {
          await updateData({
            id: editData.id,
            formData,
          });
        } else {
          await createData(formData);
        }
        setSubmitting(false);
      } catch (error) {
        setSubmitting(false);
      }
    },
  });

  const {
    values,
    touched,
    errors,
    handleBlur,
    handleChange,
    handleSubmit,
    setValues,
    setFieldValue,
    setErrors,
    isSubmitting,
    resetForm,
  } = formik;

  useEffect(() => {
    if (editData) {
      setValues({
        ...initializeValues,
        ...editData,
      });
    }
  }, [editData, setValues]);

  useEffect(() => {
    if (isError) {
      toast.error(error?.data?.message);
      setErrors(handleServerErrors(error?.data?.data || {}));
    }
    if (isSuccess) {
      onHide();
      resetForm();
      toast.success(data?.message);
    }
  }, [isError, isSuccess, error, data]);

  useEffect(() => {
    if (isErrorUpdate) {
      toast.error(errorUpdate?.data?.message);
      setErrors(handleServerErrors(errorUpdate?.data?.data || {}));
    }
    if (isSuccessUpdate) {
      onHide();
      resetForm();
      toast.success(dataUpdate?.message);
    }
    // eslint-disabled-next-line
  }, [isErrorUpdate, isSuccessUpdate, errorUpdate, dataUpdate]);

  const handleServerErrors = (serverErrors) => {
    const errors = {};
    Object.keys(serverErrors).forEach((field) => {
      errors[field] = serverErrors[field].join(", ");
    });
    return errors;
  };

  return (
    <Modal
      {...props}
      onHide={() => {
        onHide();
        resetForm();
      }}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header style={{ display: "flex", justifyContent: "center" }}>
        <Modal.Title id="contained-modal-title-vcenter">
          <span className="fw-bold px-3">
            {editData
              ? "প্রশ্ন এবং উত্তর সম্পাদনা করুনঃ"
              : "নতুন প্রশ্ন এবং উত্তর যোগ করুন"}
          </span>
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <form onSubmit={handleSubmit}>
          <div className="row">
            <div className="col-md-12">
              <FormikFormField
                value={values.question}
                name="question"
                label="প্রশ্ন"
                placeholder="প্রশ্ন লিখুন"
                errors={errors}
                touched={touched}
                handleChange={handleChange}
                handleBlur={handleBlur}
                additional={{
                  as: "textarea",
                  rows: "3",
                }}
              />
            </div>
            <div className="col-md-12">
              <FormikFormField
                value={values.answer}
                name="answer"
                label="উত্তর"
                placeholder="উত্তর লিখুন"
                errors={errors}
                touched={touched}
                handleChange={handleChange}
                handleBlur={handleBlur}
                additional={{
                  as: "textarea",
                  rows: "6",
                }}
              />
            </div>
            <div className="col-md-6">
              <FormikFormSelect
                value={values.status}
                name="status"
                label="স্ট্যাটাস"
                placeholder="স্ট্যাটাস নির্বাচন করুন"
                errors={errors}
                touched={touched}
                handleChange={handleChange}
                handleBlur={handleBlur}
                options={[
                  { label: "Active", value: 1 },
                  { label: "In-Active", value: 2 },
                ]}
              />
            </div>

            <div className="col-md-6">
              <FormikFormField
                value={values.sorting_index}
                name="sorting_index"
                label="ইনডেক্স"
                placeholder="ইনডেক্স লিখুন"
                errors={errors}
                touched={touched}
                handleChange={handleChange}
                handleBlur={handleBlur}
              />
            </div>

            <div className="col-md-12">
              <hr />
              <div className="d-flex justify-content-between align-items-center pt-2">
                <Button
                  variant="secondary"
                  onClick={() => {
                    onHide();
                    resetForm();
                  }}
                  style={{ width: "100px" }}
                >
                  বন্ধ
                </Button>
                <Button
                  variant="primary"
                  type="submit"
                  style={{ width: "150px" }}
                  disabled={isSubmitting}
                >
                  {isSubmitting ? (
                    <BeatLoader className="text-white" />
                  ) : (
                    "দাখিল করুন"
                  )}
                </Button>
              </div>
            </div>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
};

export default memo(AddData);
