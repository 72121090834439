export const applicationFormShortTitle = (dropdownSectionCopyright3) => {
  switch (dropdownSectionCopyright3) {
    case 13:
      return `অনাপত্তি পত্রের/হস্তান্তর দলিলের
          স্ক্যান কপি`;
    case 19:
      return `প্রাসঙ্গিক দলিলের / প্রমাণাদির স্ক্যান কপি`;
    case 14:
    case 8:
      return `হস্তান্তর দলিলের স্ক্যান কপি`;
    case 15:
    case 9:
      return `নিয়োগ পত্রের স্ক্যান কপি`;
    case 16:
    case 10:
      return `অনাপত্তি / সম্মতি / হস্তান্তর দলিলের স্ক্যান কপি`;
    case 17:
      return `মূল লেখকের অনুমতিপত্রের স্ক্যান কপি `;
    case 18:
      return `মূল প্রণেতার অনাপত্তিপত্রের / সন্মতিপত্রের স্ক্যান কপি `;
      
    default:
      return `অনাপত্তি পত্রের / সম্মতি পত্রের স্ক্যান কপি `;
    }
};
